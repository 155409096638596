import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { getRoomSidebarWidth } from 'helpers';
import Heading from 'components/typography/Heading';
import PPVPassEarningsTable from './components/PPVPassEarningsTable';
import { UserContext } from 'context/user';
import { Redirect } from 'react-router-dom';
import routes from 'routes';

const PPVPassEarningsPage = () => {
  const sidebarWidth = getRoomSidebarWidth();
  const { user, isCreator } = useContext<Record<string, any>>(UserContext);

  if (!user) {
    return <Redirect to={routes.login} />;
  }

  if (!isCreator) {
    return <Redirect to={routes.dashboard} />;
  }

  return (
    <>
      <Helmet>
        <title>Pay Per View Season Pass Earnings | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>Pay Per View Season Pass Earnings</Heading>
      </div>
      <div className='mr-6 mt-28 -ml-2 px-8 py-10 shadow-card rounded-lg min-h-80vh'>
        <Heading headingSize={6} classes='font-bold tracking-tight mb-9'>
          Pay Per View Season Pass Earnings
        </Heading>
        <PPVPassEarningsTable />
      </div>
    </>
  );
};

export default PPVPassEarningsPage;
