import React, { useContext, useState } from 'react';
import Heading from '../typography/Heading';
import ProfileDetailsEdit from './ProfileDetailsEdit';
import Checkbox from '../form/Checkbox';
import { UserContext } from '../../context/user';
import { AlertsContext } from '../../context/alerts';

import { boolToInt, intToBool } from '../../helpers';

const ProfileDetails = () => {
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);

  const { user, updateCurrentUser } = userContext;
  const { addAlert } = alertsContext;

  const userData = user?.dbData?.data?.getUser || {};

  const [offers, setOffers] = useState(userData?.subscribedForNews || false);
  const [notifications, setNotifications] = useState(
    intToBool(userData?.notifications) || false
  );
  const [wild, setWild] = useState(intToBool(userData?.isWild) || false);

  const saveOffers = async (value) => {
    setOffers(value);
    const result = await updateCurrentUser({
      subscribedForNews: value,
    });

    if (!result.success) {
      addAlert('danger', 'Something went wrong. Please try again.');
      return;
    }
  };

  const saveNotifications = async (value) => {
    setNotifications(value);
    const result = await updateCurrentUser({
      notifications: boolToInt(value),
    });

    if (!result.success) {
      addAlert('danger', 'Something went wrong. Please try again.');
      return;
    }
  };

  const saveWild = async (value) => {
    setWild(value);
    const result = await updateCurrentUser({
      isWild: boolToInt(value),
    });

    if (!result.success) {
      addAlert('danger', 'Something went wrong. Please try again.');
      return;
    }
  };

  return (
    <div className={`profile-details-box`}>
      <Heading
        headingSize={6}
        classes='mb-7 font-bold text-primaryC text-center tracking-tight'
      >
        Profile Details
      </Heading>
      <ProfileDetailsEdit type='displayName' />
      <ProfileDetailsEdit type='location' />
      <ProfileDetailsEdit type='fullName' />
      <Checkbox
        checkboxID='wild'
        checkboxName='wild'
        classes='col-span-4'
        checked={wild}
        toggle={() => saveWild(!wild)}
      >
        Wild user (Event hosts can invite you even if you are not watching)
      </Checkbox>
      <Checkbox
        checkboxID='notifications'
        checkboxName='notifications'
        classes='col-span-4'
        checked={notifications}
        toggle={() => saveNotifications(!notifications)}
      >
        Receive event notifications
      </Checkbox>
      <Checkbox
        checkboxID='offers'
        checkboxName='offers'
        classes='col-span-4'
        checked={offers}
        toggle={() => saveOffers(!offers)}
      >
        Keep me up to date on news and exclusive offers.
      </Checkbox>
    </div>
  );
};

export default ProfileDetails;
