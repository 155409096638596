import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { UserContext } from '../context/user';

import InfoBox from '../components/withdraw/InfoBox';
import BankDataForm from '../components/withdraw/BankDataForm';

import routes from '../routes';
import history from '../history';
import { getRoomSidebarWidth } from '../helpers';
import Heading from '../components/typography/Heading';
import WithdrawTile from '../components/withdraw/WithdrawTile';
import useBankingData from '../hooks/useBankingData';

const WithdrawPage = () => {
  const [loadedUser, setLoadedUser] = useState(false);
  const [loadedData, setLoadedData] = useState(false);

  const sidebarWidth = getRoomSidebarWidth();
  const {
    data,
    getCurrentUserBankingData,
    createBankingData,
    updateBankingData,
    deleteBankingData,
  } = useBankingData();

  const userContext = useContext(UserContext);
  const { userIsLoading, user, isCreator } = userContext;

  useEffect(() => {
    if (!userIsLoading && !user) {
      history.replace({
        pathname: routes.login,
        state: { from: history.location },
      });
      return;
    }

    if (user && !isCreator) {
      history.replace(routes.dashboard);
      return;
    }

    if (!loadedUser) {
      setLoadedUser(true);
    }
  }, [userIsLoading, user, isCreator]);

  useEffect(async () => {
    if (!loadedData && loadedUser) {
      await getCurrentUserBankingData();
      setLoadedData(true);
    }
  }, [loadedUser]);

  return (
    <>
      <Helmet>
        <title>Withdraw Money | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>Withdraw Money</Heading>
      </div>
      <div className='grid grid-cols-12 gap-4 mr-6 mt-28'>
        <InfoBox />
        <WithdrawTile bankingData={data} loadedBankingData={loadedData} />
        <BankDataForm
          dataHasLoaded={loadedData}
          data={data}
          createBankingData={createBankingData}
          updateBankingData={updateBankingData}
          deleteBankingData={deleteBankingData}
        />
      </div>
    </>
  );
};

export default WithdrawPage;
