import React from 'react';
import Card from '../card/card-restyle/Card';
import CardSkeleton from '../../skeletons/CardSkeleton';

const renderLoading = () => {
  return Array(6)
    .fill()
    .map((item, index) => <CardSkeleton key={index} />);
};

const DashLayout = ({
  events,
  loading,
  showBuyModal,
  ppvItems,
  currentUserId,
  contentType = 'events',
}) => {
  const renderEvents = () => {
    const loader = renderLoading();
    let cards = [];

    if (!events) {
      return loader;
    }

    for (const event of events) {
      let eventIsCreatedByCurrentUser = false;
      if (event.ppv) {
        let eventCreatorId = event?.hostSponsorRoom?.userID;

        if (contentType === 'videos') {
          eventCreatorId = event.userID;
        }

        if (eventCreatorId === currentUserId) {
          eventIsCreatedByCurrentUser = true;
        }
      }

      if (event) {
        cards.push(
          <Card
            id={event.id}
            key={event.id}
            hostName={event.hostName}
            hostUser={event.hostUser?.displayName}
            hostSponsorRoom={event.hostSponsorRoom?.title}
            eventState={event.state}
            images={event.imageUrls}
            date={event.startTime}
            title={event.title}
            description={`${event.contestorA} VS ${event.contestorB}`}
            isPPV={event.ppv || false}
            price={event.ppvPrice || 0}
            showBuyModal={showBuyModal}
            isPurchased={ppvItems[event.id]}
            eventIsCreatedByCurrentUser={eventIsCreatedByCurrentUser}
            roomId={event.hostSponsorRoom?.id}
            contentType={contentType}
            seoUrl={event.seoUrl}
          />
        );
      }

      if (loading) {
        cards = [...cards, ...renderLoading()];
      }
    }

    return cards;
  };

  return renderEvents();
};

export default DashLayout;
