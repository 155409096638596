/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Heading from '../components/typography/Heading';
import Button from '../components/common/Button';
import Table from '../components/table/Table';
import TableBody from '../components/table/TableBody';
import TableHead from '../components/table/TableHead';
import TableRow from '../components/table/TableRow';
import TableCell from '../components/table/TableCell';
import Icon from '../components/typography/Icon';
import DropdownList from '../components/dropdown/DropdownList';

import useVOD from '../hooks/useVOD';
import { SponsorRoomsContext } from '../context/sponsorRooms';

import { getRoomSidebarWidth, formatDate } from '../helpers';
import routes from '../routes';
import history from '../history';
import { UserContext } from '../context/user';

const RenderLoader = ({ innerRef }) => {
  return (
    <TableRow innerRef={innerRef} classes={`border-b border-secondaryB`}>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={20} width={240} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={20} width={100} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={60} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={60} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={60} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={60} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={60} />
      </TableCell>
    </TableRow>
  );
};

const VideosList = () => {
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);

  const { currentUserRoom } = sponsorRoomsContext;
  const { user, isCreator } = userContext;

  const { loadVideosByRoomId, videos, nextToken, loading } = useVOD();
  const sidebarWidth = getRoomSidebarWidth();
  const tableHeadingStyles =
    'py-2 font-display font-medium text-sm text-left leading-5 tracking-tight';

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  useEffect(() => {
    if (videos.length === 0 && currentUserRoom) {
      loadVideosByRoomId(currentUserRoom.id);
    }
  }, [currentUserRoom]);

  const loadMore = () => {
    if (nextToken) {
      loadVideosByRoomId(currentUserRoom.id);
    }
  };

  const goToEdit = (id) => {
    history.push(routes.editVideo.replace(':videoId', id));
  };

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: nextToken || false,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <>
      <Helmet>
        <title>My Videos | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>My Videos</Heading>
      </div>
      <div className='mt-28 mr-6 px-8 py-10 shadow-card rounded-lg'>
        <div className='flex items-center justify-end w-full mb-8'>
          <Button
            isLink
            linkTo={routes.newVideo}
            buttonSize='small'
            buttonType='primary'
          >
            Upload a Video
          </Button>
        </div>
        <Table>
          <TableHead>
            <TableRow classes='border-b border-secondaryB'>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Title
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Listing
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Date
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Views
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Comments
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Coins
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Purchases
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos.map((video) => (
              <TableRow
                key={video.id}
                classes={`border-b border-secondaryB`}
                clickable
                onClick={() => {
                  goToEdit(video.id);
                }}
              >
                <TableCell variant='body' classes='font-bold py-2'>
                  {video.title}
                </TableCell>
                <TableCell variant='body' classes='py-2'>
                  {video.listing}
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight'
                >
                  {formatDate(video.createdAt)}
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-bold py-2 text-sm font-numbers'
                >
                  N/A
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-bold py-2 text-sm font-numbers'
                >
                  N/A
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-bold py-2 text-sm font-numbers'
                >
                  {video.ppv === 1 ? video.ppvPrice : '0'}
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-bold py-2 text-sm font-numbers'
                >
                  N/A
                </TableCell>
                <TableCell variant='body' classes='py-2'>
                  <DropdownList
                    showArrow={false}
                    items={[
                      { text: 'Edit', onClick: () => goToEdit(video.id) },
                    ]}
                  >
                    <Icon name='dots' />
                  </DropdownList>
                </TableCell>
              </TableRow>
            ))}
            {nextToken && <RenderLoader key='sentry' innerRef={sentryRef} />}
          </TableBody>
        </Table>
      </div>
    </>
  );
};

export default VideosList;
