import React, { useEffect, useState } from 'react';

const StoryBar = ({
  index,
  goToIndex,
  goToNextIndex,
  duration,
  isActive,
  shouldReset,
  shouldFill,
  resetter,
}) => {
  const [currentValue, setCurrentValue] = useState(0);

  const updateValue = () => {
    const newValue = currentValue + 0.2;
    setCurrentValue(newValue);
  };

  useEffect(() => {
    let interval = null;

    if (shouldReset) {
      setCurrentValue(0);
    }

    if (shouldFill) {
      setCurrentValue(duration);
    }

    if (isActive) {
      interval = setInterval(updateValue, 200);
    }

    if (currentValue > duration) {
      clearInterval(interval);
      goToNextIndex(index + 1);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  });

  const handleClick = () => {
    goToIndex(index);

    if (currentValue === duration) {
      setCurrentValue(0);
    }
  };

  if (resetter) {
    return (
      <progress
        id={'bar-' + index}
        key={index}
        max={duration}
        value={currentValue}
        style={{
          width: 0,
          flex: 0,
        }}
        className={`progress`}
        onClick={handleClick}
      ></progress>
    );
  }

  return (
    <progress
      id={'bar-' + index}
      key={index}
      max={duration}
      value={currentValue}
      style={{
        width: '30px',
      }}
      className={`progress`}
      onClick={handleClick}
    ></progress>
  );
};

export default StoryBar;
