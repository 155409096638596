import React, { useState } from 'react';
import Heading from '../typography/Heading';
import Button from '../common/Button';
import Paragraph from '../typography/Paragraph';

const items = [
  'Unwanted commercial content or spam',
  'Pornography or sexually explicit material',
  'Child abuse',
  'Hate speech or graphic violence',
  'Harassment or bullying',
];

const ReportMessage = ({ message, checked, setChecked }) => {
  const handleOnChange = () => {
    setChecked();
  };

  return (
    <label className='radio-container' onClick={(e) => e.stopPropagation()}>
      <Paragraph>{message}</Paragraph>
      <input type='radio' onChange={handleOnChange} checked={checked} />
      <span className='checkmark'></span>
    </label>
  );
};

const ReportModal = ({ modalIsVisible, closeModal, createUserReport }) => {
  const [checked, setChecked] = useState(0);

  const handleSubmit = (e) => {
    e.stopPropagation();
    createUserReport(items[checked]);
    closeModal();
  };

  const handleClose = (e) => {
    e.stopPropagation();
    closeModal();
  };

  if (!modalIsVisible) {
    return null;
  }

  return (
    <div className='absolute inset-0 flex items-center justify-center z-50'>
      <div className='bg-white w-full py-6 px-4 mx-2 rounded-lg max-w-lg shadow-card'>
        <div className='pb-8'>
          <Heading headingSize={5}>Report message</Heading>
        </div>
        <div className='flex flex-col font-paragraph text-base mb-9'>
          {items.map((msg, i) => {
            return (
              <ReportMessage
                key={i}
                message={msg}
                checked={checked === i}
                setChecked={() => setChecked(i)}
              />
            );
          })}
        </div>
        <div className='flex justify-end'>
          <Button
            buttonType='secondary'
            buttonSize='small'
            classes='mr-4'
            buttonOnClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            buttonType='primary'
            buttonSize='small'
            buttonOnClick={handleSubmit}
          >
            Report
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportModal;
