import React, { useEffect } from 'react';
import {
  useVideoInputs,
  useAudioInputs,
  useAudioOutputs,
  useSelectAudioInputDevice,
  useSelectAudioOutputDevice,
  useSelectVideoInputDevice,
} from 'amazon-chime-sdk-component-library-react';

import SelectField from '../form/SelectField';
import CameraPreview from './CameraPreview';

import useDeviceSelection from '../../hooks/useDeviceSelection';

const DeviceSelectors = ({ error, onError }) => {
  const {
    videoInput,
    audioInput,
    audioOutput,
    setVideoInput,
    setAudioInput,
    setAudioOutput,
    chooseDevices,
    getDevicePermissions,
  } = useDeviceSelection();

  const { devices: videoDevices, selectedDevice } = useVideoInputs();
  const { devices: audioInputDevices } = useAudioInputs();
  const { devices: audioOutputDevices } = useAudioOutputs();
  const selectVideoInputDevice = useSelectVideoInputDevice();
  const selectAudioInputDevice = useSelectAudioInputDevice();
  const selectAudioOutputDevice = useSelectAudioOutputDevice();

  const handleCameraSelect = (device) => {
    setVideoInput(device);
    selectVideoInputDevice(device.deviceId);
    chooseDevices();
  };

  const handleMicSelect = (device) => {
    setAudioInput(device);
    selectAudioInputDevice(device.deviceId);
  };

  const handleAudioOutputSelect = (device) => {
    setAudioOutput(device);
    selectAudioOutputDevice(device.deviceId);
  };

  const onDevicesError = () => {
    onError(true);
  };

  useEffect(async () => {
    await getDevicePermissions(onDevicesError);
  }, []);

  useEffect(() => {
    if (videoDevices[0] && !videoInput) {
      handleCameraSelect(videoDevices[0]);
    }
  }, [videoDevices]);

  useEffect(() => {
    if (audioInputDevices[0] && !audioInput) {
      handleMicSelect(audioInputDevices[0]);
    }
  }, [audioInputDevices]);

  useEffect(() => {
    if (audioOutputDevices[0] && !audioOutput) {
      handleAudioOutputSelect(audioOutputDevices[0]);
    }
  }, [audioOutputDevices]);

  const getCameraOptions = () => {
    const cameras = videoDevices.map((input) => ({
      id: input.deviceId,
      text: input.label,
      onClick: () => {
        handleCameraSelect(input);
      },
    }));

    return [{ id: 'no-camera', text: '-', onClick: () => {} }, ...cameras];
  };

  const getMicOptions = () => {
    const mics = audioInputDevices.map((input) => ({
      id: input.deviceId,
      text: input.label,
      onClick: () => {
        handleMicSelect(input);
      },
    }));

    return [{ id: 'no-mic', text: '-', onClick: () => {} }, ...mics];
  };

  const getAudioOutputOptions = () => {
    const outputs = audioOutputDevices.map((input) => ({
      id: input.deviceId,
      text: input.label,
      onClick: () => {
        handleAudioOutputSelect(input);
      },
    }));

    return [
      { id: 'no-audio-output', text: '-', onClick: () => {} },
      ...outputs,
    ];
  };

  if (error) {
    return <div>Please check your input devices and try again!</div>;
  }

  return (
    <div>
      <CameraPreview selectedCamera={selectedDevice} />
      <SelectField
        label='Select camera'
        value={videoInput?.label}
        options={getCameraOptions()}
        listFullWidth
        listMaxHeight={200}
      />
      <SelectField
        label='Select microphone'
        value={audioInput?.label}
        options={getMicOptions()}
        listFullWidth
        listMaxHeight={200}
      />
      <SelectField
        label='Select audio output'
        value={audioOutput?.label}
        options={getAudioOutputOptions()}
        listFullWidth
        listMaxHeight={200}
      />
    </div>
  );
};

export default DeviceSelectors;
