/* eslint-disable new-cap */
/* eslint-disable prefer-promise-reject-errors */
import { useState, useEffect } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { matchPath } from 'react-router-dom';
import axios from 'axios';
import password from 'secure-random-password';
import routes from './routes';
import Resizer from 'react-image-file-resizer';

import config from './config';

const deploymentDomain = process.env.REACT_APP_DEPLOYMENT_DOMAIN;

const mailFormat =
  /^[a-zA-Z0-9.!#$%&'+/=?^_{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const locationsWithoutNavbar = [
  routes.login,
  routes.register,
  routes.completeProfile,
];

export const allowedVideoTypes = {
  'video/avi': 'avi',
  'video/msvideo': 'avi',
  'video/x-msvideo': 'avi',
  'video/avs-video': 'avi',
  'video/x-ms-asf': 'asf',
  'video/mpeg': 'mpeg',
  'video/ogg': 'ogg',
  'video/ogv': 'ogv',
  'video/mp4': 'mp4',
};

const locationsWithoutDashboardEventsFilter = [
  routes.accountProfile,
  routes.accountMyGoods,
  routes.accountCoins,
  routes.accountPayments,
  routes.leaderboard,
  routes.event,
  routes.room.replace(':roomId', ''),
  routes.roomSEO.replace(':slug', ''),
  routes.allCreators,
  routes.allGroups,
];

export const isEmailValid = (text) => {
  const matches = text.trim().match(mailFormat);

  if (matches && matches.length > 0) {
    return true;
  }

  return false;
};

export const isMobileNumberValid = (text) => {
  return isValidPhoneNumber(text.trim());
};

export const generatePassword = () => {
  return password.randomPassword({
    characters: [
      password.upper,
      password.digits,
      password.lower,
      password.symbols,
    ],
  });
};

export const shouldRenderNavbar = (pathname) => {
  return !locationsWithoutNavbar.includes(pathname) && pathname !== routes.base;
};

export const shouldRenderDashboardEventsFilter = (pathname) => {
  const pathIsRoomPage =
    matchPath(pathname, {
      path: routes.room,
      isExact: true,
    }) ||
    matchPath(pathname, {
      path: routes.roomSEO,
      isExact: true,
    });

  const pathIsGroupsPage = matchPath(pathname, {
    path: routes.groups,
    isExact: true,
  });

  return (
    !locationsWithoutDashboardEventsFilter.includes(pathname) &&
    !pathIsRoomPage &&
    !pathIsGroupsPage
  );
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);

      handleResize();

      return () => window.removeEventListener('resize', handleResize);
    }
  }, []);

  return windowSize;
};

export function getUserSidebarWidth() {
  const [sidebarWidth, setSidebarWidth] = useState();

  const getSidebarWidth = () => {
    const sidebar = document.getElementById('user-sidebar');
    if (sidebar) {
      setSidebarWidth(sidebar.offsetWidth);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', getSidebarWidth);

      getSidebarWidth();

      return () => window.removeEventListener('resize', getSidebarWidth);
    }
  }, []);
  return sidebarWidth;
}

export function getRoomSidebarWidth() {
  const [sidebarWidth, setSidebarWidth] = useState();

  const getSidebarWidth = () => {
    const sidebar = document.getElementById('rooms-sidebar');
    if (sidebar) {
      setSidebarWidth(sidebar.offsetWidth);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', getSidebarWidth);

      getSidebarWidth();

      return () => window.removeEventListener('resize', getSidebarWidth);
    }
  }, []);
  return sidebarWidth;
}

export const resizeImage = async (file, dimentions) => {
  const maxWidth = dimentions?.width || 1024;
  const maxHeight = dimentions?.height || 1024;
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      'JPEG',
      90,
      0,
      (file) => {
        resolve(file);
      },
      'file'
    );
  });
};

export const calculateScoreAfterAction = (action, userLevel, score) => {
  let power = 0;

  if (action.name === 'Clap') {
    power = 1;
  } else {
    power = action.defaultPower;
  }

  return power * userLevel + score;
};

export const getActionCooldown = (action, userLevel) => {
  if (action.name === 'Clap') {
    return userLevel < 30 ? 300 - Math.floor(userLevel / 5) * 50 : 50;
  }

  return action.defaultCooldown;
};

export const logError = (...args) => {
  if (config.debug) {
    console.log(...args);
  }
};

export const getVictoryStatus = (a = 0, b = 0) => {
  if (a > b) {
    return 'Victory!';
  }

  if (a < b) {
    return 'Defeat';
  }

  return 'Draw';
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getChimeData = async (
  eventUUID,
  accessCode,
  chimeRegionEndpoint,
  deploymentDomain
) => {
  try {
    if (!eventUUID) {
      throw new Error('Missing mixerEventUUID in the event');
    }

    if (!accessCode) {
      throw new Error('Missing accessToken');
    }

    const regionResp = await fetch(chimeRegionEndpoint);
    const { region } = await regionResp.json();

    if (!region) {
      throw new Error('Missing chime region');
    }

    const joinMeetingEndpoint = `${deploymentDomain}events/mixer/chime-join-meeting?mediaRegion=${region}`;
    const joinResp = await fetch(joinMeetingEndpoint, {
      method: 'GET',
      headers: {
        Authorization: `${eventUUID} ${accessCode}`,
        broadcasterOrigin: deploymentDomain,
      },
    });
    const { data } = await joinResp.json();

    return data;
  } catch (error) {
    logError('getChimeData', error);
    return null;
  }
};

export const messagingConnect = (
  wsUrl,
  eventUUID,
  accessCode,
  onBroadcasterJoin,
  chimeData
) => {
  const data = new URLSearchParams({
    eventId: eventUUID,
    accessCode: accessCode,
  })?.toString();

  wsUrl += `?${data}`;
  const sock = new WebSocket(wsUrl);

  sock.onopen = () => {
    const message = {
      topic: 'talent-listing',
      status: 'add',
      chimeAttendee: {
        ...chimeData.attendeeInfo.Attendee,
        metadata: { ...chimeData.attendeeRecord },
      },
      meetingRoom: 'live',
    };
    sock.send(JSON.stringify(message));
    // start keep-alive pinging interval job
    setInterval(() => {
      const message = {
        message: 'ping',
      };
      sock.send(JSON.stringify(message));
    }, 60 * 1000);
  };

  sock.onmessage = (event) => {
    const message = JSON.parse(event.data);

    if (message.topic === 'talent-listing' && message.status === 'add') {
      const data = message.chimeAttendee?.metadata || null;

      if (data && data.role === 'broadcast') {
        onBroadcasterJoin();
      }
    }
  };
};

export const listChimeAttendees = async (eventUUID, accessCode) => {
  const listAttendees = `${deploymentDomain}events/mixer/chime-list-attendees`;
  const listResp = await fetch(listAttendees, {
    method: 'GET',
    headers: {
      Authorization: `${eventUUID} ${accessCode}`,
      broadcasterOrigin: deploymentDomain,
    },
  });
  const { data } = await listResp.json();

  return data;
};

export const setStreamLiveStatus = async (eventUUID, accessCode) => {
  const setChimeEventProperites = `${deploymentDomain}events/mixer/chime-set-event-properties`;
  const headers = {
    Authorization: `${eventUUID} ${accessCode}`,
    'Content-Type': 'application/json',
  };

  const now = new Date();

  const data = {
    liveStartedTime: now.toISOString(),
    liveStatus: 'live',
  };

  const statusResp = await fetch(setChimeEventProperites, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });

  const res = await statusResp.json();

  return res;
};

export const leaveMeeting = async (params, eventUUID, accessCode) => {
  const data = new URLSearchParams(params)?.toString();

  try {
    await fetch(`${deploymentDomain}events/mixer/chime-leave-meeting?${data}`, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `${eventUUID} ${accessCode}`,
      },
    });

    // const resTojson = await res.json();
  } catch (error) {
    logError(error);
  }
};

export const checkHLSActive = async (url) => {
  try {
    const res = await axios.head(url);
    return /2\d\d/.test('' + res.status);
  } catch (err) {
    return false;
  }
};

export const boolToInt = (value = false) => {
  if (value) {
    return 1;
  }

  return 0;
};

export const intToBool = (value = 0) => {
  if (value === 1) {
    return true;
  }

  return false;
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const getCookie = (cname) => {
  const name = cname + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const setCurrentUserInLocalStorage = (user) => {
  if (!user) {
    localStorage.setItem('currentUser', null);
  } else {
    const userString = JSON.stringify({
      id: user.attributes.sub,
      cl_user_id: user.attributes.sub,
    });
    localStorage.setItem('currentUser', userString);
  }
};

export const getCurrentUserFromLocalStorage = () => {
  const userString = localStorage.getItem('currentUser');

  if (!userString) {
    return null;
  }

  try {
    const user = JSON.parse(userString);
    return user;
  } catch (error) {
    logError('Local Storage parse error');
    return null;
  }
};

export const formatDate = (date) => {
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return `${new Intl.DateTimeFormat('en-US', options).format(
    new Date(date)
  )} LT`;
};

export const formatGraphDate = (date) => {
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    // second: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return `${new Intl.DateTimeFormat('en-US', options).format(new Date(date))}`;
};

/* eslint-disable prettier/prettier */
const getOrdinalNumber = (number) => {
  return number > 0
    ? ["th", "st", "nd", "rd"][
        (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
      ]
    : "";
};

export const formatOrdinalDate = (date) => {
  const day = date.getDate();
  const ordinal = getOrdinalNumber(day);
  return `${day}${ordinal} of ${date.toLocaleString('en-us',{ month:'long', year:'numeric' })}`
};

export const getEndOfCurrentMonthDate = () => {
  const date = new Date();
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return 'Windows Phone';
  }

  if (/android/i.test(userAgent)) {
    return 'Android';
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'iOS';
  }

  return 'unknown';
};
