import React, { useContext, useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { UserContext } from '../../context/user';
import Icon from '../typography/Icon';
import themeColors from '../../theme-colors';
import { Link } from 'react-router-dom';
import routes from '../../routes';

const CreatorListItems = ({ creator, all }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div
      key={creator.id}
      style={{
        minWidth: 103,
      }}
      className={
        all
          ? 'flex col-span-3 justify-start'
          : 'flex mx-1 h-26 justify-center relative'
      }
    >
      <Link
        to={routes.room.replace(':roomId', creator.id).replace(':tab', 'all')}
        onClick={(e) => e.stopPropagation()}
      >
        {creator?.image?._url ? (
          <>
            <img
              src={creator.image._url}
              className={`${
                all ? 'w-20 h-20' : 'w-26 h-26'
              } rounded-full object-cover`}
              style={{
                minWidth: 80,
              }}
              onLoad={() => setLoaded(true)}
            />
            {!loaded && (
              <Skeleton
                circle={true}
                className={`${all ? 'w-20 h-20' : 'w-26 h-26'} absolute`}
              />
            )}
          </>
        ) : (
          <Skeleton
            circle={true}
            height={all ? 80 : 96}
            width={all ? 80 : 96}
            className={all ? 'w-20 h-20' : 'w-26 h-26'}
          />
        )}
      </Link>
      {all && (
        <div className='ml-4 mt-6 mr-2' style={{ width: 'calc(100% - 80px)' }}>
          <h6 className='font-display text-sm text-primaryC font-bold truncate w-full'>
            {creator.title}
          </h6>
          <h6 className='font-display text-primaryC text-xxs font-medium'>
            {creator.followers +
              `${creator.followers === 1 ? ' Follower' : ' Followers'}`}
          </h6>
        </div>
      )}
    </div>
  );
};

const CreatorList = ({ all }) => {
  const roomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);

  const { rooms, loadRooms, loading: roomsAreLoading } = roomsContext;
  const { getCurrentUserId } = userContext;

  const listRef = useRef(null);

  useEffect(async () => {
    await loadRooms(getCurrentUserId());
  }, []);

  const scrollLeft = () => {
    listRef.current.scrollLeft += 103;
  };

  const scrollRight = () => {
    listRef.current.scrollLeft -= 103;
  };

  return (
    <div
      className={
        all ? 'col-span-12' : 'flex flex-1 items-center justify-center'
      }
    >
      {!all && (
        <button
          onClick={scrollRight}
          className='w-12 h-12 flex items-center justify-center outline-none focus:outline-none active:outline-none'
        >
          <Icon name='back' size={24} color={themeColors.primaryA} />
        </button>
      )}
      <div
        ref={listRef}
        className={
          all
            ? 'relative grid gap-x-8 gap-y-16 mr-8 auto-rows-creator-row '
            : 'flex flex-nowrap px-2 overflow-hidden'
        }
      >
        {!roomsAreLoading &&
          rooms &&
          rooms.map((item) => {
            if (all || item.featured) {
              return (
                <CreatorListItems key={item.id} creator={item} all={all} />
              );
            }

            return null;
          })}
        {roomsAreLoading &&
          [...Array(all ? 20 : 10).keys()].map((i) => (
            <div
              className={`flex mx-1 h-26 justify-center cursor-pointer ${
                all ? 'col-span-3' : ''
              }`}
              key={i}
            >
              <Skeleton
                circle={true}
                height={all ? 80 : 96}
                width={all ? 80 : 96}
                className={all ? 'w-20 h-20' : 'w-26 h-26'}
              />
              {all && (
                <Skeleton height={30} width={160} className='mt-6 h-8 ml-4' />
              )}
            </div>
          ))}
      </div>
      {!all && (
        <button
          onClick={scrollLeft}
          className='w-12 h-12 flex items-center justify-center outline-none focus:outline-none active:outline-none'
        >
          <Icon name='forward' size={24} color={themeColors.primaryA} />
        </button>
      )}
    </div>
  );
};

export default CreatorList;
