import React from 'react';

import PromotedRoom from './PromotedRoom';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const PromotedRooms = ({ rooms }) => {
  if (rooms.length === 0) {
    return null;
  }

  return (
    <div className='grid grid-cols-12 gap-4'>
      {rooms.map((r) => (
        <PromotedRoom
          key={r.id}
          className='col-span-6 md:col-span-4 xl:col-span-3 mb-7'
          roomId={r.id}
          image={r.image ? `${imgBaseUrl}${r.image.key}?w=200` : ''}
          title={r.title}
        />
      ))}
    </div>
  );
};

export default PromotedRooms;
