import React, { useContext } from 'react';

import Modal from '../system/Modal';
import Button from '../common/Button';
import Paragraph from '../typography/Paragraph';
import { AlertsContext } from '../../context/alerts';
import { TransactionsContext } from '../../context/transactions';
import { UserContext } from '../../context/user';
import { InventoryContext } from '../../context/inventory';
import { useLogEvent } from '../../firebase';
import { EventsContext } from '../../context/events';

const BuyEventModal = ({
  data,
  onClose,
  onBuy = () => {},
  onDecline = () => {},
}) => {
  const alertsContext = useContext(AlertsContext);
  const transactionsContext = useContext(TransactionsContext);
  const userContext = useContext(UserContext);
  const inventoryContext = useContext(InventoryContext);
  const eventsContext = useContext(EventsContext);

  const { addAlert } = alertsContext;
  const { buyPPVEvent } = transactionsContext;
  const { getCurrentUserId, getCurrentUserDisplayName } = userContext;
  const { coins, updateCoins } = inventoryContext;
  const { getCurrentEvent } = eventsContext;
  const { logEvent } = useLogEvent();

  const onReject = () => {
    onClose();
    onDecline();
  };

  const onAccept = async () => {
    if (coins < data.price) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      onClose();
      return;
    }
    const newCoinsAmount = coins - data.price;
    const transaction = await buyPPVEvent(
      data.id,
      getCurrentUserId(),
      getCurrentUserDisplayName(),
      data.title
    );

    if (transaction.success) {
      updateCoins(newCoinsAmount);
      const eventData = await getCurrentEvent(data.id);
      const params = {
        category: 'monetization',
        virtual_currency_name: 'Coins',
        value: data.price,
        item_id: 'PPV',
        item_name: 'PPV',
        eventId: eventData.id,
        event_title: eventData.title,
      };
      logEvent('spend_virtual_currency', params);
      onBuy();
      addAlert('success', `You successfully purchased ${data?.title}`);
    }
    onClose();
  };

  const isVisible = data !== null;

  return (
    <Modal
      title='Request to access Pay Per View'
      actions={[
        <Button
          key='decline'
          buttonType='secondary'
          buttonSize='medium'
          buttonOnClick={onReject}
          classes='mr-4'
        >
          No
        </Button>,
        <Button
          key='accept'
          buttonType='primary'
          buttonSize='medium'
          buttonOnClick={onAccept}
        >
          Yes
        </Button>,
      ]}
      isVisible={isVisible}
      handleClose={onReject}
    >
      <div className='flex flex-col font-paragraph text-base mt-8'>
        <Paragraph classes='mb-4'>
          Access to <span className='font-bold'>{data?.title}</span> costs{' '}
          <span className='font-bold'>{data?.price} coins</span>
        </Paragraph>
        <Paragraph>
          Are you sure you want to pay{' '}
          <span className='font-bold'>{data?.price} coins</span>?
        </Paragraph>
      </div>
    </Modal>
  );
};

export default BuyEventModal;
