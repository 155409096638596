/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
  FacebookShareButton,
  FacebookIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

import ClickawayListener from '../system/ClickawayListener';

import { AlertsContext } from '../../context/alerts';
import { useLogEvent } from '../../firebase';
import Button from 'components/common/Button';
import DeepLinking from 'infrastructure/DeepLinking';

const Share = ({
  id,
  title,
  desc,
  url,
  buttonLabel = 'Share',
  positionClasses,
  CustomButton,
  contentType,
  img,
}) => {
  const alertsContext = useContext(AlertsContext);
  const { addAlert } = alertsContext;
  const { logEvent } = useLogEvent();

  const [isOpen, setIsOpen] = useState(false);
  const [sharingUrl, setSharingUrl] = useState();

  useEffect(() => {
    const linkData = {
      data: {
        $desktop_url: url.desktop,
        $og_title: title,
        $og_description: desc,
        $og_image_url: img,
        $twitter_title: title,
        $twitter_description: desc,
        $twitter_image_url: img,
        $ios_deeplink_path: url.ios,
        $android_deeplink_path: url.android,
      },
    };

    const cb = (err, data) => setSharingUrl(data);

    DeepLinking.createLink(linkData, cb);
  }, []);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sharingUrl);
    logShare(true, 'Link');
    addAlert('success', 'Copied!');
    closeShare();
  };

  const logShare = (e, method) => {
    const payload = {
      category: 'engagement',
      method,
      eventId: id,
      content_type: contentType,
      video_title: title,
      video_URL: url.desktop,
      provider: 'https://www.branch.io/',
      url: sharingUrl
    };

    if (e) {
      logEvent('social_share', payload);
    }
  };

  const openShare = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsOpen(true);
  };

  const closeShare = () => {
    setIsOpen(false);
  };

  return (
    <div className='relative'>
      {CustomButton ? (
        <CustomButton onClick={openShare} />
      ) : (
        <Button
          icon='share'
          buttonSize='small'
          buttonType='secondary'
          buttonOnClick={openShare}
        >
          {buttonLabel}
        </Button>
      )}

      {isOpen && (
        <ClickawayListener
          onClick={closeShare}
          classes={`absolute bg-white px-8 py-6 rounded-lg z-10 ${
            positionClasses ? positionClasses : 'top-12 -left-24'
          } shadow-card`}
        >
          <>
            <div className='base-input whitespace-nowrap max-w-lg w-full overflow-hidden overflow-ellipsis mb-6'>
              {sharingUrl}
            </div>
            <div className='flex items-center justify-center mb-6'>
              <Button
                buttonSize='small'
                buttonType='secondary'
                buttonOnClick={copyToClipboard}
              >
                Copy
              </Button>
            </div>
            <div className='flex items-center justify-center'>
              <FacebookShareButton
                onShareWindowClose={(e) => logShare(e, 'Facebook')}
                url={sharingUrl}
                className='mr-4'
              >
                <FacebookIcon size={38} round />
              </FacebookShareButton>
              <TwitterShareButton
                onShareWindowClose={(e) => logShare(e, 'Twitter')}
                url={sharingUrl}
                className='mr-4'
              >
                <TwitterIcon size={38} round />
              </TwitterShareButton>
              <WhatsappShareButton
                onShareWindowClose={(e) => logShare(e, 'WhatsApp')}
                url={sharingUrl}
                separator=':: '
                className='mr-4'
              >
                <WhatsappIcon size={38} round />
              </WhatsappShareButton>
              <RedditShareButton
                onShareWindowClose={(e) => logShare(e, 'Reddit')}
                url={sharingUrl}
                windowWidth={660}
                windowHeight={460}
              >
                <RedditIcon size={38} round />
              </RedditShareButton>
            </div>
          </>
        </ClickawayListener>
      )}
    </div>
  );
};

Share.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
  url: PropTypes.object,
  buttonLabel: PropTypes.string,
  CustomButton: PropTypes.func,
  positionClasses: PropTypes.string,
  img: PropTypes.string,
};

export default Share;
