import React, { useContext, useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import 'firebase/analytics';
import { Helmet } from 'react-helmet';

import Heading from '../typography/Heading';
import ShopItemSkeleton from '../../skeletons/ShopItemSkelleton';
import Modal from '../system/Modal';
import Button from '../common/Button';

import { CoinsContext } from '../../context/coins';
import { UserContext } from '../../context/user';
import { AlertsContext } from '../../context/alerts';
import { InventoryContext } from '../../context/inventory';

import routes from '../../routes';
import history from '../../history';
import { useLogEvent } from '../../firebase';

const Coins = () => {
  const coinsContext = useContext(CoinsContext);
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertsContext);
  const inventoryContext = useContext(InventoryContext);

  const { loadCoinPacks, getPaymentIntent, coinPacks, loading } = coinsContext;
  const {
    getCurrentUserDisplayName,
    getCurrentUserId,
    loadCurrentUser,
    getCurrentUserEmail,
  } = userContext;
  const { addAlert } = alertContext;
  const { setInventory } = inventoryContext;
  const { logEvent } = useLogEvent();

  const [selectedPackage, setSelectedPackage] = useState(false);
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  useEffect(async () => {
    if (coinPacks.length === 0) {
      await loadCoinPacks();
    }
  }, []);

  useEffect(() => {
    if (coinPacks.length) {
      logItemsList();
    }
  }, [coinPacks.length]);

  const logItemsList = () => {
    const items = itemsList(coinPacks);
    logEvent('view_item_list', { items });
  };

  const itemsList = (items) => {
    const list = [];
    items.forEach((item) => {
      list.push({
        item_id: item.id,
        item_name: item.title,
        item_category: 'CoinPack',
        price: item.priceInUSD,
        quantity: 1,
      });
    });
    return list;
  };

  const handlePackClick = (item) => {
    const email = getCurrentUserEmail();
    if (!email || (typeof email === 'string' && email.trim().length === 0)) {
      setShowEmailPopup(true);
      return;
    }
    const items = itemsList([item]);
    logEvent('view_item', {
      category: 'monetization',
      currency: 'USD',
      value: item.priceInUSD,
      items,
    });
    setSelectedPackage(item);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    const { error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      addAlert('danger', error.message);
      return;
    }

    const paymentIntent = await getPaymentIntent(
      selectedPackage,
      getCurrentUserId(),
      getCurrentUserDisplayName()
    );

    if (!paymentIntent || paymentIntent.length === 0) {
      addAlert('danger', `Could not initiate payment`);
      return;
    }

    const items = itemsList([selectedPackage]);
    logEvent('add_payment_info', {
      category: 'monetization',
      payment_type: 'Credit Card',
      currency: 'USD',
      value: selectedPackage.priceInUSD,
      items,
    });

    const paymentResult = await stripe.confirmCardPayment(
      paymentIntent.paymentIntent.client_secret,
      {
        payment_method: {
          card: cardElement,
        },
      }
    );

    closeModal(); // Modal must be closed when payment is finished

    if (paymentResult.error) {
      addAlert('danger', `${paymentResult.error.message}`);
      await loadCoinPacks();
      return;
    }

    addAlert(
      'success',
      `${paymentResult.paymentIntent.status} buying ${selectedPackage.title} for ${selectedPackage.priceInUSD} $`
    );

    logEvent('purchase', {
      category: 'monetization',
      transaction_id: paymentResult.paymentIntent.id,
      currency: 'USD',
      value: selectedPackage.priceInUSD,
      items,
    });

    logEvent('earn_virtual_currency', {
      category: 'monetization',
      virtual_currency_name: 'Coins',
      value: selectedPackage.numberOfCoins,
    });

    setTimeout(async () => {
      await loadCoinPacks();
    }, 1200);

    setTimeout(async () => {
      await loadCurrentUser(setInventory);
    }, 3500);
  };

  const closeModal = () => {
    setSelectedPackage(false);
  };

  const renderCoins = () => {
    if (loading) {
      return [
        <ShopItemSkeleton key={1} />,
        <ShopItemSkeleton key={2} />,
        <ShopItemSkeleton key={3} />,
      ];
    }

    if (coinPacks.length === 0) {
      return null;
    }

    return coinPacks.map((item) => {
      return (
        <div
          key={item.id}
          className='coins-item mr-16 mb-8'
          onClick={(event) => handlePackClick(item)}
          style={{ maxWidth: 278, width: '100%', height: 215 }}
        >
          <div
            className='w-full h-full py-3 px-4'
            style={{
              backgroundImage: `url(${item?.image?._url})`,
              backgroundPosition: 'right, left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            <Heading headingSize={1} classes='text-white mb-15'>
              {item.numberOfCoins}
            </Heading>
            <Heading headingSize={3} classes='text-white opacity-60'>
              {`$${item.priceInUSD}`}
            </Heading>
          </div>
        </div>
      );
    });
  };

  const CARD_OPTIONS = {
    iconStyle: 'solid',
    style: {
      base: {
        iconColor: '#5200e9',
        color: '#5200e9',
        fontWeight: 500,
        fontFamily: 'Inter, Helvetica, Arial, sans-serif',
        fontSize: '20px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#5200e9',
        },
        '::placeholder': {
          color: '#e4d5ff',
        },
      },
      invalid: {
        iconColor: '#ff004f',
        color: '#ff004f',
      },
    },
  };

  return (
    <div className='coins-container mb-8'>
      <Helmet>
        <title>Coins | ClashTV</title>
      </Helmet>
      <div className='flex justify-between mb-9'>
        <Heading headingSize={6} classes='font-bold tracking-tight'>
          Coins
        </Heading>
      </div>
      <div className='coins-section'>{renderCoins()}</div>
      <Modal
        title={'Pay with Credit Card'}
        message={`Total: $ ${selectedPackage.priceInUSD}` || 'Pay'}
        isVisible={!!selectedPackage}
        handleClose={closeModal}
      >
        <form onSubmit={handleSubmit}>
          <CardElement options={CARD_OPTIONS} className='mb-12' />
          <div className='flex justify-end items-center'>
            <Button
              key='pay-button'
              disabled={!stripe}
              type='primary'
              buttonSize='medium'
              onClick={handleSubmit}
            >
              Pay
            </Button>
          </div>
        </form>
      </Modal>
      <Modal
        title={'Warning'}
        message={
          'In order to receive a payment receipt you need to fill your e-mail address in your profile'
        }
        isVisible={showEmailPopup}
        handleClose={() => setShowEmailPopup(false)}
        actions={[
          <Button
            key='profile-button'
            disabled={false}
            type='primary'
            buttonSize='medium'
            buttonOnClick={() => {
              setShowEmailPopup(false);
              history.push(routes.accountProfile);
            }}
          >
            Go to Profile
          </Button>,
        ]}
      ></Modal>
    </div>
  );
};

export default Coins;
