import React, { useContext, useEffect, useState } from 'react';

import { EventsContext } from '../../context/events';
import { LeftDrawerContext } from '../../context/leftDrawer';
import Badge from '../common/Badge';
import CardContent from '../card/CardContent';
import Chat from '../chat/Chat';
import Stories from './Stories';
import StoryBar from './Storybar';

import history from '../../history';

import eventEndedPortaitVideo from '../../assets/Event_Ended_Portrait.mp4';

const EventPreview = ({ toggleDrawer }) => {
  const leftDrawerContext = useContext(LeftDrawerContext);
  const eventsContext = useContext(EventsContext);

  const { eventId } = leftDrawerContext;
  const { events, getCurrentEvent } = eventsContext;

  const [selectedEvent, setSelectedEvent] = useState(
    events.find((e) => e.id === eventId) ||
      history?.location?.state?.previewEvent
  );
  const [loading, setLoading] = useState(false);

  useEffect(async () => {
    if (!selectedEvent) {
      setLoading(true);
      const e = await getCurrentEvent(eventId, true);
      setSelectedEvent(e);
      setLoading(false);
    }
  }, []);

  if (loading) {
    return null;
  }

  if (!selectedEvent) {
    toggleDrawer();
    return null;
  }

  const allImages = [
    ...selectedEvent.imageUrls.landscape,
    ...selectedEvent.imageUrls.standard,
    ...selectedEvent.imageUrls.vertical,
  ];

  const [chatIsHidden, setChatIsHidden] = useState(true);
  const [activeStory, setActiveStory] = useState(0);
  const [teaserDuration, setTeaserDuration] = useState(10);
  const [videoDuration, setVideoDuration] = useState(120);

  const getHostName = (events) => {
    // Selecting the hostname by priority.
    let hostName;
    if (
      events?.hostSponsorRoom?.title !== null &&
      events?.hostSponsorRoom?.title !== undefined
    ) {
      hostName = events?.hostSponsorRoom?.title;
    } else if (
      events?.hostSponsorRoom?.displayName !== null &&
      events?.hostSponsorRoom?.displayName !== undefined
    ) {
      hostName = events?.hostSponsorRoom?.displayName;
    } else {
      hostName = events.hostName;
    }
    return hostName;
  };

  const toggleChat = (value) => {
    setChatIsHidden(value);
  };

  const generateStories = () => {
    const stories = [];

    stories.push({
      type: 'resetter',
    });

    if (selectedEvent.state === 'ended') {
      if (selectedEvent.archived && selectedEvent.videoUrl) {
        stories.push({
          type: 'video',
          videoType: 'video',
          controls: true,
          url: selectedEvent.videoUrl,
        });
      }
      stories.push({
        type: 'score',
        contestorAScore: selectedEvent.contestorAScore,
        contestorBScore: selectedEvent.contestorBScore,
        contestorA: selectedEvent.contestorA,
        contestorB: selectedEvent.contestorB,
      });
    }

    if (selectedEvent.teaserUrl) {
      stories.push({
        type: 'video',
        videoType: 'teaser',
        controls: false,
        url: selectedEvent.teaserUrl,
      });
    }

    for (const img of allImages) {
      stories.push({
        type: 'image',
        url: img,
      });
    }

    return stories;
  };

  const generatedStories = generateStories();

  const renderProgressbars = () => {
    const bars = [];
    let index = 0;

    const isActive = activeStory === 0;

    bars.push(
      <StoryBar
        resetter
        key={0}
        index={index}
        goToIndex={goToStory}
        goToNextIndex={goToNextStory}
        duration={1}
        isActive={isActive}
        shouldFill={true}
        shouldReset={false}
      />
    );

    index++;

    if (selectedEvent.state === 'ended') {
      if (selectedEvent.archived) {
        const isActive = activeStory === index;
        const shouldFill = !isActive && activeStory > index;
        const shouldReset = !isActive && activeStory < index;

        bars.push(
          <StoryBar
            key={index}
            index={index}
            goToIndex={goToStory}
            goToNextIndex={goToNextStory}
            duration={videoDuration}
            isActive={isActive}
            shouldFill={shouldFill}
            shouldReset={shouldReset}
          />
        );

        index++;
      }
      const isActive = activeStory === index;
      const shouldFill = !isActive && activeStory > index;
      const shouldReset = !isActive && activeStory < index;

      bars.push(
        <StoryBar
          key={index}
          index={index}
          goToIndex={goToStory}
          goToNextIndex={goToNextStory}
          duration={10}
          isActive={isActive}
          shouldFill={shouldFill}
          shouldReset={shouldReset}
        />
      );

      index++;
    }

    if (selectedEvent.teaserUrl) {
      // const index = selectedEvent.state === 'ended' ? 2 : 1;
      const isActive = activeStory === index;
      const shouldFill = !isActive && activeStory > index;
      const shouldReset = !isActive && activeStory < index;

      bars.push(
        <StoryBar
          key={index}
          index={index}
          goToIndex={goToStory}
          goToNextIndex={goToNextStory}
          duration={teaserDuration}
          isActive={isActive}
          shouldFill={shouldFill}
          shouldReset={shouldReset}
        />
      );

      index++;
    }

    const imageStoryBars = allImages.map((_, i) => {
      const storyIndex = index;
      index++;

      const isActive = activeStory === storyIndex;
      const shouldFill = !isActive && activeStory > storyIndex;
      const shouldReset = !isActive && activeStory < storyIndex;

      return (
        <StoryBar
          key={storyIndex}
          index={storyIndex}
          goToIndex={goToStory}
          goToNextIndex={goToNextStory}
          duration={10}
          isActive={isActive}
          shouldFill={shouldFill}
          shouldReset={shouldReset}
        />
      );
    });

    return [...bars, imageStoryBars];
  };

  const goToStory = (index) => {
    setActiveStory(index);
  };

  const goToNextStory = (index) => {
    if (!generatedStories[index]) {
      goToStory(0);
      return;
    }

    goToStory(index);
  };

  const shouldShowBgAnimation = () => {
    if (selectedEvent.state === 'ended') {
      if (
        selectedEvent.archived &&
        selectedEvent.videoUrl &&
        activeStory === 2
      ) {
        return true;
      }

      if (!selectedEvent.archived && activeStory === 1) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className='relative h-full'>
      {shouldShowBgAnimation() && (
        <div className='absolute -inset-10'>
          <video loop autoPlay muted height='100%' width='100%'>
            <source type='video/mp4' src={eventEndedPortaitVideo}></source>
          </video>
        </div>
      )}
      <div className='flex flex-col justify-between'>
        <div className='text-primaryD z-30'>
          <div className='progress-container flex'>{renderProgressbars()}</div>
          <div className='mb-5'>
            {selectedEvent.state && (
              <Badge
                badgeType={selectedEvent.state.toLowerCase()}
                classes='mr-2'
              >
                {selectedEvent.state}
              </Badge>
            )}
            <Badge badgeType='ghostWhite'>{getHostName(selectedEvent)}</Badge>
          </div>
          <CardContent
            theme='transparent'
            date={selectedEvent.startTime}
            title={selectedEvent.title}
            description={selectedEvent.description}
            classes='mb-6'
          />
          <div className='max-h-106 rounded-lg overflow-hidden'>
            <Stories
              stories={generatedStories}
              activeIndex={activeStory}
              setTeaserDuration={setTeaserDuration}
              setVideoDuration={setVideoDuration}
            />
          </div>
        </div>
        {!selectedEvent.disableChat && (
          <Chat
            eventId={eventId}
            isHidden={chatIsHidden}
            isHiddenFunc={toggleChat}
            classes={`bottom-4 absolute z-30 ${
              chatIsHidden ? 'max-h-64' : 'h-4/5'
            }`}
            inPreview={true}
            eventState={selectedEvent.state}
          />
        )}
      </div>
    </div>
  );
};

export default EventPreview;
