/* eslint-disable prettier/prettier */
import React, { useContext } from 'react';

import { UserContext } from 'context/user';
import { TransactionsContext } from 'context/transactions';

import Share from 'components/creator/Share';
import PurchaseEventButtons from 'components/event/PurchaseEventButtons';
import CardRoomAvatar from 'components/card/card-restyle/CardRoomAvatar';
import Heading from 'components/typography/Heading';

import ImageService from 'infrastructure/ImageService';
import FollowRoomButton from 'components/event/FollowRoomButton';
import Button from 'components/common/Button';

const EventActionBar = ({
  event,
  eventUrl,
  setActiveEndedTab,
  isOwnEvent,
  isPPVPurchased,
  onPurchase,
}) => {
  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);

  const { myPPVList } = transactionsContext;
  const { getCurrentUserId } = userContext;

  const shareImg = Array.isArray(event.imageUrls.standard)
    ? event.imageUrls.standard[0]
    : event.imageUrls.standard;

  const isEventEnded = event.state === 'ended';
  const getContentType = () => (isEventEnded ? 'video' : 'stream');

  const getIsEventRestricted = () => {
    const isPPVEvent = event.ppv === 1
    const isPurchased = !!myPPVList[event.id];
    const eventIsCreatedByCurrentUser = getCurrentUserId() === event?.hostSponsorRoom?.userID

    return isPPVEvent && !isPurchased && !eventIsCreatedByCurrentUser;
  };

  return (
    <div className='flex w-full mb-4'>
      <div className='flex flex-1 justify-between	'>
        <div className="flex gap-4 items-center">
          <CardRoomAvatar
            uri={`${ImageService.getImageByKey(event.hostSponsorRoom.image.key)}?w=48`}
            className='w-12 h-12'
          />
          <Heading
            headingSize={5}
            classes={`font-bold tracking-tight text-primaryC`}
          >
            {event.hostSponsorRoom.title}
          </Heading>
        </div>

        <div className="flex gap-2 items-center">
          <PurchaseEventButtons
            event={event}
            isOwn={isOwnEvent}
            isPurchased={isPPVPurchased}
            isRestricted={getIsEventRestricted()}
            onEndedEventClick={setActiveEndedTab}
            onPurchase={onPurchase}
          />

          <Share
            id={event.id}
            title={event.title}
            desc={event.description}
            url={eventUrl}
            img={shareImg}
            contentType={getContentType()}
            CustomButton={(props) => (
              <Button
                {...props}
                buttonType='outlined'
                buttonSize='small'
                icon='share2'
                iconSize={14}
                iconClasses='mr-2'
                buttonOnClick={props.onClick}
              >
                Share
              </Button>
            )}
            positionClasses={`share-position`}
          />

          <FollowRoomButton
            eventId={event.id}
            eventTitle={event.title}
            eventVideoUrl={event.videoUrl}
            roomId={event.hostSponsorRoom.id}
          />
        </div>
      </div>
    </div>
  )
}

export default EventActionBar;
