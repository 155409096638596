/* eslint-disable prettier/prettier */
import React, { useRef, useContext, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';

import Input from '../form/Input';
import SelectField from '../form/SelectField';
import DatePicker from '../form/DatePicker';
import ImagePicker from '../form/ImagePicker';
import VideoPicker from '../form/VideoPicker';
import Spinner from '../system/Spinner';
import Button from '../common/Button';
import Checkbox from '../form/Checkbox';
import DescriptionField from './DescriptionField';
import UserAutocomplete from './UserAutocomplete';

import { EventCategoriesContext } from '../../context/eventCategories';
import { EventsContext } from '../../context/events';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { AlertsContext } from '../../context/alerts';

import history from '../../history';
import routes from '../../routes';
import { logError, intToBool, boolToInt } from '../../helpers';
import { useLogEvent } from '../../firebase';
import { useLocation } from 'react-router-dom';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const Row = ({ children }) => {
  return (
    <div className='grid grid-cols-12 gap-4 col-span-full'>{children}</div>
  );
};

const EventInformation = ({ isNewEvent, eventId }) => {
  const eventCategoriesContext = useContext(EventCategoriesContext);
  const eventsContext = useContext(EventsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const alertsContext = useContext(AlertsContext);

  const { eventCategories } = eventCategoriesContext;
  const { getCurrentEvent, createEvent, updateEvent } = eventsContext;
  const { currentUserRoom } = sponsorRoomsContext;
  const { addAlert } = alertsContext;
  const { logEvent } = useLogEvent();

  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({
    id: '-',
    title: 'Select',
  });
  const [imagesUploadingState, setImagesUploadingState] = useState({
    image: false,
    image1: false,
  });
  const [teaserFileIsLoading, setTeaserFileIsLoading] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [eventState, setEventState] = useState('');
  const [dynamicParticipants, setDynamicParticipants] = useState(false);
  const [isPPV, setIsPPV] = useState(false);
  const [isCensored, setIsCensored] = useState(false);
  const [priceError, setPriceError] = useState(false);

  const titleRef = useRef(null);
  // const descriptionRef = useRef(null);
  const participant1Ref = useRef(null);
  const participant2Ref = useRef(null);
  const priceRef = useRef(null);

  const location = useLocation();

  useEffect(async () => {
    if (eventId) {
      setLoading(true);
      const event = await getCurrentEvent(eventId, true);

      if (event) {
        setEventData(event);
        setEventState(event.state);
        setDynamicParticipants(event.dynamicParticipants);
        setIsPPV(intToBool(event.ppv));
        setIsCensored(!!event.censorChat);
      }
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (eventData?.eventCategorisationID) {
      setSelectedCategory(eventData.eventCategorisation);
    }
  }, [eventData?.eventCategorisationID]);

  useEffect(() => {
    if (eventData?.startTime) {
      setStartTime(new Date(eventData?.startTime));
    }
  }, [eventData?.startTime]);

  const getCategories = () => {
    const categoryOptions = eventCategories.map((c) => ({
      text: c.title,
      onClick: () => {
        setSelectedCategory(c);
        handleAutoSave({ newCategory: c });
      },
    }));

    return [
      {
        text: 'Select',
        onClick: () => setSelectedCategory({ id: '-', title: 'Select' }),
      },
      ...categoryOptions,
    ];
  };

  const updateTimeAndDate = (date) => {
    const newDateValue = date ? new Date(date) : null;
    setStartTime(newDateValue);
    handleAutoSave({ newStartDate: newDateValue });
  };

  const handleCreateNewEvent = async (data) => {
    setLoading(true);
    const createData = {
      ...data,
      archived: false,
      paused: false,
      realm: 'A',
      contestorAScore: 0,
      contestorBScore: 0,
      scoreAnswerA: 0,
      scoreAnswerB: 0,
      scoreAnswerC: 0,
      startTimestamp: -1000, // inactive
      hostSponsorRoomID: currentUserRoom.id,
      dynamicParticipants: false,
      state: 'inactive',
    };

    const result = await createEvent(createData);
    setLoading(false);

    if (!result.success) {
      addAlert('danger', 'Something went wrong while creating the event');
      return;
    }

    history.push(`${routes.editMyEvent}/${result.event.id}/event`);
  };

  const handleUpdateEvent = async (data, images, teaserFile) => {
    if (images) {
      setImagesUploadingState({
        ...imagesUploadingState,
        [Object.keys(images)[0]]: true,
      });
    }

    if (teaserFile) {
      setTeaserFileIsLoading(true);
    }

    const result = await updateEvent(data, images, teaserFile);

    if (!result.success) {
      addAlert('danger', 'Something went wrong while updating the event');
      return;
    }

    if (result.updatedEvent) {
      const e = result.updatedEvent;
      let eventState = e.state;

      setEventData(result.updatedEvent);
      setEventState(eventState);

      if (
        (!eventData.title ||
          !e.eventCategorisationID ||
          !e.startTime ||
          new Date(e.startTime) <= new Date() ||
          (!e.image && !e.image2)) &&
        e.state !== 'inactive'
      ) {
        const res = await updateEvent({
          id: e.id,
          state: 'inactive',
          startTimestamp: -1000,
        });
        eventState = 'inactive';

        if (res.updatedEvent) {
          setEventData(res.updatedEvent);
          setEventState(eventState);
        }
      }
    }

    if (images) {
      setImagesUploadingState({
        ...imagesUploadingState,
        [Object.keys(images)[0]]: false,
      });
    }

    if (teaserFile) {
      setTeaserFileIsLoading(false);
    }
  };

  const getExistingImageUrl = (key) => {
    if (!key) {
      return null;
    }

    return `${imgBaseUrl}${key}`;
  };

  const handleImageSelect = async (event, imgField) => {
    const file = event?.target?.files?.[0] || null;

    if (file) {
      const fileType = file.type;

      if (!fileType.includes('image')) {
        logError(imgField, 'File is not an image');
        addAlert('danger', 'Please select an image file');
        return;
      }

      const newImage = {
        [imgField]: file,
      };

      handleAutoSave({ newImage });
    }
  };

  const handleVideoSelect = (file) => {
    handleAutoSave({ teaserFile: file });
  };

  const handleDynamicParticipants = () => {
    const value = dynamicParticipants;
    setDynamicParticipants(!value);
    if (value) {
      logEvent('disable_dynamic_participants', {
        eventId: eventId,
        category: 'creator',
      });
    } else {
      logEvent('enable_dynamic_participants', {
        eventId: eventId,
        category: 'creator',
      });
    }
    handleAutoSave({ newDynamicParticipantsValue: !value });
  };

  const handlePPV = () => {
    const value = isPPV;
    setIsPPV(!value);

    handleAutoSave({ ppv: boolToInt(!value) });
  };

  const handleCensor = () => {
    const value = isCensored;
    setIsCensored(!value);

    handleAutoSave({ censorChat: !value });
  };

  const handleAutoSave = ({
    newCategory,
    newStartDate,
    newImage,
    teaserFile,
    newDynamicParticipantsValue,
    ppv,
    descriptionPlainText,
    descriptionRichText,
    censorChat,
  }) => {
    const title = titleRef?.current?.value?.trim() || '';
    const contestorA = participant1Ref?.current?.value?.trim() || '';
    const contestorB = participant2Ref?.current?.value?.trim() || '';
    // const price = parseInt(priceRef?.current?.value || 0);

    if (!title || title.length === 0) {
      addAlert('danger', 'Events must have a title');
      return;
    }

    const data = {
      title,
      description: eventData?.description || '',
    };

    if (isNewEvent) {
      if (location?.state?.mode) {
        data.mode = location.state.mode;
      }
      handleCreateNewEvent(data);
      return;
    }

    if (eventData) {
      let shouldUpdate = false;

      if (title !== '') {
        data.title = title;
        shouldUpdate = true;
      }

      if (
        descriptionPlainText &&
        descriptionPlainText !== eventData.description
      ) {
        data.description = descriptionPlainText;
        shouldUpdate = true;
      }

      if (
        descriptionRichText &&
        descriptionRichText !== eventData.description
      ) {
        data.richTextDescription = descriptionRichText;
        shouldUpdate = true;
      }

      if (contestorA !== eventData.contestorA) {
        data.contestorA = contestorA;
        shouldUpdate = true;
      }

      if (contestorB !== eventData.contestorB) {
        data.contestorB = contestorB;
        shouldUpdate = true;
      }

      // if (
      //   eventData.ppv &&
      //   typeof price === 'number' &&
      //   price !== eventData.ppvPrice
      // ) {
      //   if (price > 0) {
      //     data.ppvPrice = price;
      //     shouldUpdate = true;
      //   } else {
      //     shouldUpdate = false;
      //     if (
      //       typeof parseInt(priceRef.current.value) === 'number' &&
      //       !isNaN(parseInt(priceRef.current.value))
      //     ) {
      //       addAlert('danger', 'Price must be a number bigger than 0');
      //     }
      //     priceRef.current.value = eventData.ppvPrice;
      //   }
      // }

      if (ppv !== eventData.ppv) {
        data.ppv = ppv;
        shouldUpdate = true;
      }

      if (censorChat !== eventData.censorChat) {
        data.censorChat = censorChat;
        shouldUpdate = true;
      }

      if (typeof newDynamicParticipantsValue !== 'undefined') {
        data.dynamicParticipants = newDynamicParticipantsValue;
        shouldUpdate = true;
      }

      if (
        selectedCategory &&
        selectedCategory.id !== '-' &&
        selectedCategory.id !== eventData.eventCategorisationID
      ) {
        data.eventCategorisationID = selectedCategory.id;
        shouldUpdate = true;
      }

      // handle autosave
      if (newCategory && newCategory.id !== '-') {
        data.eventCategorisationID = newCategory.id;
        shouldUpdate = true;
      }

      // handle autosave
      if (newStartDate !== undefined) {
        if (newStartDate) {
          data.startTime = newStartDate.toISOString();

          if (new Date() > newStartDate) {
            data.startTimestamp = -1000; // inactive
            data.state = 'inactive';
          }
        } else {
          data.startTime = null;
          data.startTimestamp = -1000; // inactive
          data.state = 'inactive';
        }
        shouldUpdate = true;
      }

      if (newImage || teaserFile) {
        shouldUpdate = true;
      }

      if (shouldUpdate) {
        data.id = eventData.id;
        handleUpdateEvent(data, newImage, teaserFile);
      }
    }
  };

  const autosavePrice = () => {
    const price = parseInt(priceRef?.current?.value || 0);

    if (
      eventData.ppv &&
      typeof price === 'number' &&
      price !== eventData.ppvPrice
    ) {
      if (price > 0) {
        setPriceError(false);
        handleUpdateEvent({ id: eventData.id, ppvPrice: price });
      } else {
        addAlert('danger', 'Price must be a number bigger than 0');
        setPriceError(true);
        // priceRef.current.value = eventData.ppvPrice;
        console.log(priceRef.current.value);
      }
    }
  };

  const scheduleEvent = async () => {
    if (eventState === 'upcoming') {
      return;
    }

    const startTimestamp = Math.round(startTime.getTime() / 1000);

    const result = await updateEvent({
      id: eventData.id,
      state: 'upcoming',
      startTimestamp,
    });

    if (!result.success) {
      addAlert('danger', 'Something went wrong while schedulig the event');
      return;
    }

    setEventData(result.updatedEvent);
    setEventState('upcoming');
    addAlert('success', 'Event scheduled successfully');
    logEvent('schedule_event', {
      eventId: eventId,
      category: 'creator',
      roomName: currentUserRoom.title,
    });
    logEvent('created_an_event', { eventId: eventId, category: 'engagement' });
  };

  const isEventSchedulingDisabled = () => {
    if (!eventData) {
      return true;
    }

    if (!eventData.title) {
      return true;
    }

    if (!eventData.eventCategorisationID) {
      return true;
    }

    if (!eventData.startTime) {
      return true;
    }

    if (new Date(eventData.startTime) <= new Date()) {
      return true;
    }

    if (!eventData.image && !eventData.image2) {
      return true;
    }

    return false;
  };

  const eventHasEnded =
    eventData && (eventState === 'ended' || eventData.archived);
  const eventIsLive = eventData && eventState === 'live';
  const eventIsUpcoming = eventData && eventState === 'upcoming';
  const eventIsDelayedLive = eventData && eventData.mode === 'delayedLive';

  const isFieldDisabled = (field) => {
    switch (field) {
      case 'title':
        return eventHasEnded || eventIsLive;
      case 'participant':
        return (
          isNewEvent || eventHasEnded || eventIsLive || dynamicParticipants
        );
      case 'ppv':
      case 'price':
        return isNewEvent || eventHasEnded || eventIsLive || eventIsUpcoming;
      case 'date':
        return (
          isNewEvent ||
          eventHasEnded ||
          eventIsLive ||
          (eventIsDelayedLive && eventIsUpcoming)
        );
      case 'moderator':
        return isNewEvent || eventHasEnded;
      default:
        return isNewEvent || eventHasEnded || eventIsLive;
    }
  };

  const renderScheduleButton = () => {
    if (eventState === 'live') {
      return null;
    }

    if (eventState === 'upcoming') {
      return (
        <Button buttonSize='medium' buttonType='primary' disabled={true}>
          Event is Scheduled
        </Button>
      );
    }

    return (
      <Button
        buttonSize='medium'
        buttonType='primary'
        buttonOnClick={scheduleEvent}
        disabled={isEventSchedulingDisabled() || eventHasEnded}
      >
        Schedule Event
      </Button>
    );
  };

  const handleModeratorSelection = (user) => {
    handleUpdateEvent({ id: eventData.id, moderatorID: user.id });
  };

  const handleModeratorClear = () => {
    handleUpdateEvent({ id: eventData.id, moderatorID: null });
  };

  return (
    <div className='col-span-full mt-8 mb-8 px-8 py-10 shadow-card rounded-lg'>
      <div className='grid grid-cols-12 gap-4'>
        <Row>
          <div className='col-span-6'>
            <Input
              id='event-name'
              setRef={titleRef}
              label={
                <>
                  <span data-tip='Name is required to create an event'>
                    Event Name *
                  </span>{' '}
                  <ReactTooltip effect='solid' className='react-tooltip' />
                </>
              }
              placeholder='Name'
              wrapClasses='mb-4'
              onBlur={handleAutoSave}
              initialValue={eventData?.title || ''}
              disabled={isFieldDisabled('title')}
            />
            <DescriptionField
              initialDescriptionValue={eventData?.description}
              richTextValue={eventData?.richTextDescription}
              onUpdate={handleAutoSave}
              disabled={isFieldDisabled('description')}
            />
          </div>
          <div className='col-span-6 mt-10'>
            <VideoPicker
              id='teaser'
              videoSrc={eventData?.teaserUrl || ''}
              thumbnailUrl={eventData?.teaserThumbnailUrl || ''}
              handleSelect={handleVideoSelect}
              disabled={isFieldDisabled('teaser')}
              loading={teaserFileIsLoading}
            />
          </div>
        </Row>
        <Row>
          <div className='col-span-6'>
            <SelectField
              label={
                <>
                  <span data-tip='Category is required to create an event'>
                    Category *
                  </span>{' '}
                  <ReactTooltip effect='solid' className='react-tooltip' />
                </>
              }
              value={selectedCategory.title}
              options={getCategories()}
              listFullWidth
              listMaxHeight={200}
              disabled={isFieldDisabled('category')}
            />
          </div>
          <div className='col-span-6 flex justify-center mt-10'>
            <ImagePicker
              id='image'
              imgSrc={getExistingImageUrl(eventData?.image?.key)}
              handleSelect={handleImageSelect}
              type='landscape'
              tooltip='Image is required to create an event'
              disabled={isFieldDisabled('image')}
              loading={imagesUploadingState['image']}
              containerClassName='mr-4'
              crop
            />
            <ImagePicker
              id='image2'
              imgSrc={getExistingImageUrl(eventData?.image2?.key)}
              handleSelect={handleImageSelect}
              type='landscape'
              disabled={isFieldDisabled('image')}
              loading={imagesUploadingState['image2']}
              containerClassName='mr-4'
              crop
            />
          </div>
        </Row>
        <Row>
          <div className='col-span-6'>
            <Input
              id='participant-1'
              setRef={participant1Ref}
              label='Participant #1'
              placeholder='Name'
              wrapClasses='mb-4'
              disabled={isFieldDisabled('participant')}
              onBlur={handleAutoSave}
              initialValue={eventData?.contestorA || ''}
            />
          </div>
          <div className='col-span-6'>
            <Input
              id='participant-2'
              setRef={participant2Ref}
              label='Participant #2'
              placeholder='Guest'
              wrapClasses='mb-4'
              disabled={isFieldDisabled('participant')}
              onBlur={handleAutoSave}
              initialValue={eventData?.contestorB || ''}
            />
          </div>
          <div className='col-span-full'>
            <Checkbox
              checkboxID='wild'
              checkboxName='wild'
              classes='col-span-4'
              checked={dynamicParticipants}
              toggle={handleDynamicParticipants}
              disabled={isFieldDisabled('dynamic-participants')}
            >
              <span
                className={`label-base ${
                  isFieldDisabled('dynamic-participants') ? 'disabled' : ''
                }`}
              >
                Dynamic Participants
              </span>{' '}
              (In battle mode display the names of the participants)
            </Checkbox>
          </div>
        </Row>
        <Row>
          <div className='col-span-6'>
            <DatePicker
              label={
                <>
                  <span data-tip='Date is required to schedule an event'>
                    Date and Time *
                  </span>{' '}
                  <ReactTooltip effect='solid' className='react-tooltip' />
                </>
              }
              value={startTime}
              setValue={updateTimeAndDate}
              className='mb-8'
              timeIntervals={1}
              disabled={isFieldDisabled('date')}
              minDate={new Date()}
            />
          </div>
          <div className='col-span-6'>
            <UserAutocomplete
              label='Moderator'
              tooltip='Assign moderator for sticky comments'
              initialvalue={eventData?.moderator}
              onSelect={handleModeratorSelection}
              onClear={handleModeratorClear}
              disabled={isFieldDisabled('moderator') || false}
            />
          </div>
          <Row>
            <div className='col-span-6 flex'>
              <Checkbox
                checkboxID='ppv'
                checkboxName='ppv'
                classes='col-span-4'
                checked={isPPV}
                toggle={handlePPV}
                disabled={isFieldDisabled('ppv')}
              >
                PPV
              </Checkbox>
            </div>
            <div className='col-span-6'>
              {isPPV && (
                <Input
                  id='price'
                  setRef={priceRef}
                  placeholder='Coins'
                  wrapClasses='mb-4'
                  disabled={isFieldDisabled('price')}
                  onBlur={autosavePrice}
                  initialValue={eventData?.ppvPrice || ''}
                  type='number'
                  error={priceError}
                />
              )}
            </div>
          </Row>
          <Row>
            <div className='col-span-6 flex'>
              <Checkbox
                checkboxID='censor'
                checkboxName='censor'
                classes='col-span-4'
                checked={isCensored}
                toggle={handleCensor}
                disabled={isFieldDisabled('censor')}
              >
                Enable Live Chat Filter
              </Checkbox>
            </div>
          </Row>
        </Row>
        <div className='flex items-center justify-center col-span-full'>
          {renderScheduleButton()}
        </div>
      </div>
      {(loading || (!isNewEvent && !eventData)) && (
        <div className='fixed inset-0 flex items-center justify-center'>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default EventInformation;
