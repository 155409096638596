/* eslint-disable prettier/prettier */
import React, { FC, useContext, useEffect } from 'react';

import { SponsorRoomsContext } from 'context/sponsorRooms';
import { UserContext } from 'context/user';

import Button from 'components/common/Button';
import { useLogEvent } from '../../firebase';

type Props = {
  roomId: string;
  eventId: string;
  eventTitle: string;
  eventVideoUrl: string | null;
};

const FollowRoomButton: FC<Props> = ({ roomId, eventId, eventTitle, eventVideoUrl }) => {
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);

  const { toggleFollowRoom, followedRooms, loadUserFollowings } = sponsorRoomsContext as any;
  const { getCurrentUserId, getCurrentUserDisplayName } = userContext as any;
  const { logEvent } = useLogEvent();

  const isFollowed = (followedRooms as { sponsorRoomID : string }[]).some(
    (room) => room.sponsorRoomID === roomId
  );

  useEffect(() => {
    loadUserFollowings(getCurrentUserId());
  }, []);

  const toggleFollow = () => {
    toggleFollowRoom(roomId, getCurrentUserId(), getCurrentUserDisplayName());

    if (!isFollowed) {
      logEvent('follow', {
        category: 'engagement',
        creator_id: roomId,
        video_title: eventTitle,
        video_URL: eventVideoUrl,
        eventId: eventId,
      });
    }
  };

  return (
    <Button
      buttonType='outlined'
      buttonSize='small'
      buttonOnClick={toggleFollow}
      icon={isFollowed ? 'check' : 'heart'}
      iconSize={14}
      iconClasses='mr-2'
    >
      {isFollowed ? 'Followed' : 'Follow'}
    </Button>
  );
};

export default FollowRoomButton;
