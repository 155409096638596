import React from 'react';
import { Helmet } from 'react-helmet';

import EditPhoneAndEmail from './EditPhoneAndEmail';
import ProfileAvatar from './ProfileAvatar';
import ProfileDetails from './ProfileDetails';
import DeleteAccount from './DeleteAccount';

const Profile = () => {
  return (
    <div className='relative col-span-full grid grid-cols-9 gap-8 mt-28'>
      <Helmet>
        <title>Profile | ClashTV</title>
      </Helmet>
      <div className='col-span-4 shadow-card rounded-lg'>
        <ProfileAvatar />
      </div>
      <div className='relative min-h-24 h-full p-8 col-span-5 shadow-card rounded-lg overflow-hidden'>
        <ProfileDetails />
      </div>
      <div className='relative px-8 py-10 col-span-full shadow-card rounded-lg overflow-hidden mb-8'>
        <EditPhoneAndEmail />
        <DeleteAccount />
      </div>
    </div>
  );
};

export default Profile;
