import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import GoodsButton from './GoodsButton';

const MinimizedChatMock = ({
  eventState,
  eventMode,
  maximize,
  className,
  joinIsEnabled,
}) => {
  const onInputFocus = () => {
    maximize();
  };

  const onSendButtonClick = () => {
    maximize();
  };

  const onStickersButtonClick = () => {
    maximize();
  };

  const onGoodsButtonClick = () => {
    maximize();
  };

  const onJoinButtonClick = () => {
    maximize();
  };

  const shouldShowJoinButton =
    joinIsEnabled && eventState === 'live' && eventMode === 'battle';

  return (
    <div className={`minimized-chat-mock-container ${className}`}>
      <div className='chat-mock-heading'>
        <Heading headingSize={6}>Live Chat</Heading>
        <button onClick={maximize} data-tip='Show chat'>
          <Icon name='show' size={17} />
          <ReactTooltip effect='solid' place='left' className='react-tooltip' />
        </button>
      </div>
      <div className='input-mock-container'>
        <input placeholder='Send a message' onFocus={onInputFocus} />
        <button className='chat-mock-button' onClick={onStickersButtonClick}>
          <Icon name='smile-emoji' size={24} />
        </button>
        <button className='chat-mock-button' onClick={onSendButtonClick}>
          <Icon name='submit' size={24} />
        </button>
      </div>
      <div className='chat-mock-bottom'>
        <GoodsButton onClick={onGoodsButtonClick} />
        {shouldShowJoinButton && (
          <button className='chat-mock-join-button' onClick={onJoinButtonClick}>
            <Icon name='plus' size={12} />
          </button>
        )}
      </div>
    </div>
  );
};

MinimizedChatMock.propTypes = {
  maximize: PropTypes.func,
  className: PropTypes.string,
};

MinimizedChatMock.defaultProps = {
  maximize: () => {},
  className: '',
};

export default MinimizedChatMock;
