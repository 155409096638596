// eslint-disable-next-line prettier/prettier
import awsExports from "aws-exports";

const getS3ImageObject = (key: string) => {
  return {
    bucket: awsExports['aws_user_files_s3_bucket'],
    region: awsExports['aws_user_files_s3_bucket_region'],
    key,
  };
};

export default {
  getS3ImageObject,
};
