import React, { useContext, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';

import Input from '../components/form/Input';
import SelectField from '../components/form/SelectField';
import ImagePicker from '../components/form/ImagePicker';
import Button from '../components/common/Button';
import MerchInput from '../components/creator/MerchInput';
import RoomFollowers from '../components/creator/RoomFollowers';
import Paragraph from '../components/typography/Paragraph';
import Modal from '../components/system/Modal';

import routes from '../routes';
import history from '../history';
import { logError } from '../helpers';

import { AlertsContext } from '../context/alerts';
import { SponsorRoomsContext } from '../context/sponsorRooms';
import { UserContext } from '../context/user';
import { RoomCategoriesContext } from '../context/roomCategories';

const MyRoom = () => {
  const alertsContext = useContext(AlertsContext);
  const roomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);
  const roomCategoriesContext = useContext(RoomCategoriesContext);

  const { addAlert } = alertsContext;
  const {
    createRoom,
    updateRoom,
    currentUserRoom,
    loading: roomIsLoading,
  } = roomsContext;
  const {
    user,
    loading: userIsLoading,
    getCurrentUserId,
    isCreator,
  } = userContext;
  const { roomCategories } = roomCategoriesContext;

  const [newLogo, setNewLogo] = useState(null);
  const [newCover, setNewCover] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState({
    id: '-',
    title: 'Select',
  });
  const [descriptionError, setDescriptionError] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [warningVisible, setWarningVisible] = useState(false);

  const merchRef = React.useRef(null);

  if (!userIsLoading && !user) {
    history.replace({
      pathname: routes.login,
      state: { from: history.location },
    });
  }

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  const roomIsLive = useMemo(() => {
    if (!currentUserRoom) {
      return false;
    }

    return !!currentUserRoom.isLive;
  }, [currentUserRoom]);

  useEffect(() => {
    if (currentUserRoom?.roomCategorisationID) {
      setSelectedCategory(currentUserRoom.roomCategorisation);
    }
  }, [currentUserRoom?.roomCategorisationID]);

  useEffect(() => {
    if (roomIsLoading) {
      return;
    }

    if (!roomIsLive) {
      setWarningVisible(true);
    }
  }, [roomIsLoading, roomIsLive]);

  const handleLogoSelect = async (event, imgField) => {
    const file = event?.target?.files?.[0] || null;

    if (file) {
      const fileType = file.type;

      if (!fileType.includes('image')) {
        logError(imgField, 'File is not an image');
        addAlert('danger', 'Please select an image file');
        return;
      }

      setNewLogo(file);
      handleAutoSave(null, file);
    }
  };

  const handleCoverSelect = async (event, imgField) => {
    const file = event?.target?.files?.[0] || null;

    if (file) {
      const fileType = file.type;

      if (!fileType.includes('image')) {
        logError(imgField, 'File is not an image');
        addAlert('danger', 'Please select an image file');
        return;
      }

      setNewCover(file);
      handleAutoSave(null, null, file);
    }
  };

  const handleCreateRoom = async (details) => {
    await createRoom(getCurrentUserId(), details);
    // setNewImage(null);
  };

  const handleUpdateRoom = async (details) => {
    await updateRoom(currentUserRoom.id, details);
    setNewLogo(null);
  };

  const handleAutoSave = (
    newCategory = null,
    logoFile = null,
    coverFile = null
  ) => {
    const roomIsCreated = !!currentUserRoom?.id;
    const details = {};
    const roomName = document.getElementById('room-name')?.value?.trim() || '';
    const roomDescription =
      document.getElementById('room-desc')?.value?.trim() || '';
    const merchUrl =
      document.getElementById('merchandise')?.value?.trim() || '';
    const merchIsValid = merchRef?.current?.state?.isValid || false;

    if (roomName.length > 0) {
      details.title = roomName;
    }

    if (newLogo) {
      details.logoFile = newLogo;
    }

    if (newCover) {
      details.coverFile = newCover;
    }

    // handle autosave of file
    if (logoFile) {
      details.logoFile = logoFile;
    }

    if (coverFile) {
      details.coverFile = coverFile;
    }

    if (selectedCategory && selectedCategory.id !== '-') {
      details.roomCategorisationID = selectedCategory.id;
    }

    // handle autosave of category select field
    if (newCategory && newCategory.id !== '-') {
      details.roomCategorisationID = newCategory.id;
    }

    if (roomDescription && roomDescription.length >= 200) {
      setDescriptionError('Description must be shroter than 200 symbols');
      return;
    }

    details.description = roomDescription;

    if (merchUrl.length === 0 || (merchUrl.length > 0 && merchIsValid)) {
      details.merchUrl = merchUrl;
    }

    if (descriptionError) {
      setDescriptionError(null);
    }

    if (roomIsCreated) {
      handleUpdateRoom(details);
      return;
    }

    handleCreateRoom(details);
  };

  const getCategoryOptions = () => {
    const categoryOptions = roomCategories.map((c) => ({
      text: c.title,
      onClick: () => {
        setSelectedCategory(c);
        handleAutoSave(c, null);
      },
    }));

    return [
      {
        text: 'Select',
        onClick: () => setSelectedCategory({ id: '-', title: 'Select' }),
      },
      ...categoryOptions,
    ];
  };

  const goToNewEvent = () => {
    history.push(routes.newEvent);
  };

  const closeWarning = () => {
    setWarningVisible(false);
  };

  return (
    <>
      <Helmet>
        <title>My Room | ClashTV</title>
      </Helmet>
      <div className='col-span-8 mt-8 px-8 py-10 shadow-card rounded-lg'>
        <RoomFollowers roomId={currentUserRoom?.id} />
      </div>
      <div className='col-span-8 mt-8 px-8 py-10 shadow-card rounded-lg mb-10'>
        <div className='flex items-center justify-end mb-8'>
          <Button
            buttonType='primary'
            buttonSize='small'
            disabled={currentUserRoom?.isLive !== true}
            buttonOnClick={goToNewEvent}
          >
            Create New Event
          </Button>
        </div>
        <div>
          <div className='flex justify-center'>
            <div>
              <div className='label-base mb-5'>Logo</div>
              <ImagePicker
                id='logo'
                imgSrc={currentUserRoom?.image?._url}
                handleSelect={handleLogoSelect}
                type='square'
                tooltip='Logo is required to create a room'
                aspectRatio='none'
                containerClassName='mr-4'
              />
            </div>
            <div>
              <div className='label-base mb-5'>Cover</div>
              <ImagePicker
                id='cover'
                imgSrc={currentUserRoom?.coverPhoto?._url}
                handleSelect={handleCoverSelect}
                type='square'
                aspectRatio='none'
                containerClassName='mr-4'
              />
            </div>
          </div>
          <Input
            id='room-name'
            label={
              <>
                <span data-tip='Name is required to create a room'>
                  Room Name *
                </span>{' '}
                <ReactTooltip effect='solid' className='react-tooltip' />
              </>
            }
            placeholder='Write a room name here'
            wrapClasses='mb-4'
            onBlur={handleAutoSave}
            initialValue={currentUserRoom?.title || ''}
          />
          <Input
            id='room-desc'
            label='Description'
            placeholder='Write a room description here'
            wrapClasses='mb-4'
            onBlur={handleAutoSave}
            initialValue={currentUserRoom?.description || ''}
            maxLength={200}
            error={!!descriptionError}
            hint={descriptionError || ''}
          />
          <SelectField
            label={
              <>
                <span data-tip='Category is required to create a room'>
                  Category *
                </span>{' '}
                <ReactTooltip effect='solid' className='react-tooltip' />
              </>
            }
            value={selectedCategory.title}
            options={getCategoryOptions()}
            listFullWidth
            listMaxHeight={200}
          />
          <MerchInput
            ref={merchRef}
            onBlur={handleAutoSave}
            initialValue={currentUserRoom?.merchUrl || ''}
          />
        </div>
      </div>
      <Modal
        title='My Room Warning'
        actions={[
          <Button
            key='accept'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={closeWarning}
          >
            OK
          </Button>,
        ]}
        isVisible={warningVisible}
        handleClose={closeWarning}
      >
        <div className='flex flex-col font-paragraph text-base mt-8'>
          <Paragraph>
            Please fill out mandatory fields:{' '}
            <span className='font-bold'>Logo Image</span>,{' '}
            <span className='font-bold'>Room Name</span>,{' '}
            <span className='font-bold'>Category</span>
          </Paragraph>
        </div>
      </Modal>
    </>
  );
};

export default MyRoom;
