import React from 'react';
import PropTypes from 'prop-types';

import Modal from './Modal';
import Button from '../common/Button';

const LeaveStreamDialog = ({ onClose, onSubmit, isVisible }) => {
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      title={
        <span>
          <span>Are you sure you want to</span>
          <br />
          <br />
          <span>leave the stream?</span>
        </span>
      }
      actions={[
        <Button
          key='dismiss'
          buttonType='secondary'
          buttonSize='medium'
          buttonOnClick={onClose}
          classes='mr-4'
        >
          No
        </Button>,
        <Button
          key='leave'
          buttonType='primary'
          buttonSize='medium'
          buttonOnClick={handleSubmit}
        >
          Yes
        </Button>,
      ]}
      isVisible={isVisible}
      handleClose={onClose}
      headingSize={4}
    />
  );
};

LeaveStreamDialog.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

LeaveStreamDialog.defaultProps = {
  isVisible: false,
  onClose: () => {},
  onSubmit: () => {},
};

export default LeaveStreamDialog;
