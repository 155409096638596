/* eslint-disable prettier/prettier */
import React, { FC } from 'react';

import Badge from 'components/common/Badge';

type Props = {
  status: string;
  className?: string;
};

const EventStatus: FC<Props> = ({ status, className }) => {
  const classes = [
    'absolute',
    'top-4',
    'right-5',
    'px-3.5',
    'capitalize',
    'min-w-[68px]'
  ];

  return (
    <Badge
      badgeType={status.toLowerCase()}
      classes={
        className
        ? classes.concat(className).join(' ')
        : classes.join(' ')
    }
    >
      {status}
    </Badge>
  );
};

export default EventStatus;
