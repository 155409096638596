import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

const AvatarShopItem = ({
  id,
  image,
  price,
  title,
  onClick,
  isInInventory,
}) => {
  const handleClick = () => {
    if (isInInventory) {
      return;
    }

    onClick(id);
  };

  return (
    <div
      className={`avatar-shop-item ${isInInventory ? 'in-inventory' : ''}`}
      onClick={handleClick}
      data-tip={title}
    >
      <img
        src={image}
        className={`avatar-shop-item-image ${
          isInInventory ? 'in-inventory' : ''
        }`}
      />
      {isInInventory ? (
        <div className='avatar-shop-item-price-container in-inventory'>
          You already have
          <br />
          this avatar!
        </div>
      ) : (
        <div className='avatar-shop-item-price-container'>
          Get for
          <br />
          <span>{`${price} coins`}</span>
        </div>
      )}
      <ReactTooltip place='right' effect='solid' className='react-tooltip' />
    </div>
  );
};

AvatarShopItem.propTypes = {
  id: PropTypes.string,
  image: PropTypes.string,
  price: PropTypes.number,
  title: PropTypes.string,
  onClick: PropTypes.func,
  isInInventory: PropTypes.bool,
};

export default AvatarShopItem;
