/* eslint-disable new-cap */

import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Heading from '../typography/Heading';
import ChatInput from './ChatInput';
import ChatMessage from './ChatMessage';
import Icon from '../typography/Icon';
import Tooltip from '../system/Tooltip';
import StickerMenu from './StickerMenu';
import ParticipantsList from '../creator/ParticipantsList';
import PollsContainer from '../polls/PollsContainer';
import UserPoll from '../polls/UserPoll';
import MyGoodsMenu from './MyGoodsMenu';
import RequestToJoinDialog from '../event/RequestToJoinDialog';
import GoodsButton from '../event/GoodsButton';
import DonationButton from '../../donations/DonationButton';
import DonationDialog from '../../donations/DonationDialog';

import { ChatContext } from '../../context/chat';
import { UserContext } from '../../context/user';
import { StickersContext } from '../../context/stickers';
import { EventsContext } from '../../context/events';
import { ParticipantsContext } from '../../context/participants';
import UserReportsProvider from '../../context/UserReportsProvider';

import { useLogEvent } from '../../firebase';
import { StickyCommentsContext } from '../../context/stickyComments';

const isToday = (date) => {
  const today = new Date();
  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

const formatDate = (date) => {
  const options = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return `${new Intl.DateTimeFormat('en-US', options).format(new Date(date))}`;
};

const getDate = (date) => {
  if (!isToday(new Date(date))) {
    return formatDate(date);
  }

  return new Date(date).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
};

const Chat = ({
  eventId,
  isHidden,
  isHiddenFunc,
  classes,
  inPreview,
  inEvent,
  inCreator,
  disabled,
  minimizable,
  eventState,
  mediaContainerRef,
  isCensored,
  moderatorName,
  moderatorId,
  eventCreatorId,
}) => {
  const chatContext = useContext(ChatContext);
  const userContext = useContext(UserContext);
  const stickersContext = useContext(StickersContext);
  const eventsContext = useContext(EventsContext);
  const participantsContext = useContext(ParticipantsContext);
  const stickyCommentsContext = useContext(StickyCommentsContext);

  const {
    chatMessages: messages,
    loading: loadingMessages,
    allUsers,
    chatNextTokens,
    loadMessages,
    loadMoreMessages,
    createNewMessage,
    removeSubscribtion,
  } = chatContext;
  const {
    getCurrentUserId,
    getCurrentUserDisplayName,
    getCurrentUserImageKey,
    getCurrentUserLevel,
  } = userContext;
  const { stickers, loadStickers } = stickersContext;
  const { getCurrentEvent } = eventsContext;
  const { updateParticipant } = participantsContext;
  const {
    stickyComment,
    loadStickyCommentByEventId,
    clear: clearStickyComment,
  } = stickyCommentsContext;
  const { logEvent } = useLogEvent();

  const [isStickersMenuOpen, setIsStickersMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('chat');
  const [raiseHandIsEnabled, enableRaiseHand] = useState(false);
  const [shouldShowScrollButton, setShouldShowScrollButton] = useState(false);
  const [goodsMenuIsOpen, setGoodsMenuIsOpen] = useState(false);
  const [requestToJoinVisible, setRequestToJoinVisible] = useState(false);
  const [showDonationDialog, setShowDonationDialog] = useState(false);

  const lastScrollDistanceToBottomRef = useRef();
  const scrollableRootRef = useRef(null);
  const chatBottomRef = useRef(null);

  const loadMore = async () => {
    await loadMoreMessages(eventId);
  };

  const handleRootScroll = useCallback(() => {
    const bottomNode = chatBottomRef.current;
    if (bottomNode) {
      const rect = bottomNode.getBoundingClientRect();

      const isInView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth);

      setShouldShowScrollButton(!isInView);
    }
  }, []);

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: loadingMessages,
    hasNextPage: chatNextTokens[eventId] || false,
    onLoadMore: loadMore,
  });

  const rootRefSetter = useCallback(
    (node) => {
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef]
  );

  useEffect(async () => {
    const event = await getCurrentEvent(eventId);

    if (!event || event?.mode !== 'battle' || !event.hostSponsorRoomID) {
      return;
    }

    if (event?.hostSponsorRoom?.userID !== getCurrentUserId()) {
      enableRaiseHand(true);
    }
  }, []);

  useEffect(() => {
    return () => {
      clearStickyComment();
    };
  }, []);

  useEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    const lastScrollDistanceToBottom =
      lastScrollDistanceToBottomRef.current ?? 0;

    if (scrollableRoot) {
      scrollableRoot.scrollTop =
        scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
    }
  }, [messages, rootRef]);

  const goToBottom = () => {
    if (chatBottomRef.current) {
      chatBottomRef.current.scrollIntoView({
        block: 'nearest',
        inline: 'center',
        behavior: 'smooth',
        alignToTop: false,
      });
    }
  };

  useEffect(async () => {
    await loadMessages(eventId);
    await loadStickyCommentByEventId(eventId);

    if (stickers.length === 0) {
      await loadStickers();
    }

    return () => removeSubscribtion(eventId);
  }, []);

  const handleSubmit = async (text, stickerId, logMessage = true) => {
    if (!text && !stickerId) {
      return;
    }

    const userId = getCurrentUserId();
    const userDisplayName = getCurrentUserDisplayName();
    const userImageKey = getCurrentUserImageKey();
    const userLevel = getCurrentUserLevel();
    const content = text.trim();

    await createNewMessage(
      userId,
      eventId,
      content,
      stickerId,
      userDisplayName,
      userImageKey,
      userLevel
    );
    if (logMessage) {
      logEvent('post_comment', { eventId, category: 'engagement' });
    }

    setTimeout(() => goToBottom(), 500);
  };

  const handleStickerSubmit = (stickerId) => {
    handleSubmit('', stickerId, false);
    logEvent('post_sticker', { eventId, category: 'engagement' });
  };

  const handleToggle = (e) => {
    e.stopPropagation();
    goToBottom();
    isHiddenFunc(!isHidden);
  };

  const toggleStickersFromInput = () => {
    if (!isStickersMenuOpen && isHidden) {
      isHiddenFunc(false);
    }

    setIsStickersMenuOpen(!isStickersMenuOpen);
  };

  const getClasses = () => {
    if (inPreview) {
      return 'in-preview';
    }

    if (inEvent) {
      return 'in-event';
    }

    if (inCreator) {
      let classes = 'in-creator';

      if (minimizable) {
        classes += ' minimizable';
      }

      return classes;
    }
  };

  const raiseHand = () => {
    handleSubmit('#raise_hand', '', false);
    updateParticipant(eventId, getCurrentUserId(), { raisedHand: true });
    logEvent('raise_hand', { eventId, category: 'consumer' });
  };

  const getInputPlaceholder = () => {
    if (disabled && messages[eventId] && messages[eventId].length > 0) {
      return 'Chat is disabled';
    }
    return 'Send a message';
  };

  const renderInEventHeading = () => {
    if (inEvent) {
      return (
        <div className='chat-header'>
          <Heading
            headingSize={6}
            classes='font-display font-bold tracking-tight'
          >
            Live Chat
          </Heading>
          <button className='chat-header-btn' onClick={handleToggle}>
            {isHidden ? (
              <>
                <Icon name='show' size={17} />
                <Tooltip
                  arrowSide='right-arrow'
                  txt='Show chat'
                  classes='tooltip-chat-header'
                />
              </>
            ) : (
              <>
                <Icon name='hide' size={17} />
                <Tooltip
                  arrowSide='right-arrow'
                  txt='Hide chat'
                  classes='tooltip-chat-header'
                />
              </>
            )}
          </button>
        </div>
      );
    }

    return null;
  };

  const renderInPreviewHeading = () => {
    if (inPreview) {
      return (
        <div className='chat-header'>
          <Heading
            headingSize={5}
            classes='font-display font-bold tracking-tight'
          >
            Comments
          </Heading>
          <button className='chat-header-btn' onClick={handleToggle}>
            {isHidden ? (
              <>
                <Icon name='show' size={17} />
                <Tooltip
                  arrowSide='right-arrow'
                  txt='Show chat'
                  classes='tooltip-chat-header'
                />
              </>
            ) : (
              <>
                <Icon name='hide' size={17} />
                <Tooltip
                  arrowSide='right-arrow'
                  txt='Hide chat'
                  classes='tooltip-chat-header'
                />
              </>
            )}
          </button>
        </div>
      );
    }

    return null;
  };

  const renderInCreatorHeading = () => {
    if (inCreator) {
      let text = 'Live Chat';

      if (activeTab === 'participants') {
        text = 'Participants';
      }

      if (activeTab === 'poll') {
        text = 'Polls';
      }

      return (
        <div className='chat-header'>
          <Heading
            headingSize={5}
            classes='font-display font-bold tracking-tight'
          >
            {text}
          </Heading>
          {minimizable && (
            <button className='chat-header-btn' onClick={handleToggle}>
              {isHidden ? (
                <>
                  <Icon name='show' size={17} />
                  <Tooltip
                    arrowSide='right-arrow'
                    txt='Show chat'
                    classes='tooltip-chat-header'
                  />
                </>
              ) : (
                <>
                  <Icon name='hide' size={17} />
                  <Tooltip
                    arrowSide='right-arrow'
                    txt='Hide chat'
                    classes='tooltip-chat-header'
                  />
                </>
              )}
            </button>
          )}
        </div>
      );
    }

    return null;
  };

  const renderChatDisabledMessage = () => {
    if (
      activeTab === 'chat' &&
      (!messages[eventId] || messages[eventId].length === 0) &&
      disabled
    ) {
      return (
        <div className='chat-message in-creator'>
          Chat will be active as soon as the event is scheduled!
        </div>
      );
    }

    return null;
  };

  const renderChatMessages = () => {
    if (activeTab === 'chat') {
      return (
        <div
          ref={rootRefSetter}
          id='chatBody'
          className={`chat-body ${disabled ? 'opacity-50' : ''}`}
          onScroll={handleRootScroll}
        >
          {chatNextTokens[eventId] && (
            <div className='chat-message' ref={infiniteRef}></div>
          )}
          {messages[eventId] &&
            messages[eventId].map((message) => {
              const user = allUsers[message.userID];
              const sticker = stickers.find((s) => s.id === message.stickerID);
              const showReport =
                !message.stickerID && message.userID !== getCurrentUserId();
              const showPinAction =
                moderatorId === getCurrentUserId() ||
                eventCreatorId === getCurrentUserId();
              return (
                <ChatMessage
                  key={message.id}
                  time={message.createdAt}
                  avatar={user?.image?._url || ''}
                  level={user?.level}
                  userName={user?.displayName}
                  msg={message.content}
                  stickerId={message.stickerID}
                  stickerUrl={sticker?.image?._url}
                  inPreview={inPreview}
                  inEvent={inEvent}
                  inCreator={inCreator}
                  messageUserId={message.userID}
                  showReport={showReport}
                  showCensored={isCensored}
                  censoredMsg={message.censoredContent}
                  showPinAction={showPinAction}
                  eventId={eventId}
                />
              );
            })}
          <div
            ref={chatBottomRef}
            id='chat-bottom'
            className='h-0 w-full border-black'
          ></div>
        </div>
      );
    }

    return null;
  };

  const renderChatFooter = () => {
    return (
      <div className={`chat-footer ${getClasses()}`}>
        {activeTab === 'chat' && scrollableRootRef.current && (
          <button
            className={`chat-go-bottom ${
              shouldShowScrollButton
                ? 'opacity-1 visible'
                : 'invisible opacity-0'
            }`}
            onClick={() => goToBottom()}
          >
            <Icon name='selector' size={15} classes='m-auto' />
          </button>
        )}
        <div className='flex flex-col flex-1'>
          {activeTab === 'chat' && (
            <div
              className={`flex flex-1 items-center ${
                disabled ? 'opacity-50' : ''
              } ${inEvent ? 'border border-primaryC rounded-lg' : ''}`}
            >
              <ChatInput
                stickerFunc={toggleStickersFromInput}
                stickerState={isStickersMenuOpen}
                onSubmit={handleSubmit}
                inPreview={inPreview}
                inEvent={inEvent}
                inCreator={inCreator}
                disabled={disabled}
                placeholder={getInputPlaceholder()}
              />
            </div>
          )}
          {inEvent && (
            <div className='flex flex-1 items-center justify-between mt-4'>
              <div className='flex'>
                <GoodsButton onClick={() => setGoodsMenuIsOpen(true)} />
                <DonationButton
                  className='ml-4'
                  onClick={() => setShowDonationDialog(true)}
                  eventId={eventId}
                />
              </div>
              {raiseHandIsEnabled && eventState === 'live' && (
                <button
                  className='join-button'
                  onClick={() => setRequestToJoinVisible(true)}
                >
                  Join the stream
                </button>
              )}
            </div>
          )}
          {inCreator && (
            <div className='flex flex-1 mt-4 pl-4'>
              <button
                onClick={() => setActiveTab('chat')}
                className={`creator-chat-button ${
                  activeTab === 'chat' ? 'active' : ''
                }`}
              >
                Chat
              </button>
              <button
                onClick={() => setActiveTab('participants')}
                className={`creator-chat-button ${
                  activeTab === 'participants' ? 'active' : ''
                }`}
              >
                Participants
              </button>
              <button
                onClick={() => setActiveTab('poll')}
                className={`creator-chat-button ${
                  activeTab === 'poll' ? 'active' : ''
                }`}
              >
                Polls
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderParticipants = () => {
    if (inCreator && activeTab === 'participants') {
      return <ParticipantsList eventId={eventId} disabled={disabled} />;
    }
  };

  const renderPolls = () => {
    if (inCreator && activeTab === 'poll') {
      return <PollsContainer eventId={eventId} disabled={disabled} />;
    }
  };

  return (
    <div className={`chat ${getClasses()} ${classes}`}>
      {renderInEventHeading()}
      {renderInPreviewHeading()}
      {renderInCreatorHeading()}
      {stickyComment && (
        <div className='sticky-comment-container'>
          <p className='sticky-label'>Pinned by {moderatorName}</p>
          <p className='sticky-comment'>{stickyComment.text}</p>
          <p className='comment-by'>
            <span className='user'>
              {stickyComment.originalCommentDisplayName}
            </span>{' '}
            sent at {getDate(stickyComment.originalCommentTime)}
          </p>
        </div>
      )}
      {renderChatDisabledMessage()}
      <UserReportsProvider>{renderChatMessages()}</UserReportsProvider>
      {renderParticipants()}
      {renderPolls()}
      {inEvent && eventState === 'live' && <UserPoll eventId={eventId} />}
      {renderChatFooter()}
      <StickerMenu
        isOpen={isStickersMenuOpen}
        handleClose={() => setIsStickersMenuOpen(false)}
        stickers={stickers}
        submitSticker={handleStickerSubmit}
        inPreview={inPreview}
        inEvent={inEvent}
        inCreator={inCreator}
      />
      <MyGoodsMenu
        isOpen={goodsMenuIsOpen}
        handleClose={() => setGoodsMenuIsOpen(false)}
      />
      <RequestToJoinDialog
        isVisible={requestToJoinVisible}
        onClose={() => setRequestToJoinVisible(false)}
        onAccept={raiseHand}
        node={mediaContainerRef}
      />
      <DonationDialog
        isOpen={showDonationDialog}
        onClose={() => setShowDonationDialog(false)}
        eventId={eventId}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

Chat.propTypes = {
  eventId: PropTypes.string.isRequired,
  isHidden: PropTypes.bool,
  isHiddenFunc: PropTypes.func,
  classes: PropTypes.string,
  inPreview: PropTypes.bool,
  inEvent: PropTypes.bool,
  inCreator: PropTypes.bool,
  disabled: PropTypes.bool,
  minimizable: PropTypes.bool,
  eventState: PropTypes.string,
  isCensored: PropTypes.bool,
  moderatorName: PropTypes.string,
  moderatorId: PropTypes.string,
  eventCreatorId: PropTypes.string,
};

Chat.defaultProps = {
  isHidden: true,
  isHiddenFunc: () => {},
  classes: '',
  inPreview: false,
  inEvent: false,
  inCreator: false,
  disabled: false,
  minimizable: false,
  eventState: '',
  isCensored: false,
  moderatorName: 'Moderator',
  moderatorId: null,
  eventCreatorId: null,
};

export default Chat;
