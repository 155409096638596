import React, { useState, useRef, useContext } from 'react';

import Heading from '../typography/Heading';
import Input from '../form/Input';
import Button from '../common/Button';
import Spinner from '../system/Spinner';
import SelectField from '../form/SelectField';
import Modal from '../system/Modal';

import { UserContext } from '../../context/user';
import { AlertsContext } from '../../context/alerts';

const initialErrors = {
  abaNumber: null,
  accountNumber: null,
  accountName: null,
};

const BankDataForm = ({
  data,
  dataHasLoaded,
  createBankingData,
  updateBankingData,
  deleteBankingData,
}) => {
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);

  const { getCurrentUserId } = userContext;
  const { addAlert } = alertsContext;

  const [errors, setErrors] = useState(initialErrors);
  const [accountType, setAccountType] = useState(
    data?.accountType || 'checking'
  );
  const [delConfirm, setDelConfirm] = useState(false);

  const abaNumberRef = useRef(null);
  const accountNumberRef = useRef(null);
  const accountNameRef = useRef(null);

  const isNum = (value) => /^\d+$/.test(value);

  const handleErrors = () => {
    const abaNumber = (abaNumberRef.current?.value || '').toString().trim();
    const accountNumber = (accountNumberRef.current?.value || '')
      .toString()
      .trim();
    const accountName = (accountNameRef.current?.value || '').trim();

    const newErrors = { ...initialErrors };

    if (!isNum(abaNumber)) {
      newErrors.abaNumber = 'ABA number must consist of digits only';
    }

    if (abaNumber.length > 9) {
      newErrors.abaNumber = 'ABA number must not exceed 9 digits';
    }

    if (abaNumber.length === 0) {
      newErrors.abaNumber = 'ABA number is required';
    }

    if (!isNum(accountNumber)) {
      newErrors.accountNumber = 'Account number must consist of digits only';
    }

    if (accountNumber.length > 17) {
      newErrors.accountNumber = 'Account number must not exceed 17 characters';
    }

    if (accountNumber.length === 0) {
      newErrors.accountNumber = 'Account number is required';
    }

    if (accountName.length > 0 && accountName.includes(',')) {
      newErrors.accountName = 'Account name must not have comma';
    }

    if (accountName.length > 22) {
      newErrors.accountName =
        'Account name must be shorter than 22 characters ';
    }

    if (accountName.length === 0) {
      newErrors.accountName = 'Account name is required';
    }

    setErrors(newErrors);
    const errorsArr = Object.values(newErrors);

    let hasErrors = false;

    for (const key in errorsArr) {
      if (errorsArr[key] !== null) {
        hasErrors = true;
        break;
      }
    }

    return hasErrors;
  };

  const onSave = async () => {
    const abaNumber = (abaNumberRef.current?.value || '').toString().trim();
    const accountNumber = (accountNumberRef.current?.value || '')
      .toString()
      .trim();
    const accountName = (accountNameRef.current?.value || '').trim();

    const hasErrors = handleErrors();

    if (hasErrors) {
      return;
    }

    let res = null;

    if (!data) {
      res = await createBankingData({
        userID: getCurrentUserId(),
        abaNumber,
        accountName,
        accountNumber,
        accountType,
      });
    } else {
      res = await updateBankingData({
        id: data.id,
        abaNumber,
        accountName,
        accountNumber,
        accountType,
      });
    }

    if (!res.success) {
      return addAlert('danger', 'Bank data was not saved');
    }

    addAlert('success', 'Bank data saved successfully');
  };

  const getDeleteConfirm = () => {
    setDelConfirm(true);
  };

  const onConfirmDismiss = () => {
    setDelConfirm(false);
  };

  const onDelete = async () => {
    const res = await deleteBankingData();
    onConfirmDismiss();

    if (!res.success) {
      return addAlert('danger', 'Error deleting your banking data');
    }

    addAlert('success', 'Banking data deleted successfully');
  };

  return (
    <div className='col-span-12 grid grid-cols-12 gap-4 shadow-card rounded-lg p-6 relative'>
      <>
        <Heading headingSize={5} classes='col-span-full mb-4'>
          Banking Information
        </Heading>
        <div className='col-span-full md:col-span-6 flex flex-col justify-start'>
          <Input
            type='number'
            label='ABA Number:'
            placeholder='123456789'
            initialValue={data?.abaNumber || ''}
            id='aba-input'
            wrapClasses='mb-4'
            setRef={abaNumberRef}
            error={!!errors.abaNumber}
            hint={errors.abaNumber ? errors.abaNumber : ''}
            icon={data?.abaNumber ? 'check' : ''}
            maxLength={9}
          />
          <Input
            label='Account Name:'
            placeholder='John Doe'
            initialValue={data?.accountName || ''}
            id='account-name-input'
            wrapClasses='mb-4'
            setRef={accountNameRef}
            error={!!errors.accountName}
            hint={errors.accountName ? errors.accountName : ''}
            icon={data?.accountName ? 'check' : ''}
            maxLength={22}
          />
        </div>
        <div className='col-span-full md:col-span-6 flex flex-col justify-start'>
          <SelectField
            listFullWidth
            label='Account Type:'
            value={accountType}
            options={[
              {
                text: 'Checking',
                onClick: () => setAccountType('checking'),
              },
              { text: 'savings', onClick: () => setAccountType('savings') },
            ]}
            className='mb-4'
          />
          <Input
            type='number'
            label='Account Number:'
            placeholder='12345678912345678'
            initialValue={data?.accountNumber || ''}
            id='account-number-input'
            wrapClasses='mb-4'
            setRef={accountNumberRef}
            error={!!errors.accountNumber}
            hint={errors.accountNumber ? errors.accountNumber : ''}
            icon={data?.accountNumber ? 'check' : ''}
            maxLength={17}
          />
        </div>
        <div className='col-span-full flex justify-between items-end md:items-start flex-col md:flex-row'>
          <Button
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={getDeleteConfirm}
            disabled={data === null}
            classes='mb-4'
          >
            Delete my banking information
          </Button>
          <Button
            buttonType='primary'
            buttonSize='medium'
            classes='mb-4'
            buttonOnClick={onSave}
          >
            Verify and save my banking information
          </Button>
        </div>
      </>
      {!dataHasLoaded && (
        <div className='absolute inset-0 flex items-center justify-center bg-white'>
          <Spinner size='small' />
        </div>
      )}
      <Modal
        title={
          <span>
            <span>Are you sure you want to delete</span>
            <br />
            <br />
            <span>your banking information?</span>
          </span>
        }
        actions={[
          <Button
            key='dismiss'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={onConfirmDismiss}
            classes='mr-4'
          >
            No
          </Button>,
          <Button
            key='leave'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={onDelete}
          >
            Yes
          </Button>,
        ]}
        isVisible={delConfirm}
        handleClose={onConfirmDismiss}
        headingSize={4}
      />
    </div>
  );
};

export default BankDataForm;
