import React, { Component } from 'react';
import { InventoryContext } from './inventory';
import { logError } from '../helpers';
import { UserContext } from './user';

export default class InventoryProvider extends Component {
  constructor(props) {
    super(props);

    this.userSubscription = null;

    this.state = {
      inventory: {},
      coins: null,
      usd: null,
      setInventory: this.setInventory,
      updateInventory: this.updateInventory,
      updateCoins: this.updateCoins,
      extractUsd: this.extractUsd,
      updateInventoryAndCoins: this.updateInventoryAndCoins,
      clearInventory: this.clearInventory,
    };
  }

  calculateUSD = (coins = 0) => {
    return (coins * 0.006 * 0.5).toFixed(2);
  };

  calculateCoins = (usd = 0) => {
    return Math.round((2 * parseFloat(usd)) / 0.006);
  };

  setInventory = (inventory = '{}', coins) => {
    try {
      const inventoryObj = JSON.parse(inventory);

      this.setState({
        coins,
        inventory: inventoryObj,
        usd: this.calculateUSD(coins),
      });
    } catch (error) {
      logError(error);
    }
  };

  updateInventory = (itemId, amount) => {
    const newValue = {
      ...this.state.inventory,
      [itemId]: {
        ...this.state.inventory[itemId],
        count: this.state.inventory[itemId].count + amount,
      },
    };

    this.setState({ inventory: newValue });
  };

  updateCoins = (coins) => {
    this.setState({ coins, usd: this.calculateUSD(coins) });
  };

  extractUsd = (amount) => {
    const usd = this.state.usd - amount;
    this.setState({ usd: usd.toFixed(2), coins: this.calculateCoins(usd) });
  };

  updateInventoryAndCoins = (shopItem, allActions) => {
    if (!shopItem) {
      return;
    }

    let inventory;

    if (shopItem.coins > this.state.coins) {
      return;
    }

    if (shopItem.gameActionID) {
      inventory = this.setUserInventory(shopItem, 'gameAction', allActions);
    }

    if (shopItem.avatarID) {
      inventory = this.setUserInventory(shopItem, 'avatar');
    }

    if (shopItem.stickersBundleID) {
      inventory = this.setUserInventory(shopItem, 'stickersBundle');
    }

    const coins = this.state.coins - shopItem.price;

    this.setState({
      inventory,
      coins,
      usd: this.calculateUSD(coins),
    });
  };

  setUserInventory = (shopItem, type, allItems) => {
    const userInventory = this.state.inventory || {};

    if (type === 'avatar') {
      userInventory[shopItem.avatar.id] = {
        title: shopItem.title,
        factor: 1,
        itemType: 'Avatar',
        type: 'Avatar',
        duration: 1,
        activated: false,
        activeUntil: false,
        count: 1,
      };
    }

    if (type === 'stickersBundle') {
      userInventory[shopItem.stickersBundle.id] = {
        title: shopItem.title,
        factor: 1,
        itemType: 'StickerBundle',
        type: 'StickerBundle',
        duration: 1,
        activated: false,
        activeUntil: false,
        count: 1,
      };
    }

    if (type === 'gameAction') {
      const newCount = shopItem.gameActionAmount;
      const action = allItems.find((item) => item.id === shopItem.gameActionID);

      if (!action) {
        return userInventory;
      }

      if (userInventory[action.id]) {
        userInventory[action.id].count =
          userInventory[action.id].count + newCount;
      } else {
        userInventory[action.id] = {
          title: action.name,
          factor: 1,
          itemType: 'GameAction',
          type: 'Game Action',
          duration: 1,
          activated: false,
          activeUntil: false,
          count: newCount,
        };
      }
    }

    return userInventory;
  };

  updateAmount = (inventory) => {
    try {
      const inventoryObj = JSON.parse(inventory);
      this.setState({ inventory: inventoryObj });
    } catch (error) {
      logError(error);
    }
  };

  clearInventory = () => {
    this.setState({ inventory: {}, coins: null, usd: null });
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    return this.context.getCurrentUserId();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.context.getCurrentUserId() && !this.userSubscription) {
      this.userSubscription = this.context.subscribeToUserUpdate(
        this.context.getCurrentUserId(),
        this.updateAmount
      );
    }
  }

  componentWillUnmount() {
    if (this.userSubscription) {
      this.context.unsubscribeFromUserUpdate(this.userSubscription);
    }
  }

  render() {
    return (
      <InventoryContext.Provider value={this.state}>
        {this.props.children}
      </InventoryContext.Provider>
    );
  }
}

InventoryProvider.contextType = UserContext;
