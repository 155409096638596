import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import Heading from '../typography/Heading';
import Paragraph from '../typography/Paragraph';
import Modal from '../system/Modal';
import Button from '../common/Button';
import FollowersList from './FollowersList';

import { SponsorRoomsContext } from '../../context/sponsorRooms';

const RoomFollowers = ({ roomId }) => {
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const { loadRoomFollowers, roomFollowers, loadingFollowers } =
    sponsorRoomsContext;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (roomId) {
      loadRoomFollowers(roomId);
    }
  }, [roomId]);

  const viewFollowersList = () => {
    setModalIsOpen(true);
  };

  const hideFollowersList = () => {
    setModalIsOpen(false);
  };

  return (
    <div>
      <Heading headingSize={5}>Followers</Heading>
      {loadingFollowers && <Skeleton height={20} width={240} />}
      {!loadingFollowers && roomFollowers.length === 0 && (
        <Paragraph>This room does not have any followers yet.</Paragraph>
      )}
      {roomFollowers.length > 0 && (
        <Paragraph>
          This room has{' '}
          <span
            onClick={viewFollowersList}
            className='text-primaryA cursor-pointer hover:text-primaryADark hover:underline'
          >
            {roomFollowers.length}{' '}
            {roomFollowers.length === 1 ? 'follower' : 'followers'}.
          </span>
        </Paragraph>
      )}
      <Modal
        title={'Followers list'}
        isVisible={modalIsOpen}
        handleClose={hideFollowersList}
        actions={[
          <Button
            key='hide-followers-button'
            disabled={false}
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={hideFollowersList}
          >
            Close
          </Button>,
        ]}
      >
        <FollowersList roomId={roomId} />
      </Modal>
    </div>
  );
};

export default RoomFollowers;
