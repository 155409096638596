import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { RankingContext } from '../context/rankig';
import { UserContext } from '../context/user';
import Table from '../components/table/Table';
import TableHead from '../components/table/TableHead';
import TableBody from '../components/table/TableBody';
import TableRow from '../components/table/TableRow';
import TableCell from '../components/table/TableCell';
import Heading from '../components/typography/Heading';
import Avatar from '../components/common/Avatar';
import rankImg from '../../src/rankOne.svg';
import Skeleton from 'react-loading-skeleton';

import useInfiniteScroll from 'react-infinite-scroll-hook';

const Leaderboard = () => {
  const rankingContext = useContext(RankingContext);
  const userContext = useContext(UserContext);
  const { user } = userContext;
  const {
    rankingUsers,
    loading: loadingRankingUsers,
    nextToken,
    loadMore,
  } = rankingContext;
  const tableHeadingStyles =
    'py-2 font-display font-medium text-sm text-left leading-5 tracking-tight';
  const numberStyles = 'text-xs font-number font-medium py-2 tracking-tight';

  const [sentryRef] = useInfiniteScroll({
    loading: loadingRankingUsers,
    hasNextPage: nextToken || false,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  if (rankingUsers.length === 0 && loadingRankingUsers) {
    return 'Loading Ranking...';
  }

  if (rankingUsers.length === 0) {
    return 'There are no users with ranks';
  }

  const renderLoader = () => {
    return (
      <TableRow innerRef={sentryRef} classes={`border-b border-secondaryB`}>
        <TableCell
          variant='body'
          classes='w-16 text-center text-primaryA text-xl font-number font-bold py-2'
        >
          <Skeleton height={32} width={32} />
        </TableCell>
        <TableCell variant='body' classes='w-2/4 pl-40 py-2'>
          <div className='flex items-center'>
            <Skeleton circle={true} height={40} width={40} />
            <span
              className={`text-xs font-display font-medium tracking-tight leading-4 break-all `}
            >
              <Skeleton height={30} width={120} />
            </span>
          </div>
        </TableCell>
        <TableCell variant='body' classes={`w-1/3 `}>
          <Skeleton height={30} width={120} />
        </TableCell>
        <TableCell variant='body'>
          <Skeleton height={40} width={120} />
        </TableCell>
      </TableRow>
    );
  };

  return (
    <div className='col-span-full mt-8 px-8 py-10 shadow-card rounded-lg'>
      <Helmet>
        <title>Leaderboard | ClashTV</title>
      </Helmet>
      <div className='flex justify-between mb-9'>
        <Heading headingSize={6} classes='font-bold tracking-tight'>
          Leaderboard
        </Heading>
        {/* <p className='text-xs font-display font-medium leading-5 tracking-tight text-secondaryA'>
          Last update: 12/10/2020
        </p> */}
      </div>
      <Table>
        <TableHead>
          <TableRow classes='border-b border-secondaryB'>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              #Rank
            </TableCell>
            <TableCell variant='head' classes={`pl-40 ${tableHeadingStyles}`}>
              User
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Level
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Experience
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rankingUsers.map((rankUser) => (
            <TableRow
              key={rankUser.id}
              classes={`border-b border-secondaryB ${
                user?.dbData?.data?.getUser?.id === rankUser.id
                  ? 'bg-secondaryC'
                  : ''
              }`}
            >
              <TableCell
                variant='body'
                classes='w-16 text-center text-primaryA text-xl font-number font-bold py-2'
              >
                {rankUser.rank == 1 ? (
                  <img src={rankImg} className='ml-auto' />
                ) : (
                  rankUser.rank
                )}
              </TableCell>
              <TableCell variant='body' classes='w-2/4 pl-40 py-2'>
                <div className='flex items-center'>
                  <Avatar
                    imgSrc={rankUser?.image?._url}
                    imgAlt={`${rankUser.displayName} avatar`}
                    imgSize={5}
                    classes='mr-2'
                  />
                  <span
                    className={`text-xs font-display font-medium tracking-tight leading-4 break-all ${
                      user?.dbData?.data?.getUser?.id === rankUser.id
                        ? 'text-primaryA'
                        : 'text-secondaryA'
                    }`}
                  >
                    {rankUser.displayName}
                  </span>
                </div>
              </TableCell>
              <TableCell
                variant='body'
                classes={`w-1/3 ${numberStyles} ${
                  user?.dbData?.data?.getUser?.id === rankUser.id
                    ? 'text-primaryA'
                    : 'text-secondaryA'
                }`}
              >
                {rankUser.level}
              </TableCell>
              <TableCell
                variant='body'
                classes={`${numberStyles} ${
                  user?.dbData?.data?.getUser?.id === rankUser.id
                    ? 'text-primaryA'
                    : 'text-secondaryA'
                }`}
              >
                {rankUser.experience}
              </TableCell>
            </TableRow>
          ))}
          {nextToken && renderLoader()}
        </TableBody>
      </Table>
    </div>
  );
};

export default Leaderboard;
