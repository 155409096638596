/* eslint-disable prettier/prettier */
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { UserContext } from '../context/user';
import { SponsorRoomsContext } from '../context/sponsorRooms';

import UserMainInfo from './UserSidebar/UserMainInfo';
import Icon from './typography/Icon';
import routes from '../routes';
import colors from '../theme-colors';
import featureFlags from '../features';

const RoomsSidebarLink = ({ label, icon, active, to, disabled = true }) => {
  const [hovered, setHovered] = useState(false);
  let color = colors.primaryC;

  if (active || hovered) {
    color = colors.primaryADark;
  }

  return (
    <Link
      className={`rooms-sidebar-link ${
        active || hovered ? 'text-primaryADark' : 'text-primaryC'
      } ${disabled ? 'disabled' : ''}`}
      to={to}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {icon && <Icon name={icon} color={color} classes='mr-2' />}
      {label}
    </Link>
  );
};

const RoomsSidebar = () => {
  const userContext = useContext(UserContext);
  const roomsContext = useContext(SponsorRoomsContext);

  const { user, userImageUrl } = userContext;
  const { currentUserRoom } = roomsContext;

  const location = useLocation();

  if (!user) {
    return null;
  }

  return (
    <div
      id='rooms-sidebar'
      className='pt-8 px-4 border-r border-secondaryB col-span-2 xl:px-8 xl:pt-6 3xl:px-10 flex justify-between h-full'
    >
      <div className='flex flex-col'>
        <RoomsSidebarLink
          label='My Dashboard'
          active={location.pathname === routes.roomDashboard}
          to={routes.roomDashboard}
          disabled={!currentUserRoom?.isLive}
        />
        <RoomsSidebarLink
          label='My Room'
          active={location.pathname === routes.myRoom}
          to={routes.myRoom}
          disabled={false}
        />
        <RoomsSidebarLink
          label='My Events'
          active={
            location.pathname === routes.myEvents ||
            location.pathname === routes.newEvent
          }
          to={routes.myEvents}
          disabled={!currentUserRoom?.isLive}
        />
        {featureFlags.videos.active && (
          <RoomsSidebarLink
            label='My Videos'
            active={location.pathname === routes.videoList}
            to={routes.videoList}
            disabled={!currentUserRoom?.isLive}
          />
        )}
        <RoomsSidebarLink
          label='My Premium Content Pricing'
          active={location.pathname === routes.subscriptionPass.list}
          to={routes.subscriptionPass.list}
          disabled={!currentUserRoom?.isLive}
        />
        <RoomsSidebarLink
          label='Withdraw Money'
          active={location.pathname === routes.withdraw}
          to={routes.withdraw}
          disabled={!currentUserRoom?.isLive}
        />
        <RoomsSidebarLink
          label='Earnings'
          active={location.pathname === routes.earnings}
          to={routes.earnings}
          disabled={!currentUserRoom?.isLive}
        />
        <RoomsSidebarLink
          label='PPV Season Pass Earnings'
          active={location.pathname === routes.ppvPassEarnings}
          to={routes.ppvPassEarnings}
          disabled={!currentUserRoom?.isLive}
        />
      </div>
      <UserMainInfo user={user} imageUrl={userImageUrl} />
    </div>
  );
};

export default RoomsSidebar;
