import { API, graphqlOperation } from "aws-amplify";
import { sponsorRoomPayPerViewProfitByUserId } from "graphql/queries";

const loadPpvPassEarnings = async ({ userID, nextToken = null }: { userID: string; nextToken: string | null }) => {

    try {
        const variables = { userID, nextToken };

        const response = await API.graphql<any>(
          graphqlOperation(sponsorRoomPayPerViewProfitByUserId, variables)
        );

        return {
            earnings: response?.data?.sponsorRoomPayPerViewProfitByUserId?.items || [],
            nextToken: response?.data?.sponsorRoomPayPerViewProfitByUserId?.nextToken,
        };

    } catch (error) { 
        console.error('PPV Pass Earnings error: ', error);
        throw error;
    }
}

export default {
    loadPpvPassEarnings
};