import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { ParticipantsContext } from './participants';
import {
  customOnlineUsersByEventAndDisplayName,
  customOnlineUsersByEventAndDisplayNameLowerCase,
} from '../graphql/customQueries';
import {
  createEventOnlineUser,
  deleteEventOnlineUser,
  updateEventOnlineUser,
} from '../graphql/mutations';
import {
  onUserJoinEvent,
  onUserLeaveEvent,
  onUserRaiseHand,
} from '../graphql/subscriptions';
import { logError } from '../helpers';

export default class ParticipantsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      participants: [],
      loading: false,
      error: null,
      nextToken: null,
      currentUserEvent: null,
      loadMore: this.loadMore,
      loadParticipants: this.loadParticipants,
      getParticipants: this.getParticipants,
      addParticipant: this.addParticipant,
      removeParticipant: this.removeParticipant,
      updateParticipant: this.updateParticipant,
      createParticipantsSubscriptions: this.createParticipantsSubscriptions,
      removeParticipantsSubscription: this.removeParticipantsSubscription,
      clearParticipants: this.clearParticipants,
    };
  }

  createParticipantsSubscriptions = async (eventID) => {
    const newParticipantSubscription = await API.graphql(
      graphqlOperation(onUserJoinEvent, {
        eventID,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        const existingUser = this.state.participants.find(
          (p) => p.id === value.data.onUserJoinEvent.user.id
        );

        if (!existingUser) {
          this.setState({
            participants: [
              ...this.state.participants,
              {
                ...value.data.onUserJoinEvent.user,
                raisedHand: value.data.onUserJoinEvent.raisedHand,
              },
            ],
          });
        }
      },
      error: (error) => logError(error),
    });

    const raiseHandSubscription = await API.graphql(
      graphqlOperation(onUserRaiseHand, {
        eventID,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        const pIndex = this.state.participants.findIndex(
          (p) => p.id === value.data.onUserRaiseHand.user.id
        );
        const newParticipants = [...this.state.participants];
        newParticipants[pIndex] = {
          ...value.data.onUserRaiseHand.user,
          raisedHand: value.data.onUserRaiseHand.raisedHand,
        };

        this.setState({ participants: newParticipants });
      },
      error: (error) => logError(error),
    });

    const leftParticipantSubscription = await API.graphql(
      graphqlOperation(onUserLeaveEvent, {
        eventID,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        this.setState({
          participants: this.state.participants.filter(
            (p) => p.id !== value.data.onUserLeaveEvent.user.id
          ),
        });
      },
      error: (error) => logError(error),
    });

    return {
      newParticipantSubscription,
      raiseHandSubscription,
      leftParticipantSubscription,
    };
  };

  removeParticipantsSubscription = async ({
    newParticipantSubscription,
    raiseHandSubscription,
    leftParticipantSubscription,
  }) => {
    newParticipantSubscription.unsubscribe();
    raiseHandSubscription.unsubscribe();
    leftParticipantSubscription.unsubscribe();
  };

  addParticipant = async (eventID, userID, displayName) => {
    try {
      let currentUserEvent = null;
      const res = await API.graphql(
        graphqlOperation(customOnlineUsersByEventAndDisplayName, {
          nextToken: null,
          eventID,
          userID,
        })
      );

      let exists = false;

      if (res.data.onlineUsersByEventAndDisplayName.items.length > 0) {
        for (const item of res.data.onlineUsersByEventAndDisplayName.items) {
          if (item.userID === userID && item.eventID === eventID) {
            currentUserEvent = { ...item.user, raisedHand: item.raisedHand };
            exists = true;
          }
        }
      }

      if (!exists) {
        const res = await API.graphql(
          graphqlOperation(createEventOnlineUser, {
            input: {
              eventID,
              userID,
              displayName,
              displayNameLowerCase: displayName.toLowerCase(),
              raisedHand: false,
            },
          })
        );

        currentUserEvent = res.data.createEventOnlineUser;
      }

      this.setState({ currentUserEvent });
    } catch (error) {
      logError(error);
    }
  };

  updateParticipant = async (eventID, userID, newData) => {
    if (
      this.state.currentUserEvent &&
      this.state.currentUserEvent.eventID === eventID &&
      this.state.currentUserEvent.userID === userID
    ) {
      try {
        const res = await API.graphql(
          graphqlOperation(updateEventOnlineUser, {
            input: { id: this.state.currentUserEvent.id, ...newData },
          })
        );

        this.setState({ currentUserEvent: res.data.updateEventOnlineUser });
      } catch (error) {
        logError(error);
      }
    }
  };

  removeParticipant = async (eventId, userId) => {
    if (
      eventId &&
      userId &&
      this.state.currentUserEvent &&
      this.state.currentUserEvent.userID === userId &&
      this.state.currentUserEvent.eventID === eventId
    ) {
      API.graphql(
        graphqlOperation(deleteEventOnlineUser, {
          input: { id: this.state.currentUserEvent.id },
        })
      );

      this.setState({
        currentUserEvent: null,
      });
    }
  };

  loadParticipants = async (
    eventId,
    loadMore = false,
    displayNameTerm = null
  ) => {
    const variables = {
      limit: 50,
      sortDirection: 'DESC',
      nextToken: null,
      eventID: eventId,
    };

    if (loadMore) {
      variables.nextToken = this.state.nextToken;
    }

    try {
      const newState = { loading: true };

      this.setState(newState);
      const res = await API.graphql(
        graphqlOperation(customOnlineUsersByEventAndDisplayName, variables)
      );

      let participants = [];

      if (loadMore) {
        participants = [...this.state.participants];
      }

      for (const item of res.data.onlineUsersByEventAndDisplayName.items) {
        if (!item.user.displayName) {
          continue;
        }

        const existingUser = participants.find((u) => u.id === item.userID);

        if (!existingUser) {
          participants.push({ ...item.user, raisedHand: item.raisedHand });
        }
      }

      this.setState({
        loading: false,
        participants,
        error: null,
        nextToken: res.data.onlineUsersByEventAndDisplayName.nextToken,
      });
    } catch (error) {
      this.setState({
        loading: false,
        participants: [],
        error,
        nextToken: null,
      });
      logError(error);
    }
  };

  clearParticipants = async () => {
    this.setState({ participants: [] });
  };

  getParticipants = async (
    eventID,
    nextToken = null,
    displayNameTerm = null
  ) => {
    const variables = {
      nextToken,
      eventID,
      limit: 50,
      sortDirection: 'DESC',
    };

    if (displayNameTerm) {
      variables.displayNameLowerCase = {
        beginsWith: displayNameTerm.toLowerCase(),
      };
    }

    try {
      const res = await API.graphql(
        graphqlOperation(
          customOnlineUsersByEventAndDisplayNameLowerCase,
          variables
        )
      );

      const participants = [];

      for (const item of res.data.onlineUsersByEventAndDisplayNameLowerCase
        .items) {
        if (!item.user.displayName) {
          continue;
        }

        const existingUser = participants.find((u) => u.id === item.userID);

        if (!existingUser) {
          participants.push({ ...item.user, raisedHand: item.raisedHand });
        }
      }

      return {
        participants,
        nextToken: res.data.onlineUsersByEventAndDisplayNameLowerCase.nextToken,
        error: null,
      };
    } catch (error) {
      return {
        error,
      };
    }
  };

  loadMore = async (eventId, displayNameTerm) => {
    if (this.state.nextToken) {
      await this.loadParticipants(eventId, true, displayNameTerm);
    }
  };

  render() {
    return (
      <ParticipantsContext.Provider value={this.state}>
        {this.props.children}
      </ParticipantsContext.Provider>
    );
  }
}
