import React from 'react';
import Heading from '../typography/Heading';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import moreCoins from '../../assets/more-coins.png';
import moreGoods from '../../assets/more-goods.png';
import investButtonBackground from '../../assets/invest-button-background.png';
import investButtonLogo from '../../assets/invest-button-logo.png';

const UserGoods = () => {
  const styles =
    'relative block h-24 mb-4 border border-lightGray rounded-lg bg-gradient-to-r from-primaryADark to-primaryA';
  const textStyles =
    'absolute transform top-1/2 -translate-y-1/2 left-4 text-white font-display text-xl font-bold w-24';
  const goods = [
    {
      type: 'creator',
      link: routes.becomeCreator,
      text: 'Become a Creator ',
      img: investButtonBackground,
      backgroundSize: 'cover',
    },
    {
      link: routes.accountMyGoods,
      text: 'Get more Goods ',
      img: moreGoods,
      backgroundSize: 'contain',
    },
    {
      link: routes.accountCoins,
      text: 'Get more Coins ',
      img: moreCoins,
      backgroundSize: 'contain',
    },
  ];

  return (
    <div>
      {goods.map((good, i) => {
        if (good.type === 'creator') {
          return (
            <Link
              key={good.text + i}
              to={good.link}
              className={styles}
              style={{ maxWidth: 266 }}
            >
              <div
                className='w-full h-full'
                style={{
                  backgroundImage: `url(${good.img})`,
                  backgroundPosition: 'right, left',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: good.backgroundSize,
                  borderRadius: '0.5rem',
                }}
              >
                <Heading headingSize={6} classes={textStyles}>
                  {good.text}
                </Heading>
                <div className='absolute right-2 top-1/2 transform -translate-y-1/2 w-2/5'>
                  <img src={investButtonLogo} />
                </div>
              </div>
            </Link>
          );
        }

        return (
          <Link
            key={good.text + i}
            to={good.link}
            className={styles}
            style={{ maxWidth: 266 }}
          >
            <div
              className='w-full h-full'
              style={{
                backgroundImage: `url(${good.img})`,
                backgroundPosition: 'right, left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: good.backgroundSize,
                borderRadius: '0.5rem',
              }}
            >
              <Heading headingSize={6} classes={textStyles}>
                {good.text}
              </Heading>
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default UserGoods;
