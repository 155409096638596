/* eslint-disable prettier/prettier */
import { produce } from 'immer';
import {
  ActionType,
  createReducer,
} from 'typesafe-actions';

import {
  puchaseSubscription,
} from './actions';

import { SubscriptionType } from './types';

export type Actions = ActionType<
  | typeof puchaseSubscription
>;

export type State = {
  subType: SubscriptionType | null;
  subID: string | null;
  error: string | null;
};

const initialState: State = {
  subType: null,
  subID: null,
  error: null,
};

const eventReducer = createReducer<State, Actions>(initialState)
  .handleAction(puchaseSubscription.request, (state, action) =>
    produce(state, (draft) => {
      draft.subType = action.payload.type;
      draft.subID = initialState.subID;
      draft.error = initialState.error;
    }))
  .handleAction(puchaseSubscription.success, (state, action) =>
    produce(state, (draft) => {
      draft.subID = action.payload.id;
    }))
  .handleAction(puchaseSubscription.failure, (state, action) =>
    produce(state, (draft) => {
      draft.error = action.payload;
      draft.subType = initialState.subType;
      draft.subID = initialState.subID;
    }));

export default eventReducer;
