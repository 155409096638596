/* eslint-disable prettier/prettier */
import { combineReducers } from 'redux';
import subscriptionsReducer from './subscriptions/reducer';
import subscriptionCreateReducer from './subscriptionCreate/reducer';
import roomsReducer from './rooms/reducer';
import purchasedSubscriptionsReducer from './purchasedSubscriptions/reducer';
import eventReducer from './event/reducer';
import ppvPassEarningsReducer from './ppvPassEarnings/reducer';

// please keep these in alphabetical order for the browser extension
const createReducer = () => combineReducers({
  event: eventReducer,
  ppvPassEarnings: ppvPassEarningsReducer,
  purchasedSubscriptions: purchasedSubscriptionsReducer,
  rooms: roomsReducer,
  subscriptions: subscriptionsReducer,
  subscriptionCreate: subscriptionCreateReducer,
});

export default createReducer;
