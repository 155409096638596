import React, { useContext, useEffect, useState } from 'react';

import DashLayout from '../dashboard/DashLayout';
import BuyEventModal from '../dashboard/BuyEventModal';
import CardSkeleton from '../../skeletons/CardSkeleton';

import { EventsContext } from '../../context/events';
import { UserContext } from '../../context/user';
import { TransactionsContext } from '../../context/transactions';
import { AlertsContext } from '../../context/alerts';
import { InventoryContext } from '../../context/inventory';
import Heading from '../typography/Heading';

const PromotedEvents = ({ roomIds }) => {
  const eventsContext = useContext(EventsContext);
  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);
  const alertsContext = useContext(AlertsContext);
  const inventoryContext = useContext(InventoryContext);

  const { getUpcomingEventsByRoomIds, getEndedEventsByRoomIds } = eventsContext;
  const { getCurrentUserId } = userContext;
  const { loadPPVEvent, myPPVList } = transactionsContext;
  const { addAlert } = alertsContext;
  const { coins } = inventoryContext;

  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [loadingUpcoming, setLoadingUpcoming] = useState(false);
  const [endedEvents, setEndedEvents] = useState([]);
  const [loadingEnded, setLoadingEnded] = useState(false);
  const [buyModalData, setBuyModalData] = useState(null);

  const loading = loadingUpcoming || loadingEnded;
  const allEvents = [...upcomingEvents, ...endedEvents];

  const onPPVBuyAttempt = (ppvData) => {
    if (coins < ppvData.price) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      return;
    }

    setBuyModalData(ppvData);
  };

  const loadPPVData = async (eventId) => {
    await loadPPVEvent(getCurrentUserId(), eventId);
  };

  const loadUpcomingEvents = async () => {
    setLoadingUpcoming(true);

    const { success, events } = await getUpcomingEventsByRoomIds(roomIds);

    if (!success) {
      setLoadingUpcoming(false);
      return;
    }

    setUpcomingEvents(events);
    setLoadingUpcoming(false);

    for (const e of events) {
      await loadPPVData(e.id);
    }
  };

  const loadEndedEvents = async () => {
    setLoadingEnded(true);

    const { success, events } = await getEndedEventsByRoomIds(roomIds);

    if (!success) {
      setLoadingEnded(false);
      return;
    }

    setEndedEvents(events);
    setLoadingEnded(false);

    for (const e of events) {
      await loadPPVData(e.id);
    }
  };

  useEffect(() => {
    if (roomIds.length > 0 && upcomingEvents.length === 0) {
      loadUpcomingEvents();
    }

    if (roomIds.length > 0 && endedEvents.length === 0) {
      loadEndedEvents();
    }
  }, [roomIds]);

  const renderLoading = () => {
    return Array(6)
      .fill()
      .map((item, index) => <CardSkeleton key={index} />);
  };

  if (!loading && allEvents.length === 0) {
    return <Heading headingSize={5}>There are no upcoming events yet</Heading>;
  }

  return (
    <div className='relative grid gap-8 mr-8 auto-rows-card-row mb-8'>
      <DashLayout
        events={allEvents}
        loading={false}
        showBuyModal={onPPVBuyAttempt}
        ppvItems={myPPVList}
        currentUserId={getCurrentUserId()}
      />
      {loading && renderLoading()}
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
      />
    </div>
  );
};

export default PromotedEvents;
