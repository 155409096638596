import React from 'react';
import Icon from '../typography/Icon';
import Heading from '../typography/Heading';
import colors from '../../theme-colors';

const BackButton = ({ onClick }) => {
  return (
    <button className='flex items-center justify-start' onClick={onClick}>
      <Icon name='back' classes='mr-2' size={14} color={colors.primaryA} />
      <Heading headingSize={6}>Back</Heading>
    </button>
  );
};

export default BackButton;
