import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const TableRow = ({
  children,
  classes,
  innerRef,
  onMouseEnter,
  onMouseLeave,
  onClick,
  clickable,
}) => {
  if (!children) {
    logError("Component TableRow not rendered, because 'TableBody' missing.");
    return null;
  }

  const getClasses = () => {
    let className = classes;

    if (clickable) {
      className += ' cursor-pointer';
    }

    return className;
  };

  return (
    <tr
      ref={innerRef}
      className={getClasses()}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </tr>
  );
};

TableRow.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  clickable: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onClick: PropTypes.func,
};

TableRow.defaultProps = {
  classes: '',
  clickable: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  onClick: () => {},
};

export default TableRow;
