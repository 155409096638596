/* eslint-disable prettier/prettier */
import React from 'react';
import Icon from '../typography/Icon';

const CustomShareButton = (props) => (
  <button
    {...props}
    className={`flex items-center justify-center focus:outline-none ${props.className ?? ''}`}
  >
    <Icon name='share2' classes='mr-2' />
    Share
  </button>
);

export default CustomShareButton;
