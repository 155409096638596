import React, { FC } from 'react';

import ImageService from 'infrastructure/ImageService';
import ticket from 'assets/ticket.svg';

import './styles.css';

type Props = {
  image: string;
  subTitle: string;
  description: string;
  price: number;
  onClick: () => unknown;
};

const PPVOption: FC<Props> = ({
  image,
  subTitle,
  description,
  price,
  onClick,
}) => (
  <div
    style={{backgroundImage: `
      linear-gradient(rgb(0 0 0 / 0.8), rgb(0 0 0 / 0.8)),
      url(${ImageService.getImageByKey(image)}?w=520)`}
    }
    className={`
      room-bg
      w-520
      rounded-lg
      bg-center
      bg-black
      bg-blend-luminosity
      overflow-hidden
      text-white
      p-4
      pb-3.5
      flex
      flex-col
      gap-1
      cursor-pointer
    `}
    onClick={onClick}
  >
    <span className="text-xl font-bold">{subTitle}</span>
    <span className="text-sm font-medium">{description}</span>

    <div className="flex justify-between mt-auto">
      <span className="text-purple text-base font-bold">{price} Coins</span>
      <img src={ticket} />
    </div>
  </div>
);

export default PPVOption;
