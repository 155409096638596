/* eslint-disable no-unused-vars */
import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';
import { getEventScore } from '../graphql/queries';
import { onUpdateEventScoreByID } from '../graphql/subscriptions';
import { logError } from '../helpers';

const useScore = () => {
  const [score, setScore] = useState({
    contestorAScore: 0,
    contestorBScore: 0,
  });

  const loadScore = async (eventId) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getEventScore, {
          id: eventId,
        })
      );

      const scoreData = res?.data?.getEventScore;

      if (scoreData) {
        setScore({
          contestorAScore: scoreData.contestorAScore,
          contestorBScore: scoreData.contestorBScore,
        });
      }

      return {
        success: true,
      };
    } catch (error) {
      logError('loadScore', error);
      return { success: false };
    }
  };

  const subscribeToScore = async (eventId) => {
    const subscription = await API.graphql(
      graphqlOperation(onUpdateEventScoreByID, {
        id: eventId,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        const scoreData = value?.data?.onUpdateEventScoreByID;
        if (scoreData) {
          setScore({
            contestorAScore: scoreData.contestorAScore,
            contestorBScore: scoreData.contestorBScore,
          });
        }
      },
      error: (error) => logError('subscribeToScore', error),
    });

    return subscription;
  };

  return { score, loadScore, subscribeToScore };
};

export default useScore;
