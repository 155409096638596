import React, { useEffect, useState } from 'react';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const SystemBanner = ({ id, type, message, handleDismiss }) => {
  if (!message) {
    logError("Component SystemBanner not rendered, because 'message' missing.");
    return null;
  }

  const [timeout, setRemoveTimeout] = useState(null);

  useEffect(() => {
    const systemBannerTimeout = setTimeout(() => handleRemove(), 3000);
    setRemoveTimeout(systemBannerTimeout);
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const handleRemove = () => {
    handleDismiss(id);

    if (timeout) {
      clearTimeout(timeout);
    }
  };

  return (
    <div className={`system-banner ${type}`}>
      <div className='relative w-auto max-w-3/4'>
        {type !== 'success' && (
          <Icon
            name={`${type}-exclamation-mark`}
            size={30}
            classes='absolute -left-12 transform top-1/2 -translate-y-1/2'
          />
        )}
        <Heading headingSize={5} classes='font-medium tracking-tight'>
          {message}
        </Heading>
      </div>
      <button onClick={handleRemove} className='absolute right-4'>
        <Icon name='error' size={14} classes='' />
      </button>
    </div>
  );
};

SystemBanner.propTypes = {
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['primary', 'warning', 'danger', 'success']).isRequired,
  handleDismiss: PropTypes.func,
};

SystemBanner.defaultProps = {
  message: '',
  type: 'primary',
};

export default SystemBanner;
