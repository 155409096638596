/* eslint-disable prettier/prettier */
/* eslint-disable */
import { createStore, applyMiddleware, compose } from 'redux';

import customMiddlewares from './middlewares';
import createReducer from './reducers';
import { runtimeEnvironment } from 'config';

export default function configureStore() {
  const middlewares = [
    ...customMiddlewares,
  ];

  const enhancers = [
    applyMiddleware(...middlewares),
  ];

  const composeEnhancers =
    (runtimeEnvironment !== 'prod') &&
      typeof window === 'object' &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;
  /* eslint-enable */

  const store = createStore(
    createReducer(),
    {},
    composeEnhancers(...enhancers),
  );

  return store;
}
