import React from 'react';
import ReactCodeInput from '../react-code-input-fork/src/ReactCodeInput';
import PropTypes from 'prop-types';
import Label from './Label';

const CodeInput = ({
  codeId,
  codeType,
  codeFieldsNumb,
  codeValue,
  codeName,
  codeOnChange,
  codeDisabled,
  codeFocus,
  label,
  classes,
  wrapClasses,
  handleCodeResend,
  onEnter,
}) => {
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onEnter();
    }
  };

  return (
    <div className={`flex flex-col ${wrapClasses}`}>
      {Object.keys(label).length != 0 ? (
        <Label
          text={label}
          inputId={codeId}
          classes={codeDisabled ? 'mb-5 disabled' : 'mb-5'}
        />
      ) : null}
      <ReactCodeInput
        type={codeType}
        fields={codeFieldsNumb}
        value={codeValue}
        name={codeName}
        onChange={codeOnChange}
        disabled={codeDisabled}
        autoFocus={codeFocus}
        className={`code-input ${classes}`}
        onKeyPress={handleKeyPress}
      />
      <div className='resend-hint'>
        Haven&apos;t received it?{' '}
        <span
          className='text-primaryA cursor-pointer'
          onClick={handleCodeResend}
        >
          Resend Code.
        </span>
      </div>
    </div>
  );
};

CodeInput.propTypes = {
  codeId: PropTypes.string,
  codeType: PropTypes.oneOf(['text', 'number', 'password', 'tel']).isRequired,
  codeFieldsNumb: PropTypes.number,
  codeValue: PropTypes.string,
  codeName: PropTypes.string,
  codeOnChange: PropTypes.func,
  codeDisabled: PropTypes.bool,
  codeFocus: PropTypes.bool,
  label: PropTypes.object,
  classes: PropTypes.string,
  wrapClasses: PropTypes.string,
  handleCodeResend: PropTypes.func,
  onEnter: PropTypes.func,
};

CodeInput.defaultProps = {
  codeType: 'number',
  codeFieldsNumb: '6',
  codeValue: '',
  codeName: '',
  codeDisabled: false,
  codeFocus: true,
  label: {},
  classes: '',
  wrapClasses: '',
  handleCodeResend: () => {},
  onEnter: () => {},
};

export default CodeInput;
