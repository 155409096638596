/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { Handler } from 'store/types';
import { getType } from 'typesafe-actions';

import createMiddleware from 'store/middlewareCreator';

import { eventPageMounted } from 'store/event/actions';

import SubscriptionAPIClient from 'infrastructure/api/profile/SubscriptionAPIClient';

import { loadPurchasedSubscriptions } from './actions';


const fetchPurchasedSubs: Handler<ReturnType<typeof eventPageMounted>> = async (store, next, action) => {
  next(action);

  const { dispatch } = store;
  const { payload: { userID } } = action;

  try {
    const subs = await SubscriptionAPIClient.loadPurchasedSubsciptions(userID);
    dispatch(loadPurchasedSubscriptions.success(subs))
  } catch (err) {
    dispatch(loadPurchasedSubscriptions.failure('Load Purchased Subscriptions error'))
    console.log(err);
  }
};

const purchasedSubsciptionsMiddleware = createMiddleware({
  [getType(eventPageMounted)]: fetchPurchasedSubs,
});

export default purchasedSubsciptionsMiddleware;
