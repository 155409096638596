/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { EventCategoriesContext } from './eventCategories';
import { listEventCategorisations } from '../graphql/queries';
import { logError } from '../helpers';

export default class EventCategoriesProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventCategories: [],
      loading: false,
      loadEventCategories: this.loadEventCategories,
    };
  }

  loadEventCategories = async () => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listEventCategorisations));
      const eventCategories = res?.data?.listEventCategorisations?.items || [];
      const sorted = eventCategories.sort((a, b) => a.order - b.order);

      this.setState({
        eventCategories: sorted,
        loading: false,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        eventCategories: [],
        error: 'Error loading event categories',
        loading: false,
      });
    }
  };

  render() {
    return (
      <EventCategoriesContext.Provider value={this.state}>
        {this.props.children}
      </EventCategoriesContext.Provider>
    );
  }
}
