import React from 'react';
import Logo from '../common/Logo';
import GridContainer from '../layout/GridContainer';
import Heading from '../typography/Heading';

const NoAuthContainer = ({ children, heading }) => {
  return (
    <div className='w-full h-full max-w-1440 max-h-1024 relative transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-primaryD'>
      <GridContainer classes='h-full'>
        <div className='relative col-start-2 col-end-6 flex flex-col'>
          <Logo classes='inline-block mt-18 mb-24' />
          <Heading headingSize={2} classes='text-primaryC mb-10'>
            {heading}
          </Heading>
          {children}
        </div>
        <div className='flex flex-col justify-between mt- bg-login-hero bg-center bg-cover bg-no-repeat col-start-7 col-end-13 bg-gray-100 text-center'>
          <div className='flex flex-1 flex-col items-center justify-center'>
            <Heading
              headingSize={2}
              classes='px-18 text-primaryD tracking-tight'
            >
              ClashTV Brings You the Best
            </Heading>
            <Heading
              headingSize={2}
              classes='px-18 text-primaryD tracking-tight'
            >
              In High-Passion Sports Streaming
            </Heading>
          </div>
          <div className='flex flex-1 flex-col items-center justify-center'>
            <Heading
              headingSize={4}
              classes='px-18 pb-4 text-primaryD tracking-tight'
            >
              ClashTV offers:
            </Heading>
            <Heading
              headingSize={4}
              classes='px-18 text-primaryD tracking-tight'
            >
              - The #1 global destination for live street basketball
            </Heading>
            <Heading
              headingSize={4}
              classes='px-18 text-primaryD tracking-tight'
            >
              - Exciting combat sports. MMA, Bare Knuckle Boxing and more
            </Heading>
            <Heading
              headingSize={4}
              classes='px-18 text-primaryD tracking-tight'
            >
              - Sign-up to receive 1,000 Clash Coins for free to use for in-app
              purchases.
            </Heading>
          </div>
        </div>
      </GridContainer>
    </div>
  );
};

export default NoAuthContainer;
