/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import { UserContext } from '../context/user';
import { EventsContext } from '../context/events';

import Avatar from './common/Avatar';
import Spinner from './system/Spinner';

const loadLimit = 10;

const EventLeaderBoard = ({ eventId }) => {
  const userContext = useContext(UserContext);
  const eventsContext = useContext(EventsContext);

  const { getUserDataFromDBById } = userContext;
  const { getEventLeaderBoard } = eventsContext;

  const [users, setUsers] = useState([]);
  const [ranking, setRanking] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadLeaderBoard = async () => {
    const res = await getEventLeaderBoard(eventId);

    if (!res) {
      return [];
    }

    const usersArr = [];

    for (const id in res) {
      if (Object.hasOwnProperty.call(res, id)) {
        const element = res[id];
        usersArr.push({ id, points: res[id] });
      }
    }

    const sortedResult = usersArr.sort((a, b) => b.points - a.points);
    setRanking(sortedResult);

    return sortedResult;
  };

  const getUser = async (userId, points) => {
    const user = await getUserDataFromDBById(userId);

    if (!user) {
      return null;
    }

    user.eventPoints = points;

    return user;
  };

  const loadUsers = async (startIndex, limit) => {
    const newUsers = [];
    setLoading(true);

    for (let i = startIndex; i < startIndex + limit; i++) {
      const row = ranking[i];

      if (!row) {
        break;
      }

      const user = await getUser(row.id, row.points);
      if (user) {
        newUsers.push(user);
      }
    }

    setUsers([...users, ...newUsers]);
    setLoading(false);
  };

  const loadMore = async () => {
    await loadUsers(users.length, loadLimit);
  };

  useEffect(async () => {
    await loadLeaderBoard();
  }, []);

  useEffect(() => {
    if (ranking.length > 0 && users.length === 0) {
      loadUsers(0, loadLimit);
    }
  }, [ranking]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: ranking.length > users.length || false,
    onLoadMore: loadMore,
  });

  if (!ranking || ranking.length === 0) {
    return null;
  }

  const renderUser = (user) => {
    return (
      <div className='event-leaderboard-user'>
        <Avatar imgSrc={user?.image?._url || null} imgSize={5} />
        <div className='event-leaderboard-username'>{user.displayName}</div>
      </div>
    );
  };

  return (
    <div className='event-leaderboard-container'>
      <table>
        <thead>
          <tr>
            <th>Battle Leaderboard</th>
            <th>Players</th>
            <th>Points Gained</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) => {
            return (
              <tr key={user.id}>
                <td>{i + 1}</td>
                <td>{renderUser(user)}</td>
                <td>{user.eventPoints}</td>
              </tr>
            );
          })}
          {ranking.length > users.length && (
            <tr ref={sentryRef}>
              <td></td>
              <td className='py-4'>
                <Spinner size='small' classes='float-left ml-4' />
              </td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EventLeaderBoard;
