import React, { Component } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { SponsorRoomsContext } from './sponsorRooms';
import {
  listSponsorRooms,
  listFollowingsByUserID,
  sponsorRoomByUserID,
  getSponsorRoom,
  sponsorRoomByCategorisationID,
  sponsorRoomBySeo,
} from '../graphql/queries';
import { customListFollowingsBySponsorRoomID } from '../graphql/customQueries';
import {
  createFollowing,
  deleteFollowing,
  createSponsorRoom,
  updateSponsorRoom,
} from '../graphql/mutations';
import { logError, resizeImage } from '../helpers';
import awsExports from '../aws-exports';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class SponsorRoomsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rooms: [],
      currentUserRoom: null,
      followedRooms: [],
      loading: false,
      error: null,
      loadingFollowers: false,
      roomFollowers: [],
      filteredFollowers: [],
      roomFollowersNextToken: null,
      filterNextToken: null,
      loadRooms: this.loadRooms,
      loadUserFollowings: this.loadUserFollowings,
      toggleFollowRoom: this.toggleFollowRoom,
      clearRooms: this.clearRooms,
      createRoom: this.createRoom,
      updateRoom: this.updateRoom,
      loadRoomFollowers: this.loadRoomFollowers,
      loadMoreRoomFollowers: this.loadMoreRoomFollowers,
      filterRoomFollowers: this.filterRoomFollowers,
      filterMoreRoomFollowers: this.filterMoreRoomFollowers,
      getRoomsByUserId: this.getRoomsByUserId,
      getRoomById: this.getRoomById,
      getRoomsByCategorisationID: this.getRoomsByCategorisationID,
      getRoomBySeo: this.getRoomBySeo,
    };
  }

  getRoomsByCategorisationID = async (categoryId) => {
    try {
      const res = await API.graphql(
        graphqlOperation(sponsorRoomByCategorisationID, {
          roomCategorisationID: categoryId,
          limit: 50,
        })
      );

      const rooms = res.data.sponsorRoomByCategorisationID.items;

      return { success: true, rooms };
    } catch (error) {
      return { success: false, rooms: [] };
    }
  };

  getRoomById = async (id) => {
    try {
      const res = await API.graphql(graphqlOperation(getSponsorRoom, { id }));
      return res.data.getSponsorRoom || null;
    } catch (error) {
      logError('getRoomById', error);
      return null;
    }
  };

  getRoomsByUserId = async (userID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(sponsorRoomByUserID, { userID })
      );

      return res.data.sponsorRoomByUserID.items || null;
    } catch (error) {
      logError('getRoomsByUserId', error);
      return null;
    }
  };

  getS3ImageObject = async (s3PutRes) => {
    const s3Url = await this.getS3Url(s3PutRes.key);

    if (s3Url) {
      return {
        bucket: awsExports['aws_user_files_s3_bucket'],
        region: awsExports['aws_user_files_s3_bucket_region'],
        key: s3PutRes.key,
        _url: s3Url,
      };
    }

    return null;
  };

  getS3Url = async (key) => {
    const s3Url = `${imgBaseUrl}${key}?w=120`;
    this.setState({ userImageUrl: s3Url });

    return s3Url;
  };

  createRoom = async (userID, details) => {
    if (!details.title || details.title.trim().length === 0) {
      logError('Error creating room without title');
      this.setState({ error: 'Rooms must have title' });

      return;
    }

    try {
      if (details.logoFile) {
        const resizedLogoFile = await resizeImage(details.logoFile);
        const fileType = resizedLogoFile.type;

        if (!fileType.includes('image')) {
          return { success: false, error: new Error('File is not an image') };
        }

        const ext = fileType.substring(fileType.indexOf('/') + 1);
        const fileName = `${Date.now()}-logo-${userID}.${ext}`;
        const s3PutRes = await Storage.put(fileName, resizedLogoFile, {
          contentType: fileType,
        });

        if (s3PutRes) {
          details.image = await this.getS3ImageObject(s3PutRes);
        }

        delete details.logoFile;
      }

      if (details.coverFile) {
        const resizedCoverFile = await resizeImage(details.coverFile, {
          width: 1600,
          height: 1600,
        });
        const fileType = resizedCoverFile.type;

        if (!fileType.includes('image')) {
          return { success: false, error: new Error('File is not an image') };
        }

        const ext = fileType.substring(fileType.indexOf('/') + 1);
        const fileName = `${Date.now()}-cover-${userID}.${ext}`;
        const s3PutRes = await Storage.put(fileName, resizedCoverFile, {
          contentType: fileType,
        });

        if (s3PutRes) {
          details.coverPhoto = await this.getS3ImageObject(s3PutRes);
        }

        delete details.coverFile;
      }

      if (details.image && details.title && details.roomCategorisationID) {
        details.isLive = true;
      }

      const res = await API.graphql(
        graphqlOperation(createSponsorRoom, {
          input: { userID, ...details, followers: 0 },
        })
      );

      if (!res?.data?.createSponsorRoom) {
        logError('Room was not created');

        throw new Error('Room was not created');
      }

      this.setState({
        currentUserRoom: res.data.createSponsorRoom,
        error: null,
      });
    } catch (error) {
      logError('Error creating room', error);
      this.setState({ error: 'Error creating room' });
    }
  };

  updateRoom = async (roomId, details) => {
    const oldValues = this.state.currentUserRoom;
    try {
      if (details.logoFile) {
        const resizedLogoFile = await resizeImage(details.logoFile);
        const fileType = resizedLogoFile.type;

        if (!fileType.includes('image')) {
          return { success: false, error: new Error('File is not an image') };
        }

        const ext = fileType.substring(fileType.indexOf('/') + 1);
        const fileName = `${Date.now()}-logo-${roomId}.${ext}`;
        const s3PutRes = await Storage.put(fileName, resizedLogoFile, {
          contentType: fileType,
        });

        if (s3PutRes) {
          details.image = await this.getS3ImageObject(s3PutRes);
        }

        delete details.logoFile;
      }

      if (details.coverFile) {
        const resizedCoverFile = await resizeImage(details.coverFile, {
          width: 1600,
          height: 1600,
        });
        const fileType = resizedCoverFile.type;

        if (!fileType.includes('image')) {
          return { success: false, error: new Error('File is not an image') };
        }

        const ext = fileType.substring(fileType.indexOf('/') + 1);
        const fileName = `${Date.now()}-cover-${roomId}.${ext}`;
        const s3PutRes = await Storage.put(fileName, resizedCoverFile, {
          contentType: fileType,
        });

        if (s3PutRes) {
          details.coverPhoto = await this.getS3ImageObject(s3PutRes);
        }

        delete details.coverFile;
      }

      if (
        (details.image || oldValues.image) &&
        (details.title || oldValues.title) &&
        (details.roomCategorisationID || oldValues.roomCategorisationID)
      ) {
        details.isLive = true;
      }

      const res = await API.graphql(
        graphqlOperation(updateSponsorRoom, {
          input: { id: roomId, ...details },
        })
      );

      if (res?.data?.updateSponsorRoom?.image) {
        res.data.updateSponsorRoom.image._url = this.getImageUrl(
          res.data.updateSponsorRoom.image
        );
      }

      this.setState({
        currentUserRoom: res.data.updateSponsorRoom,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({ error: 'Error creating room' });
    }
  };

  clearRooms = () => {
    this.setState({ currentUserRoom: null, rooms: [], followedRooms: [] });
  };

  loadRooms = async (currentUserId, force = false) => {
    // if (this.state.rooms.length > 0 && !force) {
    //   return;
    // }

    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listSponsorRooms));
      const items = [];

      if (res?.data?.listSponsorRooms?.items) {
        for (const room of res.data.listSponsorRooms.items) {
          if (room.image) {
            room.image._url = this.getImageUrl(room.image);
          }

          items.push(room);
        }
      }

      const sorted = items.sort((a, b) => a.order - b.order);
      const currentUserRoom = sorted.find((room) => {
        return room.userID && room.userID === currentUserId;
      });

      this.setState({
        loading: false,
        rooms: sorted,
        currentUserRoom: currentUserRoom || null,
        error: null,
      });
    } catch (err) {
      logError(err);
      this.setState({
        loading: false,
        error: 'Error loading sponsor rooms',
        currentUserRoom: null,
      });
    }
  };

  loadUserFollowings = async (userID) => {
    if (!userID) {
      return;
    }
    try {
      const res = await API.graphql(
        graphqlOperation(listFollowingsByUserID, { userID })
      );
      const items = [];

      if (res?.data?.listFollowingsByUserID?.items) {
        for (const room of res.data.listFollowingsByUserID.items) {
          if (room.image) {
            room.image._url = this.getImageUrl(room.image);
          }

          items.push(room);
        }
      }

      this.setState({
        followedRooms: items,
        error: null,
      });
    } catch (err) {
      logError(err);
      this.setState({ error: 'Error loading user followings' });
    }
  };

  getImageUrl = (image) => {
    const s3Key = image?.key;

    if (s3Key) {
      return `${imgBaseUrl}${s3Key}?w=360`;
    }

    return null;
  };

  toggleFollowRoom = async (roomId, userId, followerDisplayName) => {
    const followedRoom = this.state.followedRooms.find(
      (room) => room.sponsorRoomID === roomId
    );

    if (followedRoom) {
      return await this.deleteRoomFollowing(followedRoom.id);
    }

    return await this.createRoomFollowing(roomId, userId, followerDisplayName);
  };

  createRoomFollowing = async (
    sponsorRoomID,
    userID,
    followerDisplayName = ''
  ) => {
    try {
      const res = await API.graphql(
        graphqlOperation(createFollowing, {
          input: {
            sponsorRoomID,
            userID,
            followerDisplayName: followerDisplayName.toLowerCase(),
          },
        })
      );

      const room = res?.data?.createFollowing || null;
      if (room) {
        if (room.image) {
          room.image._url = this.getImageUrl(room.image);
        }

        this.setState({
          followedRooms: [...this.state.followedRooms, room],
          error: null,
        });
      }
    } catch (error) {
      logError(error);
      this.setState({ error: 'Error creating following' });
    }
  };

  deleteRoomFollowing = async (followingId) => {
    try {
      await API.graphql(
        graphqlOperation(deleteFollowing, { input: { id: followingId } })
      );

      this.setState({
        followedRooms: this.state.followedRooms.filter(
          (following) => following.id !== followingId
        ),
      });
    } catch (error) {
      logError(error);
      this.setState({ error: 'Error deleting following' });
    }
  };

  filterRoomFollowers = async (
    sponsorRoomID,
    userDisplayNameSearchTerm = null
  ) => {
    this.setState({ filteringFollowers: true });

    try {
      const input = { sponsorRoomID, limit: 10, nextToken: null };

      if (userDisplayNameSearchTerm) {
        input.filter = {
          followerDisplayName: {
            contains: userDisplayNameSearchTerm.toLowerCase(),
          },
        };
      }

      const res = await API.graphql(
        graphqlOperation(customListFollowingsBySponsorRoomID, input)
      );

      const followers = [];
      for (const item of res.data.listFollowingsBySponsorRoomID.items) {
        if (item.user) {
          followers.push(item.user);
        }
      }

      this.setState({
        filteredFollowers: followers,
        filteringFollowers: false,
        filterNextToken: res.data.listFollowingsBySponsorRoomID.nextToken,
        error: null,
      });
    } catch (err) {
      logError(err);
      this.setState({
        filteredFollowers: [],
        filteringFollowers: false,
        error: 'Error filtering user followings',
        filterNextToken: null,
      });
    }
  };

  filterMoreRoomFollowers = async (
    sponsorRoomID,
    userDisplayNameSearchTerm = null
  ) => {
    if (this.state.filterNextToken) {
      this.setState({ filteringFollowers: true });

      try {
        const input = {
          sponsorRoomID,
          limit: 10,
          nextToken: this.state.filterNextToken,
        };

        if (userDisplayNameSearchTerm) {
          input.filter = {
            followerDisplayName: {
              contains: userDisplayNameSearchTerm.toLowerCase(),
            },
          };
        }

        const res = await API.graphql(
          graphqlOperation(customListFollowingsBySponsorRoomID, input)
        );

        const followers = [];
        for (const item of res.data.listFollowingsBySponsorRoomID.items) {
          if (item.user) {
            followers.push(item.user);
          }
        }

        this.setState({
          filteredFollowers: [...this.state.filteredFollowers, ...followers],
          filteringFollowers: false,
          filterNextToken: res.data.listFollowingsBySponsorRoomID.nextToken,
          error: null,
        });
      } catch (error) {
        logError(error);
        this.setState({
          filteredFollowers: [],
          filteringFollowers: false,
          error: 'Error filtering more user followings',
          filterNextToken: null,
        });
      }
    }
  };

  loadRoomFollowers = async (sponsorRoomID) => {
    this.setState({ loadingFollowers: true });

    try {
      const input = { sponsorRoomID };

      const res = await API.graphql(
        graphqlOperation(customListFollowingsBySponsorRoomID, input)
      );

      const followers = [];
      for (const item of res.data.listFollowingsBySponsorRoomID.items) {
        if (item.user) {
          followers.push(item.user);
        }
      }

      this.setState({
        roomFollowers: followers,
        loadingFollowers: false,
        error: null,
        roomFollowersNextToken:
          res.data.listFollowingsBySponsorRoomID.nextToken,
      });
    } catch (err) {
      logError(err);
      this.setState({
        loadingFollowers: false,
        error: 'Error loading user followings',
        roomFollowers: [],
        roomFollowersNextToken: null,
      });
    }
  };

  loadMoreRoomFollowers = async (sponsorRoomID) => {};

  getRoomBySeo = async (seoUrl) => {
    try {
      const res = await API.graphql(
        graphqlOperation(sponsorRoomBySeo, { seoUrl, nextToken: null })
      );

      return res.data.sponsorRoomBySeo.items[0] || null;
    } catch (error) {
      logError('Error getting room by seo url', error);
      return null;
    }
  };

  render() {
    return (
      <SponsorRoomsContext.Provider value={this.state}>
        {this.props.children}
      </SponsorRoomsContext.Provider>
    );
  }
}
