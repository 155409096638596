import React from 'react';
import Heading from '../typography/Heading';

const ShopItem = ({
  shopItemId,
  headline,
  multiply,
  coins,
  bgImage,
  classes,
  onClick,
  isInInventory,
}) => {
  const handleClick = () => {
    if (isInInventory) {
      return false;
    }
    onClick(shopItemId);
  };
  return (
    <div
      onClick={handleClick}
      style={{ maxWidth: 238, width: '100%', height: 191 }}
      className={`stickers-shop-item flex flex-col justify-between pt-3 px-4 relative ${
        isInInventory ? 'bg-white cursor-default' : 'bg-primaryB cursor-pointer'
      } ${classes}`}
    >
      <div
        className='absolute inset-0'
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'right, left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          opacity: isInInventory ? 0.38 : 1,
        }}
      ></div>
      <Heading
        headingSize={5}
        classes={`w-5/6 mb-3 font-display font-bold tracking-tight ${
          isInInventory ? 'text-primaryADark' : 'text-white'
        }`}
      >
        {headline}
      </Heading>
      {isInInventory ? (
        <span className='font-display font-bold text-sm text-primaryADark mb-4'>
          You already
          <br />
          have this pack!
        </span>
      ) : (
        <>
          {multiply && (
            <span className='block mt-12 font-display font-bold text-xl text-white tracking-tight'>
              {multiply} for
            </span>
          )}
          <span className='block font-display font-bold text-xl tracking-tight text-secondaryC opacity-60 mb-4'>
            {coins} Coins
          </span>
        </>
      )}
    </div>
  );
};

export default ShopItem;
