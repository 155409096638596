import React, { useContext, useEffect, useState } from 'react';

import DashLayout from '../dashboard/DashLayout';
import BuyEventModal from '../dashboard/BuyEventModal';
import CardSkeleton from '../../skeletons/CardSkeleton';

import { EventsContext } from '../../context/events';
import { UserContext } from '../../context/user';
import { TransactionsContext } from '../../context/transactions';
import { AlertsContext } from '../../context/alerts';
import { InventoryContext } from '../../context/inventory';
import Heading from '../typography/Heading';

const PromotedLiveEvents = ({ roomIds }) => {
  const eventsContext = useContext(EventsContext);
  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);
  const alertsContext = useContext(AlertsContext);
  const inventoryContext = useContext(InventoryContext);

  const { getLiveEventsByRoomIds } = eventsContext;
  const { getCurrentUserId } = userContext;
  const { loadPPVEvent, myPPVList } = transactionsContext;
  const { addAlert } = alertsContext;
  const { coins } = inventoryContext;

  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buyModalData, setBuyModalData] = useState(null);

  const onPPVBuyAttempt = (ppvData) => {
    if (coins < ppvData.price) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      return;
    }

    setBuyModalData(ppvData);
  };

  const loadPPVData = async (eventId) => {
    await loadPPVEvent(getCurrentUserId(), eventId);
  };

  const loadEvents = async () => {
    setLoading(true);

    const { success, events: liveEvents } = await getLiveEventsByRoomIds(
      roomIds
    );

    if (!success) {
      setLoading(false);
      return;
    }

    setEvents(liveEvents);
    setLoading(false);

    for (const e of liveEvents) {
      await loadPPVData(e.id);
    }
  };

  useEffect(() => {
    if (roomIds.length > 0 && events.length === 0) {
      loadEvents();
    }
  }, [roomIds]);

  const renderLoading = () => {
    return Array(6)
      .fill()
      .map((item, index) => <CardSkeleton key={index} />);
  };

  if (!loading && events.length === 0) {
    return (
      <Heading headingSize={5}>There are no live events at the moment</Heading>
    );
  }

  return (
    <div className='relative grid gap-8 mr-8 auto-rows-card-row mb-8'>
      <DashLayout
        events={events}
        loading={false}
        showBuyModal={onPPVBuyAttempt}
        ppvItems={myPPVList}
        currentUserId={getCurrentUserId()}
      />
      {loading && renderLoading()}
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
      />
    </div>
  );
};

export default PromotedLiveEvents;
