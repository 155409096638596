import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EventsContext } from '../context/events';
import { UserContext } from '../context/user';

const Circle = ({ className }) => {
  const getCircleClassName = () => {
    let buttonClassName =
      'absolute w-6 h-6 border bg-white border-primaryC rounded-full';

    if (className) {
      buttonClassName += ` ${className}`;
    }

    return buttonClassName;
  };
  return <div className={getCircleClassName()}></div>;
};

const DonationButton = ({ className, onClick, eventId }) => {
  const [visible, setVisible] = useState(false);
  const eventsContext = useContext(EventsContext);
  const userContext = useContext(UserContext);

  const { getCurrentEvent } = eventsContext;
  const { getCurrentUserId, getUserDataFromDBById } = userContext;

  useEffect(async () => {
    const e = await getCurrentEvent(eventId);
    const creatorId = e?.hostSponsorRoom?.userID;

    if (!creatorId) {
      setVisible(false);
      return;
    }

    const creator = await getUserDataFromDBById(creatorId);

    if (!creator) {
      setVisible(false);
      return;
    }

    const userId = getCurrentUserId();

    if (userId !== creatorId) {
      setVisible(true);
      return;
    }
  }, []);

  const getButtonClassName = () => {
    let buttonClassName = 'relative';

    if (className) {
      buttonClassName += ` ${className}`;
    }

    return buttonClassName;
  };

  if (!visible) {
    return null;
  }

  return (
    <button className={getButtonClassName()} onClick={onClick}>
      <Circle />
      <Circle className='left-1' />
      <span className='ml-8'>Donate</span>
    </button>
  );
};

DonationButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

DonationButton.defaultProps = {
  className: '',
  onClick: () => {},
};

export default DonationButton;
