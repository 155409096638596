/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { getType } from 'typesafe-actions';
import { Handler } from 'store/types';
import createMiddleware from 'store/middlewareCreator';

import SubscriptionAPIClient from 'infrastructure/api/profile/SubscriptionAPIClient';

import { puchaseSubscription } from './actions';

const requestSubscriptionPurchase: Handler<ReturnType<typeof puchaseSubscription.request>> = async (store, next, action) => {
  next(action);

  const { dispatch } = store;
  const {
    description,
    id,
    title,
    type,
    userID,
  } = action.payload;

  try {
    const res = await SubscriptionAPIClient.buySubscription({
      sponsorRoomPayPerViewID: id,
      isMonthPayPerView: type === 'month',
      userID,
      title,
      description,
    });

    const now = new Date();

    dispatch(puchaseSubscription.success({
      createdAt: res.createdAt,
      id: res.id,
      month: res.isMonthPayPerView
        ? `${now.getFullYear()}-${now.getMonth() + 1}`
        : null,
      sponsorRoomPayPerViewID: res.sponsorRoomPayPerViewID,
      updatedAt: res.updatedAt,
      userID: res.userID,
    }));
  } catch (err) {
    dispatch(puchaseSubscription.failure(`Error trying to buy subscription with id: ${id}`));
  }
};

const eventMiddleware = createMiddleware({
  [getType(puchaseSubscription.request)]: requestSubscriptionPurchase,
});

export default eventMiddleware;
