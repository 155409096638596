import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Label from './Label';
import Icon from '../typography/Icon';

const TextArea = ({
  label,
  id,
  disabled,
  textAreaRef,
  placeholder,
  hint,
  error,
  success,
  initialValue,
  autoFocus,
  classes,
  rows,
  wrapClasses,
  icon,
}) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleOnChange = (event) => {
    setValue(event.target.value);
  };

  let areaClassName = 'base-input';
  let currentIcon = icon;
  let iconClassName = 'icon-right';

  if (icon) {
    areaClassName += ' with-icon';
  }

  if (success) {
    areaClassName += ' input-success';
    iconClassName += ' success';
    currentIcon = 'check';
  }

  if (error) {
    areaClassName += ' input-error';
    iconClassName += ' error';
    currentIcon = 'error';
  }

  const renderIcon = () => {
    if (success || error) {
      return (
        <div className={`input-icon-right`}>
          <Icon size={24} name={currentIcon} classes={`${iconClassName}`} />
        </div>
      );
    }

    return (
      <div className={`input-icon-right`}>
        <Icon size={24} name={currentIcon} classes={`${iconClassName}`} />
      </div>
    );
  };

  return (
    <div className={`flex flex-col ${wrapClasses}`}>
      {Object.keys(label).length != 0 ? (
        <Label
          text={label}
          inputId={id}
          classes={disabled ? 'mb-5 disabled' : 'mb-5'}
        />
      ) : null}
      <div className='relative'>
        <textarea
          ref={textAreaRef}
          autoComplete='off'
          id={id}
          placeholder={placeholder}
          value={value}
          onChange={handleOnChange}
          disabled={disabled}
          autoFocus={autoFocus}
          className={`${areaClassName} ${classes}`}
          rows={rows}
          style={{ resize: 'none' }}
        />
        {icon && renderIcon()}
      </div>
      {hint && <p className={`input-hint ${error ? 'error' : ''}`}>{hint}</p>}
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  id: PropTypes.string,
  disabled: PropTypes.bool,
  textAreaRef: PropTypes.object,
  placeholder: PropTypes.string,
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.bool,
  success: PropTypes.bool,
  initialValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  classes: PropTypes.string,
  wrapClasses: PropTypes.string,
  rows: PropTypes.number,
  icon: PropTypes.string,
};

TextArea.defaultProp = {
  id: 'text-area',
  disabled: false,
  textAreaRef: { current: null },
  placeholder: '',
  hint: '',
  error: false,
  success: false,
  initialValue: '',
  autoFocus: false,
  classes: '',
  wrapClasses: '',
  rows: 2,
  icon: '',
};

export default TextArea;
