import React from 'react';
import Tooltip from '../system/Tooltip';
import Icon from '../typography/Icon';
import PropTypes from 'prop-types';
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from 'react-circular-progressbar';
import history from '../../history';
import routes from '../../routes';

const GameItem = ({
  amount,
  classes,
  btnSize,
  buyTooltipTxt,
  img,
  onClick,
  background,
  buyable,
  showAmount,
  showProgressBar,
  btnColor,
  fullCooldown,
  cooldownPassed,
  passive,
}) => {
  const delayed = fullCooldown !== cooldownPassed && cooldownPassed !== null;

  const handleClick = () => {
    if (delayed) {
      return;
    }
    onClick();
  };

  const renderContent = () => {
    return (
      <>
        <img src={img} alt='action' className='gamification-img' />
        {showAmount && <span className={`btn-amount`}>{amount}</span>}
        {buyable && amount === 0 && (
          <button
            className='buy-btn'
            onClick={() => history.push(routes.myGoods)}
          >
            <Icon size={18} name='plus' classes='' />
            <Tooltip
              arrowSide='none'
              txt={buyTooltipTxt}
              classes='buy-tooltip'
            />
          </button>
        )}
      </>
    );
  };

  const getBackground = () => {
    if (showProgressBar) {
      if (amount === 0) {
        return '#fff';
      }

      return 'transparent';
    }

    if (background === 'white') {
      return '#fff';
    }

    if (amount === 0) {
      return 'transparent';
    }

    return '#2500dc';
  };

  return (
    <div
      className={`game-item-container ${amount == 0 ? 'empty' : ''} ${
        delayed && !passive ? 'delay cursor-default' : ''
      } ${classes}`}
      style={{
        width: btnSize * 2,
        height: btnSize * 2,
        backgroundColor: getBackground(),
      }}
      onClick={handleClick}
    >
      {showProgressBar && amount > 0 ? (
        <CircularProgressbarWithChildren
          background={true}
          value={cooldownPassed || fullCooldown}
          minValue={0}
          maxValue={fullCooldown}
          strokeWidth={4}
          styles={buildStyles({
            rotation: 0,
            strokeLinecap: 'butt',
            pathColor: btnColor,
            trailColor: '#fff',
            backgroundColor: background === 'white' ? '#fff' : '#2500dc',
          })}
        >
          {renderContent()}
        </CircularProgressbarWithChildren>
      ) : (
        renderContent()
      )}
    </div>
  );
};

GameItem.propTypes = {
  classes: PropTypes.string,
  btnSize: PropTypes.number,
  amount: PropTypes.number,
  buyTooltipTxt: PropTypes.string,
  background: PropTypes.string,
  buyable: PropTypes.bool,
  showAmount: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  btnColor: PropTypes.string,
  fullCooldown: PropTypes.number,
  cooldownPassed: PropTypes.number,
  passive: PropTypes.bool,
};

GameItem.defaultProps = {
  classes: '',
  buyTooltipTxt: '',
  btnSize: 32,
  background: 'blue',
  buyable: true,
  showAmount: true,
  showProgressBar: false,
  btnColor: '#ff004f',
  passive: false,
  onClick: () => {},
};

export default GameItem;
