import React, { Component } from 'react';
import UserProvider from './UserProvider';
import EventsProvider from './EventsProvider';
import SponsorRoomsProvider from './SponsorRoomsProvider';
import RankingProvider from './RankingProvider';
import GameActionsProvider from './GameActionsProvider';
import BoostersProvider from './BoostersProvider';
import LeftDrawerProvider from './LeftDrawerProvider';
import ShopItemsProvider from './ShopItemsProvider';
import TransactionsProvider from './TransactionsProvider';
import AlertsProvider from './AlertsProvider';
import ChatProvider from './ChatProvider';
import StickersProvider from './StickersProvider';
import CoinsProvider from './CoinsProvider';
import AvatarsProvider from './AvatarsProvider';
import PaymentsProvider from './PaymentsProvider';
import InventoryProvider from './InventoryProvider';
import NotificationsProvider from './NotificationsProvider';
import RoomCategoriesProvider from './RoomCategoriesProvider';
import EventCategoriesProvider from './EventCategoriesProvider';
import ParticipantsProvider from './ParticipantsProvider';
import InvitationsProvider from './InvitationsProvider';
import PollsProvider from './PollsProvider';
import DevicesProvider from './DevicesProvider';
import StreamProvider from './StreamProvider';
import CookieConsentProvider from './CookieConsentProvider';
import StickyCommentsProvider from './StickyCommentsProvider';

export class Context extends Component {
  render() {
    return (
      <UserProvider>
        <AvatarsProvider>
          <EventsProvider>
            <SponsorRoomsProvider>
              <GameActionsProvider>
                <BoostersProvider>
                  <CoinsProvider>
                    <RankingProvider>
                      <ShopItemsProvider>
                        <TransactionsProvider>
                          <LeftDrawerProvider>
                            <AlertsProvider>
                              <ChatProvider>
                                <StickersProvider>
                                  <PaymentsProvider>
                                    <InventoryProvider>
                                      <NotificationsProvider>
                                        <RoomCategoriesProvider>
                                          <EventCategoriesProvider>
                                            <ParticipantsProvider>
                                              <InvitationsProvider>
                                                <PollsProvider>
                                                  <DevicesProvider>
                                                    <StreamProvider>
                                                      <CookieConsentProvider>
                                                        <StickyCommentsProvider>
                                                          {this.props.children}
                                                        </StickyCommentsProvider>
                                                      </CookieConsentProvider>
                                                    </StreamProvider>
                                                  </DevicesProvider>
                                                </PollsProvider>
                                              </InvitationsProvider>
                                            </ParticipantsProvider>
                                          </EventCategoriesProvider>
                                        </RoomCategoriesProvider>
                                      </NotificationsProvider>
                                    </InventoryProvider>
                                  </PaymentsProvider>
                                </StickersProvider>
                              </ChatProvider>
                            </AlertsProvider>
                          </LeftDrawerProvider>
                        </TransactionsProvider>
                      </ShopItemsProvider>
                    </RankingProvider>
                  </CoinsProvider>
                </BoostersProvider>
              </GameActionsProvider>
            </SponsorRoomsProvider>
          </EventsProvider>
        </AvatarsProvider>
      </UserProvider>
    );
  }
}

export const ContextProvider = Context;
