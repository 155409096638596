/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { Handler } from 'store/types';
import { getType } from 'typesafe-actions';
import createMiddleware from 'store/middlewareCreator';

import PpvEarningsAPIClient from 'infrastructure/api/profile/PpvEarningsAPIClient';

import { loadPpvPassEarnings, ppvPassEarningsTableMounted } from './actions';

const fetchPpvPassEarnings: Handler<ReturnType<typeof ppvPassEarningsTableMounted>> = async (store, next, action) => {
  next(action);

  const { dispatch } = store;
  const { payload } = action;

  try {
    const response = await PpvEarningsAPIClient.loadPpvPassEarnings(payload);

    dispatch(loadPpvPassEarnings.success(response))
  } catch (err) {
    dispatch(loadPpvPassEarnings.failure('Load PPV Earnings error'));
    console.log(err);
  }

}

const handlers: Record<string, Handler<any>> = {
  [getType(ppvPassEarningsTableMounted)]: fetchPpvPassEarnings,
};

const ppvPassEarningsMiddleware = createMiddleware(handlers);

export default ppvPassEarningsMiddleware;
