import React, { useState } from 'react';
import DropdownItem from './DropdownItem';
import Icon from '../typography/Icon';
import ClickawayListener from '../system/ClickawayListener';
import PropTypes from 'prop-types';

const DropdownList = ({
  children,
  items,
  classes,
  showArrow,
  buttonClasses,
  containerClasses,
  fullWidth,
  maxHeight,
  shouldPositionOnTop,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const handleOpen = (e) => {
    e.stopPropagation();
    if (disabled) {
      return;
    }

    setIsOpen(!isOpen);
  };

  const handleItemClick = (e, item) => {
    item.onClick(e);
    setIsOpen(false);
  };

  const containerClassNames = containerClasses || 'relative inline-block';

  const buttonClassNames =
    buttonClasses || 'flex items-center inline-block focus:outline-none';

  return (
    <div className={containerClassNames}>
      <button
        className={`${buttonClassNames} ${
          disabled ? 'text-secondaryB cursor-default' : ''
        }`}
        onClick={handleOpen}
      >
        {children}
        {showArrow && (
          <Icon
            name={isOpen ? 'selectorUp' : 'selector'}
            size={10}
            classes='inline-block ml-2'
            color={disabled ? '#6b7280' : '#5200e9'}
          />
        )}
      </button>
      {isOpen && (
        <ClickawayListener onClick={handleClickOutside}>
          <ul
            style={maxHeight ? { maxHeight, overflow: 'auto' } : {}}
            className={`dropdown-list ${classes} ${
              shouldPositionOnTop ? 'bottom-0' : ''
            } ${fullWidth ? 'w-full' : ''}`}
          >
            {items.map((item, i) => (
              <DropdownItem
                key={i + item.text}
                text={item.text}
                onClick={(e) => handleItemClick(e, item)}
              />
            ))}
          </ul>
        </ClickawayListener>
      )}
    </div>
  );
};

DropdownList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  classes: PropTypes.string,
  showArrow: PropTypes.bool,
  items: PropTypes.array,
  fullWidth: PropTypes.bool,
  maxHeight: PropTypes.number,
  shouldPositionOnTop: PropTypes.bool,
  disabled: PropTypes.bool,
};

DropdownList.defaultProps = {
  classes: '',
  showArrow: true,
  items: [],
  fullWidth: false,
  shouldPositionOnTop: false,
  disabled: false,
};

export default DropdownList;
