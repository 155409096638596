/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { selectUpToDateSubscriptions } from 'store/subscriptions/selectors';
import { selectPurchasedSubscriptions } from 'store/purchasedSubscriptions/selectors';
import {
  selectEventSubscriptionId,
  selectEventSubscriptionType,
} from 'store/event/selectors';

import {
  eventPageMounted,
  puchaseSubscription,
} from 'store/event/actions';

import SubscriptionAPIClient from 'infrastructure/api/profile/SubscriptionAPIClient';

import { InventoryContext } from 'context/inventory';
import { AlertsContext } from 'context/alerts';
import { EventsContext } from 'context/events';
import { InvitationsContext } from 'context/invitations';
import { UserContext } from 'context/user';
import { ParticipantsContext } from 'context/participants';
import { TransactionsContext } from 'context/transactions';

import Spinner from 'components/system/Spinner';
import Chat from 'components/chat/Chat';
import BuyEventModal from 'components/dashboard/BuyEventModal';

import useStatistics from 'hooks/useStatistics';

import { logError } from '../../helpers';
import { useLogEvent } from '../../firebase';
import routes from '../../routes';
import history from '../../history';

import EventVideoContainer from './components/EventVideoContainer';
import EventActionBar from './components/EventActionBar';
import EventDetails from './components/EventDetails';

let interval = null;

const EventPage = () => {
  const dispatch = useDispatch();

  const seoUrl = useParams().eventId;
  const eventsContext       = useContext(EventsContext);
  const invitationsContext  = useContext(InvitationsContext);
  const userContext         = useContext(UserContext);
  const participantsContext = useContext(ParticipantsContext);
  const transactionsContext = useContext(TransactionsContext);
  const inventoryContext    = useContext(InventoryContext);
  const alertsContext       = useContext(AlertsContext);

  const {
    getCurrentEvent,
    createEventSubscription,
    removeEventSubscription,
    getEventIdBySeoUrl,
  } = eventsContext;

  const {
    acceptedInviteAsParticipant,
    subscribeToInvitationUpdateById
  } = invitationsContext;

  const {
    getCurrentUserId,
    user,
    loading: userIsLoading,
    getCurrentUserDisplayName,
  } = userContext;

  const {
    coins,
    updateCoins,
  } = inventoryContext;
  const { addAlert } = alertsContext;

  const { logEvent } = useLogEvent();
  const { addParticipant, removeParticipant } = participantsContext;
  const {
    loadPPVEvent,
    buyPPVEvent,
  } = transactionsContext;

  const [eventId, setEventId] = useState(null);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dimentions, setDimentions] = useState({ width: 0, height: 0 });
  const [chatIsHidden, setChatIsHidden] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [showUserIsLive, setShowUserIsLive] = useState(false);

  const [gamificationDisabled, setGamificationDisabled] = useState(true);
  const [buyModalData, setBuyModalData] = useState(null);
  const [activeEndedTab, setActiveEndedTab] = useState('teaser');
  const [initialState, setInitialState] = useState('');

  const [isOwnEvent, setIsOwnEvent] = useState(false);
  const [isPPVPurchased, setIsPPVPurchased] = useState(false);

  const subscriptions   = useSelector(selectUpToDateSubscriptions(currentEvent?.startTime));
  const purchasedSubs   = useSelector(selectPurchasedSubscriptions);
  const purchasedSubTye = useSelector(selectEventSubscriptionType);
  const purchasedSubID  = useSelector(selectEventSubscriptionId);

  const chatStateRef = useRef(chatIsHidden);
  const mediaContainerRef = useRef(null);

  const { addUserToWatchers, updateUserInWatchers } = useStatistics();

  const setChatState = (state) => {
    setChatIsHidden(state);
    chatStateRef.current = state;
    if (!state) {
      logEvent('enable_chat_overlay', { eventId, category: 'consumer' });
    } else {
      logEvent('disable_chat_overlay', { eventId, category: 'consumer' });
    }
  };

  const calculatePlayerSize = () => {
    const hidden = chatStateRef.current;
    const margins = hidden ? 2 * 16 : 3 * 16;
    const chatWidth = hidden ? 0 : 320;
    const navbarHeight = 56;

    const maxPlayerHeight = window.innerHeight - 2 * 16 - navbarHeight;

    let playerWidth = window.innerWidth - margins - chatWidth;
    let playerHeight = playerWidth / (16 / 9);

    if (playerHeight > maxPlayerHeight) {
      playerHeight = maxPlayerHeight;
      playerWidth = playerHeight / (9 / 16);
    }

    return { width: playerWidth, height: playerHeight };
  };

  const getChatHeight = () => window.innerHeight - 56 - 32;

  const setPlayerContainerSize = () => {
    setDimentions(calculatePlayerSize());
  };

  const handleInviteUpdated = (invite) => {
    if (invite.status === 'merged') {
      setShowUserIsLive(true);
    } else {
      setShowUserIsLive(false);
    }
  };

  const purchasePPVOption = async ({ id, type }) => {
    if (type === 'event') return await purchaseEventPPV()

    return purchaseSubscription(id, type);
  };

  const purchaseSubscription = (id, type) => {
    const subToPurchase = subscriptions.find((s) => s.id === id);
    if (!subToPurchase) return;

    const isMonthly = type === 'month'
    const price = isMonthly
      ? subToPurchase.monthPrice
      : subToPurchase.price;

    if (coins < price) {
      addAlert('danger', `You don't have enough coins to purchase this ${isMonthly ? 'monthly' : 'season'} pass`);
      return;
    }

    dispatch(puchaseSubscription.request({
      description: subToPurchase.description,
      id: subToPurchase.id,
      title: subToPurchase.title,
      type,
      userID: getCurrentUserId(),
    }));
  };

  const purchaseEventPPV = async () => {
    if (coins < currentEvent.ppvPrice) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      return;
    }

    const newCoinsAmount = coins - currentEvent.ppvPrice;
    const transaction = await buyPPVEvent(
      currentEvent.id,
      getCurrentUserId(),
      getCurrentUserDisplayName(),
      currentEvent.title
    );

    if (transaction.success) {
      updateCoins(newCoinsAmount);
      const params = {
        category: 'monetization',
        virtual_currency_name: 'Coins',
        value: currentEvent.ppvPrice,
        item_id: 'PPV',
        item_name: 'PPV',
        eventId: currentEvent.id,
        event_title: currentEvent.title,
      };
      logEvent('spend_virtual_currency', params);

      setIsPPVPurchased(true);
      setActiveEndedTab('video');

      addAlert('success', `You successfully purchased ${currentEvent.title}`);
    }
  };

  useEffect(async () => {
    if (!currentEvent?.state) {
      return;
    }

    const state = currentEvent.state;

    if (state === 'live') {
      const newData = await addUserToWatchers(getCurrentUserId(), eventId);
      interval = setInterval(() => {
        updateUserInWatchers(newData.id);
      }, 60 * 1000);
    }

    if (state === 'ended') {
      clearInterval(interval);
      if (initialState === 'live') {
        logEvents('completed_watching', 'stream', 'Infinity');
      }
    } else {
      setInitialState(currentEvent.state);
    }
  }, [currentEvent?.state]);

  useEffect(() => {
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setPlayerContainerSize);

    setPlayerContainerSize();

    return () => {
      window.removeEventListener('resize', setPlayerContainerSize);
    };
  }, []);

  useEffect(() => {
    setPlayerContainerSize();
  }, [chatIsHidden]);

  const handleRemoveParticipant = () => {
    removeParticipant(eventId, getCurrentUserId());
  };

  useEffect(() => {
    window.addEventListener('beforeunload', handleRemoveParticipant);
    return () => {
      window.removeEventListener('beforeunload', handleRemoveParticipant);
      handleRemoveParticipant();
    };
  }, []);

  const loadEvent = async () => {
    if (!userIsLoading && !user) {
      history.replace({
        pathname: routes.login,
        state: { from: history.location },
      });
      return;
    }

    setLoading(true);
    const e = await getCurrentEvent(eventId);
    setCurrentEvent(e);

    if (e.state === 'inactive') {
      history.replace(routes.notFound);
      return;
    }

    const { ppv: purchasedEvent } = await loadPPVEvent(
      getCurrentUserId(),
      eventId
    );

    const isEventPurchased = purchasedEvent !== null;
    setIsPPVPurchased(isEventPurchased);

    const eventCreatorId = e?.hostSponsorRoom?.userID;
    const eventIsCreatedByCurrentUser = eventCreatorId === getCurrentUserId();
    setIsOwnEvent(eventIsCreatedByCurrentUser);

    if (e.state === 'live') {
      setGamificationDisabled(!!e.paused);

      if (e.ppv) {
        if (!purchasedEvent && !eventIsCreatedByCurrentUser) {
          setBuyModalData({ price: e.ppvPrice, title: e.title, id: eventId });
        }
      }
    } else {
      if (e.state === 'ended') {
        if (e.ppv) {
          if (purchasedEvent || eventIsCreatedByCurrentUser) {
            setActiveEndedTab('video');
          }
        } else {
          setActiveEndedTab('video');
        }
      }
      setGamificationDisabled(true);
    }

    if (eventId && getCurrentUserId() && getCurrentUserDisplayName()) {
      addParticipant(eventId, getCurrentUserId(), getCurrentUserDisplayName());
    }

    setLoading(false);
  };

  const logEvents = (phrase, type, duration) => {
    const params = { eventId, category: 'consumer' };
    params[`${type}_category`] = currentEvent.category;
    params[`${type}_title`] = currentEvent.title;
    params[`${type}_url`] = currentEvent.videoUrl;
    params[`${type}_duration`] = duration;
    logEvent(phrase, params);
  };

  const updateEvent = async (event) => {
    Object.keys(event).forEach((k) => event[k] === null && delete event[k]);

    setCurrentEvent((prevState) => ({ ...prevState, ...event }));

    if (event.state === 'inactive') {
      history.replace(routes.notFound);
      return;
    }

    if (event.state === 'live') {
      setGamificationDisabled(!!event.paused);
    } else {
      setGamificationDisabled(false);
    }
  };

  const handleUpdateError = (error) => {
    logError(error);
  };

  useEffect(async () => {
    const id = await getEventIdBySeoUrl(seoUrl);
    setEventId(id);
  }, [seoUrl]);

  useEffect(() => {
    let eventSubscription;
    if (eventId) {
      loadEvent();

      eventSubscription = createEventSubscription(
        eventId,
        updateEvent,
        handleUpdateError
      );
    }

    return () => {
      if (eventSubscription) {
        removeEventSubscription(eventSubscription);
      }
    };
  }, [eventId]);

  useEffect(() => {
    if (currentEvent?.hostSponsorRoomID) {
      dispatch(eventPageMounted({
        roomID: currentEvent.hostSponsorRoomID,
        userID: getCurrentUserId(),
      }));
    }
  }, [currentEvent?.hostSponsorRoomID]);

  useEffect(() => {
    if (!isOwnEvent && !isPPVPurchased && subscriptions.length && purchasedSubs.length) {
      const subIds = subscriptions.map((s) => s.id);
      setIsPPVPurchased(purchasedSubs.some(
        (p) => subIds.includes(p.sponsorRoomPayPerViewID)
      ));
    }
  }, [isOwnEvent, isPPVPurchased, subscriptions.length, purchasedSubs.length]);

  useEffect(() => {
    if (!isOwnEvent && subscriptions.length) {
      const roomPPVSub = SubscriptionAPIClient.subscribeForRoomSubscriptions(getCurrentUserId()).subscribe({
        next: ({ provider, value }) => {
          const {
            sponsorRoomPayPerViewID: id,
            month
          } = value.data.onSponsorRoomPayPerViewListItemCreateByUserID;

          const sub = subscriptions.find((p) => p.id === id);
          if (!sub) return;

          const price = month
            ? sub.monthPrice
            : sub.price;

          updateCoins(coins - price);

          const params = {
            category: 'monetization',
            virtual_currency_name: 'Coins',
            value: price,
            item_id: id,
            item_name: sub.title,
          };
          logEvent('spend_virtual_currency', params);

          addAlert('success', `You successfully purchased ${sub.title}`);
        },
        error: (err) => {
          logError('onSponsorRoomPayPerViewListItemCreateByUserID', err);
          setActiveEndedTab('teaser');
          setIsPPVPurchased(false);
        }
      });

      return () => {
        roomPPVSub.unsubscribe();
      };
    }
  }, [isOwnEvent, subscriptions.length]);

  useEffect(() => {
    if (purchasedSubTye && purchasedSubID) {
      setIsPPVPurchased(true);
      setActiveEndedTab('video');
    }
  }, [purchasedSubTye, purchasedSubID]);

  useEffect(async () => {
    let sub = null;

    if (acceptedInviteAsParticipant) {
      sub = await subscribeToInvitationUpdateById(
        acceptedInviteAsParticipant.id,
        handleInviteUpdated
      );
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [acceptedInviteAsParticipant]);

  if (!currentEvent) {
    return (
      <div className='mt-navbarHeight'>
        <Helmet>
          <title>Event | ClashTV</title>
        </Helmet>
        {loading && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <Spinner size='small' />
          </div>
        )}
      </div>
    );
  }

  let eventUrl = {};

  if (seoUrl) {
    eventUrl = {
      desktop: `${window.location.origin}/event/${seoUrl}`,
      ios: `/event/${eventId}`,
      android: `/event/${eventId}`,
    };
  }

  if (loading) {
    return (
      <>
        <Helmet>
          <title>Event | ClashTV</title>
        </Helmet>
        <div className='event-page-container p-4'>
          <div className='absolute inset-0 flex items-center justify-center'>
            <Spinner size='small' />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${currentEvent.title} | Event | ClashTV`}</title>
      </Helmet>
      <div className='event-page-container p-4'>
        <div
          style={{ width: dimentions.width }}
          className='flex flex-col mr-4 rounded-lg'
        >
          <EventVideoContainer
            ref={mediaContainerRef}
            width={dimentions.width}
            height={dimentions.height}
            event={currentEvent}
            gamificationDisabled={gamificationDisabled}
            activeEndedTab={activeEndedTab}
            setActiveEndedTab={setActiveEndedTab}
          />
          <EventActionBar
            event={currentEvent}
            eventUrl={eventUrl}
            setActiveEndedTab={setActiveEndedTab}
            isOwnEvent={isOwnEvent}
            isPPVPurchased={isPPVPurchased}
            onPurchase={purchasePPVOption}
          />
          <EventDetails
            event={currentEvent}
            chatIsHidden={chatIsHidden}
            setChatState={setChatState}
            hasPPV={!isOwnEvent && !isPPVPurchased && currentEvent.ppvPrice}
          />
        </div>
        {
          currentEvent && !currentEvent.disableChat && !chatIsHidden && (
            <div
              className='event-chat-wrapper'
              style={{ height: getChatHeight() }}
            >
              <Chat
                inPreview={false}
                inEvent={true}
                isCensored={currentEvent.censorChat}
                eventId={currentEvent.id}
                isHidden={chatIsHidden}
                isHiddenFunc={setChatState}
                eventState={currentEvent.state}
                mediaContainerRef={mediaContainerRef?.current}
                moderatorName={currentEvent.moderator?.displayName}
                moderatorId={currentEvent.moderatorID}
                eventCreatorId={currentEvent?.hostSponsorRoom?.userID}
              />
            </div>
          )
        }
      </div>
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
        onBuy={() => setActiveEndedTab('video')}
      />
    </>
  );
};

export default EventPage;
