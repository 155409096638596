import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import 'react-slideshow-image/dist/styles.css';
import CardContent from './CardContent';
import CardCover from './CardCover';
import routes from '../../../routes';
import history from '../../../history';

import { LeftDrawerContext } from '../../../context/leftDrawer';

const Card = ({
  id,
  hostName,
  hostUser,
  hostSponsorRoom,
  images,
  date,
  title,
  description,
  contentTheme,
  classes,
  type,
  eventState,
  isPPV,
  price,
  showBuyModal,
  isPurchased,
  eventIsCreatedByCurrentUser,
  roomId,
  contentType,
  seoUrl,
}) => {
  const leftDrawerContext = useContext(LeftDrawerContext);
  const { toggleEventPreview } = leftDrawerContext;
  const { state } = history.location;

  // const isPurchasable =
  //   isPPV &&
  //   price &&
  //   !eventIsCreatedByCurrentUser &&
  //   !isPurchased &&
  //   eventState !== 'ended';

  useEffect(() => {
    if (state?.eventPreviewId && id === state.eventPreviewId) {
      toggleEventPreview(state.eventPreviewId);
    }
  });

  let imageUrls = images.standard;

  if (type === 'landscape' && images.landscape && images.landscape.length > 0) {
    imageUrls = images.landscape;
  }

  if (type === 'vertical' && images.vertical && images.vertical.length > 0) {
    imageUrls = images.vertical;
  }

  const onEventClick = (e) => {
    e.stopPropagation();

    // if (eventState == 'live') {
    //   if (eventPreviewIsOpen) {
    //     toggleEventPreview(null);
    //   }

    //   if (isPurchasable) {
    //     showBuyModal({
    //       id,
    //       price,
    //       title,
    //     });

    //     return;
    //   }

    //   history.push(`${routes.event}/${id}`);
    // } else {
    //   // toggleEventPreview(id);
    //   history.push(`${routes.event}/${id}`);
    // }
    if (contentType === 'videos') {
      return history.push(
        routes.video.replace(':videoId', seoUrl ? seoUrl : id)
      );
    }

    history.push(`${routes.event}/${seoUrl ? seoUrl : id}`);
  };

  return (
    <div
      id={`event-${id}`}
      className={`flex flex-col relative rounded-lg overflow-hidden cursor-pointer col-span-3 ${classes}`}
      onClick={onEventClick}
    >
      <CardCover images={imageUrls} />
      <CardContent
        eventId={id}
        title={title}
        date={date}
        description={description}
        hostUser={hostUser}
        hostSponsorRoom={hostSponsorRoom}
        hostName={hostName}
        state={eventState}
        isPPV={isPPV}
        price={price}
        showBuyModal={showBuyModal}
        isPurchased={isPurchased}
        eventIsCreatedByCurrentUser={eventIsCreatedByCurrentUser}
        roomId={roomId}
      />
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.string.isRequired,
  images: PropTypes.object.isRequired,
  date: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  contentTheme: PropTypes.oneOf(['white', 'transparent']),
  classes: PropTypes.string,
  type: PropTypes.oneOf(['square', 'vertical', 'landscape']),
  isArchived: PropTypes.bool,
};

Card.defaultProps = {
  images: {},
  date: '',
  title: '',
  description: '',
  contentTheme: 'transparent',
  classes: '',
  type: 'square',
};

export default Card;
