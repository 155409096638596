import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Heading from '../components/typography/Heading';
import Icon from '../components/typography/Icon';

import { InventoryContext } from '../context/inventory';
import { useLogEvent } from '../firebase';

import headerImage from '../assets/donation.webp';
import themeColors from '../theme-colors';
import routes from '../routes';
import Button from '../components/common/Button';
import { EventsContext } from '../context/events';
import { AlertsContext } from '../context/alerts';
import { UserContext } from '../context/user';
import { TransactionsContext } from '../context/transactions';

const DonationDialog = ({ isOpen, onClose, eventId, onSubmit }) => {
  const [value, setValue] = useState(150);
  const [error, setError] = useState(null);
  const [creator, setCreator] = useState(null);
  const [eventData, setEventData] = useState(null);

  const inventoryContext = useContext(InventoryContext);
  const eventsContext = useContext(EventsContext);
  const alertsContext = useContext(AlertsContext);
  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);

  const { coins, updateCoins } = inventoryContext;
  const { getCurrentEvent } = eventsContext;
  const { addAlert } = alertsContext;
  const { getUserDataFromDBById, getCurrentUserDisplayName, getCurrentUserId } =
    userContext;
  const { donate } = transactionsContext;
  const { logEvent } = useLogEvent();

  useEffect(async () => {
    const e = await getCurrentEvent(eventId);

    const creatorId = e?.hostSponsorRoom?.userID;

    if (!creatorId) {
      // addAlert('danger', 'Unknown creator!');
      return;
    }

    const user = await getUserDataFromDBById(creatorId);

    if (!user) {
      // addAlert('danger', 'Unknown creator!');
      return;
    }

    setCreator(user);
    setEventData(e);
  }, []);

  const handleSubmit = async () => {
    const valueNum = parseInt(value);

    if (`${value}`.includes(',') || `${value}`.includes('.')) {
      setError(
        <span className='label-sm text-center my-4 text-primaryB'>
          Coins value must be an integer!
        </span>
      );

      return;
    }

    if (`${value}`.includes('-')) {
      setError(
        <span className='label-sm text-center my-4 text-primaryB'>
          Coins value cannot be negative!
        </span>
      );

      return;
    }

    if (valueNum === 0) {
      setError(
        <span className='label-sm text-center my-4 text-primaryB'>
          Coins value cannot be 0!
        </span>
      );

      return;
    }

    if (valueNum > parseInt(coins)) {
      setError(
        <span className='label-sm text-center my-4 text-primaryB'>
          You don&apos;t have enough coins to donate. Go to{' '}
          <Link className='underline' to={routes.accountCoins}>
            My Goods
          </Link>{' '}
          to get more coins.
        </span>
      );
      return;
    }

    const { success } = await donate(
      eventId,
      getCurrentUserId(),
      valueNum,
      getCurrentUserDisplayName(),
      eventData.title
    );

    if (!success) {
      addAlert('danger', 'Donation was not successful');
      return;
    }

    const params = {
      category: 'monetization',
      virtual_currency_name: 'Coins',
      value: valueNum,
      item_id: 'Donation',
      item_name: 'Donation',
      creator_id: creator.id,
      creator_name: creator.displayName,
      eventId: eventData.id,
    };

    logEvent('spend_virtual_currency', params);

    onClose();
    addAlert('success', 'Donation successful');
    onSubmit(`#donation_${valueNum}`, '', false);
    updateCoins(coins - valueNum);
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    let newError = null;

    if (`${newValue}`.includes(',') || `${newValue}`.includes('.')) {
      newError = (
        <span className='label-sm text-center my-4 text-primaryB'>
          Coins value must be an integer!
        </span>
      );
    }

    setValue(newValue);
    setError(newError);
  };

  return (
    <div
      style={{ maxHeight: 260, height: '100%', marginBottom: 103 }}
      className={`donations-menu ${
        isOpen ? 'opacity-100 visible bottom-4' : 'opacity-0 invisible bottom-8'
      }`}
    >
      <div
        className='donations-menu-header'
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundPosition: 'right, left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <Heading headingSize={6} classes='text-white'>
          Show support for
        </Heading>
        {creator && (
          <Heading headingSize={6} classes='text-white font-bold'>
            {creator.displayName}
          </Heading>
        )}
        <button
          className='w-6 h-6 flex items-center justify-center absolute top-1 right-1 focus:outline-none'
          onClick={onClose}
        >
          <Icon name='error' color={themeColors.primaryD} size={12} />
        </button>
      </div>
      <div className='donations-menu-body'>
        {!error ? (
          <span className='label-sm text-center my-4'>
            Send some coins to the creator so they can keep delivering great
            content!
          </span>
        ) : (
          error
        )}
        <div className='donations-menu-input-container'>
          <div className='donations-menu-input'>
            <input
              className='label-sm w-full mr-1'
              placeholder='150'
              type='number'
              value={value}
              onChange={handleChange}
            />
            <span className='label-sm text-primaryALight'>Coins</span>
          </div>
          <Button
            buttonSize='small'
            buttonType='tertiary'
            disabled={!value || value === '0'}
            buttonOnClick={handleSubmit}
          >
            Donate
          </Button>
        </div>
      </div>
    </div>
  );
};

DonationDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  eventId: PropTypes.string,
  onSubmit: PropTypes.func,
};
DonationDialog.defaultProps = {
  isOpen: false,
  onClose: () => {},
  eventId: '',
  onSubmit: () => {},
};

export default DonationDialog;
