import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';

const PromotedRoom = ({ className, roomId, image, title }) => {
  let containerClassName = 'promoted-room-container';

  if (className) {
    containerClassName += ` ${className}`;
  }
  return (
    <Link
      className={containerClassName}
      to={routes.room.replace(':tab', 'all').replace(':roomId', roomId)}
    >
      <div className='flex flex-1 items-center justify-center flex-col'>
        {image ? (
          <img src={image} className='w-26 h-26 object-cover rounded-full' />
        ) : (
          <Icon
            name='camera'
            color={'#dadada'}
            size={48}
            classes='w-26 h-26 object-cover rounded-full bg-secondaryC'
          />
        )}
        <Heading headingSize={5} classes='text-center'>
          {title}
        </Heading>
      </div>
    </Link>
  );
};

export default PromotedRoom;
