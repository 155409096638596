/* eslint-disable prettier/prettier */
import React, { useContext, useState } from 'react';

import { UserContext } from 'context/user';
import { BoostersContext } from 'context/boosters';
import { GameActionsContext } from 'context/gameActions';
import { InventoryContext } from 'context/inventory';

import blueClapImage from 'assets/blue-clap.png';
import redClapImage from 'assets/red-clap.png';
import blueTomatoImage from 'assets/blue-tomato.png';
import redTomatoImage from 'assets/red-tomato.png';

import GameItem from 'components/gamification/GameItem';
import ClapButton from 'components/gamification/ClapButton';
import ActiveBoosters from 'components/event/ActiveBoosters';

import { useLogEvent } from '../../../../../firebase';

const Gamification = ({ eventId }) => {
  const userContext        = useContext(UserContext);
  const inventoryContext   = useContext(InventoryContext);
  const gameActionsContext = useContext(GameActionsContext);
  const boostersContext    = useContext(BoostersContext);

  const { activeBoosters } = boostersContext;
  const { getCurrentUserId } = userContext;
  const { inventory, updateInventory } = inventoryContext;

  const {
    actions,
    cooldowns,
    doAction,
    startTomatoAnimation
  } = gameActionsContext;

  const [clapDisabled, setClapDisabled] = useState(false);

  const { logEvent } = useLogEvent();

  const tomatoAction = actions.find((action) => action.name === 'Tomato');
  const tomatoInInventory = (tomatoAction && inventory?.[tomatoAction.id]) || null;

  const handleLocalScoreUpdate = (action) => {
    let shouldDisableClap = true;
    const clapCooldown = 500;

    if (action.name === 'Clap') {
      const hasActiveBooster = activeBoosters &&
      Object.keys(activeBoosters).length > 0 &&
      activeBoosters.constructor === Object;

      if (hasActiveBooster) {
        for (const id in activeBoosters) {
          if (activeBoosters[id]) {
            const booster = activeBoosters[id];
            if (booster.type === 'cooldown') {
              shouldDisableClap = false;
              setClapDisabled(false);
            }
          }
        }
      }

      if (clapDisabled) return;
    }

    if (shouldDisableClap) {
      setClapDisabled(true);
      setTimeout(() => setClapDisabled(false), clapCooldown);
    }
  };

  const handleClap = (contestor) => {
    if (clapDisabled) return;

    const clapAction = actions.find((action) => action.name === 'Clap');

    if (clapAction) {
      handleLocalScoreUpdate(clapAction);
      doAction(
        getCurrentUserId(),
        eventId,
        clapAction.id,
        contestor
      );
      logEvent('clap', { eventId, category: 'engagement' });
    }
  };

  const handleTomatoThrow = (contestorColor, amountAvailable) => {
    if (!amountAvailable) {
      return;
    }

    const contestor = contestorColor === 'blue' ? 'A' : 'B';
    const tomatoAction = actions.find((action) => action.name === 'Tomato');

    if (tomatoAction && inventory[tomatoAction.id]) {
      startTomatoAnimation(contestorColor);
      handleLocalScoreUpdate(tomatoAction);
      doAction(
        getCurrentUserId(),
        eventId,
        tomatoAction.id,
        contestor,
        tomatoAction.defaltCooldown
      );

      updateInventory(tomatoAction.id, -1);
      logEvent('throw_tomato', { eventId, category: 'engagement' });
    }
  };

  return (
    <div className='gamification-container'>
      <div className='event-contestor-actions contestorA'>
        <ClapButton
          img={blueClapImage}
          onClick={() => handleClap('A')}
          classes='mr-4'
          btnColor='#022ae0'
          btnSize={25}
        />
        <GameItem
          img={blueTomatoImage}
          amount={tomatoInInventory?.count || 0}
          classes={`mr-4 ${
            tomatoInInventory?.count ? 'cursor-pointer' : 'cursor-default'
          }`}
          background='white'
          showAmount={false}
          showProgressBar={true}
          onClick={() => handleTomatoThrow('blue', tomatoInInventory?.count || 0)}
          btnColor='#022ae0'
          fullCooldown={tomatoAction?.defaltCooldown || 100}
          cooldownPassed={cooldowns?.[tomatoAction?.id] || null}
          btnSize={25}
          buyable={false}
        />
      </div>
      <ActiveBoosters />
      <div className='event-contestor-actions contestorB'>
        <GameItem
          img={redTomatoImage}
          amount={tomatoInInventory?.count || 0}
          classes={`mr-4 ${
            tomatoInInventory?.count ? 'cursor-pointer' : 'cursor-default'
          }`}
          background='white'
          showAmount={false}
          showProgressBar={true}
          onClick={() => handleTomatoThrow('red', tomatoInInventory?.count || 0)}
          btnColor='#ff004f'
          fullCooldown={tomatoAction?.defaltCooldown || 100}
          cooldownPassed={cooldowns?.[tomatoAction?.id] || null}
          btnSize={25}
          buyable={false}
        />
        <ClapButton
          img={redClapImage}
          onClick={() => handleClap('B')}
          classes=''
          btnColor='#ff004f'
          btnSize={25}
        />
      </div>
    </div>
  );
};

export default Gamification;
