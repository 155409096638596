import React from 'react';
import { Helmet } from 'react-helmet';

const SubscriptionPassListMeta = () => (
  <Helmet>
    <title>My Premium Content Pricing | ClashTV</title>
  </Helmet>
);

export default SubscriptionPassListMeta;
