/* eslint-disable new-cap */
import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../typography/Heading';
import { logError } from '../../helpers';

const CardContent = ({
  theme,
  date,
  title,
  description,
  classes,
  children,
}) => {
  if (!title || !theme) {
    logError("Component CardContent not rendered, because 'title' missing.");
    return null;
  }

  const formatDate = (date) => {
    const options = {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };

    return `${new Intl.DateTimeFormat('en-US', options).format(
      new Date(date)
    )} LT`;
  };

  const bgColor = theme == 'white' ? 'primaryD' : 'transparent';
  const primaryColor = theme == 'white' ? 'primaryC' : 'primaryD';
  const secondaryColor = theme == 'white' ? 'secondaryA' : 'primaryD';

  return (
    <div className={`rounded-b-lg bg-${bgColor} ${classes}`}>
      <time
        dateTime={date}
        className={`inline-block mb-1 font-display font-medium text-xs leading-4.5 tracking-tight text-${secondaryColor}`}
      >
        {formatDate(date)}
      </time>
      <Heading
        headingSize={6}
        classes={`mb-1 font-bold tracking-tight text-${primaryColor}`}
      >
        {title}
      </Heading>
      <p
        className={`font-display font-medium m-0 text-sm leading-5 text-${secondaryColor}`}
      >
        {description}
      </p>
      {children}
    </div>
  );
};

CardContent.propTypes = {
  theme: PropTypes.oneOf(['white', 'transparent']),
  date: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  classes: PropTypes.string,
};

CardContent.defaultProps = {
  theme: 'white',
  date: '',
  title: '',
  description: '',
  classes: '',
};

export default CardContent;
