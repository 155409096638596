/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { getType } from 'typesafe-actions';
import { Handler } from 'store/types';
import createMiddleware from 'store/middlewareCreator';

import SubscriptionAPIClient from 'infrastructure/api/profile/SubscriptionAPIClient';

import { createSubscriptionPass } from './actions';

const createSubPass: Handler<ReturnType<typeof createSubscriptionPass.request>> = async (store, next, action) => {
  next(action);

  const { dispatch } = store;
  const { payload } = action;

  try {
    if (payload.startDate && payload.endDate) {
      const allSubsciptions = await SubscriptionAPIClient.loadSubsciptions(payload.sponsorRoomId);

      const subWithConflictingDate = allSubsciptions.some((s: any) => {
        const newDate           = new Date(payload.startDate as string);
        const existingStartDate = new Date(s.startDate);
        const existingEndDate   = new Date(s.endDate);

        return newDate > existingStartDate && newDate < existingEndDate;
      })

      if (subWithConflictingDate) {
        dispatch(createSubscriptionPass.failure('Subscription for a similar period already exists.'));
        return;
      }
    }

    await SubscriptionAPIClient.createSubscriptionsPass(payload);

    dispatch(createSubscriptionPass.success());
  } catch (err) {
    dispatch(createSubscriptionPass.failure('Mandatory fields are now provided.'));
  }
};

const subsciptionCreateMiddleware = createMiddleware({
  [getType(createSubscriptionPass.request)]: createSubPass,
});

export default subsciptionCreateMiddleware;
