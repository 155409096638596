import React from 'react';
import { Helmet } from 'react-helmet';
import PrivacyComponent from '../clash_privacy';

const Privacy = () => {
  return (
    <div className='grid grid-cols-12 gap-4 mx-4'>
      <Helmet>
        <title>Privacy | ClashTV</title>
      </Helmet>
      <div className='legal'>
        <PrivacyComponent />
      </div>
    </div>
  );
};

export default Privacy;
