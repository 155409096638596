import React from 'react';
import Heading from '../typography/Heading';
import Paragraph from '../typography/Paragraph';
import Icon from '../typography/Icon';
import PropTypes from 'prop-types';

const Modal = ({
  isVisible,
  title,
  message,
  actions,
  handleClose,
  children,
  headingSize,
}) => {
  return (
    <>
      {isVisible && (
        <div className='modal-overlay'>
          <div className='modal-dialog flex flex-col'>
            <button
              onClick={handleClose}
              className='absolute right-4 top-4 text-primaryC'
            >
              <Icon size={13} name='error' />
            </button>
            <Heading headingSize={headingSize} classes='font-medium'>
              {title}
            </Heading>
            <Paragraph paragraphSize='large' classes='mb-14'>
              {message}
            </Paragraph>
            <div className='flex flex-1 flex-col h-full overflow-y-auto scrollable pt-4 pr-1'>
              {children}
            </div>
            <div className='flex justify-end items-center'>{actions}</div>
          </div>
        </div>
      )}
    </>
  );
};

Modal.propTypes = {
  isVisible: PropTypes.bool,
  actions: PropTypes.array,
  handleClose: PropTypes.func,
  title: PropTypes.any,
  message: PropTypes.string,
  headingSize: PropTypes.number,
};

Modal.defaultProps = {
  title: '',
  message: '',
  headingSize: 4,
};

export default Modal;
