import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import './Player.css';
import { logError } from '../../helpers';
import Spinner from '../system/Spinner';
import { useLogEvent } from '../../firebase';

let interval = null;

const Player = ({
  streamUrl,
  player,
  onPlay,
  onReady,
  logEvents,
  type,
  eventId,
  maxHeight,
  maxWidth,
}) => {
  const { IVSPlayer } = window;
  const { isPlayerSupported } = IVSPlayer;
  const { logEvent } = useLogEvent();

  const [loading, setLoading] = useState(true);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [logStart, setLogStart] = useState(false);
  const [log30sec, setLog30sec] = useState(false);
  const [logTime, setLogTime] = useState(0);

  const videoEl = useRef(null);

  useEffect(() => {
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (!logEvents) {
      return;
    }
    if (!logStart && Number.parseInt(progress) >= 3) {
      setLogStart(true);
      if (logTime === 100) {
        setLogTime(0);
      }
      if (type === 'ended') {
        logEvents('start_watching', 'video', duration);
      } else {
        logEvents('start_watching', 'stream', duration);
      }
    } else if (
      !log30sec &&
      Number.parseInt(progress) >= 30 &&
      type === 'live'
    ) {
      setLog30sec(true);
      logEvents('watches_30sec', 'stream', duration);
    }
    if (type === 'ended') {
      if (progress / duration >= 0.15 && logTime < 15) {
        logEvent('percentage_watched_video', {
          eventId: eventId,
          category: 'consumer',
          video_progress: '15%',
        });
        setLogTime(15);
      } else if (progress / duration >= 0.5 && logTime < 50) {
        logEvent('percentage_watched_video', {
          eventId: eventId,
          category: 'consumer',
          video_progress: '50%',
        });
        setLogTime(50);
      } else if (progress / duration >= 0.75 && logTime < 75) {
        logEvent('percentage_watched_video', {
          eventId: eventId,
          category: 'consumer',
          video_progress: '75%',
        });
        setLogTime(75);
      }
    }
  }, [progress]);

  useEffect(() => {
    if (!logEvents) {
      return;
    }
    if (logStart && type === 'ended') {
      const timer = setTimeout(() => {
        logEvents('watches_30sec', 'video', duration);
      }, 27000);
      return () => clearTimeout(timer);
    }
  }, [logStart]);

  useEffect(() => {
    const { ENDED, PLAYING, READY } = IVSPlayer.PlayerState;
    const { ERROR, TIME_UPDATE } = IVSPlayer.PlayerEventType;

    if (!isPlayerSupported) {
      console.warn(
        'The current browser does not support the Amazon IVS player.'
      );

      return;
    }

    const onStateChange = () => {
      if (!logEvents) {
        return;
      }
      const playerState = player.current.getState();

      logError(`Player State - ${playerState}`);
      setLoading(playerState !== PLAYING);

      if (playerState === PLAYING) {
        clearInterval(interval);
      }

      if (READY) {
        onReady(videoEl.current);
        onPlay();
        setDuration(player.current.getDuration());
      }

      // handle stream interruption
      if (playerState === ENDED) {
        if (type === 'ended') {
          logEvents('completed_watching', 'video', duration);
          logEvent('percentage_watched_video', {
            eventId: eventId,
            category: 'consumer',
            video_progress: '100%',
          });
          setLogTime(100);
          setLog30sec(false);
          setLogStart(false);
        }
        interval = setInterval(() => {
          if (playerState !== PLAYING) {
            player.current.load(streamUrl);
            player.current.play();
          }
        }, 10 * 1000);
      }
    };

    const onError = (err) => {
      setLoading(true);
      // try again, maybe video is not available yet
      setTimeout(() => {
        player.current.load(streamUrl);
        player.current.play();
      }, 10 * 1000);
    };

    const onTimeUpdate = (playedSeconds) => {
      setProgress(playedSeconds);
    };

    player.current = IVSPlayer.create();
    player.current.attachHTMLVideoElement(videoEl.current);
    player.current.load(streamUrl);
    player.current.play();
    player.current.addEventListener(READY, onStateChange);
    player.current.addEventListener(PLAYING, onStateChange);
    player.current.addEventListener(ENDED, onStateChange);
    player.current.addEventListener(ERROR, onError);
    player.current.addEventListener(TIME_UPDATE, onTimeUpdate);

    return () => {
      player.current.pause();
      player.current.removeEventListener(READY, onStateChange);
      player.current.removeEventListener(PLAYING, onStateChange);
      player.current.removeEventListener(ENDED, onStateChange);
      player.current.removeEventListener(ERROR, onError);
      player.current.removeEventListener(TIME_UPDATE, onTimeUpdate);
    };
  }, [IVSPlayer, isPlayerSupported, streamUrl]);

  if (!isPlayerSupported) {
    return null;
  }

  return (
    <>
      <video className='h-full w-auto' ref={videoEl} playsInline></video>
      {loading && (
        <div className='absolute inset-0 flex flex-1 items-center justify-center'>
          <Spinner size='small' />
        </div>
      )}
    </>
  );
};

Player.propTypes = {
  streamUrl: PropTypes.string,
  player: PropTypes.object,
  onPlay: PropTypes.func,
  maxHeight: PropTypes.number,
  maxWidth: PropTypes.number,
  onReady: PropTypes.func,
};

Player.defaultProps = {
  onPlay: () => {},
  onReady: () => {},
  maxHeight: 0,
  maxWidth: 0,
};

export default Player;
