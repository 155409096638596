/* eslint-disable prettier/prettier */
import React, { FC } from "react";
import TableRow from "components/table/TableRow";
import TableCell from "components/table/TableCell";
import { formatDate } from "helpers";

type Props = {
  id: string;
  title: string;
  price: string;
  monthPrice: string;
  description: string;
  startDate: string;
  endDate: string;
}

const PassRow: FC<Props> = ({
  id,
  title,
  price,
  monthPrice,
  startDate,
  endDate
}) => {
  const cellCasses = `
    font-numbers
    font-bold
    py-2
    text-sm
    text-secondaryA
    tracking-tight
  `

  return (
    <TableRow
      key={id}
      innerRef={null}
      classes={`border-b border-secondaryB`}
    >
      <TableCell
        variant='body'
        classes='font-bold py-2 pr-4'
      >
       {title}
      </TableCell>
      <TableCell
        variant='body'
        classes={cellCasses}
      >
        {price || '-'}
      </TableCell>
      <TableCell
        variant='body'
        classes={cellCasses}
      >
        {monthPrice || '-'}
      </TableCell>
      <TableCell
        variant='body'
        classes={cellCasses}
      >
        {formatDate(startDate)}
      </TableCell>
      <TableCell
        variant='body'
        classes={cellCasses}
      >
        {formatDate(endDate)}
      </TableCell>
    </TableRow>
  )
}

export default PassRow;
