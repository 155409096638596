/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Avatar from '../common/Avatar';
import { ParticipantsContext } from '../../context/participants';
import Icon from '../typography/Icon';
import colors from '../../theme-colors';
import Spinner from '../system/Spinner';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const Participant = ({ displayName, avatarKey, raisedHand }) => {
  return (
    <div className='participant-container'>
      <Avatar
        imgSrc={avatarKey ? `${imgBaseUrl}${avatarKey}` : null}
        imgSize={6}
        classes='mr-2'
      />
      <div className='participant-displayName'>{displayName}</div>
      <div>{raisedHand && <Icon name='hand' color={colors.primaryA} />}</div>
    </div>
  );
};

const ParticipantsList = ({ eventId, disabled }) => {
  const participantsContext = useContext(ParticipantsContext);
  const {
    loadParticipants,
    loadMore,
    participants,
    nextToken,
    loading,
    createParticipantsSubscriptions,
    removeParticipantsSubscription,
    clearParticipants,
  } = participantsContext;

  useEffect(async () => {
    let sub = null;
    if (eventId) {
      loadParticipants(eventId);
      sub = await createParticipantsSubscriptions(eventId);
    }

    return () => {
      removeParticipantsSubscription(sub);
    };
  }, []);

  useEffect(() => {
    return () => clearParticipants();
  }, []);

  const handleLoadMore = () => {
    if (eventId && nextToken) {
      loadMore(eventId);
    }
  };

  const renderParticipants = () => {
    return participants.map((p) => {
      return (
        <Participant
          key={p.id}
          displayName={p.displayName}
          avatarKey={p?.image?.key}
          raisedHand={p?.raisedHand}
        />
      );
    });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: nextToken || false,
    onLoadMore: handleLoadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  if (loading && (!participants || participants.length === 0)) {
    return (
      <div className='flex items-center justify-center'>
        <Spinner />
      </div>
    );
  }

  if (disabled) {
    return (
      <div className='chat-message in-creator flex flex-1'>
        Participants will be visible when event is live
      </div>
    );
  }

  return (
    <div ref={sentryRef} className='participants-container'>
      {renderParticipants()}
    </div>
  );
};

export default ParticipantsList;
