import React, { useContext } from 'react';

import Icon from '../typography/Icon';

import { InventoryContext } from '../../context/inventory';

const GoodsButton = ({ onClick }) => {
  const inventoryContext = useContext(InventoryContext);
  const { inventory } = inventoryContext;

  const getAmount = () => {
    let itemsCount = 0;

    for (const id in inventory) {
      if (Object.hasOwnProperty.call(inventory, id)) {
        const element = inventory[id];

        if (
          element &&
          (element.itemType === 'GameAction' || element.itemType === 'Avatar')
        ) {
          itemsCount += element.count;
        }
      }
    }

    return itemsCount;
  };
  return (
    <button className='event-goods-btn' onClick={onClick}>
      <span>{getAmount()}</span>
      <Icon name='star' size={24} />
    </button>
  );
};

export default GoodsButton;
