import React, { useContext } from 'react';
import { UserContext } from '../../context/user';
import { LeftDrawerContext } from '../../context/leftDrawer';
import DefaultAvatar from '../../dummy-avatar-300x300.jpg';

const ProfileAvatar = () => {
  const userContext = useContext(UserContext);
  const { updateCurrentUser, userImageUrl } = userContext;
  const leftDrawerContext = useContext(LeftDrawerContext);
  const { toggleUserAvatars } = leftDrawerContext;

  const getUserDataImageUrl = () => {
    return userImageUrl;
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      saveProfile(file);
    }
  };

  const saveProfile = async (file) => {
    const result = await updateCurrentUser({
      imgFile: file,
    });

    if (!result.success) {
      // handle errors
      return;
    }

    // return some success message
  };

  const toggleAvatars = (e) => {
    e.stopPropagation();
    toggleUserAvatars();
  };

  const imgSrc = getUserDataImageUrl() || DefaultAvatar;

  return (
    <div className='relative transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2'>
      <label htmlFor='imgFile' className='select-avatar'>
        <img
          src={imgSrc}
          alt='profile image'
          className='h-full w-full object-cover'
        />
      </label>
      <div className='flex items-center justify-center mb-6'>
        <button
          className='mr-8 text-primaryA text-base font-display leading-6 tracking-tight font-normal'
          onClick={toggleAvatars}
        >
          Select Avatar
        </button>
        <label
          htmlFor='imgFile'
          className='cursor-pointer text-primaryA text-base font-display leading-6 tracking-tight font-normal'
        >
          Upload Picture
        </label>
        <input
          id='imgFile'
          style={{ visibility: 'hidden', width: 0, height: 0 }}
          type='file'
          onChange={handleUpload}
          accept='image/png, image/jpeg'
        />
      </div>
    </div>
  );
};

export default ProfileAvatar;
