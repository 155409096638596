import React, { useState } from 'react';
// import PropTypes from 'prop-types';

import Icon from '../typography/Icon';

const ChatInput = ({
  classes,
  stickerFunc,
  stickerState,
  onSubmit,
  inPreview,
  inEvent,
  inCreator,
  disabled = false,
  placeholder,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [value, setValue] = useState('');

  const handleOnChange = (event) => {
    if (disabled) {
      return;
    }

    setValue(event.target.value);
    event.target.parentNode.dataset.replicatedValue = event.target.value;
  };

  const handleSubmit = () => {
    if (disabled) {
      return;
    }

    if (value.trim()) {
      onSubmit(value.trim());
      setValue('');
      const input = document.getElementById('chatInput');
      input.parentNode.dataset.replicatedValue = '';
    }
  };

  const handleKeyPress = (e) => {
    if (disabled) {
      return;
    }

    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();

      handleSubmit();
    }
  };

  const getClasses = () => {
    if (inPreview) {
      return 'in-preview';
    }

    if (inEvent) {
      return 'in-event';
    }

    if (inCreator) {
      return 'in-creator';
    }
  };

  const getIconColor = () => {
    if (inPreview) {
      return undefined;
    }

    if (inEvent) {
      return undefined;
    }

    if (inCreator) {
      return undefined;
    }
  };

  return (
    <>
      <div className={`grow-wrap ${getClasses()} ${classes}`}>
        <textarea
          name='chatInput'
          id='chatInput'
          rows='1'
          onFocus={() => (!disabled ? setIsFocused(true) : null)}
          onBlur={() => (!disabled ? setIsFocused(false) : null)}
          onChange={handleOnChange}
          placeholder={placeholder}
          onKeyPress={handleKeyPress}
          value={value}
          disabled={disabled}
        ></textarea>
        <button
          disabled={disabled}
          className={`emoji-btn ${
            stickerState ? 'text-primaryA' : 'text-primaryC'
          }`}
          onClick={!disabled ? stickerFunc : () => null}
        >
          <Icon name='smile-emoji' size={24} color={getIconColor()} />
        </button>
      </div>
      <button
        className={`chat-submit-bnt ${isFocused ? 'focus' : ''}`}
        onClick={handleSubmit}
        disabled={!value || disabled}
      >
        <Icon name='submit' size={24} color={getIconColor()} />
      </button>
    </>
  );
};

// ChatInput.propTypes = {
// };

// ChatInput.defaultProps = {
// };

export default ChatInput;
