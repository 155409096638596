import 'branch-sdk';

let initialized = false;

const init = () => {
  window.branch.init(process.env.REACT_APP_BRANCH_KEY, () => {
    initialized = true;
  });
};

const createLink = (linkData: any, cb: () => void) => {
  if (!initialized) init();

  if (window.branch) {
    window.branch.link(linkData, cb);
  }
};

export default {
  createLink,
};
