import React, { useContext, useEffect, useRef, useState } from 'react';
import RoomGroup from './RoomGroup';
import { RoomCategoriesContext } from '../../context/roomCategories';

const RoomGroups = ({ all, groupsNumber, setHasMoreGroups }) => {
  const roomCategoriesContext = useContext(RoomCategoriesContext);
  const { roomCategories, loadRoomCategories, loading } = roomCategoriesContext;
  const [groups, setGroups] = useState([]);

  const listRef = useRef(null);

  useEffect(() => {
    if (roomCategories.length === 0) {
      loadRoomCategories();
    }
  }, []);

  useEffect(() => {
    if (!all) {
      if (roomCategories.length <= groupsNumber) {
        setHasMoreGroups(false);
      } else {
        setHasMoreGroups(true);
      }
      setGroups(roomCategories.slice(0, groupsNumber));
    }
  }, [roomCategories.length, groupsNumber]);

  const renderGroups = () => {
    return all
      ? roomCategories.map((group) => (
          <RoomGroup key={group.id} group={group} all={all} />
        ))
      : groups.map((group) => (
          <RoomGroup key={group.id} group={group} all={all} />
        ));
  };

  return (
    <div className={'col-span-12'}>
      <div
        ref={listRef}
        className={`grid auto-rows-group-row gap-x-8 gap-y-6 ${
          all ? 'mb-16' : ''
        }`}
      >
        {loading && 'Loading...'}
        {!loading && roomCategories.length > 0 && renderGroups()}
      </div>
    </div>
  );
};

export default RoomGroups;
