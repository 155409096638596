import React, { useEffect, useRef, useState } from 'react';
import Label from '../form/Label';
import DropdownList from '../dropdown/DropdownList';

const SelectField = ({
  label,
  value,
  options,
  listFullWidth,
  listMaxHeight,
  inputClassName,
  disabled,
}) => {
  const [shouldPositionOnTop, setShouldPositionOnTop] = useState(false);
  const valueRef = useRef(null);

  const handleListPosition = () => {
    if (valueRef?.current) {
      const element = valueRef.current;
      const { bottom } = element.getBoundingClientRect();

      if (listMaxHeight) {
        return setShouldPositionOnTop(
          window.innerHeight - bottom < listMaxHeight
        );
      }

      return setShouldPositionOnTop(false);
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleListPosition);
    handleListPosition();
    return () => document.removeEventListener('scroll', handleListPosition);
  }, []);

  return (
    <div className='flex flex-col mb-4'>
      <Label
        text={label}
        inputId={Math.random().toString()}
        classes={`mb-5 ${disabled ? 'text-secondaryB' : ''}`}
      />
      <div className={`base-input ${inputClassName}`}>
        <DropdownList
          items={options}
          buttonClasses='flex items-center flex-1 focus:outline-none'
          containerClasses='flex relative'
          fullWidth={listFullWidth}
          maxHeight={listMaxHeight}
          shouldPositionOnTop={shouldPositionOnTop}
          disabled={disabled}
        >
          <div
            ref={valueRef}
            className={`select-label text-left w-full mr-0 ${
              disabled
                ? 'text-secondaryA hover:text-secondaryA cursor-default'
                : ''
            }`}
          >
            {value}
          </div>
        </DropdownList>
      </div>
    </div>
  );
};

export default SelectField;
