/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Node } from 'slate';

import ImageService from 'infrastructure/ImageService';

import PPVBadge from '../components/event/PPVBadge';
import Share from '../components/creator/Share';
import Heading from '../components/typography/Heading';
import MinimizedChatMock from '../components/event/MinimizedChatMock';
import RoomTile from '../components/event/RoomTile';
import Chat from '../components/chat/Chat';
import BuyEventModal from '../components/dashboard/BuyEventModal';
import MediaContent from '../components/event/MediaContent';
import CustomShareButton from '../components/event/CustomShareButton';

import useVOD from '../hooks/useVOD';

import { formatDate } from '../helpers';
import history from '../history';
import routes from '../routes';
import { UserContext } from '../context/user';
import { TransactionsContext } from '../context/transactions';
import { SponsorRoomsContext } from '../context/sponsorRooms';

const VideoPage = () => {
  const [video, setVideo] = useState(null);
  const [dimentions, setDimentions] = useState({ width: 0, height: 0 });
  const [chatIsHidden, setChatIsHidden] = useState(false);
  const [mediaContainerHovered, setMediaContainerHovered] = useState(false);
  const [buyModalData, setBuyModalData] = useState(null);
  const [room, setRoom] = useState({});

  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);

  const { getCurrentUserId } = userContext;
  const { loadPPVEvent } = transactionsContext;
  const { getRoomById } = sponsorRoomsContext;

  const chatStateRef = useRef(chatIsHidden);
  const mediaContainerRef = useRef(null);

  const { videoId } = useParams();
  const { loadVideo } = useVOD();

  useEffect(async () => {
    const result = await loadVideo(videoId);

    if (!result.video) {
      history.replace(routes.notFound);
      return;
    }

    const { ppv: purchasedVideo } = await loadPPVEvent(
      getCurrentUserId(),
      videoId
    );
    const eventIsCreatedByCurrentUser =
      result.video.userID === getCurrentUserId();

    if (result.video.ppv) {
      if (!purchasedVideo && !eventIsCreatedByCurrentUser) {
        setBuyModalData({
          price: result.video.ppvPrice,
          title: result.video.title,
          id: videoId,
        });
      }
    }

    const room = await getRoomById(result.video.hostSponsorRoomID);
    setRoom(room);

    setVideo(result.video);
  }, []);

  const calculatePlayerSize = () => {
    const hidden = chatStateRef.current;
    const margins = hidden ? 2 * 16 : 3 * 16;
    const chatWidth = hidden ? 0 : 320;
    const navbarHeight = 56;

    // max height to keep only player in view
    const maxPlayerHeight = window.innerHeight - 2 * 16 - navbarHeight;

    let playerWidth = window.innerWidth - margins - chatWidth;
    let playerHeight = playerWidth / (16 / 9);

    if (playerHeight > maxPlayerHeight) {
      playerHeight = maxPlayerHeight;
      playerWidth = playerHeight / (9 / 16);
    }

    return { width: playerWidth, height: playerHeight };
  };

  const setPlayerContainerSize = () => {
    setDimentions(calculatePlayerSize());
  };

  useEffect(() => {
    window.addEventListener('resize', setPlayerContainerSize);

    setPlayerContainerSize();

    return () => {
      window.removeEventListener('resize', setPlayerContainerSize);
    };
  }, []);

  useEffect(() => {
    setPlayerContainerSize();
  }, [chatIsHidden]);

  const setChatState = (state) => {
    setChatIsHidden(state);
    chatStateRef.current = state;
  };

  const getEventCreatorId = () => {
    return video?.userID;
  };

  const getChatHeight = () => {
    return window.innerHeight - 56 - 32;
  };

  const getParsedValue = () => {
    if (!video.richTextDescription) {
      return null;
    }

    try {
      return JSON.parse(video.richTextDescription);
    } catch (error) {
      return null;
    }
  };

  const renderDescription = () => {
    if (!video) {
      return;
    }

    if (video.richTextDescription) {
      const parsed = getParsedValue();

      return parsed.map((n, i) => {
        const paragraph = Node.string(n);
        if (paragraph.length === 0) {
          return <br key={i} />;
        }

        return <div key={i}>{paragraph}</div>;
      });
    }

    return video.description;
  };

  const sharingUrls = {
    desktop: `${window.location.origin}/video/${videoId}`,
    ios: `/video/${videoId}`,
    andorid: `/video/${videoId}`,
  };

  const shareImg = ImageService.getImageByKey(video?.thumbnail?.key);

  return (
    <>
      <Helmet>
        <title>{`Event | ClashTV`}</title>
      </Helmet>
      {video && (
        <div className='event-page-container p-4'>
          <div
            style={{ width: dimentions.width }}
            className='flex flex-col mr-4 rounded-lg'
          >
            <div
              ref={mediaContainerRef}
              style={dimentions}
              className='aspect-ratio-box mb-4'
              onMouseEnter={(e) => setMediaContainerHovered(true)}
              onMouseLeave={() => setMediaContainerHovered(false)}
            >
              <MediaContent
                eventId={video.id}
                eventState={'ended'}
                teaserUrl={null}
                image={null}
                image2={null}
                eventMode={null}
                videoUrl={video.playbackUrl}
                containerIsHovered={mediaContainerHovered}
                mediaContainer={mediaContainerRef.current}
                gamificationHidden={true}
                setGamificationHidden={() => {}}
                isPPV={video.ppv}
                eventCreatorId={getEventCreatorId()}
                contestorA={null}
                contestorB={null}
                activeEndedTab={'video'}
                setActiveEndedTab={() => {}}
                roomId={room.id}
                roomTitle={room.title}
                eventCategory={video.eventCategorisation.title}
                eventTitle={video.title}
                isVideo={true}
              />
            </div>
            <div className='flex w-full mb-6'>
              <div className='flex flex-1 items-center'>
                <PPVBadge
                  eventId={video.id}
                  eventTitle={video.title}
                  eventCreatorId={getEventCreatorId()}
                  eventState={null}
                  isPPV={video.ppv || false}
                  price={video.ppvPrice || 0}
                  onBuy={() => {}}
                />
                <Share
                  id={video.id}
                  title={video.title}
                  desc={video.description}
                  url={sharingUrls}
                  img={shareImg}
                  CustomButton={CustomShareButton}
                  positionClasses='share-position'
                  contentType='video'
                />
              </div>
            </div>
            <div className={`flex max-h-44 mt-2`}>
              <div className='flex flex-1 flex-col'>
                <Heading headingSize={4} classes='mb-2'>
                  {video.title}
                </Heading>
                <time dateTime={video.createdAt} className='event-date-time'>
                  {formatDate(video.createdAt)}
                </time>
                <div className='event-description'>{renderDescription()}</div>
              </div>
              <div className='flex flex-1 justify-end'>
                <RoomTile roomId={video.hostSponsorRoomID} event={video} />
                {chatIsHidden && (
                  <MinimizedChatMock
                    joinIsEnabled={false}
                    eventState={null}
                    eventMode={null}
                    maximize={() => {
                      setChatState(false);
                    }}
                    className='ml-4'
                  />
                )}
              </div>
            </div>
          </div>
          {!chatIsHidden && (
            <div
              className='event-chat-wrapper'
              style={{ height: getChatHeight() }}
            >
              <Chat
                inPreview={false}
                inEvent={true}
                eventId={video.id}
                isHidden={chatIsHidden}
                isHiddenFunc={(value) => setChatState(value)}
                eventState={null}
                mediaContainerRef={mediaContainerRef?.current}
              />
            </div>
          )}
        </div>
      )}
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
        onBuy={() => {}}
        onDecline={() => history.push(routes.dashboard)}
      />
    </>
  );
};

export default VideoPage;
