import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { RankingContext } from './rankig';
import { usersByExperience } from '../graphql/queries';
import { logError } from '../helpers';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class RankingProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rankingUsers: [],
      loading: false,
      error: null,
      nextToken: null,
      loadMore: this.loadMore,
      loadRankingUsers: this.loadRankingUsers,
    };
  }

  loadRankingUsers = async (loadMore = false) => {
    const variables = {
      limit: 50,
      realm: 'A',
      experience: {
        gt: 0,
      },
      sortDirection: 'DESC',
      nextToken: loadMore ? this.state.nextToken : null,
    };
    try {
      this.setState({ loading: true });
      const res = await API.graphql(
        graphqlOperation(usersByExperience, variables)
      );

      const items = res?.data?.usersByExperience?.items || [];
      for (let i = 0; i < items.length; i++) {
        if (items[i].image) {
          items[i].image._url = this.getUserImageUrl(items[i].image.key);
        }
        items[i].rank = i + this.state.rankingUsers.length + 1;
      }
      this.setState({
        loading: false,
        rankingUsers: [...this.state.rankingUsers, ...items],
        error: null,
        nextToken: res.data.usersByExperience.nextToken,
      });
    } catch (error) {
      logError(error);
      this.setState({
        rankingUsers: [],
        error: 'Error loading ranking users',
        loading: false,
        nextToken: null,
      });
    }
  };

  loadMore = async () => {
    if (this.state.nextToken) {
      await this.loadRankingUsers(true);
    }
  };

  getUserImageUrl = (key) => {
    return `${imgBaseUrl}${key}?w=40`;
  };

  render() {
    return (
      <RankingContext.Provider value={this.state}>
        {this.props.children}
      </RankingContext.Provider>
    );
  }
}
