/* eslint-disable prettier/prettier */
import React, { useContext, memo, useCallback } from 'react';
import { EventCategoriesContext } from '../../context/eventCategories';
import {
  useHistory,
  useLocation,
} from 'react-router-dom/cjs/react-router-dom.min';

const CategoryFilter = () => {
  const eventCategoriesContext = useContext(EventCategoriesContext);
  const { eventCategories } = eventCategoriesContext;
  const history = useHistory();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const filter = params.get('filter');

  const handleFiltering = async ({ category }) => {
    const params = new URLSearchParams();

    filter === category
      ? params.delete('filter')
      : params.set('filter', category);

    const updatedParams = params.toString();

    if (!updatedParams) {
      resetToInitialPath();
      return;
    }

    history.replace({ pathname, search: params.toString() });
  };

  const getActiveClasses = useCallback(
    (category) => {
      return filter === category
        ? 'ring-2 ring-indigo-600'
        : 'ring-1 ring-gray-300';
    },
    [filter]
  );

  const resetToInitialPath = () =>
    history.replace({ pathname: '/dashboard/state/upcoming', search: '' });

  const allBtnActiveClasses = !filter
    ? 'ring-2 ring-indigo-600'
    : 'ring-1 ring-gray-300';

  const renderFilters = () =>
    eventCategories.map((c) => (
      <button
        key={c.id}
        onClick={() => handleFiltering({ category: c.id })}
        className={`focus:outline-none font-semibold text-black py-2 px-3 rounded-lg bg-secondaryC ${getActiveClasses(
          c.id
        )}`}
      >
        {c.title}
      </button>
    ));

  return (
    <div className='flex gap-4 flex-wrap self-start my-8'>
      <button
        onClick={resetToInitialPath}
        className={`focus:outline-none font-semibold text-black py-2 px-3 rounded-lg bg-secondaryC ${allBtnActiveClasses}`}
      >
        All
      </button>
      {renderFilters()}
    </div>
  );
};

export default memo(CategoryFilter);
