/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
} from 'react-router-dom';

import SubscriptionPassList from 'pages/SubscriptionPasses/list';

import { UserContext } from '../context/user';
import { CookieConsentContext } from '../context/cookieConsent';
import { EventsContext } from '../context/events';

import routes from '../routes';
import { shouldRenderNavbar } from '../helpers';
import Account from '../pages/Account';
import CompleteProfile from '../pages/CompleteProfile';
import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import Leaderboard from '../pages/Leaderboard';
import EventPage from '../pages/EventPage';
import NotFoundPage from '../pages/NotFoundPage';
import Terms from '../pages/Terms';
import Privacy from '../pages/Privacy';
import MyRoom from '../pages/MyRoom';
import ContainerWithUserSidebar from './layout/ContainerWithUserSidebar';
import ContainerWithDrawer from './layout/ContainerWithDrawer';
import ContainerWithRoomSidebar from './layout/ContainerWithRoomSidebar';
import CreatorNewEvent from '../pages/CreatorNewEvent';
import CreatorEditEvent from '../pages/CreatorEditEvent';
import CreatorEventsList from '../pages/CreatorEventsList';
import InvitationsContainer from './invitations/InvitationsContainer';
import WithdrawPage from '../pages/WithdrawPage';
import EventEarnings from '../pages/EventEarnings';
import { useLogEvent } from '../firebase';
import BecomeACreator from '../pages/BecomeACreator';
import { InventoryContext } from '../context/inventory';
import Spinner from './system/Spinner';
import PromotedCategory from '../pages/PromotedCategory';
import Room from '../pages/Room';
import RoomDashboard from '../pages/RoomDashboard';
import CreateVideo from '../pages/CreateVideo';
import VideosList from '../pages/VideosList';
import EditVideo from '../pages/EditVideo';
import VideoPage from '../pages/VideoPage';
import AllCreators from '../pages/AllCreators';
import AllGroups from '../pages/AllGroups';

import featureFlags from '../features';
import SubscriptionPassCreate from 'pages/SubscriptionPasses/create';
import PPVPassEarningsPage from 'pages/PPVPassEarningsPage';
import LandingPage from 'pages/LandingPage';

const hasBackground = [routes.login, routes.register];

const PrivateRoute = ({ children, ...rest }) => {
  const [userHasLoaded, setUserHasLoaded] = useState(false);

  const userContext = useContext(UserContext);
  const inventoryContext = useContext(InventoryContext);

  const { user, loadCurrentUser, loading: userIsLoading } = userContext;
  const { setInventory } = inventoryContext;

  const handleInitialLoad = async () => {
    if (user) {
      setUserHasLoaded(true);
      return;
    }
    if (!userIsLoading && !user) {
      await loadCurrentUser(setInventory);
      setUserHasLoaded(true);
    }
  };

  useEffect(() => {
    handleInitialLoad();
  }, []);

  if (!userHasLoaded) {
    return (
      <div className='fixed inset-0 flex items-center justify-center'>
        <Spinner size='small' />
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={() => {
        return user !== null ? children : <Redirect to={routes.login} />;
      }}
    />
  );
};

const Routing = () => {
  const location = useLocation();

  const userContext = useContext(UserContext);
  const cookieConsentContext = useContext(CookieConsentContext);
  const eventsContext = useContext(EventsContext);

  const { loading: userIsLoading, getCurrentUserId } = userContext;
  const { isVisible } = cookieConsentContext;
  const prevPathname = useRef();
  const { getEventIdBySeoUrl } = eventsContext;
  const { logEvent } = useLogEvent();

  useEffect(async () => {
    const matchEventPage = matchPath(prevPathname.current, {
      path: routes.eventWithID,
      exact: true,
    });

    if (matchEventPage) {
      const eventId = await getEventIdBySeoUrl(matchEventPage.params.eventId);
      logEvent('quit_event', {
        category: 'live_stream_stats',
        eventId,
      });
    }

    if (
      (prevPathname.current?.includes('login') ||
        prevPathname.current?.includes('complete-profile')) &&
      location.pathname?.includes('dashboard')
    ) {
      logEvent('after_login_dashboard_visit', { category: 'consumer' });
      if (prevPathname.current?.includes('complete-profile')) {
        logEvent('user_registration', { category: 'consumer' });
      }
    }

    prevPathname.current = location.pathname;
  }, [location.pathname]);

  const getBackgroundColor = () => {
    if (userIsLoading) {
      return '';
    }

    if (
      hasBackground.includes(location.pathname) ||
      location.pathname === routes.base
    ) {
      return 'bg-primaryC';
    }

    return '';
  };

  const getHeight = () => {
    const vhHeight = [
      routes.base,
      routes.login,
      routes.register,
      routes.completeProfile,
    ];

    if (vhHeight.includes(location.pathname)) {
      return 'h-screen';
    }

    return 'h-full';
  };

  const getPosition = () => {
    if (isVisible) {
      if (!shouldRenderNavbar(location.pathname)) {
        return 'mt-with-cookie-without-navbar';
      }
      return 'mt-with-cookie';
    }

    if (shouldRenderNavbar(location.pathname)) {
      return 'mt-navbarHeight';
    }

    return '';
  };

  return (
    <main
      className={`relative ${getHeight()} ${getBackgroundColor()} ${getPosition()}`}
    >
      <Switch>
        <Route exact path={routes.base} component={LandingPage} />
        <Route exact path={routes.login} component={Login} />
        <PrivateRoute exact path={routes.completeProfile}>
          <ContainerWithDrawer>
            <CompleteProfile />
          </ContainerWithDrawer>
        </PrivateRoute>
        <Route
          exact
          path={routes.dashboard}
          component={() => (
            <Redirect to={`${routes.dashboard}/state/upcoming`} />
          )}
        />
        <PrivateRoute exact path={routes.dashboardFilteredByStateAndRoom}>
          <ContainerWithDrawer>
            <ContainerWithUserSidebar useCardGrid={true}>
              <Dashboard />
            </ContainerWithUserSidebar>
          </ContainerWithDrawer>
        </PrivateRoute>
        <PrivateRoute exact path={routes.dashboardFilteredByState}>
          <ContainerWithDrawer>
            <ContainerWithUserSidebar useCardGrid={true}>
              <Dashboard />
            </ContainerWithUserSidebar>
          </ContainerWithDrawer>
        </PrivateRoute>
        <PrivateRoute exact path={routes.dashboardFilteredByRoom}>
          <ContainerWithDrawer>
            <ContainerWithUserSidebar useCardGrid={true}>
              <Dashboard />
            </ContainerWithUserSidebar>
          </ContainerWithDrawer>
        </PrivateRoute>
        <PrivateRoute path={`${routes.account}/:tab`}>
          <ContainerWithDrawer>
            <ContainerWithUserSidebar useCardGrid={false}>
              <Account />
            </ContainerWithUserSidebar>
          </ContainerWithDrawer>
        </PrivateRoute>
        <Redirect exact from={routes.account} to={routes.accountProfile} />
        <PrivateRoute exact path={routes.leaderboard}>
          <ContainerWithUserSidebar>
            <Leaderboard />
          </ContainerWithUserSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.eventWithID}>
          <EventPage />
        </PrivateRoute>
        <PrivateRoute exact path={routes.video}>
          <VideoPage />
        </PrivateRoute>
        <Route
          exact
          path={routes.termsOfUse}
          component={() => {
            return <Terms />;
          }}
        />
        <Route
          exact
          path={routes.privacyPolicy}
          component={() => {
            return <Privacy />;
          }}
        />
        <PrivateRoute exact path={routes.myRoom}>
          <ContainerWithRoomSidebar>
            <MyRoom />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.roomDashboard}>
          <ContainerWithRoomSidebar>
            <RoomDashboard />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.newEvent}>
          <ContainerWithRoomSidebar>
            <CreatorNewEvent />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.editMyEventWithIDEventTab}>
          <ContainerWithRoomSidebar>
            <CreatorEditEvent />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.editMyEventWithIDStreamTab}>
          <ContainerWithRoomSidebar>
            <CreatorEditEvent />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.editMyEventWithIDStatisticsTab}>
          <ContainerWithRoomSidebar>
            <CreatorEditEvent />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.withdraw}>
          <ContainerWithRoomSidebar>
            <WithdrawPage />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.myEvents}>
          <ContainerWithRoomSidebar>
            <CreatorEventsList />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.earnings}>
          <ContainerWithRoomSidebar>
            <EventEarnings />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.ppvPassEarnings}>
          <ContainerWithRoomSidebar>
            <PPVPassEarningsPage />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.becomeCreator}>
          <BecomeACreator />
        </PrivateRoute>
        <PrivateRoute exact path={routes.groups}>
          <ContainerWithUserSidebar>
            <PromotedCategory />
          </ContainerWithUserSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.room}>
          <ContainerWithUserSidebar>
            <Room />
          </ContainerWithUserSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.roomSEO}>
          <ContainerWithUserSidebar>
            <Room />
          </ContainerWithUserSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.allCreators}>
          <ContainerWithUserSidebar>
            <AllCreators />
          </ContainerWithUserSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.allGroups}>
          <ContainerWithUserSidebar>
            <AllGroups />
          </ContainerWithUserSidebar>
        </PrivateRoute>
        {featureFlags.videos.active && (
          <PrivateRoute exact path={routes.videoList}>
            <ContainerWithRoomSidebar>
              <VideosList />
            </ContainerWithRoomSidebar>
          </PrivateRoute>
        )}
        {featureFlags.videos.active && (
          <PrivateRoute exact path={routes.newVideo}>
            <ContainerWithRoomSidebar>
              <CreateVideo />
            </ContainerWithRoomSidebar>
          </PrivateRoute>
        )}
        {featureFlags.videos.active && (
          <PrivateRoute exact path={routes.editVideo}>
            <ContainerWithRoomSidebar>
              <EditVideo />
            </ContainerWithRoomSidebar>
          </PrivateRoute>
        )}
        <PrivateRoute exact path={routes.subscriptionPass.list}>
          <ContainerWithRoomSidebar>
            <SubscriptionPassList />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <PrivateRoute exact path={routes.subscriptionPass.create}>
          <ContainerWithRoomSidebar>
            <SubscriptionPassCreate />
          </ContainerWithRoomSidebar>
        </PrivateRoute>
        <Route exact path={routes.notFound} component={NotFoundPage} />
        <Route component={NotFoundPage} />
      </Switch>
      {getCurrentUserId() && <InvitationsContainer />}
    </main>
  );
};

// Routing.whyDidYouRender = true;

export default Routing;
