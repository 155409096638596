import React from 'react';

const VideoProgressBar = ({ max, progress, handleProgress }) => {
  const calcProgressPercentage = () => {
    if (max === 0 || progress === 0) {
      return 0;
    }

    return (progress / max) * 100;
  };

  const progressPercent = calcProgressPercentage();

  const gradient = `linear-gradient(to right,
    rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) ${progressPercent}%,
    rgba(255, 255, 255, .3) 0%, rgba(255, 255, 255, .3) 100%)`;
  return (
    <div className='wrap'>
      <input
        type='range'
        min='0'
        max={max}
        value={progress}
        onChange={(event) => handleProgress(event.target.value)}
        className='range'
        style={{ background: gradient }}
      />
    </div>
  );
};

export default VideoProgressBar;
