import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import { getVictoryStatus } from '../../helpers';

const Story = ({ active, story, setTeaserDuration, setVideoDuration }) => {
  const videoRef = useRef();
  const [mute, setMute] = useState(true);

  const handleMute = (e) => {
    e.stopPropagation();
    setMute(!mute);
  };

  useEffect(() => {
    if (story.type === 'video' && active) {
      videoRef.current.seekTo(0);
    }
  }, [active]);

  const handleTeaserDurationSet = (duration) => {
    setTeaserDuration(duration);
  };

  const handleVideoDurationSet = (duration) => {
    setVideoDuration(duration);
  };

  if (story.type === 'score') {
    return (
      <div className={`flex justify-between mt-28 ${active ? '' : 'hidden'}`}>
        <div className='event-ended-preview-contestorA-container'>
          <Heading headingSize={3} classes='text-white'>
            {getVictoryStatus(story.contestorAScore, story.contestorBScore)}
          </Heading>
          <div className='flex items-center whitespace-nowrap mb-10 justify-start'>
            <div className='contestor-color blue'></div>
            <div className='event-contestor-name'>{story.contestorA}</div>
          </div>
          <div className='event-ended-preview-score'>
            <div className='number'>{story.contestorAScore || 0}</div>
            <div className='text'>Points Clapped</div>
          </div>
        </div>
        <div className='event-ended-preview-contestorB-container'>
          <Heading headingSize={3} classes='text-white'>
            {getVictoryStatus(story.contestorBScore, story.contestorAScore)}
          </Heading>
          <div className='flex items-center whitespace-nowrap mb-10 justify-end'>
            <div className='event-contestor-name'>{story.contestorB}</div>
            <div className='contestor-color red'></div>
          </div>
          <div className='event-ended-preview-score'>
            <div className='number'>{story.contestorBScore || 0}</div>
            <div className='text'>Points Clapped</div>
          </div>
        </div>
      </div>
    );
  }

  if (story.type === 'video') {
    return (
      <div className={active ? 'relative' : 'hidden'}>
        <ReactPlayer
          ref={videoRef}
          id='event-video'
          playing={active}
          onDuration={
            story.videoType === 'video'
              ? handleVideoDurationSet
              : handleTeaserDurationSet
          }
          controls={story.controls}
          muted={mute}
          url={story.url}
          width='100%'
          height='100%'
          className={`drawer-video event-preview-video bg-black rounded-lg`}
          config={{
            file: {
              attributes: { disablePictureInPicture: true },
              forceHLS: true,
              hlsOptions: { capLevelToPlayerSize: true },
            },
          }}
        />
        {!story.controls && (
          <button
            onClick={handleMute}
            className='absolute bg-transparent w-10 h-10 left-1/2 top-1/2 rounded-full flex items-center justify-center focus:outline-none'
            style={{
              transform: 'translate(-50%, -50%)',
              background: 'rgba(0,0,0,0.6)',
            }}
          >
            <Icon name={mute ? 'volume-mute' : 'volume-up'} />
          </button>
        )}
      </div>
    );
  }

  if (story.type === 'image') {
    return (
      <div className={`h-full ${active ? '' : 'hidden'}`}>
        <img
          src={story.url}
          className='block relative h-full w-full object-cover'
        />
      </div>
    );
  }

  if (story.type === 'resetter') {
    return <span></span>;
  }

  return null;
};

export default Story;
