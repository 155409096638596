import React from 'react';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import Button from '../common/Button';

const CurrentBalance = ({ isVisible, onClick, coins, usd, bankingData }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className='flex flex-1 h-full items-center justify-center flex-col p-6 bg-primaryADark rounded-lg'>
      <p className='font-display font-bold text-xl tracking-tight text-white mb-7'>
        Your Current Balance:
      </p>
      <Heading headingSize={2} classes='text-white'>
        {coins} coins
      </Heading>
      <Icon name='exchange' color='rgba(255,255,255, 0.5)' size={43} />
      <Heading headingSize={2} classes='text-white mb-9'>
        ${usd}
      </Heading>
      <Button
        buttonType='secondary'
        buttonSize='small'
        classes='text-black'
        buttonOnClick={onClick}
        disabled={usd === 0 || !bankingData}
      >
        Withdraw
      </Button>
    </div>
  );
};

export default CurrentBalance;
