/* eslint-disable no-unused-vars */
import React, { useEffect, useContext } from 'react';

import Heading from '../typography/Heading';
import Avatar from '../common/Avatar';
import GameItem from '../gamification/GameItem';

import { GameActionsContext } from '../../context/gameActions';
import { AvatarsContext } from '../../context/avatars';
import { InventoryContext } from '../../context/inventory';
import { Link } from 'react-router-dom';
import routes from '../../routes';

const MyGoodsMenuContent = () => {
  const actionsContext = useContext(GameActionsContext);
  const avatarsContext = useContext(AvatarsContext);
  const inventoryContext = useContext(InventoryContext);

  const { actions } = actionsContext;
  const { avatars, loadAvatars } = avatarsContext;
  const { inventory } = inventoryContext;

  const tomatoAction = actions.find((a) => a.name === 'Tomato');

  useEffect(async () => {
    await loadAvatars();
  }, []);

  const renderAvatars = () => {
    if (inventory && avatars && avatars.length > 0) {
      return avatars.map((a) => {
        if (inventory[a.id]) {
          return (
            <Avatar
              key={a.id}
              imgSrc={a?.image?._url}
              imgAlt={`avatar-ring avatar-${a.id}`}
              imgSize={5}
              classes='shadow-card mr-4 mb-2'
            />
          );
        }

        return null;
      });
    }

    return null;
  };

  const renderActions = () => {
    const availableActions = actions.map((a) => {
      if (!inventory) {
        return null;
      }

      if (a.name === 'Clap') {
        return null;
      }

      const actionsInInventory = inventory[a.id];
      let amount = 0;
      let label = '';

      if (actionsInInventory) {
        amount = actionsInInventory.count;
        label = actionsInInventory.title;
      }

      return (
        <Link key={a.id} to={routes.myGoods}>
          <div className='goods-menu-item-container'>
            <div className='goods-menu-item-amount'>{amount}</div>
            <div className='goods-menu-item-image-container'>
              <img src={a.icon._url} className='w-5 h-5' />
            </div>
            <span className='label-base goods-menu-item-label'>{label}</span>
          </div>
        </Link>
      );
    });

    return availableActions;
  };

  return (
    <div className='goods-menu-content-container'>
      <div className='goods-menu-content-section'>
        <div className='goods-menu-label'>Game Actions</div>
        <div className='goods-menu-items-container'>{renderActions()}</div>
      </div>
      <div className='goods-menu-content-section'>
        <div className='goods-menu-label'>Avatars</div>
        <div className='goods-menu-items-container'>{renderAvatars()}</div>
      </div>
    </div>
  );
};

export default MyGoodsMenuContent;
