/* eslint-disable no-unreachable */
import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { UserContext } from '../context/user';
import history from '../history';
import routes from '../routes';
import Spinner from '../components/system/Spinner';
import EventsList from '../components/dashboard/EventsList';

const Dashboard = (props) => {
  const userContext = useContext(UserContext);
  const { user, loading: userIsLoading } = userContext;

  useEffect(() => {
    if (!userIsLoading && !user) {
      history.replace({
        pathname: routes.login,
        state: { from: history.location },
      });
    }

    if (!userIsLoading && user) {
      const userData = user?.dbData?.data?.getUser || null;
      if (!userData || !userData?.profileCompleted) {
        history.replace(routes.completeProfile);
      }
    }
  }, [userIsLoading]);

  if (userIsLoading) {
    return (
      <div className='absolute inset-0 flex items-center justify-center'>
        <Spinner size='big' />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Events | ClashTV</title>
      </Helmet>

      <EventsList />
    </>
  );
};

export default Dashboard;
