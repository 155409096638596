import React, { useState, useMemo } from 'react';
import { createEditor, Node } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

import Label from '../form/Label';
import Modal from '../system/Modal';
import Button from '../common/Button';

const DescriptionField = ({
  initialDescriptionValue,
  richTextValue,
  disabled = false,
  onUpdate = () => {},
}) => {
  const [editorIsOpen, setEditorIsOpen] = useState(false);

  const editor = useMemo(() => withReact(createEditor()), []);

  // const initialValue = [
  //   {
  //     type: 'paragraph',
  //     children: [
  //       { text: 'This is editable plain text, just like a <textarea>!' },
  //     ],
  //   },
  // ];

  const serialize = (value) => {
    return (
      value
        // Return the string content of each paragraph in the value's children.
        .map((n) => Node.string(n))
        // Join them all with line breaks denoting paragraphs.
        .join('\n')
    );
  };

  const getParsedValue = () => {
    if (!richTextValue) {
      return null;
    }

    try {
      return JSON.parse(richTextValue);
    } catch (error) {
      return null;
    }
  };

  const getInitialValue = () => {
    const parsedValue = getParsedValue();

    if (parsedValue) {
      return parsedValue;
    }

    if (initialDescriptionValue) {
      return [
        {
          type: 'paragraph',
          children: [{ text: initialDescriptionValue }],
        },
      ];
    }

    return [
      {
        type: 'paragraph',
        children: [{ text: '' }],
      },
    ];
  };

  const openEditor = () => {
    if (disabled) {
      return;
    }

    setEditorIsOpen(true);
  };

  const handleCancel = () => {
    setEditorIsOpen(false);
  };

  const handleUpdate = () => {
    const descriptionRichText = JSON.stringify(editor.children);
    const descriptionPlainText = serialize(editor.children);

    onUpdate({ descriptionPlainText, descriptionRichText });

    setEditorIsOpen(false);
  };

  const renderContent = () => {
    if (!initialDescriptionValue && !richTextValue) {
      return (
        <div
          className={`base-input ${
            disabled ? 'cursor-default' : 'cursor-pointer'
          }`}
          onClick={openEditor}
        >
          Add description
        </div>
      );
    }

    if (richTextValue) {
      const parsed = getParsedValue();

      return (
        <div
          className={`event-description mb-0 max-h-32 overflow-y-auto scrollable ${
            disabled ? 'cursor-default' : 'cursor-pointer'
          }`}
          onClick={openEditor}
        >
          {parsed.map((n, i) => {
            const paragraph = Node.string(n);
            if (paragraph.length === 0) {
              return <br key={i} />;
            }

            return <div key={i}>{paragraph}</div>;
          })}
        </div>
      );
    }

    return (
      <div
        className={`event-description mb-0 max-h-32 overflow-y-auto scrollable ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
        onClick={openEditor}
      >
        {initialDescriptionValue}
      </div>
    );
  };

  return (
    <div className='flex flex-col mb-4'>
      <Label
        text='Description'
        inputId='description'
        classes={disabled ? 'mb-5 disabled' : 'mb-5'}
      />
      {renderContent()}
      <Modal
        title='Description'
        actions={[
          <Button
            key='decline'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={handleCancel}
            classes='mr-4'
          >
            Cancel
          </Button>,
          <Button
            key='accept'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={handleUpdate}
          >
            Update
          </Button>,
        ]}
        isVisible={editorIsOpen}
        handleClose={handleCancel}
      >
        <div className='flex flex-col font-paragraph text-base mt-16 mb-8'>
          <Slate editor={editor} value={getInitialValue()}>
            <Editable placeholder='Add description here...' />
          </Slate>
        </div>
      </Modal>
    </div>
  );
};

export default DescriptionField;
