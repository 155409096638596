import React from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import Spinner from '../system/Spinner';

const EventControls = ({
  eventState,
  isStreaming,
  eventMode,
  broadcasterIsLive,
  onStartStreaming,
  onStopStreaming,
  onGoLive,
  onEndEvent,
  isEnding,
}) => {
  const renderButtons = () => {
    const buttons = [];

    if (eventMode === 'battle' && !isStreaming && eventState !== 'ended') {
      buttons.push(
        <Button
          key='start-streaming'
          buttonType='primary'
          buttonSize='small'
          buttonOnClick={onStartStreaming}
          disabled={isStreaming}
          classes='mr-4'
        >
          Start streaming
        </Button>
      );
    }

    if (eventMode === 'battle' && isStreaming && eventState !== 'live') {
      buttons.push(
        <Button
          key='stop-streaming'
          buttonType='primary'
          buttonSize='small'
          buttonOnClick={onStopStreaming}
          disabled={!isStreaming}
          classes='mr-4'
        >
          Stop streaming
        </Button>
      );
    }

    if (
      eventState === 'upcoming' &&
      (broadcasterIsLive || eventMode === 'user')
    ) {
      buttons.push(
        <Button
          key='go-live'
          buttonType='primary'
          buttonSize='small'
          buttonOnClick={onGoLive}
          disabled={eventState !== 'upcoming'}
        >
          Go Live Now
        </Button>
      );
    }

    if (eventState === 'live') {
      buttons.push(
        <Button
          key='end-event'
          buttonType='primary'
          buttonSize='small'
          buttonOnClick={onEndEvent}
          disabled={eventState !== 'live'}
        >
          {isEnding && <Spinner size='small' />} End Event
        </Button>
      );
    }

    return buttons;
  };

  return renderButtons();
};

EventControls.propTypes = {
  eventState: PropTypes.string,
  isStreaming: PropTypes.bool,
  eventMode: PropTypes.string,
  broadcasterIsLive: PropTypes.bool,
  isEnding: PropTypes.bool,
  onStartStreaming: PropTypes.func,
  onStopStreaming: PropTypes.func,
  onGoLive: PropTypes.func,
  onEndEvent: PropTypes.func,
};

EventControls.defaultProps = {
  eventState: null,
  isStreaming: false,
  eventMode: null,
  broadcasterIsLive: false,
  isEnding: false,
  onStartStreaming: () => {},
  onStopStreaming: () => {},
  onGoLive: () => {},
  onEndEvent: () => {},
};

export default EventControls;
