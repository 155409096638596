import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Avatar from '../common/Avatar';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';

import { useLogEvent } from '../../firebase';
import colors from '../../theme-colors';
import routes from '../../routes';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { UserContext } from '../../context/user';
import Skeleton from 'react-loading-skeleton';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const RoomTile = ({ roomId, event }) => {
  const [room, setRoom] = useState(null);

  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);

  const { getRoomById, toggleFollowRoom, followedRooms, loadUserFollowings } =
    sponsorRoomsContext;
  const { getCurrentUserId, getCurrentUserDisplayName } = userContext;
  const { logEvent } = useLogEvent();

  useEffect(async () => {
    if (roomId) {
      loadUserFollowings(getCurrentUserId());
      const r = await getRoomById(roomId);
      setRoom(r);
    }
  }, []);

  const getImageUrl = (key) => {
    if (!key) {
      return null;
    }

    return `${imgBaseUrl}${key}?w=128`;
  };

  const toggleFollow = () => {
    toggleFollowRoom(roomId, getCurrentUserId(), getCurrentUserDisplayName());

    if (!isFollowed) {
      logEvent('follow', {
        category: 'engagement',
        creator_id: roomId,
        video_title: event.title,
        video_URL: event.videoUrl,
        eventId: event.id,
      });
    }
  };

  if (!roomId) {
    return null;
  }

  if (!room) {
    return (
      <div className='room-tile-container'>
        <Skeleton circle={true} height={64} width={64} />
        <div className='flex flex-col'>
          <Skeleton height={24} width={160} />
          <Skeleton height={20} width={120} />
        </div>
      </div>
    );
  }

  const isFollowed = followedRooms.find(
    (room) => room.sponsorRoomID === roomId
  );

  return (
    <div className='room-tile-container'>
      <Avatar
        imgSrc={getImageUrl(room?.image?.key)}
        imgSize='4-5'
        classes='mr-4'
      />
      <div className='flex flex-col'>
        <Link
          to={`${routes.room
            .replace(':roomId', roomId)
            .replace(':tab', 'all')}`}
        >
          <Heading headingSize={5} classes='hover:text-primaryADark'>
            {room.title}
          </Heading>
        </Link>
        <button className='room-tile-follow-button' onClick={toggleFollow}>
          <Icon
            name={isFollowed ? 'check' : 'heart'}
            color={colors.primaryA}
            classes='mr-2'
          />
          {isFollowed ? 'Followed' : 'Follow'}
        </button>
      </div>
    </div>
  );
};

export default RoomTile;
