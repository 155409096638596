/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { produce } from 'immer';
import {
  ActionType,
  createReducer,
} from 'typesafe-actions';

import { Subscription } from 'domain/types';

import { eventPageMounted } from 'store/event/actions';
import { arrayToByID } from 'store/mapping';

import {
  loadSubscriptions,
  subscriptionsPageMounted
} from './actions';

export type Actions = ActionType<
  | typeof loadSubscriptions
  | typeof subscriptionsPageMounted
  | typeof eventPageMounted
>;

export type State = {
  byID: Record<string, Subscription>;
  allIDs: string[];
  loading: boolean;
  hasError: boolean;
};

const initialState: State = {
  byID: {},
  allIDs: [],
  loading: false,
  hasError: false,
};

const subscriptionsReducer = createReducer<State, Actions>(initialState)
  .handleAction([
    subscriptionsPageMounted,
    loadSubscriptions.request,
    eventPageMounted,
  ], (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }))
  .handleAction(loadSubscriptions.success, (state, action) =>
    produce(state, (draft) => {
      draft.byID = arrayToByID(action.payload);
      draft.allIDs = action.payload.map((s) => s.id);
      draft.loading = false;
    }))
  .handleAction(loadSubscriptions.failure, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.hasError = true;
    }));

export default subscriptionsReducer;
