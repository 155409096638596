import React, { useEffect, useRef, useState } from 'react';
import screenfull from 'screenfull';
import { useLogEvent } from '../../firebase';

import TeaserSlide from './TeaserSlide';
import VideoControls from './VideoControls';

const Slide = ({
  type,
  url,
  style,
  videoControlsVisible,
  isActive,
  goToNextSlide,
  eventId,
}) => {
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [logTime, setLogTime] = useState(0);
  const [watchTime, setWatchTime] = useState(0);

  const videoPlayerRef = useRef(null);
  const slideRef = useRef(null);
  const { logEvent } = useLogEvent();

  useEffect(() => {
    let timeOutId;
    if (isActive && type === 'image') {
      timeOutId = setTimeout(goToNextSlide, 10 * 1000);
    }

    if (!isActive && timeOutId) {
      clearTimeout(timeOutId);
    }

    if (!isActive && type === 'video') {
      pause();
    }
  }, [isActive]);

  const handleEnd = () => {
    pause();
    setProgress(0);
    logEvent('completed_watching', {
      eventId,
      video_url: url,
      video_duration: duration,
    });
    logEvent('percentage_watched_video', {
      eventId,
      category: 'consumer',
      video_progress: '100%',
    });
    setLogTime(100);
    setWatchTime(0);
    goToNextSlide();
  };

  useEffect(() => {
    if (Number.parseInt(watchTime) === 3) {
      logEvent('start_watching', {
        eventId,
        video_url: url,
        video_duration: duration,
      });
    } else if (Number.parseInt(watchTime) === 30) {
      logEvent('watches_30sec', {
        eventId,
        video_url: url,
        video_duration: duration,
      });
    }
  }, [watchTime]);

  useEffect(() => {
    if (logTime === 100) {
      setLogTime(0);
    }
    if (progress / duration >= 0.15 && logTime < 15) {
      logEvent('percentage_watched_video', {
        eventId,
        category: 'consumer',
        video_progress: '15%',
      });
      setLogTime(15);
    } else if (progress / duration >= 0.5 && logTime < 50) {
      logEvent('percentage_watched_video', {
        eventId,
        category: 'consumer',
        video_progress: '50%',
      });
      setLogTime(50);
    } else if (progress / duration >= 0.75 && logTime < 75) {
      logEvent('percentage_watched_video', {
        eventId,
        category: 'consumer',
        video_progress: '75%',
      });
      setLogTime(75);
    }
  }, [progress]);

  useEffect(() => {
    let interval;
    if (playing && watchTime < 31) {
      setTimeout(() => {
        interval = setInterval(() => {
          if (watchTime < 32) {
            setWatchTime((secs) => secs + 1);
          }
        }, 1000);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [playing]);

  const toggleMute = () => {
    setMuted(!muted);
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  const playVideo = () => {
    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
  };

  const onSeek = (progressValue) => {
    setProgress(progressValue);
    videoPlayerRef.current.seekTo(progressValue, 'seconds');
  };

  const handleProgress = (progressValue) => {
    setProgress(progressValue);
  };

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      const videoContainer = slideRef.current;
      screenfull.toggle(videoContainer);
    }
  };

  const toggleFullscreen = () => {
    setFullScreen(!fullScreen);
    handleClickFullscreen();
  };

  return (
    <div ref={slideRef} style={style} className='slide'>
      {type === 'image' && <img src={url} alt='' />}
      {type === 'video' && (
        <div>
          <TeaserSlide
            playerRef={videoPlayerRef}
            playing={playing}
            muted={muted}
            streamUrl={url}
            play={playVideo}
            pause={pause}
            setDuration={setDuration}
            setProgress={handleProgress}
            toggleMute={toggleMute}
            goToNextSlide={goToNextSlide}
            handleEnd={handleEnd}
          />
          <div
            className={`event-video-mask-bottom ${
              videoControlsVisible ? 'visible' : ''
            }`}
          ></div>
          <VideoControls
            playing={playing}
            duration={duration}
            progress={progress}
            muted={muted}
            fullscreen={fullScreen}
            togglePlay={togglePlay}
            toggleMute={toggleMute}
            onSeek={onSeek}
            toggleFullscreen={toggleFullscreen}
            visible={videoControlsVisible}
          />
        </div>
      )}
    </div>
  );
};

export default Slide;
