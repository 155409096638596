import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dummyAvatar from '../../../src/dummy-avatar-300x300.jpg';
import { logError } from '../../helpers';

const Avatar = ({ imgSrc, imgAlt, imgSize, classes }) => {
  const [image, setImage] = useState(imgSrc || dummyAvatar);

  useEffect(() => {
    setImage(imgSrc);
  }, [imgSrc]);

  if (imgSize <= 0 || imgSize > 7 || !imgSize) {
    logError('Component Avatar not rendered, because of wrong imgSize.');
    return null;
  }

  const handleImageError = () => {
    setImage(dummyAvatar);
  };

  return (
    <img
      src={image || dummyAvatar}
      alt={imgAlt}
      className={`avatar avatar-size-${imgSize} ${classes}`}
      onError={handleImageError}
    />
  );
};

Avatar.propTypes = {
  imgAlt: PropTypes.string.isRequired,
  imgSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  classes: PropTypes.string,
};

Avatar.defaultProps = {
  imgAlt: '',
  imgSize: 2,
  classes: '',
};

export default Avatar;
