/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';

import { GameActionsContext } from '../../context/gameActions';
import { AvatarsContext } from '../../context/avatars';
import { InventoryContext } from '../../context/inventory';
import { StickersContext } from '../../context/stickers';

import Heading from '../typography/Heading';
import GameItem from '../gamification/GameItem';
import Avatar from '../common/Avatar';

const MyGoodsDrawer = () => {
  const actionsContext = useContext(GameActionsContext);
  const avatarsContext = useContext(AvatarsContext);
  const inventoryContext = useContext(InventoryContext);
  const stickersContext = useContext(StickersContext);

  const { actions } = actionsContext;
  const { avatars, loadAvatars } = avatarsContext;
  const { inventory } = inventoryContext;
  const { bundles, loadStickers } = stickersContext;

  const tomatoAction = actions.find((a) => a.name === 'Tomato');

  useEffect(async () => {
    await loadAvatars();
    await loadStickers();
  }, []);

  const renderAvatars = () => {
    if (inventory && avatars && avatars.length > 0) {
      return avatars.map((a) => {
        if (inventory[a.id]) {
          return (
            <div key={a.id} className='w-1/2 flex flex-col items-center mb-4'>
              <Avatar
                imgSrc={a?.image?._url}
                imgAlt={`avatar-ring avatar-${a.id}`}
                imgSize={4}
                classes='shadow-card'
              />
            </div>
          );
        }

        return null;
      });
    }

    return null;
  };

  const renderStickers = () => {
    if (inventory && bundles && bundles.length > 0) {
      return bundles.map((b) => {
        if (inventory[b.id]) {
          return (
            <div key={b.id} className='w-1/2 flex flex-col items-center mb-4'>
              <div className='avatar avatar-size-4 sticker-bundle-item shadow-card mb-2'>
                <img className='w-12 h-12 rounded-full' src={b?.icon?._url} />
              </div>
              <span className='game-item-name'>{b.title}</span>
            </div>
          );
        }

        return null;
      });
    }
    return null;
  };

  const renderActions = (buyableActions) => {
    const availableActions = actions.map((a) => {
      if (!inventory) {
        return null;
      }

      if (a.name === 'Clap') {
        return null;
      }

      const actionsInInventory = inventory[a.id];
      let amount = 0;
      let label = '';

      if (actionsInInventory) {
        amount = actionsInInventory.count;
        label = actionsInInventory.title;
      }

      return (
        <div key={a.id} className='w-1/2 flex flex-col items-center mb-4'>
          <GameItem
            img={a.icon._url}
            amount={amount}
            label={label}
            classes='mb-2 shadow-card cursor-default'
            background='blue'
            onClick={() => false}
          />
          <span className='game-item-name'>{a.name}</span>
        </div>
      );
    });

    if (availableActions.every((element) => element === null)) {
      return buyableActions.map((buyable) => {
        if (buyable) {
          return (
            <div
              key={buyable.id}
              className='w-1/2 flex flex-col items-center mb-4'
            >
              <GameItem
                img={buyable.icon._url}
                amount={0}
                label={buyable.name}
                classes='mb-2 shadow-card cursor-default'
                background='blue'
                onClick={() => false}
              />
              <span className='game-item-name'>{buyable.name}</span>
            </div>
          );
        }
      });
    }

    return availableActions;
  };

  return (
    <div className='flex flex-col items-center justify-center h-full'>
      <Heading headingSize={5} classes='mb-16 text-primaryC text-center'>
        My goods
      </Heading>
      <div className='w-full pl-5 pr-7'>
        <div className='flex items-start flex-wrap mb-12'>
          {renderAvatars()}
        </div>
        <div className='flex items-start flex-wrap mb-12'>
          {renderStickers()}
        </div>
        <div className='flex items-start flex-wrap'>
          {renderActions([tomatoAction])}
        </div>
      </div>
    </div>
  );
};

export default MyGoodsDrawer;
