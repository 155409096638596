import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const Table = ({ children, classes }) => {
  if (!children) {
    logError("Component Table not rendered, because 'children' missing.");
    return null;
  }
  return <table className={`table ${classes}`}>{children}</table>;
};

Table.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
};

Table.defaultProps = {
  classes: '',
};

export default Table;
