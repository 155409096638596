import React from 'react';
import Heading from '../typography/Heading';

const ShopItem = ({
  shopItemId,
  headline,
  multiply,
  coins,
  bgImage,
  classes,
  onClick,
}) => {
  const handleClick = () => {
    onClick(shopItemId);
  };
  return (
    <div
      onClick={handleClick}
      className={`shop-item ${classes}`}
      style={{ maxWidth: 238, width: '100%', height: 191 }}
    >
      <div
        className='w-full h-full py-3 px-4'
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundPosition: 'right, left',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
        }}
      >
        <Heading
          headingSize={5}
          classes='w-5/6 mb-3 font-display font-bold text-white tracking-tight'
        >
          {headline}
        </Heading>
        {multiply && (
          <span className='block mt-12 font-display font-bold text-3xl text-white tracking-tight'>
            x{multiply}
          </span>
        )}
        <span className='block font-display font-bold text-xl tracking-tight text-secondaryC opacity-60'>
          {coins} Coins
        </span>
      </div>
    </div>
  );
};

export default ShopItem;
