import React, { useContext, useEffect, useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import useResizeAware from 'react-resize-aware';

import Spinner from '../system/Spinner';
import Modal from '../system/Modal';
import Button from '../common/Button';
import Paragraph from '../typography/Paragraph';
import DevicesDialog from './DevicesDialog';
import CreatorVideoTile from './CreatorVideoTile';
import ParticipantVideoTile from './ParticipantVideoTile';

import { EventsContext } from '../../context/events';
import useBroadcaster from '../../hooks/useBroadcaster';

import {
  getChimeData,
  messagingConnect,
  setStreamLiveStatus,
} from '../../helpers';

const chimeRegionEndpoint = 'https://nearest-media-region.l.chime.aws';
const deploymentDomain = process.env.REACT_APP_DEPLOYMENT_DOMAIN;

const CreatorTile = () => {
  const [resizeListener, sizes] = useResizeAware();

  const { width, height } = sizes;

  return (
    <div
      className={`relative flex items-center justify-center ${
        width > height ? 'col-span-3' : 'col-span-2'
      } col-start-2`}
    >
      <div className='flex relative'>
        {resizeListener}
        <CreatorVideoTile className='participant-tile flex items-center' />
      </div>
    </div>
  );
};

const ParticipantTile = () => {
  const [resizeListener, sizes] = useResizeAware();

  const { width, height } = sizes;

  return (
    <div
      className={`relative ${
        width > height ? 'col-span-3 col-start-5' : 'col-span-2 col-start-6'
      }`}
    >
      <div className='flex relative'>
        {resizeListener}
        <ParticipantVideoTile className='participant-tile flex items-center' />
      </div>
    </div>
  );
};

const StreamParticipants = ({ eventId, eventUUID, accessCode }) => {
  const eventsContext = useContext(EventsContext);
  const { updateEvent } = eventsContext;

  const [loading, setLoading] = useState(false);
  const [showDevicePermissionWarning, setShowDevicePermissionWarning] =
    useState(false);
  const [showDeviceSelection, setShowDeviceSelection] = useState(false);
  const [showCreatorCamera, setShowCreatorCamera] = useState(false);

  const meetingManager = useMeetingManager();
  const { setBroadcasterState } = useBroadcaster();

  const joinMeeting = async (chimeData) => {
    await updateEvent({
      id: eventId,
      videoUrl: chimeData.eventRecord.playbackUrl,
    });

    const joinData = {
      meetingInfo: chimeData.meetingInfo.Meeting,
      attendeeInfo: chimeData.attendeeInfo.Attendee,
    };

    await meetingManager.join(joinData);
    // meetingManager.meetingSession.logger.setLogLevel(1);

    setShowDeviceSelection(true);
  };

  const onBroadcasterJoin = async () => {
    await setStreamLiveStatus(eventUUID, accessCode);
    setBroadcasterState(true);
  };

  useEffect(async () => {
    setLoading(true);

    const chimeData = await getChimeData(
      eventUUID,
      accessCode,
      chimeRegionEndpoint,
      deploymentDomain
    );

    if (!chimeData) {
      setLoading(false);
      return;
    }

    await joinMeeting(chimeData);

    const endpoint = chimeData.meetingInfo.MessagingEndpoint;
    messagingConnect(
      endpoint,
      eventUUID,
      accessCode,
      onBroadcasterJoin,
      chimeData
    );
    setLoading(false);
  }, []);

  const handleDevicesSelection = async () => {
    await meetingManager.start();
    setShowDeviceSelection(false);
    setShowCreatorCamera(true);
  };

  const handleDialogClose = () => {
    setShowDeviceSelection(false);
  };

  return (
    <div className='grid grid-cols-8 gap-4 col-span-full min-h-48 h-full py-4 bg-gray-100 rounded-lg'>
      {loading && (
        <div className='flex flex-1 col-span-full items-center justify-center'>
          <Spinner size='medium' />
        </div>
      )}
      {showCreatorCamera && <CreatorTile />}
      <ParticipantTile />
      <DevicesDialog
        isVisible={showDeviceSelection}
        onAccept={handleDevicesSelection}
        onClose={handleDialogClose}
      />
      <Modal
        title='Media permissions'
        actions={[
          <Button
            key='approve-delete'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={() => setShowDevicePermissionWarning(false)}
          >
            Ok
          </Button>,
        ]}
        isVisible={showDevicePermissionWarning}
        handleClose={() => setShowDevicePermissionWarning(false)}
      >
        <Paragraph>
          We need permission to use the microphone and camera of your device.
        </Paragraph>
      </Modal>
    </div>
  );
};

export default StreamParticipants;
