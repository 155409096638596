import React from 'react';
import PropTypes from 'prop-types';
import Number from '../typography/Number';
import { logError } from '../../helpers';

const ProgressRing = ({ radius, progress, levelNumb, classes }) => {
  if (radius <= 0 || !radius) {
    logError('Component ProgressRing not rendered, because of wrong radius.');
    return null;
  }

  const normalizedRadius = radius - 4;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <div className={`progress-ring ${classes}`}>
      <svg height={radius * 2} width={radius * 2}>
        <defs>
          <linearGradient id='gradient' x1='0%' y1='0%' x2='0%' y2='100%'>
            <stop offset='0%' stopColor='#ff004f' />
            <stop offset='100%' stopColor='#5200e9' />
          </linearGradient>
        </defs>
        <circle
          stroke='rgba(0, 0, 0, 0.09)'
          fill='transparent'
          strokeWidth={4}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          stroke='url(#gradient)'
          fill='transparent'
          strokeWidth={4}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
      <Number
        numberSize={1}
        classes='absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-primaryA font-bold'
      >
        {levelNumb}
      </Number>
    </div>
  );
};

ProgressRing.propTypes = {
  radius: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  levelNumb: PropTypes.number,
};

export default ProgressRing;
