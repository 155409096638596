import { API, graphqlOperation, Storage } from 'aws-amplify';
import { logError } from '../helpers';
import { teaserVideoByEventID } from '../graphql/queries';
import { deleteTeaserVideo, createTeaserVideo } from '../graphql/mutations';
import { onUpdateTeaserVideoByID } from '../graphql/subscriptions';
import awsExports from '../aws-exports';
import { allowedVideoTypes } from '../helpers';

const getS3ImageObject = async (s3PutRes) => {
  return {
    bucket: awsExports['aws_user_files_s3_bucket'],
    region: awsExports['aws_user_files_s3_bucket_region'],
    key: s3PutRes.key,
  };
};

const useEventVideos = () => {
  const getVideosByEventId = async (eventID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(teaserVideoByEventID, { eventID })
      );

      return { success: true, videos: res.data.teaserVideoByEventID.items };
    } catch (error) {
      logError('getVideosByEventId', error);
      return { success: false, videos: [] };
    }
  };

  const getLatestDelayedLiveVideoByEventId = async (eventID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(teaserVideoByEventID, {
          eventID,
          filter: { isDelayedLive: { eq: true } },
          limit: 100,
        })
      );

      const videos = res.data.teaserVideoByEventID.items;
      const sortedByDate = videos.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      const latest = sortedByDate[0] || null;

      return { success: true, video: latest };
    } catch (error) {
      logError('getLatestDelayedLiveVideoByEventId', error);
      return { success: false, video: null };
    }
  };

  const removeVideo = async (videoId) => {
    try {
      await API.graphql(
        graphqlOperation(deleteTeaserVideo, { input: { id: videoId } })
      );
      return { success: true, error: null };
    } catch (error) {
      logError('Error deleting video', error);
      return { success: false, error: 'Error deleting video' };
    }
  };

  const uploadVideo = async (eventID, file, length, onUploadProgress) => {
    try {
      const videoFileType = file.type;
      if (!allowedVideoTypes[videoFileType]) {
        const error = `Video with type ${videoFileType} is not allowed!`;
        return { success: false, error, video: null };
      }

      const videoExt = allowedVideoTypes[videoFileType];
      const videoFileName = `${Date.now()}.${videoExt}`;

      const videoS3PutRes = await Storage.put(videoFileName, file, {
        useAccelerateEndpoint: true, // use accelerated S3 endpoint
        contentType: videoFileType,
        progressCallback: (progress) => onUploadProgress(progress),
      });

      if (videoS3PutRes) {
        const videoS3Object = await getS3ImageObject(videoS3PutRes);
        const res = await API.graphql(
          graphqlOperation(createTeaserVideo, {
            input: {
              eventID,
              length,
              file: videoS3Object,
              isDelayedLive: true,
            },
          })
        );

        return {
          success: true,
          error: null,
          video: res.data.createTeaserVideo,
        };
      }
    } catch (error) {
      logError('uploadVideo', error);
      return { success: false, video: null, error: 'Video upload failed!' };
    }
  };

  const createVideoUpdateSubscription = async (videoId, handleUpdate) => {
    const videoUpdateSubscription = await API.graphql(
      graphqlOperation(onUpdateTeaserVideoByID, {
        id: videoId,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        if (value.data.onUpdateTeaserVideoByID) {
          handleUpdate(value.data.onUpdateTeaserVideoByID);
        }
      },
      error: (error) => logError(error),
    });

    return videoUpdateSubscription;
  };

  const removeVideoUpdateSubscription = async (subscription) => {
    if (subscription.unsubscribe) {
      await subscription.unsubscribe();
    }
  };

  return {
    getVideosByEventId,
    getLatestDelayedLiveVideoByEventId,
    removeVideo,
    uploadVideo,
    createVideoUpdateSubscription,
    removeVideoUpdateSubscription,
  };
};

export default useEventVideos;
