/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';

import ImageService from 'infrastructure/ImageService';

import Heading from '../components/typography/Heading';
import Button from '../components/common/Button';
import RoomLiveEvents from '../components/room/RoomLiveEvents';
import RoomUpcomingEvents from '../components/room/RoomUpcomingEvents';
import RoomEndedEvents from '../components/room/RoomEndedEvents';
import RoomAbout from '../components/room/RoomAbout';
import RoomVideos from '../components/room/RoomVideos';
import Skeleton from 'react-loading-skeleton';
import RoomAllEvents from '../components/room/RoomAllEvents';

import { SponsorRoomsContext } from '../context/sponsorRooms';
import { UserContext } from '../context/user';

import history from '../history';
import routes from '../routes';
import { useLogEvent } from '../firebase';

import Share from 'components/creator/Share';
import CustomShareButton from 'components/event/CustomShareButton';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const Room = () => {
  const { roomId, tab = 'all', slug } = useParams();

  const [room, setRoom]       = useState(null);
  const [loading, setLoading] = useState(false);
  const [id, setId]           = useState(roomId);

  const {
    getCurrentUserId,
    getCurrentUserDisplayName
  } = useContext(UserContext);

  const {
    getRoomById,
    followedRooms,
    loadUserFollowings,
    toggleFollowRoom,
    getRoomBySeo,
  } = useContext(SponsorRoomsContext);

  const { logEvent } = useLogEvent();

  const loadRoom = async () => {
    setLoading(true);

    const r = slug ? await getRoomBySeo(slug) : await getRoomById(id);

    if (!id) setId(r.id);

    await loadUserFollowings(getCurrentUserId());

    if (!r) {
      history.replace(routes.notFound);
      return;
    }

    setRoom(r);
    setLoading(false);
  };

  useEffect(() => {
    setRoom(null);
    loadRoom();
  }, [id]);

  const isFollowed = followedRooms.some((room) => room.sponsorRoomID === id);

  const handleFollowRoom = async () => {
    if (id) {
      await toggleFollowRoom(
        id,
        getCurrentUserId(),
        getCurrentUserDisplayName()
      );
      if (!isFollowed) {
        logEvent('follow', {
          category: 'engagement',
          creator_id: id,
        });
      }
    }
  };

  let sharingUrls = {};

  if (id) {
    sharingUrls = {
      desktop: `${window.location.origin}/creator/${id}/tab/all`,
      ios: `/creator/${id}`,
      android: `/creator/${id}`,
    };
  }

  return (
    <div className='relative col-span-full'>
      {room?.coverPhoto?.key && (
        <div
          className='room-banner-container'
          style={
            room?.coverPhoto?.key
              ? {
                  backgroundImage: `url(${imgBaseUrl}${room.coverPhoto.key})`,
                  backgroundPosition: 'right, left',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }
              : null
          }
        ></div>
      )}
      <div className='room-title-container'>
        {!loading && room?.image?.key && (
          <img
            src={`${imgBaseUrl}${room.image.key}?w=128`}
            className='room-image'
          />
        )}
        {!room && loading && (
          <Skeleton circle width={64} height={64} className='mr-4' />
        )}
        {room && !loading && <Heading headingSize={4}>{room.title}</Heading>}
        {!room && loading && <Skeleton width={240} height={20} />}
        <div className='flex flex-1 justify-end'>
          {room && (
            <Button
              buttonId='follow'
              buttonType='secondary'
              buttonSize='medium'
              isLink={false}
              classes='ml-4 text-center xl:text-left xl:ml-8 xl:flex'
              icon={isFollowed ? 'check' : 'heart'}
              iconClasses='m-auto xl:mr-2 text-primaryA'
              disabled={false}
              buttonOnClick={handleFollowRoom}
            >
              <span className='hidden xl:inline-block'>
                {isFollowed ? 'Followed' : 'Follow'}
              </span>
            </Button>
          )}
        </div>
        <div className='flex justify-end ml-4'>
          {room && (
            <Share
              id={room.id}
              title={room.title}
              desc={room.description}
              url={sharingUrls}
              img={ImageService.getImageByKey(room.image.key)}
              contentType='room'
              CustomButton={CustomShareButton}
              positionClasses='room-share-position'
            />
          )}
        </div>
      </div>
      <div className='room-navigation-container'>
        <Link
          to={routes.room.replace(':roomId', id).replace(':tab', 'all')}
          className={`room-navigation-button mr-8${
            tab === 'all' ? ' active' : ''
          }`}
        >
          All events
        </Link>
        <Link
          to={routes.room.replace(':roomId', id).replace(':tab', 'live')}
          className={`room-navigation-button mr-8${
            tab === 'live' ? ' active' : ''
          }`}
        >
          Live event
        </Link>
        <Link
          className={`room-navigation-button mr-8${
            tab === 'upcoming' ? ' active' : ''
          }`}
          to={routes.room.replace(':roomId', id).replace(':tab', 'upcoming')}
        >
          Upcoming events
        </Link>
        <Link
          className={`room-navigation-button mr-8${
            tab === 'ended' ? ' active' : ''
          }`}
          to={routes.room.replace(':roomId', id).replace(':tab', 'ended')}
        >
          Ended events
        </Link>
        <Link
          className={`room-navigation-button mr-8${
            tab === 'videos' ? ' active' : ''
          }`}
          to={routes.room.replace(':roomId', id).replace(':tab', 'videos')}
        >
          Videos
        </Link>
        <Link
          className={`room-navigation-button${
            tab === 'about' ? ' active' : ''
          }`}
          to={routes.room.replace(':roomId', id).replace(':tab', 'about')}
        >
          About
        </Link>
      </div>
      {tab === 'all' && <RoomAllEvents roomId={id} />}
      {tab === 'live' && <RoomLiveEvents roomId={id} />}
      {tab === 'upcoming' && <RoomUpcomingEvents roomId={id} />}
      {tab === 'ended' && <RoomEndedEvents roomId={id} />}
      {tab === 'videos' && <RoomVideos roomId={id} />}
      {tab === 'about' && <RoomAbout room={room} />}
    </div>
  );
};

export default Room;
