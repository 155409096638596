import React, { useContext, useEffect } from 'react';
import Avatar from '../common/Avatar';
import DropdownList from '../dropdown/DropdownList';
import history from '../../history';
import routes from '../../routes';

import { AvatarsContext } from '../../context/avatars';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { UserContext } from '../../context/user';
import { useLogEvent } from '../../firebase';

const UserDropdown = ({ image, userData, signOut, classes }) => {
  const avatarsContext = useContext(AvatarsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);

  const { clearSelectedAvatar } = avatarsContext;
  const { loadRooms, rooms, currentUserRoom } = sponsorRoomsContext;
  const { isCreator } = userContext;
  const { logEvent } = useLogEvent();

  useEffect(() => {
    if (!rooms || rooms.length === 0) {
      loadRooms(userData.id);
    }
  }, []);

  const handleLogout = (event) => {
    event.preventDefault();
    clearSelectedAvatar();
    signOut();
  };

  const goToProfile = () => {
    history.push(routes.accountProfile);
  };

  // const goToAdmin = () => {
  //   history.push(routes.eventAdministration);
  // };

  const goToMyRoom = () => {
    logEvent('visit_creator_tool', { category: 'creator' });

    if (currentUserRoom?.isLive) {
      history.push(routes.roomDashboard);
      return;
    }

    history.push(routes.myRoom);
  };

  const createDropdownItems = () => {
    // if (rooms && rooms.length > 0) {
    //   if (currentUserRoom) {
    //     return [
    //       { text: 'Profile', onClick: goToProfile },
    //       { text: 'Administration', onClick: () => goToAdmin() },
    //       {
    //         text: 'Log out',
    //         onClick: handleLogout,
    //       },
    //     ];
    //   }
    // }

    if (isCreator) {
      return [
        { text: 'Profile', onClick: goToProfile },
        { text: 'My Room', onClick: goToMyRoom },
        {
          text: 'Log Out',
          onClick: handleLogout,
        },
      ];
    }

    return [
      { text: 'Profile', onClick: goToProfile },
      {
        text: 'Log Out',
        onClick: handleLogout,
      },
    ];
  };

  return (
    <div className={`inline-block relative ${classes}`}>
      <DropdownList items={createDropdownItems()}>
        <Avatar
          imgSize={7}
          imgSrc={image}
          imgAlt=''
          classes='inline-block mr-2'
        />
        <span className='navbar-user-displayName'>{userData.displayName}</span>
      </DropdownList>
    </div>
  );
};

export default UserDropdown;
