/* eslint-disable new-cap */
import React, { useContext } from 'react';
import Avatar from '../common/Avatar';
import Tooltip from '../system/Tooltip';
import Icon from '../typography/Icon';
import DropdownList from '../dropdown/DropdownList';
import { UserReportsContext } from '../../context/user-reports';
import { UserContext } from '../../context/user';

import coinsImg from '../../assets/donation-coins.png';
import { StickyCommentsContext } from '../../context/stickyComments';

const formatDate = (date) => {
  const options = {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return `${new Intl.DateTimeFormat('en-US', options).format(new Date(date))}`;
};

const ChatMessage = ({
  time,
  avatar,
  level,
  userName,
  msg,
  stickerId,
  stickerUrl,
  inPreview,
  inEvent,
  inCreator,
  showReport,
  messageUserId,
  showCensored,
  censoredMsg,
  eventId,
  showPinAction = false,
}) => {
  const userReportsContext = useContext(UserReportsContext);
  const userContext = useContext(UserContext);
  const stickyCommentsContext = useContext(StickyCommentsContext);

  const { openModal } = userReportsContext;
  const { getCurrentUserId } = userContext;
  const { createOrUpdateStickyComment } = stickyCommentsContext;

  const openReportModal = () => {
    const reportingUserID = getCurrentUserId();
    openModal(reportingUserID, messageUserId, msg);
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() == today.getDate() &&
      date.getMonth() == today.getMonth() &&
      date.getFullYear() == today.getFullYear()
    );
  };

  const getDate = (date) => {
    if (!isToday(new Date(date))) {
      return formatDate(date);
    }

    return new Date(date).toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const pinComment = () => {
    createOrUpdateStickyComment(eventId, getCurrentUserId(), {
      text: msg,
      commentUserName: userName,
      commentCreatedAt: time,
    });
  };

  const dropdownItems = [];

  if (showReport) {
    dropdownItems.push({ text: 'Report', onClick: openReportModal });
  }

  if (showPinAction) {
    dropdownItems.push({ text: 'Pin Comment', onClick: pinComment });
  }

  const getClasses = () => {
    if (inPreview) {
      return 'in-preview';
    }

    if (inEvent) {
      return 'in-event';
    }

    if (inCreator) {
      return 'in-creator';
    }
  };

  const getMsgType = () => {
    if (msg === '#raise_hand') {
      return 'handraise';
    }

    if (msg.includes('#donation_')) {
      return 'donation';
    }

    return 'comment';
  };

  const getMessage = () => {
    let amount;

    if (showCensored) {
      return censoredMsg || '...';
    }

    switch (getMsgType()) {
      case 'handraise':
        return 'Raises hand';
      case 'donation':
        amount = msg.replace('#donation_', '');
        return (
          <span>
            just donated <span className='font-bold'>{amount} coins</span>
          </span>
        );
      default:
        return msg;
    }
  };

  if (showCensored && !censoredMsg) {
    return null;
  }

  return (
    <div className={`chat-message ${getClasses()}`}>
      <time dateTime={time} className={`chat-message-time ${getClasses()}`}>
        {getDate(time)}
      </time>
      {(showReport || showPinAction) && (
        <div className={`chat-dots`}>
          <DropdownList
            items={dropdownItems}
            classes='w-32 right-4 bottom-0'
            showArrow={false}
          >
            <Icon name='dots' size={17} classes='text-secondaryA' />
          </DropdownList>
        </div>
      )}
      <div className='chat-message-avatar-container'>
        <Avatar imgSrc={avatar} imgAlt={`user-name avatar`} imgSize={6} />
        <div className={`chat-level ${getClasses()}`}>
          {level}
          <Tooltip arrowSide='left-arrow' txt='Level' classes='tooltip-level' />
        </div>
      </div>
      <p className={`chat-message-text-container ${getClasses()}`}>
        <span className={`chat-message-username ${getClasses()}`}>
          {userName}
          {getMsgType() !== 'donation' ? ':' : ''}
        </span>
        <span
          className={`${
            getMsgType() === 'handraise' ? 'font-bold text-primaryADark' : ''
          }`}
        >
          {getMessage()}
          {getMsgType() === 'donation' && (
            <img className='w-auto h-5 inline-block mb-1 ml-1' src={coinsImg} />
          )}
        </span>
        {stickerId && stickerUrl && (
          <img src={stickerUrl} className='h-24 mt-2' />
        )}
      </p>
    </div>
  );
};

export default ChatMessage;
