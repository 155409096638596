import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { ChatContext } from './chat';
import { chatMessagesByEventID, getUser } from '../graphql/queries';
import { createChatMessage } from '../graphql/mutations';
import { onCreateChatMessage } from '../graphql/subscriptions';
import { logError } from '../helpers';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;
export default class ChatProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatMessages: {},
      chatSubscribtions: {},
      chatNextTokens: {},
      allUsers: {},
      loadingMessages: false,
      loadMessages: this.loadMessages,
      loadMoreMessages: this.loadMoreMessages,
      createNewMessage: this.createNewMessage,
      subscribeToChat: this.subscribeToChat,
      unsubscribeFromChat: this.unsubscribeFromChat,
      removeSubscribtion: this.removeSubscribtion,
    };
  }

  addUserData = async (userId) => {
    if (this.state.allUsers[userId]) {
      return;
    }

    const userData = await API.graphql(
      graphqlOperation(getUser, { id: userId })
    );

    const s3Key = userData?.data?.getUser?.image?.key;

    if (s3Key) {
      userData.data.getUser.image._url = `${imgBaseUrl}${s3Key}?d=32x32`;
    }

    const users = {
      ...this.state.allUsers,
      [userId]: userData.data.getUser,
    };

    this.setState({ allUsers: users });
  };

  loadMessages = async (eventID) => {
    const messages = await this.getMessagesByEventId(eventID);
    const subscription = await this.subscribeToChat(
      eventID,
      (newMessage) => this.updateMessages(newMessage),
      (error) => logError(error)
    );

    for (const msg of messages) {
      await this.addUserData(msg.userID);
    }

    this.setState({
      chatMessages: {
        ...this.state.chatMessages,
        [eventID]: messages,
      },
      chatSubscribtions: {
        ...this.state.chatSubscribtions,
        [eventID]: subscription,
      },
    });
  };

  loadMoreMessages = async (eventID) => {
    if (this.state.loadingMessages) {
      return;
    }

    this.setState({ loadingMessages: true });

    const input = {
      eventID,
      time: { lt: Math.floor(Date.now() / 1000) },
      sortDirection: 'DESC',
      limit: 10,
      nextToken: this.state.chatNextTokens[eventID] || null,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(chatMessagesByEventID, input)
      );

      const newMessages =
        res?.data?.chatMessagesByEventID?.items?.reverse() || [];

      for (const msg of newMessages) {
        await this.addUserData(msg.userID);
      }

      const chatNextTokens = {
        ...this.state.chatNextTokens,
        [eventID]: res?.data?.chatMessagesByEventID?.nextToken || null,
      };

      const oldMessages = this.state.chatMessages[eventID] || [];

      this.setState({
        loadingMessages: false,
        chatMessages: {
          ...this.state.chatMessages,
          [eventID]: [...newMessages, ...oldMessages],
        },
        chatNextTokens,
      });
    } catch (error) {
      logError(error);
      const chatNextTokens = {
        ...this.state.chatNextTokens,
        [eventID]: null,
      };
      const chatMessages = {
        ...this.state.chatMessages,
        [eventID]: [],
      };
      this.setState({ loadingMessages: false, chatNextTokens, chatMessages });
    }
  };

  updateMessages = async (newMsg) => {
    const eventId = newMsg.eventID;

    const existingMsg = this.state.chatMessages[eventId]?.find(
      (e) => e.id === newMsg.id
    );

    let updatedMessages = [];
    if (existingMsg) {
      for (const msg of this.state.chatMessages[eventId]) {
        if (msg.id === newMsg.id) {
          updatedMessages.push(newMsg);
        } else {
          updatedMessages.push(msg);
        }
      }
    } else {
      updatedMessages = [...this.state.chatMessages[eventId], newMsg];
      await this.addUserData(newMsg.userID);
    }

    this.setState({
      chatMessages: {
        ...this.state.chatMessages,
        [eventId]: updatedMessages,
      },
    });
  };

  getMessagesByEventId = async (eventID) => {
    if (this.state.loadingMessages || this.state.chatMessages[eventID]) {
      return this.state.chatMessages[eventID] || [];
    }

    this.setState({ loadingMessages: true });
    const input = {
      eventID,
      time: { lt: Math.floor(Date.now() / 1000) },
      sortDirection: 'DESC',
      limit: 10,
      nextToken: null,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(chatMessagesByEventID, input)
      );

      const chatNextTokens = {
        ...this.state.chatNextTokens,
        [eventID]: res?.data?.chatMessagesByEventID?.nextToken || null,
      };

      this.setState({ loadingMessages: false, chatNextTokens });

      return res?.data?.chatMessagesByEventID?.items?.reverse() || [];
    } catch (error) {
      logError(error);
      const chatNextTokens = {
        ...this.state.chatNextTokens,
        [eventID]: null,
      };
      this.setState({ loadingMessages: false, chatNextTokens });
      return [];
    }
  };

  createNewMessage = async (
    userID,
    eventID,
    content = null,
    stickerID = null,
    userDisplayName = '',
    userAvatar = '',
    level = 0
  ) => {
    const input = {
      userID,
      eventID,
      content,
      stickerID,
      displayName: userDisplayName,
      avatar: userAvatar,
      level,
      time: Math.floor(Date.now() / 1000),
    };

    await API.graphql(graphqlOperation(createChatMessage, { input }));
  };

  subscribeToChat = (eventID, handleUpdate, handleError) => {
    const chatSubscription = API.graphql(
      graphqlOperation(onCreateChatMessage, {
        eventID,
      })
    ).subscribe({
      next: ({ provider, value }) =>
        handleUpdate(value.data.onCreateChatMessage),
      error: (error) => handleError(error),
    });

    return chatSubscription;
  };

  unsubscribeFromChat = (subscribtion) => {
    subscribtion.unsubscribe();
  };

  removeSubscribtion = async (eventId) => {
    const subscribtion = this.state.chatSubscribtions[eventId];

    if (subscribtion) {
      const allSubscribtions = this.state.chatSubscribtions;
      delete allSubscribtions[eventId];
      await subscribtion.unsubscribe();
      this.setState({ chatSubscribtions: allSubscribtions });
    }
  };

  render() {
    return (
      <ChatContext.Provider value={this.state}>
        {this.props.children}
      </ChatContext.Provider>
    );
  }
}
