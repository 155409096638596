import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import explode from '../../lotties/Explode.json';

const ClapButton = ({ img, classes, btnColor, btnSize, onClick, disabled }) => {
  const [isStopped, setStopped] = useState(true);

  const handleClick = (event) => {
    onClick();
    setStopped(false);
  };

  const delayed = false;
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: explode,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      className={`game-item-container bg-white border-2 flex items-center justify-center border-solid ${
        delayed ? 'delay' : ''
      } ${disabled ? 'empty' : ''} ${classes}`}
      style={{ width: btnSize * 2, height: btnSize * 2, borderColor: btnColor }}
    >
      <Lottie
        speed={2}
        options={defaultOptions}
        height={btnSize * 12}
        width={btnSize * 12}
        isStopped={isStopped}
        direction={1}
        isPaused={false}
        isClickToPauseDisabled={true}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => {
              setStopped(true);
            },
          },
        ]}
        style={{
          position: 'absolute',
          cursor: 'default',
          zIndex: 10,
          pointerEvents: 'none',
        }}
      />
      <div
        style={{
          width: btnSize * 2,
          height: btnSize * 2,
        }}
        className={`flex items-center justify-center  rounded-full ${
          disabled ? 'cursor-default' : 'cursor-pointer'
        }`}
        onClick={handleClick}
      >
        <img src={img} className='gamification-img' />
      </div>
    </div>
  );
};

ClapButton.propTypes = {
  classes: PropTypes.string,
  btnColor: PropTypes.string,
  btnSize: PropTypes.number,
  disabled: PropTypes.bool,
};

ClapButton.defaultProps = {
  coolDownSec: 6,
  classes: '',
  btnColor: '',
  disabled: false,
};

export default ClapButton;
