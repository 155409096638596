import React, { useState } from 'react';
import Heading from '../typography/Heading';
import CodeInput from '../form/CodeInput';
import Button from '../common/Button';

const AttributeCodeInput = ({
  type,
  isOpen,
  receiver,
  onSubmit,
  resendCode,
}) => {
  const [code, setCode] = useState('');

  const handleCodeChange = (value) => {
    setCode(value);
  };

  const handleSubmit = () => {
    onSubmit(code);
  };

  return (
    <div className={`profile-details-code-box ${isOpen ? '' : 'hide'}`}>
      <Heading
        headingSize={6}
        classes='mb-16 font-bold text-primaryC text-center tracking-tight'
      >
        {`Verify your ${type}`}
      </Heading>
      <CodeInput
        codeValue={code}
        codeOnChange={handleCodeChange}
        codeId={`inputCode-${type}`}
        codeType='number'
        codeFieldsNumb={6}
        codeName='inputCode'
        codeFocus={true}
        codeDisabled={false}
        label={
          <>
            Please enter the verification code sent to{' '}
            <span className='font-bold'>{receiver}</span>
          </>
        }
        wrapClasses='w-1/2 2xl:w-1/3 mx-auto mb-10'
        onEnter={handleSubmit}
        handleCodeResend={resendCode}
      />
      <Button
        buttonId='codeDone'
        buttonType='secondary'
        buttonSize='medium'
        isLink={false}
        classes='relative transform left-1/2 -translate-x-1/2 inline-blok w-52'
        disabled={false}
        buttonOnClick={handleSubmit}
      >
        Done
      </Button>
    </div>
  );
};

export default AttributeCodeInput;
