import React from 'react';
import {
  useRemoteVideoTileState,
  RemoteVideo,
} from 'amazon-chime-sdk-component-library-react';

const ParticipantVideoTile = ({ className }) => {
  const { tiles } = useRemoteVideoTileState();

  if (tiles.length === 0) {
    return null;
  }

  return tiles.map((tileId) => {
    return (
      <RemoteVideo
        id='particiantTile'
        key={tileId}
        className={className}
        tileId={tileId}
      />
    );
  });
};

export default ParticipantVideoTile;
