import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { subscriptionsPageMounted } from 'store/subscriptions/actions';

import SubscriptionPassListMeta from './components/SubscriptionPassListMeta';
import SubscriptionPassListHeader from './components/SubscriptionPassListHeader';
import SubscriptionPassListMainContent from './components/SubscriptionPassListMainContent';
import { SponsorRoomsContext } from 'context/sponsorRooms';

const SubscriptionPassList = () => {
  const { currentUserRoom } = useContext<any>(SponsorRoomsContext);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!currentUserRoom) return;

    dispatch(subscriptionsPageMounted(currentUserRoom.id));
  }, [currentUserRoom]);

  return (
    <>
      <SubscriptionPassListMeta />
      <SubscriptionPassListHeader />
      <SubscriptionPassListMainContent />
    </>
  );
};

export default SubscriptionPassList;
