/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBoosterActivation = /* GraphQL */ `
  query GetBoosterActivation($id: ID!) {
    getBoosterActivation(id: $id) {
      id
      userID
      boosterID
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listBoosterActivations = /* GraphQL */ `
  query ListBoosterActivations(
    $filter: ModelBoosterActivationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoosterActivations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        boosterID
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      description
      category
      type
      runWorkflowAutomatically
      videoInputUrl
      streamKey
      workflowID
      workflowType
      videoUrl
      teaserUrl
      videoRecordingDownloadUrl
      teaserThumbnailUrl
      libraryVodGuid
      startTime
      endTime
      startTimestamp
      endTimestamp
      hostSponsorRoomID
      moderatorID
      contestorA
      contestorAColor
      contestorB
      contestorBColor
      contestorAScore
      contestorBScore
      image {
        bucket
        region
        key
        _url
      }
      image2 {
        bucket
        region
        key
        _url
      }
      image3 {
        bucket
        region
        key
        _url
      }
      image4 {
        bucket
        region
        key
        _url
      }
      teaserFile {
        bucket
        region
        key
        _url
      }
      teaserFileBrokerID
      landscapeImage {
        bucket
        region
        key
        _url
      }
      landscapeImage2 {
        bucket
        region
        key
        _url
      }
      landscapeImage3 {
        bucket
        region
        key
        _url
      }
      landscapeImage4 {
        bucket
        region
        key
        _url
      }
      verticalImage {
        bucket
        region
        key
        _url
      }
      verticalImage2 {
        bucket
        region
        key
        _url
      }
      verticalImage3 {
        bucket
        region
        key
        _url
      }
      verticalImage4 {
        bucket
        region
        key
        _url
      }
      currentRound
      state
      paused
      archived
      deleted
      censorChat
      realm
      highlighted
      createdAt
      updatedAt
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      eventCategorisationID
      roundsEnabled
      mixerEventUUID
      mixerEventModeratorUUID
      mixerEventModeratorAccessCode
      mode
      disableChat
      dynamicParticipants
      ppv
      ppvPrice
      duration
      richTextDescription
      googleAdTagUrl
      seoUrl
      moderator {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
      hostSponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventsByTitle = /* GraphQL */ `
  query EventsByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventsByWorkflowID = /* GraphQL */ `
  query EventsByWorkflowID(
    $workflowID: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByWorkflowID(
      workflowID: $workflowID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByType = /* GraphQL */ `
  query EventByType(
    $type: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByType(
      type: $type
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventBySeoUrl = /* GraphQL */ `
  query EventBySeoUrl(
    $seoUrl: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySeoUrl(
      seoUrl: $seoUrl
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventBySeo = /* GraphQL */ `
  query EventBySeo(
    $seoUrl: String
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySeo(
      seoUrl: $seoUrl
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByCategory = /* GraphQL */ `
  query EventByCategory(
    $category: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByCategory(
      category: $category
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByState = /* GraphQL */ `
  query EventByState(
    $state: String
    $startTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByState(
      state: $state
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByStateAndMode = /* GraphQL */ `
  query EventByStateAndMode(
    $state: String
    $mode: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByStateAndMode(
      state: $state
      mode: $mode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByStateAndRoomID = /* GraphQL */ `
  query EventByStateAndRoomID(
    $state: String
    $hostSponsorRoomID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByStateAndRoomID(
      state: $state
      hostSponsorRoomID: $hostSponsorRoomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventBySponsorRoom = /* GraphQL */ `
  query EventBySponsorRoom(
    $hostSponsorRoomID: ID
    $startTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySponsorRoom(
      hostSponsorRoomID: $hostSponsorRoomID
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventBySponsorRoomAndState = /* GraphQL */ `
  query EventBySponsorRoomAndState(
    $hostSponsorRoomID: ID
    $state: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventBySponsorRoomAndState(
      hostSponsorRoomID: $hostSponsorRoomID
      state: $state
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByStartTimestampRealm = /* GraphQL */ `
  query EventByStartTimestampRealm(
    $realm: String
    $startTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByStartTimestampRealm(
      realm: $realm
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventByHighlighting = /* GraphQL */ `
  query EventByHighlighting(
    $highlighted: Int
    $startTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByHighlighting(
      highlighted: $highlighted
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventsByCategorisationID = /* GraphQL */ `
  query EventsByCategorisationID(
    $eventCategorisationID: ID
    $startTimestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByCategorisationID(
      eventCategorisationID: $eventCategorisationID
      startTimestamp: $startTimestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getGoogleDefaultTagUrl = /* GraphQL */ `
  query GetGoogleDefaultTagUrl($id: ID!) {
    getGoogleDefaultTagUrl(id: $id) {
      id
      googleAdTagUrl
      createdAt
      updatedAt
    }
  }
`;
export const listGoogleDefaultTagUrls = /* GraphQL */ `
  query ListGoogleDefaultTagUrls(
    $filter: ModelGoogleDefaultTagUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGoogleDefaultTagUrls(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        googleAdTagUrl
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOnEventEndedTrigger = /* GraphQL */ `
  query GetOnEventEndedTrigger($id: ID!) {
    getOnEventEndedTrigger(id: $id) {
      id
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const listOnEventEndedTriggers = /* GraphQL */ `
  query ListOnEventEndedTriggers(
    $filter: ModelOnEventEndedTriggerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnEventEndedTriggers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventUserWatchTime = /* GraphQL */ `
  query GetEventUserWatchTime($id: ID!) {
    getEventUserWatchTime(id: $id) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listEventUserWatchTimes = /* GraphQL */ `
  query ListEventUserWatchTimes(
    $filter: ModelEventUserWatchTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventUserWatchTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const watchTimeByEventId = /* GraphQL */ `
  query WatchTimeByEventId(
    $eventID: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventUserWatchTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    watchTimeByEventId(
      eventID: $eventID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventStatistics = /* GraphQL */ `
  query GetEventStatistics($id: ID!) {
    getEventStatistics(id: $id) {
      id
      eventLength
      totalViewers
      totalUniqueViewers
      totalComments
      averageWatchTime
      watchGraph
      claps
      tomatoes
      createdAt
      updatedAt
    }
  }
`;
export const listEventStatistics = /* GraphQL */ `
  query ListEventStatistics(
    $filter: ModelEventStatisticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventStatistics(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventLength
        totalViewers
        totalUniqueViewers
        totalComments
        averageWatchTime
        watchGraph
        claps
        tomatoes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      hostSponsorRoomID
      userID
      title
      description
      richTextDescription
      eventCategorisationID
      thumbnail {
        bucket
        region
        key
        _url
      }
      generatedThumbnailUrl
      listing
      length
      ppv
      ppvPrice
      videoFile {
        bucket
        region
        key
        _url
      }
      playbackUrl
      createdAt
      updatedAt
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        hostSponsorRoomID
        userID
        title
        description
        richTextDescription
        eventCategorisationID
        thumbnail {
          bucket
          region
          key
          _url
        }
        generatedThumbnailUrl
        listing
        length
        ppv
        ppvPrice
        videoFile {
          bucket
          region
          key
          _url
        }
        playbackUrl
        createdAt
        updatedAt
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const videosByListingType = /* GraphQL */ `
  query VideosByListingType(
    $listing: String
    $hostSponsorRoomID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videosByListingType(
      listing: $listing
      hostSponsorRoomID: $hostSponsorRoomID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostSponsorRoomID
        userID
        title
        description
        richTextDescription
        eventCategorisationID
        thumbnail {
          bucket
          region
          key
          _url
        }
        generatedThumbnailUrl
        listing
        length
        ppv
        ppvPrice
        videoFile {
          bucket
          region
          key
          _url
        }
        playbackUrl
        createdAt
        updatedAt
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const videosByHostSponsorRoomID = /* GraphQL */ `
  query VideosByHostSponsorRoomID(
    $hostSponsorRoomID: ID
    $listing: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    videosByHostSponsorRoomID(
      hostSponsorRoomID: $hostSponsorRoomID
      listing: $listing
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        hostSponsorRoomID
        userID
        title
        description
        richTextDescription
        eventCategorisationID
        thumbnail {
          bucket
          region
          key
          _url
        }
        generatedThumbnailUrl
        listing
        length
        ppv
        ppvPrice
        videoFile {
          bucket
          region
          key
          _url
        }
        playbackUrl
        createdAt
        updatedAt
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getEventStickyMessage = /* GraphQL */ `
  query GetEventStickyMessage($id: ID!) {
    getEventStickyMessage(id: $id) {
      id
      eventID
      moderatorID
      text
      originalCommentDisplayName
      originalCommentTime
      createdAt
      updatedAt
    }
  }
`;
export const listEventStickyMessages = /* GraphQL */ `
  query ListEventStickyMessages(
    $filter: ModelEventStickyMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventStickyMessages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        moderatorID
        text
        originalCommentDisplayName
        originalCommentTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const stickyMessageByEventId = /* GraphQL */ `
  query StickyMessageByEventId(
    $eventID: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventStickyMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stickyMessageByEventId(
      eventID: $eventID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        moderatorID
        text
        originalCommentDisplayName
        originalCommentTime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventScore = /* GraphQL */ `
  query GetEventScore($id: ID!) {
    getEventScore(id: $id) {
      id
      contestorAScore
      contestorBScore
      createdAt
      updatedAt
    }
  }
`;
export const listEventScores = /* GraphQL */ `
  query ListEventScores(
    $filter: ModelEventScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventScores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        contestorAScore
        contestorBScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventScoreById = /* GraphQL */ `
  query EventScoreById(
    $id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventScoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventScoreById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        contestorAScore
        contestorBScore
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSingleModeEventEnd = /* GraphQL */ `
  query GetSingleModeEventEnd($id: ID!) {
    getSingleModeEventEnd(id: $id) {
      id
      eventID
      status
      fileKey
      jobID
      numberOfJobs
      brokerID
      channelArn
      createdAt
      updatedAt2
      updatedAt
    }
  }
`;
export const listSingleModeEventEnds = /* GraphQL */ `
  query ListSingleModeEventEnds(
    $filter: ModelSingleModeEventEndFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSingleModeEventEnds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        status
        fileKey
        jobID
        numberOfJobs
        brokerID
        channelArn
        createdAt
        updatedAt2
        updatedAt
      }
      nextToken
    }
  }
`;
export const singleModeEventEndByStatus = /* GraphQL */ `
  query SingleModeEventEndByStatus(
    $status: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSingleModeEventEndFilterInput
    $limit: Int
    $nextToken: String
  ) {
    singleModeEventEndByStatus(
      status: $status
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        status
        fileKey
        jobID
        numberOfJobs
        brokerID
        channelArn
        createdAt
        updatedAt2
        updatedAt
      }
      nextToken
    }
  }
`;
export const singleModeEventEndByBrokerID = /* GraphQL */ `
  query SingleModeEventEndByBrokerID(
    $brokerID: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSingleModeEventEndFilterInput
    $limit: Int
    $nextToken: String
  ) {
    singleModeEventEndByBrokerID(
      brokerID: $brokerID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        status
        fileKey
        jobID
        numberOfJobs
        brokerID
        channelArn
        createdAt
        updatedAt2
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserPayPerViewList = /* GraphQL */ `
  query GetUserPayPerViewList($id: ID!) {
    getUserPayPerViewList(id: $id) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listUserPayPerViewLists = /* GraphQL */ `
  query ListUserPayPerViewLists(
    $filter: ModelUserPayPerViewListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserPayPerViewLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const payPerViewListByUser = /* GraphQL */ `
  query PayPerViewListByUser(
    $userID: String
    $eventID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserPayPerViewListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payPerViewListByUser(
      userID: $userID
      eventID: $eventID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSponsorRoomPayPerViewList = /* GraphQL */ `
  query GetUserSponsorRoomPayPerViewList($id: ID!) {
    getUserSponsorRoomPayPerViewList(id: $id) {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const listUserSponsorRoomPayPerViewLists = /* GraphQL */ `
  query ListUserSponsorRoomPayPerViewLists(
    $filter: ModelUserSponsorRoomPayPerViewListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSponsorRoomPayPerViewLists(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorRoomPayPerViewID
        userID
        month
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userSponsorRoomPayPerViewListByUserID = /* GraphQL */ `
  query UserSponsorRoomPayPerViewListByUserID(
    $userID: String
    $sponsorRoomPayPerViewID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserSponsorRoomPayPerViewListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userSponsorRoomPayPerViewListByUserID(
      userID: $userID
      sponsorRoomPayPerViewID: $sponsorRoomPayPerViewID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorRoomPayPerViewID
        userID
        month
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTeaserVideo = /* GraphQL */ `
  query GetTeaserVideo($id: ID!) {
    getTeaserVideo(id: $id) {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTeaserVideos = /* GraphQL */ `
  query ListTeaserVideos(
    $filter: ModelTeaserVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeaserVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        isDelayedLive
        vodPlaybackUrl
        livePlaybackUrl
        thumbnailUrl
        vodGuID
        length
        length3
        brokerID
        file {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teaserVideoByBrokerID = /* GraphQL */ `
  query TeaserVideoByBrokerID(
    $brokerID: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeaserVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teaserVideoByBrokerID(
      brokerID: $brokerID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        isDelayedLive
        vodPlaybackUrl
        livePlaybackUrl
        thumbnailUrl
        vodGuID
        length
        length3
        brokerID
        file {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const teaserVideoByEventID = /* GraphQL */ `
  query TeaserVideoByEventID(
    $eventID: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeaserVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    teaserVideoByEventID(
      eventID: $eventID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        isDelayedLive
        vodPlaybackUrl
        livePlaybackUrl
        thumbnailUrl
        vodGuID
        length
        length3
        brokerID
        file {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionAnswer = /* GraphQL */ `
  query GetQuestionAnswer($id: ID!) {
    getQuestionAnswer(id: $id) {
      id
      eventID
      pollID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionAnswers = /* GraphQL */ `
  query ListQuestionAnswers(
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        pollID
        userID
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const answersByPollID = /* GraphQL */ `
  query AnswersByPollID(
    $pollID: String
    $userID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByPollID(
      pollID: $pollID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        pollID
        userID
        answer
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventPoll = /* GraphQL */ `
  query GetEventPoll($id: ID!) {
    getEventPoll(id: $id) {
      id
      eventID
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      status
      createdAt
      updatedAt
    }
  }
`;
export const listEventPolls = /* GraphQL */ `
  query ListEventPolls(
    $filter: ModelEventPollFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventPolls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventID
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventPollsByEventID = /* GraphQL */ `
  query EventPollsByEventID(
    $eventID: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventPollFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventPollsByEventID(
      eventID: $eventID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventID
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventLastUpdate = /* GraphQL */ `
  query GetEventLastUpdate($id: ID!) {
    getEventLastUpdate(id: $id) {
      id
      type
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const listEventLastUpdates = /* GraphQL */ `
  query ListEventLastUpdates(
    $filter: ModelEventLastUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventLastUpdates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        eventID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByDisplayName = /* GraphQL */ `
  query UsersByDisplayName(
    $displayName: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByDisplayName(
      displayName: $displayName
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByRank = /* GraphQL */ `
  query UsersByRank(
    $realm: String
    $rank: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByRank(
      realm: $realm
      rank: $rank
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByIsWild = /* GraphQL */ `
  query UsersByIsWild(
    $isWild: Int
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByIsWild(
      isWild: $isWild
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersByExperience = /* GraphQL */ `
  query UsersByExperience(
    $realm: String
    $experience: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByExperience(
      realm: $realm
      experience: $experience
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersWithFCM = /* GraphQL */ `
  query UsersWithFCM(
    $realm: String
    $fcmID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersWithFCM(
      realm: $realm
      fcmID: $fcmID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const usersActiveNottification = /* GraphQL */ `
  query UsersActiveNottification(
    $notifications: Int
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersActiveNottification(
      notifications: $notifications
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGameAction = /* GraphQL */ `
  query GetGameAction($id: ID!) {
    getGameAction(id: $id) {
      id
      name
      description
      defaultPower
      defaltCooldown
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const listGameActions = /* GraphQL */ `
  query ListGameActions(
    $filter: ModelGameActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGameActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const gameActionsByName = /* GraphQL */ `
  query GameActionsByName(
    $name: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGameActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gameActionsByName(
      name: $name
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAction = /* GraphQL */ `
  query GetAction($id: ID!) {
    getAction(id: $id) {
      id
      userID
      gameActionID
      eventID
      contestor
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        gameActionID
        eventID
        contestor
        createdAt
        updatedAt
        event {
          id
          title
          description
          category
          type
          runWorkflowAutomatically
          videoInputUrl
          streamKey
          workflowID
          workflowType
          videoUrl
          teaserUrl
          videoRecordingDownloadUrl
          teaserThumbnailUrl
          libraryVodGuid
          startTime
          endTime
          startTimestamp
          endTimestamp
          hostSponsorRoomID
          moderatorID
          contestorA
          contestorAColor
          contestorB
          contestorBColor
          contestorAScore
          contestorBScore
          teaserFileBrokerID
          currentRound
          state
          paused
          archived
          deleted
          censorChat
          realm
          highlighted
          createdAt
          updatedAt
          question
          answerA
          answerB
          answerC
          scoreAnswerA
          scoreAnswerB
          scoreAnswerC
          eventCategorisationID
          roundsEnabled
          mixerEventUUID
          mixerEventModeratorUUID
          mixerEventModeratorAccessCode
          mode
          disableChat
          dynamicParticipants
          ppv
          ppvPrice
          duration
          richTextDescription
          googleAdTagUrl
          seoUrl
        }
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserActionHistory = /* GraphQL */ `
  query GetUserActionHistory($id: ID!) {
    getUserActionHistory(id: $id) {
      id
      userID
      stats
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listUserActionHistories = /* GraphQL */ `
  query ListUserActionHistories(
    $filter: ModelUserActionHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserActionHistories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        stats
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const byUserId = /* GraphQL */ `
  query ByUserId(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserActionHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byUserId(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        stats
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getEventCategorisation = /* GraphQL */ `
  query GetEventCategorisation($id: ID!) {
    getEventCategorisation(id: $id) {
      id
      title
      order
      createdAt
      updatedAt
    }
  }
`;
export const listEventCategorisations = /* GraphQL */ `
  query ListEventCategorisations(
    $filter: ModelEventCategorisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventCategorisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const eventCategorisationByTitle = /* GraphQL */ `
  query EventCategorisationByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventCategorisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventCategorisationByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoomCategorisation = /* GraphQL */ `
  query GetRoomCategorisation($id: ID!) {
    getRoomCategorisation(id: $id) {
      id
      title
      order
      logo {
        bucket
        region
        key
        _url
      }
      cover {
        bucket
        region
        key
        _url
      }
      label
      promotionTitle
      promoted
      createdAt
      updatedAt
    }
  }
`;
export const listRoomCategorisations = /* GraphQL */ `
  query ListRoomCategorisations(
    $filter: ModelRoomCategorisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoomCategorisations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roomCategorisationByTitle = /* GraphQL */ `
  query RoomCategorisationByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomCategorisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomCategorisationByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const roomCategorisationPromoted = /* GraphQL */ `
  query RoomCategorisationPromoted(
    $promoted: Int
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomCategorisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    roomCategorisationPromoted(
      promoted: $promoted
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSponsorRoomPayPerView = /* GraphQL */ `
  query GetSponsorRoomPayPerView($id: ID!) {
    getSponsorRoomPayPerView(id: $id) {
      id
      sponsorRoomId
      title
      description
      image {
        bucket
        region
        key
        _url
      }
      price
      monthPrice
      startDate
      endDate
      createdAt
      updatedAt
      userID
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSponsorRoomPayPerViews = /* GraphQL */ `
  query ListSponsorRoomPayPerViews(
    $filter: ModelSponsorRoomPayPerViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorRoomPayPerViews(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const payPerViewBySponsorRoomId = /* GraphQL */ `
  query PayPerViewBySponsorRoomId(
    $sponsorRoomId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomPayPerViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payPerViewBySponsorRoomId(
      sponsorRoomId: $sponsorRoomId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const payPerViewByTitle = /* GraphQL */ `
  query PayPerViewByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomPayPerViewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    payPerViewByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsorRoom = /* GraphQL */ `
  query GetSponsorRoom($id: ID!) {
    getSponsorRoom(id: $id) {
      id
      userID
      title
      description
      merchUrl
      image {
        bucket
        region
        key
        _url
      }
      coverPhoto {
        bucket
        region
        key
        _url
      }
      collaborationLogo {
        bucket
        region
        key
        _url
      }
      order
      isLive
      roomCategorisationID
      ivsChannel {
        channelArn
        ingestEndpoint
        playbackUrl
        name
        streamKeyArn
        streamKey
        recordingConfigurationArn
      }
      ioioChannel {
        ioioChannelID
        playbackUrl
      }
      followers
      featured
      seoUrl
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      roomCategorisation {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
    }
  }
`;
export const listSponsorRooms = /* GraphQL */ `
  query ListSponsorRooms(
    $filter: ModelSponsorRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorRooms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const sponsorRoomByUserID = /* GraphQL */ `
  query SponsorRoomByUserID(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorRoomByUserID(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const sponsorRoomByTitle = /* GraphQL */ `
  query SponsorRoomByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorRoomByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const sponsorRoomByCategorisationID = /* GraphQL */ `
  query SponsorRoomByCategorisationID(
    $roomCategorisationID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorRoomByCategorisationID(
      roomCategorisationID: $roomCategorisationID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const sponsorRoomByFeaturedFlag = /* GraphQL */ `
  query SponsorRoomByFeaturedFlag(
    $featured: Int
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorRoomByFeaturedFlag(
      featured: $featured
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const sponsorRoomBySeo = /* GraphQL */ `
  query SponsorRoomBySeo(
    $seoUrl: String
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorRoomBySeo(
      seoUrl: $seoUrl
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSticker = /* GraphQL */ `
  query GetSticker($id: ID!) {
    getSticker(id: $id) {
      id
      title
      image {
        bucket
        region
        key
        _url
      }
      stickerBundleID
      order
      createdAt
      updatedAt
      stickerBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
    }
  }
`;
export const listStickers = /* GraphQL */ `
  query ListStickers(
    $filter: ModelStickerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStickers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        image {
          bucket
          region
          key
          _url
        }
        stickerBundleID
        order
        createdAt
        updatedAt
        stickerBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const stickersByBundle = /* GraphQL */ `
  query StickersByBundle(
    $stickerBundleID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelStickerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    stickersByBundle(
      stickerBundleID: $stickerBundleID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        image {
          bucket
          region
          key
          _url
        }
        stickerBundleID
        order
        createdAt
        updatedAt
        stickerBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getStickersBundle = /* GraphQL */ `
  query GetStickersBundle($id: ID!) {
    getStickersBundle(id: $id) {
      id
      title
      description
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
      stickers {
        items {
          id
          title
          stickerBundleID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listStickersBundles = /* GraphQL */ `
  query ListStickersBundles(
    $filter: ModelStickersBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStickersBundles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const bundlesByTitle = /* GraphQL */ `
  query BundlesByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelStickersBundleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bundlesByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getBooster = /* GraphQL */ `
  query GetBooster($id: ID!) {
    getBooster(id: $id) {
      id
      type
      title
      description
      factor
      awardUserLevel
      duration
      icon {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBoosters = /* GraphQL */ `
  query ListBoosters(
    $filter: ModelBoosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoosters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const boostersByTitle = /* GraphQL */ `
  query BoostersByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBoosterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    boostersByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      shopItemID
      eventID
      sponsorRoomPayPerViewID
      isMonthPayPerView
      isDonation
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      shopItem {
        id
        image {
          bucket
          region
          key
          _url
        }
        title
        description
        price
        boosterID
        boosterAmount
        gameActionID
        gameActionAmount
        avatarID
        stickersBundleID
        order
        createdAt
        updatedAt
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
        stickersBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
        avatar {
          id
          free
          gender
          order
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        amount
        description
        status
        createdAt
        updatedAt
        shopItemID
        eventID
        sponsorRoomPayPerViewID
        isMonthPayPerView
        isDonation
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoomPayPerView {
          id
          sponsorRoomId
          title
          description
          price
          monthPrice
          startDate
          endDate
          createdAt
          updatedAt
          userID
        }
        shopItem {
          id
          title
          description
          price
          boosterID
          boosterAmount
          gameActionID
          gameActionAmount
          avatarID
          stickersBundleID
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const transactionsByUser = /* GraphQL */ `
  query TransactionsByUser(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    transactionsByUser(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        amount
        description
        status
        createdAt
        updatedAt
        shopItemID
        eventID
        sponsorRoomPayPerViewID
        isMonthPayPerView
        isDonation
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoomPayPerView {
          id
          sponsorRoomId
          title
          description
          price
          monthPrice
          startDate
          endDate
          createdAt
          updatedAt
          userID
        }
        shopItem {
          id
          title
          description
          price
          boosterID
          boosterAmount
          gameActionID
          gameActionAmount
          avatarID
          stickersBundleID
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      time
      provider
      coinsPackageID
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      coinsPackage {
        id
        title
        description
        numberOfCoins
        priceInUSD
        iOsProductID
        androidProductID
        image {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        amount
        description
        status
        createdAt
        updatedAt
        time
        provider
        coinsPackageID
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        coinsPackage {
          id
          title
          description
          numberOfCoins
          priceInUSD
          iOsProductID
          androidProductID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const paymentsByUser = /* GraphQL */ `
  query PaymentsByUser(
    $userID: ID
    $time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    paymentsByUser(
      userID: $userID
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        amount
        description
        status
        createdAt
        updatedAt
        time
        provider
        coinsPackageID
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        coinsPackage {
          id
          title
          description
          numberOfCoins
          priceInUSD
          iOsProductID
          androidProductID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getFollowing = /* GraphQL */ `
  query GetFollowing($id: ID!) {
    getFollowing(id: $id) {
      id
      userID
      sponsorRoomID
      followerDisplayName
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listFollowings = /* GraphQL */ `
  query ListFollowings(
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        sponsorRoomID
        followerDisplayName
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listFollowingsByUserID = /* GraphQL */ `
  query ListFollowingsByUserID(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowingsByUserID(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sponsorRoomID
        followerDisplayName
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const listFollowingsBySponsorRoomID = /* GraphQL */ `
  query ListFollowingsBySponsorRoomID(
    $sponsorRoomID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowingsBySponsorRoomID(
      sponsorRoomID: $sponsorRoomID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sponsorRoomID
        followerDisplayName
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getShopItem = /* GraphQL */ `
  query GetShopItem($id: ID!) {
    getShopItem(id: $id) {
      id
      image {
        bucket
        region
        key
        _url
      }
      title
      description
      price
      boosterID
      boosterAmount
      gameActionID
      gameActionAmount
      avatarID
      stickersBundleID
      order
      createdAt
      updatedAt
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      stickersBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      avatar {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const listShopItems = /* GraphQL */ `
  query ListShopItems(
    $filter: ModelShopItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShopItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image {
          bucket
          region
          key
          _url
        }
        title
        description
        price
        boosterID
        boosterAmount
        gameActionID
        gameActionAmount
        avatarID
        stickersBundleID
        order
        createdAt
        updatedAt
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
        stickersBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
        avatar {
          id
          free
          gender
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const shopItemByTitle = /* GraphQL */ `
  query ShopItemByTitle(
    $title: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelShopItemFilterInput
    $limit: Int
    $nextToken: String
  ) {
    shopItemByTitle(
      title: $title
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        image {
          bucket
          region
          key
          _url
        }
        title
        description
        price
        boosterID
        boosterAmount
        gameActionID
        gameActionAmount
        avatarID
        stickersBundleID
        order
        createdAt
        updatedAt
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
        stickersBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
        avatar {
          id
          free
          gender
          order
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAchievement = /* GraphQL */ `
  query GetAchievement($id: ID!) {
    getAchievement(id: $id) {
      id
      badge {
        bucket
        region
        key
        _url
      }
      title
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const listAchievements = /* GraphQL */ `
  query ListAchievements(
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAchievements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        badge {
          bucket
          region
          key
          _url
        }
        title
        type
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const achievementByType = /* GraphQL */ `
  query AchievementByType(
    $type: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAchievementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    achievementByType(
      type: $type
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        badge {
          bucket
          region
          key
          _url
        }
        title
        type
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatMessage = /* GraphQL */ `
  query GetChatMessage($id: ID!) {
    getChatMessage(id: $id) {
      id
      userID
      eventID
      content
      censoredContent
      stickerID
      displayName
      avatar
      level
      time
      createdAt
      updatedAt
    }
  }
`;
export const listChatMessages = /* GraphQL */ `
  query ListChatMessages(
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        eventID
        content
        censoredContent
        stickerID
        displayName
        avatar
        level
        time
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const chatMessagesByEventID = /* GraphQL */ `
  query ChatMessagesByEventID(
    $eventID: String
    $time: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelChatMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    chatMessagesByEventID(
      eventID: $eventID
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        eventID
        content
        censoredContent
        stickerID
        displayName
        avatar
        level
        time
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventLeaderBoard = /* GraphQL */ `
  query GetEventLeaderBoard($id: ID!) {
    getEventLeaderBoard(id: $id) {
      id
      board
      createdAt
      updatedAt
    }
  }
`;
export const listEventLeaderBoards = /* GraphQL */ `
  query ListEventLeaderBoards(
    $filter: ModelEventLeaderBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventLeaderBoards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        board
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCoinsPackage = /* GraphQL */ `
  query GetCoinsPackage($id: ID!) {
    getCoinsPackage(id: $id) {
      id
      title
      description
      numberOfCoins
      priceInUSD
      iOsProductID
      androidProductID
      image {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCoinsPackages = /* GraphQL */ `
  query ListCoinsPackages(
    $filter: ModelCoinsPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoinsPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        numberOfCoins
        priceInUSD
        iOsProductID
        androidProductID
        image {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAvatar = /* GraphQL */ `
  query GetAvatar($id: ID!) {
    getAvatar(id: $id) {
      id
      image {
        bucket
        region
        key
        _url
      }
      free
      gender
      order
      createdAt
      updatedAt
    }
  }
`;
export const listAvatars = /* GraphQL */ `
  query ListAvatars(
    $filter: ModelAvatarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAvatars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const orderedAvatars = /* GraphQL */ `
  query OrderedAvatars(
    $order: Int
    $sortDirection: ModelSortDirection
    $filter: ModelAvatarFilterInput
    $limit: Int
    $nextToken: String
  ) {
    orderedAvatars(
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getChatReport = /* GraphQL */ `
  query GetChatReport($id: ID!) {
    getChatReport(id: $id) {
      id
      reportingUserID
      reportedUserID
      message
      description
      createdAt
      updatedAt
      reportingUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      reportedUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listChatReports = /* GraphQL */ `
  query ListChatReports(
    $filter: ModelChatReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        reportingUserID
        reportedUserID
        message
        description
        createdAt
        updatedAt
        reportingUser {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        reportedUser {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getLegalInformation = /* GraphQL */ `
  query GetLegalInformation($id: ID!) {
    getLegalInformation(id: $id) {
      id
      termsOfService
      privacyPolicy
      createdAt
      updatedAt
    }
  }
`;
export const listLegalInformations = /* GraphQL */ `
  query ListLegalInformations(
    $filter: ModelLegalInformationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLegalInformations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        termsOfService
        privacyPolicy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomNotification = /* GraphQL */ `
  query GetCustomNotification($id: ID!) {
    getCustomNotification(id: $id) {
      id
      message
      createdAt
      updatedAt
    }
  }
`;
export const listCustomNotifications = /* GraphQL */ `
  query ListCustomNotifications(
    $filter: ModelCustomNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      creatorID
      userID
      status
      eventID
      mixerEventUUID
      mixerAtendeeID
      mixerAccessCode
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        creatorID
        userID
        status
        eventID
        mixerEventUUID
        mixerAtendeeID
        mixerAccessCode
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invitationByUserID = /* GraphQL */ `
  query InvitationByUserID(
    $userID: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationByUserID(
      userID: $userID
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creatorID
        userID
        status
        eventID
        mixerEventUUID
        mixerAtendeeID
        mixerAccessCode
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invitationByEventID = /* GraphQL */ `
  query InvitationByEventID(
    $eventID: String
    $timestamp: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationByEventID(
      eventID: $eventID
      timestamp: $timestamp
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creatorID
        userID
        status
        eventID
        mixerEventUUID
        mixerAtendeeID
        mixerAccessCode
        timestamp
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventOnlineUser = /* GraphQL */ `
  query GetEventOnlineUser($id: ID!) {
    getEventOnlineUser(id: $id) {
      id
      userID
      displayName
      displayNameLowerCase
      eventID
      createdAt
      updatedAt
      raisedHand
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listEventOnlineUsers = /* GraphQL */ `
  query ListEventOnlineUsers(
    $filter: ModelEventOnlineUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventOnlineUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        displayName
        displayNameLowerCase
        eventID
        createdAt
        updatedAt
        raisedHand
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const onlineUsersByEventAndDisplayName = /* GraphQL */ `
  query OnlineUsersByEventAndDisplayName(
    $eventID: String
    $displayName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventOnlineUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onlineUsersByEventAndDisplayName(
      eventID: $eventID
      displayName: $displayName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        displayName
        displayNameLowerCase
        eventID
        createdAt
        updatedAt
        raisedHand
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const onlineUsersByEventAndDisplayNameLowerCase = /* GraphQL */ `
  query OnlineUsersByEventAndDisplayNameLowerCase(
    $eventID: String
    $displayNameLowerCase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventOnlineUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onlineUsersByEventAndDisplayNameLowerCase(
      eventID: $eventID
      displayNameLowerCase: $displayNameLowerCase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        displayName
        displayNameLowerCase
        eventID
        createdAt
        updatedAt
        raisedHand
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getAppVersion = /* GraphQL */ `
  query GetAppVersion($id: ID!) {
    getAppVersion(id: $id) {
      id
      minVersion
      currentVersion
      createdAt
      updatedAt
    }
  }
`;
export const listAppVersions = /* GraphQL */ `
  query ListAppVersions(
    $filter: ModelAppVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        minVersion
        currentVersion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCreatorPayoutInfo = /* GraphQL */ `
  query GetCreatorPayoutInfo($id: ID!) {
    getCreatorPayoutInfo(id: $id) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCreatorPayoutInfos = /* GraphQL */ `
  query ListCreatorPayoutInfos(
    $filter: ModelCreatorPayoutInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreatorPayoutInfos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        abaNumber
        accountNumber
        accountType
        accountName
        bankInfo
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const creatorPayoutInfoByUserId = /* GraphQL */ `
  query CreatorPayoutInfoByUserId(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreatorPayoutInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creatorPayoutInfoByUserId(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        abaNumber
        accountNumber
        accountType
        accountName
        bankInfo
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getSponsorRoomPayPerViewProfit = /* GraphQL */ `
  query GetSponsorRoomPayPerViewProfit($id: ID!) {
    getSponsorRoomPayPerViewProfit(id: $id) {
      id
      userID
      totalWholePurchases
      totalMonthPurchases
      payPerViewID
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listSponsorRoomPayPerViewProfits = /* GraphQL */ `
  query ListSponsorRoomPayPerViewProfits(
    $filter: ModelSponsorRoomPayPerViewProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSponsorRoomPayPerViewProfits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        totalWholePurchases
        totalMonthPurchases
        payPerViewID
        totalCoinsFromPPV
        totalCoins
        totalUsd
        revenuePercent
        totalCreatorProfitUsd
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoomPayPerView {
          id
          sponsorRoomId
          title
          description
          price
          monthPrice
          startDate
          endDate
          createdAt
          updatedAt
          userID
        }
      }
      nextToken
    }
  }
`;
export const sponsorRoomPayPerViewProfitByUserId = /* GraphQL */ `
  query SponsorRoomPayPerViewProfitByUserId(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomPayPerViewProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorRoomPayPerViewProfitByUserId(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        totalWholePurchases
        totalMonthPurchases
        payPerViewID
        totalCoinsFromPPV
        totalCoins
        totalUsd
        revenuePercent
        totalCreatorProfitUsd
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoomPayPerView {
          id
          sponsorRoomId
          title
          description
          price
          monthPrice
          startDate
          endDate
          createdAt
          updatedAt
          userID
        }
      }
      nextToken
    }
  }
`;
export const sponsorRoomPayPerViewProfitByPayPerViewId = /* GraphQL */ `
  query SponsorRoomPayPerViewProfitByPayPerViewId(
    $payPerViewID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSponsorRoomPayPerViewProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sponsorRoomPayPerViewProfitByPayPerViewId(
      payPerViewID: $payPerViewID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        totalWholePurchases
        totalMonthPurchases
        payPerViewID
        totalCoinsFromPPV
        totalCoins
        totalUsd
        revenuePercent
        totalCreatorProfitUsd
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        sponsorRoomPayPerView {
          id
          sponsorRoomId
          title
          description
          price
          monthPrice
          startDate
          endDate
          createdAt
          updatedAt
          userID
        }
      }
      nextToken
    }
  }
`;
export const getEventProfit = /* GraphQL */ `
  query GetEventProfit($id: ID!) {
    getEventProfit(id: $id) {
      id
      userID
      totalPurchases
      eventID
      totalCoinsFromDonations
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listEventProfits = /* GraphQL */ `
  query ListEventProfits(
    $filter: ModelEventProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventProfits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        totalPurchases
        eventID
        totalCoinsFromDonations
        totalCoinsFromPPV
        totalCoins
        totalUsd
        revenuePercent
        totalCreatorProfitUsd
        createdAt
        updatedAt
        event {
          id
          title
          description
          category
          type
          runWorkflowAutomatically
          videoInputUrl
          streamKey
          workflowID
          workflowType
          videoUrl
          teaserUrl
          videoRecordingDownloadUrl
          teaserThumbnailUrl
          libraryVodGuid
          startTime
          endTime
          startTimestamp
          endTimestamp
          hostSponsorRoomID
          moderatorID
          contestorA
          contestorAColor
          contestorB
          contestorBColor
          contestorAScore
          contestorBScore
          teaserFileBrokerID
          currentRound
          state
          paused
          archived
          deleted
          censorChat
          realm
          highlighted
          createdAt
          updatedAt
          question
          answerA
          answerB
          answerC
          scoreAnswerA
          scoreAnswerB
          scoreAnswerC
          eventCategorisationID
          roundsEnabled
          mixerEventUUID
          mixerEventModeratorUUID
          mixerEventModeratorAccessCode
          mode
          disableChat
          dynamicParticipants
          ppv
          ppvPrice
          duration
          richTextDescription
          googleAdTagUrl
          seoUrl
        }
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventProfitsByUserId = /* GraphQL */ `
  query EventProfitsByUserId(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventProfitsByUserId(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        totalPurchases
        eventID
        totalCoinsFromDonations
        totalCoinsFromPPV
        totalCoins
        totalUsd
        revenuePercent
        totalCreatorProfitUsd
        createdAt
        updatedAt
        event {
          id
          title
          description
          category
          type
          runWorkflowAutomatically
          videoInputUrl
          streamKey
          workflowID
          workflowType
          videoUrl
          teaserUrl
          videoRecordingDownloadUrl
          teaserThumbnailUrl
          libraryVodGuid
          startTime
          endTime
          startTimestamp
          endTimestamp
          hostSponsorRoomID
          moderatorID
          contestorA
          contestorAColor
          contestorB
          contestorBColor
          contestorAScore
          contestorBScore
          teaserFileBrokerID
          currentRound
          state
          paused
          archived
          deleted
          censorChat
          realm
          highlighted
          createdAt
          updatedAt
          question
          answerA
          answerB
          answerC
          scoreAnswerA
          scoreAnswerB
          scoreAnswerC
          eventCategorisationID
          roundsEnabled
          mixerEventUUID
          mixerEventModeratorUUID
          mixerEventModeratorAccessCode
          mode
          disableChat
          dynamicParticipants
          ppv
          ppvPrice
          duration
          richTextDescription
          googleAdTagUrl
          seoUrl
        }
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const eventProfitByEventId = /* GraphQL */ `
  query EventProfitByEventId(
    $eventID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventProfitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventProfitByEventId(
      eventID: $eventID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        totalPurchases
        eventID
        totalCoinsFromDonations
        totalCoinsFromPPV
        totalCoins
        totalUsd
        revenuePercent
        totalCreatorProfitUsd
        createdAt
        updatedAt
        event {
          id
          title
          description
          category
          type
          runWorkflowAutomatically
          videoInputUrl
          streamKey
          workflowID
          workflowType
          videoUrl
          teaserUrl
          videoRecordingDownloadUrl
          teaserThumbnailUrl
          libraryVodGuid
          startTime
          endTime
          startTimestamp
          endTimestamp
          hostSponsorRoomID
          moderatorID
          contestorA
          contestorAColor
          contestorB
          contestorBColor
          contestorAScore
          contestorBScore
          teaserFileBrokerID
          currentRound
          state
          paused
          archived
          deleted
          censorChat
          realm
          highlighted
          createdAt
          updatedAt
          question
          answerA
          answerB
          answerC
          scoreAnswerA
          scoreAnswerB
          scoreAnswerC
          eventCategorisationID
          roundsEnabled
          mixerEventUUID
          mixerEventModeratorUUID
          mixerEventModeratorAccessCode
          mode
          disableChat
          dynamicParticipants
          ppv
          ppvPrice
          duration
          richTextDescription
          googleAdTagUrl
          seoUrl
        }
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getWithdrawRequest = /* GraphQL */ `
  query GetWithdrawRequest($id: ID!) {
    getWithdrawRequest(id: $id) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      amount
      coins
      status
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listWithdrawRequests = /* GraphQL */ `
  query ListWithdrawRequests(
    $filter: ModelWithdrawRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWithdrawRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        abaNumber
        accountNumber
        accountType
        accountName
        bankInfo
        amount
        coins
        status
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const withdrawRequestsByStatus = /* GraphQL */ `
  query WithdrawRequestsByStatus(
    $status: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWithdrawRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    withdrawRequestsByStatus(
      status: $status
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        abaNumber
        accountNumber
        accountType
        accountName
        bankInfo
        amount
        coins
        status
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const withdrawRequestsByuserID = /* GraphQL */ `
  query WithdrawRequestsByuserID(
    $userID: ID
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWithdrawRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    withdrawRequestsByuserID(
      userID: $userID
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        abaNumber
        accountNumber
        accountType
        accountName
        bankInfo
        amount
        coins
        status
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getCreatorRequest = /* GraphQL */ `
  query GetCreatorRequest($id: ID!) {
    getCreatorRequest(id: $id) {
      id
      userID
      email
      name
      displayName
      socialLink1
      socialLink2
      socialLink3
      socialLink4
      socialLink5
      socialLink6
      motivation
      heardFromAdventuresMusicTour
      adventuresMusicTourCity
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCreatorRequests = /* GraphQL */ `
  query ListCreatorRequests(
    $filter: ModelCreatorRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCreatorRequests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        email
        name
        displayName
        socialLink1
        socialLink2
        socialLink3
        socialLink4
        socialLink5
        socialLink6
        motivation
        heardFromAdventuresMusicTour
        adventuresMusicTourCity
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const creatorRequestByEMail = /* GraphQL */ `
  query CreatorRequestByEMail(
    $email: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreatorRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creatorRequestByEMail(
      email: $email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        email
        name
        displayName
        socialLink1
        socialLink2
        socialLink3
        socialLink4
        socialLink5
        socialLink6
        motivation
        heardFromAdventuresMusicTour
        adventuresMusicTourCity
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const creatorRequestByUserID = /* GraphQL */ `
  query CreatorRequestByUserID(
    $userID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCreatorRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    creatorRequestByUserID(
      userID: $userID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        email
        name
        displayName
        socialLink1
        socialLink2
        socialLink3
        socialLink4
        socialLink5
        socialLink6
        motivation
        heardFromAdventuresMusicTour
        adventuresMusicTourCity
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getMultipartUploadLibraryQueue = /* GraphQL */ `
  query GetMultipartUploadLibraryQueue($id: ID!) {
    getMultipartUploadLibraryQueue(id: $id) {
      id
      uploadID
      status
      bucket
      copySource
      key
      lastPartNumber
      numRequests
      uploadedParts {
        ETag
        PartNumber
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMultipartUploadLibraryQueues = /* GraphQL */ `
  query ListMultipartUploadLibraryQueues(
    $filter: ModelMultipartUploadLibraryQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMultipartUploadLibraryQueues(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        status
        bucket
        copySource
        key
        lastPartNumber
        numRequests
        uploadedParts {
          ETag
          PartNumber
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queueByUploadID = /* GraphQL */ `
  query QueueByUploadID(
    $uploadID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMultipartUploadLibraryQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queueByUploadID(
      uploadID: $uploadID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uploadID
        status
        bucket
        copySource
        key
        lastPartNumber
        numRequests
        uploadedParts {
          ETag
          PartNumber
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
