/* eslint-disable prettier/prettier */
import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { Event, Subscription } from 'domain/types';

import { selectUpToDateSubscriptions } from 'store/subscriptions/selectors';

import {
  formatOrdinalDate,
  getEndOfCurrentMonthDate,
} from 'helpers';

import Heading from 'components/typography/Heading';
import Icon from 'components/typography/Icon';

import PPVOption from './components/PPVOption';

import './styles.css';

type onPurchaseInput = { id: string, type: 'season' | 'month' | 'event' };
type Props = {
  event: Event;
  onClose: () => unknown;
  onPurchase: (input: onPurchaseInput) => unknown;
};

const BuyRoomPPVModal: FC<Props> = ({
  event,
  onClose,
  onPurchase,
}) => {
  const subscriptions = useSelector(selectUpToDateSubscriptions(event.startTime));

  const renderSubscriptionOption = (s: Subscription) => (
    <React.Fragment key={s.id}>
      { s.price
        ? <PPVOption
          image={s.image.key}
          subTitle='Season Pass'
          description={`until ${formatOrdinalDate(new Date(s.endDate))}`}
          price={s.price}
          onClick={() => {
            onPurchase({ id: s.id, type: 'season' });
            onClose();
          }}
        />
        : null
      }
      { s.monthPrice
        ? <PPVOption
          image={s.image.key}
          subTitle='Month Pass'
          description={`until ${formatOrdinalDate(getEndOfCurrentMonthDate())}`}
          price={s.monthPrice}
          onClick={() => {
            onPurchase({ id: s.id, type: 'month' });
            onClose();
          }}
        />
        : null
      }
    </React.Fragment>
  );

  return (
    <div className="ppv-modal modal-overlay">
      <div className="modal-dialog flex flex-col font-display rounded-lg pt-8">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 text-primaryC"
        >
          <Icon size={13} name="error" />
        </button>

        <Heading headingSize={6} classes="font-medium text-2xl">
          Get your pass
        </Heading>

        <p className="text-sm font-normal mt-2 mb-6">
          Activate {event.hostSponsorRoom.title} season pass to access all events, or purchase a single event pass.
        </p>

        <div className="flex flex-col gap-y-2">
          {subscriptions.map(renderSubscriptionOption)}
          { event.ppv && event.ppvPrice
            ? <PPVOption
              key={event.id}
              image={event.image.key}
              subTitle='Get this event'
              description='One time purchase'
              price={event.ppvPrice}
              onClick={() => {
                onPurchase({ id: event.id, type: 'event' });
                onClose();
              }}
            />
            : null
          }
        </div>
      </div>
    </div>
  );
};

export default BuyRoomPPVModal;
