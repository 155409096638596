import React from 'react';
import Table from '../table/Table';
import TableHead from '../table/TableHead';
import TableBody from '../table/TableBody';
import TableRow from '../table/TableRow';
import TableCell from '../table/TableCell';
import TableRowSkeleton from '../../skeletons/TableRowSkeleton';

const tableHeadingStyles =
  'w-1/3 pt-2 pb-7 font-display font-medium text-sm text-left leading-5 tracking-tight';

const RecentTransactions = ({ loading, transactions, actions, boosters }) => {
  const renderRows = () => {
    if (loading) {
      return [<TableRowSkeleton key={1} />, <TableRowSkeleton key={2} />];
    }

    if (transactions.length === 0) {
      return null;
    }

    const formatDate = (dateStr) => {
      if (dateStr) {
        const date = new Date(dateStr);
        const day = date.getUTCDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        const time = date.toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });

        return `${day}/${month}/${year} ${time}`;
      }

      return '';
    };

    return transactions.map((t) => {
      return (
        <TableRow classes={`border-b border-secondaryB`} key={t.id}>
          <TableCell
            variant='body'
            classes='text-primaryC text-sm font-display font-medium leading-5 py-2 tracking-tight'
          >
            {t.description}
          </TableCell>
          <TableCell
            variant='body'
            classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight'
          >
            {formatDate(t?.createdAt)}
          </TableCell>
          <TableCell variant='body' classes='font-numbers font-bold text-base'>
            {t?.shopItem?.price}
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <Table>
      <TableHead>
        <TableRow classes='border-b border-secondaryB'>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Item
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Date
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Coins
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{renderRows()}</TableBody>
    </Table>
  );
};

export default RecentTransactions;
