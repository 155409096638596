/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import VideoUpload from '../form/VideoUpload';
import Icon from '../typography/Icon';
import Modal from '../system/Modal';
import Button from '../common/Button';
import Paragraph from '../typography/Paragraph';

import { AlertsContext } from '../../context/alerts';
import { EventsContext } from '../../context/events';
import useEventVideos from '../../hooks/useEventVideos';

import themeColors from '../../theme-colors';
import history from '../../history';
import routes from '../../routes';
import { allowedVideoTypes } from '../../helpers';
import useVideoProcessing from '../../hooks/useVideoProcessing';

const MAX_VIDEO_SIZE = 1024 * 1000 * 1000 * 5;

const DelayedLiveTab = ({
  eventId,
  inactive,
  eventState,
  roomId,
  eventStartTimestamp,
}) => {
  const [uploadProgress, setUploadProgress] = useState(0);
  const [state, setState] = useState('idle');
  const [loading, setLoading] = useState(false);
  const [uploadedVideo, setUploadedVideo] = useState(null);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showTimeSlotDialog, setShowTimeSlotDialog] = useState(false);
  const [fileError, setFileError] = useState(false);

  const alertsContext = useContext(AlertsContext);
  const eventsContext = useContext(EventsContext);

  const { addAlert } = alertsContext;
  const { isTimeIntervalAvailable, updateEvent } = eventsContext;

  const {
    getLatestDelayedLiveVideoByEventId,
    removeVideo,
    uploadVideo,
    createVideoUpdateSubscription,
  } = useEventVideos();

  const { handleProcessing, processing, clearProcessingInterval } =
    useVideoProcessing();
  const getVideo = async () => {
    setLoading(true);
    const { video } = await getLatestDelayedLiveVideoByEventId(eventId);
    setUploadedVideo(video);
    setLoading(false);

    if (!video) {
      return;
    }

    if (!video.vodPlaybackUrl) {
      setState('processing');

      const videoLength = parseInt(video.length);
      if (videoLength) {
        handleProcessing(video.file, videoLength);
      }

      const subscription = await createVideoUpdateSubscription(
        video.id,
        (updatedVideo) => {
          if (updatedVideo.vodPlaybackUrl) {
            setState('processed');
            setUploadedVideo(updatedVideo);

            clearProcessingInterval();

            subscription.unsubscribe();
          }
        }
      );
      return;
    }

    setState('processed');

    clearProcessingInterval();
  };

  useEffect(() => {
    getVideo();
  }, []);

  const handleCloseTimeSlotDialog = async () => {
    updateEvent({
      id: eventId,
      state: 'inactive',
      startTimestamp: -1000,
      startTime: null,
    });

    history.push(routes.editMyEventWithIDEventTab.replace(':eventId', eventId));
  };

  const handleCloseFileErrorDialog = () => {
    setFileError(null);
  };

  const handleUpload = async (file) => {
    setState('uploading');
    let durationInMilliseconds = 0;
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    videoElement.onloadedmetadata = async () => {
      window.URL.revokeObjectURL(videoElement.src);
      const duration = videoElement.duration;
      durationInMilliseconds = Math.ceil(duration) * 1000;

      const timeSlotIsAvailable = await isTimeIntervalAvailable(
        eventId,
        roomId,
        eventStartTimestamp,
        duration
      );

      if (!timeSlotIsAvailable) {
        setShowTimeSlotDialog(true);
        return;
      }

      const { success, video, error } = await uploadVideo(
        eventId,
        file,
        durationInMilliseconds.toString(),
        (progress) =>
          setUploadProgress(
            ((progress.loaded / progress.total) * 100).toFixed()
          )
      );

      if (!success) {
        addAlert('danger', error);
        setState('idle');
        return;
      }

      setState('processing');
      handleProcessing(video.file, durationInMilliseconds);

      const subscription = await createVideoUpdateSubscription(
        video.id,
        (updatedVideo) => {
          if (updatedVideo.vodPlaybackUrl) {
            setState('processed');
            setUploadedVideo(updatedVideo);

            clearProcessingInterval();

            subscription.unsubscribe();
          }
        }
      );

      videoElement.remove();
    };

    if (videoElement) {
      videoElement.src = URL.createObjectURL(file);
    }
  };

  const onFileSelect = (file) => {
    if (inactive) {
      return;
    }

    if (file.size >= MAX_VIDEO_SIZE) {
      setFileError({
        type: 'filesize',
        filesize: file.size,
        filetype: file.type,
      });
      return;
    }

    if (!allowedVideoTypes[file.type]) {
      setFileError({
        type: 'filetype',
        filesize: file.size,
        filetype: file.type,
      });
      return;
    }

    handleUpload(file);
  };

  const deleteVideo = async () => {
    setShowDeleteDialog(false);
    const { success } = await removeVideo(uploadedVideo.id);

    if (!success) {
      addAlert('danger', 'Deleting video failed');
      return;
    }

    setUploadedVideo(null);
    setState('idle');
    addAlert('success', 'Video removed successfully');
  };

  if (loading) {
    return (
      <div className='flex items-center justify-center flex-col mr-4'>
        <label
          htmlFor='delayedLiveUpload'
          className={`select-image relative w-full`}
        >
          <div className={`empty-placeholder video fullwidth`}>
            <div className='absolute inset-0 flex items-center justify-center flex-col'>
              <Icon name='video' color={themeColors.secondaryA} size={48} />
              <div className='text-base font-display text-secondaryA'>
                Looking for your video
              </div>
            </div>
          </div>
        </label>
      </div>
    );
  }

  if (uploadedVideo && state === 'processed') {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          paddingTop: '56.25%',
          height: 'auto',
        }}
      >
        <div className='absolute inset-0'>
          <ReactPlayer
            id='video-placeholder'
            playing={false}
            controls={true}
            muted={true}
            url={uploadedVideo.vodPlaybackUrl}
            width='100%'
            height='100%'
            config={{
              file: {
                attributes: { disablePictureInPicture: true },
                forceHLS: true,
                hlsOptions: { capLevelToPlayerSize: true },
              },
            }}
          />
        </div>
        <div
          className='absolute w-10 h-10 flex items-center justify-center top-2 right-2 cursor-pointer'
          onClick={() => setShowDeleteDialog(true)}
        >
          <Icon name='error' size={20} color={themeColors.primaryD} />
        </div>
        <Modal
          title='Warning!'
          actions={[
            <Button
              key='disapprove-delete'
              buttonType='secondary'
              buttonSize='medium'
              buttonOnClick={() => setShowDeleteDialog(false)}
              classes='mr-4'
            >
              No
            </Button>,
            <Button
              key='approve-delete'
              buttonType='primary'
              buttonSize='medium'
              buttonOnClick={deleteVideo}
            >
              Yes
            </Button>,
          ]}
          isVisible={showDeleteDialog}
          handleClose={() => setShowDeleteDialog(false)}
        >
          <Paragraph>
            Are you sure you want to delete the video for this event?
          </Paragraph>
        </Modal>
      </div>
    );
  }

  return (
    <>
      <VideoUpload
        onFileSelect={onFileSelect}
        disabled={inactive}
        state={state}
        uploadProgress={uploadProgress}
        processingProgress={processing}
        containerClassName='mr-4'
      />
      <Modal
        title='Warning!'
        actions={[
          <Button
            key='close-time-slot-dialog'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={handleCloseFileErrorDialog}
            classes='mr-4'
          >
            Ok
          </Button>,
        ]}
        isVisible={!!fileError}
        handleClose={handleCloseFileErrorDialog}
      >
        <Paragraph>
          {fileError?.type === 'filesize'
            ? 'This file is too big!'
            : 'File type is not supported'}
        </Paragraph>
        <Paragraph>
          {fileError?.type === 'filesize'
            ? 'File exceeds the maximum upload filesize (5GB). Please select a smaller file'
            : 'File type must be mp4, avi or mpeg'}
        </Paragraph>
      </Modal>
      <Modal
        title='Warning!'
        actions={[
          <Button
            key='close-time-slot-dialog'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={handleCloseTimeSlotDialog}
            classes='mr-4'
          >
            Ok
          </Button>,
        ]}
        isVisible={showTimeSlotDialog}
        handleClose={handleCloseTimeSlotDialog}
      >
        <Paragraph>
          You already have one scheduled event for this time slot.
        </Paragraph>
        <Paragraph>
          Please change the start time of the event and try again!
        </Paragraph>
      </Modal>
    </>
  );
};

export default DelayedLiveTab;
