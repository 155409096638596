import React, { Component } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { AlertsContext } from './alerts';
import SystemBanner from '../components/system/SystemBanner';

export default class AlertsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      addAlert: this.addAlert,
      removeAlert: this.removeAlert,
    };
  }

  addAlert = (type, message) => {
    this.setState({
      alerts: [
        { type, message, id: Date.now().toString() },
        ...this.state.alerts,
      ],
    });
  };

  removeAlert = (id) => {
    this.setState({
      alerts: this.state.alerts.filter((a) => a.id !== id),
    });
  };

  render() {
    return (
      <AlertsContext.Provider value={this.state}>
        <div className='fixed inset-x-0 top-0 z-50'>
          <TransitionGroup>
            {this.state.alerts.map(({ id, type, message }) => (
              <CSSTransition key={id} timeout={500} classNames='item'>
                <SystemBanner
                  id={id}
                  type={type}
                  message={message}
                  handleDismiss={this.removeAlert}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
        </div>
        {/* <div className='fixed top-15 flex z-50'>
          <button onClick={() => this.addAlert('primary', 'Test alert')}>
            Primary
          </button>
          <button onClick={() => this.addAlert('warning', 'Test alert')}>
            Warning
          </button>
          <button onClick={() => this.addAlert('danger', 'Test alert')}>
            Danger
          </button>
          <button onClick={() => this.addAlert('success', 'Test alert')}>
            Success
          </button>
        </div> */}
        {this.props.children}
      </AlertsContext.Provider>
    );
  }
}
