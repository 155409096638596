import React from 'react';
import Heading from '../typography/Heading';
import Avatar from '../common/Avatar';

const UserMainInfo = ({ user, imageUrl }) => {
  const userData = user?.dbData?.data?.getUser || null;

  if (!userData) {
    return null;
  }

  return (
    <div className='flex flex-wrap justify-center items-center mb-6 xl:flex-nowrap xl:justify-start'>
      <div className='w-18 mb-4 mr-4 xl:mb-0'>
        <Avatar
          imgSrc={imageUrl}
          imgAlt={`${userData.displayName} avatar`}
          imgSize={4}
        />
      </div>
      <div className='w-full flex flex-1 flex-wrap xl:w-24 xl:text-left'>
        <Heading
          headingSize={5}
          classes='mb-2 font-bold overflow-ellipsis overflow-hidden w-full'
        >
          {userData.displayName}
        </Heading>
        <p className='mb-2 font-display font-bold text-secondaryB text-sm leading-4 tracking-tight break-all'>
          {userData.location}
        </p>
      </div>
    </div>
  );
};

export default UserMainInfo;
