/* eslint-disable prettier/prettier */
import React from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import GridContainer from '../layout/GridContainer';
import RoomsSidebar from '../RoomsSidebar';
import routes from '../../routes';

const ContainerWithRoomSidebar = ({ children }) => {
  const routeMatch = useRouteMatch();
  const location = useLocation();

  let largeColSpan = 'col-span-10 ';
  let defaultColSpan = 'col-span-8';

  const isMYRoomRoute = location.pathname.includes(routes.myRoom);
  const isMyEventsRoute = location.pathname === routes.myEvents;
  const isNewVideoRoute = location.pathname === routes.newVideo;
  const isSubsciptionPassesRoute = location.pathname.includes(routes.subscriptionPass.list);

  if (
    isMYRoomRoute ||
    isMyEventsRoute ||
    isNewVideoRoute ||
    isSubsciptionPassesRoute
  ) {
    largeColSpan = 'col-span-8';
  }

  if (
    location.pathname === routes.newEvent ||
    routeMatch.path === routes.editMyEventWithID ||
    location.pathname === routes.withdraw ||
    location.pathname === routes.earnings ||
    location.pathname === routes.roomDashboard ||
    location.pathname === routes.videoList ||
    routeMatch.path === routes.editMyEventWithIDStatisticsTab
  ) {
    defaultColSpan = 'col-span-10';
  }

  return (
    <GridContainer>
      <RoomsSidebar />
      <div className={`relative ${defaultColSpan} 2xl:${largeColSpan}`}>
        {children}
      </div>
    </GridContainer>
  );
};

export default ContainerWithRoomSidebar;
