import React, { useState } from 'react';

import Modal from '../system/Modal';
import DeviceSelectors from './DeviceSelectors';
import Button from '../common/Button';

import useDeviceSelection from '../../hooks/useDeviceSelection';

const DevicesDialog = ({ isVisible, onAccept, onClose }) => {
  const [error, setError] = useState(false);
  const { selectAndChooseDevices } = useDeviceSelection();

  const handleAcceptButton = () => {
    selectAndChooseDevices();
    onAccept();
  };

  return (
    <Modal
      title='Select and test devices'
      actions={[
        <Button
          key='close-devices'
          buttonType='secondary'
          buttonSize='medium'
          buttonOnClick={error ? onClose : handleAcceptButton}
          classes='mr-4'
        >
          OK
        </Button>,
      ]}
      isVisible={isVisible}
      handleClose={onClose}
    >
      <DeviceSelectors error={error} onError={setError} />
    </Modal>
  );
};

export default DevicesDialog;
