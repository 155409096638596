module.exports = {
  debug: Boolean(process.env.REACT_APP_DEBUG),
  firebaseConfig:
    process.env.REACT_APP_ENV === 'prod'
      ? {
          apiKey: 'AIzaSyBCcvT8zmGXSoQy-Fw6Of2mY-mafuyESC4',
          authDomain: 'clashtv-a9af1.firebaseapp.com',
          databaseURL: 'https://clashtv-a9af1.firebaseio.com',
          projectId: 'clashtv-a9af1',
          storageBucket: 'clashtv-a9af1.appspot.com',
          messagingSenderId: '176076330388',
          appId: '1:176076330388:web:ba8cea4fbc42a62e03ab81',
          measurementId: 'G-BD1FJ7D5ZZ',
          debug_mode: true,
        }
      : {
          apiKey: 'AIzaSyCRbN63GqzNosG0uZrpECmfVR-AUD1jNXI',
          authDomain: 'clashtv-staging-ab4fc.firebaseapp.com',
          projectId: 'clashtv-staging-ab4fc',
          storageBucket: 'clashtv-staging-ab4fc.appspot.com',
          messagingSenderId: '457118487512',
          appId: '1:457118487512:web:9f1467cdf3c2031f1ad8ad',
          measurementId: 'G-PJDTMFJJ0C',
          debug_mode: true,
        },
  webPushCertificate:
    'BBbV2iW_Mr3IORCF3iMVorQdCueVKtZohapwozhFO9fHHKSP6f0pBVjxgvNHc9YH10XPgDuIziTt0Ug_ZSP3e_M',
  runtimeEnvironment: process.env.REACT_APP_ENV,
};
