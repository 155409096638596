import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import PromotedEvents from '../components/promoted/PromotedEvents';
import PromotedRooms from '../components/promoted/PromotedRooms';
import Heading from '../components/typography/Heading';

import { RoomCategoriesContext } from '../context/roomCategories';
import { SponsorRoomsContext } from '../context/sponsorRooms';

import history from '../history';
import routes from '../routes';
import PromotedLiveEvents from '../components/promoted/PromotedLiveEvents';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const PromotedCategory = () => {
  const { categoryId, tab } = useParams();

  const roomCategoriesContext = useContext(RoomCategoriesContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);

  const { getCategorieById } = roomCategoriesContext;
  const { getRoomsByCategorisationID } = sponsorRoomsContext;

  const [category, setCategory] = useState(null);
  const [promotedRooms, setPromotedRooms] = useState([]);
  const [roomIds, setRoomIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadCategory = async () => {
    const { category } = await getCategorieById(categoryId);

    if (!category) {
      history.replace(routes.notFound);
      return;
    }

    setCategory(category);
  };

  const loadRooms = async () => {
    const { success, rooms } = await getRoomsByCategorisationID(categoryId);

    if (!success) {
      return [];
    }

    setPromotedRooms(rooms);

    return rooms;
  };

  useEffect(async () => {
    setLoading(true);
    await loadCategory();
    const rooms = await loadRooms();
    const roomIds = rooms.map((r) => r.id);
    setRoomIds(roomIds);
    setLoading(false);
  }, [categoryId]);

  // const renderLoader = () => {
  //   return (
  //     <div className='grid grid-cols-12 gap-4'>
  //       {Array(6)
  //         .fill()
  //         .map((item, index) => (
  //           <div
  //             key={index}
  //             className='col-span-3 flex items-center justify-center'
  //           >
  //             <Skeleton circle width={120} height={120} />
  //           </div>
  //         ))}
  //     </div>
  //   );
  // };

  return (
    <>
      <Helmet>
        <title>Promoted | ClashTV</title>
      </Helmet>
      <div className='col-span-full'>
        {category?.cover?.key && (
          <div
            className='room-banner-container'
            style={
              category?.cover?.key
                ? {
                    backgroundImage: `url(${imgBaseUrl}${category.cover.key})`,
                    backgroundPosition: 'right, left',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }
                : null
            }
          ></div>
        )}
        <div className='room-title-container'>
          {!loading && category?.logo?.key && (
            <img
              src={`${imgBaseUrl}${category.logo.key}?w=128`}
              className='room-image'
            />
          )}
          {!category && loading && (
            <Skeleton circle width={64} height={64} className='mr-4' />
          )}
          {category && !loading && (
            <Heading headingSize={4}>{category.title}</Heading>
          )}
          {!category && loading && <Skeleton width={240} height={20} />}
        </div>
        <div className='room-navigation-container'>
          <Link
            className={`room-navigation-button mr-8${
              tab === 'creators' ? ' active' : ''
            }`}
            to={routes.groups
              .replace(':categoryId', categoryId)
              .replace(':tab', 'creators')}
          >
            Creators
          </Link>
          <Link
            to={routes.groups
              .replace(':categoryId', categoryId)
              .replace(':tab', 'live')}
            className={`room-navigation-button mr-8${
              tab === 'live' ? ' active' : ''
            }`}
          >
            Live events
          </Link>
          <Link
            to={routes.groups
              .replace(':categoryId', categoryId)
              .replace(':tab', 'events')}
            className={`room-navigation-button mr-8${
              tab === 'events' ? ' active' : ''
            }`}
          >
            All events
          </Link>
        </div>
        <div className='py-7'>
          {tab === 'creators' && <PromotedRooms rooms={promotedRooms} />}
          {tab === 'live' && <PromotedLiveEvents roomIds={roomIds} />}
          {tab === 'events' && <PromotedEvents roomIds={roomIds} />}
        </div>
      </div>
    </>
  );
};

export default PromotedCategory;
