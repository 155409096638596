import React from 'react';
import { Helmet } from 'react-helmet';
import TermsComponent from '../clash_terms';

const Terms = () => {
  return (
    <div className='grid grid-cols-12 gap-4 mx-4'>
      <Helmet>
        <title>Terms | ClashTV</title>
      </Helmet>
      <div className='legal'>
        <TermsComponent />
      </div>
    </div>
  );
};

export default Terms;
