import React, { RefCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import TableCell from 'components/table/TableCell';
import TableRow from 'components/table/TableRow';

const SKELETON_HEIGHT = 22;
const SKELETON_WIDTH = '100%';

const PPVPassEarningsLoader = ({ innerRef }: { innerRef: RefCallback<HTMLTableRowElement>}) => {
  return (
    <TableRow innerRef={innerRef} classes={`border-b border-secondaryB`}>
      <TableCell variant='body' classes='font-bold py-2 pr-2'>
        <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
      </TableCell>
      <TableCell variant='body' classes='font-bold py-2 pr-2'>
        <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
      </TableCell>

      <TableCell
        variant='body'
        classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight pr-2'
      >
        <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
      </TableCell>
      <TableCell variant='body' classes='py-2 pr-2'>
        <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
      </TableCell>
      <TableCell variant='body' classes='py-2 pr-2'>
        <Skeleton height={SKELETON_HEIGHT} width={SKELETON_WIDTH} />
      </TableCell>
    </TableRow>
  );
};

export default PPVPassEarningsLoader;
