/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import {
  createAction,
  createAsyncAction,
} from "typesafe-actions";

import { CreateSubscriptionPassPayload } from "./types";

export const subscriptionCreatePageMounted = createAction('SubsriptionPassCreate/MOUNTED')<string>();

export const createSubscriptionPass = createAsyncAction(
  'SubsriptionPassCreate/CREATE_REQUEST',
  'SubsriptionPassCreate/CREATE_SUCCESS',
  'SubsriptionPassCreate/CREATE_ERROR'
)<CreateSubscriptionPassPayload, undefined, string>();
