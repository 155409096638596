import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from '../typography/Icon';
import Label from './Label';

import { isMobileNumberValid, isEmailValid } from '../../helpers';

const Input = ({
  placeholder,
  type,
  name,
  initialValue,
  id,
  error,
  success,
  classes,
  wrapClasses,
  disabled,
  label,
  hint,
  icon,
  togglePassword,
  setRef,
  autoFocus,
  iconIsDynamic,
  onEnter,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  rightText,
  pattern = '',
}) => {
  const [fieldType, setFieldType] = useState(type);
  const [value, setValue] = useState(initialValue);
  const successState = success;
  let currentIcon = icon;

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  let inputClassName = 'base-input';
  let iconClassName = 'icon-right';

  if (icon) {
    inputClassName += ' with-icon';
  }

  if (rightText) {
    inputClassName += ' with-rightText';
  }

  if (successState) {
    inputClassName += ' input-success';
    iconClassName += ' success';
    currentIcon = 'check';
  }

  if (error) {
    inputClassName += ' input-error';
    iconClassName += ' error';
    currentIcon = 'error';
  }

  if (disabled) {
    iconClassName += ' disabled';
  }

  const togglePasswordVisibility = () => {
    if (!type === 'password') {
      return;
    }

    if (togglePassword) {
      setFieldType(fieldType === 'password' ? 'text' : 'password');
    }
  };

  const renderIcon = () => {
    if (success || error) {
      return (
        <div className={`input-icon-right`}>
          <Icon size={24} name={currentIcon} classes={`${iconClassName}`} />
        </div>
      );
    }

    if (togglePassword) {
      if (fieldType === 'text') {
        currentIcon = 'hidePassword';
      } else {
        currentIcon = 'showPassword';
      }
    }

    if (iconIsDynamic) {
      if (isMobileNumberValid(value)) {
        currentIcon = 'phone';
      } else if (isEmailValid(value)) {
        currentIcon = 'email';
      }
    }

    return (
      <div
        className={`input-icon-right ${togglePassword ? 'clickable' : ''}`}
        onClick={togglePasswordVisibility}
      >
        <Icon size={24} name={currentIcon} classes={`${iconClassName}`} />
      </div>
    );
  };

  const handleOnChange = (event) => {
    setValue(event.target.value);

    onChange(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      onEnter();
    }
  };

  return (
    <div className={`flex flex-col ${wrapClasses}`}>
      {Object.keys(label).length != 0 ? (
        <Label
          text={label}
          inputId={id}
          classes={disabled ? 'mb-5 disabled' : 'mb-5'}
        />
      ) : null}
      <div className='relative'>
        <input
          ref={setRef}
          autoComplete='off'
          id={id}
          placeholder={placeholder}
          type={fieldType}
          name={name}
          value={value}
          onChange={handleOnChange}
          disabled={disabled}
          autoFocus={autoFocus}
          className={`${inputClassName} ${classes}`}
          onKeyPress={handleKeyPress}
          maxLength={maxLength}
          onBlur={onBlur}
          onFocus={onFocus}
          pattern={pattern}
        />
        {icon && renderIcon()}
        {rightText && <div className={`input-icon-right`}>{rightText}</div>}
      </div>
      {hint && <p className={`input-hint ${error ? 'error' : ''}`}>{hint}</p>}
    </div>
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string.isRequired,
  error: PropTypes.bool,
  classes: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  success: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hint: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  togglePassword: PropTypes.bool,
  iconIsDynamic: PropTypes.bool,
  onEnter: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  rightText: PropTypes.string,
};

Input.defaultProps = {
  placeholder: '',
  type: 'text',
  name: '',
  initialValue: '',
  error: false,
  classes: '',
  disabled: false,
  autoFocus: false,
  success: false,
  label: {},
  hint: '',
  icon: '',
  togglePassword: false,
  iconIsDynamic: false,
  onEnter: () => {},
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  rightText: '',
};

export default Input;
