import React, { useContext, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';

import GridContainer from '../layout/GridContainer';
import Heading from '../typography/Heading';
import Input from '../form/Input';
import SelectField from '../form/SelectField';
import Checkbox from '../form/Checkbox';
import VideoUpload from '../form/VideoUpload';
import Button from '../common/Button';
import ImageUpload from '../form/ImageUpload';
import Icon from '../typography/Icon';
import DescriptionField from '../creator/DescriptionField';
import Modal from '../system/Modal';
import Paragraph from '../typography/Paragraph';

import { EventCategoriesContext } from '../../context/eventCategories';
import themeColors from '../../theme-colors';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { allowedVideoTypes } from '../../helpers';

const MAX_VIDEO_SIZE = 1024 * 1000 * 1000 * 5;

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const VideoForm = ({
  videoData,
  onSubmit,
  videoState,
  videoUploadProgress,
  buttonLabel,
}) => {
  const listings = [
    {
      key: 'private',
      id: 'private',
      text: 'Private',
      onClick: () => setSelectedListing('private'),
    },
    {
      key: 'public',
      id: 'public',
      text: 'Public',
      onClick: () => setSelectedListing('public'),
    },
  ];

  const eventCategoriesContext = useContext(EventCategoriesContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);

  const { eventCategories } = eventCategoriesContext;
  const { currentUserRoom } = sponsorRoomsContext;

  const getInitialCategory = () => {
    if (!videoData?.eventCategorisation) {
      return {
        id: '-',
        title: 'Select',
      };
    }

    const { eventCategorisation } = videoData;

    return { title: eventCategorisation.title, id: eventCategorisation.id };
  };

  const [isPPV, setIsPPV] = useState(videoData?.ppv === 1 ? true : false);
  const [selectedListing, setSelectedListing] = useState(
    videoData?.listing ?? listings[0].id
  );
  const [selectedCategory, setSelectedCategory] = useState(
    getInitialCategory()
  );
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailObject, setThumbnailObject] = useState(videoData?.thumbnail);
  const [thumbnailUrl, setThumbnailUrl] = useState(
    thumbnailObject?.key ? `${imgBaseUrl}${thumbnailObject.key}` : ''
  );
  const [videoFile, setVideoFile] = useState(null);
  const [description, setDescription] = useState(videoData?.description || '');
  const [richDescription, setRichDescription] = useState(
    videoData?.richTextDescription
  );
  const [fileError, setFileError] = useState(null);

  const titleRef = useRef();
  // const descriptionRef = useRef();
  const priceRef = useRef();

  const getCategories = () => {
    const categoryOptions = eventCategories.map((c) => ({
      text: c.title,
      onClick: () => {
        setSelectedCategory(c);
      },
    }));

    return [
      {
        text: 'Select',
        onClick: () => setSelectedCategory({ id: '-', title: 'Select' }),
      },
      ...categoryOptions,
    ];
  };

  const selectThumbnail = (e) => {
    setThumbnailFile(e.target.files[0]);
  };

  const handleCloseFileErrorDialog = () => {
    setFileError(null);
  };

  const selectVideoFile = (file) => {
    if (file.size >= MAX_VIDEO_SIZE) {
      setFileError({
        type: 'filesize',
        filesize: file.size,
        filetype: file.type,
      });
      return;
    }

    if (!allowedVideoTypes[file.type]) {
      setFileError({
        type: 'filetype',
        filesize: file.size,
        filetype: file.type,
      });
      return;
    }

    setVideoFile(file);
  };

  const renderVideoField = () => {
    if (!videoData) {
      if (videoFile) {
        return (
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%',
              height: 'auto',
            }}
          >
            <div className='absolute inset-0'>
              <ReactPlayer
                id='video-placeholder'
                playing={false}
                controls={true}
                muted={true}
                url={URL.createObjectURL(videoFile)}
                width='100%'
                height='100%'
                config={{
                  file: {
                    attributes: { disablePictureInPicture: true },
                  },
                }}
              />
            </div>
            <div
              className='absolute w-10 h-10 flex items-center justify-center top-2 right-2 cursor-pointer'
              onClick={() => setVideoFile(null)}
            >
              <Icon name='error' size={20} color={themeColors.primaryD} />
            </div>
          </div>
        );
      }

      return (
        <VideoUpload
          onFileSelect={selectVideoFile}
          disabled={false}
          state={videoState}
          uploadProgress={videoUploadProgress}
        >
          <div className='image-upload-subtitle text-center'>
            File type must be mp4, avi or mpeg and cannot exceed filesize of 5GB
          </div>
        </VideoUpload>
      );
    }

    if (videoData?.videoFile) {
      if (videoData.playbackUrl) {
        return (
          <div
            style={{
              position: 'relative',
              width: '100%',
              paddingTop: '56.25%',
              height: 'auto',
            }}
          >
            <div className='absolute inset-0'>
              <ReactPlayer
                id='video-placeholder'
                playing={false}
                controls={true}
                muted={true}
                url={videoData.playbackUrl}
                width='100%'
                height='100%'
                config={{
                  file: {
                    attributes: { disablePictureInPicture: true },
                  },
                }}
              />
            </div>
          </div>
        );
      } else {
        return <VideoUpload disabled={true} state={'processing'} />;
      }
    }
  };

  const submit = () => {
    const title = titleRef.current?.value || '';
    const price = priceRef.current?.value || 0;

    const data = {
      title,
      description,
      richTextDescription: richDescription,
      thumbnailFile,
      thumbnail: thumbnailObject,
      eventCategorisationID:
        selectedCategory.id !== '-' ? selectedCategory.id : '',
      listing: selectedListing,
      ppv: isPPV ? 1 : 0,
      ppvPrice: price,
      file: videoFile,
      hostSponsorRoomID: currentUserRoom.id,
    };

    onSubmit(data);
  };

  const removeImage = () => {
    setThumbnailFile(null);
    setThumbnailUrl('');
    setThumbnailObject(null);
  };

  const updateDescription = ({ descriptionPlainText, descriptionRichText }) => {
    setDescription(descriptionPlainText);
    setRichDescription(descriptionRichText);
  };

  return (
    <GridContainer>
      <div className='col-span-full flex flex-col mb-6'>
        <Heading headingSize={3} classes='mb-8'>
          Details
        </Heading>
        <div className='flex flex-1 mb-4 mr-4'>
          <div className='flex flex-1 flex-col mr-4'>
            <Input
              id='title'
              initialValue={videoData?.title ?? ''}
              placeholder='Title'
              type='text'
              label='Title *'
              setRef={titleRef}
              classes='py-4'
              wrapClasses='mb-4'
            />
            {/* <Input
              id='description'
              initialValue={videoData?.description ?? ''}
              placeholder='Description'
              type='text'
              label='Description'
              setRef={descriptionRef}
              classes='py-4'
              wrapClasses='mb-4'
            /> */}
            <DescriptionField
              initialDescriptionValue={description}
              richTextValue={richDescription}
              onUpdate={updateDescription}
            />
            <SelectField
              label='Category *'
              value={selectedCategory.title}
              options={getCategories()}
              inputClassName='py-4'
              wrapClasses='mb-4'
              listFullWidth
            />
            <SelectField
              label='Listing'
              value={selectedListing}
              options={listings}
              inputClassName='py-4'
              wrapClasses='mb-4'
              listFullWidth
            />
            <div className='flex items-center justify-between h-15'>
              <Checkbox
                checkboxID='ppv'
                checkboxName='ppv'
                checked={isPPV}
                toggle={() => setIsPPV(!isPPV)}
              >
                Pay-Per-View
              </Checkbox>
              {isPPV && (
                <Input
                  id='price'
                  placeholder='Coins'
                  onBlur={() => {}}
                  initialValue={videoData?.ppvPrice ?? ''}
                  type='number'
                  setRef={priceRef}
                  classes='py-4'
                />
              )}
            </div>
          </div>
          <div className='flex flex-1 flex-col ml-4'>
            <div className='mb-5'>
              <label className='label-base'>Thumbnail</label>
            </div>
            <div className='relative'>
              <ImageUpload
                containerClassName='mb-4'
                inputId='thumbnail'
                imageSrc={thumbnailUrl}
                setImageSrc={setThumbnailUrl}
                handleSelect={selectThumbnail}
              />
              {(videoData?.thumbnail?.key || thumbnailFile) && (
                <div
                  className='absolute w-10 h-10 flex items-center justify-center top-2 right-2 cursor-pointer'
                  onClick={() => removeImage()}
                >
                  <Icon name='error' size={20} color={themeColors.primaryD} />
                </div>
              )}
            </div>
            <div className='mb-5'>
              <label className='label-base'>Video *</label>
            </div>
            {renderVideoField()}
          </div>
        </div>
        <Button
          buttonSize='medium'
          buttonType='secondary'
          classes='self-end'
          buttonOnClick={submit}
        >
          {buttonLabel}
        </Button>
      </div>
      {videoState === 'uploading' && (
        <div className='fixed inset-0 flex items-center justify-center bg-opacity-40 bg-black z-20'>
          <div className='font-display text-base text-primaryA text-center bg-secondaryC rounded-lg shadow-md px-8 py-6'>
            <div>{videoUploadProgress}%</div>
            <div>Uploading...</div>
          </div>
        </div>
      )}
      <Modal
        title='Warning!'
        actions={[
          <Button
            key='close-time-slot-dialog'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={handleCloseFileErrorDialog}
            classes='mr-4'
          >
            Ok
          </Button>,
        ]}
        isVisible={!!fileError}
        handleClose={handleCloseFileErrorDialog}
      >
        <Paragraph>
          {fileError?.type === 'filesize'
            ? 'This file is too big!'
            : 'File type is not supported'}
        </Paragraph>
        <Paragraph>
          {fileError?.type === 'filesize'
            ? 'File exceeds the maximum upload filesize (5GB). Please select a smaller file'
            : 'File type must be mp4, avi or mpeg'}
        </Paragraph>
      </Modal>
    </GridContainer>
  );
};

VideoForm.propTypes = {
  videoData: PropTypes.object,
  onSubmit: PropTypes.func,
  videoState: PropTypes.string,
  videoUploadProgress: PropTypes.number,
  buttonLabel: PropTypes.string,
};

VideoForm.defaultProps = {
  videoData: null,
  onSubmit: () => {},
  videoState: 'idle',
  videoUploadProgress: 0,
  buttonLabel: 'Publish',
};

export default VideoForm;
