import React from 'react';
import Icon from '../typography/Icon';
import VideoProgressBar from './VideoProgressBar';

const VideoControls = ({
  playing,
  duration,
  progress,
  muted,
  fullscreen,
  togglePlay,
  toggleMute,
  onSeek,
  toggleFullscreen,
  visible,
}) => {
  return (
    <div className={`teaser-controls-container ${visible ? 'visible' : ''}`}>
      <button onClick={togglePlay} className='teaser-controls-btn'>
        <Icon name={playing ? 'pause' : 'play'} />
      </button>
      <button onClick={toggleMute} className='teaser-controls-btn'>
        <Icon name={muted ? 'volume-mute' : 'volume-up'} />
      </button>
      <VideoProgressBar
        max={duration}
        progress={progress}
        handleProgress={onSeek}
      />
      <button className='teaser-controls-btn' onClick={toggleFullscreen}>
        <Icon name={fullscreen ? 'minimize' : 'full-screen'} />
      </button>
    </div>
  );
};

export default VideoControls;
