/* eslint-disable */

export const getUserNotifications = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
    }
  }
`;

export const customListFollowingsBySponsorRoomID = /* GraphQL */ `
  query ListFollowingsBySponsorRoomID(
    $sponsorRoomID: ID
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFollowingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFollowingsBySponsorRoomID(
      sponsorRoomID: $sponsorRoomID
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sponsorRoomID
        createdAt
        updatedAt
        followerDisplayName
        user {
          id
          displayName
          creator
          location
          level
          image {
            bucket
            region
            key
            _url
          }
        }
      }
      nextToken
    }
  }
`;

export const customOnlineUsersByEventAndDisplayName = /* GraphQL */ `
  query OnlineUsersByEventAndDisplayName(
    $eventID: String
    $displayName: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventOnlineUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onlineUsersByEventAndDisplayName(
      eventID: $eventID
      displayName: $displayName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        displayName
        eventID
        createdAt
        updatedAt
        raisedHand
        user {
          id
          email
          phone
          fullName
          displayName
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
          image {
            bucket
            region
            key
            _url
          }
        }
      }
      nextToken
    }
  }
`;

export const customOnlineUsersByEventAndDisplayNameLowerCase = /* GraphQL */ `
  query OnlineUsersByEventAndDisplayNameLowerCase(
    $eventID: String
    $displayNameLowerCase: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventOnlineUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    onlineUsersByEventAndDisplayNameLowerCase(
      eventID: $eventID
      displayNameLowerCase: $displayNameLowerCase
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        displayName
        displayNameLowerCase
        eventID
        createdAt
        updatedAt
        raisedHand
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
          image {
            bucket
            region
            key
            _url
          }
        }
      }
      nextToken
    }
  }
`;