/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { logError } from '../../helpers';

import Icon from '../typography/Icon';

function setButtonStyles(type, size) {
  const typeClassname = type ? `btn-${type.toLowerCase()}` : '';
  const sizeClassname = size ? `btn-${size.toLowerCase()}` : '';

  return typeClassname
    ? `${typeClassname} ${sizeClassname}`
    : sizeClassname;
}

const Button = ({
  children,
  buttonType,
  buttonSize,
  buttonOnClick,
  buttonId,
  isLink,
  linkAttr,
  linkTarget,
  linkTo,
  icon,
  iconClasses,
  iconSize,
  iconColor,
  classes,
  disabled,
}) => {
  if (!children) {
    logError("Component Button not rendered, because 'children' missing.");
    return null;
  }

  const allButtonStyles = `btn ${setButtonStyles(
    buttonType,
    buttonSize
  )} ${classes}`;

  return (
    <>
      {isLink ? (
        <Link
          id={buttonId}
          data-testid={buttonId}
          className={allButtonStyles}
          to={linkTo}
          title={linkAttr}
          target={linkTarget}
        >
          {children}
        </Link>
      ) : (
        <button
          id={buttonId}
          data-testid={buttonId}
          className={allButtonStyles}
          onClick={buttonOnClick}
          disabled={disabled}
        >
          {icon.length !== 0 && icon !== undefined ? (
            <Icon
              name={icon}
              size={iconSize}
              color={iconColor}
              classes={iconClasses}
            />
          ) : null}
          {children}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  buttonId: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'outlined', 'purple']),
  buttonSize: PropTypes.oneOf(['large', 'medium', 'small']),
  buttonOnClick: PropTypes.func,
  isLink: PropTypes.bool.isRequired,
  linkAttr: PropTypes.string,
  linkTarget: PropTypes.oneOf(['_blank', '_self']),
  linkTo: PropTypes.string,
  icon: PropTypes.string,
  iconClasses: PropTypes.string,
  iconSize: PropTypes.number,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  classes: PropTypes.string,
};

Button.defaultProps = {
  buttonId: '',
  buttonType: 'primary',
  buttonSize: 'large',
  isLink: false,
  linkAttr: '',
  linkTarget: '_self',
  linkTo: '',
  icon: '',
  iconClasses: 'inline-block mr-4 align-middle fill-current',
  iconSize: 18,
  iconColor: '',
  disabled: false,
  classes: '',
};

export default Button;
