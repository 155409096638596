import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { BoostersContext } from './boosters';
import { listBoosters } from '../graphql/queries';
import { onCreateBoosterActivation } from '../graphql/subscriptions';
import { logError } from '../helpers';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class BoostersProvider extends Component {
  constructor(props) {
    super(props);

    this.intervals = {};

    this.state = {
      boosters: [],
      activeBoosters: {},
      loading: false,
      error: null,
      durations: {},
      loadBoosters: this.loadBoosters,
      subscribeToBoosterActivation: this.subscribeToBoosterActivation,
      unsubscribeBoosterActivation: this.unsubscribeBoosterActivation,
    };
  }

  subscribeToBoosterActivation = (userID) => {
    const boosterActivationSubscription = API.graphql(
      graphqlOperation(onCreateBoosterActivation, {
        userID,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        const booster = value?.data?.onCreateBoosterActivation || null;

        if (booster) {
          const boosterData = this.state.boosters.find(
            (b) => b.id === booster.boosterID
          );

          this.intervals = {
            [booster.boosterID]: setInterval(
              () =>
                this.updateDurations(booster.boosterID, boosterData.duration),
              1000
            ),
          };

          this.setState({
            activeBoosters: {
              ...this.state.activeBoosters,
              [booster.boosterID]: boosterData,
            },
          });
        }
      },
      error: (error) => logError(error),
    });

    return boosterActivationSubscription;
  };

  unsubscribeBoosterActivation = (subscription) => {
    subscription.unsubscribe();
  };

  loadBoosters = async () => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listBoosters));

      const items = res?.data?.listBoosters?.items || [];

      for (const booster of items) {
        if (booster?.icon?.key) {
          booster.icon._url = `${imgBaseUrl}${booster.icon.key}?d=62x62`;
        }
      }

      this.setState({
        loading: false,
        boosters: items,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        boosters: [],
        error: 'Error loading ranking users',
        loading: false,
      });
    }
  };

  updateDurations = (boosterID, duration) => {
    const cooldown = this.state.durations[boosterID] || 0;
    const seconds = cooldown + 1;

    if (seconds > duration) {
      const durations = { ...this.state.durations };
      delete durations[boosterID];

      clearInterval(this.intervals[boosterID]);
      this.setState({
        durations,
      });

      const activeBoosters = this.state.activeBoosters;
      delete activeBoosters[boosterID];

      this.setState({ activeBoosters });

      return;
    }

    this.setState({
      durations: {
        ...this.state.durations,
        [boosterID]: seconds,
      },
    });
  };

  componentWillUnmount() {
    for (const id in this.intervals) {
      if (Object.hasOwnProperty.call(this.intervals, id)) {
        const interval = this.intervals[id];
        clearInterval(interval);
      }
    }
  }

  render() {
    return (
      <BoostersContext.Provider value={this.state}>
        {this.props.children}
      </BoostersContext.Provider>
    );
  }
}
