import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { UserContext } from '../../context/user';
import { CookieConsentContext } from '../../context/cookieConsent';

import Icon from '../typography/Icon';
import Paragraph from '../typography/Paragraph';
import Button from '../common/Button';

import { analytics } from '../../firebase';
import { setCookie, getCookie } from '../../helpers';
import routes from '../../routes';

const CookieConsent = () => {
  const userContext = useContext(UserContext);
  const cookieConsentContext = useContext(CookieConsentContext);

  const { user, getCurrentUserId } = userContext;
  const { isVisible, showConsent, hideConsent, globalCookie, setGlobalCookie } =
    cookieConsentContext;

  const onAccept = async () => {
    if (user?.attributes?.sub) {
      setCookie(getCurrentUserId(), JSON.stringify({ analytics: true }), 365);
    }
    setCookie('globalCookie', JSON.stringify({ analytics: true }), 365);
    setGlobalCookie(true);
    analytics();
    hideConsent();
  };

  // const onDismiss = () => {
  //   setCookie(getCurrentUserId(), JSON.stringify({ analytics: false }), 365);
  //   hideConsent();
  // };

  useEffect(() => {
    if (user) {
      const cookie = getCookie(getCurrentUserId());

      if (cookie) {
        try {
          const value = JSON.parse(cookie);
          if (value.analytics === true) {
            analytics();
            hideConsent();
          }
        } catch (error) {
          showConsent();
        }
      } else {
        globalCookie ? hideConsent() : showConsent();
      }
    } else {
      globalCookie ? hideConsent() : showConsent();
    }
  }, [user, globalCookie]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className='fixed top-0 h-30 w-full bg-primaryADark z-50 flex'>
      <div className='flex flex-1 items-center justify-end pr-4'>
        <Icon name='cookie' color={'#fff'} size={40} />
      </div>
      <div className='flex flex-1 items-center'>
        <Paragraph
          paragraphSize='medium'
          classes='font-bold text-white font-display'
        >
          This website uses cookies to improve user experience. By using our
          website you consent to all cookies in accordance with our{' '}
          <Link to={routes.privacyPolicy} className='underline' target='_blank'>
            Privacy Policy
          </Link>
          .
        </Paragraph>
      </div>
      <div className='flex flex-1 items-center justify-end'>
        <Button
          buttonType='secondary'
          buttonSize='small'
          buttonOnClick={onAccept}
          classes='mr-6 text-black'
        >
          OK
        </Button>
        {/* <button
          className='w-12 h-12 flex items-center justify-center mr-4'
          onClick={onDismiss}
        >
          <Icon name='error' color='#fff' />
        </button> */}
      </div>
    </div>
  );
};

export default CookieConsent;
