import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { AvatarsContext } from './avatars';
import { listAvatars } from '../graphql/queries';
import { logError } from '../helpers';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class AvatarsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      avatars: [],
      selectedAvatar: {},
      loadAvatars: this.loadAvatars,
      setSelectedAvatar: this.setSelectedAvatar,
      clearSelectedAvatar: this.clearSelectedAvatar,
    };
  }

  loadAvatars = async () => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listAvatars));
      const items = res?.data?.listAvatars?.items || [];

      if (items.length > 0) {
        for (const i of items) {
          if (i?.image?.key) {
            i.image._url = this.getImageUrl(i.image.key);
          }
        }
      }

      const sorted = items.sort((a, b) => a.order - b.order);

      this.setState({ loading: false, avatars: sorted, error: null });
    } catch (error) {
      logError(error);
      this.setState({
        avatars: [],
        loading: false,
        error: 'Error loading avatars',
      });
    }
  };

  getImageUrl = (key) => {
    return `${imgBaseUrl}${key}?w=120`;
  };

  setSelectedAvatar = (imageObject, file, url) => {
    this.setState({ selectedAvatar: { imageObject, file, url } });
  };

  clearSelectedAvatar = () => this.setState({ selectedAvatar: {} });

  render() {
    return (
      <AvatarsContext.Provider value={this.state}>
        {this.props.children}
      </AvatarsContext.Provider>
    );
  }
}
