import React, { useState } from 'react';
import PropTypes from 'prop-types';
import themeColors from '../../theme-colors';
import Icon from '../typography/Icon';
import CroppingTool from './CroppingTool';

const ImageUpload = ({
  disabled,
  handleSelect,
  containerClassName,
  inputId,
  imageSrc,
  setImageSrc,
}) => {
  const [cropIsVisible, setCropIsVisible] = useState(false);
  const [fileToCrop, setFileToCrop] = useState(null);
  const fr = new FileReader();

  const handleFileSelect = (e) => {
    if (disabled) {
      return;
    }

    const file = e?.target?.files[0];

    setCropIsVisible(true);
    setFileToCrop(file);
  };

  const onCrop = (file, url) => {
    if (FileReader && file) {
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setImageSrc(fr.result);
          handleSelect({ target: { files: [file] } }, inputId);
          fr.abort();
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  };

  return (
    <div className={`image-upload-container ${containerClassName}`}>
      <label htmlFor={inputId} className='image-upload-content-container'>
        {!imageSrc && (
          <Icon
            size={22}
            name='upload'
            color={themeColors.primaryADark}
            classes='mb-4'
          />
        )}
        {!imageSrc && (
          <div className='image-upload-title'>Choose an image file</div>
        )}
        {!imageSrc && (
          <div className='image-upload-subtitle'>
            Image must be less than 40 MB
          </div>
        )}
        {!imageSrc && (
          <input
            id={inputId}
            style={{ visibility: 'hidden', width: 0, height: 0 }}
            type='file'
            onChange={handleFileSelect}
            accept='image/png, image/jpeg'
            disabled={disabled}
          />
        )}
        {imageSrc && <img src={imageSrc} className='image-upload-image' />}
      </label>
      {cropIsVisible && (
        <CroppingTool
          file={fileToCrop}
          onCrop={onCrop}
          close={() => setCropIsVisible(false)}
        />
      )}
    </div>
  );
};

ImageUpload.propTypes = {
  disabled: PropTypes.bool,
  handleSelect: PropTypes.func,
  containerClassName: PropTypes.string,
  inputId: PropTypes.string,
  imageSrc: PropTypes.string,
  setImageSrc: PropTypes.func,
};

ImageUpload.defaultProps = {
  disabled: false,
  handleSelect: () => {},
  containerClassName: '',
  inputId: '',
  imageSrc: '',
  setImageSrc: () => {},
};

export default ImageUpload;
