/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { produce } from 'immer';
import {
  ActionType,
  createReducer,
} from 'typesafe-actions';

import {
  createSubscriptionPass,
} from './actions';

export type Actions = ActionType<
  | typeof createSubscriptionPass
>;

export type State = {
  loading: boolean;
  error: string | null;
  successfullySaved: boolean
};

const initialState: State = {
  loading: false,
  error: null,
  successfullySaved: false,
};

const subscriptionCreateReducer = createReducer<State, Actions>(initialState)
  .handleAction(createSubscriptionPass.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.error = null;
      draft.successfullySaved = false;
    }))
  .handleAction(createSubscriptionPass.success, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = null;
      draft.successfullySaved = true;
    }))
  .handleAction(createSubscriptionPass.failure, (state, action) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = action.payload;
      draft.successfullySaved = false;
    }));

export default subscriptionCreateReducer;
