/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
import React, { useContext, useEffect, useState } from 'react';
import Switch from 'react-switch';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { Prompt } from 'react-router-dom';

import TabButton from './TabButton';
import Button from '../common/Button';
import StreamPlayer from './StreamPlayer';
import BattleModeTab from './BattleModeTab';
import RtmpTab from './RtmpTab';
import Modal from '../system/Modal';
import Paragraph from '../typography/Paragraph';
import StreamParticipants from './StreamParticipants';
import EventControls from './EventControls';

import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { EventsContext } from '../../context/events';
import { AlertsContext } from '../../context/alerts';
import { PollsContext } from '../../context/polls';
import { InvitationsContext } from '../../context/invitations';
import { StreamContext } from '../../context/stream';

import useBroadcaster from '../../hooks/useBroadcaster';
import Spinner from '../system/Spinner';
import Heading from '../typography/Heading';

import { checkHLSActive, leaveMeeting, logError } from '../../helpers';
import { useLogEvent } from '../../firebase';
import routes from '../../routes';
import history from '../../history';
import { UserContext } from '../../context/user';
import DelayedLiveTab from './DelayedLiveTab';

const StreamInformation = ({ eventId }) => {
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const eventsContext = useContext(EventsContext);
  const alertsContext = useContext(AlertsContext);
  const pollsContext = useContext(PollsContext);
  const invitationsContext = useContext(InvitationsContext);
  const streamContext = useContext(StreamContext);
  const userContext = useContext(UserContext);

  const { currentUserRoom } = sponsorRoomsContext;
  const {
    updateEvent,
    getLiveEventsByRoomId,
    getCurrentEvent,
    createEventSubscription,
  } = eventsContext;
  const { addAlert } = alertsContext;
  const { getLatestActivePollByEventId, updatePoll } = pollsContext;
  const { getLastInviteByEventId, updateInvitation } = invitationsContext;
  const { endEventByLeaving, startedStreaming, setStartedStreaming } =
    streamContext;
  const { getCurrentUserId } = userContext;
  const { logEvent } = useLogEvent();

  const [activeTab, setActiveTab] = useState('stream-info');
  const [streamType, setStreamType] = useState(null);
  const [paused, setPaused] = useState(true);
  const [rounds, setRounds] = useState(0);
  const [roundsEnabled, setRoundsEnabled] = useState(false);
  const [showRTMPModal, setShowRTMPModal] = useState(false);
  const [showBattleModal, setShowBattleModal] = useState(false);
  const [showDelayedLiveModal, setShowDelayedLiveModal] = useState(false);
  const [eventData, setEventData] = useState(null);
  const [rtmpIsReady, setRtmpIsReady] = useState(false);
  const [ending, setEnding] = useState(false);

  const {
    broadcasterIsLive,
    setStreamUrl,
    streamUrl,
    setBroadcasterState,
    checkAndSetBroadcasterState,
  } = useBroadcaster();
  const meetingManager = useMeetingManager();

  const handleEventUpdate = (updatedEvent) => {
    if (!eventData) {
      Object.keys(updatedEvent).forEach(
        (k) => updatedEvent[k] === null && delete updatedEvent[k]
      );

      setEventData((prevState) => ({ ...prevState, ...updatedEvent }));
      return;
    }

    if (eventData.mixerEventUUID !== updatedEvent.mixerEventUUID) {
      Object.keys(updatedEvent).forEach(
        (k) => updatedEvent[k] === null && delete updatedEvent[k]
      );

      setEventData((prevState) => ({ ...prevState, ...updatedEvent }));
    }
  };

  useEffect(async () => {
    const eventSubscription = await createEventSubscription(
      eventId,
      handleEventUpdate,
      (err) => logError(err)
    );

    return () => {
      if (eventSubscription) {
        eventSubscription.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (currentUserRoom && eventData?.mode === 'user') {
      const interval = setInterval(async () => {
        const isActive = await checkHLSActive(
          currentUserRoom.ivsChannel.playbackUrl
        );

        if (isActive) {
          setRtmpIsReady(true);
          clearInterval(interval);
        }
      }, 2 * 1000);
    }
  }, [eventData?.mode]);

  useEffect(async () => {
    const event = await getCurrentEvent(eventId);

    if (event) {
      setEventData(event);
      setPaused(!!event.paused);
      setRounds(event.currentRound || 0);
      setRoundsEnabled(!!event.roundsEnabled);

      switch (event.mode) {
        case 'user':
          setStreamType('rtmp');
          break;
        case 'battle':
          setStreamType('battle');
          break;
        case 'delayedLive':
          setStreamType('delayedLive');
          break;
        default:
          break;
      }

      const eventUUID = event?.mixerEventUUID || null;
      const accessCode = event?.mixerEventModeratorAccessCode || null;

      if (eventUUID && accessCode) {
        checkAndSetBroadcasterState(eventUUID, accessCode);
      }
    }
  }, []);

  useEffect(async () => {
    if (!broadcasterIsLive) {
      return;
    }

    setStartedStreaming(true);
    const event = await getCurrentEvent(eventId, true);
    if (event && event.videoUrl) {
      setStreamUrl(event.videoUrl);
    }
  }, [broadcasterIsLive]);

  useEffect(async () => {
    if (!startedStreaming) {
      return;
    }

    let eventUUID = eventData?.mixerEventUUID || null;
    let accessCode = eventData?.mixerEventModeratorAccessCode || null;

    if (!eventUUID && !accessCode) {
      const event = await getCurrentEvent(eventId);
      eventUUID = event.mixerEventUUID;
      accessCode = event.mixerEventModeratorAccessCode;
    }

    if (eventUUID && accessCode) {
      checkAndSetBroadcasterState(eventUUID, accessCode);
    }
  }, [startedStreaming]);

  useEffect(async () => {
    let shouldEnd = false;
    const event = await getCurrentEvent(eventId, true);

    if (!event.hostSponsorRoom) {
      history.replace(routes.notFound);
      return;
    }

    if (event.hostSponsorRoom.userID !== getCurrentUserId()) {
      history.replace(routes.notFound);
      return;
    }

    shouldEnd = true;

    return () => {
      if (shouldEnd) {
        endEventByLeaving(eventId);
      }
    };
  }, []);

  useEffect(() => {
    if (eventData?.mixerEventUUID && startedStreaming && !broadcasterIsLive) {
      checkAndSetBroadcasterState(
        eventData.mixerEventUUID,
        eventData.mixerEventModeratorAccessCode
      );
    }
  }, [eventData?.mixerEventUUID]);

  const handleRadioChange = async (event) => {
    setStreamType(event.target.name);
  };

  const togglePaused = async () => {
    const pausedValue = !paused;

    setPaused(pausedValue);
    const res = await updateEvent({ id: eventId, paused: pausedValue });

    if (res.updatedEvent) {
      setEventData(res.updatedEvent);
    }
  };

  const handleRoundsChange = async (newValue) => {
    if (newValue < 0) {
      return;
    }

    setRounds(newValue);

    const res = await updateEvent({ id: eventId, currentRound: newValue });

    if (res.updatedEvent) {
      setEventData(res.updatedEvent);
    }
  };

  const toggleRoundsEnable = async () => {
    const roundsEnabledValue = !roundsEnabled;

    setRoundsEnabled(roundsEnabledValue);
    const res = await updateEvent({
      id: eventId,
      roundsEnabled: roundsEnabledValue,
    });

    if (res.updatedEvent) {
      setEventData(res.updatedEvent);
    }
  };

  const increaseRounds = () => {
    handleRoundsChange(rounds + 1);
  };

  const decreaseRounds = () => {
    handleRoundsChange(rounds - 1);
  };

  const goLive = async () => {
    if (eventData.mode !== 'user' && eventData.mode !== 'battle') {
      addAlert('danger', 'Please select event mode!');
      return;
    }

    let liveRes = { events: [], error: null, nextToken: null };

    do {
      liveRes = await getLiveEventsByRoomId(
        eventData.hostSponsorRoomID,
        liveRes.nextToken
      );

      if (liveRes.events.length > 0) {
        break;
      }
    } while (liveRes.nextToken);

    if (liveRes.events.length > 0) {
      addAlert('danger', 'Another event is currently live!');
      return;
    }

    const res = await updateEvent({ id: eventId, state: 'live' });

    if (res.updatedEvent) {
      setEventData(res.updatedEvent);
    }

    logEvent('start_live_event', { eventId, category: 'live_stream_stats' });

    if (eventData.mode === 'user') {
      logEvent('start_rtmp_event', { eventId, category: 'live_stream_stats' });
    }

    if (eventData.mode === 'battle') {
      logEvent('start_battle_mode_event', {
        eventId,
        category: 'live_stream_stats',
      });
    }
  };

  const endEvent = async () => {
    setEnding(true);
    if (eventData.mode === 'battle') {
      const invite = await getLastInviteByEventId(eventId);

      if (invite && invite.status !== 'declined') {
        await updateInvitation(invite.id, {
          status: 'declined',
        });

        await leaveMeeting(
          { meetingId: meetingManager.meetingId },
          invite.mixerEventUUID,
          invite.mixerAccessCode
        );
      }

      const eventUUID = eventData?.mixerEventUUID || null;
      const accessCode = eventData?.mixerEventModeratorAccessCode || null;

      if (eventUUID && accessCode) {
        await leaveMeeting(
          { meetingId: meetingManager.meetingId },
          eventUUID,
          accessCode
        );
      }

      setBroadcasterState(false);
    }

    setTimeout(async () => {
      const activePoll = await getLatestActivePollByEventId(eventId);
      const res = await updateEvent({ id: eventId, state: 'ended' });

      if (eventData.mode === 'battle' && eventData.dynamicParticipants) {
        logEvent('event_ended_with_dynamic_participants', {
          eventId,
          category: 'creator',
        });
      }

      if (!paused) {
        logEvent('event_ended_with_enabled_gamification', {
          eventId,
          category: 'creator',
        });
      }

      if (roundsEnabled) {
        logEvent('event_ended_with_enabled_rounds', {
          eventId,
          category: 'creator',
        });
      }

      if (activePoll) {
        await updatePoll(activePoll.id, { status: 'archived' });
      }

      if (res.updatedEvent) {
        setEventData(res.updatedEvent);
      }

      setStartedStreaming(false);
      setEnding(false);
    }, 5 * 1000);
  };

  const selectRtmpMode = () => {
    if (eventData.mode && eventData.mode !== 'manual') {
      return;
    }

    setShowRTMPModal(true);
  };

  const selectBattleMode = () => {
    if (eventData.mode && eventData.mode !== 'manual') {
      return;
    }

    setShowBattleModal(true);
  };

  const selectDelayedLiveMode = (e) => {
    if (eventData.mode && eventData.mode !== 'manual') {
      return;
    }

    setShowDelayedLiveModal(true);
  };

  const setRtmpMode = async () => {
    setStreamType('rtmp');
    setShowRTMPModal(false);
    const res = await updateEvent({ id: eventId, mode: 'user' });

    if (res.updatedEvent) {
      setEventData(res.updatedEvent);
      logEvent('create_rtmp_event', {
        eventId,
        roomName: currentUserRoom.title,
        category: 'live_stream_stats',
      });
    }
  };

  const setBattleMode = async () => {
    setStreamType('battle');
    setShowBattleModal(false);
    const res = await updateEvent({ id: eventId, mode: 'battle' });

    if (res.updatedEvent) {
      setEventData(res.updatedEvent);
      logEvent('create_battle_mode_event', {
        eventId,
        roomName: currentUserRoom.title,
        category: 'live_stream_stats',
      });
    }
  };

  const setDelayedLiveMode = async () => {
    setStreamType('delayedLive');
    setShowDelayedLiveModal(false);
    const res = await updateEvent({ id: eventId, mode: 'delayedLive' });

    if (res.updatedEvent) {
      setEventData(res.updatedEvent);
      logEvent('create_delayed_live_mode_event', {
        eventId,
        roomName: currentUserRoom.title,
        category: 'live_stream_stats',
      });
    }
  };

  const renderStreamParticipants = () => {
    if (
      startedStreaming &&
      eventData &&
      eventData.mode === 'battle' &&
      eventData.mixerEventUUID &&
      eventData.mixerEventModeratorAccessCode
    ) {
      return (
        <StreamParticipants
          eventId={eventId}
          eventUUID={eventData.mixerEventUUID}
          accessCode={eventData.mixerEventModeratorAccessCode}
        />
      );
    }
  };

  const renderBattleModePlayer = () => {
    if (!eventData) {
      return null;
    }

    if (!startedStreaming) {
      return null;
    }

    if (broadcasterIsLive && eventData.state !== 'ended') {
      return (
        <div className='col-span-6 col-start-4'>
          <StreamPlayer streamUrl={streamUrl} />
        </div>
      );
    }
  };

  const renderRtmpModePlayer = () => {
    if (!eventData) {
      return null;
    }

    if (rtmpIsReady && currentUserRoom && eventData.mode === 'user') {
      return (
        <div className='col-span-6 col-start-4'>
          <StreamPlayer streamUrl={currentUserRoom.ivsChannel.playbackUrl} />
        </div>
      );
    }
  };

  const renderBroadcastLoader = () => {
    if (!eventData) {
      return null;
    }

    if (!broadcasterIsLive && startedStreaming && eventData.state !== 'ended') {
      return (
        <div className='col-span-6 col-start-4 flex flex-col items-center justify-center'>
          <Spinner size='small' />
          <Heading headingSize={4}>
            Please wait while we prepare your stream
          </Heading>
        </div>
      );
    }
  };

  const stopStreaming = async () => {
    setStartedStreaming(false);

    const eventUUID = eventData?.mixerEventUUID || null;
    const accessCode = eventData?.mixerEventModeratorAccessCode || null;

    if (eventUUID && accessCode) {
      await leaveMeeting(
        { meetingId: meetingManager.meetingId },
        eventUUID,
        accessCode
      );
    }
  };

  return (
    <div className='col-span-full'>
      <Prompt
        when={eventData?.state === 'live'}
        message='Leaving the page will end the event. Are you sure you want to continue?'
      />
      {(eventData?.state === 'live' || eventData?.state === 'upcoming') && (
        <div className='col-span-full grid grid-cols-12 gap-4'>
          {renderBattleModePlayer()}
          {renderBroadcastLoader()}
          {renderRtmpModePlayer()}
          {renderStreamParticipants()}
        </div>
      )}
      <div className='col-span-full mt-8 px-8 py-10 shadow-card rounded-lg'>
        <div className='border border-t-0 border-l-0 border-r-0 border-secondaryB'>
          <TabButton
            label='Stream Info'
            onClick={() => setActiveTab('stream-info')}
            isActive={activeTab === 'stream-info'}
            className='mr-4'
          />
          <TabButton
            label='Gamification'
            onClick={() => setActiveTab('gamification')}
            isActive={activeTab === 'gamification'}
          />
        </div>
        {eventData && activeTab === 'stream-info' && (
          <div className='flex flex-col'>
            {currentUserRoom?.ivsChannel && (
              <div
                className={`mode-tab flex-col ${
                  !streamType ? 'not-selected' : ''
                } ${streamType === 'rtmp' ? 'selected' : ''}`}
                onClick={selectRtmpMode}
              >
                <div className='flex items-center my-4'>
                  <input
                    id='rtmp'
                    type='radio'
                    name='rtmp'
                    checked={streamType === 'rtmp'}
                    onChange={handleRadioChange}
                  />
                  <label
                    htmlFor='rtmp'
                    className='ml-2 mr-8 text-base font-display'
                  >
                    RTMP Solo
                  </label>
                </div>
                <div className='mode-tab-content'>
                  <RtmpTab inactive={streamType !== 'rtmp'} />
                </div>
              </div>
            )}
            {/* {
              <div
                className={`mode-tab flex-col ${
                  !streamType ? 'not-selected' : ''
                } ${streamType === 'battle' ? 'selected' : ''}`}
                onClick={selectBattleMode}
              >
                <div className='flex items-center my-4'>
                  <input
                    id='battle'
                    type='radio'
                    name='battle'
                    checked={streamType === 'battle'}
                    onChange={handleRadioChange}
                  />
                  <label
                    htmlFor='battle'
                    className='ml-2 mr-8 text-base font-display'
                  >
                    Battle Mode
                  </label>
                </div>
                <div className='mode-tab-content'>
                  <BattleModeTab
                    eventData={eventData}
                    eventState={eventData?.state || 'upcoming'}
                  />
                </div>
              </div>
            } */}
            {
              <div
                className={`mode-tab flex-col ${
                  !streamType ? 'not-selected' : ''
                } ${streamType === 'delayedLive' ? 'selected' : ''}`}
                onClick={selectDelayedLiveMode}
              >
                <div className='flex items-center my-4'>
                  <input
                    id='delayed-live'
                    type='radio'
                    name='delayed-live'
                    checked={streamType === 'delayedLive'}
                    onChange={handleRadioChange}
                  />
                  <label
                    htmlFor='delayed-live'
                    className='ml-2 mr-8 text-base font-display'
                  >
                    Delayed Live
                  </label>
                </div>
                <div className='mode-tab-content'>
                  <DelayedLiveTab
                    inactive={streamType !== 'delayedLive'}
                    eventId={eventData.id}
                    eventState={eventData.state}
                    roomId={eventData.hostSponsorRoomID}
                    eventStartTimestamp={eventData.startTimestamp}
                  />
                </div>
                <div className='bg-warningLight border border-warning rounded-lg p-4 mt-4'>
                  <Paragraph>
                    File cannot be larger than <b>5GB</b>
                  </Paragraph>
                  <Paragraph>
                    File type must be <b>mp4</b>, <b>avi</b> or <b>mpeg</b>
                  </Paragraph>
                </div>
              </div>
            }
          </div>
        )}
        {eventData && activeTab === 'gamification' && (
          <div className='mt-9'>
            <div className='items-center grid grid-cols-12 gap-4'>
              <div className='mr-8 label-base col-span-4'>
                Enable Gamification
              </div>
              <Switch
                checked={paused !== true}
                onChange={togglePaused}
                offColor='#6B7280'
                onColor='#40CB39'
                className='col-span-2'
                disabled={eventData?.state === 'ended'}
              />
            </div>
            <div className='grid grid-cols-12 gap-4 items-center'>
              <div className='mr-8 label-base col-span-4'>Enable Rounds</div>
              <Switch
                checked={roundsEnabled}
                onChange={toggleRoundsEnable}
                offColor='#6B7280'
                onColor='#40CB39'
                className='col-span-2'
                disabled={eventData?.state === 'ended'}
              />
              <div className='col-span-2'>
                <div
                  className={` border-2 px-2 py-2 ml-8 rounded-lg font-display font-medium text-sm flex items-center justify-center ${
                    roundsEnabled && eventData?.state !== 'ended'
                      ? 'border-secondaryA'
                      : 'border-secondaryB bg-secondaryC text-secondaryB'
                  }`}
                >
                  <button
                    className='mr-2'
                    disabled={!roundsEnabled || eventData?.state === 'ended'}
                    onClick={decreaseRounds}
                  >
                    -
                  </button>
                  <div>{rounds}</div>
                  <button
                    className='ml-2'
                    disabled={!roundsEnabled || eventData?.state === 'ended'}
                    onClick={increaseRounds}
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className='col-span-12 flex items-center justify-end mt-4'>
          {eventData && (
            <EventControls
              eventState={eventData.state}
              isStreaming={startedStreaming}
              eventMode={eventData.mode}
              broadcasterIsLive={broadcasterIsLive}
              isEnding={ending}
              onStartStreaming={() => setStartedStreaming(true)}
              onStopStreaming={stopStreaming}
              onGoLive={goLive}
              onEndEvent={endEvent}
            />
          )}
        </div>
      </div>
      <Modal
        title='Warning!'
        actions={[
          <Button
            key='disapprove-rtmp'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={() => setShowRTMPModal(false)}
            classes='mr-4'
          >
            No
          </Button>,
          <Button
            key='approve-rtmp'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={setRtmpMode}
          >
            Yes
          </Button>,
        ]}
        isVisible={showRTMPModal}
        handleClose={() => setShowRTMPModal(false)}
      >
        <Paragraph>
          Are you sure you want to set RTMP Solo Mode for this event? This
          change cannot be reversed!
        </Paragraph>
      </Modal>
      <Modal
        title='Warning!'
        actions={[
          <Button
            key='disapprove-battle'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={() => setShowBattleModal(false)}
            classes='mr-4'
          >
            No
          </Button>,
          <Button
            key='approve-battle'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={setBattleMode}
          >
            Yes
          </Button>,
        ]}
        isVisible={showBattleModal}
        handleClose={() => setShowBattleModal(false)}
      >
        <Paragraph>
          Are you sure you want to set Battle Mode for this event? This change
          cannot be reversed!
        </Paragraph>
      </Modal>
      <Modal
        title='Warning!'
        actions={[
          <Button
            key='disapprove-delayed'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={() => setShowDelayedLiveModal(false)}
            classes='mr-4'
          >
            No
          </Button>,
          <Button
            key='approve-delayed'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={setDelayedLiveMode}
          >
            Yes
          </Button>,
        ]}
        isVisible={showDelayedLiveModal}
        handleClose={() => setShowDelayedLiveModal(false)}
      >
        <Paragraph>
          Are you sure you want to set Delayed Live Mode for this event? This
          change cannot be reversed!
        </Paragraph>
      </Modal>
    </div>
  );
};

export default StreamInformation;
