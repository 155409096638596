/* eslint-disable prettier/prettier */
import { RootState } from 'store/config';

import { State } from './reducer';

const getPpvEarningsState = (state: RootState): State => state.ppvPassEarnings;
export const selectPpvEarnings = (state: RootState) => getPpvEarningsState(state).earnings;
export const selectPpvEarningsAreLoading = (state: RootState) => getPpvEarningsState(state).loading;
export const selectPpvEarningsNextToken = (state: RootState) => getPpvEarningsState(state).nextToken;
export const selectPpvEarningsHasError = (state: RootState) => getPpvEarningsState(state).hasError;

