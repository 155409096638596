import React, { useState, useEffect, useContext } from 'react';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const RoomGroup = ({ group, all }) => {
  const [rooms, setRooms] = useState([]);

  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const { getRoomsByCategorisationID } = sponsorRoomsContext;

  const loadRooms = async () => {
    const { success, rooms } = await getRoomsByCategorisationID(group.id);

    if (!success) {
      return [];
    }

    setRooms(rooms);

    return rooms;
  };

  useEffect(() => {
    loadRooms();
  }, []);

  return (
    <Link
      to={routes.groups
        .replace(':categoryId', group.id)
        .replace(':tab', 'creators')}
      className='col-span-3'
    >
      <div
        className='px-4 rounded-lg relative h-full'
        style={{
          backgroundImage: `url(${group?.cover?._url || ''})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div
          className='absolute inset-0 transform -rotate-180 rounded-lg'
          style={{
            backgroundImage:
              'linear-gradient(to bottom, #180044 -4%, rgba(0, 0, 0, 0) 140%)',
          }}
        ></div>
        <div className='relative row-group-padding' style={{ height: '60%' }}>
          <Heading headingSize={5} classes='text-white relative z-10'>
            {group.title}
          </Heading>
          {group.promotionTitle && (
            <h6 className='font-display text-xs text-white font-medium relative z-10 truncate'>
              {group.promotionTitle}
            </h6>
          )}
          <div className='flex absolute bottom-2 z-10'>
            <div
              className='mr-2 border rounded-full h-3 w-3 flex justify-end'
              style={{ borderColor: '#e4d5ff' }}
            >
              <Icon
                name={'three-clock'}
                size={6}
                color={'#e4d5ff'}
                classes='font-medium '
              />
            </div>
            <h6 className='font-display text-primaryALight text-xxs font-medium '>
              {'Starts ' +
                new Intl.DateTimeFormat('en-GB', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                }).format(new Date(group.createdAt))}
            </h6>
          </div>
        </div>
        <div className='relative' style={{ height: '40%' }}>
          <div className='h-px bg-secondaryA -mx-4 opacity-50' />
          <p className='font-display text-xs font-medium text-primaryALight mt-4 mb-2 relative z-10'>
            Creators
          </p>
          <div className='relative w-full h-8 z-10'>
            {rooms.map((room, i) => {
              const restCount = rooms.length - 5;

              if (i === 6 && restCount) {
                return (
                  <div
                    key={i}
                    style={{ left: 5 * 24 }}
                    className='absolute w-8 h-8 flex items-center justify-center rounded-full bg-primaryA border-2 border-primaryADark'
                  >
                    <span className='text-xs font-display text-white font-medium'>
                      {restCount > 10 ? '10+' : restCount}
                    </span>
                  </div>
                );
              }

              if (i > 4) {
                return null;
              }

              return (
                <div
                  key={room.id}
                  style={{ left: i * 24 }}
                  className='absolute w-8 h-8 rounded-full bg-red-300 border-2 border-primaryADark'
                >
                  {room?.image?.key && (
                    <img
                      src={`${imgBaseUrl}${room.image.key}?w=200`}
                      className='w-full h-full object-cover rounded-full'
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default RoomGroup;
