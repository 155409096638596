import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Input from '../form/Input';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import Button from '../common/Button';

import { UserContext } from '../../context/user';
import { AlertsContext } from '../../context/alerts';

const getLabelAndValue = (type, user) => {
  switch (type) {
    case 'displayName':
      return { label: 'User Name', value: user?.displayName?.trim() || '' };
    case 'location':
      return { label: 'Location', value: user?.location?.trim() || '' };
    case 'fullName':
      return { label: 'Full Name', value: user?.fullName?.trim() || '' };
    default:
      return { label: '', value: '' };
  }
};

const ProfileDetailsEdit = ({ type }) => {
  const [showEditField, setShowEditField] = useState(false);

  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);

  const { user, updateCurrentUser } = userContext;
  const { addAlert } = alertsContext;

  const userData = user?.dbData?.data?.getUser || {};
  const { label, value } = getLabelAndValue(type, userData);

  const handleOnClick = () => {
    setShowEditField(true);
  };

  const saveProfile = async (value) => {
    const result = await updateCurrentUser({
      [type]: value,
    });

    if (!result.success) {
      addAlert('danger', result.error);
      return;
    }

    setShowEditField(false);
  };

  const handleSubmit = () => {
    const newValue =
      document.getElementById(`userPropertyInput-${type}`)?.value?.trim() || '';

    if (type === 'displayName' && newValue.length === 0) {
      setShowEditField(false);
      return;
    }

    if (newValue.length === 0 || newValue === value) {
      setShowEditField(false);
      return;
    }

    saveProfile(newValue);
  };

  const renderInputBox = () => {
    return (
      <div>
        <div className='mb-16'>
          <button onClick={() => setShowEditField(false)}>
            <Icon
              color='#5200e9'
              name='back'
              size={12}
              classes='align-middle'
            />
          </button>
          <Heading
            headingSize={6}
            classes='inline-block ml-9 font-bold text-primaryC tracking-tight'
          >
            {label}
          </Heading>
        </div>
        <Input
          id={`userPropertyInput-${type}`}
          type='text'
          name={type}
          initialValue={value}
          placeholder={label}
          wrapClasses='mb-28'
          maxLength={type === 'displayName' ? 30 : undefined}
        />
        <Button
          buttonId='done'
          buttonType='secondary'
          buttonSize='medium'
          buttonOnClick={() => handleSubmit()}
          isLink={false}
          classes='relative transform left-1/2 -translate-x-1/2 inline-blok w-52'
          disabled={false}
        >
          Done
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className='mb-7 pb-4 border-b-2 border-secondaryB'>
        <p className='mb-4 text-sm font-display font-medium leading-5 tracking-tight text-primaryC'>
          {label}
        </p>
        <div className='flex justify-between items-center'>
          <p className='text-sm font-display font-bold leading-5 tracking-tight text-primaryC'>
            {!value ? 'Not added' : value}
          </p>
          <button
            className='font-display text-base text-primaryA leading-6 tracking-tight'
            onClick={handleOnClick}
          >
            {!value ? 'Add' : 'Edit'}
          </button>
        </div>
      </div>
      <div
        className={`profile-details-edit-box ${showEditField ? 'show' : ''}`}
      >
        {renderInputBox()}
      </div>
    </>
  );
};

ProfileDetailsEdit.propTypes = {
  type: PropTypes.oneOf(['displayName', 'location', 'fullName']),
};

export default ProfileDetailsEdit;
