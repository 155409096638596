import React, { Component } from 'react';
import { LeftDrawerContext } from './leftDrawer';

export default class LeftDrawerProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventId: null,
      eventPreviewIsOpen: false,
      userAvatarsAreOpen: false,
      myGoodsIsOpen: false,
      toggleEventPreview: this.toggleEventPreview,
      toggleUserAvatars: this.toggleUserAvatars,
      toggleMyGoods: this.toggleMyGoods,
      getActiveDrawer: this.getActiveDrawer,
    };
  }

  getActiveDrawer = () => {
    if (this.state.eventPreviewIsOpen) {
      return 'eventPreview';
    }

    if (this.state.userAvatarsAreOpen) {
      return 'userAvatars';
    }

    if (this.state.myGoodsIsOpen) {
      return 'myGoods';
    }

    return null;
  };

  toggleEventPreview = (eventId) => {
    this.setState({
      eventPreviewIsOpen: !this.state.eventPreviewIsOpen,
      eventId,
    });
  };

  toggleUserAvatars = () => {
    this.setState({ userAvatarsAreOpen: !this.state.userAvatarsAreOpen });
  };

  toggleMyGoods = () => {
    this.setState({ myGoodsIsOpen: !this.state.myGoodsIsOpen });
  };

  render() {
    return (
      <LeftDrawerContext.Provider value={this.state}>
        {this.props.children}
      </LeftDrawerContext.Provider>
    );
  }
}
