import React from 'react';
import Button from '../common/Button';

const ChangeAttributeBox = ({
  containerClasses,
  type,
  title,
  user,
  showEditInput,
}) => {
  if (!type || !user) {
    return null;
  }

  let attribute = '';
  let status = 'Not active';
  let buttonLabel = '';

  if (type === 'email') {
    attribute = 'email';
    buttonLabel = 'Change Email';

    if (user?.attributes?.['email_verified'] === 'true') {
      status = 'Active';
    }
  }

  if (type === 'phone') {
    attribute = 'phone_number';
    buttonLabel = 'Change Phone';

    if (user?.attributes?.['phone_number_verified'] === 'true') {
      status = 'Active';
    }
  }

  const currentValue = user?.attributes?.[attribute] || '';

  return (
    <div className={containerClasses}>
      <p className='mb-4 text-sm font-display font-medium leading-5 tracking-tight text-primaryC'>
        {title}
      </p>
      <div className='flex justify-between mb-11 items-center'>
        <p className='flex flex-wrap justify-between items-center text-sm font-display font-bold leading-5 tracking-tight text-primaryC'>
          <span className='inline-block mb-2 mr-20 xl:mr-24 2xl:mb-0'>
            {!currentValue ? `No ${type} added` : currentValue}
          </span>
          <span
            className={`p-2 text-xs leading-4 font-display text-white rounded-full ${
              status === 'Active' ? 'bg-positive' : 'bg-secondaryB'
            }`}
          >
            {status}
          </span>
        </p>
      </div>
      <Button
        buttonId={`${attribute}-input`}
        buttonType='secondary'
        buttonSize='medium'
        isLink={false}
        classes='w-52'
        disabled={false}
        buttonOnClick={showEditInput}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

export default ChangeAttributeBox;
