import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { StickyCommentsContext } from './stickyComments';
import { stickyMessageByEventId } from '../graphql/queries';
import {
  createEventStickyMessage,
  updateEventStickyMessage,
} from '../graphql/mutations';
import { logError } from '../helpers';

export default class StickyCommentsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stickyComment: null,
      loading: false,
      error: '',
      loadStickyCommentByEventId: this.loadStickyCommentByEventId,
      createOrUpdateStickyComment: this.createOrUpdateStickyComment,
      clear: this.clear,
    };
  }

  clear = () => {
    this.setState({ stickyComment: null });
  };

  loadStickyCommentByEventId = async (eventId) => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(
        graphqlOperation(stickyMessageByEventId, { eventID: eventId })
      );

      const items = res?.data?.stickyMessageByEventId?.items || [];

      this.setState({
        stickyComment: items[0] || null,
        loading: false,
        error: '',
      });
    } catch (error) {
      logError(error);
      this.setState({
        stickyComment: null,
        error: 'Error loading sticky comment',
        loading: false,
      });
    }
  };

  createStickyComment = async (eventId, userId, commentData) => {
    try {
      const result = await API.graphql(
        graphqlOperation(createEventStickyMessage, {
          input: {
            eventID: eventId,
            moderatorID: userId,
            text: commentData.text,
            originalCommentDisplayName: commentData.commentUserName,
            originalCommentTime: commentData.commentCreatedAt,
          },
        })
      );

      this.setState({
        stickyComment: result.data.createEventStickyMessage,
      });
    } catch (error) {
      logError(error);
      this.setState({
        error: 'Error creating sticky comment',
      });
    }
  };

  updateStickyComment = async (id, eventId, userId, commentData) => {
    try {
      const result = await API.graphql(
        graphqlOperation(updateEventStickyMessage, {
          input: {
            id,
            eventID: eventId,
            moderatorID: userId,
            text: commentData.text,
            originalCommentDisplayName: commentData.commentUserName,
            originalCommentTime: commentData.commentCreatedAt,
          },
        })
      );

      this.setState({
        stickyComment: result.data.updateEventStickyMessage,
      });
    } catch (error) {
      logError(error);
      this.setState({
        error: 'Error updating sticky comment',
      });
    }
  };

  createOrUpdateStickyComment = async (eventId, userId, commentData) => {
    const res = await API.graphql(
      graphqlOperation(stickyMessageByEventId, { eventID: eventId })
    );

    const items = res?.data?.stickyMessageByEventId?.items || [];
    const stickyComment = items[0];
    if (!stickyComment) {
      this.createStickyComment(eventId, userId, commentData);
      return;
    }

    this.updateStickyComment(stickyComment.id, eventId, userId, commentData);
  };

  render() {
    return (
      <StickyCommentsContext.Provider value={this.state}>
        {this.props.children}
      </StickyCommentsContext.Provider>
    );
  }
}
