import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Button from '../common/Button';
import Heading from '../typography/Heading';
import Spinner from '../system/Spinner';
import Badge from '../common/Badge';

import { PollsContext } from '../../context/polls';

const getPercentageValue = (votes, poll) => {
  if (!votes) {
    return 0;
  }

  const allVotesCount =
    poll.scoreAnswerA + poll.scoreAnswerB + poll.scoreAnswerC;

  return ((votes / allVotesCount) * 100).toFixed(2);
};

const PollResults = ({
  pollData,
  archive,
  className,
  showArhiveButton,
  maxTextLength,
  showStatus,
}) => {
  const pollsContext = useContext(PollsContext);
  const { setPollSubscriptionById } = pollsContext;

  const [poll, setPoll] = useState(pollData);
  const [archiving, setArchiving] = useState(false);
  const [pollUpdateSub, setPollUpdateSub] = useState(null);

  const handlePollUpdate = (updatedPoll) => {
    setPoll(updatedPoll);

    if (updatedPoll.status === 'archived' && pollUpdateSub) {
      pollUpdateSub.unsubscribe();
      setPollUpdateSub(null);
    }
  };

  useEffect(async () => {
    if (poll.status === 'active') {
      const sub = await setPollSubscriptionById(poll.id, handlePollUpdate);
      setPollUpdateSub(sub);
    }

    return () => {
      if (pollUpdateSub) {
        pollUpdateSub.unsubscribe();
      }
    };
  }, []);

  const archivePoll = async () => {
    setArchiving(true);
    archive(poll.id);
  };

  const truncate = (string, length = maxTextLength) => {
    if (string.length > length) {
      return `${string.substring(0, length)}...`;
    }

    return string;
  };

  return (
    <div className={`rounded-lg p-4 w-full relative ${className}`}>
      {archiving && (
        <div className='absolute flex inset-0 items-center justify-center bg-white bg-opacity-50 z-10'>
          <Spinner size='medium' />
        </div>
      )}
      {showStatus && (
        <Badge badgeType={poll.status === 'active' ? 'live' : 'archived'}>
          {poll.status}
        </Badge>
      )}
      <Heading headingSize={5} classes='my-4'>
        {poll.question}
      </Heading>
      {poll.answerA && (
        <div className='quiz-progress-container'>
          <div className='quiz-progress-text'>
            {truncate(poll.answerA)} -{' '}
            {getPercentageValue(poll.scoreAnswerA, poll)}%
          </div>
        </div>
      )}
      {poll.answerB && (
        <div className='quiz-progress-container'>
          <div className='quiz-progress-text'>
            {truncate(poll.answerB)} -{' '}
            {getPercentageValue(poll.scoreAnswerB, poll)}%
          </div>
        </div>
      )}
      {poll.answerC && (
        <div className='quiz-progress-container'>
          <div className='quiz-progress-text'>
            {truncate(poll.answerC)} -{' '}
            {getPercentageValue(poll.scoreAnswerC, poll)}%
          </div>
        </div>
      )}
      {showArhiveButton && poll.status === 'active' && (
        <div className='flex justify-end'>
          <Button
            buttonSize='small'
            buttonType='primary'
            buttonOnClick={archivePoll}
          >
            {archiving ? 'Archiving...' : 'Archive'}
          </Button>
        </div>
      )}
    </div>
  );
};

PollResults.propTypes = {
  pollData: PropTypes.object,
  archive: PropTypes.func,
  className: PropTypes.string,
  showArhiveButton: PropTypes.bool,
  maxTextLength: PropTypes.number,
  showStatus: PropTypes.bool,
};

PollResults.defaultProps = {
  archive: () => {},
  className: '',
  showArhiveButton: true,
  maxTextLength: 35,
  showStatus: true,
};

export default PollResults;
