import { useContext, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { UserContext } from '../context/user';
import { eventProfitsByUserId, eventProfitByEventId } from '../graphql/queries';
import { logError } from '../helpers';

const useEventProfits = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState(null);
  const userContext = useContext(UserContext);
  const { getCurrentUserId } = userContext;

  const getEventProfitByEventId = async (eventID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(eventProfitByEventId, { eventID })
      );

      return {
        success: true,
        profit: res.data.eventProfitByEventId.items?.[0] || null,
      };
    } catch (error) {
      logError('getEventProfitByEventId', error);
      return { success: false, profit: null };
    }
  };

  const getEventProfitByUserId = async () => {
    setLoading(true);
    const userID = getCurrentUserId();

    if (!userID) {
      logError('getEventProfitByUserId', 'No user id');
      setLoading(false);
      setNextToken(null);
      return { success: false, data: [] };
    }

    try {
      const res = await API.graphql(
        graphqlOperation(eventProfitsByUserId, { userID, limit: 100 })
      );

      setData(res.data.eventProfitsByUserId.items);
      setNextToken(res.data.eventProfitsByUserId.nextToken);
      setLoading(false);
      return {
        success: true,
        data: res.data.eventProfitsByUserId.items,
      };
    } catch (error) {
      logError('getEventProfitByUserId', error);
      setLoading(false);
      setNextToken(null);
      return { success: false, data: [] };
    }
  };

  const loadMoreByUserId = async () => {
    setLoading(true);
    const userID = getCurrentUserId();

    try {
      const res = await API.graphql(
        graphqlOperation(eventProfitsByUserId, {
          userID,
          nextToken,
          limit: 100,
        })
      );

      setData([...data, ...res.data.eventProfitsByUserId.items]);
      setNextToken(res.data.eventProfitsByUserId.nextToken);
      setLoading(false);
      return {
        success: true,
        data: res.data.eventProfitsByUserId.items,
      };
    } catch (error) {
      logError('getEventProfitByUserId', error);
      setLoading(false);
      setNextToken(null);
      return { success: false, data: [] };
    }
  };

  return {
    data,
    nextToken,
    loading,
    getEventProfitByUserId,
    loadMoreByUserId,
    getEventProfitByEventId,
  };
};

export default useEventProfits;
