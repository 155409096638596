import React from 'react';
import Paragraph from '../typography/Paragraph';
import Heading from '../typography/Heading';

const InfoBox = () => {
  return (
    <div className='shadow-card rounded-lg col-span-full md:col-span-7 p-6'>
      <Paragraph classes='mb-6'>
        {
          'Please fill out the information below to receive a payout. Your information is stored for internal use only and will not be shared with anyone'
        }
      </Paragraph>
      <Heading headingSize={6} classes='mb-6'>
        1 coin = $0.006, and the revenue share with Clash TV is 50%.
      </Heading>
      <Paragraph classes='mb-6'>
        Your total balance in USD is shown already with the revenue share
        deducted.
      </Paragraph>
      <Paragraph>
        Once you click the button to Withdraw, the money will be transferred to
        you in a maximum of 7 days.
      </Paragraph>
    </div>
  );
};

export default InfoBox;
