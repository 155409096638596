import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from '../system/Modal';
import Button from '../common/Button';
import Heading from '../typography/Heading';

import { EventsContext } from '../../context/events';
import { InvitationsContext } from '../../context/invitations';
import { UserContext } from '../../context/user';

const InvitationDialog = ({ invitation, isVisible, onDecline, onAccept }) => {
  const [event, setEvent] = useState(null);
  const [creator, setCreator] = useState(null);

  const invitationsContext = useContext(InvitationsContext);
  const eventsContext = useContext(EventsContext);
  const userContext = useContext(UserContext);

  const { updateInvitation } = invitationsContext;
  const { getCurrentEvent } = eventsContext;
  const { getUserDataFromDBById } = userContext;

  useEffect(async () => {
    const eventData = await getCurrentEvent(invitation.eventID);
    const creatorData = await getUserDataFromDBById(invitation.creatorID);

    setEvent(eventData);
    setCreator(creatorData);
  }, []);

  const handleDeclineInvitaiton = async () => {
    await updateInvitation(invitation.id, { status: 'declined' });
    onDecline(invitation.id);
  };

  const handleAcceptInvitatian = async () => {
    await updateInvitation(invitation.id, { status: 'accepted' });
    onAccept(invitation.id);
  };

  if (!event || !creator) {
    return null;
  }

  return (
    <Modal
      title='Invitation'
      actions={[
        <Button
          key='decline'
          buttonType='secondary'
          buttonSize='medium'
          buttonOnClick={handleDeclineInvitaiton}
          classes='mr-4'
        >
          Decline
        </Button>,
        <Button
          key='accept'
          buttonType='primary'
          buttonSize='medium'
          buttonOnClick={handleAcceptInvitatian}
        >
          Accept
        </Button>,
      ]}
      isVisible={isVisible}
      handleClose={handleDeclineInvitaiton}
    >
      <Heading headingSize={4} classes='mt-8'>
        {`${creator.displayName} is inviting you to join battle - ${event.contestorA} vs ${event.contestorB}`}
      </Heading>
      <div className='flex flex-col font-paragraph text-base mt-16'></div>
    </Modal>
  );
};

InvitationDialog.propTypes = {
  isVisible: PropTypes.bool,
  invitation: PropTypes.object,
  onDecline: PropTypes.func,
  onAccept: PropTypes.func,
};

InvitationDialog.defaultProps = {
  isVisible: false,
  onDecline: () => {},
  onAccept: () => {},
};

export default InvitationDialog;
