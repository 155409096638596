import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CardSkeleton = () => {
  return (
    <div className='shadow-card rounded-lg col-span-3 p-4'>
      <Skeleton duration={2} height={160} width='100%' className='mb-4' />
      <Skeleton duration={2} height={12} width='60%' className='mb-2' />
      <Skeleton duration={2} height={12} width='80%' className='mb-2' />
      <Skeleton duration={2} height={12} width='70%' />
      <div className='flex align-middle mb-4'>
        <Skeleton
          duration={2}
          height={30}
          width={69}
          className='mr-4 rounded-lg'
        />
        <Skeleton duration={2} height={30} width={69} className='rounded-lg' />
      </div>
    </div>
  );
};

const CardSkeletonWithRef = React.forwardRef((props, ref) => (
  <div ref={ref} className='shadow-card rounded-lg col-span-3 p-4'>
    <Skeleton duration={2} height={160} width='100%' className='mb-4' />
    <Skeleton duration={2} height={12} width='60%' className='mb-2' />
    <Skeleton duration={2} height={12} width='80%' className='mb-2' />
    <Skeleton duration={2} height={12} width='70%' />
    <div className='flex align-middle mb-4'>
      <Skeleton
        duration={2}
        height={30}
        width={69}
        className='mr-4 rounded-lg'
      />
      <Skeleton duration={2} height={30} width={69} className='rounded-lg' />
    </div>
  </div>
));

CardSkeletonWithRef.displayName = 'CardSkeletonWithRef';

export default CardSkeleton;
export { CardSkeletonWithRef };
