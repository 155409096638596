import React, { useContext, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

import { UserContext } from '../../context/user';
import { AvatarsContext } from '../../context/avatars';
import { InventoryContext } from '../../context/inventory';

import Heading from '../typography/Heading';
import Avatar from '../common/Avatar';

import history from '../../history';
import routes from '../../routes';

const Avatars = () => {
  const userContext = useContext(UserContext);
  const avatarsContext = useContext(AvatarsContext);
  const inventoryContext = useContext(InventoryContext);

  const { updateCurrentUser, setUserImageUrl } = userContext;
  const { avatars, loadAvatars, loading, setSelectedAvatar } = avatarsContext;
  const { inventory } = inventoryContext;

  useEffect(async () => {
    await loadAvatars();
  }, []);

  const chooseAvatar = async (avatar) => {
    if (history.location.pathname === routes.completeProfile) {
      setSelectedAvatar(avatar.image, null, avatar.image._url);
      return;
    }

    try {
      await updateCurrentUser({
        image: avatar.image,
      });
      setUserImageUrl(avatar.image._url);
    } catch (err) {
      console.log(err);
    }
  };

  const avatarIsInInventory = (avatarId) => {
    return inventory?.[avatarId];
  };

  const renderAvatars = () => {
    if (loading) {
      return [
        <Skeleton
          key={1}
          circle={true}
          height={79}
          width={79}
          className='mb-6 mx-3'
        />,
        <Skeleton
          key={2}
          circle={true}
          height={79}
          width={79}
          className='mb-6 mx-3'
        />,
        <Skeleton
          key={3}
          circle={true}
          height={79}
          width={79}
          className='mb-6 mx-3'
        />,
      ];
    }

    return avatars.map((avatar) => {
      if (avatar.free || avatarIsInInventory(avatar.id)) {
        return (
          <button
            key={avatar.id}
            onClick={() => chooseAvatar(avatar)}
            className={`round-bt-container relative mb-6 mx-3`}
          >
            <div className='avatar-ring'></div>
            <div className='round-bt'>
              <Avatar
                imgSrc={avatar?.image?._url}
                imgAlt={`avatar-ring avatar-${avatar.id}`}
                imgSize={4}
              />
            </div>
          </button>
        );
      }

      return null;
    });
  };

  return (
    <div className='flex flex-col items-center justify-center h-full'>
      <Heading headingSize={5} classes='mb-8 text-primaryC text-center'>
        Select Avatar
      </Heading>
      <div className='flex justify-center flex-wrap'>{renderAvatars()}</div>
    </div>
  );
};

export default Avatars;
