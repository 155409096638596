import React, { useContext, useEffect, useState } from 'react';

import Badge from '../common/Badge';
import BuyEventModal from '../dashboard/BuyEventModal';

import { TransactionsContext } from '../../context/transactions';
import { UserContext } from '../../context/user';

const PPVBadge = ({
  eventId,
  eventTitle,
  isPPV,
  price,
  eventCreatorId,
  onBuy = () => {},
  eventState,
}) => {
  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);

  const { getCurrentUserId } = userContext;
  const { loadPPVEvent, myPPVList } = transactionsContext;

  const [buyModalData, setBuyModalData] = useState(null);

  const loadStatus = async () => {
    if (isPPV) {
      await loadPPVEvent(getCurrentUserId(), eventId);
    }
  };

  useEffect(() => loadStatus(), []);

  const getBadgeData = () => {
    if (isPPV && price) {
      const eventIsCreatedByCurrentUser = eventCreatorId === getCurrentUserId();

      if (eventIsCreatedByCurrentUser) {
        return { type: 'recap', label: 'OWN' };
      }

      if (isPurchased) {
        return { type: 'succeeded', label: 'PURCHASED' };
      }

      return {
        type: 'price',
        label: `BUY NOW FOR ${price} coins`,
        onClick: onPriceClick,
      };
    }

    return null;
  };

  const onPriceClick = (e) => {
    e.stopPropagation();
    setBuyModalData({ price, title: eventTitle, id: eventId });
  };

  const isPurchased = myPPVList[eventId];
  const badgeData = getBadgeData();

  if (!badgeData) {
    return null;
  }

  return (
    <>
      <Badge
        badgeType={badgeData.type}
        classes='self-start mr-4 cursor-pointer'
        onClick={badgeData.onClick || null}
      >
        {badgeData.label}
      </Badge>
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
        onBuy={onBuy}
      />
    </>
  );
};

export default PPVBadge;
