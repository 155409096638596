import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';

import { NotificationsContext } from './notifications';

import { getUserNotifications } from '../graphql/customQueries';
import { onNotificationsUpdate } from '../graphql/customSubscriptions';
import { updateUserNotifications } from '../graphql/customMutations';
import { logError } from '../helpers';
import { logEvent } from '../firebase';

export default class AlertsProvider extends Component {
  constructor(props) {
    super(props);

    this.notificationsSubscription = null;

    this.state = {
      notifications: [],
      loadNotifications: this.loadNotifications,
      markAsRead: this.markAsRead,
      subscribeToNotifications: this.subscribeToNotifications,
      clearNotifications: this.clearNotifications,
      error: null,
    };
  }

  subscribeToNotifications = async (userId) => {
    this.notificationsSubscription = await API.graphql(
      graphqlOperation(onNotificationsUpdate, {
        id: userId,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        await this.loadNotifications(userId);
      },
      error: (error) => logError(error),
    });
  };

  loadNotifications = async (userId) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getUserNotifications, { id: userId })
      );

      const notifications = res?.data?.getUser?.notificationsArray || [];
      if (
        notifications.length &&
        notifications.some((not) => not.read === false)
      ) {
        notifications.forEach((notification) => {
          if (!notification.read) {
            logEvent('app_notification_received', {
              category: 'consumer',
              app_notification_title: notification.message,
            });
          }
        });
        const state = document.visibilityState;
        if (state === 'visible') {
          logEvent('notification_foreground', { category: 'consumer' });
        }
      }
      this.setState({ notifications });
    } catch (error) {
      logError('Error loading notifications');
      this.setState({ notifications: [], error });
    }
  };

  markAsRead = async (userId) => {
    const notifications = this.state.notifications.map((n) => {
      if (!n.read) {
        n.read = true;
        logEvent('app_notification_opened', {
          category: 'consumer',
          app_notification_title: n.message,
        });
      }

      return n;
    });

    this.setState({ notifications });

    try {
      await API.graphql(
        graphqlOperation(updateUserNotifications, {
          id: userId,
          notificationsArray: notifications,
        })
      );
    } catch (error) {
      logError('Error when marking notifications as read');
    }
  };

  clearNotifications = async (userId) => {
    this.setState({ notifications: [] });
    logEvent('notification_dismiss', { category: 'consumer' });

    try {
      await API.graphql(
        graphqlOperation(updateUserNotifications, {
          id: userId,
          notificationsArray: [],
        })
      );
    } catch (error) {
      logError('Error clearing notifications');
    }
  };

  componentWillUnmount() {
    if (this.notificationsSubscription) {
      this.notificationsSubscription.unsubscribe();
    }
  }

  // componentDidMount() {
  //   this.setOnMessageListener();
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   this.setOnMessageListener();
  // }

  // setOnMessageListener = () => {
  //   onMessageListener()
  //     .then((payload) => {
  //       this.loadNotifications(this.state.userId);
  //       // refactor with new notification data

  //       // this.setState({
  //       //   notifications: [
  //       //     {
  //       //       message: payload.notification.body,
  //       //       read: false,
  //       //       date: new Date(parseInt(payload.from)),
  //       //     },
  //       //     ...this.state.notifications,
  //       //   ],
  //       // });
  //     })
  //     .catch((err) => console.log('failed: ', err));
  // };

  render() {
    return (
      <NotificationsContext.Provider value={this.state}>
        {this.props.children}
      </NotificationsContext.Provider>
    );
  }
}
