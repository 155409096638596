import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';

import { store } from 'store/config';

import reportWebVitals from './reportWebVitals';
import App from './App';
import './index.css';

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
