import React, { useState } from 'react';
import PropTypes from 'prop-types';

import NotificationsItem from './NotificationsItem';
import Icon from '../typography/Icon';
import ClickawayListener from '../system/ClickawayListener';
import Heading from '../typography/Heading';
import Paragraph from '../typography/Paragraph';
import Button from '../common/Button';

const NotificationsDropdown = ({
  children,
  items,
  classes,
  showArrow,
  buttonClasses,
  containerClasses,
  updateNotifications,
  clear,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClickOutside = () => {
    setIsOpen(false);
    updateNotifications();
  };

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const renderNotifications = () => {
    if (items.length === 0) {
      return (
        <div className='notifications-container'>
          <div className='notifications-empty'>
            <Icon name='bell' size={28} color='#5200E9' />
            <Heading
              headingSize={5}
              classes='whitespace-nowrap mt-4 mb-4 text-primaryA'
            >
              Notifications
            </Heading>
            <hr className='w-full bg-black' />
            <Paragraph classes='whitespace-nowrap mb-20 mt-4 text-secondaryA'>
              Find important announcements for events relevant to you
            </Paragraph>
          </div>
        </div>
      );
    }

    return (
      <div className='notifications-container'>
        <div className='notifications-full'>
          <div className='notifications-header'>
            <Icon name='bell' size={20} color='#5200E9' />
            <Heading
              headingSize={5}
              classes='whitespace-nowrap ml-4 mt-4 mb-4 text-primaryA w-full'
            >
              Notifications
            </Heading>
            <Button
              buttonOnClick={clear}
              buttonSize='small'
              buttonType='secondary'
            >
              Clear
            </Button>
          </div>
          <hr className='w-full bg-black' />
          <div className='notifications-list w-full'>
            {items.map((item) => (
              <NotificationsItem
                key={new Date(item.createdAt * 1000).getTime()}
                message={item.message}
                isRead={item.read}
                date={item.createdAt}
                openUrl={item.openUrl}
                close={handleClickOutside}
              />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const containerClassNames = containerClasses || 'relative inline-block';

  const buttonClassNames =
    buttonClasses || 'flex items-center inline-block focus:outline-none';

  return (
    <div className={containerClassNames}>
      <button className={buttonClassNames} onClick={handleOpen}>
        {children}
        {showArrow && (
          <Icon
            name={isOpen ? 'selectorUp' : 'selector'}
            size={10}
            classes='inline-block ml-2'
            color='#5200e9'
          />
        )}
      </button>
      {isOpen && (
        <ClickawayListener onClick={handleClickOutside}>
          {renderNotifications()}
        </ClickawayListener>
      )}
    </div>
  );
};

NotificationsDropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  classes: PropTypes.string,
  showArrow: PropTypes.bool,
  items: PropTypes.array,
};

NotificationsDropdown.defaultProps = {
  classes: '',
  showArrow: true,
  items: [],
};

export default NotificationsDropdown;
