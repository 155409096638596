import React from 'react';
import Paragraph from '../typography/Paragraph';

const RoomAbout = ({ room }) => {
  if (!room) {
    return null;
  }

  return (
    <div className='pt-8 mb-4'>
      <Paragraph paragraphSize='large' classes='mb-8'>
        {room.description}
      </Paragraph>
      {room.merchUrl && (
        <Paragraph paragraphSize='large'>
          <a
            href={room.merchUrl}
            target='_blank'
            rel='noreferrer'
            className='font-semibold'
          >
            {room.merchUrl}
          </a>
        </Paragraph>
      )}
    </div>
  );
};

export default RoomAbout;
