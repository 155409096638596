import React, { useRef, useState, useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import screenfull from 'screenfull';

import Heading from '../typography/Heading';
import Paragraph from '../typography/Paragraph';
import Button from '../common/Button';
import VideoControls from './VideoControls';
import VideoPlayer from '../VideoPlayer';
import Icon from '../typography/Icon';
import ReactTooltip from 'react-tooltip';
import useIMA from '../../hooks/useIMA';
import { logEvent } from '../../firebase';

import { logError } from '../../helpers';

const EndedEventVideoTab = ({
  setActiveTab,
  videoUrl,
  useIvs,
  eventId,
  logEvents,
  roomId,
  roomTitle,
  eventCategory,
  eventTitle,
  isVideo,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [endedStreamError, setEndedStreamError] = useState(false);
  const [muted, setMuted] = useState(true);
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [progress, setProgress] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [videoControlsVisible, setVideoControlsVisible] = useState(false);
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const adContainerRef = useRef(null);
  const [logTime, setLogTime] = useState(0);
  const [watchTime, setWatchTime] = useState(0);
  const [startWatch, setStartWatch] = useState(0);
  const [stopWatch, setStopWatch] = useState(0);

  const { initializeIMA, adFinished, initStarted } = useIMA();

  const params = {
    cl_video_type: isVideo ? 'video' : 'ended event',
    creator_id: roomId,
    creator_name: roomTitle || 'Not Available',
    content_category: eventCategory,
    cl_video_title: eventTitle,
  };

  const handlePlayerReady = (videoElement) => {
    if (adFinished.current || initStarted.current) {
      return;
    }
    initializeIMA(eventId, videoElement, adContainerRef.current);
  };

  const toggleMute = () => {
    setMuted(!muted);
  };

  const togglePlay = () => {
    setPlaying(!playing);
  };

  const playVideo = () => {
    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
  };

  const onSeek = (progressValue) => {
    setProgress(progressValue);
    playerRef.current.seekTo(progressValue, 'seconds');
  };

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(containerRef.current);
    }
  };

  const handleEnd = () => {
    pause();
    setProgress(0);
    logEvent('completed_watching', {
      ...params,
      eventId,
      video_url: videoUrl,
      video_duration: duration,
    });
    logEvent('percentage_watched_video', {
      ...params,
      eventId,
      category: 'consumer',
      video_progress: '100%',
    });
    setLogTime(100);
    setStopWatch(0);
    setStartWatch(0);
    setWatchTime(0);
  };

  const toggleFullscreen = () => {
    setFullScreen(!fullScreen);
    handleClickFullscreen();
  };

  const handleError = (error) => {
    const msg = error.message || error;
    logError(msg);

    if (msg.includes('call to pause()')) {
      setMuted(true);
      setPlaying(false);

      setTimeout(() => setPlaying(true), 500);
      return;
    }

    // handle DOMException: play() failed because the user didn't interact with the document first.
    if (msg.includes('play() failed')) {
      setMuted(true);
      setPlaying(true);
    } else {
      setActiveTab('teaser');
      // setEndedStreamError(true);
    }
  };

  const logProgress = (seconds) => {
    setProgress(seconds);
  };

  useEffect(() => {
    if (Number.parseInt(watchTime) === 3) {
      logEvent('start_watching', {
        ...params,
        eventId,
        video_url: videoUrl,
        video_duration: duration,
      });
    } else if (Number.parseInt(watchTime) === 30) {
      logEvent('watches_30sec', {
        ...params,
        eventId,
        video_url: videoUrl,
        video_duration: duration,
      });
    }
  }, [watchTime]);

  useEffect(() => {
    if (logTime === 100) {
      setLogTime(0);
    }
    if (progress / duration >= 0.15 && logTime < 15) {
      logEvent('percentage_watched_video', {
        ...params,
        eventId: eventId,
        category: 'consumer',
        video_progress: '15%',
      });
      setLogTime(15);
    } else if (progress / duration >= 0.5 && logTime < 50) {
      logEvent('percentage_watched_video', {
        ...params,
        eventId: eventId,
        category: 'consumer',
        video_progress: '50%',
      });
      setLogTime(50);
    } else if (progress / duration >= 0.75 && logTime < 75) {
      logEvent('percentage_watched_video', {
        ...params,
        eventId: eventId,
        category: 'consumer',
        video_progress: '75%',
      });
      setLogTime(75);
    }
  }, [progress]);

  const increment = () => {
    const now = new Date().getTime();
    const time = (now - startWatch) / 1000;
    setWatchTime((secs) => stopWatch + time);
    setStopWatch(stopWatch + time);
  };

  useEffect(() => {
    let interval;
    if (playing && watchTime < 31) {
      setTimeout(() => {
        interval = setInterval(() => {
          if (watchTime < 32) {
            setWatchTime((secs) => secs + 1);
          }
        }, 1000);
      }, 1000);
    } else {
      clearInterval(interval);
      if (startWatch) {
        increment();
      }
    }
    return () => clearInterval(interval);
  }, [playing]);

  return (
    <div
      ref={containerRef}
      onMouseEnter={() => setVideoControlsVisible(true)}
      onMouseLeave={() => setVideoControlsVisible(false)}
      className={useIvs ? 'w-full h-full' : ''}
    >
      {useIvs && (
        <VideoPlayer
          ref={playerRef}
          ivs={true}
          isDelayedlive={false}
          streamUrl={videoUrl}
          onPlayerReady={handlePlayerReady}
          logEvents={logEvents}
          type='ended'
          eventId={eventId}
          roomId={roomId}
          roomTitle={roomTitle}
          eventCategory={eventCategory}
          eventTitle={eventTitle}
          onReady={() => {
            setMuted(playerRef.current.isMuted());
          }}
        />
      )}
      {useIvs && (
        <div
          className={`event-player-controls-container ${
            videoControlsVisible ? 'visible' : ''
          }`}
        >
          <button
            data-tip='Toggle sound'
            data-for='sound'
            className='event-player-controls-button'
            onClick={() => {
              setMuted(!playerRef.current.isMuted());
              playerRef.current.setMuted(!playerRef.current.isMuted());
            }}
          >
            <Icon
              size={20}
              name={muted ? 'volume-mute' : 'volume-up'}
              color='#fff'
            />
            <ReactTooltip
              id='sound'
              place='top'
              effect='solid'
              className='react-tooltip'
            />
          </button>
          <div className='flex'>
            <button
              data-tip='Toggle full screen'
              data-for='fullscreen'
              className='event-player-controls-button'
              onClick={() => {
                setFullScreen(!fullScreen);
                handleClickFullscreen();
              }}
            >
              <Icon
                size={20}
                name={fullScreen ? 'minimize' : 'full-screen'}
                color='#fff'
              />
              <ReactTooltip
                id='fullscreen'
                place='top'
                effect='solid'
                className='react-tooltip'
              />
            </button>
          </div>
        </div>
      )}
      {!useIvs && (
        <ReactPlayer
          id='event-video'
          ref={playerRef}
          onReady={() => {
            handlePlayerReady(playerRef.current.getInternalPlayer());
            playVideo();
            if (muted) {
              toggleMute();
            }
          }}
          onPlay={() => {
            setStartWatch(new Date().getTime());
          }}
          onError={handleError}
          onDuration={(duration) => setDuration(duration)}
          onProgress={({ playedSeconds }) => logProgress(playedSeconds)}
          onEnded={handleEnd}
          config={{
            file: {
              forceHLS: true,
              attributes: { playsInline: true },
            },
          }}
          controls={false}
          playing={playing}
          muted={muted}
          volume={muted ? 0 : 1}
          url={videoUrl}
          loop={false}
          width='100%'
          height='100%'
          className='event-video bg-black rounded-lg'
        />
      )}
      {!useIvs && (
        <div
          className={`event-video-mask-bottom ${
            videoControlsVisible ? 'visible' : ''
          }`}
        ></div>
      )}
      {!useIvs && (
        <VideoControls
          playing={playing}
          duration={duration}
          progress={progress}
          muted={muted}
          fullscreen={fullScreen}
          togglePlay={togglePlay}
          toggleMute={toggleMute}
          onSeek={onSeek}
          toggleFullscreen={toggleFullscreen}
          visible={videoControlsVisible}
        />
      )}
      {/* not used currently, because we show teaser screen on error */}
      {endedStreamError && (
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg px-4 py-6 shadow-card'>
          <Heading headingSize={5} classes='mb-4'>
            Video is not available
          </Heading>
          <Paragraph>This video is not available at the moment.</Paragraph>
          <Paragraph classes='mb-4'>Please try again later!</Paragraph>
          <div className='flex items-center justify-end'>
            <Button
              buttonOnClick={() => setActiveTab('score')}
              buttonSize='small'
            >
              Close
            </Button>
          </div>
        </div>
      )}
      <div
        ref={adContainerRef}
        className='absolute top-0 left-0 w-full z-50'
      ></div>
    </div>
  );
};

export default EndedEventVideoTab;
