import React from 'react';
import { Helmet } from 'react-helmet';
import Heading from '../components/typography/Heading';
import Button from '../components/common/Button';

const NotFoundPage = () => {
  return (
    <div className='not-found-page-wrap'>
      <Helmet>
        <title>Not Found | ClashTV</title>
      </Helmet>
      <div className='absolute transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 max-w-2xl text-center'>
        <h1 className='mb-5 font-display font-bold text-8xl text-primaryA tracking-tighter'>
          Page not found!
        </h1>
        <Heading
          classes='mb-10 px-14 font-bold tracking-tighter'
          headingSize={3}
        >
          The page you are looking for doesn&apos;t exist or has been moved.
        </Heading>
        <Button
          buttonType='primary'
          buttonSize='large'
          isLink={true}
          linkTo='/dashboard'
          classes='py-5'
        >
          Back to events
        </Button>
      </div>
    </div>
  );
};

export default NotFoundPage;
