/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { produce } from 'immer';
import {
  ActionType,
  createReducer,
} from 'typesafe-actions';

import { SponsorRoom } from 'domain/types';

import {
  roomLoaded,
} from './actions';

export type Actions = ActionType<
  | typeof roomLoaded
>;

export type State = {
  byID: Record<string, SponsorRoom>;
  allIDs: string[];
};

const initialState: State = {
  byID: {},
  allIDs: [],
};

const roomsReducer = createReducer<State, Actions>(initialState)
  .handleAction(roomLoaded, (state, action) =>
    produce(state, (draft) => {
      const { id } = action.payload;
      draft.byID[id] = action.payload;
      if (!state.allIDs.includes(id)) draft.allIDs.push(id);
    }));

export default roomsReducer;
