import React, { useState } from 'react';
import Slide from './Slide';
import SliderButton from './SliderButton';

const MediaSlider = ({ media, eventId }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [hovered, setHovered] = useState(false);
  const maxSlides = media.length - 1;

  const handleNextSlide = () => {
    if (media.length === 1) {
      return;
    }

    if (activeSlide === maxSlides) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  };

  const handlePrevSlide = () => {
    if (activeSlide === 0) {
      setActiveSlide(maxSlides);
    } else {
      setActiveSlide(activeSlide - 1);
    }
  };

  return (
    <div
      className='slider'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {media.map((item, i) => (
        <Slide
          eventId={eventId}
          key={i}
          type={item.type}
          url={item.url}
          style={{ transform: `translateX(${100 * (i - activeSlide)}%)` }}
          videoControlsVisible={hovered}
          goToNextSlide={handleNextSlide}
          isActive={activeSlide === i}
        />
      ))}
      {media.length > 1 && (
        <SliderButton
          visible={hovered}
          direction='backward'
          onClick={handlePrevSlide}
        />
      )}
      {media.length > 1 && (
        <SliderButton
          visible={hovered}
          direction='forward'
          onClick={handleNextSlide}
        />
      )}
    </div>
  );
};

export default MediaSlider;
