import React, { useContext, useEffect, useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';

import Icon from '../typography/Icon';
import LeaveStreamDialog from '../system/LeaveStreamDialog';
import { InvitationsContext } from '../../context/invitations';

import { logError, leaveMeeting } from '../../helpers';
import useScore from '../../hooks/useScore';

const Score = ({
  eventId,
  gamificationHidden,
  contestorA,
  contestorB,
  currentRound,
  roundsEnabled,
  eventState,
}) => {
  const [showLeaveDialog, setShowLeaveDialog] = useState(false);

  const invitationsContext = useContext(InvitationsContext);
  const { acceptedInviteAsParticipant, updateInvitation } = invitationsContext;

  const meetingManager = useMeetingManager();
  const { loadScore, score, subscribeToScore } = useScore();

  useEffect(() => {
    loadScore(eventId);
  }, []);

  useEffect(async () => {
    const subscription = await subscribeToScore(eventId);

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, []);

  const handleLeaveStream = async () => {
    try {
      await leaveMeeting(
        { meetingId: meetingManager.meetingId },
        acceptedInviteAsParticipant.mixerEventUUID,
        acceptedInviteAsParticipant.mixerAccessCode
      );

      await updateInvitation(acceptedInviteAsParticipant.id, {
        status: 'declined',
      });
    } catch (error) {
      logError(error);
    }
  };

  const onLeaveAttempt = () => {
    setShowLeaveDialog(true);

    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };

  if (eventState === 'ended') {
    return null;
  }

  return (
    <div className='event-stats-container'>
      <div className='event-score-container'>
        {acceptedInviteAsParticipant && (
          <button className='participant-leave-btn' onClick={onLeaveAttempt}>
            <Icon name='exit' />
          </button>
        )}
        {!gamificationHidden && (
          <>
            <div className='flex flex-1 justify-end items-center'>
              {score.contestorAScore || 0}
            </div>
            <span>:</span>
            <div className='flex flex-1 justify-start'>
              {score.contestorBScore || 0}
            </div>
          </>
        )}
      </div>
      {!gamificationHidden && (
        <div className='event-contestors-container'>
          <div className='flex flex-1 justify-end'>
            <span className='event-contestor-name'>{contestorA}</span>
            <span className='contestor-color blue'></span>
          </div>
          <span
            className={`event-current-round ${
              !roundsEnabled ? 'invisible' : ''
            }`}
          >
            {currentRound || 0}
          </span>
          <div className='flex flex-1 justify-start items-center'>
            <span className='contestor-color red'></span>
            <span className='event-contestor-name'>{contestorB}</span>
          </div>
        </div>
      )}
      <LeaveStreamDialog
        isVisible={showLeaveDialog}
        onClose={() => setShowLeaveDialog(false)}
        onSubmit={handleLeaveStream}
      />
    </div>
  );
};

export default Score;
