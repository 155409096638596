import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as BackComponent } from '../../icons/back.svg';
import { ReactComponent as CheckComponent } from '../../icons/check.svg';
import { ReactComponent as EmailComponent } from '../../icons/email.svg';
import { ReactComponent as ErrorComponent } from '../../icons/error.svg';
import { ReactComponent as FlagComponent } from '../../icons/flag.svg';
import { ReactComponent as ForwardComponent } from '../../icons/forward.svg';
import { ReactComponent as HidePassComponent } from '../../icons/hide-password.svg';
import { ReactComponent as PhoneComponent } from '../../icons/phone.svg';
import { ReactComponent as SelectorComponent } from '../../icons/selector.svg';
import { ReactComponent as SelectorUpComponent } from '../../icons/selector-up.svg';
import { ReactComponent as ShowPassComponent } from '../../icons/show-password.svg';
import { ReactComponent as UserComponent } from '../../icons/user.svg';
import { ReactComponent as LocationComponent } from '../../icons/location.svg';
import { ReactComponent as BellComponent } from '../../icons/bell.svg';
import { ReactComponent as BroadCastOnlineComponent } from '../../icons/broadcast-online.svg';
import { ReactComponent as BroadCastOfflineComponent } from '../../icons/broadcast-offline.svg';
import { ReactComponent as CalendarComponent } from '../../icons/calendar.svg';
import { ReactComponent as HistoryComponent } from '../../icons/history.svg';
import { ReactComponent as HeartComponent } from '../../icons/heart.svg';
import { ReactComponent as PrimaryExclamationMark } from '../../icons/primary-exclamation-mark.svg';
import { ReactComponent as WarningExclamationMark } from '../../icons/warning-exclamation-mark.svg';
import { ReactComponent as DangerExclamationMark } from '../../icons/danger-exclamation-mark.svg';
import { ReactComponent as SubmitComponent } from '../../icons/submit.svg';
import { ReactComponent as SmileEmojiComponent } from '../../icons/smile-emoji.svg';
import { ReactComponent as HideComponent } from '../../icons/hide.svg';
import { ReactComponent as ShowComponent } from '../../icons/show.svg';
import { ReactComponent as DotsComponent } from '../../icons/dots.svg';
import { ReactComponent as PlusComponent } from '../../icons/plus.svg';
import { ReactComponent as StarComponent } from '../../icons/star.svg';
import { ReactComponent as CommentOnComponent } from '../../icons/hide-chat.svg';
import { ReactComponent as CommentOffComponent } from '../../icons/chat.svg';
import { ReactComponent as VolumeMuteComponent } from '../../icons/no-sound.svg';
import { ReactComponent as VolumeUpComponent } from '../../icons/volume-up.svg';
import { ReactComponent as FullScreenComponent } from '../../icons/full-screen.svg';
import { ReactComponent as MinimizeComponent } from '../../icons/minimize.svg';
import { ReactComponent as PictureInPictureComponent } from '../../icons/picture-in-picture.svg';
import { ReactComponent as PlayComponent } from '../../icons/play.svg';
import { ReactComponent as CameraComponent } from '../../icons/camera.svg';
import { ReactComponent as VideoComponent } from '../../icons/video-solid.svg';
import { ReactComponent as TrashComponent } from '../../icons/trash-solid.svg';
import { ReactComponent as SearchComponent } from '../../icons/search-solid.svg';
import { ReactComponent as ShareComponent } from '../../icons/share-solid.svg';
import { ReactComponent as HandComponent } from '../../icons/hand-paper-regular.svg';
import { ReactComponent as LiveComponent } from '../../icons/dot-circle-regular.svg';
import { ReactComponent as ExitComponent } from '../../icons/exit.svg';
import { ReactComponent as CookieComponent } from '../../icons/cookie.svg';
import { ReactComponent as MoneyComponent } from '../../icons/money.svg';
import { ReactComponent as ExchangeComponent } from '../../icons/exchange.svg';
import { ReactComponent as Share2Component } from '../../icons/share-2.svg';
import { ReactComponent as PauseComponent } from '../../icons/pause.svg';
import { ReactComponent as UploadComponent } from '../../icons/upload.svg';
import { ReactComponent as PlayAgainComponent } from '../../icons/play-again.svg';
import { ReactComponent as ClapComponent } from '../../icons/clap.svg';
import { ReactComponent as DownloadComponent } from '../../icons/download.svg';
import { ReactComponent as ThreeClockComponent } from '../../icons/three-clock.svg';

const Icon = ({ name, size, color, classes }) => {
  const iconMap = new Map([
    ['back', BackComponent],
    ['check', CheckComponent],
    ['email', EmailComponent],
    ['error', ErrorComponent],
    ['flag', FlagComponent],
    ['forward', ForwardComponent],
    ['hidePassword', HidePassComponent],
    ['phone', PhoneComponent],
    ['selector', SelectorComponent],
    ['selectorUp', SelectorUpComponent],
    ['showPassword', ShowPassComponent],
    ['user', UserComponent],
    ['location', LocationComponent],
    ['bell', BellComponent],
    ['broadcast-online', BroadCastOnlineComponent],
    ['broadcast-offline', BroadCastOfflineComponent],
    ['calendar', CalendarComponent],
    ['history', HistoryComponent],
    ['heart', HeartComponent],
    ['primary-exclamation-mark', PrimaryExclamationMark],
    ['warning-exclamation-mark', WarningExclamationMark],
    ['danger-exclamation-mark', DangerExclamationMark],
    ['submit', SubmitComponent],
    ['smile-emoji', SmileEmojiComponent],
    ['hide', HideComponent],
    ['show', ShowComponent],
    ['dots', DotsComponent],
    ['plus', PlusComponent],
    ['star', StarComponent],
    ['comments-on', CommentOnComponent],
    ['comments-off', CommentOffComponent],
    ['volume-mute', VolumeMuteComponent],
    ['volume-up', VolumeUpComponent],
    ['full-screen', FullScreenComponent],
    ['minimize', MinimizeComponent],
    ['picture-in-picture', PictureInPictureComponent],
    ['play', PlayComponent],
    ['camera', CameraComponent],
    ['video', VideoComponent],
    ['trash', TrashComponent],
    ['search', SearchComponent],
    ['share', ShareComponent],
    ['hand', HandComponent],
    ['live', LiveComponent],
    ['exit', ExitComponent],
    ['cookie', CookieComponent],
    ['money', MoneyComponent],
    ['exchange', ExchangeComponent],
    ['share2', Share2Component],
    ['pause', PauseComponent],
    ['upload', UploadComponent],
    ['watch-again', PlayAgainComponent],
    ['clap', ClapComponent],
    ['download', DownloadComponent],
    ['three-clock', ThreeClockComponent],
  ]);

  const IconComponent = iconMap.get(name);

  return (
    <IconComponent
      width={size}
      height={size}
      color={color}
      className={classes}
    />
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
  color: PropTypes.string,
  classes: PropTypes.string,
};

Icon.defaultProps = {
  name: 'check',
  size: 16,
  color: '',
  classes: '',
};

export default Icon;
