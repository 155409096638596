/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { createAsyncAction } from "typesafe-actions";

import { PurchasedSubscription } from "domain/types";

export const loadPurchasedSubscriptions = createAsyncAction(
  'PurchasedSubsriptions/LOAD_REQUEST',
  'PurchasedSubsriptions/LOAD_SUCCESS',
  'PurchasedSubsriptions/LOAD_ERROR'
)<string, PurchasedSubscription[], string>();
