/* eslint-disable prettier/prettier */
import React, { FC, useState } from 'react';

import { Event } from 'domain/types';

import BuyRoomPPVModal from 'components/dashboard/BuyRoomPPVModal/index';
import Button from 'components/common/Button';

const getClasses = ({ isOwn, isPurchased }: { [key: string]: boolean }) => {
  const classes = [
    'flex',
    'items-center',
    'rounded-full',
  ];

  if (isPurchased) return classes.concat(['bg-positive', 'border-positive', 'pointer-events-none']).join(' ');
  else if (isOwn) return classes.concat(['bg-secondaryA', 'border-secondaryA', 'pointer-events-none']).join(' ');
  else return classes.join(' ');
};

const getLabel = ({ isOwn, isPurchased, isEnded }: { [key: string]: boolean }) => {
  if (isOwn) return 'Own';
  if (isPurchased) return 'Purchased';
  if (isEnded) return 'Watch Again';
  return 'Watch Now';
};

type Props = {
  event: Event;
  onPurchase: () => unknown;
  isRestricted: boolean;
  onEndedEventClick: (tab: string) => unknown;
  isOwn: boolean;
  isPurchased: boolean;
};

const PurchaseEventButtons: FC<Props> = ({
  event,
  onPurchase,
  isRestricted,
  onEndedEventClick,
  isOwn = false,
  isPurchased = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const isEnded = event.state === 'ended';
  const hasVideo = !!event.videoUrl;

  const canPurcahseEndedEvent = !isOwn && !isPurchased && isEnded;

  const buttonType = canPurcahseEndedEvent
    ? 'outlined'
    : 'purple';

  const getIconName = () => {
    if (isOwn || isPurchased) return;
    return canPurcahseEndedEvent
      ? 'watch-again'
      : 'play';
  };

  const onWatchButtonClick = () => {
    if (isEnded) {
      if (!hasVideo && !isRestricted) return onEndedEventClick('teaser');
      if (!isRestricted) return onEndedEventClick('video');
    }

    if (isOwn || isPurchased) return;
    openModal();
  };

  const onBattleButonClick = () => {
    if (!isRestricted) return onEndedEventClick('score');

    if (isOwn || isPurchased) return;
    openModal();
  };

  return (
    <>
      <Button
        buttonType={buttonType}
        buttonSize='small'
        buttonOnClick={onWatchButtonClick}
        icon={getIconName()}
        iconSize={14}
        iconClasses='mr-2'
        classes={getClasses({ isOwn, isPurchased })}
      >
        {getLabel({ isOwn, isPurchased, isEnded })}
      </Button>

      {
        isEnded &&
        <Button
          buttonType='outlined'
          buttonSize='small'
          buttonOnClick={onBattleButonClick}
          icon='clap'
          iconSize={14}
          iconClasses='mr-2'
          classes='flex flex-center rounded-full'
        >
          ClashTV Battle
        </Button>
      }

      { isModalOpen && (
        <BuyRoomPPVModal
          event={event}
          onClose={closeModal}
          onPurchase={onPurchase}
        />
      )}
    </>
  );
};

export default PurchaseEventButtons;
