import React, { useContext, useEffect, useState } from 'react';

import Button from '../common/Button';
import NewPoll from './NewPoll';
import PollResults from './PollResults';

import { PollsContext } from '../../context/polls';
import { AlertsContext } from '../../context/alerts';
import { EventsContext } from '../../context/events';
import { logError } from '../../helpers';
import Paragraph from '../typography/Paragraph';
import Spinner from '../system/Spinner';

const PollsContainer = ({ eventId, disabled }) => {
  const pollsContext = useContext(PollsContext);
  const alertsContext = useContext(AlertsContext);
  const eventsContext = useContext(EventsContext);

  const { getPollsByEventId, updatePoll, setPollsUpdateByEventIdSubscription } =
    pollsContext;
  const { addAlert } = alertsContext;
  const { getCurrentEvent, createEventSubscription } = eventsContext;

  const [newPollVisible, setNewPollVisible] = useState(false);
  const [latestActivePoll, setLatestActivePoll] = useState(null);
  const [archivedPolls, setArchivedPolls] = useState([]);
  const [eventState, setEventState] = useState('');
  const [loading, setLoading] = useState(true);

  const setLatestPolls = async () => {
    let newestActivePoll = null;
    const archived = [];
    const eventPolls = await getPollsByEventId(eventId);

    for (const poll of eventPolls) {
      if (poll.status === 'active') {
        if (
          newestActivePoll &&
          new Date(newestActivePoll.createdAt) > new Date(poll.createdAt)
        ) {
          continue;
        }

        newestActivePoll = poll;
      }

      if (poll.status === 'archived') {
        archived.push(poll);
      }
    }

    const sorted = archived.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );

    setLatestActivePoll(newestActivePoll);
    setArchivedPolls(sorted);
  };

  const handleEventStateUpdate = (updatedEvent) => {
    setEventState((currentState) => {
      if (currentState !== updatedEvent.state) {
        return updatedEvent.state;
      }

      return currentState;
    });
  };

  const handleUpdatedPolls = async (updatedData) => {
    const event = await getCurrentEvent(eventId, true);

    if (event.state === 'ended') {
      setLatestPolls();
    }
  };

  useEffect(async () => {
    setLatestPolls();

    const event = await getCurrentEvent(eventId, true);
    let eventSub = null;

    if (event.state) {
      setEventState(event.state);

      eventSub = await createEventSubscription(
        eventId,
        handleEventStateUpdate,
        logError
      );
    }

    setLoading(false);

    return () => {
      if (eventSub) {
        eventSub.unsubscribe();
      }
    };
  }, []);

  useEffect(async () => {
    const pollsUpdateSub = await setPollsUpdateByEventIdSubscription(
      eventId,
      handleUpdatedPolls
    );

    return () => {
      if (pollsUpdateSub) {
        pollsUpdateSub.unsubscribe();
      }
    };
  }, []);

  const onCreate = () => {
    setLatestPolls();
  };

  const archive = async (id) => {
    const res = await updatePoll(id, { status: 'archived' });

    if (!res.success) {
      addAlert('danger', 'Something went wrong while archiling!');
    }

    await setLatestPolls();
    addAlert('success', 'Poll was archived successfully');
  };

  if (loading) {
    return (
      <div className='polls-container'>
        <Spinner size='big' />
      </div>
    );
  }

  if (disabled && archivedPolls.length === 0) {
    return (
      <div className='polls-container'>
        <div className='chat-message in-creator flex flex-1'>
          Polls wil be active as soon as the event is scheduled
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='polls-container'>
        {eventState === 'upcoming' && !latestActivePoll && (
          <Paragraph>
            Polls are visible to viewers on the Live event page
          </Paragraph>
        )}
        <NewPoll
          eventId={eventId}
          visible={newPollVisible}
          onDismiss={() => setNewPollVisible(false)}
          onSubmit={onCreate}
        />
        <div className='h-full'>
          {latestActivePoll && (
            <PollResults
              pollData={latestActivePoll}
              archive={archive}
              className={'mb-4 bg-gray-100'}
            />
          )}
          {archivedPolls.map((p) => (
            <PollResults key={p.id} pollData={p} className='mb-4 bg-gray-100' />
          ))}
        </div>
      </div>
      {!disabled && (
        <Button
          buttonSize='medium'
          buttonType='primary'
          buttonOnClick={() => setNewPollVisible(true)}
          classes='my-4 mx-4'
          disabled={!!latestActivePoll}
        >
          New Poll
        </Button>
      )}
    </>
  );
};

export default PollsContainer;
