import React, { FC, Fragment, useEffect, useState } from 'react';
import Logo from 'components/common/Logo';

import { setCookie, setCurrentUserInLocalStorage } from 'helpers';

import { logUnregisteredEvent } from '../../firebase';

import './styles.css';

const LandingPage: FC = () => {
  const [step, setStep] = useState('');

  const initSignUp = () => {
    setStep('register-init');
    logUnregisteredEvent('screen_view', {
      firebase_screen: 'Create Your Account Page',
      page_title: 'Register | ClashTV',
      page_URL: `${window.location.origin}${window.location.pathname}register`,
      url: `${window.location.origin}${window.location.pathname}register${window.location.hash}`,
      hostname: window.location.hostname,
    });
  };

  const verifyCode = () => {
    setStep('register-verify');
    logUnregisteredEvent('screen_view', {
      firebase_screen: 'Code Verification Page',
      page_title: 'Code Verification | ClashTV',
      page_URL: `${window.location.origin}${window.location.pathname}code-verification`,
      url: `${window.location.origin}${window.location.pathname}code-verification${window.location.hash}`,
      hostname: window.location.hostname,
    });
  };

  const completeProfile = () => {
    setCookie('some-user-id', JSON.stringify({ analytics: true }), 365);
    setCurrentUserInLocalStorage({
      attributes: {
        sub: 'some-user-id',
      },
    });

    setStep('register-complete');
    logUnregisteredEvent('screen_view', {
      firebase_screen: 'Complete Profile Page',
      page_title: 'Complete Profile | ClashTV',
      page_URL: `${window.location.origin}${window.location.pathname}complete-profile`,
      url: `${window.location.origin}${window.location.pathname}complete-profile${window.location.hash}`,
      hostname: window.location.hostname,
    });
  };

  const loginInit = () => {
    setStep('login-init');
    logUnregisteredEvent('screen_view', {
      firebase_screen: 'Login Page',
      page_title: 'Login | ClashTV',
      page_URL: `${window.location.origin}${window.location.pathname}login`,
      url: `${window.location.origin}${window.location.pathname}login${window.location.hash}`,
      hostname: window.location.hostname,
    });
  };

  const reset = () => {
    localStorage.clear();
    setStep('');
    logUnregisteredEvent('screen_view', {
      firebase_screen: 'Welcome Page',
      page_title: 'Welcome | ClashTV',
      page_URL: `${window.location.origin}${window.location.pathname}`,
      url: window.location.href,
      hostname: window.location.hostname,
    });
  };

  useEffect(() => {
    localStorage.clear();
    logUnregisteredEvent('screen_view', {
      firebase_screen: 'Welcome Page',
      page_title: 'Welcome | ClashTV',
      page_URL: `${window.location.origin}${window.location.pathname}`,
      url: window.location.href,
      hostname: window.location.hostname,
    });
  }, []);

  const containerClasses = [
    'landing-page',
    'relative',
    'isolate',
    'h-full',
    'bg-fixed',
    'bg-center',
    'bg-no-repeat',
    'flex',
    'items-center',
    'justify-center',
  ].join(' ');

  const innerClasses = [
    'inner',
    'w-full',
    'h-full',
    'flex',
    'flex-col',
    'items-center',
    'bg-white',
    'rounded-lg',
    'pt-12',
    'px-18',
    'z-10',
  ].join(' ');

  const headingClasses = [
    'font-clash',
    'font-semibold',
    'text-3xl',
    'tracking-tight',
    'mt-24',
    'mb-10',
  ].join(' ');

  const btnsContainerClasses = [
    'w-full',
    'flex',
    'flex-col',
    'gap-3',
  ].join(' ');

  const btnClasses = [
    'h-12',
    'font-display',
    'font-medium',
    'text-base',
    'rounded-full',
  ].join(' ');

  return (
    <div className={containerClasses}>
      <div className={innerClasses}>
        <Logo logoHeight={54} logoWidth={100} />

        {
          step === '' && (
            <Fragment>
              <h3 className={headingClasses}>
                Welcome to ClashTV
              </h3>
              <div className={btnsContainerClasses}>
                <button onClick={initSignUp} className={`sign-up-btn text-white ${btnClasses}`}>Sign up</button>
                <button onClick={loginInit} className={`border border-primaryA ${btnClasses}`}>Login</button>
              </div>
            </Fragment>
          )
        }

        {
          step === 'register-init' && (
            <Fragment>
              <h3 className={headingClasses}>
                Create your account
              </h3>
              <p>WIP</p>
              <button onClick={verifyCode} className={`bg-primaryA text-white w-full ${btnClasses}`}>Sign up</button>
            </Fragment>
          )
        }

        {
          step === 'register-verify' && (
            <Fragment>
              <h3 className={headingClasses}>
                Complete your profile
              </h3>
              <p>WIP</p>
              <button onClick={completeProfile} className={`bg-primaryA text-white w-full ${btnClasses}`}>Explore ClashTV</button>
            </Fragment>
          )
        }

        {
          step === 'register-complete' && (
            <Fragment>
              <h3 className={headingClasses}>
                Test complete, please check tracking
              </h3>
              <p>or use the button below to go back to the initial step</p>
              <button onClick={reset} className={`bg-primaryA text-white w-full ${btnClasses}`}>Start over</button>
            </Fragment>
          )
        }

        {
          step === 'login-init' && (
            <Fragment>
              <h3 className={headingClasses}>
                Log in
              </h3>
              <p>WIP</p>
              <button onClick={verifyCode} className={`bg-primaryA text-white w-full ${btnClasses}`}>Log in</button>
            </Fragment>
          )
        }
      </div>
    </div>
  );
};

export default LandingPage;
