import React from 'react';
import Heading from './components/typography/Heading';
import { useLogEvent } from './firebase';

import { ReactComponent as AppStoreBadge } from './assets/app-store-badge.svg';
import GooglePlaybadge from './assets/google-play.svg';
import tabletBackground from './assets/tablet-background.png';

const appStoreUrl = 'https://apps.apple.com/tt/app/clashtv/id1468675126';
const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.clashtv.clashtv';

const TabletScreen = () => {
  const { logEvent } = useLogEvent();
  const onAppStoreClick = () => {
    logEvent('open_app_store', { category: 'engagement' });
  };

  const onGooglePlayClick = () => {
    logEvent('open_google_play', { category: 'engagement' });
  };

  return (
    <div className='h-screen flex flex-col justify-center tablet'>
      <div
        style={{
          height: '100%',
          maxHeight: 768,
          backgroundColor: 'rgb(82,0,233)',
          background:
            'linear-gradient(305deg, rgba(82,0,233,1) 2%, rgba(47,0,135,1) 98%)',
        }}
      >
        <div
          className='h-full flex flex-col justify-center pl-40'
          style={{
            backgroundImage: `url(${tabletBackground})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right center',
          }}
        >
          <Heading headingSize={2} classes='text-white mb-16'>
            Download ClashTV app <br /> for ultimate expirience!
          </Heading>
          <a className='mb-10' href={appStoreUrl} onClick={onAppStoreClick}>
            <AppStoreBadge />
          </a>
          <a href={googlePlayUrl} onClick={onGooglePlayClick}>
            <img src={GooglePlaybadge} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default TabletScreen;
