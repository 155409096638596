import React, { useState } from 'react';
import GridContainer from '../layout/GridContainer';
import UserSidebar from '../UserSidebar/Index';
import DashboardEventsFilter from '../dashboard/DashboardEventsFilter';
import { shouldRenderDashboardEventsFilter } from '../../helpers';
import routes from '../../routes';
import { useLocation } from 'react-router-dom';

const ContainerWithUserSidebar = ({ children, useCardGrid }) => {
  const location = useLocation();
  const [sidebarLoaded, setSidebarLoaded] = useState(false);

  const renderContent = () => {
    if (
      location.pathname.indexOf(routes.dashboard) !== -1 ||
      location.pathname.indexOf(routes.groups) !== -1
    ) {
      return children;
    }

    return (
      <div
        className={`relative grid grid-cols-9 gap-8 mr-8 2xl:grid-cols-12 ${
          useCardGrid && 'auto-rows-card-row-2xl'
        } ${
          !shouldRenderDashboardEventsFilter(location.pathname) ? '' : 'mt-28'
        }`}
      >
        {children}
      </div>
    );
  };

  return (
    <GridContainer>
      <UserSidebar setSidebarState={setSidebarLoaded} />
      <div className='relative col-span-9 2xl:col-span-10'>
        {sidebarLoaded && <DashboardEventsFilter />}
        {renderContent()}
      </div>
    </GridContainer>
  );
};

export default ContainerWithUserSidebar;
