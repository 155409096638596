import React, { useContext } from 'react';
import Drawer from '../system/Drawer';
import Avatars from '../profile/Avatars';
import EventPreview from '../event-preview/EventPreview';
import MyGoodsDrawer from '../my-goods/MyGoodsDrawer';
import ClickawayListener from '../system/ClickawayListener';

import { LeftDrawerContext } from '../../context/leftDrawer';

const ContainerWithDrawer = ({ children }) => {
  const leftDrawerContext = useContext(LeftDrawerContext);
  const {
    eventPreviewIsOpen,
    userAvatarsAreOpen,
    myGoodsIsOpen,
    toggleEventPreview,
    toggleUserAvatars,
    toggleMyGoods,
    getActiveDrawer,
  } = leftDrawerContext;

  const isActive = eventPreviewIsOpen || userAvatarsAreOpen || myGoodsIsOpen;

  const toggleDrawer = () => {
    if (getActiveDrawer() === 'eventPreview') {
      toggleEventPreview();
    }

    if (getActiveDrawer() === 'userAvatars') {
      toggleUserAvatars();
    }

    if (getActiveDrawer() === 'myGoods') {
      toggleMyGoods();
    }
  };

  const renderContent = () => {
    if (getActiveDrawer() === 'eventPreview') {
      return <EventPreview toggleDrawer={toggleDrawer} />;
    }

    if (getActiveDrawer() === 'userAvatars') {
      return <Avatars />;
    }

    if (getActiveDrawer() === 'myGoods') {
      return <MyGoodsDrawer />;
    }

    return null;
  };

  const getDrawerClasses = () => {
    switch (getActiveDrawer()) {
      case 'eventPreview':
        return 'event';
      case 'userAvatars':
        return 'user';
      case 'myGoods':
        return 'my-goods';
      default:
        return '';
    }
  };

  const getButtonColor = () => {
    switch (getActiveDrawer()) {
      case 'eventPreview':
        return 'primaryD';
      default:
        return 'primaryA';
    }
  };

  return (
    <>
      <ClickawayListener onClick={toggleDrawer}>
        <Drawer
          drawerStatus={isActive}
          toggle={toggleDrawer}
          classes={getDrawerClasses()}
          btnColor={getButtonColor()}
        >
          <div className='relative transform h-full'>{renderContent()}</div>
        </Drawer>
      </ClickawayListener>
      {children}
    </>
  );
};

export default ContainerWithDrawer;
