import React, { useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import ReactPlayer from 'react-player';

import Icon from '../typography/Icon';
import Spinner from '../system/Spinner';

import { logError } from '../../helpers';
import { AlertsContext } from '../../context/alerts';

const MAX_TEASER_SIZE = 40000000;

const VideoPlaceholder = ({
  streamUrl,
  videoBlob,
  thumbnailUrl,
  disabled,
  fullWidth = false,
}) => {
  if (videoBlob) {
    return (
      <video
        className={`h-full w-full object-cover shadow-card rounded-lg cursor-pointer landscape ${
          !fullWidth ? 'max-h-48' : ''
        }`}
        style={disabled ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
      >
        <source src={videoBlob} />
      </video>
    );
  }

  if (thumbnailUrl) {
    return (
      <img
        className={`h-full w-full object-cover shadow-card rounded-lg cursor-pointer landscape ${
          !fullWidth ? 'max-h-48' : ''
        }`}
        src={thumbnailUrl}
        style={disabled ? { filter: 'grayscale(100%)', opacity: 0.5 } : {}}
      />
    );
  }

  return (
    <ReactPlayer
      id='teaser-placeholder'
      playing={false}
      controls={false}
      muted={true}
      url={streamUrl}
      width='100%'
      height='100%'
      config={{
        file: {
          attributes: { disablePictureInPicture: true },
          forceHLS: true,
          hlsOptions: { capLevelToPlayerSize: true },
        },
      }}
    />
  );
};

const VideoPicker = ({
  id,
  videoSrc,
  handleSelect,
  tooltip,
  thumbnailUrl,
  disabled = false,
  loading = false,
  fullWidth = false,
}) => {
  const alertsContext = useContext(AlertsContext);
  const { addAlert } = alertsContext;

  const [videoUrl, setVideoUrl] = useState(null);

  const handleFileSelect = (e) => {
    if (disabled) {
      return;
    }

    const file = e?.target?.files[0];

    if (file) {
      const fileType = file.type;

      if (file.size > MAX_TEASER_SIZE) {
        addAlert('danger', 'Video file cannot be larger than 40MB');
        return;
      }

      if (!fileType.includes('video')) {
        logError('File is not a video');
        addAlert('danger', 'Please select a video file');
        return;
      }

      const url = URL.createObjectURL(file);
      setVideoUrl(url);
    }

    handleSelect(file);
  };

  return (
    <div
      className='flex items-center justify-center flex-col mr-4'
      data-tip={tooltip}
    >
      <label
        htmlFor={id}
        className={`select-image relative${fullWidth ? ' w-full' : ''}`}
      >
        {!videoSrc && !videoUrl && !thumbnailUrl ? (
          <div
            className={`empty-placeholder video${disabled ? ' disabled' : ''}${
              fullWidth ? ' fullwidth' : ''
            }`}
          >
            {fullWidth ? (
              <div className='absolute inset-0 flex items-center justify-center'>
                <Icon
                  name='video'
                  color={disabled ? '#dadada' : '#fff'}
                  size={48}
                />
              </div>
            ) : (
              <Icon
                name='video'
                color={disabled ? '#dadada' : '#fff'}
                size={48}
              />
            )}
          </div>
        ) : (
          <VideoPlaceholder
            streamUrl={videoSrc}
            videoBlob={videoUrl}
            thumbnailUrl={thumbnailUrl}
            disabled={disabled}
            fullWidth={fullWidth}
          />
        )}
        {loading && (
          <div className='absolute inset-0 flex items-center justify-center'>
            <Spinner size='small' />
          </div>
        )}
      </label>
      <div className='flex items-center justify-center mb-6'>
        <label
          htmlFor={id}
          className={`  text-base font-display leading-6 tracking-tight font-normal ${
            disabled
              ? 'text-secondaryB cursor-default'
              : 'cursor-pointer text-primaryA'
          }`}
        >
          Upload Video
        </label>
        <input
          id={id}
          style={{ visibility: 'hidden', width: 0, height: 0 }}
          type='file'
          onChange={handleFileSelect}
          accept='video/*'
          disabled={disabled}
        />
      </div>
      {tooltip && <ReactTooltip effect='solid' className='react-tooltip' />}
    </div>
  );
};

export default VideoPicker;
