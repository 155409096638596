import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { TransactionsContext } from './transactions';
import {
  transactionsByUser,
  payPerViewListByUser,
  getEvent,
} from '../graphql/queries';
import { createTransaction } from '../graphql/mutations';
import { onPayPerViewListItemCreateByUserID } from '../graphql/subscriptions';
import { logError, getCurrentUserFromLocalStorage } from '../helpers';
import history from '../history';
import routes from '../routes';

export default class ShopItemsProvider extends Component {
  constructor(props) {
    super(props);

    this.sub = null;

    this.state = {
      myTransactions: [],
      myPPVList: {},
      loading: false,
      error: null,
      loadMyTransactions: this.loadMyTransactions,
      loadPPVEvent: this.loadPPVEvent,
      buyShopItem: this.buyShopItem,
      buyPPVEvent: this.buyPPVEvent,
      donate: this.donate,
      clearTransactions: this.clearTransactions,
    };
  }

  componentDidMount() {
    this.onAddPPVEvent();
  }

  componentWillUnmount() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getEvent = async (eventId) => {
    if (!eventId) {
      return;
    }

    try {
      const res = await API.graphql(
        graphqlOperation(getEvent, { id: eventId })
      );
      const event = res.data?.getEvent;

      if (!event) {
        // it's a video
        return null;
      }

      return event;
    } catch (error) {
      logError('getEvent', error);
      return null;
    }
  };

  onAddPPVEvent = async () => {
    const user = getCurrentUserFromLocalStorage();
    if (user) {
      const userID = user.id;
      this.sub = API.graphql(
        graphqlOperation(onPayPerViewListItemCreateByUserID, {
          userID,
        })
      ).subscribe({
        next: async ({ provider, value }) => {
          const eventId = value.data.onPayPerViewListItemCreateByUserID.eventID;
          this.setState({
            myPPVList: {
              ...this.state.myPPVList,
              [eventId]: value.data.onPayPerViewListItemCreateByUserID,
            },
          });

          const event = await this.getEvent(eventId);

          if (event?.state === 'live') {
            history.push(
              routes.eventWithID.replace(
                ':eventId',
                event.seoUrl ? event.seoUrl : eventId
              )
            );
          }
        },
        error: (error) =>
          logError('setPollsUpdateByEventIdSubscription', error),
      });
    }
  };

  loadMyTransactions = async (userId) => {
    if (this.state.myTransactions.length > 0) {
      return;
    }

    this.setState({ loading: true });

    try {
      const res = await API.graphql(
        graphqlOperation(transactionsByUser, {
          userID: userId,
          filter: {
            and: [
              { status: { eq: 'successful' } },
              { shopItemID: { attributeExists: true } },
            ],
          },
        })
      );

      const items = res?.data?.transactionsByUser?.items || [];
      const sorted = items.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      this.setState({
        myTransactions: sorted,
        loading: false,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        myTransactions: [],
        loading: false,
        error: 'Error loading shop items',
      });
    }
  };

  loadPPVEvent = async (userID, eventID) => {
    if (this.state.myPPVList[eventID]) {
      return { success: true, ppv: this.state.myPPVList[eventID] };
    }

    try {
      const res = await API.graphql(
        graphqlOperation(payPerViewListByUser, {
          userID,
          eventID: { eq: eventID },
        })
      );

      const item = res.data.payPerViewListByUser.items[0] || null;
      if (item && !this.state.myPPVList[item.eventID]) {
        this.setState({
          myPPVList: { ...this.state.myPPVList, [eventID]: item },
        });
      }

      return { success: true, ppv: item };
    } catch (error) {
      logError(error);
      return { success: false, ppv: null };
    }
  };

  buyPPVEvent = async (eventID, userID, username, eventTitle) => {
    const description = `${username} bought ${eventTitle}`;
    const input = {
      eventID,
      userID,
      description,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(createTransaction, { input })
      );

      const transaction = res.data.createTransaction;
      await this.loadPPVEvent(transaction.userID, transaction.eventID);

      return { success: true, transaction: res.data.createTransaction };
    } catch (error) {
      logError(error);
      this.setState({
        error: 'Error purchasing PPV Event',
      });

      return { success: false, transaction: null };
    }
  };

  donate = async (eventID, userID, amount, donatorName, eventTitle) => {
    const description = `${donatorName} is donating ${amount} to ${eventTitle}`;
    const input = {
      eventID,
      userID,
      description,
      amount,
      isDonation: true,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(createTransaction, { input })
      );

      const transaction = res.data.createTransaction;
      await this.loadPPVEvent(transaction.userID, transaction.eventID);

      return { success: true, transaction: res.data.createTransaction };
    } catch (error) {
      logError(error);
      this.setState({
        error: 'Error making donation',
      });

      return { success: false, transaction: null };
    }
  };

  buyShopItem = async (shopItem, userId) => {
    const gameActionAmount = shopItem.gameActionAmount || 0;
    // const boosterAmount = shopItem.boosterAmount || 0;
    const gameActionName = shopItem?.gameAction?.name || '';
    // const boosterName = shopItem?.booster?.title || '';
    let description = '';

    if (shopItem.avatarID) {
      description += `x1 ${shopItem.title}`;
    }

    if (shopItem.stickersBundleID) {
      description += `x1 ${shopItem.title}`;
    }

    if (gameActionAmount && gameActionName) {
      description += `x${gameActionAmount} ${gameActionName}`;
    }

    // if (boosterAmount && boosterName) {
    //   if (gameActionAmount && gameActionName) {
    //     description += ', ';
    //   }

    //   description += `x${boosterAmount} ${boosterName}`;
    // }

    const input = {
      description,
      shopItemID: shopItem.id,
      userID: userId,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(createTransaction, { input })
      );

      this.setState({
        myTransactions: [
          res.data.createTransaction,
          ...this.state.myTransactions,
        ],
      });

      return { success: true, itemName: shopItem.title };
    } catch (error) {
      logError(error);
      this.setState({
        error: 'Error purchasing shop item',
      });

      return { success: false, itemName: shopItem.title };
    }
  };

  clearTransactions = () => {
    this.setState({ myTransactions: [], myPPVList: [] });
  };

  render() {
    return (
      <TransactionsContext.Provider value={this.state}>
        {this.props.children}
      </TransactionsContext.Provider>
    );
  }
}
