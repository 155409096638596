import React from 'react';
import ProgressRing from '../system/ProgressRing';

const UserMainStats = ({ user }) => {
  const labelStyles =
    'text-xs font-display leading-4.2 tracking-tight text-primaryC font-medium';

  const userData = user?.dbData?.data?.getUser || null;

  if (!userData) {
    return null;
  }

  const maxExp = userData.nextLevelBaseExperience;
  const minExp = userData.currentLevelBaseExperience;
  const currentExp = userData.experience;

  const expPercentage = 100 * ((currentExp - minExp) / (maxExp - minExp));

  return (
    <div className='flex items-center mb-6'>
      <ProgressRing
        radius={32}
        progress={expPercentage}
        levelNumb={userData.level}
        classes='mr-6'
      />
      <ul>
        <li className={labelStyles}>
          <span className='inline-block w-14 font-bold'>Exp:</span>{' '}
          {userData.experience}
        </li>
        <li className={labelStyles}>
          <span className='inline-block w-14 font-bold'>Rank:</span>{' '}
          {`#${userData.rank || 'N/A'}`}
        </li>
        <li className={labelStyles}>
          <span className='inline-block w-14 font-bold'>Battles:</span>{' '}
          {userData.battles || 0}
        </li>
      </ul>
    </div>
  );
};

export default UserMainStats;
