/* eslint-disable no-unused-vars */
import React from 'react';
import { Fade } from 'react-slideshow-image';

import defaultBackground from '../../../assets/default-image.webp';

const CardCover = ({ images }) => {
  const renderContent = () => {
    if (images && images.length > 0) {
      return (
        <Fade
          arrows={false}
          pauseOnHover={false}
          className={'absolute inset-0 w-full h-full rounded-lg'}
        >
          {images.map((img, i) => (
            <div key={i} className='h-full'>
              <img
                src={img}
                className='block relative h-full w-full object-cover'
              />
              <div className='absolute inset-0 bg-primaryC opacity-10'></div>
            </div>
          ))}
        </Fade>
      );
    }

    return (
      <div className={'absolute inset-0 w-full h-full rounded-lg'}>
        <img
          className='block relative h-full w-full object-cover rounded-lg'
          src={defaultBackground}
        />
      </div>
    );
  };

  return (
    <div
      className='rounded-lg'
      style={{
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%',
      }}
    >
      {renderContent()}
    </div>
  );
};

export default CardCover;
