import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../typography/Icon';
import { logError } from '../../helpers';

const Drawer = ({ children, drawerStatus, toggle, classes, btnColor }) => {
  if (!children) {
    logError("Component Drawer not rendered, because 'children' missing.");
    return null;
  }
  return (
    <div
      className={`drawer ${
        drawerStatus ? 'translate-x-0' : '-translate-x-82'
      } ${classes}`}
    >
      <button onClick={toggle} className='absolute right-4 top-4 z-10'>
        <Icon
          size={18}
          name='error'
          classes={`close-drawer text-${btnColor} hover:text-${btnColor}`}
        />
      </button>
      {children}
    </div>
  );
};

Drawer.propTypes = {
  children: PropTypes.object.isRequired,
  drawerStatus: PropTypes.bool,
  toggle: PropTypes.func,
};

Drawer.defaultProps = {
  toggle: false,
};

export default Drawer;
