import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useParams, matchPath, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { UserContext } from '../../context/user';

import Heading from '../typography/Heading';
// import DropdownList from '../dropdown/DropdownList';
import Icon from '../typography/Icon';
import Button from '../common/Button';
import themeColors from '../../theme-colors';
// import CategoryPromotionButton from './CategoryPromotionButton';

import { useLogEvent } from '../../firebase';
import {
  shouldRenderNavbar,
  shouldRenderDashboardEventsFilter,
  getUserSidebarWidth,
  useWindowSize,
} from '../../helpers';
import history from '../../history';
import routes from '../../routes';
import CreatorList from './CreatorList';
import RoomGroups from './RoomGroups';
import CategoryFilter from './CategoryFilter';

const DashboardEventsFilter = () => {
  const { pathname, search } = useLocation();
  const { roomId, state, categoryId } = useParams();
  const [groupsNumber, setGroupsNumber] = useState(0);
  const [groupRows, setGroupRows] = useState(2);
  const [hasMoreGroups, setHasMoreGroups] = useState(true);
  const windowSize = useWindowSize();

  const roomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);

  const {
    rooms,
    loadRooms,
    followedRooms,
    loadUserFollowings,
    loading: roomsAreLoading,
    toggleFollowRoom,
  } = roomsContext;
  const { getCurrentUserId, getCurrentUserDisplayName } = userContext;

  const routeIsPromotedPortal = matchPath(pathname, {
    path: routes.groups,
    isExact: true,
  });
  const { logEvent } = useLogEvent();

  useEffect(async () => {
    await loadRooms(getCurrentUserId());
    await loadUserFollowings(getCurrentUserId());
  }, []);

  useEffect(() => {
    if (windowSize.width < 768) {
      setGroupsNumber(groupRows);
    } else if (windowSize.width < 1100) {
      setGroupsNumber(groupRows * 2);
    } else if (windowSize.width < 1700) {
      setGroupsNumber(groupRows * 3);
    } else {
      setGroupsNumber(groupRows * 4);
    }
  }, [windowSize.width, groupRows]);

  const sidebarWidth = getUserSidebarWidth();

  const generatePath = ({ newRoomId, newState }) => {
    if (newRoomId && newState) {
      return `${routes.dashboard}/state/${newState}/room/${newRoomId}`;
    }

    if (newRoomId) {
      return `${routes.dashboard}/state/upcoming/room/${newRoomId}`;
    }

    if (newState) {
      return `${routes.dashboard}/state/${newState}${search}`;
    }

    return `${routes.dashboard}/state/upcoming${search}`;
  };

  const filterWithState = (newState) => {
    const path = generatePath({ newRoomId: roomId, newState });
    history.push(path);
  };

  const filterWithRoom = (newRoomId) => {
    const path = generatePath({ newRoomId, state });
    if (newRoomId) {
      history.push({
        pathname: routes.room
          .replace(':roomId', newRoomId)
          .replace(':tab', 'all'),
      });
    } else {
      // handle dashboard without filters
      history.push({ pathname: path });
    }
  };

  if (!shouldRenderNavbar(pathname)) {
    return null;
  }

  if (!shouldRenderDashboardEventsFilter(pathname)) {
    return null;
  }

  const dropdownItems = [];

  if (!roomsAreLoading && rooms) {
    for (const room of rooms) {
      if (
        routeIsPromotedPortal &&
        categoryId &&
        room.roomCategorisationID !== categoryId
      ) {
        continue;
      }
      if (room.isLive) {
        dropdownItems.push({
          text: room.title,
          onClick: () => {
            filterWithRoom(room.id);
          },
        });
      }
    }

    dropdownItems.unshift({
      text: 'All Events',
      onClick: () => {
        filterWithRoom(null);
      },
    });
  }

  const getSelectedRoomTitle = () => {
    if (roomId) {
      const room = rooms.find((r) => r.id === roomId);

      if (room) {
        return room.title;
      }
    }

    return 'All Creators';
  };

  const handleFollowRoom = async () => {
    if (roomId) {
      await toggleFollowRoom(
        roomId,
        getCurrentUserId(),
        getCurrentUserDisplayName()
      );
      if (!isFollowed) {
        logEvent('follow', {
          category: 'engagement',
          creator_id: roomId,
        });
      }
    }
  };

  const showMoreGroups = () => {
    setGroupRows((prevState) => prevState + 1);
  };

  const isFollowed = followedRooms.find(
    (room) => room.sponsorRoomID === roomId
  );

  const noUpcomingIndication = history?.location?.state?.noUpcomingIndication;
  const isDashboard = pathname.includes('/dashboard');
  const relativeStyles = {
    position: 'relative',
    marginLeft: -32,
  };

  const fixedStyles = {
    position: 'fixed',
    left: sidebarWidth,
  };

  return (
    <>
      <CategoryFilter />

      {!search ? (
        <div
          id='dashboard-events-filter'
          style={!isDashboard ? fixedStyles : relativeStyles}
          className='events-filter flex-col'
        >
          <div className='flex flex-1 w-full flex-col mb-8'>
            <div className='flex items-center w-full mb-6'>
              <Heading headingSize={4} classes='flex font-bold mr-4'>
                Creators
              </Heading>
              <Link to={routes.allCreators} className='events-filter-all-btn'>
                All Creators
              </Link>
            </div>
            <CreatorList all={false} />
          </div>
          <div className='flex flex-1 w-full flex-col mb-11'>
            <div className='flex items-center w-full mb-6'>
              <Heading headingSize={4} classes='font-bold mr-4'>
                Groups
              </Heading>
              <Link to={routes.allGroups} className='events-filter-all-btn'>
                All Groups
              </Link>
            </div>
            <RoomGroups
              all={false}
              setHasMoreGroups={setHasMoreGroups}
              groupsNumber={groupsNumber}
            />
          </div>
          <div className='flex w-full justify-center items-center mb-10'>
            <div className='flex-grow h-px border-b border-secondaryB opacity-50 -ml-8'></div>
            {hasMoreGroups && (
              <div
                className='events-filter-all-btn mx-6 cursor-pointer'
                onClick={showMoreGroups}
              >
                Show more
                <Icon
                  name='forward'
                  size={24}
                  color={themeColors.primaryA}
                  classes={'forword'}
                />
              </div>
            )}
            <div className='flex-grow h-px border-b border-secondaryB opacity-50 -mr-8'></div>
          </div>
        </div>
      ) : null}

      <div className='flex-row flex w-full flex-1 justify-between'>
        <div className='events-filter__flex'>
          {!routeIsPromotedPortal && (
            <Heading headingSize={4} classes='font-bold mr-4'>
              {getSelectedRoomTitle()}
            </Heading>
          )}
          {/* {!roomId && routeIsPromotedPortal && (
            <CategoryPromotionButton className='mr-4' />
          )} */}
          {roomId && (
            <Button
              buttonId='follow'
              buttonType='secondary'
              buttonSize='small'
              isLink={false}
              classes='ml-4 text-center xl:text-left xl:ml-8 xl:flex'
              icon={isFollowed ? 'check' : 'heart'}
              iconClasses='m-auto xl:mr-2 text-primaryA'
              disabled={false}
              buttonOnClick={handleFollowRoom}
            >
              <span className='hidden xl:inline-block'>
                {isFollowed ? 'Followed' : 'Follow'}
              </span>
            </Button>
          )}
        </div>
        <div className='events-filter__flex'>
          {/* {!roomId && !routeIsPromotedPortal && (
            <CategoryPromotionButton className='mr-4' />
          )} */}
          {!noUpcomingIndication && !routeIsPromotedPortal && (
            <button
              className={`events-filter__btn ${
                state === 'upcoming' ? 'active' : ''
              }`}
              onClick={() => filterWithState('upcoming')}
            >
              <Icon name={'calendar'} size={16} classes='mr-2' />
              Upcoming
            </button>
          )}
          {!noUpcomingIndication && !routeIsPromotedPortal && (
            <button
              className={`events-filter__btn ${
                state === 'live' ? 'active' : ''
              }`}
              onClick={() => filterWithState('live')}
            >
              <Icon name={'broadcast-online'} size={16} classes='mr-2' />
              Live
            </button>
          )}
          {!noUpcomingIndication && !routeIsPromotedPortal && (
            <button
              className={`events-filter__btn ${
                state === 'ended' ? 'active' : ''
              }`}
              onClick={() => filterWithState('ended')}
            >
              <Icon name={'history'} size={16} classes='mr-2' />
              Previous
            </button>
          )}
          {/* <DropdownList items={dropdownItems} maxHeight={512}>
            <span className='events-filter__btn mr-0'>
              {getSelectedRoomTitle()}
            </span>
          </DropdownList> */}
        </div>
      </div>
    </>
  );
};

DashboardEventsFilter.propTypes = {
  event: PropTypes.string.isRequired,
};

DashboardEventsFilter.defaultProps = {
  event: '',
};

export default DashboardEventsFilter;
