import React, { Component } from 'react';
import { Auth, API, graphqlOperation, Storage } from 'aws-amplify';
import history from '../history';
import routes from '../routes';
import { UserContext } from './user';
import {
  isEmailValid,
  isMobileNumberValid,
  generatePassword,
  resizeImage,
  logError,
  setCurrentUserInLocalStorage,
  setCookie,
  getCookie,
} from '../helpers';
import { getToken, setUserId, analytics } from '../firebase';

import { getUser, listUsers, usersByIsWild } from '../graphql/queries';
import { customUpdateUser, deleteUser } from '../graphql/mutations';
import { updateFcmId } from '../graphql/customMutations';
import {
  onCustomUpdateUser,
  onCreatorPermissionUpdate,
} from '../graphql/customSubscriptions';

import awsExports from '../aws-exports';
const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class UserProvider extends Component {
  constructor(props) {
    super(props);

    this.newPasswordRequiringStatuses = [
      'NEW_PASSWORD_REQUIRED',
      'FORCE_CHANGE_PASSWORD',
    ];

    this.state = {
      user: null,
      userHasSignedIn: false,
      loading: false,
      userImageUrl: null,
      subscribedForNews: false,
      isCreator: false,
      updateUserPhone: this.updateUserPhone,
      updateUserEmail: this.updateUserEmail,
      setUserImageUrl: this.setUserImageUrl,
      getCurrentUserId: this.getCurrentUserId,
      getCurrentUserLevel: this.getCurrentUserLevel,
      getCurrentUserInventory: this.getCurrentUserInventory,
      getCurrentUserDisplayName: this.getCurrentUserDisplayName,
      getCurrentUserImageKey: this.getCurrentUserImageKey,
      getCurrentUserEmail: this.getCurrentUserEmail,
      updateInventory: this.updateInventory,
      subscribeToUserUpdate: this.subscribeToUserUpdate,
      subscribeToCreatorPermissionUpdate:
        this.subscribeToCreatorPermissionUpdate,
      unsubscribeFromUserUpdate: this.unsubscribeFromUserUpdate,
      loadCurrentUser: this.loadCurrentUser,
      updateCurrentUser: this.updateCurrentUserMain,
      signOut: this.signOut,
      signIn: this.signIn,
      sendLoginCode: this.sendLoginCode,
      signUp: this.signUp,
      updateUserPhoneAttribute: this.updateUserPhoneAttribute,
      updateUserEmailAttribute: this.updateUserEmailAttribute,
      sendPhoneConfirmCodeToUser: this.sendPhoneConfirmCodeToUser,
      sendEmailConfirmCodeToUser: this.sendEmailConfirmCodeToUser,
      confirmAttribute: this.confirmAttribute,
      getUserDBData: this.getUserDBData,
      getUserAttributes: this.getUserAttributes,
      getUserDataFromDBById: this.getUserDataFromDBById,
      deleteUserAccount: this.deleteUserAccount,
      getWildUsers: this.getWildUsers,
      getAllWildUsers: this.getAllWildUsers,
      getUsersByDisplayNameTerm: this.getUsersByDisplayNameTerm,
      log: (message) => {
        logError(message);
      },
    };
  }

  getUsersByDisplayNameTerm = async (
    nextToken = null,
    displayNameTerm = null
  ) => {
    try {
      let items = [];
      let newNextToken = nextToken;

      do {
        const input = { limit: 100, nextToken: newNextToken };

        if (displayNameTerm && displayNameTerm.length > 0) {
          input.filter = {
            displayNameLowerCase: {
              contains: displayNameTerm.toLowerCase(),
            },
          };
        }

        const res = await API.graphql(
          graphqlOperation(listUsers, { ...input })
        );

        const itemsWithDisplayName = res.data.listUsers.items.filter(
          (item) => item.displayName.trim().length > 0
        );

        items = [...items, ...itemsWithDisplayName];
        newNextToken = res.data.listUsers.nextToken;
      } while (items.length <= 10 && newNextToken !== null);

      return {
        users: items,
        nextToken: newNextToken,
        error: null,
      };
    } catch (error) {
      logError('getUsersByDisplayNameTerm', error);
      return {
        users: [],
        nextToken: null,
        error: error,
      };
    }
  };

  getWildUsers = async (nextToken = null, displayNameTerm = null) => {
    try {
      let items = [];
      let newNextToken = nextToken;

      do {
        const input = { isWild: 1, limit: 50, nextToken: newNextToken };

        if (displayNameTerm && displayNameTerm.length > 0) {
          input.filter = {
            displayNameLowerCase: {
              contains: displayNameTerm.toLowerCase(),
            },
          };
        }
        const res = await API.graphql(
          graphqlOperation(usersByIsWild, { ...input })
        );

        const itemsWithDisplayName = res.data.usersByIsWild.items.filter(
          (item) => item.displayName.trim().length > 0
        );

        items = [...items, ...itemsWithDisplayName];
        newNextToken = res.data.usersByIsWild.nextToken;
      } while (items.length >= 10 && newNextToken === null);

      return {
        users: items,
        nextToken: newNextToken,
        error: null,
      };
    } catch (error) {
      logError('searchWildUsers', error);
      return {
        users: [],
        nextToken: null,
        error: error,
      };
    }
  };

  getAllWildUsers = async () => {
    try {
      let items = [];
      let newNextToken = null;

      do {
        const input = { isWild: 1, limit: 50, nextToken: newNextToken };
        const res = await API.graphql(
          graphqlOperation(usersByIsWild, { ...input })
        );

        const itemsWithDisplayName = res.data.usersByIsWild.items.filter(
          (item) => item.displayName.trim().length > 0
        );

        items = [...items, ...itemsWithDisplayName];
        newNextToken = res.data.usersByIsWild.nextToken;
      } while (newNextToken !== null);

      return {
        users: items,
        error: null,
      };
    } catch (error) {
      logError('searchWildUsers', error);
      return {
        users: [],
        error: error,
      };
    }
  };

  deleteUserAccount = async () => {
    const id = this.getCurrentUserId();

    try {
      await API.graphql(graphqlOperation(deleteUser, { input: { id } }));
      return { success: true, error: null };
    } catch (error) {
      logError(error);
      return { success: false, error: 'Error deleting account' };
    }
  };

  setUserImageUrl = (url) => this.setState({ userImageUrl: url });

  getCurrentUserId = () => this.state.user?.attributes?.sub || null;

  getCurrentUserLevel = () => {
    const userData = this.getUserDBData();
    return userData?.level || 0;
  };

  getCurrentUserInventory = () => {
    const userData = this.getUserDBData();
    return userData?.inventory || '{}';
  };

  getCurrentUserDisplayName = () => {
    const userData = this.getUserDBData();
    return userData?.displayName || '';
  };

  getCurrentUserImageKey = () => {
    const userData = this.getUserDBData();
    return userData?.image?.key || '';
  };

  getCurrentUserEmail = () => {
    const userData = this.getUserDBData();
    return userData?.email || '';
  };

  subscribeToUserUpdate = async (userId, handleUpdate) => {
    const userUpdateSubscription = await API.graphql(
      graphqlOperation(onCustomUpdateUser, {
        id: userId,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        if (value?.data?.onUpdateUser?.inventory) {
          handleUpdate(value.data.onUpdateUser.inventory);
        }

        return '{}';
      },
      error: (error) => logError(error),
    });

    return userUpdateSubscription;
  };

  subscribeToCreatorPermissionUpdate = async () => {
    const userId = this.state.getCurrentUserId();

    if (!userId) {
      return null;
    }

    const sub = await API.graphql(
      graphqlOperation(onCreatorPermissionUpdate, {
        id: userId,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        const perm = value?.data?.onUpdateUser?.creator;
        if (
          typeof perm === 'boolean' &&
          this.getUserDBData().creator !== perm
        ) {
          window.location.reload();
        }
      },
      error: (error) => logError(error),
    });

    return sub;
  };

  unsubscribeFromUserUpdate = (subscription) => {
    if (subscription?.unsubscribe) {
      logError(subscription);
      subscription.unsubscribe();
    }
  };

  loadCurrentUser = async (setInventory) => {
    try {
      this.setState({ loading: true });

      const user = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);

      for (const attr of attributes) {
        user.attributes[attr.Name] = attr.Value;
      }

      user['dbData'] = await this.getUserDataFromDB(user);

      getToken(this.updateUserFcmId);

      if (typeof setInventory === 'function') {
        setInventory(
          user.dbData.data.getUser.inventory,
          user.dbData.data.getUser.coins
        );
      }

      setCurrentUserInLocalStorage(user);
      setUserId(user.attributes.sub);

      const globalCookie = getCookie('globalCookie');

      if (globalCookie) {
        const value = JSON.parse(globalCookie);
        if (value.analytics === true) {
          const cookie = getCookie(user.attributes.sub);
          if (!cookie) {
            setCookie(
              user.attributes.sub,
              JSON.stringify({ analytics: true }),
              365
            );
            analytics();
          }
        }
      }
      this.setState({
        user: user,
        isCreator: user.dbData.data.getUser.creator,
        loading: false,
        userHasSignedIn: true,
      });
    } catch (error) {
      this.setState({
        user: null,
        loading: false,
        isCreator: false,
      });
      this.state.log('Auth error' + error);
    }
  };

  updateCurrentUserMain = async (newData) => {
    const { imgFile, ...rest } = newData;
    const user = this.state.user;
    const userId = user.attributes.sub;

    if (!imgFile) {
      return await this.updateCurrentUser({ ...rest });
    }

    const resizedFile = await resizeImage(imgFile);
    const fileType = resizedFile.type;

    if (!fileType.includes('image')) {
      return { success: false, error: new Error('File is not an image') };
    }

    const ext = fileType.substring(fileType.indexOf('/') + 1);
    const fileName = `${Date.now()}-${userId}.${ext}`;
    const s3PutRes = await Storage.put(fileName, resizedFile, {
      contentType: fileType,
    });

    if (s3PutRes) {
      const image = await this.getS3ImageObject(s3PutRes);

      const updateData = {
        ...rest,
        image,
      };

      return await this.updateCurrentUser(updateData, false);
    }

    return { success: false, error: 'Could not update user' };
  };

  signOut = async () => {
    try {
      await Auth.signOut();
      this.setState({
        user: null,
        userHasSignedIn: false,
        userImageUrl: null,
        subscribedForNews: false,
        isCreator: false,
      });
      setCurrentUserInLocalStorage(null);
      setUserId(null);
      history.push(routes.login);
    } catch (error) {
      this.state.log('error signing out' + error);
      return false;
    }
  };

  signIn = async (value) => {
    try {
      const user = await Auth.signIn(value);
      this.setState({
        user: user,
      });

      return true;
    } catch (error) {
      this.state.log('error signing in: ' + error.message);
      return false;
    }
  };

  sendLoginCode = async (code, setInventory) => {
    try {
      const user = await Auth.sendCustomChallengeAnswer(this.state.user, code);

      user['dbData'] = await this.getUserDataFromDB(user);

      this.setState({
        user: user,
        userHasSignedIn: true,
      });

      await this.state.loadCurrentUser(setInventory);

      return { success: true, error: null };
    } catch (error) {
      this.state.log('error signing in' + error);
      return { success: false, error: 'Wrong code. Please try again' };
    }
  };

  signUp = async (userInput, subscribedForNews = false) => {
    const isEmail = isEmailValid(userInput);
    const isMobile = isMobileNumberValid(userInput);
    const userData = {
      password: generatePassword(),
    };

    if (isMobile) {
      userData.username = userInput.replace('+', '');
      userData.attributes = {
        phone_number: userInput,
        email: '',
      };
    }

    if (isEmail) {
      userData.username = userInput.replace('@', '');
      userData.attributes = {
        email: userInput,
      };
    }

    try {
      await Auth.signUp(userData);
      await Auth.signOut();
      await this.state.signIn(userInput);

      this.setState({ subscribedForNews });

      return { success: true };
    } catch (error) {
      logError(error);
      this.state.log('error signing up ' + JSON.stringify(error, null, 2));
      let userError = 'Something went wrong. Please try again.';

      if (
        error?.message ===
        'PreSignUp failed with error User with that email already exists.'
      ) {
        userError = 'User with that email already exists.';
      }

      if (
        error?.message ===
        'PreSignUp failed with error User with that phone number already exists.'
      ) {
        userError = 'User with that phone number already exists.';
      }

      return { success: false, error: userError };
    }
  };

  updateUserPhoneAttribute = async (phoneNumber) => {
    try {
      const res = await Auth.updateUserAttributes(this.state.user, {
        phone_number: phoneNumber,
      });
      if (res === 'SUCCESS') {
        return { success: true, error: null };
      }
    } catch (error) {
      this.state.log(
        'error updating user phone ' + JSON.stringify(error, null, 2)
      );
      return { success: false, error: null };
    }
  };

  updateUserEmailAttribute = async (email) => {
    try {
      const res = await Auth.updateUserAttributes(this.state.user, {
        email,
      });

      if (res === 'SUCCESS') {
        return { success: true, error: null };
      }
    } catch (error) {
      logError(error);
      this.state.log('error updating user phone' + error);
      return null;
    }
  };

  sendPhoneConfirmCodeToUser = async () => {
    try {
      await Auth.verifyUserAttribute(this.state.user, 'phone_number');
      return true;
    } catch (error) {
      this.state.log('error sending user phone confirmation code ' + error);
      return false;
    }
  };

  sendEmailConfirmCodeToUser = async () => {
    try {
      await Auth.verifyUserAttribute(this.state.user, 'email');
      return true;
    } catch (error) {
      this.state.log('error sending user email confirmation code ' + error);
      return false;
    }
  };

  confirmAttribute = async (attr, code) => {
    try {
      const user = await Auth.currentUserPoolUser();
      return await Auth.verifyUserAttributeSubmit(user, attr, code);
    } catch (error) {
      this.state.log(
        'error confirming user ' + attr + JSON.stringify(error, null, 2)
      );
      return { error: error.message };
    }
  };

  updateInventory = (itemId, amount) => {
    const user = this.state.user;
    const dbData = this.getUserDBData();
    const inventory = dbData.inventory;

    try {
      const inventoryObj = JSON.parse(inventory);
      if (inventoryObj?.[itemId]?.count) {
        inventoryObj[itemId].count = inventoryObj[itemId].count + amount;
        dbData.inventory = JSON.stringify(inventoryObj);
        this.setState({
          user: { ...user, dbData: { data: { getUser: dbData } } },
        });
      }
    } catch (error) {
      logError(error);
    }
  };

  updateUserFcmId = async (fcmID = 'default') => {
    const userId = this.state.getCurrentUserId();
    try {
      await API.graphql(
        graphqlOperation(updateFcmId, {
          id: userId,
          fcmID,
        })
      );
    } catch (error) {
      logError('Error updating FCM ID');
    }
  };

  getUserDBData = () => {
    return this.state.user?.dbData?.data?.getUser || null;
  };

  updateUserEmail = async (email) => {
    try {
      const newData = { email };
      const user = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);

      for (const attr of attributes) {
        user.attributes[attr.Name] = attr.Value;
      }

      if (!user.Session) {
        const session = await Auth.currentSession();
        user.Session = session;
      }

      user['dbData'] = await this.getUserDataFromDB(user, false);

      const input = {
        ...user.dbData.data.getUser,
        ...newData,
      };

      await API.graphql(graphqlOperation(customUpdateUser, { ...input }));

      return { success: true };
    } catch (error) {
      logError(error);
      return {
        success: false,
        error: error?.errors[0]?.message || 'Could not update email!',
      };
    }
  };

  updateUserPhone = async (phone) => {
    try {
      const newData = { phone: phone };
      const user = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);

      for (const attr of attributes) {
        user.attributes[attr.Name] = attr.Value;
      }

      if (!user.Session) {
        const session = await Auth.currentSession();
        user.Session = session;
      }

      user['dbData'] = await this.getUserDataFromDB(user, false);

      const input = {
        ...user.dbData.data.getUser,
        ...newData,
      };

      await API.graphql(graphqlOperation(customUpdateUser, { ...input }));

      return { success: true };
    } catch (error) {
      logError(error);
      return {
        success: false,
        error: error?.errors[0]?.message || 'Could not update phone number!',
      };
    }
  };

  updateCurrentUser = async (newData, getImgUrl = true) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const attributes = await Auth.userAttributes(user);

      for (const attr of attributes) {
        user.attributes[attr.Name] = attr.Value;
      }

      if (!user.Session) {
        const session = await Auth.currentSession();
        user.Session = session;
      }

      user['dbData'] = await this.getUserDataFromDB(user, getImgUrl);

      const input = {
        ...user.dbData.data.getUser,
        ...newData,
      };

      if (newData.displayName) {
        input.displayNameLowerCase = newData.displayName.toLowerCase();
      }

      if (newData.image) {
        this.setState({ userImageUrl: newData.image._url });
      }

      const result = await API.graphql(
        graphqlOperation(customUpdateUser, { ...input })
      );

      if (result?.data?.customUpdateUser) {
        user.dbData.data.getUser = result.data.customUpdateUser;
        setCurrentUserInLocalStorage(user);
        setUserId(user.attributes.sub);
        this.setState({
          user,
        });
      }

      return { success: true };
    } catch (error) {
      logError(error);
      let errorMsg = 'Error updating user';

      if (error?.errors?.[0]?.message) {
        if (
          error.errors[0].message === 'Attribute phone value is already taken!'
        ) {
          errorMsg = 'This phone number is already taken!';
        }

        if (
          error.errors[0].message === 'Attribute email value is already taken!'
        ) {
          errorMsg = 'This email is already taken!';
        }

        if (
          error.errors[0].message ===
          'Attribute displayName value is already taken!'
        ) {
          errorMsg = 'This username is already taken!';
        }

        if (
          error.errors[0].message ===
          'displayName is too long maximum 30 allowed!'
        ) {
          errorMsg =
            'This username is too long! Maximum 30 characters allowed.';
        }
      }

      return { success: false, error: errorMsg };
    }
  };

  getUserDataFromDB = async (user, getImgUrl = true) => {
    const dbData = await API.graphql(
      graphqlOperation(getUser, { id: user.attributes.sub })
    );

    if (getImgUrl) {
      const s3Key = dbData?.data?.getUser?.image?.key;

      if (s3Key) {
        const s3Url = `${imgBaseUrl}${s3Key}?w=120`;
        dbData.data.getUser.image._url = s3Url;
        this.setState({ userImageUrl: s3Url });
      }
    }

    return dbData;
  };

  getUserDataFromDBById = async (id) => {
    const dbData = await API.graphql(graphqlOperation(getUser, { id }));

    const s3Key = dbData?.data?.getUser?.image?.key;

    if (s3Key) {
      const s3Url = `${imgBaseUrl}${s3Key}?w=120`;
      dbData.data.getUser.image._url = s3Url;
    }

    return dbData.data.getUser;
  };

  getS3ImageObject = async (s3PutRes) => {
    const s3Url = await this.getS3Url(s3PutRes.key);

    if (s3Url) {
      return {
        bucket: awsExports['aws_user_files_s3_bucket'],
        region: awsExports['aws_user_files_s3_bucket_region'],
        key: s3PutRes.key,
        _url: s3Url,
      };
    }

    return null;
  };

  getS3Url = async (key) => {
    const s3Url = `${imgBaseUrl}${key}?w=120`;
    this.setState({ userImageUrl: s3Url });

    return s3Url;
  };

  getUserAttributes = async () => {
    return await Auth.userAttributes(this.state.user);
  };

  setUserInventory = (shopItem, type, allItems) => {
    const dbData = this.getUserDBData();
    const userInventory = JSON.parse(dbData.inventory) || {};

    if (type === 'avatar') {
      userInventory[shopItem.avatar.id] = {
        title: shopItem.title,
        factor: 1,
        itemType: 'Avatar',
        type: 'Avatar',
        duration: 1,
        activated: false,
        activeUntil: false,
        count: 1,
      };
    }

    if (type === 'gameAction') {
      const newCount = shopItem.gameActionAmount;
      const action = allItems.find((item) => item.id === shopItem.gameActionID);

      if (!action) {
        return dbData.inventory;
      }

      if (userInventory[action.id]) {
        userInventory[action.id].count =
          userInventory[action.id].count + newCount;
      } else {
        userInventory[action.id] = {
          title: action.name,
          factor: 1,
          itemType: 'GameAction',
          type: 'Game Action',
          duration: 1,
          activated: false,
          activeUntil: false,
          count: newCount,
        };
      }
    }

    if (type === 'booster') {
      const newCount = shopItem.boosterAmount;
      const booster = allItems.find((item) => item.id === shopItem.boosterID);

      if (!booster) {
        return dbData.inventory;
      }

      if (userInventory[booster.id]) {
        userInventory[booster.id].count =
          userInventory[booster.id].count + newCount;
      } else {
        userInventory[booster.id] = {
          title: booster.title,
          factor: booster.factor,
          itemType: 'Booster',
          type: booster.type,
          duration: booster.duration,
          activated: false,
          activeUntil: false,
          count: newCount,
        };
      }
    }

    return JSON.stringify(userInventory);
  };

  render() {
    return (
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}
