/* eslint-disable */
import React, { useContext } from 'react';

import { UserContext } from '../../context/user';

import UserMainInfo from './UserMainInfo';
import UserMainStats from './UserMainStats';
import UserRooms from './UserRooms';
import UserGoods from './UserGoods';
import UserSidebarSkeleton from '../../skeletons/UserSidebarSkeleton';

const Index = ({ setSidebarState }) => {
  const userContext = useContext(UserContext);
  const { user, userImageUrl, loading: userIsLoading } = userContext;

  if (userIsLoading) {
    return <UserSidebarSkeleton />;
  }

  if (!user) {
    return null;
  }

  return (
    <div
      onLoad={() => setSidebarState(true)}
      id='user-sidebar'
      className='pt-8 px-4 border-r border-secondaryB col-span-3 xl:px-8 xl:pt-6 2xl:col-span-2 3xl:px-10'
    >
      <UserMainInfo user={user} imageUrl={userImageUrl} />
      <div className='divider mb-6'></div>
      <UserMainStats user={user} />
      <div className='divider mb-6'></div>
      <UserRooms />
      <UserGoods />
    </div>
  );
};

export default Index;
