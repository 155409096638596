/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import Button from '../common/Button';

const CroppingTool = ({ file, onCrop, close }) => {
  const [crop, setCrop] = useState();
  const [imgSrc, setImgSrc] = useState('');
  const [aspect, setAspect] = useState(16 / 9);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [croppedImageUrl, setCroppedImageUrl] = useState('');
  const [croppedFile, setCroppedFile] = useState(null);

  const imgRef = useRef(null);

  useEffect(() => {
    if (file) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      );
      reader.readAsDataURL(file);
    }
  }, []);

  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e) => {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  };

  const onCropCompleted = (crop) => {
    if (imgRef && crop.width && crop.height) {
      const croppedUrl = getCroppedImg(imgRef.current, crop);
      setCroppedImageUrl(croppedUrl);
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width * scaleX);
    canvas.height = Math.ceil(crop.height * scaleY);
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        dataURLtoFile(reader.result, 'cropped.jpg');
      };
    });
  };

  const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const croppedImage = new File([u8arr], filename, { type: mime });
    setCroppedFile(croppedImage);
  };

  const handleCrop = () => {
    if (croppedFile) {
      onCrop(croppedFile, croppedImageUrl);
      close();
    }
  };

  return (
    <div className='fixed inset-0 bg-white z-50 flex flex-col p-6'>
      <div className='flex w-full items-center justify-between'>
        <Heading headingSize={4}>Crop Image</Heading>
        <button onClick={() => close()}>
          <Icon name='error' />
        </button>
      </div>
      <div className='flex flex-1 items-center justify-center'>
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={onCropCompleted}
          aspect={aspect}
          className='cropping-tool-container rounded-lg'
        >
          <img
            ref={imgRef}
            alt='Crop me'
            src={imgSrc}
            style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
            onLoad={onImageLoad}
          />
        </ReactCrop>
      </div>
      <div className='flex items-center justify-end'>
        <Button
          key='cancel-crop'
          buttonType='secondary'
          buttonSize='medium'
          buttonOnClick={() => close()}
          classes='mr-4'
        >
          Cancel
        </Button>
        <Button
          key='approve-crop'
          buttonType='primary'
          buttonSize='medium'
          buttonOnClick={handleCrop}
        >
          Crop
        </Button>
        ,
      </div>
    </div>
  );
};

export default CroppingTool;
