import React from 'react';

import MyGoodsMenuContent from '../my-goods/MyGoodsMenuContent';
import Icon from '../typography/Icon';

const MyGoodsMenu = ({ isOpen, handleClose }) => {
  return (
    <div
      style={{ maxHeight: 302, height: '100%', marginBottom: 103 }}
      className={`goods-menu ${
        isOpen ? 'opacity-100 visible bottom-4' : 'opacity-0 invisible bottom-8'
      }`}
    >
      <div className='flex justify-between p-4 border-b border-secondaryB'>
        <span className='font-display font-semibold text-sm tracking-tight text-primaryC'>
          My Goods
        </span>
        <button
          onClick={handleClose}
          className='hover:text-primaryA focus:outline-none active:outline-none hover:outline-none'
        >
          <Icon name='error' size={12} />
        </button>
      </div>
      {isOpen && <MyGoodsMenuContent />}
    </div>
  );
};

export default MyGoodsMenu;
