/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { Middleware } from 'redux';
import { Handler } from './types';

const createMiddleware = (handlers: Record<string, Handler<any>>): Middleware => (store) => (next) => (action) => {
  if (Object.keys(handlers).includes(action.type)) {
    return handlers[action.type](store, next, action);
  }

  next(action);
};

export default createMiddleware;
