import React from 'react';
import Paragraph from '../typography/Paragraph';

const PollOption = ({ message, checked, setChecked }) => {
  const handleOnChange = () => {
    setChecked();
  };

  return (
    <label
      className='radio-container break-all pb-4'
      onClick={(e) => e.stopPropagation()}
    >
      <Paragraph>{message}</Paragraph>
      <input type='radio' onChange={handleOnChange} checked={checked} />
      <span className='checkmark'></span>
    </label>
  );
};

export default PollOption;
