import React from 'react';

const TabButton = ({
  label,
  onClick,
  isActive,
  className,
  disabled = false,
}) => {
  const handleClick = () => {
    if (disabled || isActive) {
      return;
    }

    onClick();
  };
  return (
    <button
      onClick={handleClick}
      className={`font-display text-base mr-8 tracking-tight transition duration-300 ease-in-out hover:text-primaryA active:text-primaryA focus:text-primaryA focus:outline-none ${
        isActive ? 'text-primaryA cursor-default' : ''
      } ${
        disabled ? 'text-secondaryB hover:text-secondaryB cursor-default' : ''
      } ${className}`}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default TabButton;
