/* eslint-disable new-cap */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Paragraph from '../typography/Paragraph';
import { useLogEvent } from '../../firebase';

import { EventsContext } from '../../context/events';
import { AlertsContext } from '../../context/alerts';

import history from '../../history';
import routes from '../../routes';

const formatDate = (date) => {
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return `${new Intl.DateTimeFormat('en-US', options).format(
    new Date(date * 1000)
  )} LT`;
};

const NotificationsItem = ({ message, date, isRead, openUrl, close }) => {
  const eventsContext = useContext(EventsContext);
  const alertsContext = useContext(AlertsContext);

  const { getCurrentEvent, getEventIdBySeoUrl } = eventsContext;
  const { addAlert } = alertsContext;
  const { logEvent } = useLogEvent();

  const handleOnClick = async () => {
    close();
    logEvent('app_notification_clicked', {
      category: 'consumer',
      app_notification_title: message,
    });
    if (!openUrl) {
      close();
      return;
    }

    const regex = /\/event\/(.*)/gm;

    let m;
    const matches = [];

    while ((m = regex.exec(openUrl)) !== null) {
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      m.forEach((match, groupIndex) => {
        matches.push(match);
      });
    }

    const seoUrl = matches[matches.length - 1];

    if (!seoUrl) {
      // URL does not lead to event
      window.open(openUrl);
      return;
    }

    const eventId = await getEventIdBySeoUrl(seoUrl);
    const event = await getCurrentEvent(eventId, true);

    if (!event || event?.state === 'inactive') {
      addAlert('danger', 'This event does not exist any more');
      return;
    }

    history.push(`${routes.event}/${seoUrl}`);
  };

  return (
    <div
      onClick={handleOnClick}
      className={`relative notifications-item ${
        openUrl ? 'cursor-pointer' : 'cursor-default'
      }`}
    >
      <Paragraph classes='font-bold whitespace-nowrap'>{message}</Paragraph>
      <Paragraph paragraphSize='medium'>{formatDate(date)}</Paragraph>
      {!isRead && (
        <div className='absolute w-1 h-1 rounded-full bg-primaryB left-2 top-1/2 transform -translate-y-1/2'></div>
      )}
    </div>
  );
};

NotificationsItem.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func,
  date: PropTypes.number,
  isRead: PropTypes.bool,
};

NotificationsItem.defaultProps = {
  message: '',
  onClick: () => {},
};

export default NotificationsItem;
