import React, { useContext, useEffect } from 'react';
import Icon from '../typography/Icon';
import NotificationsDropdown from './NotificationsDropdown';

import { NotificationsContext } from '../../context/notifications';
import { UserContext } from '../../context/user';

const Notifications = ({ classes }) => {
  const notificationsContext = useContext(NotificationsContext);
  const userContext = useContext(UserContext);

  const {
    notifications,
    loadNotifications,
    markAsRead,
    subscribeToNotifications,
    clearNotifications,
  } = notificationsContext;
  const { getCurrentUserId } = userContext;

  useEffect(() => {
    if (getCurrentUserId()) {
      loadNotifications(getCurrentUserId());
    }
  }, []);

  useEffect(async () => {
    if (getCurrentUserId) {
      await subscribeToNotifications(getCurrentUserId());
    }
  }, []);

  let unreadCount = 0;

  for (const n of notifications) {
    if (!n.read) {
      unreadCount++;
    }
  }

  const handleMarkAsRead = () => {
    if (unreadCount > 0) {
      markAsRead(getCurrentUserId());
    }
  };

  const clear = () => {
    clearNotifications(getCurrentUserId());
  };

  const handleIconClick = () => {
    handleMarkAsRead();
  };

  return (
    <div className={`inline-block relative ${classes}`}>
      <NotificationsDropdown
        items={notifications}
        showArrow={false}
        updateNotifications={handleMarkAsRead}
        clear={clear}
      >
        {unreadCount > 0 && (
          <div className='absolute -top-2 -right-2 rounded-full bg-primaryB text-white font-display font-bold text-xs w-4 h-4 text-center'>
            {unreadCount}
          </div>
        )}
        <span
          className='inline-block focus:outline-none'
          onClick={handleIconClick}
        >
          <Icon name='bell' />
        </span>
      </NotificationsDropdown>
    </div>
  );
};

export default Notifications;
