/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import {
  createAction,
  createAsyncAction,
} from "typesafe-actions";

import { Subscription } from "domain/types";

export const subscriptionsPageMounted = createAction('Subsriptions/MOUNTED')<string>();

export const loadSubscriptions = createAsyncAction(
  'Subsriptions/LOAD_REQUEST',
  'Subsriptions/LOAD_SUCCESS',
  'Subsriptions/LOAD_ERROR'
)<string, Subscription[], string>();
