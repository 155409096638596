import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import VideoForm from '../components/videos/VideoForm';
import Heading from '../components/typography/Heading';
import Spinner from '../components/system/Spinner';

import { getRoomSidebarWidth } from '../helpers';
import useVOD from '../hooks/useVOD';
import { AlertsContext } from '../context/alerts';
import history from '../history';
import routes from '../routes';
import { UserContext } from '../context/user';

const EditVideo = () => {
  const [video, setVideo] = useState(null);
  const sidebarWidth = getRoomSidebarWidth();
  const { loadVideo, updateVod } = useVOD();
  const { videoId } = useParams();

  const alertsContext = useContext(AlertsContext);
  const userContext = useContext(UserContext);

  const { addAlert } = alertsContext;
  const { user, isCreator, getCurrentUserId } = userContext;

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  useEffect(async () => {
    const { success, video } = await loadVideo(videoId);

    if (success) {
      if (video.userID !== getCurrentUserId()) {
        history.replace(routes.notFound);
        return;
      }

      setVideo(video);
    }
  }, []);

  const onSubmit = async (data) => {
    delete data.file;
    data.id = video.id;

    if (!data.title.trim().length) {
      return addAlert('danger', 'Title cannot be empty!');
    }

    if (!data.eventCategorisationID.length) {
      return addAlert('danger', 'Please select category!');
    }

    const { success } = await updateVod(data);

    if (!success) {
      return addAlert('danger', 'Error updating video');
    }

    addAlert('success', 'Video updated successfully');
    history.push(routes.videoList);
  };

  return (
    <>
      <Helmet>
        <title>Edit Video | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>Edit Video</Heading>
      </div>
      <div className='mt-8'>
        {video ? (
          <VideoForm
            onSubmit={onSubmit}
            videoData={video}
            buttonLabel='Update'
          />
        ) : (
          <div className='absolute inset-0 flex items-center justify-center h-full'>
            <Spinner size='small' />
          </div>
        )}
      </div>
    </>
  );
};

export default EditVideo;
