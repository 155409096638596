import { API, graphqlOperation, Storage } from 'aws-amplify';
import { createVideo, updateVideo } from '../graphql/mutations';
import {
  videosByHostSponsorRoomID,
  getVideo,
  videosByListingType,
} from '../graphql/queries';
import awsExports from '../aws-exports';
import { logError, resizeImage } from '../helpers';
import { useContext, useState } from 'react';
import { TransactionsContext } from '../context/transactions';
import { UserContext } from '../context/user';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const getS3ImageObject = (s3PutRes) => {
  return {
    bucket: awsExports['aws_user_files_s3_bucket'],
    region: awsExports['aws_user_files_s3_bucket_region'],
    key: s3PutRes.key,
  };
};

const useVOD = () => {
  const [videos, setVideos] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [loading, setLoading] = useState(false);

  const transactionsContext = useContext(TransactionsContext);
  const userContext = useContext(UserContext);

  const { loadPPVEvent } = transactionsContext;
  const { getCurrentUserId } = userContext;

  const loadPPVData = async (videoId) => {
    return await loadPPVEvent(getCurrentUserId(), videoId);
  };

  const loadVideosByRoomId = async (hostSponsorRoomID) => {
    try {
      setLoading(true);
      const res = await API.graphql(
        graphqlOperation(videosByHostSponsorRoomID, {
          hostSponsorRoomID,
          nextToken,
        })
      );

      const newItems = res.data.videosByHostSponsorRoomID.items;

      setVideos([...videos, ...newItems]);
      setNextToken(res.data.videosByHostSponsorRoomID.nextToken);
      setLoading(false);
    } catch (error) {
      logError('loadVideosByRoomId', error);
      setLoading(false);
    }
  };

  const loadVideosByListingAndRoomId = async (listing, hostSponsorRoomID) => {
    try {
      setLoading(true);
      const res = await API.graphql(
        graphqlOperation(videosByListingType, {
          hostSponsorRoomID: { eq: hostSponsorRoomID },
          listing,
          nextToken,
        })
      );

      const newItems = res.data.videosByListingType.items;

      for (const item of newItems) {
        item.startTime = item.createdAt;
        item.imageUrls = { standard: [] };

        if (item.thumbnail?.key) {
          item.imageUrls.standard.push(
            `${imgBaseUrl}${item.thumbnail.key}?w=360`
          );
        }

        await loadPPVData(item.id);
      }

      setVideos([...videos, ...newItems]);
      setNextToken(res.data.videosByListingType.nextToken);
      setLoading(false);
    } catch (error) {
      logError('loadVideosByRoomId', error);
      setLoading(false);
    }
  };

  const loadVideo = async (id) => {
    setLoading(true);

    try {
      const res = await API.graphql(
        graphqlOperation(getVideo, {
          id,
        })
      );

      setLoading(false);
      return { success: true, video: res.data.getVideo };
    } catch (error) {
      setLoading(false);
      return { success: false, video: null };
    }
  };

  const uploadThumbnail = async (image) => {
    const file = await resizeImage(image);
    const fileType = file.type;

    const ext = fileType.substring(fileType.indexOf('/') + 1);
    const fileName = `${Date.now()}.${ext}`;
    const s3PutRes = await Storage.put(fileName, file, {
      contentType: fileType,
    });

    if (!s3PutRes) {
      return { success: false, thumbnail: null };
    }

    const thumbnail = getS3ImageObject(s3PutRes);

    return { success: true, thumbnail };
  };

  const uploadVideo = async (file, onUploadProgress) => {
    try {
      const videoFileType = file.type;
      const videoExt = videoFileType.substring(videoFileType.indexOf('/') + 1);
      const videoFileName = `${Date.now()}.${videoExt}`;

      const videoS3PutRes = await Storage.put(videoFileName, file, {
        useAccelerateEndpoint: true, // use accelerated S3 endpoint
        contentType: videoFileType,
        progressCallback: (progress) => onUploadProgress(progress),
      });

      if (!videoS3PutRes) {
        return { success: false, error: 'Video not found', video: null };
      }

      const videoS3Object = getS3ImageObject(videoS3PutRes);

      return { success: true, error: null, video: videoS3Object };
    } catch (error) {
      logError('uploadVideo', error);
      return { success: false, error, video: null };
    }
  };

  const createVod = async (data, onUploadProgress) => {
    try {
      const { file, thumbnailFile, ...rest } = data;
      let thumbnailObject = null;

      if (thumbnailFile) {
        const { success, thumbnail } = await uploadThumbnail(thumbnailFile);

        if (!success) {
          return { success: false, error: 'Unsuccessful thumbnail upload' };
        }

        thumbnailObject = thumbnail;
      }

      const { success, video: videoFile } = await uploadVideo(
        file,
        onUploadProgress
      );

      if (!success) {
        return { success: false, error: 'Unsuccessful upload' };
      }

      const input = { ...rest, thumbnail: thumbnailObject, videoFile };

      await API.graphql(
        graphqlOperation(createVideo, {
          input,
        })
      );

      return { success: true, error: null };
    } catch (error) {
      logError('createVideo', error);
      return { success: false, error };
    }
  };

  const updateVod = async (data) => {
    const { thumbnailFile, ...rest } = data;
    const input = { ...rest };

    try {
      if (thumbnailFile) {
        const { success, thumbnail } = await uploadThumbnail(thumbnailFile);

        if (!success) {
          return { success: false, error: 'Unsuccessful thumbnail upload' };
        }

        input.thumbnail = thumbnail;
      }

      await API.graphql(graphqlOperation(updateVideo, { input }));

      return { success: true };
    } catch (error) {
      logError('updateVod', error);
      return { success: false };
    }
  };

  return {
    loadVideosByRoomId,
    loadVideosByListingAndRoomId,
    createVod,
    loadVideo,
    updateVod,
    videos,
    loading,
    nextToken,
  };
};

export default useVOD;
