import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../context/user';
import { AlertsContext } from '../context/alerts';
import { InventoryContext } from '../context/inventory';
// import { isEmailValid, isMobileNumberValid } from '../helpers';
import { isEmailValid } from '../helpers';
import { Helmet } from 'react-helmet';
import { useLogEvent } from '../firebase';

import NoAuthContainer from '../components/layout/NoAuthContainer';
import Input from '../components/form/Input';
import Button from '../components/common/Button';
import CodeInput from '../components/form/CodeInput';
import Spinner from '../components/system/Spinner';
import Heading from '../components/typography/Heading';

import routes from '../routes.js';
import history from '../history';

const Login = () => {
  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertsContext);
  const inventoryContext = useContext(InventoryContext);

  const {
    user,
    loading: userIsLoading,
    signIn,
    signUp,
    sendLoginCode,
  } = userContext;
  const { addAlert } = alertContext;
  const { setInventory } = inventoryContext;
  const { logEvent } = useLogEvent();

  const [userError, setUserError] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [codeInput, setCodeInput] = useState('');
  const [userText, setUserText] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [userIsRegistering, setUserIsRegistering] = useState(false);

  useEffect(() => {
    if (user?.attributes) {
      if (history?.location?.state?.from) {
        const prevLocation = history.location.state.from;

        if (prevLocation?.state?.from) {
          history.replace(prevLocation.state.from.pathname);
        } else {
          history.replace(history.location.state.from.pathname);
        }
      } else {
        if (!userIsRegistering) {
          history.replace(`${routes.dashboard}/state/upcoming`);
        }
      }
    }
  }, [user, userIsRegistering]);

  const handleCodeInput = (value) => {
    setCodeInput(value);
  };

  const handleSendCode = async () => {
    const userInput = document.getElementById('userInput')?.value || '';

    if (!userInput || userInput.trim().length === 0) {
      setUserError('Please enter an Email or Mobile number.');
      return;
    }

    const isEmail = isEmailValid(userInput);
    // const isMobile = isMobileNumberValid(userInput);
    setUserText(userInput.trim());
    if (!isEmail) {
      setUserError('Please enter either a valid Email.');
      return;
    }

    // if (!isEmail && !isMobile) {
    //   setUserError(
    //     'Please enter either a valid Email or a valid Mobile number.'
    //   );
    //   return;
    // }
    setShowSpinner(true);
    const success = await signIn(userInput);

    if (!success) {
      setShowSpinner(false);
      registerUser();
      return;
    }
    setShowSpinner(false);
    setShowCodeInput(true);
  };

  const registerUser = async () => {
    const userInput = document.getElementById('userInput')?.value || '';

    if (!isEmailValid(userInput)) {
      setUserError('Please enter either a valid Email!');
      return;
    }

    // if (!isEmailValid(userInput) && !isMobileNumberValid(userInput)) {
    //   setUserError(
    //     'Please enter either a valid Email or a valid Mobile number.'
    //   );
    //   return;
    // }

    setUserText(userInput.trim());
    setShowSpinner(true);
    const { success, error } = await signUp(userInput.trim());

    if (!success) {
      // ToDO: couldn't send confirmation code
      setShowSpinner(false);
      setUserError(error);
      return;
    }

    setShowSpinner(false);
    setShowCodeInput(true);
    setUserIsRegistering(true);
  };

  const handleLogin = async () => {
    setShowSpinner(true);
    const { success, error } = await sendLoginCode(codeInput, setInventory);

    if (!success) {
      setShowSpinner(false);
      addAlert('danger', error);
      return;
    }
    setShowSpinner(false);

    if (userIsRegistering) {
      history.push(routes.completeProfile);
    } else {
      logEvent('user_sign_in', { category: 'consumer' });
    }
  };

  const renderUserInput = () => {
    return (
      <>
        <div className='grid grid-cols-4 gap-x-8 flex-1'>
          <Input
            id='userInput'
            type='text'
            name='userInput'
            // label={
            //   <>
            //     <span>E-mail</span> OR <span>Phone</span> [+code,number]
            //   </>
            // }
            wrapClasses='mb-2 col-span-4'
            error={!!userError}
            hint={
              userError || (
                <span>
                  {/* For Example: <span className='underline'>+</span>19171234567*/}
                </span>
              )
            }
            // placeholder='Email or Phone Number'
            placeholder='Email'
            autoFocus={true}
            icon='user'
            iconIsDynamic={true}
            onEnter={handleSendCode}
          />
          <Heading headingSize={6} classes={'col-span-4'}>
            For both new and existing users, please enter your email!
            {/* For both new and existing users, please enter your email or mobile*/}
            {/* phone number (use country code +1) in the field above and follow*/}
            {/* instructions*/}
          </Heading>
        </div>
        <div className='grid grid-cols-4 gap-x-8'>
          <Button
            buttonId='signIn'
            buttonType='primary'
            buttonSize='large'
            buttonOnClick={handleSendCode}
            isLink={false}
            classes='mb-8 col-span-4 xl:mb-14 xl:col-span-2'
            disabled={false}
          >
            Sign-up / Log In
          </Button>
        </div>
      </>
    );
  };

  const handleCodeResend = async () => {
    const codeIsSent = await signIn(userText);

    if (!codeIsSent) {
      alert('danger', 'Code did not resend');
    }
  };

  const renderCodeInput = () => {
    return (
      <>
        <div className='grid grid-cols-4 gap-x-8 flex-1'>
          <CodeInput
            codeId='inputCode'
            codeType='number'
            codeFieldsNumb={6}
            codeValue={codeInput}
            codeName='inputCode'
            codeFocus={true}
            codeOnChange={handleCodeInput}
            codeDisabled={false}
            handleCodeResend={handleCodeResend}
            label={
              <>
                Please enter the verification code sent to{' '}
                <span className='font-bold'>{userText}</span>
              </>
            }
            wrapClasses='mb-6 col-span-4'
            onEnter={handleLogin}
          />
        </div>
        <div className='grid grid-cols-4 gap-x-8'>
          <Button
            buttonId='signIn'
            buttonType='primary'
            buttonSize='large'
            buttonOnClick={handleLogin}
            isLink={false}
            classes='mb-14 col-span-2'
            disabled={false}
          >
            Sign-up / Log In
          </Button>
        </div>
      </>
    );
  };

  if (userIsLoading) {
    return null;
  }

  return (
    <NoAuthContainer
      heading={
        showCodeInput ? 'Confirm Your Details' : 'Sign-up or Log In below'
      }
    >
      <Helmet>
        <title>
          {showCodeInput ? 'Code Verification | ClashTV' : 'Login | ClashTV'}
        </title>
      </Helmet>
      {showCodeInput ? renderCodeInput() : renderUserInput()}
      {showSpinner && (
        <div className='absolute inset-0 bg-primaryC bg-opacity-40 -mx-40 flex items-center justify-center'>
          <Spinner />
        </div>
      )}
    </NoAuthContainer>
  );
};

export default Login;
