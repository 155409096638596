import React, { useState, useContext } from 'react';
import Button from '../common/Button';
import Heading from '../typography/Heading';
import Modal from '../system/Modal';

import { UserContext } from '../../context/user';
import { InventoryContext } from '../../context/inventory';
import { TransactionsContext } from '../../context/transactions';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { AlertsContext } from '../../context/alerts';

const DeleteAccount = () => {
  const userContext = useContext(UserContext);
  const inventoryContext = useContext(InventoryContext);
  const transactionsContext = useContext(TransactionsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const alertContext = useContext(AlertsContext);

  const { deleteUserAccount, signOut } = userContext;
  const { clearInventory } = inventoryContext;
  const { clearTransactions } = transactionsContext;
  const { clearRooms } = sponsorRoomsContext;
  const { addAlert } = alertContext;

  const [showDialog, setShowDialog] = useState(false);

  const showConfirmDialog = () => {
    setShowDialog(true);
  };

  const hideConfirmDialog = () => {
    setShowDialog(false);
  };

  const logOut = () => {
    clearInventory();
    clearTransactions();
    clearRooms();
    signOut();
  };

  const handleDeleteAccount = async () => {
    const { success, error } = await deleteUserAccount();
    hideConfirmDialog();

    if (error && !success) {
      addAlert('danger', error);
      return;
    }

    logOut();
  };

  return (
    <div className='border-t-2 border-solid border-secondaryB pt-10'>
      <Heading
        headingSize={6}
        classes='mb-14 font-bold text-primaryC tracking-tight'
      >
        Account Actions
      </Heading>
      <div className='flex'>
        <Button
          buttonOnClick={showConfirmDialog}
          icon='danger-exclamation-mark'
          buttonType='secondary'
          buttonSize='small'
          classes='border-primaryB text-primaryB'
        >
          Delete Account
        </Button>
      </div>
      <Modal
        title='Delete Account'
        message={`Are you sure you want to delete your account?`}
        actions={[
          <Button
            key='dismiss-delete'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={hideConfirmDialog}
            classes='mr-4'
          >
            No
          </Button>,
          <Button
            key='approve-delete'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={handleDeleteAccount}
          >
            Yes
          </Button>,
        ]}
        isVisible={showDialog}
        handleClose={hideConfirmDialog}
      ></Modal>
    </div>
  );
};

export default DeleteAccount;
