module.exports = {
  primaryA: '#5200e9',
  primaryALight: '#e4d5ff',
  primaryADark: '#2f0087',
  primaryB: '#ff004f',
  primaryBlight: '#ffc4d6',
  primaryBDark: '#c4003d',
  secondaryA: '#6b7280',
  secondaryB: '#dadada',
  secondaryC: '#f9fafb',
  positive: '#40cb39',
  positiveLight: '#e2fbe0',
  warning: '#ffdb19',
  warningLight: '#fff7cd',
  primaryC: '#000',
  primaryD: '#fff',
  lightGray: '#f8f8f8',
  darkGrey: '#444444',
  grey: '#f2f2f2',
  limeGreen: '#c4f143',
  darkBlue: '#022ae0',
  darkViolet: '#180044',
  purple: '#CC4CFF',
  purpleLight: '#F5DBFF',
  purpleDark: '#A33DCC',
};
