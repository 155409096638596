import React, { useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import Heading from '../typography/Heading';
import UserRoomsItem from './UserRoomsItem';

import { SponsorRoomsContext } from '../../context/sponsorRooms';

import routes from '../../routes';

const UserRooms = () => {
  const { roomId } = useParams();
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const { followedRooms } = sponsorRoomsContext;

  if (followedRooms.length === 0) {
    return null;
  }

  const isScrollable = followedRooms.length > 7 ? true : false;

  return (
    <div className='mb-6'>
      <div className='flex justify-between align-middle mb-6'>
        <Heading headingSize={6} classes='font-bold'>
          Followed
        </Heading>
        <Link
          to={routes.dashboard}
          className='font-display font-light text-primaryA text-sm cursor-pointer tracking-tight'
        >
          All Creators
        </Link>
      </div>
      <ul
        className={isScrollable ? 'overflow-auto max-h-64 scrollable pr-4' : ''}
      >
        {followedRooms.map((followed) => {
          const room = followed.sponsorRoom;
          if (!room) {
            return null;
          }
          return (
            <UserRoomsItem
              key={room.id}
              id={room.id}
              isActive={roomId === room.id}
              name={room.title}
              sponsor={room?.image?._url}
              status={true}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default UserRooms;
