import React, { useContext, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from '../typography/Icon';
import Spinner from '../system/Spinner';
import CroppingTool from './CroppingTool';
import { AlertsContext } from '../../context/alerts';

const ImagePicker = ({
  id,
  imgSrc,
  handleSelect,
  type,
  tooltip = '',
  disabled = false,
  loading = false,
  aspectRatio = '16/9', // ['16/9', 'none']
  containerClassName = '',
  crop = false,
}) => {
  const alertsContext = useContext(AlertsContext);
  const { addAlert } = alertsContext;
  const [imageUrl, setImageUrl] = useState(null);
  const [cropIsVisible, setCropIsVisible] = useState(false);
  const [fileToCrop, setFileToCrop] = useState(null);
  // const [error, setError] = useState(false);
  const fr = new FileReader();

  const handleFileSelect = (e) => {
    if (disabled) {
      return;
    }

    const file = e?.target?.files[0];

    if (crop) {
      setCropIsVisible(true);
      setFileToCrop(file);
      return;
    }

    if (FileReader && file) {
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          if (aspectRatio === '16/9') {
            if (img.width / img.height === 16 / 9) {
              setImageUrl(fr.result);
              handleSelect(e, id);
              fr.abort();
            } else {
              addAlert('danger', 'Image must have an aspect ratio of 16:9!');
            }
          } else {
            setImageUrl(fr.result);
            handleSelect(e, id);
            fr.abort();
          }
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  };

  const onCrop = (file, url) => {
    if (FileReader && file) {
      fr.onload = function () {
        const img = new Image();
        img.onload = function () {
          setImageUrl(fr.result);
          handleSelect({ target: { files: [file] } }, id);
          fr.abort();
        };
        img.src = fr.result;
      };
      fr.readAsDataURL(file);
    }
  };

  return (
    <>
      <div
        className={`flex items-center justify-center flex-col ${containerClassName}`}
        data-tip={tooltip}
      >
        <label htmlFor={id} className='select-image relative'>
          {!imgSrc && !imageUrl ? (
            <div
              className={`empty-placeholder ${type} ${
                disabled ? 'disabled' : ''
              }`}
            >
              <Icon
                name='camera'
                color={disabled ? '#dadada' : '#fff'}
                size={48}
              />
            </div>
          ) : (
            <img
              src={imageUrl || imgSrc}
              alt='image picker'
              className={`h-full w-full object-cover shadow-card rounded-lg cursor-pointer ${type}`}
            />
          )}
          {loading && (
            <div className='absolute inset-0 flex items-center justify-center'>
              <Spinner size='small' />
            </div>
          )}
        </label>
        <div className='flex items-center justify-center mb-6 max-w-10'>
          <label
            htmlFor={id}
            className={`text-base font-display leading-6 tracking-tight font-normal ${
              disabled
                ? 'cursor-default text-secondaryB'
                : 'cursor-pointer text-primaryA'
            }`}
          >
            Upload Image
          </label>
          <input
            id={id}
            style={{ visibility: 'hidden', width: 0, height: 0 }}
            type='file'
            onChange={handleFileSelect}
            accept='image/png, image/jpeg'
            disabled={disabled}
          />
        </div>
        {tooltip && <ReactTooltip effect='solid' className='react-tooltip' />}
      </div>
      {cropIsVisible && (
        <CroppingTool
          file={fileToCrop}
          onCrop={onCrop}
          close={() => setCropIsVisible(false)}
        />
      )}
    </>
  );
};

export default ImagePicker;
