import React, { useContext, useState } from 'react';
import { UserContext } from '../../context/user';
import { AlertsContext } from '../../context/alerts';
import Heading from '../typography/Heading';
import ChangeAttributeBox from './ChangeAttributeBox';
import EditAttributeInput from './EditAttributeInput';
import AttributeCodeInput from './AttributeCodeInput';

const EditPhoneAndEmail = () => {
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [showPhoneInput, setShowPhoneInput] = useState(false);
  const [showEmailCode, setShowEmailCode] = useState(false);
  const [showPhoneCode, setShowPhoneCode] = useState(false);

  const userContext = useContext(UserContext);
  const alertContext = useContext(AlertsContext);

  const {
    user,
    sendPhoneConfirmCodeToUser,
    sendEmailConfirmCodeToUser,
    updateUserPhoneAttribute,
    updateUserEmailAttribute,
    confirmAttribute,
    updateCurrentUser,
    getUserAttributes,
    updateUserPhone,
    updateUserEmail,
  } = userContext;
  const { addAlert } = alertContext;

  const updateUser = async (newData) => {
    const result = await updateCurrentUser(newData);

    if (!result.success) {
      addAlert('danger', result.error || 'Something went wrong!');
      return;
    }

    addAlert('success', 'Successfully updated your information');
  };

  const sendCodeToPhone = async () => {
    await sendPhoneConfirmCodeToUser();
  };

  const sendCodeToEmail = async () => {
    await sendEmailConfirmCodeToUser();
  };

  const handleEmailUpdate = async (value) => {
    const res = await updateUserEmail(value);
    if (!res.success) {
      addAlert('danger', res.error);
      return;
    }
    const { success, error } = await updateUserEmailAttribute(value);
    if (!success) {
      addAlert('danger', error);
      return false;
    }

    setShowEmailCode(true);
    return await sendCodeToEmail();
  };

  const handlePhoneUpdate = async (value) => {
    const res = await updateUserPhone(value);
    if (!res.success) {
      addAlert('danger', res.error);
      return;
    }
    const { success, error } = await updateUserPhoneAttribute(value);
    if (!success) {
      addAlert('danger', error);
      return false;
    }

    setShowPhoneCode(true);
  };

  const handleEmailCodeSubmit = async (code) => {
    const res = await confirmAttribute('email', code);
    setShowEmailCode(false);

    if ('SUCCESS' !== res) {
      addAlert('danger', res.error || 'Wrong code. Please try again');
      return false;
    }

    const attributes = await getUserAttributes();

    if (attributes) {
      const emailAttr = attributes.find((a) => a.Name === 'email');
      if (emailAttr) {
        updateUser({ email: emailAttr.Value });
      }
    }
  };

  const handlePhoneCodeSubmit = async (code) => {
    const res = await confirmAttribute('phone_number', code);
    setShowPhoneCode(false);

    if ('SUCCESS' !== res) {
      addAlert('danger', res.error || 'Wrong code. Please try again');
      return false;
    }

    const attributes = await getUserAttributes();

    if (attributes) {
      const phoneAttr = attributes.find((a) => a.Name === 'phone_number');
      if (phoneAttr) {
        updateUser({ phone: phoneAttr.Value });
      }
    }
  };

  return (
    <>
      <div className='relative'>
        <Heading
          headingSize={6}
          classes='mb-14 font-bold text-primaryC tracking-tight'
        >
          Login Information
        </Heading>
        <div className='grid grid-cols-9 gap-8 pb-18'>
          <ChangeAttributeBox
            user={user}
            containerClasses='col-span-4 py-4 border-r-2 border-secondaryB'
            type='email'
            title='Email:'
            showEditInput={() => setShowEmailInput(true)}
          />
          <ChangeAttributeBox
            user={user}
            containerClasses='col-span-5 pl-20 py-4'
            type='phone'
            title='Phone Number:'
            showEditInput={() => setShowPhoneInput(true)}
          />
        </div>
      </div>
      <EditAttributeInput
        type='email'
        isOpen={showEmailInput}
        sendCode={handleEmailUpdate}
        cancel={() => setShowEmailInput(false)}
        currentValue={user?.attributes?.email || ''}
        isConfirmed={user?.attributes?.['email_verified'] || false}
      />
      <EditAttributeInput
        type='phone'
        isOpen={showPhoneInput}
        sendCode={handlePhoneUpdate}
        cancel={() => setShowPhoneInput(false)}
        currentValue={user?.attributes?.['phone_number'] || ''}
        isConfirmed={user?.attributes?.['phone_number_verified'] || false}
      />
      <AttributeCodeInput
        type='email'
        receiver=''
        onSubmit={handleEmailCodeSubmit}
        isOpen={showEmailCode}
        resendCode={sendCodeToEmail}
      />
      <AttributeCodeInput
        type='phone'
        receiver=''
        onSubmit={handlePhoneCodeSubmit}
        isOpen={showPhoneCode}
        resendCode={sendCodeToPhone}
      />
    </>
  );
};

export default EditPhoneAndEmail;
