import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { ShopItemsContext } from './shopItems';
import { listShopItems } from '../graphql/queries';
import { logError } from '../helpers';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class ShopItemsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shopItems: [],
      gameActions: [],
      boosters: [],
      avatars: [],
      packs: [],
      loading: false,
      error: null,
      loadShopItems: this.loadShopItems,
    };
  }

  loadShopItems = async () => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listShopItems));
      const items = res?.data?.listShopItems?.items || [];

      if (items.length > 0) {
        for (const i of items) {
          if (i?.image?.key) {
            i.image._url = `${imgBaseUrl}${i.image.key}?w=360`;
          }
        }
      }
      const { shopItems, gameActions, boosters, packs, avatars } =
        this.sortItems(items);

      this.setState({
        avatars,
        shopItems,
        boosters,
        gameActions,
        packs,
        loading: false,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        shopItems: [],
        gameActions: [],
        boosters: [],
        packs: [],
        loading: false,
        error: 'Error loading shop items',
      });
    }
  };

  sortItems = (items) => {
    const gameActions = [];
    const boosters = [];
    const packs = [];
    const avatars = [];
    const shopItems = [...items];

    if (shopItems.length > 0) {
      for (const i of shopItems) {
        if (i.booster && i.gameAction) {
          packs.push(i);
          continue;
        }

        if (i.booster) {
          boosters.push(i);
          continue;
        }

        if (i.gameAction) {
          gameActions.push(i);
          continue;
        }

        if (i.avatar) {
          avatars.push(i);
        }
      }
    }

    return {
      avatars,
      packs,
      boosters,
      gameActions,
      shopItems,
    };
  };

  render() {
    return (
      <ShopItemsContext.Provider value={this.state}>
        {this.props.children}
      </ShopItemsContext.Provider>
    );
  }
}
