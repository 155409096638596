import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../typography/Icon';
import { logError } from '../../helpers';

const Checkbox = ({
  children,
  checkboxID,
  checkboxName,
  classes,
  checked,
  toggle,
  disabled,
}) => {
  if (!children) {
    logError("Component Checkbox not rendered, because 'children' missing.");
    return null;
  }

  return (
    <label className={`checkbox ${classes}`}>
      <span className='checkbox-input'>
        <input
          type='checkbox'
          id={checkboxID}
          name={checkboxName}
          checked={checked}
          onChange={toggle}
          disabled={disabled}
        />
        <span
          className={`checkbox-control ${disabled ? 'cursor-default' : ''}`}
        >
          <Icon name='check' size={16} color={disabled ? '#444' : '#5200e9'} />
        </span>
      </span>
      <span
        className={`checkbox-label ${
          disabled ? 'disabled cursor-default' : ''
        }`}
      >
        {children}
      </span>
    </label>
  );
};

Checkbox.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  checkboxID: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  toggle: PropTypes.func,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  checkboxID: '',
  name: '',
  checked: false,
  toggle: () => {},
  disabled: false,
};

export default Checkbox;
