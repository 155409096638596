import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { UserReportsContext } from './user-reports';
import { createChatReport } from '../graphql/mutations';
import ReportModal from '../components/chat/ReportModal';
import { logError } from '../helpers';

export default class UserReportsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsVisible: false,
      reportingUserID: null,
      userID: null,
      chatMessage: '',
      openModal: this.openModal,
      closeModal: this.closeModal,
      createUserReport: this.createUserReport,
    };
  }

  openModal = (reportingUserID, userID, chatMessage) => {
    this.setState({
      reportingUserID,
      userID,
      chatMessage,
      modalIsVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      reportingUserID: null,
      userID: null,
      chatMessage: '',
      modalIsVisible: false,
    });
  };

  createUserReport = async (description) => {
    try {
      await API.graphql(
        graphqlOperation(createChatReport, {
          input: {
            description,
            reportingUserID: this.state.reportingUserID,
            reportedUserID: this.state.userID,
            message: this.state.chatMessage,
          },
        })
      );
    } catch (error) {
      logError(error);
      logError('User report failed');
    }
  };

  render() {
    return (
      <UserReportsContext.Provider value={this.state}>
        {this.props.children}
        <ReportModal
          modalIsVisible={this.state.modalIsVisible}
          closeModal={this.closeModal}
          createUserReport={this.createUserReport}
        />
      </UserReportsContext.Provider>
    );
  }
}
