/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { produce } from 'immer';
import {
  ActionType,
  createReducer,
} from 'typesafe-actions';

import {
  loadPpvPassEarnings,
  ppvPassEarningsTableMounted
} from './actions';
import { PpvPassEarning } from './types';

export type Actions = ActionType<typeof loadPpvPassEarnings | typeof ppvPassEarningsTableMounted>;

export interface State {
  earnings: PpvPassEarning[];
  loading: boolean;
  hasError: boolean;
  nextToken: string | null;
}

const initialState: State = {
  earnings: [],
  loading: false,
  hasError: false,
  nextToken: null,
};

const ppvPassEarningsReducer = createReducer<State, Actions>(initialState)
  .handleAction([
    ppvPassEarningsTableMounted
  ], (state) => 
    produce(state, (draft) => {
      draft.earnings = [];
      draft.loading = true;
    }))
  .handleAction([
    loadPpvPassEarnings.request
  ], (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }))
  .handleAction(loadPpvPassEarnings.success, (state, action) =>
    produce(state, (draft) => {
      draft.earnings = [...state.earnings, ...action.payload.earnings];
      draft.nextToken = action.payload.nextToken;
      draft.loading = false;
    }))
  .handleAction(loadPpvPassEarnings.failure, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.hasError = true;
      draft.nextToken = null;
    }));

export default ppvPassEarningsReducer;
