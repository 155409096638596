/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { createAction, createAsyncAction } from "typesafe-actions";
import { PpvEarningsResponse, PpvMountedActionParams } from "./types";

const KEY = 'PpvPassEarnings';
  
export const ppvPassEarningsTableMounted = createAction(`${KEY}/MOUNTED`)<PpvMountedActionParams>();
  
export const loadPpvPassEarnings = createAsyncAction(
    `${KEY}/LOAD_REQUEST`,
    `${KEY}/LOAD_SUCCESS`,
    `${KEY}/LOAD_ERROR`
)<PpvMountedActionParams, PpvEarningsResponse, string>();