import React from 'react';
import Icon from '../typography/Icon';
import themeColors from '../../theme-colors';

const SliderButton = ({ visible, direction, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={`slider-btn ${direction === 'forward' ? 'next' : 'prev'} ${
        visible ? 'visible' : ''
      }`}
    >
      <Icon
        name={direction === 'forward' ? 'forward' : 'back'}
        color={themeColors.primaryD}
      />
    </button>
  );
};

export default SliderButton;
