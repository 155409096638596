import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Heading from '../typography/Heading';
import ShopItems from './ShopItems';
import GameItem from '../gamification/GameItem';
import RecentTransactions from './RecentTransactions';

import { GameActionsContext } from '../../context/gameActions';
import { UserContext } from '../../context/user';
import { BoostersContext } from '../../context/boosters';
import { TransactionsContext } from '../../context/transactions';
import { InventoryContext } from '../../context/inventory';

const MyGoods = () => {
  const gameActionsContext = useContext(GameActionsContext);
  const userContext = useContext(UserContext);
  const boostersContext = useContext(BoostersContext);
  const transactionsContext = useContext(TransactionsContext);
  const inventoryContext = useContext(InventoryContext);

  const { actions } = gameActionsContext;
  const { user } = userContext;
  const { boosters } = boostersContext;
  const { loadMyTransactions, loading, myTransactions } = transactionsContext;
  const { inventory } = inventoryContext;

  useEffect(async () => {
    if (user) {
      await loadMyTransactions(user.attributes.sub);
    }
  }, []);

  const renderMyGoods = () => {
    const myActions = [];
    const myBoosters = [];

    if (inventory) {
      for (const b of boosters) {
        if (inventory[b.id]) {
          const myItem = inventory[b.id];
          myBoosters.push(
            <GameItem
              key={b.id}
              img={b.icon._url}
              amount={myItem.count}
              label={b.name}
              classes='mr-16 mb-6'
              itemTooltip={b.description}
            />
          );
        }
      }

      for (const a of actions) {
        if (inventory[a.id]) {
          const myItem = inventory[a.id];
          myActions.push(
            <GameItem
              key={a.id}
              img={a.icon._url}
              amount={myItem.count}
              label={myItem.title}
              classes='mr-16 mb-6 shadow-card'
              itemTooltip={a.description}
            />
          );
        }
      }
    }

    return (
      <>
        {myBoosters.length > 0 && (
          <span className='inline-block mb-8 font-display text-sm text-primaryC leading-5 tracking-tight'>
            Boosters
          </span>
        )}
        {myBoosters.length > 0 && (
          <div className='flex flex-wrap mb-10'>{myBoosters}</div>
        )}
        {myActions.length > 0 && (
          <span className='inline-block mb-8 font-display text-sm text-primaryC leading-5 tracking-tight'>
            Game Actions
          </span>
        )}
        {myActions.length > 0 && (
          <div className='flex flex-wrap mb-10'>{myActions}</div>
        )}
      </>
    );
  };

  return (
    <>
      <Helmet>
        <title>My Goods | ClashTV</title>
      </Helmet>
      <div className='col-span-full mt-28 mb-8 px-8 py-10 shadow-card rounded-lg'>
        <Heading headingSize={6} classes='mb-11 font-bold tracking-tight'>
          My Goods
        </Heading>
        {renderMyGoods()}
        <div className='w-full h-px mb-10 bg-secondaryB'></div>
        <ShopItems />
      </div>
      <div className='col-span-full mb-16 px-8 py-10 shadow-card rounded-lg'>
        <Heading headingSize={6} classes='mb-8 font-bold tracking-tight'>
          Recent Purchases:
        </Heading>
        <RecentTransactions
          loading={loading}
          transactions={myTransactions}
          actions={actions}
          boosters={boosters}
        />
      </div>
    </>
  );
};

export default MyGoods;
