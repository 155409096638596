import React from 'react';
import {
  LocalVideo,
  RemoteVideo,
  useRemoteVideoTileState,
} from 'amazon-chime-sdk-component-library-react';
const ParticipantSplitScreen = () => {
  const { tiles } = useRemoteVideoTileState();

  return (
    <div className='MiniPlayer-videoBox'>
      <div className='absolute inset-0 flex flex-1'>
        <div className='flex flex-1'>
          <LocalVideo className='flex flex-1' nameplate='Participant' />
        </div>
        <div className='flex flex-1'>
          {tiles.map((tileId) => {
            return (
              <RemoteVideo
                className='flex flex-1'
                key={tileId}
                tileId={tileId}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ParticipantSplitScreen;
