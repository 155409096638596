/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';
import useEvents from '../../hooks/useEvents';
import Heading from '../typography/Heading';
import Card from '../card/card-restyle/Card';
import { UserContext } from '../../context/user';
import BuyEventModal from './BuyEventModal';
import { TransactionsContext } from '../../context/transactions';
import { InventoryContext } from '../../context/inventory';
import { AlertsContext } from '../../context/alerts';
import history from '../../history';
import CardSkeleton from '../../skeletons/CardSkeleton';
import { EventsContext } from '../../context/events';
import useInfiniteScroll from 'react-infinite-scroll-hook';

const EventsList = () => {
  const [{ events, isLoading, error }, nextToken, fetchMoreEvents] =
    useEvents();
  const { getCurrentUserId } = useContext(UserContext);
  const { sortImageUrls } = useContext(EventsContext);
  const currentUserId = getCurrentUserId();
  const { myPPVList } = useContext(TransactionsContext);
  const { coins } = useContext(InventoryContext);
  const { addAlert } = useContext(AlertsContext);
  const [buyModalData, setBuyModalData] = useState(null);

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: nextToken !== null,
    onLoadMore: fetchMoreEvents,
  });

  const onPPVBuyAttempt = (ppvData) => {
    if (coins < ppvData.price) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      return;
    }

    setBuyModalData(ppvData);
  };

  useEffect(() => {
    const location = history.location;

    if (location.state?.ppv) {
      onPPVBuyAttempt(location.state?.ppv);
    }
  }, []);

  const renderEvents = () =>
    events?.map((event) => (
      <Card
        id={event.id}
        key={event.id}
        hostName={event.hostName}
        hostUser={event.hostUser?.displayName}
        hostSponsorRoom={event.hostSponsorRoom?.title}
        eventState={event.state}
        images={sortImageUrls(event)}
        date={event.startTime}
        title={event.title}
        description={`${event.contestorA} VS ${event.contestorB}`}
        isPPV={event.ppv || false}
        price={event.ppvPrice || 0}
        showBuyModal={onPPVBuyAttempt}
        isPurchased={myPPVList[event.id]}
        eventIsCreatedByCurrentUser={
          event.ppv && event?.hostSponsorRoom?.userID === currentUserId
        }
        roomId={event.hostSponsorRoom?.id}
        contentType='events'
        seoUrl={event.seoUrl}
      />
    ));

  const renderSkeletons = () => {
    return Array(6)
      .fill()
      .map((item, index) => <CardSkeleton key={index} />);
  };

  return (
    <div>
      {error ? (
        <Heading headingSize={5} classes='my-6 text-red-600'>
          {error}
        </Heading>
      ) : null}

      <>
        {!events.length && !isLoading && !nextToken ? (
          <div
            style={{ height: 300 }}
            className='col-span-full flex items-center justify-center'
          >
            <Heading headingSize={4} classes='text-secondaryA'>
              {`There are no events matching your criteria.`}
            </Heading>
          </div>
        ) : (
          <div className='relative grid gap-8 mr-8 mb-8 mt-6 auto-rows-card-row'>
            {renderEvents()}
          </div>
        )}
      </>

      {isLoading || nextToken ? (
        <div
          ref={sentryRef}
          className='relative grid mr-8 gap-8 mb-8 auto-rows-card-row'
        >
          {renderSkeletons()}
        </div>
      ) : null}

      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
      />
    </div>
  );
};

export default EventsList;
