/* eslint-disable prettier/prettier */
import { API, Storage, graphqlOperation } from "aws-amplify";

import { PurchasedSubscription } from "domain/types";

import { CreateSubscriptionPassPayload } from "store/subscriptionCreate/types";

import AWSService from "infrastructure/AWSService";

import {
  createSponsorRoomPayPerView,
  createTransaction,
} from "graphql/mutations";
import {
  payPerViewBySponsorRoomId,
  userSponsorRoomPayPerViewListByUserID,
} from "graphql/queries";
import { onSponsorRoomPayPerViewListItemCreateByUserID } from "graphql/subscriptions";

import {
  logError,
  resizeImage,
} from "helpers";

import {
  APISubscription,
  BuySubscriptionPayload,
  BuySubscriptionResponse,
} from "./types";

const loadSubsciptions = async (sponsorRoomId: string): Promise<APISubscription[]> => {
  try {
    const variables = {
      sponsorRoomId,
    }

    const { data: { payPerViewBySponsorRoomId: response } } = await API.graphql<any>(
      graphqlOperation(payPerViewBySponsorRoomId, variables)
    )

    return response.items;
  } catch (error) {
    logError('Subscription List error: ', error);
    throw error;
  }
}

const createSubscriptionsPass = async (payload: CreateSubscriptionPassPayload) => {
  const { image } = payload;

  try {
    let imageObj;

    if (image) {
      const file = await resizeImage(image);
      const fileType = file.type;

      if (!fileType.includes('image')) {
        logError(file, 'File is not an image');
        return;
      }

      const ext = fileType.substring(fileType.indexOf('/') + 1);
      const fileName = `${Date.now()}.${ext}`;

      const imgStorage = await Storage.put(fileName, file, {
        contentType: fileType,
      });

      if (imgStorage) {
        imageObj = AWSService.getS3ImageObject(imgStorage.key);
      }
    }

    const input = { ...payload, image: imageObj };

    return await API.graphql(graphqlOperation(createSponsorRoomPayPerView, { input }));
  } catch (error: any) {
    console.log('Subscription Create error: ', error);
    throw error;
  }
};

const loadPurchasedSubsciptions = async (userID: string): Promise<PurchasedSubscription[]> => {
  try {
    const res = await API.graphql<any>(graphqlOperation(
      userSponsorRoomPayPerViewListByUserID, { userID }
    ));

    return res?.data?.userSponsorRoomPayPerViewListByUserID?.items || [];
  } catch (error) {
    logError(`Error loading purchased subscriptions for user: ${userID}`, error);
    throw error;
  }
};

const buySubscription = async ({
  sponsorRoomPayPerViewID,
  isMonthPayPerView,
  userID,
  title,
  description,
}: BuySubscriptionPayload): Promise<BuySubscriptionResponse> => {
  try {
    const res = await API.graphql<any>(graphqlOperation(
      createTransaction, { input: {
        sponsorRoomPayPerViewID,
        isMonthPayPerView,
        userID,
        description: `Bought ${title}, ${description}`,
      }}
    ));

    return res?.data?.createTransaction || null;
  } catch (err) {
    logError(`Error purhcasing subscription for
      user: ${userID},
      ppv: ${title},
      id: ${sponsorRoomPayPerViewID}`,
    err);
    throw err;
  }
};

const subscribeForRoomSubscriptions = (userID: string) => API.graphql(
  graphqlOperation(onSponsorRoomPayPerViewListItemCreateByUserID, {
    userID,
  })
);

export default {
  loadSubsciptions,
  createSubscriptionsPass,
  loadPurchasedSubsciptions,
  buySubscription,
  subscribeForRoomSubscriptions,
}