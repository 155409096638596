import React from 'react';
import PropTypes from 'prop-types';

const Label = ({ text, inputId, size, classes }) => {
  if (Object.keys(text).length === 0 && text.constructor === Object) {
    return null;
  }

  if (!inputId || inputId.trim().length === 0) {
    return null;
  }

  const renderText = (text) => {
    return text;
  };

  return (
    <label htmlFor={inputId} className={`label-${size} ${classes}`}>
      {renderText(text)}
    </label>
  );
};

Label.propTypes = {
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  inputId: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['base', 'sm', 'xs']),
  classes: PropTypes.string,
};

Label.defaultProps = {
  size: 'base',
  classes: '',
};

export default Label;
