import React from 'react';
import PropTypes from 'prop-types';

const DropdownItem = ({ text, onClick, classes }) => {
  if (!text) {
    return null;
  }

  return (
    <li className={`dropdown-item  ${classes}`} onClick={onClick}>
      {text}
    </li>
  );
};

DropdownItem.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  classes: PropTypes.string,
};

DropdownItem.defaultProps = {
  onClick: () => {},
  classes: '',
};

export default DropdownItem;
