import React, { useContext, useState, useEffect } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import DashLayout from '../dashboard/DashLayout';
import BuyEventModal from '../dashboard/BuyEventModal';
import { CardSkeletonWithRef } from '../../skeletons/CardSkeleton';
import Heading from '../typography/Heading';

import { AlertsContext } from '../../context/alerts';
import { EventsContext } from '../../context/events';
import { InventoryContext } from '../../context/inventory';
import { TransactionsContext } from '../../context/transactions';
import { UserContext } from '../../context/user';

const RoomUpcomingEvents = ({ roomId }) => {
  const [buyModalData, setBuyModalData] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [nextToken, setNextToken] = useState(null);

  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);
  const eventsContext = useContext(EventsContext);
  const alertsContext = useContext(AlertsContext);
  const inventoryContext = useContext(InventoryContext);

  const { getCurrentUserId } = userContext;
  const { loadPPVEvent, myPPVList } = transactionsContext;
  const { getEventsByStateAndRoomId } = eventsContext;
  const { addAlert } = alertsContext;
  const { coins } = inventoryContext;

  const onPPVBuyAttempt = (ppvData) => {
    if (coins < ppvData.price) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      return;
    }

    setBuyModalData(ppvData);
  };

  const loadPPVData = async (eventId) => {
    await loadPPVEvent(getCurrentUserId(), eventId);
  };

  const loadEvents = async () => {
    setLoading(true);
    const {
      success,
      events: newEvents,
      nextToken: newNextToken,
    } = await getEventsByStateAndRoomId(roomId, 'upcoming', nextToken);

    setLoading(false);

    if (success) {
      setEvents([...events, ...newEvents]);
      setNextToken(newNextToken);

      for (const e of newEvents) {
        await loadPPVData(e.id);
      }
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: nextToken !== null,
    onLoadMore: loadEvents,
  });

  useEffect(async () => {
    loadEvents();
  }, [roomId]);

  return (
    <div className='relative grid gap-8 auto-rows-card-row pt-8 mb-4'>
      {!loading && events.length === 0 && (
        <Heading headingSize={6} classes='col-span-full'>
          No upcoming events available at the moment
        </Heading>
      )}
      {/* {loading && <Loader />} */}
      <DashLayout
        events={events}
        loading={false}
        showBuyModal={onPPVBuyAttempt}
        ppvItems={myPPVList}
        currentUserId={getCurrentUserId()}
      />
      {nextToken !== null && <CardSkeletonWithRef ref={sentryRef} />}
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
      />
    </div>
  );
};

export default RoomUpcomingEvents;
