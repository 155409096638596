import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import { UserContext } from '../context/user';
import { LeftDrawerContext } from '../context/leftDrawer';
import { AlertsContext } from '../context/alerts';
import { AvatarsContext } from '../context/avatars';

import routes from '../routes';
import history from '../history';
import DefaultAvatar from '../dummy-avatar-300x300.jpg';

import Input from '../components/form/Input';
import Logo from '../components/common/Logo';
import Heading from '../components/typography/Heading';
import Button from '../components/common/Button';
import GridContainer from '../components/layout/GridContainer';
import Spinner from '../components/system/Spinner';
import Checkbox from '../components/form/Checkbox';
import { boolToInt } from '../helpers';

const CompleteProfile = () => {
  const userContext = useContext(UserContext);
  const leftDrawerContext = useContext(LeftDrawerContext);
  const alertsContext = useContext(AlertsContext);
  const avatarsContext = useContext(AvatarsContext);

  const {
    user,
    loading,
    getUserDBData,
    updateCurrentUser,
    userImageUrl,
    // subscribedForNews,
  } = userContext;
  const { toggleUserAvatars } = leftDrawerContext;
  const { addAlert } = alertsContext;
  const { selectedAvatar, setSelectedAvatar } = avatarsContext;

  const [usernameError, setUsernameError] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [termsValue, setTermsValue] = useState(false);
  const [newsletterValue, setNewsletterValue] = useState(true);
  const [notificationsValue, setNotificationsValue] = useState(true);

  if (loading) {
    // TODO: Handle with a loader
    return <>Loading</>;
  }

  if (!user) {
    history.replace({
      pathname: routes.login,
      state: { from: history.location },
    });
  }

  const dbData = getUserDBData();

  if (!dbData) {
    // TODO: Handle with an error
    return <>User database data did not load</>;
  }

  if (dbData.profileCompleted) {
    history.replace(`${routes.dashboard}/state/upcoming`);
  }

  const handleUpload = async (e) => {
    const file = e?.target?.files[0];

    // FileReader support
    if (FileReader && file) {
      const fr = new FileReader();
      fr.onload = function () {
        setSelectedAvatar(null, file, fr.result);
      };
      fr.readAsDataURL(file);
    }
  };

  const updateUser = async (data) => {
    const result = await updateCurrentUser(data);
    return result;
  };

  const saveProfile = async () => {
    const username = document.getElementById('userNameInput')?.value || '';
    const location = document.getElementById('locationInput')?.value || '';
    const imgObject = selectedAvatar?.imageObject || null;
    const imgFile = selectedAvatar?.file || null;

    const profileCompleted = username.trim().length > 0;

    setShowSpinner(true);

    if (!termsValue) {
      addAlert(
        'danger',
        'Please agree to our terms and conditions to continue with the registration!'
      );
      return;
    }

    const { success, error } = await updateUser({
      profileCompleted,
      location,
      imgFile,
      subscribedForNews: newsletterValue,
      notifications: boolToInt(notificationsValue),
      image: imgObject,
      displayName: username,
      fcmID: 'default',
    });

    if (!success) {
      setShowSpinner(false);
      addAlert('danger', error);
      return;
    }

    setShowSpinner(false);
    history.push(`${routes.dashboard}/state/upcoming`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const username = document.getElementById('userNameInput')?.value || '';

    if (!username) {
      setUsernameError('Please fill your Display name here.');

      return;
    }

    saveProfile();
  };

  const toggleDrawer = (e) => {
    e.stopPropagation();
    toggleUserAvatars();
  };

  if (!dbData) {
    return null;
  }

  const imgSrc = selectedAvatar?.url || userImageUrl || DefaultAvatar;

  return (
    <div className='max-w-1440 mx-auto'>
      <Helmet>
        <title>Complete Your Profile | ClashTV</title>
      </Helmet>
      <GridContainer>
        <div className='col-start-5 col-span-4'>
          <Logo classes='mx-auto mt-18 mb-6' />
          <Heading headingSize={2} classes='text-primaryC text-center mb-2'>
            Welcome to ClashTV
          </Heading>
          <Heading headingSize={5} classes='text-secondaryB text-center mb-16'>
            Complete your profile
          </Heading>
          <label htmlFor='imgFile' className='select-avatar'>
            <img
              src={imgSrc}
              alt='profile image'
              className='h-full w-full object-cover'
            />
          </label>
          <div className='flex items-center justify-center mb-6'>
            <button
              onClick={toggleDrawer}
              className='mr-8 text-primaryA text-base font-display leading-6 tracking-tight font-normal'
            >
              Select Avatar
            </button>
            <label
              htmlFor='imgFile'
              className='cursor-pointer text-primaryA text-base font-display leading-6 tracking-tight font-normal'
            >
              Upload Picture
            </label>
            <input
              id='imgFile'
              style={{ visibility: 'hidden', width: 0, height: 0 }}
              type='file'
              onChange={handleUpload}
              accept='image/png, image/jpeg'
            />
          </div>
          <Input
            id='userNameInput'
            type='text'
            name='username'
            initialValue={dbData.displayName.trim()}
            placeholder='User name'
            wrapClasses='mb-6'
            icon='user'
            error={usernameError.length > 0}
            hint={usernameError || ''}
            maxLength={30}
          />
          <Input
            id='locationInput'
            type='text'
            name='location'
            placeholder='Location'
            initialValue={dbData.location || ''}
            icon='location'
          />
          <Checkbox
            checkboxID='notificationsInput'
            checkboxName='notificationsInput'
            classes='col-span-4 mt-6'
            checked={notificationsValue}
            toggle={() => setNotificationsValue(!notificationsValue)}
          >
            Receive Notifications
          </Checkbox>
          <Checkbox
            checkboxID='termsInput'
            checkboxName='termsInput'
            classes='col-span-4 mt-6'
            checked={termsValue}
            toggle={() => setTermsValue(!termsValue)}
          >
            By registering you agree to our{' '}
            <a
              className='underline font-bold'
              target='_blank'
              rel='noreferrer'
              href={routes.termsOfUse}
            >
              Terms of Service
            </a>{' '}
            and{' '}
            <a
              className='underline font-bold'
              target='_blank'
              rel='noreferrer'
              href={routes.privacyPolicy}
            >
              Privacy Policy
            </a>
          </Checkbox>
          <Checkbox
            checkboxID='newsletterInput'
            checkboxName='newsletterInput'
            classes='mb-26 col-span-4'
            checked={newsletterValue}
            toggle={() => setNewsletterValue(!newsletterValue)}
          >
            Keep me up to date on news and exclusive offers
          </Checkbox>
        </div>
      </GridContainer>
      <GridContainer classes='fixed w-full max-w-1440 bottom-0 pointer-events-none'>
        <Button
          buttonId='welcomeSubmit'
          buttonType='primary'
          buttonSize='large'
          buttonOnClick={handleSubmit}
          isLink={false}
          classes='mb-18 col-start-10 col-span-2 z-10 pointer-events-auto'
          disabled={!termsValue}
        >
          Explore Clash
        </Button>
      </GridContainer>
      {showSpinner && (
        <div className='absolute inset-0 bg-primaryC bg-opacity-40 flex items-center justify-center'>
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default CompleteProfile;
