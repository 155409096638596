import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/lazy';

import Player from './ivs-player/Player';
import Spinner from './system/Spinner';
import { logError } from '../helpers';
import { logEvent } from '../firebase';

export default class VideoPlayer extends Component {
  constructor(props) {
    super(props);

    this.ivsPlayerRef = createRef();
    this.playerRef = createRef();

    this.interval = null;

    this.state = {
      muted: false,
      loading: true,
      playing: true,
      logTime: 0,
      params: {
        eventId: this.props.eventId,
        category: 'consumer',
        cl_video_type:
          this.props.type === 'live' ? 'live stream' : ' ended event',
        creator_id: this.props.roomId,
        creator_name: this.props.roomTitle || 'Not Available',
        content_category: this.props.eventCategory,
        cl_video_title: this.props.eventTitle,
      },
    };
  }

  isMuted = () => {
    return this.state.muted;
  };

  setMuted = (value) => {
    if (this.ivsPlayerRef.current) {
      this.ivsPlayerRef.current.setMuted(value);
    }

    this.setState({ muted: value });
  };

  toggleMuted = () => {
    this.setMuted(!this.isMuted());
  };

  play = () => {
    if (this.ivsPlayerRef.current) {
      this.ivsPlayerRef.current.play();
    }

    this.setState({ playing: true });
  };

  pause = () => {
    if (this.ivsPlayerRef.current) {
      this.ivsPlayerRef.current.pause();
    }

    this.setState({ playing: false });
  };

  togglePlay = () => {
    if (this.ivsPlayerRef.current) {
      if (this.isPaused()) {
        this.ivsPlayerRef.current.play();
      } else {
        this.ivsPlayerRef.current.pause();
      }
    }

    this.setState({ playing: !this.isPaused() });
  };

  isPaused = () => {
    if (this.ivsPlayerRef.current) {
      return this.ivsPlayerRef.current.isPaused();
    }

    return !this.state.playing;
  };

  getDuration = () => {
    if (this.ivsPlayerRef.current) {
      // return this.ivsPlayerRef.current.isPaused();
    }

    return this.playerRef.current.getDuration();
  };

  handleOnPlay = () => {
    this.setState({ loading: false });
    const newState = { loading: false };

    if (this.props.ivs && this.ivsPlayerRef.current) {
      newState.muted = this.ivsPlayerRef.current.isMuted();
    }

    this.setState(newState);
    this.props.onPlay();
  };

  handleError = (error) => {
    const msg = error.message || error;
    logError(error);

    // handle DOMException: play() failed because the user didn't interact with the document first.
    if (typeof msg === 'string' && msg.includes('play() failed')) {
      this.setState({ muted: true, playing: true });
    } else {
      this.props.onError(true);
      // handle stream not playing when event starts before stream is available
      this.setState({ loading: true });
      setTimeout(() => {
        this.playerRef?.current?.getInternalPlayer('hls').startLoad();
      }, 5000);
    }
  };

  handleProgress = (playedSeconds) => {
    // handle stream not playing when event starts before stream is available
    if (this.props.isDelayedlive && this.state.loading) {
      this.setState({ loading: false });
    }
    if (this.state.logTime === 100) {
      this.setState({ logTime: 0 });
    }
    if (Number.parseInt(playedSeconds) === 3) {
      if (this.props.type === 'live') {
        this.props.logEvents('start_watching', 'stream', this.props.onDuration);
      } else {
        this.props.logEvents('start_watching', 'video', this.props.onDuration);
      }
    } else if (Number.parseInt(playedSeconds) === 30) {
      if (this.props.type === 'live') {
        this.props.logEvents('watches_30sec', 'stream', this.props.onDuration);
      } else {
        this.props.logEvents('watches_30sec', 'video', this.props.onDuration);
      }
    }
    if (
      this.props.type === 'ended' &&
      playedSeconds / this.props.onDuration >= 0.15 &&
      this.state.logTime < 15
    ) {
      logEvent('percentage_watched_video', {
        ...this.state.params,
        video_progress: '15%',
      });
      this.setState({ logTime: 15 });
    } else if (
      this.props.type === 'ended' &&
      playedSeconds / this.props.onDuration >= 0.5 &&
      this.state.logTime < 50
    ) {
      logEvent('percentage_watched_video', {
        ...this.state.params,
        video_progress: '50%',
      });
      this.setState({ logTime: 50 });
    } else if (
      this.props.type === 'ended' &&
      playedSeconds / this.props.onDuration >= 0.75 &&
      this.state.logTime < 75
    ) {
      logEvent('percentage_watched_video', {
        ...this.state.params,
        video_progress: '75%',
      });
      this.setState({ logTime: 75 });
    }
  };

  handleEnded = () => {
    if (this.props.type === 'ended') {
      this.props.logEvents(
        'completed_watching',
        'video',
        this.props.onDuration
      );
      logEvent('percentage_watched_video', {
        ...this.state.params,
        video_progress: '100%',
      });
      this.setState({ logTime: 100 });
    }
  };

  render() {
    const { ivs, streamUrl } = this.props;

    if (ivs) {
      return (
        <Player
          player={this.ivsPlayerRef}
          streamUrl={streamUrl}
          onPlay={this.handleOnPlay}
          onReady={this.props.onPlayerReady}
          logEvents={this.props.logEvents}
          type={this.props.type}
          eventId={this.props.eventId}
        />
      );
    }

    return (
      <>
        <ReactPlayer
          key={this.state.reloadKey}
          id='event-video'
          ref={this.playerRef}
          onReady={() => {
            this.setState({ playing: true, loading: false });
            this.props.onPlayerReady(
              this.playerRef.current.getInternalPlayer()
            );
          }}
          onPlay={() => {
            this.handleOnPlay();
            // this.setState({ playing: true, loading: false });
          }}
          onError={(err) => this.handleError(err)}
          onDuration={this.props.onDuration}
          onProgress={({ playedSeconds }) => this.handleProgress(playedSeconds)}
          onEnded={this.handleEnded()}
          config={{
            file: {
              forceHLS: true,
              attributes: { playsInline: true },
            },
          }}
          controls={false}
          playing={this.state.playing}
          muted={this.state.muted}
          volume={this.state.muted ? 0 : 1}
          url={streamUrl}
          loop={false}
          width='100%'
          height='100%'
          className='event-video bg-black rounded-lg'
        />
        {this.state.loading && (
          <div className='absolute inset-0 flex flex-1 items-center justify-center'>
            <Spinner size='small' />
          </div>
        )}
      </>
    );
  }
}

VideoPlayer.propTypes = {
  ivs: PropTypes.bool,
  streamUrl: PropTypes.string,
  onPlay: PropTypes.func,
  onError: PropTypes.func,
  onDuration: PropTypes.func,
  onPlayerReady: PropTypes.func,
};

VideoPlayer.defaultProps = {
  ivs: false,
  streamUrl: '',
  onPlay: () => {},
  onError: () => {},
  onDuration: () => {},
  onPlayerReady: () => {},
};
