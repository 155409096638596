/* eslint-disable no-invalid-this */
import React, { useContext } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { UserContext } from '../context/user';
import routes from '../routes';
import history from '../history';
import Profile from '../components/profile/Profile';
import MyGoods from '../components/my-goods/MyGoods';
import Payments from '../components/payments/Payments';
import Coins from '../components/my-goods/Coins';
import { getUserSidebarWidth } from '../helpers';

const Account = ({ match, location }) => {
  const userContext = useContext(UserContext);
  const { user, loading: userIsLoading } = userContext;
  const activeTab = match.params.tab;
  const sidebarWidth = getUserSidebarWidth();
  const { pathname } = location;

  if (!userIsLoading && !user) {
    history.replace({ pathname: routes.login, state: { from: location } });
  }

  return (
    <>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Link
          to={routes.accountProfile}
          className={`account-tab-menu__item ${
            pathname === routes.accountProfile ? 'active' : ''
          }`}
        >
          Profile
        </Link>
        <Link
          to={routes.accountMyGoods}
          className={`account-tab-menu__item ${
            pathname === routes.accountMyGoods ? 'active' : ''
          }`}
        >
          My Goods
        </Link>
        <Link
          to={routes.accountCoins}
          className={`account-tab-menu__item ${
            pathname === routes.accountCoins ? 'active' : ''
          }`}
        >
          Coins
        </Link>
        <Link
          to={routes.accountPayments}
          className={`account-tab-menu__item ${
            pathname === routes.accountPayments ? 'active' : ''
          }`}
        >
          Transactions
        </Link>
        <Link
          to={routes.privacyPolicy}
          className={`account-tab-menu__item float-right mr-4`}
          target='_blank'
        >
          Privacy Policy
        </Link>
        <Link
          to={routes.termsOfUse}
          className={`account-tab-menu__item float-right`}
          target='_blank'
        >
          Terms of Use
        </Link>
      </div>
      {activeTab === 'profile' && <Profile />}
      {activeTab === 'my-goods' && <MyGoods />}
      {activeTab === 'coins' && <Coins />}
      {activeTab === 'payments' && <Payments />}
      <div className='col-span-9 text-right font-display text-sm text-primaryC mb-4 -mt-8'>
        For any questions, contact:{' '}
        <a href='mailto:support@clsh.tv' className='font-bold'>
          support@clsh.tv
        </a>
      </div>
    </>
  );
};

const AccountWithRouter = withRouter(Account);
export default AccountWithRouter;
