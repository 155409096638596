/* eslint-disable prettier/prettier */
import React, { FC } from "react";
import { Helmet } from "react-helmet";

import Heading from "components/typography/Heading";

import { getRoomSidebarWidth } from "helpers";
import SubscriptionPassForm from "../common/SubscriptionPassForm";

const SubscriptionPassCreate: FC = () => {
  const sidebarWidth = getRoomSidebarWidth();

  return (
    <>
      <Helmet>
        <title>Create New Premium Content Pricing | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>New Premium Content Pricing </Heading>
      </div>
      <SubscriptionPassForm />
    </>
  )
}

export default SubscriptionPassCreate;
