import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ShopItemSkeleton from '../../skeletons/ShopItemSkelleton';

import { ShopItemsContext } from '../../context/shopItems';
import { TransactionsContext } from '../../context/transactions';
import { AlertsContext } from '../../context/alerts';
import { UserContext } from '../../context/user';
import { GameActionsContext } from '../../context/gameActions';
import { InventoryContext } from '../../context/inventory';
import { StickersContext } from '../../context/stickers';

import { useLogEvent } from '../../firebase';
import moreCoins from '../../../src/assets/more-coins.png';
import Heading from '../typography/Heading';
import Modal from '../system/Modal';
import AvatarShopItem from './AvatarShopItem';
import ActionsShopItem from './ActionsShopItem';
import StickersShopItem from './StickersShopItem';
import routes from '../../routes';

const styles =
  'relative block h-24 mb-4 border border-lightGray rounded-lg bg-gradient-to-r from-primaryADark to-primaryA w-2/3 m-auto';
const textStyles =
  'absolute transform top-1/2 -translate-y-1/2 left-4 text-white font-display text-xl font-bold w-24';

const ShopItems = () => {
  const shopItemsContext = useContext(ShopItemsContext);
  const transactionsContext = useContext(TransactionsContext);
  const alertsContext = useContext(AlertsContext);
  const userContext = useContext(UserContext);
  const gameActionsContext = useContext(GameActionsContext);
  const inventoryContext = useContext(InventoryContext);
  const stickersContext = useContext(StickersContext);

  const { loadShopItems, shopItems, avatars, gameActions, loading } =
    shopItemsContext;
  const { buyShopItem } = transactionsContext;
  const { addAlert } = alertsContext;
  const { user } = userContext;
  const { actions: allActions } = gameActionsContext;
  const { updateInventoryAndCoins, inventory, coins } = inventoryContext;
  const { stickers, loadStickers } = stickersContext;
  const { logEvent } = useLogEvent();

  const [showNotEnoughCoins, setShowNotEnoughCoins] = useState(false);

  useEffect(async () => {
    if (shopItems.length === 0) {
      await loadShopItems();
      await loadStickers();
    }
  }, []);

  const getStickersInBundleCount = (bundleId) => {
    let count = 0;

    for (const s of stickers) {
      if (s.stickerBundleID === bundleId) {
        count++;
      }
    }

    return count;
  };

  const handleShopItemClick = async (shopItemId) => {
    const selectedItem = shopItems.find((item) => item.id === shopItemId);

    if (selectedItem) {
      const userId = user.attributes.sub;

      if (coins < selectedItem.price) {
        setShowNotEnoughCoins(true);
        return;
      }

      const res = await buyShopItem(selectedItem, userId);

      if (!res.success) {
        addAlert('danger', `${res.itemName} could not be purchased!`);
        return;
      }

      logEvent('spend_virtual_currency', {
        category: 'monetization',
        virtual_currency_name: 'Coins',
        value: selectedItem.price,
        item_id: selectedItem.id,
        item_name: selectedItem.title,
      });

      updateInventoryAndCoins(selectedItem, allActions);

      addAlert(
        'success',
        `${res.itemName} was successfully added to your goods!`
      );
    }
  };

  const renderActions = () => {
    if (loading) {
      return [
        <ShopItemSkeleton key={1} />,
        <ShopItemSkeleton key={2} />,
        <ShopItemSkeleton key={3} />,
      ];
    }

    if (gameActions.length === 0) {
      return null;
    }

    const sorted = gameActions.sort((a, b) => a.order - b.order);

    return sorted.map((item) => {
      return (
        <ActionsShopItem
          shopItemId={item.id}
          key={item.id}
          headline={item.title}
          multiply={item.gameActionAmount}
          coins={item.price}
          onClick={handleShopItemClick}
          bgImage={item.image?._url}
          classes='mr-16 mb-8'
        />
      );
    });
  };

  const renderStickers = () => {
    let bundlesOfStickers = [];
    const bundlesOfShopItems = [];

    for (const item of shopItems) {
      if (item.stickersBundleID) {
        bundlesOfStickers.push(item);
      }
    }

    if (bundlesOfStickers.length > 0) {
      bundlesOfStickers = bundlesOfStickers.sort((a, b) => a.order - b.order);
    }

    for (const item of bundlesOfStickers) {
      const stickersCount = getStickersInBundleCount(item.stickersBundleID);
      bundlesOfShopItems.push(
        <StickersShopItem
          shopItemId={item.id}
          key={item.id}
          headline={item.title}
          multiply={stickersCount}
          coins={item.price}
          onClick={handleShopItemClick}
          bgImage={item.image?._url || ''}
          classes='mr-16 mb-8'
          isInInventory={!!inventory?.[item.stickersBundleID]}
        />
      );
    }

    return bundlesOfShopItems;
  };

  const renderAvatars = () => {
    if (avatars.length === 0) {
      return null;
    }

    const sorted = avatars.sort((a, b) => a.avatar.order - b.avatar.order);

    return sorted.map((item) => {
      return (
        <AvatarShopItem
          key={item.id}
          id={item.id}
          image={item?.image?._url || ''}
          price={item.price}
          title={item.title}
          onClick={handleShopItemClick}
          isInInventory={!!inventory?.[item.avatar.id]}
        />
      );
    });
  };

  return (
    <>
      <Heading headingSize={6} classes='mb-11 font-bold tracking-tight'>
        Get More Action!
      </Heading>
      <div className='flex flex-wrap mb-8'>{renderActions()}</div>
      <Heading headingSize={6} classes='mb-11 font-bold tracking-tight'>
        Get More Stickers:
      </Heading>
      <div className='flex flex-wrap mb-8'>{renderStickers()}</div>
      <Heading headingSize={6} classes='mb-11 font-bold tracking-tight'>
        Get More Avatars:
      </Heading>
      <div className='flex flex-wrap mb-8'>{renderAvatars()}</div>
      <Modal
        title='Error'
        message="You don't have enough coins! Click the button below to buy more."
        actions={[]}
        isVisible={showNotEnoughCoins}
        handleClose={() => setShowNotEnoughCoins(false)}
      >
        <Link to={routes.accountCoins} className={styles}>
          <div
            className='w-full h-full'
            style={{
              backgroundImage: `url(${moreCoins})`,
              backgroundPosition: 'right, left',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
          >
            <Heading headingSize={6} classes={textStyles}>
              Get more Coins
            </Heading>
          </div>
        </Link>
      </Modal>
    </>
  );
};

export default ShopItems;
