import React, { useEffect, useRef } from 'react';
import { logError } from '../../helpers';

const CameraPreview = ({ selectedCamera }) => {
  const videoRef = useRef();

  useEffect(() => {
    const constraints = { video: { width: 1280, height: 720 } };
    let stream = null;

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (mediaStream) {
        stream = mediaStream;
        videoRef.current.srcObject = mediaStream;
        videoRef.current.onloadedmetadata = function (e) {
          videoRef.current.play();
        };
      })
      .catch(function (err) {
        logError('camera preview', err.name + ': ' + err.message);
      });

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [selectedCamera]);
  return <video ref={videoRef}></video>;
};

export default CameraPreview;
