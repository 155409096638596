import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../form/Input';
import ClickawayListener from '../system/ClickawayListener';
import Button from '../common/Button';

import { PollsContext } from '../../context/polls';
import { useLogEvent } from '../../firebase';

const NewPoll = ({ eventId, visible, onDismiss, onSubmit }) => {
  const [errors, setErrors] = useState([]);

  const questionRef = useRef(null);
  const answerARef = useRef(null);
  const answerBRef = useRef(null);
  const answerCRef = useRef(null);

  const pollsContext = useContext(PollsContext);
  const { createPoll } = pollsContext;
  const { logEvent } = useLogEvent();

  const handleSubmit = async () => {
    const question = questionRef.current.value.trim();
    const answerA = answerARef.current.value.trim();
    const answerB = answerBRef.current.value.trim();
    const answerC = answerCRef.current.value.trim();

    const newErrors = [];

    if (!question) {
      newErrors.push('question');
    }

    if (!answerA) {
      newErrors.push('answerA');
    }

    if (!answerB) {
      newErrors.push('answerB');
    }

    if (newErrors.length > 0) {
      setErrors(newErrors);
      return;
    }

    onDismiss();
    await createPoll(eventId, question, answerA, answerB, answerC);
    logEvent('create_poll', { eventId, category: 'creator' });
    setErrors([]);
    onSubmit();
  };

  if (!visible) {
    return null;
  }

  const handleDismiss = () => {
    setErrors([]);
    onDismiss();
  };

  return (
    <ClickawayListener onClick={handleDismiss}>
      <div className='new-poll-card'>
        <Input
          id='poll-question'
          placeholder='Question for participants'
          wrapClasses='w-3/4 mb-2'
          setRef={questionRef}
          error={errors.includes('question')}
        />
        <Input
          id='poll-answer-a'
          placeholder='Response A'
          wrapClasses='w-3/4 mb-2'
          setRef={answerARef}
          error={errors.includes('answerA')}
        />
        <Input
          id='poll-answer-b'
          placeholder='Response B'
          wrapClasses='w-3/4 mb-2'
          setRef={answerBRef}
          error={errors.includes('answerB')}
        />
        <Input
          id='poll-answer-c'
          placeholder='Response C'
          wrapClasses='w-3/4 mb-4'
          setRef={answerCRef}
        />
        <Button
          buttonSize='medium'
          buttonType='primary'
          buttonOnClick={handleSubmit}
        >
          Post
        </Button>
      </div>
    </ClickawayListener>
  );
};

NewPoll.propTypes = {
  eventId: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onDismiss: PropTypes.func,
  onSubmit: PropTypes.func,
};

NewPoll.defaultProps = {
  visible: false,
  onDismiss: () => {},
  onSubmit: () => {},
};

export default NewPoll;
