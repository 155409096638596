import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import screenfull from 'screenfull';
import ReactPlayer from 'react-player/lazy';

import MediaSlider from './MediaSlider';
import VideoPlayer from '../VideoPlayer';
import ParticipantSplitScreen from '../ParticipantSplitScreen';
import Icon from '../typography/Icon';
import EventLeaderBoard from '../EventLeaderBoard';
import EndedEventVideoTab from './EndedEventVideoTab';

import { InvitationsContext } from '../../context/invitations';
import { TransactionsContext } from '../../context/transactions';
import { UserContext } from '../../context/user';

import { useLogEvent } from '../../firebase';
import { logError } from '../../helpers';
import useIMA from '../../hooks/useIMA';

import EventBackgroundVideo from '../../assets/Event_End_Landscape.mp4';
import EndedEventContestorStatus from './EndedEventContestorStatus';
const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const MediaContent = ({
  eventId,
  eventState,
  eventCategory,
  eventTitle,
  teaserUrl,
  image,
  image2,
  eventMode,
  videoUrl,
  containerIsHovered,
  mediaContainer,
  gamificationHidden,
  setGamificationHidden,
  isPPV,
  eventCreatorId,
  contestorA,
  contestorB,
  activeEndedTab,
  setActiveEndedTab,
  roomId,
  roomTitle,
  isVideo,
}) => {
  const invitationsContext = useContext(InvitationsContext);
  const transactionsContext = useContext(TransactionsContext);
  const userContext = useContext(UserContext);

  const { acceptedInviteAsParticipant } = invitationsContext;
  const { loadPPVEvent, myPPVList } = transactionsContext;
  const { getCurrentUserId } = userContext;

  const [muted, setMuted] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const videoPlayerRef = useRef(null);
  const adContainerRef = useRef(null);
  const { initializeIMA, adFinished, initStarted } = useIMA();
  const { logEvent } = useLogEvent();

  const toggleMuted = () => {
    const shouldMute = !videoPlayerRef.current.isMuted();

    videoPlayerRef.current.setMuted(shouldMute);
    setMuted(shouldMute);
  };

  useEffect(() => {
    if (isPPV) {
      loadPPVEvent(getCurrentUserId(), eventId);
    }
  });

  const toggleGamificationOverlay = (e, value) => {
    let newValue = value;

    if (typeof newValue === 'undefined') {
      newValue = !gamificationHidden;
    }

    setGamificationHidden(newValue);

    if (newValue) {
      logEvent('disable_gamification_overlay', {
        eventId,
        category: 'consumer',
      });
    } else {
      logEvent('enable_gamification_overlay', {
        eventId,
        category: 'consumer',
      });
    }
  };

  const handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(mediaContainer).then(() => {
        if (screenfull.isFullscreen) {
          logEvent('enable_fullscreen', { eventId, category: 'consumer' });
        } else {
          logEvent('disable_fullscreen', { eventId, category: 'consumer' });
        }
      });
    }
  };

  const logEvents = (phrase, type, duration) => {
    const params = { eventId, category: 'consumer' };
    params[`${type}_category`] = eventCategory;
    params[`${type}_title`] = eventTitle;
    params[`${type}_url`] = videoUrl;
    params[`${type}_duration`] = duration;
    logEvent(phrase, params);
  };

  const getIsEventRestricted = () => {
    const isPurchased = !!myPPVList[eventId];
    const eventIsCreatedByCurrentUser = getCurrentUserId() === eventCreatorId;

    return isPPV && !isPurchased && !eventIsCreatedByCurrentUser;
  };

  const getMedia = () => {
    const media = [];

    if (teaserUrl) {
      media.push({
        type: 'video',
        url: teaserUrl,
      });
    }

    if (image) {
      media.push({
        type: 'image',
        url: `${imgBaseUrl}${image.key}`,
      });
    }

    if (image2) {
      media.push({
        type: 'image',
        url: `${imgBaseUrl}${image2.key}`,
      });
    }

    return media;
  };

  const handlePlayerReady = (videoElement) => {
    if (adFinished.current || initStarted.current) {
      return;
    }
    initializeIMA(eventId, videoElement, adContainerRef.current);
  };

  if (eventState === 'upcoming') {
    return <MediaSlider media={getMedia()} eventId={eventId} />;
  }

  if (eventState === 'live') {
    const eventIsRestricted = getIsEventRestricted();
    if (eventIsRestricted) {
      return <MediaSlider media={getMedia()} eventId={eventId} />;
    }

    if (
      acceptedInviteAsParticipant &&
      eventId === acceptedInviteAsParticipant.eventID
    ) {
      return <ParticipantSplitScreen />;
    }

    const isIVS = eventMode === 'battle' || eventMode === 'user';
    const isDelayedlive = eventMode === 'delayedLive';

    return (
      <>
        <VideoPlayer
          eventId={eventId}
          roomId={roomId}
          roomTitle={roomTitle}
          eventCategory={eventCategory}
          eventTitle={eventTitle}
          ref={videoPlayerRef}
          ivs={isIVS}
          isDelayedlive={isDelayedlive}
          streamUrl={videoUrl}
          onPlayerReady={handlePlayerReady}
          type='live'
          onPlay={() => {
            setGamificationHidden(false);
            setMuted(videoPlayerRef.current.isMuted());
          }}
          logEvents={logEvents}
        />

        <div
          className={`event-video-mask-bottom ${
            containerIsHovered ? 'visible' : ''
          }`}
        ></div>
        <div
          className={`event-player-controls-container ${
            containerIsHovered ? 'visible' : ''
          }`}
        >
          <button
            data-tip='Toggle sound'
            data-for='sound'
            className='event-player-controls-button'
            onClick={toggleMuted}
          >
            <Icon
              size={20}
              name={muted ? 'volume-mute' : 'volume-up'}
              color='#fff'
            />
            <ReactTooltip
              id='sound'
              place='top'
              effect='solid'
              className='react-tooltip'
            />
          </button>
          <div className='flex'>
            <button
              onClick={toggleGamificationOverlay}
              className='event-player-controls-button'
              data-tip='Toggle Gamification'
              data-for='gamification'
            >
              <Icon
                size={20}
                name={gamificationHidden ? 'comments-off' : 'comments-on'}
                color='#fff'
              />
              <ReactTooltip
                id='gamification'
                effect='solid'
                className='react-tooltip'
              />
            </button>
            <button
              data-tip='Toggle full screen'
              data-for='fullscreen'
              className='event-player-controls-button'
              onClick={() => {
                setFullScreen(!fullScreen);
                handleClickFullscreen();
              }}
            >
              <Icon
                size={20}
                name={fullScreen ? 'minimize' : 'full-screen'}
                color='#fff'
              />
              <ReactTooltip
                id='fullscreen'
                place='top'
                effect='solid'
                className='react-tooltip'
              />
            </button>
          </div>
        </div>
        <div
          ref={adContainerRef}
          className='absolute top-0 left-0 w-full z-50'
        ></div>
      </>
    );
  }

  if (eventState === 'ended') {
    const eventIsRestricted = getIsEventRestricted();
    return (
      <>
        {activeEndedTab === 'score' && (
          <>
            <ReactPlayer
              id='event-video'
              onPlay={() => {}}
              onError={(err) => {
                logError(err);
              }}
              config={{
                file: {
                  forceHLS: false,
                  attributes: { autoPlay: true },
                },
              }}
              controls={false}
              muted={true}
              volume={0}
              url={EventBackgroundVideo}
              loop={true}
              width='100%'
              height='100%'
              className='event-video bg-black rounded-lg'
            />
            <div className='absolute inset-0 flex items-start justify-center'>
              <div className='w-1/3 h-full flex flex-col items-center justify-center'>
                <EndedEventContestorStatus
                  eventId={eventId}
                  contestorA={contestorA}
                  contestorB={contestorB}
                />
                <div className='h-1/2'>
                  <EventLeaderBoard eventId={eventId} />
                </div>
              </div>
            </div>
          </>
        )}
        {activeEndedTab === 'teaser' && (
          <MediaSlider media={getMedia()} eventId={eventId} />
        )}
        {activeEndedTab === 'video' && videoUrl && !eventIsRestricted && (
          <EndedEventVideoTab
            eventId={eventId}
            setActiveTab={setActiveEndedTab}
            videoUrl={videoUrl}
            useIvs={eventMode === 'user'}
            logEvents={logEvents}
            roomId={roomId}
            roomTitle={roomTitle}
            eventCategory={eventCategory}
            eventTitle={eventTitle}
            isVideo={isVideo}
          />
        )}
      </>
    );
  }

  return null;
};

export default MediaContent;
