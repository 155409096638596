export default {
  base: '/',
  login: '/login',
  register: '/register',
  dashboard: '/dashboard',
  dashboardFilteredByStateAndRoom: '/dashboard/state/:state/room/:roomId',
  dashboardFilteredByState: '/dashboard/state/:state',
  dashboardFilteredByRoom: '/dashboard/room/:roomId',
  account: '/account',
  accountProfile: '/account/profile',
  accountMyGoods: '/account/my-goods',
  accountCoins: '/account/coins',
  accountPayments: '/account/payments',
  completeProfile: '/complete-profile',
  leaderboard: '/leaderboard',
  myGoods: '/account/my-goods',
  event: '/event',
  eventWithID: '/event/:eventId',
  twentyFourSeven: '/twenty-four-seven',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  eventAdministration: '/event-administration',
  editEvent: '/edit-event',
  editEventWithId: '/edit-event/:eventId',
  createEvent: '/create-event',
  myRoom: '/room',
  roomDashboard: '/room/dashboard',
  myEvents: '/events',
  newEvent: '/events/new',
  editMyEvent: '/events',
  editMyEventWithID: '/events/:eventId',
  editMyEventWithIDEventTab: '/events/:eventId/event',
  editMyEventWithIDStreamTab: '/events/:eventId/stream',
  editMyEventWithIDStatisticsTab: '/events/:eventId/statistics',
  withdraw: '/withdraw',
  earnings: '/earnings',
  ppvPassEarnings: '/ppv-season-pass-earnings',
  notFound: '/not-found',
  becomeCreator: '/become-a-creator',
  groups: '/groups/:categoryId/tab/:tab',
  room: '/creator/:roomId/tab/:tab',
  roomSEO: '/creator/:slug',
  newVideo: '/videos/new',
  videoList: '/videos',
  editVideo: '/videos/:videoId',
  video: '/video/:videoId',
  allCreators: '/all-creators',
  allGroups: '/all-groups',
  subscriptionPass: {
    list: '/subscription-pass/list',
    create: '/subscription-pass/create',
    update: '/subscription-pass/:id/update',
    details: '/subscription-pass/:id/details',
  },
};
