/* eslint-disable prettier/prettier */
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { useHistory } from "react-router-dom";

import routes from "routes";
import { logError } from "helpers";

import { AlertsContext } from "context/alerts";
import { SponsorRoomsContext } from "context/sponsorRooms";

import {
  selectSubscriptionCreateError,
  selectIsSubscriptionCreateLoading,
  selectIsSubscriptionCreatedSuccessfully
} from "store/subscriptionCreate/selectors";

import { createSubscriptionPass } from "store/subscriptionCreate/actions";

import Input from "components/form/Input";
import Button from "components/common/Button";
import Spinner from "components/system/Spinner";
import DatePicker from "components/form/DatePicker";
import ImagePicker from "components/form/ImagePicker";

const inputProps = (inputName: string, ref?: React.RefObject<HTMLInputElement>) => ({
  id: `pass-${inputName.toLowerCase()}`,
  setRef: ref,
  label: <>
    <span data-tip={`${inputName} is required to create a pass`}>
      {inputName} *
    </span>{' '}
    <ReactTooltip effect='solid' className='react-tooltip' />
  </>,
  placeholder: inputName,
  wrapClasses: 'mb-4',
  maxLength: 200,
});

const dateInputProps = (inputName: string, value: Date | null, setValue: (date: string) => void) => ({
  className: 'mb-4',
  value,
  setValue,
  disabled: false,
  timeIntervals: 1,
  minDate: new Date(),
  label: <>
    <span data-tip={`${inputName} is required`}>
      {inputName} *
    </span>{' '}
    <ReactTooltip effect='solid' className='react-tooltip' />
  </>,
})

const SubscriptionPassForm: FC = () => {
  const dispatch = useDispatch();
  const history  = useHistory();

  const { addAlert }        = useContext<any>(AlertsContext);
  const { currentUserRoom } = useContext<any>(SponsorRoomsContext);

  const titleRef      = useRef<HTMLInputElement>(null);
  const descRef       = useRef<HTMLInputElement>(null);
  const priceRef      = useRef<HTMLInputElement>(null);
  const monthPriceRef = useRef<HTMLInputElement>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate]     = useState<Date | null>(null);
  const [image, setImage]         = useState();

  const isLoading           = useSelector(selectIsSubscriptionCreateLoading);
  const error               = useSelector(selectSubscriptionCreateError);
  const isSuccessfullySaved = useSelector(selectIsSubscriptionCreatedSuccessfully);

  useEffect(() => {
    if (error) {
      addAlert('danger', error);
      return;
    }

    if (isSuccessfullySaved) {
      addAlert('success', 'Subscription Pass created successfully');
      history.push(routes.subscriptionPass.list);
      return;
    }
  }, [error, isSuccessfullySaved])

  const updateStartDate = (date: string) => {
    const newDateValue = date ? new Date(date) : null;
    setStartDate(newDateValue);
  };

  const updateEndDate = (date: string) => {
    const newDateValue = date ? new Date(date) : null;
    setEndDate(newDateValue);
  };

  const handleImageSelect = (event: any, imgField: any) => {
    const file = event?.target?.files?.[0] || null;

    if (file) {
      const fileType = file.type;

      if (!fileType.includes('image')) {
        logError(imgField, 'File is not an image');
        addAlert('danger', 'Please select an image file');
        return;
      }

      setImage(file);
    }
  };

  const createSubscription = async () => {
    const mandatoryFields = {
      title: titleRef?.current?.value,
      description: descRef?.current?.value,
      price: Number(priceRef?.current?.value),
      monthPrice: Number(monthPriceRef?.current?.value),
      startDate: startDate?.toISOString(),
      endDate: endDate?.toISOString(),
    }

    const mandatoryFieldsMissing = Object.values(mandatoryFields).every((v) => !v);

    if (mandatoryFieldsMissing) {
      addAlert('danger', 'Mandatory fields are now provided.');
      return;
    }

    const payload = {
      ...mandatoryFields,
      sponsorRoomId: currentUserRoom.id,
      image
    };

    dispatch(createSubscriptionPass.request(payload));
  }

  return (
    <>
      {
        isLoading &&
        <div className='absolute inset-0 flex items-center justify-center z-10 bg-white'>
          <Spinner size="small" />
        </div>
      }
      <div className='grid grid-cols-8 2xl:grid-cols-10 gap-4 mr-10 mt-18'>
        <div className={`${!isLoading && 'shadow-card'} col-span-full mt-8 mb-8 px-8 py-10 rounded-lg`}>
          <div className='grid grid-cols-12 gap-4'>
            <div className='col-span-6'>
              <Input
                {...inputProps('Name', titleRef)}
                initialValue={''}
              />
              <Input
                {...inputProps('Description', descRef)}
                initialValue={''}
              />
              <Input
                {...inputProps('Season Pass Price', priceRef)}
                type="number"
                initialValue={''}
              />
              <Input
                {...inputProps('Month Subscription Price', monthPriceRef)}
                type="number"
                initialValue={''}
              />
              <DatePicker
                {...dateInputProps('Season Pass Start Date', startDate, updateStartDate)}
              />
              <DatePicker
                {...dateInputProps('Season Pass End Date', endDate, updateEndDate)}
              />
            </div>
            <div className='col-span-6'>
              <ImagePicker
                imgSrc={''}
                id='image'
                handleSelect={handleImageSelect}
                type='landscape'
                containerClassName='mt-10 subscription-image-picker'
                crop
              />
            </div>
          </div>
          <Button
            buttonSize='medium'
            buttonType='primary'
            buttonOnClick={createSubscription}
          >
            Create
          </Button>
        </div>
      </div>
    </>
  )
};

export default SubscriptionPassForm;
