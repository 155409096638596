import { API, graphqlOperation } from 'aws-amplify';
import { useEffect, useContext, useRef } from 'react';
import {
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { eventByState } from '../graphql/queries';
import { useImmer } from 'use-immer';
import { TransactionsContext } from '../context/transactions';
import { onUpdateEventLastUpdate } from '../graphql/subscriptions';
import { logError } from '../helpers';
import { EventsContext } from '../context/events';

const defaultGqlVars = {
  limit: 30,
  startTimestamp: {
    gt: 0,
  },
};

const initialState = {
  events: [],
  isLoading: false,
  error: null,
};

export default function useEvents() {
  const { loadPPVEvent } = useContext(TransactionsContext);
  const currentlyLoadingRequest = useRef(null);
  const dashboardSubscription = useRef(null);
  const eventSubscriptions = useRef([]);

  const { createEventSubscription } = useContext(EventsContext);

  const { state: eventType } = useParams();
  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const filter = params.get('filter');
  const nextToken = useRef(null);

  const [data, setData] = useImmer(initialState);

  const sortDirection = eventType === 'upcoming' ? 'ASC' : 'DESC';

  const getGraphqlOpts = () => {
    if (filter) {
      return [
        'eventByState',
        graphqlOperation(eventByState, {
          ...defaultGqlVars,
          state: eventType,
          filter: {
            eventCategorisationID: { eq: filter },
          },
          nextToken: nextToken.current,
          sortDirection,
        }),
      ];
    }

    return [
      'eventByState',
      graphqlOperation(eventByState, {
        ...defaultGqlVars,
        state: eventType,
        nextToken: nextToken.current,
        sortDirection,
      }),
    ];
  };

  const loadPPV = async (events) => {
    if (!events?.length) return;

    await Promise.all(
      events.filter((e) => e.ppv).map((e) => loadPPVEvent(e.id))
    );
  };

  const getLiveEvents = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(eventByState, {
          startTimestamp: {
            gt: 0,
          },
          state: 'live',
          sortDirection,
          ...(filter
            ? { filter: { eventCategorisationID: { eq: filter } } }
            : {}),
        })
      );

      const liveEvents = response.data['eventByState']?.items;

      return liveEvents || [];
    } catch (error) {
      console.error('Error fetching live events', error);
    }
  };

  const handleError = (error) => {
    if (!API.isCancel(error)) {
      nextToken.current = null;

      console.error(error);

      setData((draft) => {
        draft.events = [];
        draft.error = 'An error occured while fetching the events.';
      });
    }
  };

  const handleEventData = async (liveEvents = []) => {
    const [key, operation] = getGraphqlOpts();
    currentlyLoadingRequest.current = API.graphql(operation);

    const response = await currentlyLoadingRequest.current;

    const events = response.data[key]?.items;

    setData((draft) => {
      draft.events = [...liveEvents, ...draft.events, ...events];
    });

    currentlyLoadingRequest.current = null;
    nextToken.current = response.data[key]?.nextToken;

    await loadPPV(events);
  };

  const fetchMoreEvents = async () => {
    setData((draft) => {
      draft.isLoading = true;
      draft.error = null;
    });

    try {
      await handleEventData();
    } catch (error) {
      handleError(error);
    } finally {
      setData((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const fetchInitialEvents = async () => {
    setData((draft) => {
      draft.isLoading = true;
      draft.error = null;
      draft.events = [];
    });

    nextToken.current = null;

    try {
      await handleEventData(
        eventType === 'upcoming' && !filter ? await getLiveEvents() : []
      );
    } catch (error) {
      handleError(error);
    } finally {
      setData((draft) => {
        draft.isLoading = false;
      });
    }
  };

  const createDashboardSubscription = async () => {
    dashboardSubscription.current = await API.graphql(
      graphqlOperation(onUpdateEventLastUpdate)
    ).subscribe({
      next: async ({ provider, value }) => {
        if (value.data.onUpdateEventLastUpdate) {
          const isNewEvent =
            value.data.onUpdateEventLastUpdate.type === 'new_event' ||
            value.data.onUpdateEventLastUpdate.type === 'going_upcoming';

          const eventInDashboard = data.events.find(
            (event) => event.id === value.data.onUpdateEventLastUpdate.eventID
          );

          if (
            window.location.pathname.includes('/state/upcoming') &&
            isNewEvent
          ) {
            await fetchInitialEvents();
            return;
          }

          if (!eventInDashboard) {
            return;
          }

          await fetchInitialEvents();
        }
      },
      error: (error) => {
        dashboardSubscription.current = null;
        logError('Dashboard Subscription error', error);
      },
    });
  };

  const updateEvent = (event) => {
    const eventIndex = data.events.findIndex((e) => e.id === event.id);

    if (eventIndex !== -1) {
      Object.keys(event).forEach((k) => event[k] === null && delete event[k]);

      setData((draft) => {
        draft.events[eventIndex] = { ...draft.events[eventIndex], ...event };
      });
    }
  };

  const setEventSubscriptions = async (events) => {
    if (!events?.length) return;

    for (const event of events) {
      const eventStartTime = new Date(event.startTime);
      const oneHourEarlier = new Date(
        new Date().setHours(new Date().getHours() - 1)
      );
      const oneHourLater = new Date(
        new Date().setHours(new Date().getHours() + 1)
      );

      if (eventStartTime >= oneHourEarlier && eventStartTime <= oneHourLater) {
        const newSubscription = createEventSubscription(
          event.id,
          updateEvent,
          (error) => logError(error)
        );

        eventSubscriptions.current.push(newSubscription);
      }
    }
  };

  const removeEventSubscriptions = async () => {
    if (!eventSubscriptions.current?.length) return;

    for (const sub of eventSubscriptions.current) {
      if (!sub?.unsubscribe) continue;

      await sub?.unsubscribe();
    }

    eventSubscriptions.current = [];
  };

  useEffect(() => {
    if (!dashboardSubscription.current) {
      createDashboardSubscription();
    }

    return () => {
      if (dashboardSubscription?.current?.unsubscribe) {
        dashboardSubscription?.current.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    setEventSubscriptions(data.events);

    return () => removeEventSubscriptions();
  }, [data.events]);

  useEffect(() => {
    if (currentlyLoadingRequest.current) {
      API.cancel(currentlyLoadingRequest.current);
    }

    fetchInitialEvents();
  }, [eventType, filter]);

  return [data, nextToken.current, fetchMoreEvents];
}
