/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Node } from 'slate';
import { formatDate } from 'helpers';

import { UserContext } from 'context/user';

import Heading from 'components/typography/Heading';
import Paragraph from 'components/typography/Paragraph';
import MinimizedChatMock from 'components/event/MinimizedChatMock';

const getParsedValue = (event) => {
  if (!event.richTextDescription) {
    return null;
  }

  try {
    return JSON.parse(event.richTextDescription);
  } catch (error) {
    return null;
  }
};

const renderDescription = (event) => {
  if (event.richTextDescription) {
    const parsed = getParsedValue(event);

    return parsed.map((n, i) => {
      const paragraph = Node.string(n);
      if (paragraph.length === 0) {
        return <br key={i} />;
      }

      return <div key={i}>{paragraph}</div>;
    });
  }

  return event.description;
};

const EventDetails = ({
  event,
  chatIsHidden,
  setChatState,
  hasPPV
 }) => {

  const userContext = useContext(UserContext);
  const { getCurrentUserId } = userContext;

  const [hasReadMore, setHasReadMore] = useState(true);
  const openFullDescription = () => setHasReadMore(false);

  const descriptionRef = useRef(null);

  const isEventEnded = event.state === 'ended';

  useEffect(() => {
    setHasReadMore(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
  }, [])

  return (
    <div className={`flex ${isEventEnded ? 'mt-2' : ''}`}>
      <div className='flex flex-1 flex-col gap-2 pl-4 pt-4 pr-2.5 pb-4 shadow-card rounded-[20px]'>
        <div className="flex gap-2 text-secondaryA font-display font-medium text-base">
          {hasPPV && (
            <>
              <span>PPV {event.ppvPrice} Coins</span>
              <span>&middot;</span>
            </>
          )}
          <time dateTime={event.startTime}>
            {formatDate(event.startTime)}
          </time>
        </div>

        <Heading headingSize={4} classes='font-bold'>
          {event.title}
        </Heading>

        <Paragraph
          paragraphSize='medium'
          classes='event-description m-0'
        >
          <span ref={descriptionRef} className={hasReadMore ? 'line-clamp-3': ''}>
            {renderDescription(event)}
          </span>
          {hasReadMore && <span onClick={openFullDescription} className='font-bold cursor-pointer'>
            ...read more
          </span>}
        </Paragraph>
      </div>

      {chatIsHidden && (
        <div className='flex flex-1 justify-end'>
          <MinimizedChatMock
            joinIsEnabled={
              event?.hostSponsorRoom?.userID &&
              event?.hostSponsorRoom?.userID !== getCurrentUserId()
            }
            eventState={event.state}
            eventMode={event.mode}
            maximize={() => {
              setChatState(false);
            }}
            className='ml-4'
          />
        </div>
      )}
    </div>
  )
};

export default EventDetails;
