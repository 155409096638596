import React, { Component } from 'react';
import { CookieConsentContext } from './cookieConsent';
import { getCookie } from '../helpers';

export default class CookieConsentProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isVisible: false,
      showConsent: this.showConsent,
      hideConsent: this.hideConsent,
      globalCookie: false,
      setGlobalCookie: this.setGlobalCookie,
    };
  }

  showConsent = () => {
    this.setState({ isVisible: true });
  };

  hideConsent = () => {
    this.setState({ isVisible: false });
  };

  setGlobalCookie = (type) => {
    this.setState({ globalCookie: type });
  };

  componentDidMount() {
    const cookie = getCookie('globalCookie');

    if (cookie) {
      const value = JSON.parse(cookie);
      if (value.analytics === true) {
        this.setGlobalCookie(true);
      }
    }
  }

  render() {
    return (
      <CookieConsentContext.Provider value={this.state}>
        {this.props.children}
      </CookieConsentContext.Provider>
    );
  }
}
