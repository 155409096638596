/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
import { Handler } from 'store/types';
import { getType } from 'typesafe-actions';

import createMiddleware from 'store/middlewareCreator';
import { AppDispatch } from 'store/config';

import { eventPageMounted } from 'store/event/actions';
import { roomLoaded } from 'store/rooms/actions';

import SubscriptionAPIClient from 'infrastructure/api/profile/SubscriptionAPIClient';

import {
  loadSubscriptions,
  subscriptionsPageMounted,
} from './actions';

const fetchSubs = async (roomID: string, dispatch: AppDispatch) => {
  try {
    const subsWithRoom = await SubscriptionAPIClient.loadSubsciptions(roomID);

    if (subsWithRoom.length) dispatch(roomLoaded(subsWithRoom[0].sponsorRoom));

    const subs = subsWithRoom.map((s) => {
      // eslint-disable-next-line no-unused-vars
      const { sponsorRoom, ...sub } = s;
      return sub;
    });

    dispatch(loadSubscriptions.success(subs));
  } catch (err) {
    dispatch(loadSubscriptions.failure('Load Subscriptions error'))
    console.log(err);
  }
};

const fetchSubsOnSubsPage: Handler<ReturnType<typeof subscriptionsPageMounted>> = async (store, next, action) => {
  next(action);

  const { dispatch } = store;
  const { payload } = action;

  await fetchSubs(payload, dispatch);
};

const fetchSubsOnEventPage: Handler<ReturnType<typeof eventPageMounted>> = async (store, next, action) => {
  next(action);

  const { dispatch } = store;
  const { payload: { roomID } } = action;

  await fetchSubs(roomID, dispatch);
};

const subsciptionsMiddleware = createMiddleware({
  [getType(subscriptionsPageMounted)]: fetchSubsOnSubsPage,
  [getType(eventPageMounted)]: fetchSubsOnEventPage,
});

export default subsciptionsMiddleware;
