/* eslint-disable prettier/prettier */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import TabButton from './TabButton';
import EventInformation from './EventInformation';
import StreamInformation from './StreamInformation';
import StreamStatistics from './StreamStatistics';
import Share from './Share';
import Chat from '../chat/Chat';
import Icon from '../typography/Icon';

import { EventsContext } from '../../context/events';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { UserContext } from '../../context/user';

import routes from '../../routes';
import history from '../../history';
import { logError } from '../../helpers';
import colors from '../../theme-colors';

const LiveTimer = ({ startTime }) => {
  const calculateStartTime = () => {
    const newStartTime = new Date(startTime).getTime();
    const now = new Date().getTime();

    return Math.floor((now - newStartTime) / 1000);
  };

  const [seconds, setSeconds] = useState(calculateStartTime());

  const increment = () => {
    setSeconds((secs) => secs + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      increment();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = () => {
    let mins = Math.floor(seconds / 60);
    let secs = Math.abs(seconds % 60);

    if (mins > -10 && mins < 0) {
      mins = `-0${Math.abs(mins)}`;
    }

    if (mins > -1 && mins < 10) {
      mins = `0${mins}`;
    }

    if (secs < 10) {
      secs = `0${secs}`;
    }

    return `${mins}:${secs}`;
  };

  return (
    <div className='flex items-center justify-center text-secondaryA text-base font-numbers font-bold'>
      <Icon name='live' color={colors.primaryB} classes='mr-4 blink' />{' '}
      {formatTime(seconds)}
    </div>
  );
};

const CreatorEventForm = ({ isNewEvent, eventId }) => {
  const eventsContext = useContext(EventsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const userContext = useContext(UserContext);

  const { getCurrentEvent, createEventSubscription } = eventsContext;
  const { getRoomsByUserId } = sponsorRoomsContext;
  const { getCurrentUserId } = userContext;

  const [eventData, setEventData] = useState(null);
  const [chatIsHidden, setChatIsHidden] = useState(true);

  const isEventEnded = eventData?.state === 'ended';
  const isEventUpcoming = eventData?.state === 'upcoming';
  const isEventLive = eventData?.state === 'live';

  useEffect(async () => {
    const userId = getCurrentUserId();
    let sub = null;

    if (!isNewEvent) {
      if (userId) {
        const rooms = await getRoomsByUserId(userId);
        const event = await getCurrentEvent(eventId);
        const room = rooms.find(
          (r) => r.id && r.id === event?.hostSponsorRoomID
        );

        if (!event || !room) {
          history.replace(routes.notFound);
          return;
        }

        setEventData(event);

        sub = await createEventSubscription(
          eventId,
          updateEvent,
          handleUpdateError
        );
      }
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, []);

  const updateEvent = async (event) => {
    Object.keys(event).forEach(
      (k) => event[k] === null && k !== 'startTime' && delete event[k]
    );
    setEventData((prevEventData) => {
      return { ...prevEventData, ...event };
    });
  };

  const handleUpdateError = (error) => {
    logError(error);
  };

  let sharingUrl = {};

  if (eventId) {
    const desktopParam = eventData?.seoUrl ? eventData.seoUrl : eventId;

    sharingUrl = {
      desktop: `${window.location.origin}/event/${desktopParam}`,
      ios: `/event/${eventId}`,
      android: `/event/${eventId}`,
    };
  }

  const getContentType = () => (isEventEnded ? 'video' : 'stream');

  const renderShareButton = () => {
    if (!isEventUpcoming && !isEventLive) return null;

    return (
      <Share
        id={eventData.id}
        title={eventData.title}
        desc={eventData.description}
        url={sharingUrl}
        img={eventData.imageUrls.standard}
        buttonLabel='Share Event'
        contentType={getContentType()}
        contentTitle={eventData.title}
      />
    );
  };

  const isChatDisabled = () => !(isEventUpcoming || isEventLive);

  const toggleChat = (value) => {
    setChatIsHidden(value);
  };

  const eventInforamationPathname = `${routes.editMyEvent}/${eventId}/event`;
  const streamDetailsPathname = `${routes.editMyEvent}/${eventId}/stream`;
  const streamStatisticsPathname = `${routes.editMyEvent}/${eventId}/statistics`;

  return (
    <div className='grid grid-cols-8 2xl:grid-cols-10 gap-4 mr-10 mt-18'>
      <div className='mt-6 flex col-span-12 2xl:col-span-5'>
        <TabButton
          label='Event Details'
          onClick={() => history.push(eventInforamationPathname)}
          isActive={
            history.location.pathname === eventInforamationPathname ||
            history.location.pathname === routes.newEvent
          }
          className='mr-4'
        />
        <TabButton
          label='Stream Details'
          onClick={() => history.push(streamDetailsPathname)}
          isActive={history.location.pathname === streamDetailsPathname}
          disabled={isNewEvent || (eventData && eventData.state === 'inactive')}
        />
        <TabButton
          label='Statistics'
          onClick={() => history.push(streamStatisticsPathname)}
          isActive={history.location.pathname === streamStatisticsPathname}
          disabled={isNewEvent || (eventData && !isEventEnded)}
        />
        {eventData?.state === 'live' && (
          <div className='flex items-center'>
            <LiveTimer startTime={eventData.startTime} />
          </div>
        )}
        <div className='flex flex-1 justify-end items-center'>
          {renderShareButton()}
        </div>
      </div>
      <div className='mt-6 hidden 2xl:block 2xl:col-span-3'></div>
      <div
        className={`col-span-12 ${
          history.location.pathname === streamStatisticsPathname
            ? '2xl:col-span-8'
            : '2xl:col-span-6'
        } pb-14`}
      >
        {(history.location.pathname === eventInforamationPathname ||
          history.location.pathname === routes.newEvent) && (
          <EventInformation
            isNewEvent={isNewEvent}
            eventData={eventData}
            eventId={eventId}
          />
        )}
        {history.location.pathname === streamDetailsPathname && (
          <StreamInformation eventData={eventData} eventId={eventId} />
        )}
        {isEventEnded &&
          history.location.pathname === streamStatisticsPathname && (
            <StreamStatistics eventData={eventData} eventId={eventId} />
          )}
      </div>
      <div className='hidden 2xl:block 2xl:col-span-3 h-screen'>
        {eventId && eventData && (isEventUpcoming || isEventLive) && (
          <Chat
            eventId={eventId}
            isHidden={false}
            inCreator={true}
            disabled={isChatDisabled()}
            eventState={eventData?.state}
            moderatorName={eventData?.moderator?.displayName}
            moderatorId={eventData?.moderatorID}
            eventCreatorId={eventData?.hostSponsorRoom?.userID}
          />
        )}
      </div>
      {eventId && eventData && (isEventUpcoming || isEventLive) && (
        <Chat
          eventId={eventId}
          isHidden={chatIsHidden}
          isHiddenFunc={toggleChat}
          inCreator={true}
          minimizable={true}
          disabled={isChatDisabled()}
          classes={`2xl:hidden fixed right-10 bottom-0 z-30 w-1/3 ${
            !chatIsHidden ? '' : 'h-12'
          }`}
          eventState={eventData?.state}
          moderatorName={eventData?.moderator?.displayName}
          eventCreatorId={eventData?.hostSponsorRoom?.userID}
        />
      )}
    </div>
  );
};

CreatorEventForm.propTypes = {
  isNewEvent: PropTypes.bool,
  eventId: PropTypes.string,
};

CreatorEventForm.defaultPropTypes = {
  isNewEvent: false,
};

export default CreatorEventForm;
