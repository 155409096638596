import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { PaymentsContext } from './payments';
import { paymentsByUser } from '../graphql/queries';
import { logError } from '../helpers';

export default class PaymentsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      error: null,
      loading: false,
      loadPayments: this.loadPayments,
    };
  }

  loadPayments = async (userID) => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(
        graphqlOperation(paymentsByUser, {
          userID,
          time: { lt: Math.floor(Date.now() / 1000) },
          sortDirection: 'DESC',
        })
      );

      let items = res?.data?.paymentsByUser?.items || [];
      items = items.map((payment) => ({
        item: payment.coinsPackage.numberOfCoins,
        date: new Date(payment.createdAt).toLocaleDateString(),
        realDate: payment.createdAt,
        method: payment.provider,
        status: payment.status,
        amount: payment.amount,
      }));

      this.setState({
        loading: false,
        payments: items,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        payments: [],
        error: 'Error loading payments',
        loading: false,
      });
    }
  };

  render() {
    return (
      <PaymentsContext.Provider value={this.state}>
        {this.props.children}
      </PaymentsContext.Provider>
    );
  }
}
