import React from 'react';
import Icon from '../typography/Icon';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { logError } from '../../helpers';
import routes from '../../routes';

const UserRoomsItem = ({ id, name, sponsor, status, isActive }) => {
  if (!name) {
    logError("Component UserRoomsItem not rendered, because 'name' missing.");
    return null;
  }

  return (
    <li className='py-2 border-b border-lightGray'>
      <Link
        to={{
          pathname: routes.room.replace(':roomId', id).replace(':tab', 'all'),
        }}
        className='flex align-middle justify-between cursor-pointer'
      >
        <span
          className={`font-display text-sm ${
            isActive ? 'font-bold' : 'font-medium'
          }`}
        >
          {name}
        </span>
        {/* sponsor && <img src={sponsor} className='max-h-4 object-contain' /> */}
        {status ? (
          <Icon name='broadcast-online' size={20} color='#ff004f' />
        ) : (
          <Icon name='broadcast-offline' size={20} color='#000' />
        )}
      </Link>
    </li>
  );
};

UserRoomsItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  sponsor: PropTypes.string,
  status: PropTypes.bool.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

UserRoomsItem.defaultProps = {
  name: '',
  sponsor: '',
  status: false,
  isActive: false,
  onClick: () => {},
};

export default UserRoomsItem;
