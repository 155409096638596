/* eslint-disable no-undef */
import { MiddlewareAPI } from 'redux';
import configureStore from './configureStore';

export const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type Store = MiddlewareAPI<AppDispatch, RootState>;
