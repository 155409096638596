import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  XYPlot,
  LineSeries,
  HorizontalGridLines,
  XAxis,
  YAxis,
} from 'react-vis';

import Heading from '../typography/Heading';
import PollResults from '../polls/PollResults';

import { EventsContext } from '../../context/events';
import { PollsContext } from '../../context/polls';

import useStatistics from '../../hooks/useStatistics';
import useScore from '../../hooks/useScore';
import useEventProfits from '../../hooks/useEventProfits';

import themeColors from '../../theme-colors';
import { formatGraphDate } from '../../helpers';
import Spinner from '../system/Spinner';

const StatsRow = ({ label, value }) => {
  return (
    <div className='flex justify-between items-end border border-t-0 border-l-0 border-r-0 border-secondaryB h-10'>
      <div className='text-base font-paragraph text-secondaryA'>{label}</div>
      <div className='text-sm font-display text-primaryC'>{value}</div>
    </div>
  );
};

const StatsScoreRow = ({
  label,
  contestorA,
  contestorB,
  contestorAScore,
  contestorBScore,
}) => {
  return (
    <div className='flex flex-col border border-t-0 border-l-0 border-r-0 border-secondaryB pb-4 pt-6'>
      <div className='text-base font-paragraph text-secondaryA mb-6'>
        {label}
      </div>
      <div className='flex justify-between items-end'>
        <div className='text-sm font-display text-primaryC'>{contestorA}</div>
        <div className='text-sm font-display text-primaryC'>
          {contestorAScore}
        </div>
      </div>
      <div className='flex justify-between items-end'>
        <div className='text-sm font-display text-primaryC'>{contestorB}</div>
        <div className='text-sm font-display text-primaryC'>
          {contestorBScore}
        </div>
      </div>
    </div>
  );
};

const StreamStatistics = ({ eventId }) => {
  const [statistics, setStatistics] = useState(null);
  const [eventData, setEventData] = useState(null);
  const [eventProfit, setEventProfit] = useState(null);
  const [eventPolls, setEventPolls] = useState([]);
  const [width, setWidth] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [loadingGraph, setLoadingGraph] = useState(false);

  const plotTileRef = useRef();

  const { getStatisticsById } = useStatistics();
  const { loadScore, score } = useScore();
  const { getEventProfitByEventId } = useEventProfits();

  const eventsContext = useContext(EventsContext);
  const pollsContext = useContext(PollsContext);

  const { getCurrentEvent } = eventsContext;
  const { getPollsByEventId } = pollsContext;

  const loadData = async () => {
    setLoadingGraph(true);
    const stats = await getStatisticsById(eventId);
    const currentEvent = await getCurrentEvent(eventId);
    const { profit } = await getEventProfitByEventId(eventId);
    const polls = await getPollsByEventId(eventId);
    let watchGraph = [];

    if (stats.watchGraph) {
      watchGraph = JSON.parse(stats.watchGraph);
      watchGraph = Object.keys(watchGraph).map((timeStamp) => ({
        x: timeStamp,
        y: watchGraph[timeStamp],
      }));
    }

    setStatistics(stats);
    setGraphData(watchGraph);
    setLoadingGraph(false);
    setEventData(currentEvent);
    setEventProfit(profit);
    setEventPolls(polls);

    await loadScore(eventId);
  };

  const onResize = () => {
    setWidth(plotTileRef.current.offsetWidth);
  };

  useEffect(() => {
    loadData();
    onResize();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const secondsToHms = (seconds) => {
    if (!seconds) {
      return 'N/A';
    }

    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);

    return (
      ('0' + h).slice(-2) +
      ':' +
      ('0' + m).slice(-2) +
      ':' +
      ('0' + s).slice(-2)
    );
  };

  const getTimeFormat = (minutes) => {
    const secs = minutes * 60 * 1000;
    const date = new Date(secs);
    return formatGraphDate(date);
  };

  const renderGraph = () => {
    if (loadingGraph) {
      return (
        <div className='flex items-center justify-center'>
          <Spinner size='small' />
        </div>
      );
    }

    if (!graphData.length) {
      return (
        <div className='text-lg font-paragraph text-secondaryA'>
          Data is not available
        </div>
      );
    }

    if (width === 0) {
      return null;
    }

    return (
      <div className='flex flex-col'>
        <XYPlot width={width - 64} height={300}>
          <HorizontalGridLines style={{ stroke: themeColors.secondaryB }} />
          <LineSeries
            data={graphData}
            style={{ fill: 'transparent', stroke: themeColors.darkBlue }}
          />
          <XAxis
            tickFormat={getTimeFormat}
            style={{
              text: { fontSize: 10 },
            }}
          />
          <YAxis
            tickSize={2}
            style={{
              text: { fontSize: 10 },
            }}
          />
        </XYPlot>
        <div className='text-base font-paragraph text-secondaryA'>
          Local Time
        </div>
      </div>
    );
  };

  return (
    <div className='col-span-full flex flex-col mt-6'>
      <div className='flex-col lg:flex-row flex'>
        <div className='flex flex-1 flex-col shadow-card rounded-lg px-8 py-10 mb-4 lg:mr-2'>
          <Heading headingSize={5} classes='mb-4'>
            Event Information
          </Heading>
          <StatsRow
            label='Length'
            value={secondsToHms(statistics?.eventLength)}
          />
          <StatsRow
            label='Number of rounds'
            value={eventData?.currentRound || 0}
          />
          <StatsRow label='Coins earned' value={eventProfit?.totalCoins || 0} />
          <StatsScoreRow
            label='Final score of the event'
            contestorA={eventData?.contestorA || 'Team A'}
            contestorB={eventData?.contestorB || 'Team B'}
            contestorAScore={score.contestorAScore}
            contestorBScore={score.contestorBScore}
          />
        </div>
        <div className='flex flex-1 flex-col shadow-card rounded-lg px-8 py-10 mb-4 lg:mr-2'>
          <Heading headingSize={5} classes='mb-4'>
            User Engagement Statistics
          </Heading>
          <StatsRow
            label='Avg. time of users spent watching this event'
            value={secondsToHms((statistics?.averageWatchTime || 0) * 60)}
          />
          <StatsRow
            label='Unique Views'
            value={statistics?.totalViewers || 0}
          />
          <StatsRow
            label='Chat comments'
            value={statistics?.totalComments || 0}
          />
          <StatsRow label='Claps' value={statistics?.claps || 0} />
          <StatsRow label='Tomatoes' value={statistics?.tomatoes || 0} />
        </div>
        <div className='flex flex-1 flex-col shadow-card rounded-lg px-8 py-10 mb-4'>
          <Heading headingSize={5} classes='mb-4'>
            Polls Data
          </Heading>
          <div className='overflow-y-auto scrollable pr-2'>
            {eventPolls.map((p) => {
              return (
                <PollResults
                  key={p.id}
                  pollData={{ ...p, status: 'archived' }} // handle polls left active for some reason
                  className='mb-4 bg-gray-100'
                />
              );
            })}
          </div>
        </div>
      </div>
      <div
        className='flex flex-1 flex-col shadow-card rounded-lg px-8 py-10 mb-4'
        ref={plotTileRef}
      >
        <Heading headingSize={5} classes='mb-4'>
          Viewers
        </Heading>
        {renderGraph()}
      </div>
    </div>
  );
};

StreamStatistics.propTypes = {
  eventData: PropTypes.object,
  eventId: PropTypes.string,
};

StreamStatistics.defaultProps = {
  eventData: null,
  eventId: '',
};

export default StreamStatistics;
