/* eslint-disable prettier/prettier */
import React from 'react';

import { Link } from 'react-router-dom';
import routes from 'routes';

const SubscriptionPassListHeader = () => (
  <div className='mt-8 flex items-center justify-end'>
    <Link
      className="btn btn-primary btn-small"
      to={routes.subscriptionPass.create}
    >
      Create Premium Content Pricing
    </Link>
  </div>
);

export default SubscriptionPassListHeader;
