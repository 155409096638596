import React, { useContext, useEffect, useRef, useState } from 'react';
import Heading from '../components/typography/Heading';
import Input from '../components/form/Input';
import TextArea from '../components/form/TextArea';
import Button from '../components/common/Button';
import SelectField from '../components/form/SelectField';
import Paragraph from '../components/typography/Paragraph';
import { Helmet } from 'react-helmet';

import { UserContext } from '../context/user';
import { AlertsContext } from '../context/alerts';

import useCreatorRequests from '../hooks/useCreatorRequests';

import { useLogEvent } from '../firebase';
import history from '../history';
import routes from '../routes';
import { isEmailValid } from '../helpers';

const gridClasses = 'become-creator-grid-sizes';
const initialErrors = {
  name: null,
  email: null,
  heardFromAdventuresMusicTour: null,
};

const BecomeACreator = () => {
  const [errors, setErrors] = useState(initialErrors);
  const [addVenturesAnswer, setAddVenturesAnwer] = useState('yes');
  const [selectedCity, setSelectedCity] = useState('Virginia Beach');

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const nicknameRef = useRef(null);
  const socialLink1Ref = useRef(null);
  const socialLink2Ref = useRef(null);
  const socialLink3Ref = useRef(null);
  const socialLink4Ref = useRef(null);
  const socialLink5Ref = useRef(null);
  const socialLink6Ref = useRef(null);
  const motivationRef = useRef(null);

  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);

  const { user, getCurrentUserId, loading: userIsLoading } = userContext;
  const { addAlert } = alertsContext;

  const { request, getRequestByUserId, createRequest } = useCreatorRequests();
  const { logEvent } = useLogEvent();

  useEffect(() => {
    if (!request) {
      return;
    }

    setAddVenturesAnwer(request.heardFromAdventuresMusicTour ? 'yes' : 'no');

    if (request.adventuresMusicTourCity) {
      setSelectedCity(request.adventuresMusicTourCity);
    }
  }, [request]);

  useEffect(() => {
    if (!userIsLoading && !user) {
      history.replace({
        pathname: routes.login,
        state: { from: history.location },
      });
      return;
    }

    logEvent('clicked_become_creator', { category: 'engagement' });

    if (user && !request) {
      getRequestByUserId(getCurrentUserId());
    }
  }, [user, userIsLoading]);

  const userData = user?.dbData?.data?.getUser || null;

  const cityOptions = [
    {
      text: 'Virginia Beach',
      onClick: () => setSelectedCity('Virginia Beach'),
    },
    {
      text: 'Charlotte',
      onClick: () => setSelectedCity('Charlotte'),
    },
    {
      text: 'Miami',
      onClick: () => setSelectedCity('Miami'),
    },
    {
      text: 'Orlando',
      onClick: () => setSelectedCity('Orlando'),
    },
    {
      text: 'Cleveland',
      onClick: () => setSelectedCity('Cleveland'),
    },
    {
      text: 'Washington DC',
      onClick: () => setSelectedCity('Washington DC'),
    },
  ];

  const onAddVenturesAnwerSelect = (selection) => {
    setAddVenturesAnwer(selection);
  };

  const onSubmit = async () => {
    const name = (nameRef.current?.value || '').trim();
    const email = (emailRef.current?.value || '').trim();
    const nickname = (nicknameRef.current?.value || '').trim();
    const socialLink1 = (socialLink1Ref.current?.value || '').trim();
    const socialLink2 = (socialLink2Ref.current?.value || '').trim();
    const socialLink3 = (socialLink3Ref.current?.value || '').trim();
    const socialLink4 = (socialLink4Ref.current?.value || '').trim();
    const socialLink5 = (socialLink5Ref.current?.value || '').trim();
    const socialLink6 = (socialLink6Ref.current?.value || '').trim();
    const motivation = (motivationRef?.current?.value || '').trim();

    const newErrors = { ...initialErrors };

    if (name.length === 0) {
      newErrors.name = 'Name is a required';
    }

    if (email.length > 0 && !isEmailValid(email)) {
      newErrors.email = 'Email is not valid';
    }

    if (email.length === 0) {
      newErrors.email = 'Email is required';
    }

    if (addVenturesAnswer === '') {
      newErrors.heardFromAdventuresMusicTour =
        'Please share where did you hear about us';
    }

    setErrors(newErrors);

    if (newErrors.email || newErrors.name) {
      return;
    }

    const { success } = await createRequest({
      userID: getCurrentUserId(),
      displayName: nickname,
      email,
      name,
      socialLink1,
      socialLink2,
      socialLink3,
      socialLink4,
      socialLink5,
      socialLink6,
      motivation,
      heardFromAdventuresMusicTour: addVenturesAnswer === 'yes',
      adventuresMusicTourCity: addVenturesAnswer === 'yes' ? selectedCity : '',
    });

    if (!success) {
      addAlert('danger', 'Something went wrong. Please try again.');
      return;
    }
    logEvent('creator_form_submitted', { category: 'engagement' });
    addAlert('success', 'Your request was submitted successfully!');
  };

  return (
    <div className='grid grid-cols-12 gap-4 mt-14 pt-20'>
      <Helmet>
        <title>Become a Creator | ClashTV</title>
      </Helmet>
      <Heading headingSize={2} classes={`text-primaryA ${gridClasses} mb-10`}>
        Become a creator
      </Heading>
      <p
        className={`font-display font-bold text-2xl text-primaryC ${gridClasses}`}
      >
        Do you want to become a creator and
        <br />
        publish your content on Clash TV?{' '}
      </p>
      <p
        className={`font-display font-bold text-2xl text-primaryC ${gridClasses} mb-10`}
      >
        Apply and become a part of
        <br />
        ClashTV creators community in just a few steps:
      </p>
      <div className={`${gridClasses}`}>
        <Input
          label='Name: *'
          placeholder='Type here...'
          type='text'
          id='name-input'
          icon='user'
          setRef={nameRef}
          wrapClasses='mb-8'
          initialValue={request?.name || userData?.fullName || ''}
          error={errors.name !== null}
          hint={errors.name || ''}
        />
        <Input
          label='Email: *'
          placeholder='Type here...'
          type='email'
          id='email-input'
          icon='email'
          setRef={emailRef}
          wrapClasses='mb-8'
          initialValue={request?.email || userData?.email || ''}
          error={errors.email !== null}
          hint={errors.email || ''}
        />
        <Input
          label='Nickname: '
          placeholder='Type here...'
          type='text'
          id='nickname-input'
          icon='user'
          setRef={nicknameRef}
          wrapClasses='mb-18'
          initialValue={request?.displayName || userData?.displayName || ''}
        />
      </div>
      <div className={`${gridClasses} grid grid-cols-12 gap-4 mb-16`}>
        <label className='label-base col-span-full'>Socials:</label>
        <Input
          placeholder='Type here...'
          type='text'
          id='social-link1-input'
          wrapClasses='col-span-6 mb-8'
          setRef={socialLink1Ref}
          initialValue={request?.socialLink1 || ''}
        />
        <Input
          placeholder='Type here...'
          type='text'
          id='social-link2-input'
          wrapClasses='col-span-6 mb-8'
          setRef={socialLink2Ref}
          initialValue={request?.socialLink2 || ''}
        />
        <Input
          placeholder='Type here...'
          type='text'
          id='social-link3-input'
          wrapClasses='col-span-6 mb-8'
          setRef={socialLink3Ref}
          initialValue={request?.socialLink3 || ''}
        />
        <Input
          placeholder='Type here...'
          type='text'
          id='social-link4-input'
          wrapClasses='col-span-6 mb-8'
          setRef={socialLink4Ref}
          initialValue={request?.socialLink4 || ''}
        />
        <Input
          placeholder='Type here...'
          type='text'
          id='social-link5-input'
          wrapClasses='col-span-6 mb-8'
          setRef={socialLink5Ref}
          initialValue={request?.socialLink5 || ''}
        />
        <Input
          placeholder='Type here...'
          type='text'
          id='social-link6-input'
          wrapClasses='col-span-6 mb-8'
          setRef={socialLink6Ref}
          initialValue={request?.socialLink6 || ''}
        />
      </div>
      <div className={`${gridClasses} flex flex-col mb-48`}>
        <TextArea
          label='Why do you want to be a creator on Clash TV?'
          rows={5}
          id='creator-text-area'
          placeholder='Type here...'
          wrapClasses='mb-16'
          textAreaRef={motivationRef}
          initialValue={request?.motivation || ''}
        />
        <label className='label-base mb-5'>
          Did you hear from us through AddVentures Music Tour? *
        </label>
        {errors.heardFromAdventuresMusicTour && addVenturesAnswer === '' && (
          <p className='input-hint error mt-0 mb-4'>
            {errors.heardFromAdventuresMusicTour}
          </p>
        )}
        <label
          className='radio-container break-all pb-4'
          onClick={(e) => e.stopPropagation()}
        >
          <Paragraph>Yes</Paragraph>
          <input
            type='radio'
            onChange={() => onAddVenturesAnwerSelect('yes')}
            checked={addVenturesAnswer === 'yes'}
          />
          <span className='checkmark'></span>
        </label>
        <label
          className='radio-container break-all pb-4'
          onChange={() => onAddVenturesAnwerSelect('no')}
        >
          <Paragraph>No</Paragraph>
          <input
            type='radio'
            onChange={onAddVenturesAnwerSelect}
            checked={addVenturesAnswer === 'no'}
          />
          <span className='checkmark'></span>
        </label>

        {addVenturesAnswer === 'yes' && (
          <SelectField
            listFullWidth
            label='Places: *'
            value={selectedCity}
            options={cityOptions}
            className='mb-4'
          />
        )}
        {request ? (
          <div className='font-display text-xl text-center text-primaryC'>
            <p className='font-bold'>Thank you for your application!</p>
            <p>Check your email, we will contact you soon!</p>
          </div>
        ) : (
          <Button
            buttonOnClick={onSubmit}
            buttonSize='medium'
            buttonType='primary'
            classes='self-center'
          >
            Become a Creator
          </Button>
        )}
      </div>
      <div className='col-span-12 flex flex-col items-center justify-center mb-6 border-t border-secondaryB pt-6'>
        <Heading headingSize={6} classes='text-secondaryB'>
          © 2023 by ClashTV
        </Heading>
      </div>
    </div>
  );
};

export default BecomeACreator;
