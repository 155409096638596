/* eslint-disable prettier/prettier */
import React, { useRef, useState } from 'react';

import Score from 'components/event/Score';
import MediaContent from 'components/event/MediaContent';

import OverlayAnimation from './components/OverlayAnimation';
import Gamification from './components/Gamification';
import EventStatus from '../EventStatus';

const EventVideoContainer = ({
  width,
  height,
  event,
  gamificationDisabled,
  activeEndedTab,
  setActiveEndedTab,
}) => {
  const mediaContainerRef = useRef(null);

  const [gamificationHidden, setGamificationHidden] = useState(true);
  const [mediaContainerHovered, setMediaContainerHovered] = useState(false);

  const isEventEnded = event.state === 'ended';
  const withGamification = !isEventEnded && !gamificationDisabled && !gamificationHidden;

  return (
    <div
      ref={mediaContainerRef}
      style={{ width, height }}
      className='aspect-ratio-box mb-4'
      onMouseEnter={() => setMediaContainerHovered(true)}
      onMouseLeave={() => setMediaContainerHovered(false)}
    >
      <MediaContent
        eventId={event.id}
        eventState={event.state}
        eventCategory={event.eventCategorisation?.title}
        eventTitle={event.title}
        teaserUrl={event.teaserUrl}
        image={event.image}
        image2={event.image2}
        eventMode={event.mode}
        videoUrl={event.videoUrl}
        containerIsHovered={mediaContainerHovered}
        mediaContainer={mediaContainerRef.current}
        gamificationHidden={gamificationHidden || gamificationDisabled}
        setGamificationHidden={setGamificationHidden}
        isPPV={event.ppv}
        eventCreatorId={event?.hostSponsorRoom?.userID}
        contestorA={event.contestorA}
        contestorB={event.contestorB}
        activeEndedTab={activeEndedTab}
        setActiveEndedTab={setActiveEndedTab}
        roomId={event?.hostSponsorRoom?.id}
        roomTitle={event?.hostSponsorRoom?.title}
        isVideo={false}
      />
      <Score
        eventId={event.id}
        gamificationHidden={gamificationHidden || gamificationDisabled}
        contestorA={event.contestorA}
        contestorB={event.contestorB}
        currentRound={event.currentRound}
        roundsEnabled={event.roundsEnabled}
        eventState={event.state}
      />

      {
        withGamification &&
        <Gamification eventId={event.id} />
      }

      <OverlayAnimation />

      {
        !isEventEnded && <EventStatus status={event.state} />
      }
    </div>
  )
};

export default EventVideoContainer;
