import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { PollsContext } from './polls';
import {
  createEventPoll,
  updateEventPoll,
  createQuestionAnswer,
} from '../graphql/mutations';
import { eventPollsByEventID, answersByPollID } from '../graphql/queries';
import {
  onEventPollCreate,
  onEventPollUpdateID,
  onEventPollUpdate,
} from '../graphql/subscriptions';
import { logError } from '../helpers';

export default class PollsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      createPoll: this.createPoll,
      getPollsByEventId: this.getPollsByEventId,
      getLatestActivePollByEventId: this.getLatestActivePollByEventId,
      submitAnswer: this.submitAnswer,
      updatePoll: this.updatePoll,
      setPollSubscriptionByEventId: this.setPollSubscriptionByEventId,
      setPollSubscriptionById: this.setPollSubscriptionById,
      setPollsUpdateByEventIdSubscription:
        this.setPollsUpdateByEventIdSubscription,
      didUserVote: this.didUserVote,
    };
  }

  didUserVote = async (pollID, userID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(answersByPollID, { pollID, userID: { eq: userID } })
      );

      if (res.data.answersByPollID.items.length > 0) {
        return true;
      }

      return false;
    } catch (error) {
      logError('didUserVode', error);

      return false;
    }
  };

  setPollsUpdateByEventIdSubscription = (eventID, handlePollsUpdate) => {
    const pollsSubscription = API.graphql(
      graphqlOperation(onEventPollUpdate, {
        eventID,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        handlePollsUpdate(value.data.onEventPollUpdate);
      },
      error: (error) => logError('setPollsUpdateByEventIdSubscription', error),
    });

    return pollsSubscription;
  };

  setPollSubscriptionById = (id, handlePollUpdate) => {
    const pollSubscription = API.graphql(
      graphqlOperation(onEventPollUpdateID, {
        id,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        if (value.data.onEventPollUpdateID) {
          handlePollUpdate(value.data.onEventPollUpdateID);
        }
      },
      error: (error) => logError('setPollSubscriptionByEventId', error),
    });

    return pollSubscription;
  };

  setPollSubscriptionByEventId = (eventID, handleNewPoll) => {
    const pollSubscription = API.graphql(
      graphqlOperation(onEventPollCreate, {
        eventID,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        handleNewPoll(value.data.onEventPollCreate);
      },
      error: (error) => logError('setPollSubscriptionByEventId', error),
    });

    return pollSubscription;
  };

  getLatestActivePollByEventId = async (eventID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(eventPollsByEventID, { eventID })
      );

      let latestActive = null;

      for (const poll of res.data.eventPollsByEventID.items) {
        if (poll.status === 'archived') {
          continue;
        }

        if (!latestActive) {
          latestActive = poll;
          continue;
        }

        if (new Date(latestActive.createdAt) < new Date(poll.createdAt)) {
          latestActive = poll;
        }
      }

      return latestActive;
    } catch (error) {
      logError('getLatestActivePollByEventId', error);
      return null;
    }
  };

  updatePoll = async (id, newData) => {
    try {
      const res = await API.graphql(
        graphqlOperation(updateEventPoll, { input: { id, ...newData } })
      );

      return { success: true, poll: res.data.updateEventPoll, error: null };
    } catch (error) {
      logError('updatePoll', error);
      return { success: false, poll: null, error };
    }
  };

  submitAnswer = async (eventID, pollID, userID, answer) => {
    try {
      await API.graphql(
        graphqlOperation(createQuestionAnswer, {
          input: {
            eventID,
            pollID,
            userID,
            answer,
          },
        })
      );

      return {
        success: true,
        error: null,
      };
    } catch (error) {
      logError('submitAnswer', error);
      return {
        success: false,
        error: error,
      };
    }
  };

  createPoll = async (eventID, question, answerA, answerB, answerC) => {
    try {
      await API.graphql(
        graphqlOperation(createEventPoll, {
          input: {
            eventID,
            question,
            answerA,
            answerB,
            answerC,
            status: 'active',
            scoreAnswerA: 0,
            scoreAnswerB: 0,
            scoreAnswerC: 0,
          },
        })
      );
    } catch (error) {
      logError('createPoll', error);
    }
  };

  getPollsByEventId = async (eventID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(eventPollsByEventID, { eventID, limit: 50 })
      );

      return res.data.eventPollsByEventID.items;
    } catch (error) {
      logError('getEventPollsByEventId', error);
      return [];
    }
  };

  render() {
    return (
      <PollsContext.Provider value={this.state}>
        {this.props.children}
      </PollsContext.Provider>
    );
  }
}
