import React from 'react';
import { API } from 'aws-amplify';

import Input from '../form/Input';
import Icon from '../typography/Icon';

import colors from '../../theme-colors';

const apiName = process.env.REACT_APP_CLASH_REST_API_NAME;
const urlValidationPath = '/webhook/ioio/validate-url';

class MerchInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialValue !== this.props.initialValue) {
      const value = this.props.initialValue?.trim() || '';

      if (!value || value.length === 0) {
        return;
      }

      this.validate(value).then((isValid) => {
        this.setState({ isValid });
      });
    }
  }

  validate = async (url) => {
    if (url.indexOf('http') === -1) {
      url = `https://${url}`;
    }

    const result = await API.post(apiName, urlValidationPath, {
      body: {
        url,
      },
    });

    return result.ok === true;
  };

  handleOnBlur = async () => {
    const { onBlur } = this.props;
    const value = document.getElementById('merchandise')?.value?.trim() || '';

    if (value !== '') {
      const isValidUrl = await this.validate(value);
      this.setState({ isValid: isValidUrl });
    }

    onBlur();
  };

  render() {
    const { initialValue } = this.props;
    const { isValid } = this.state;
    let iconColor = colors.secondaryB;
    let iconName = 'check';
    let iconBackground = 'bg-secondaryC border-secondaryB';

    if (isValid === true) {
      iconColor = colors.positive;
      iconBackground = 'bg-positiveLight border-positive';
    } else if (isValid === false) {
      iconColor = colors.primaryBDark;
      iconName = 'error';
      iconBackground = 'bg-primaryBLight border-primaryBDark';
    }

    return (
      <div className='flex'>
        <Input
          id='merchandise'
          label='Merchandise'
          placeholder='Merch link'
          wrapClasses='flex flex-1'
          initialValue={initialValue}
          onBlur={this.handleOnBlur}
        />
        <div
          className={`w-16 h-16 rounded-full flex items-center justify-center self-end ml-4 border ${iconBackground}`}
        >
          <Icon size={24} color={iconColor} name={iconName} />
        </div>
      </div>
    );
  }
}

export default MerchInput;
