import React, { useContext, useState, useEffect } from 'react';
import { useParams, useRouteMatch } from 'react-router';
import { Helmet } from 'react-helmet';

import CreatorEventForm from '../components/creator/CreatorEventForm';
import Heading from '../components/typography/Heading';

import { UserContext } from '../context/user';
import { EventsContext } from '../context/events';
import { getRoomSidebarWidth } from '../helpers';

import routes from '../routes';
import history from '../history';

const headings = {
  [routes.editMyEventWithIDEventTab]: 'Event Information',
  [routes.editMyEventWithIDStreamTab]: 'Stream Details',
  [routes.editMyEventWithIDStatisticsTab]: 'Event Statistics',
};

const EditEventPage = () => {
  const userContext = useContext(UserContext);
  const eventsContext = useContext(EventsContext);
  const { user, loading: userIsLoading, isCreator } = userContext;
  const { getEventIdBySeoUrl } = eventsContext;

  const seoUrl = useParams().eventId;
  const routeMatch = useRouteMatch();
  const sidebarWidth = getRoomSidebarWidth();

  const [eventId, setEventId] = useState(null);

  useEffect(async () => {
    if (seoUrl) {
      const eventId = await getEventIdBySeoUrl(seoUrl);
      setEventId(eventId);
    }
  }, [seoUrl]);

  if (!userIsLoading && !user) {
    history.replace({
      pathname: routes.login,
      state: { from: history.location },
    });
  }

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  return (
    <>
      <Helmet>
        <title>Edit Event | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>{headings[routeMatch.path]}</Heading>
      </div>
      {eventId && <CreatorEventForm isNewEvent={false} eventId={eventId} />}
    </>
  );
};

export default EditEventPage;
