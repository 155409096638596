/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect } from 'react';

import DashLayout from '../dashboard/DashLayout';
import BuyEventModal from '../dashboard/BuyEventModal';
import Heading from '../typography/Heading';
import CardSkeleton from '../../skeletons/CardSkeleton';

import { AlertsContext } from '../../context/alerts';
import { EventsContext } from '../../context/events';
import { InventoryContext } from '../../context/inventory';
import { TransactionsContext } from '../../context/transactions';
import { UserContext } from '../../context/user';

const Loader = () => {
  return Array(6)
    .fill()
    .map((item, index) => <CardSkeleton key={index} />);
};

const RoomLiveEvents = ({ roomId }) => {
  const [buyModalData, setBuyModalData] = useState(null);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);
  const eventsContext = useContext(EventsContext);
  const alertsContext = useContext(AlertsContext);
  const inventoryContext = useContext(InventoryContext);

  const { getCurrentUserId } = userContext;
  const { loadPPVEvent, myPPVList } = transactionsContext;
  const { getLiveEventByRoomId } = eventsContext;
  const { addAlert } = alertsContext;
  const { coins } = inventoryContext;

  const onPPVBuyAttempt = (ppvData) => {
    if (coins < ppvData.price) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      return;
    }

    setBuyModalData(ppvData);
  };

  const loadPPVData = async (eventId) => {
    await loadPPVEvent(getCurrentUserId(), eventId);
  };

  useEffect(async () => {
    setEvents([]);
    setLoading(true);
    const { event } = await getLiveEventByRoomId(roomId);

    setLoading(false);

    if (event) {
      setEvents([event]);
      loadPPVData(event.id);
    }
  }, [roomId]);

  return (
    <div className='relative grid gap-8 auto-rows-card-row pt-8 mb-4'>
      {!loading && events.length === 0 && (
        <Heading headingSize={6} classes='col-span-full'>
          No live events available at the moment
        </Heading>
      )}
      {loading && <Loader />}
      <DashLayout
        events={events}
        loading={loading}
        showBuyModal={onPPVBuyAttempt}
        ppvItems={myPPVList}
        currentUserId={getCurrentUserId()}
      />
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
      />
    </div>
  );
};

export default RoomLiveEvents;
