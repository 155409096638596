import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../typography/Icon';
import { logError } from '../../helpers';

const Badge = ({ children, badgeType, classes, icon, onClick }) => {
  if (!children) {
    logError("Component Badge not rendered, because 'children' missing.");
    return null;
  }

  const attributes = {
    className: `badge ${badgeType} ${classes}`,
  };

  if (typeof onClick === 'function') {
    attributes.onClick = (e) => onClick(e);
  }

  return (
    <span {...attributes}>
      {icon.length !== 0 && icon !== undefined ? (
        <Icon name={icon} size={12} classes='align-middle mr-1.5' />
      ) : null}
      {children}
    </span>
  );
};

Badge.propTypes = {
  children: PropTypes.string.isRequired,
  // badgeType: PropTypes.oneOf(['live', 'recap', 'upcoming', 'ghostWhite']),
  classes: PropTypes.string,
  icon: PropTypes.string,
  onClick: PropTypes.func,
};

Badge.defaultProps = {
  classes: '',
  badgeType: 'live',
  icon: '',
};

export default Badge;
