import React, { useContext, useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';

import { UserContext } from '../../context/user';
import { InventoryContext } from '../../context/inventory';
import { TransactionsContext } from '../../context/transactions';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import { LeftDrawerContext } from '../../context/leftDrawer';
import { CookieConsentContext } from '../../context/cookieConsent';

import { shouldRenderNavbar } from '../../helpers';
import history from '../../history';
import routes from '../../routes';

import Logo from '../common/Logo';
import UserDropdown from './UserDropdown';
import Notifications from '../notifications/Notifications';

const Header = () => {
  const userContext = useContext(UserContext);
  const inventoryContext = useContext(InventoryContext);
  const transactionsContext = useContext(TransactionsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const leftDrawerContext = useContext(LeftDrawerContext);
  const cookieConsentContext = useContext(CookieConsentContext);

  const { user, signOut, userImageUrl } = userContext;
  const { coins, clearInventory } = inventoryContext;
  const { clearTransactions } = transactionsContext;
  const { clearRooms } = sponsorRoomsContext;
  const { eventId, toggleEventPreview } = leftDrawerContext;
  const { isVisible } = cookieConsentContext;

  const [showNavbar, setShowNavbar] = useState(false);

  const { pathname } = useLocation();

  useEffect(() => {
    setShowNavbar(shouldRenderNavbar(pathname));
  }, [shouldRenderNavbar(pathname)]);

  const handleUserSidebar = () => {
    if (eventId) {
      toggleEventPreview(null);
    }
  };

  const logOut = () => {
    clearInventory();
    clearTransactions();
    clearRooms();
    signOut();
  };

  if (!showNavbar) {
    return null;
  }

  const userData = user?.dbData?.data?.getUser || null;
  let isMobileView = false;

  if (history?.location?.search === '?mobile-view') {
    isMobileView = true;
  }

  return (
    <header
      className={`${!isMobileView ? 'desktop' : ''} ${
        isVisible ? 'top-29' : 'top-0'
      } navbar-container`}
    >
      <div className='flex h-full items-center'>
        <div className='navbar-links-container'>
          <Logo
            logoWidth={69}
            logoHeight={37}
            classes='mr-6 cursor-pointer'
            clickable={!isMobileView}
          />
          {user && !isMobileView && (
            <Link
              to={`${routes.dashboard}/state/upcoming`}
              className={`navbar-link ${
                pathname.indexOf(routes.dashboard) !== -1 ? 'active' : ''
              }`}
            >
              Events
            </Link>
          )}
          {user && !isMobileView && (
            <Link
              to={routes.leaderboard}
              className={`navbar-link ${
                pathname === routes.leaderboard ? 'active' : ''
              }`}
            >
              Leaderboard
            </Link>
          )}
          {user && !isMobileView && (
            <Link
              to={routes.accountMyGoods}
              className={`navbar-link ${
                pathname === routes.accountMyGoods ? 'active' : ''
              }`}
            >
              My Goods
            </Link>
          )}
          {user && userData?.creator !== true && (
            <Link
              to={routes.becomeCreator}
              className='navbar-link gradient-text'
            >
              Become a Creator
            </Link>
          )}
          {user && userData?.creator === true && (
            <Link
              to={routes.roomDashboard}
              className='navbar-link gradient-text'
            >
              Go Live
            </Link>
          )}
        </div>
        {userData && !isMobileView && (
          <div className='user-container flex items-center'>
            <span className='navbar-coins'>
              Coins: {coins} |{' '}
              <Link
                to={routes.accountCoins}
                className='navbar-get-more-link'
                onClick={handleUserSidebar}
              >
                Get More Coins
              </Link>
            </span>
            <UserDropdown
              image={userImageUrl}
              userData={userData}
              signOut={logOut}
              classes='mr-9'
            />
            {user && <Notifications />}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
