import { S3Client, HeadObjectCommand } from '@aws-sdk/client-s3';
import awsExports from '../aws-exports';
import { logError } from '../helpers';
import { useState, useRef } from 'react';

const useVideoProcessing = () => {
  const [processing, setProcessing] = useState(0);
  const intervalRef = useRef(null);

  const getPercentage = (a, b) => ((a / b) * 100).toFixed();

  const incrementProcess = (
    currentDiffInMilliseconds,
    fullDiffInMilliseconds
  ) => {
    const currentProgress = getPercentage(
      currentDiffInMilliseconds,
      fullDiffInMilliseconds
    );

    if (currentProgress === 100) {
      return;
    }

    let incrementCycle = 1;
    intervalRef.current = setInterval(() => {
      const newDiffInMilliseconds =
        currentDiffInMilliseconds + incrementCycle * 8000;

      if (fullDiffInMilliseconds > newDiffInMilliseconds) {
        const percentage = getPercentage(
          newDiffInMilliseconds,
          fullDiffInMilliseconds
        );
        setProcessing(percentage);
        incrementCycle++;

        if (percentage >= 100) {
          setProcessing(100);
          clearInterval(intervalRef.current);
        }
      } else {
        setProcessing(100);
        clearInterval(intervalRef.current);
      }
    }, 8000);
  };

  const clearProcessingInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const getHeadObject = async (bucket, key) => {
    try {
      const client = new S3Client({
        region: awsExports.aws_project_region,
        credentials: {
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
      });

      const input = {
        Bucket: bucket,
        Key: `public/${key}`,
      };

      const command = new HeadObjectCommand(input);
      const response = await client.send(command);

      return response;
    } catch (error) {
      logError(error);
      return null;
    }
  };

  const handleProcessing = async (
    s3object,
    videoLength // in milliseconds
  ) => {
    const { bucket, key } = s3object;
    const headObject = await getHeadObject(bucket, key);

    if (headObject) {
      const lastModified = headObject.LastModified;
      const uploadDate = new Date(lastModified);
      const now = new Date();

      const dateOfFinishedProcessing = new Date(lastModified);
      dateOfFinishedProcessing.setMilliseconds(
        dateOfFinishedProcessing.getMilliseconds() + videoLength * 1.1
      );

      // video has been processed
      if (dateOfFinishedProcessing <= now) {
        setProcessing(100);
        return;
      }

      const fullDiffInMilliseconds =
        dateOfFinishedProcessing.getTime() - uploadDate.getTime();
      const currentDiffInMilliseconds =
        fullDiffInMilliseconds -
        (dateOfFinishedProcessing.getTime() - now.getTime());
      const currentPercentageProgress = getPercentage(
        currentDiffInMilliseconds,
        fullDiffInMilliseconds
      );

      setProcessing(currentPercentageProgress);
      incrementProcess(currentDiffInMilliseconds, fullDiffInMilliseconds);
    }
  };

  return { handleProcessing, processing, clearProcessingInterval };
};

export default useVideoProcessing;
