/* eslint-disable prettier/prettier */
import React from 'react';
import { useSelector } from 'react-redux';

import { selectSubscriptions } from 'store/subscriptions/selectors';

import Table from 'components/table/Table';
import TableHead from 'components/table/TableHead';
import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import PassRow from './components/PassRow';

const SubscriptionPassListMainContent = () => {
  const passes = useSelector(selectSubscriptions);

  const tableHeadingStyles = `
    py-2
    font-display
    font-medium
    text-sm
    text-left
    leading-5
    tracking-tight
  `;

  if (!passes.length) return null;

  return (
    <div className='mt-8 mb-8 px-8 py-4 shadow-card rounded-lg'>
      <Table classes='table-fixed'>
        <TableHead>
          <TableRow classes='border-b border-secondaryB'>
            <TableCell variant='head' classes={`${tableHeadingStyles} w-2/6`}>
              Name
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Season Pass Price
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Month Subscription Price
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              From
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              To
            </TableCell>
          </TableRow>
        </TableHead>
        {
          passes.map(PassRow)
        }
      </Table>
    </div>
  );
}

export default SubscriptionPassListMainContent;