import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import VideoForm from '../components/videos/VideoForm';
import Heading from '../components/typography/Heading';

import { AlertsContext } from '../context/alerts';
import useVOD from '../hooks/useVOD';
import { getRoomSidebarWidth } from '../helpers';
import history from '../history';
import routes from '../routes';
import { UserContext } from '../context/user';
import { useLogEvent } from '../firebase';

const CreateVideo = () => {
  const [videoState, setVideoState] = useState('idle');
  const [uploadProgress, setUploadProgress] = useState(0);
  const sidebarWidth = getRoomSidebarWidth();

  const alertsContext = useContext(AlertsContext);
  const userContext = useContext(UserContext);

  const { addAlert } = alertsContext;
  const { user, isCreator } = userContext;

  const { createVod } = useVOD();
  const { logEvent } = useLogEvent();

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  const handleVideoUploadProgress = (progress) => {
    setUploadProgress(((progress.loaded / progress.total) * 100).toFixed());
  };

  const onSubmit = async (data) => {
    if (!data.title.trim().length) {
      return addAlert('danger', 'Title cannot be empty!');
    }

    if (!data.file) {
      return addAlert('danger', 'Please provide a video file to uplaod');
    }

    if (!data.eventCategorisationID.length) {
      return addAlert('danger', 'Please select category');
    }

    setVideoState('uploading');
    const { success } = await createVod(data, handleVideoUploadProgress);
    setVideoState('processing');

    if (!success) {
      return addAlert('danger', 'Video upload was not successful');
    }
    logEvent('created_an_event', { category: 'engagement' });
    addAlert('success', 'Video was uploaded successfully');
    history.push(routes.videoList);
  };

  return (
    <>
      <Helmet>
        <title>Create New Video | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>New Video</Heading>
      </div>
      <div className='mt-8'>
        <VideoForm
          videoState={videoState}
          onSubmit={onSubmit}
          videoUploadProgress={uploadProgress}
          buttonLabel='Publish'
        />
      </div>
    </>
  );
};

export default CreateVideo;
