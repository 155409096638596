/* eslint-disable */
export const updateUserNotifications = /* GraphQL */ `
  mutation CustomUpdateUser(
    $id: ID!
    $notificationsArray: [NotificationInput]
  ) {
    customUpdateUser(
      id: $id
      notificationsArray: $notificationsArray
    ) {
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
    }
  }
`;

export const updateFcmId = /* GraphQL */ `
  mutation CustomUpdateUser(
    $id: ID!
    $fcmID: String
  ) {
    customUpdateUser(
      id: $id
      fcmID: $fcmID
    ) {
      fcmID
    }
  }
`;