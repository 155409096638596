import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { CoinsContext } from './coins';
import { listCoinsPackages } from '../graphql/queries';
import { logError } from '../helpers';

const apiName = process.env.REACT_APP_CLASH_STRIPE_API_NAME;
const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class CoinsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentIntent: {},
      coinPacks: [],
      loading: false,
      loadCoinPacks: this.loadCoinPacks,
      getPaymentIntent: this.getPaymentIntent,
    };
  }

  getPaymentIntent = async (coinsPackage, userId, userDisplayName) => {
    this.setState({
      loading: true,
    });
    try {
      const path = '/checkout';
      const options = {
        body: {
          package: coinsPackage.title,
          amount: coinsPackage.priceInUSD * 100,
          user_id: userId,
          display_name: userDisplayName,
          package_id: coinsPackage.id,
        },
        headers: {
          Accept: 'application/json',
        },
      };

      return await API.post(apiName, path, options);
    } catch (error) {
      logError(error);
      this.setState({
        error: 'Error during payment',
        loading: false,
      });
    }
  };

  loadCoinPacks = async () => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listCoinsPackages));

      const items = res?.data?.listCoinsPackages?.items || [];

      for (const i of items) {
        if (i?.image?.key) {
          i.image._url = `${imgBaseUrl}${i.image.key}?w=250`;
        }
      }

      items.sort((a, b) => a.numberOfCoins - b.numberOfCoins);

      this.setState({
        loading: false,
        coinPacks: items,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        coinPacks: [],
        error: 'Error loading coins',
        loading: false,
      });
    }
  };

  render() {
    return (
      <CoinsContext.Provider value={this.state}>
        {this.props.children}
      </CoinsContext.Provider>
    );
  }
}
