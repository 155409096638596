/* eslint-disable prettier/prettier */
import { GameActionsContext } from 'context/gameActions';
import React, { useContext } from 'react';
import Lottie from 'react-lottie';

import blueTomato from 'lotties/BlueTomato.json';
import redTomato from 'lotties/RedTomato.json';

const getAnimation = (color) => {
  if (color === 'blue') return blueTomato;
  if (color === 'red') return redTomato;

  return null;
};

const OverlayAnimation = () => {
  const gameActionsContext = useContext(GameActionsContext);

  const {
    tomatoAnimationIsStopped,
    tomatoColor,
    stopTomatoAnimation,
  } = gameActionsContext;

  const lottieOptions = {
    loop: false,
    autoplay: false,
    animationData: getAnimation(tomatoColor),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div
      style={{ // TODO: Refactor
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
      }}
    >
      <Lottie
        options={lottieOptions}
        isStopped={tomatoAnimationIsStopped}
        direction={1}
        isPaused={false}
        eventListeners={[
          {
            eventName: 'complete',
            callback: () => {
              stopTomatoAnimation();
            },
          },
        ]}
      />
    </div>
  )
}

export default OverlayAnimation;
