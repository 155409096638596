import { useContext } from 'react';
import {
  useSelectAudioInputDevice,
  useSelectAudioOutputDevice,
  useSelectVideoInputDevice,
  useAudioVideo,
  useLocalVideo,
  useMeetingManager,
} from 'amazon-chime-sdk-component-library-react';

import { DevicesContext } from '../context/devices';

import { logError } from '../helpers';

const useDeviceSelection = () => {
  const audioVideo = useAudioVideo();
  const selectAudioInputDevice = useSelectAudioInputDevice();
  const selectAudioOutputDevice = useSelectAudioOutputDevice();
  const selectVideoInputDevice = useSelectVideoInputDevice();
  const { toggleVideo } = useLocalVideo();
  const meetingManager = useMeetingManager();

  const devicesContext = useContext(DevicesContext);
  const {
    audioInput,
    audioOutput,
    videoInput,
    setAudioInput,
    setVideoInput,
    setAudioOutput,
  } = devicesContext;

  const setSelectedDevices = () => {
    if (audioInput) {
      selectAudioInputDevice(audioInput?.deviceId);
    }

    if (audioOutput) {
      selectAudioOutputDevice(audioOutput?.deviceId);
    }

    if (videoInput) {
      selectVideoInputDevice(videoInput?.deviceId);
    }
  };

  const startVideo = async () => {
    try {
      await audioVideo.chooseVideoInputDevice(
        meetingManager.selectedVideoInputDevice
      );
    } catch (err) {
      logError('useVideoEnabled startVideo err', err);
    }
    audioVideo.startLocalVideoTile();
  };

  const chooseDevices = async () => {
    try {
      if (audioInput) {
        await audioVideo.chooseAudioInputDevice(audioInput.deviceId);
      }

      if (audioOutput) {
        await audioVideo.chooseAudioOutputDevice(audioOutput.deviceId);
      }

      if (videoInput) {
        await audioVideo.chooseVideoInputDevice(videoInput.deviceId);
        audioVideo.start();
        toggleVideo();
        audioVideo.startLocalVideoTile();
      }
    } catch (err) {
      logError('chooseDevices', err);
    }
  };

  const selectAndChooseDevices = async () => {
    setSelectedDevices();
    await chooseDevices();
  };

  const getDevicePermissions = async (onError = () => {}) => {
    try {
      await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
    } catch (err) {
      logError('setDevicePermissions', err);
      onError();
    }
  };

  return {
    audioInput,
    audioOutput,
    videoInput,
    setSelectedDevices,
    chooseDevices,
    selectAndChooseDevices,
    setAudioInput,
    setAudioOutput,
    setVideoInput,
    getDevicePermissions,
    startVideo,
  };
};

export default useDeviceSelection;
