/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InventoryContext } from '../../context/inventory';
import Icon from '../typography/Icon';

const StickerMenu = ({
  isOpen,
  handleClose,
  stickers,
  submitSticker,
  inPreview,
  inEvent,
  inCreator,
}) => {
  const [menuBottom, setMenuBottom] = useState(0);

  const inventoryContext = useContext(InventoryContext);
  const { inventory } = inventoryContext;

  useEffect(() => {
    if (inEvent) {
      const input = document.getElementById('chatInput');

      if (input) {
        const { bottom } = input.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        setMenuBottom(windowHeight - bottom + 50 + 16);
      }
    }
  });

  const onStickerSelect = (stickerId) => {
    submitSticker(stickerId);
    handleClose();
  };

  const generateBundles = () => {
    let generatedBundles = {};

    for (const s of stickers) {
      if (s.stickerBundleID && s.stickerBundle) {
        generatedBundles = {
          ...generatedBundles,
          [s.stickerBundleID]: {
            id: s.stickerBundleID,
            title: s.stickerBundle.title,
            items: [...(generatedBundles?.[s.stickerBundleID]?.items || []), s],
          },
        };
      } else {
        generatedBundles = {
          ...generatedBundles,
          ['clash-bundle']: {
            id: 'clash-bundle',
            title: 'Clash',
            items: [...(generatedBundles?.['clash-bundle']?.items || []), s],
          },
        };
      }
    }

    return generatedBundles;
  };

  const renderBundle = (bundle) => {
    let width = '';
    let ulClasses = '';

    if (inPreview) {
      width = 'w-1/6';
      ulClasses = 'mb-6';
    }

    if (inEvent) {
      width = 'w-1/6';
      ulClasses = 'mb-6';
    }

    if (inCreator) {
      width = 'w-1/6';
      ulClasses = 'mb-1';
    }

    if (bundle.id === 'clash-bundle' || inventory?.[bundle.id]) {
      return (
        <React.Fragment key={bundle.id}>
          <span className='block mb-6 font-display font-semibold text-sm tracking-tight text-primaryC'>
            {bundle.title}
          </span>
          <ul className={`flex flex-wrap ${ulClasses}`}>
            {bundle.items
              .sort((a, b) => a.order - b.order)
              .map((item) => (
                <li
                  key={item.id}
                  className={`flex ${width} h-12 mb-4 mr-4 cursor-pointer`}
                  onClick={() => onStickerSelect(item.id)}
                >
                  <img src={item?.image?._url} className='object-contain' />
                </li>
              ))}
          </ul>
        </React.Fragment>
      );
    }

    return null;
  };

  const renderStickers = () => {
    const bundles = generateBundles();
    const items = [];

    for (const id in bundles) {
      if (Object.hasOwnProperty.call(bundles, id)) {
        const element = bundles[id];
        items.push(renderBundle(element));
      }
    }

    return <div className='p-4 overflow-auto'>{items}</div>;
  };

  let stickersStyles = {};

  if (inPreview) {
    stickersStyles = {
      maxHeight: 302,
      height: '100%',
      marginBottom: 83,
    };
  }

  if (inEvent) {
    stickersStyles = {
      maxHeight: 302,
      height: '100%',
      marginBottom: 103,
    };
  }

  if (inCreator) {
    stickersStyles = {
      maxHeight: 302,
      height: '100%',
      marginBottom: 83,
    };
  }

  return (
    <div
      style={stickersStyles}
      className={`sticker-menu ${
        isOpen ? 'opacity-100 visible bottom-4' : 'opacity-0 invisible bottom-8'
      }`}
    >
      <div className='flex justify-between p-4 border-b border-secondaryB'>
        <span className='font-display font-semibold text-sm tracking-tight text-primaryC'>
          Stickers
        </span>
        <button
          onClick={handleClose}
          className='hover:text-primaryA focus:outline-none active:outline-none hover:outline-none'
        >
          <Icon name='error' size={12} />
        </button>
      </div>
      {renderStickers()}
    </div>
  );
};

StickerMenu.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  stickers: PropTypes.array,
  bundles: PropTypes.array,
  submitSticker: PropTypes.func,
  inPreview: PropTypes.bool,
  inEvent: PropTypes.bool,
  inCreator: PropTypes.bool,
};

StickerMenu.defaultProps = {
  inPreview: false,
  inEvent: false,
  inCreator: false,
};

export default StickerMenu;
