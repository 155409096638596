import React from 'react';
import BackButton from './BackButton';

const PendingWarning = ({ isVisible, onBack }) => {
  if (!isVisible) {
    return null;
  }

  return (
    <div className='p-6 bg-white rounded-lg h-full'>
      <div className='flex items-center justify-start'>
        <BackButton onClick={onBack} />
      </div>
      <div className='flex items-center flex-col h-full justify-center'>
        <p className='font-display font-bold text-base text-center'>
          You already have 1 requested payment
        </p>
      </div>
    </div>
  );
};

export default PendingWarning;
