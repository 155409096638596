/* eslint-disable no-unused-vars */
import React from 'react';
import DataLoader from '../components/DataLoader';
import { UserConsumer } from '../context/user';
import { GameActionsConsumer } from '../context/gameActions';
import { BoostersConsumer } from '../context/boosters';
import { RankingConsumer } from '../context/rankig';
import { InventoryConsumer } from '../context/inventory';
import { RoomCategoriesConsumer } from './roomCategories';
import { EventCategoriesConsumer } from './eventCategories';

const Consumers = ({ children }) => {
  return (
    <UserConsumer>
      {({
        loadCurrentUser,
        userHasSignedIn,
        subscribeToCreatorPermissionUpdate,
        user,
      }) => (
        <GameActionsConsumer>
          {({ loadActions }) => (
            <BoostersConsumer>
              {({ loadBoosters }) => (
                <RankingConsumer>
                  {({ loadRankingUsers }) => (
                    <InventoryConsumer>
                      {({ setInventory }) => (
                        <RoomCategoriesConsumer>
                          {({ loadRoomCategories }) => (
                            <EventCategoriesConsumer>
                              {({ loadEventCategories }) => (
                                <DataLoader
                                  userHasSignedIn={userHasSignedIn}
                                  loadCurrentUser={loadCurrentUser}
                                  loadActions={loadActions}
                                  loadBoosters={loadBoosters}
                                  loadRankingUsers={loadRankingUsers}
                                  setInventory={setInventory}
                                  loadRoomCategories={loadRoomCategories}
                                  loadEventCategories={loadEventCategories}
                                  subscribeToCreatorPermissionUpdate={
                                    subscribeToCreatorPermissionUpdate
                                  }
                                  user={user}
                                >
                                  {children}
                                </DataLoader>
                              )}
                            </EventCategoriesConsumer>
                          )}
                        </RoomCategoriesConsumer>
                      )}
                    </InventoryConsumer>
                  )}
                </RankingConsumer>
              )}
            </BoostersConsumer>
          )}
        </GameActionsConsumer>
      )}
    </UserConsumer>
  );
};
export default Consumers;
