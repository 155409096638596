import React from 'react';
import * as ReactDOM from 'react-dom';

import Button from '../common/Button';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';

const RequestToJoinDialog = ({ isVisible, onClose, onAccept, node }) => {
  if (!node || !isVisible) {
    return null;
  }

  const handleAccept = () => {
    onAccept();
    onClose();
  };

  return ReactDOM.createPortal(
    <div className='absolute inset-0 w-full h-full bg-primaryC bg-opacity-80 z-50'>
      <div className='relative w-96 p-6 rounded-lg transform top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 bg-primaryD'>
        <Heading headingSize={4} classes='max-w-3/4 mb-8'>
          Request to be in this live video
        </Heading>
        <button
          onClick={onClose}
          className='absolute right-4 top-4 text-primaryC focus:outline-none'
        >
          <Icon size={13} name='error' />
        </button>
        <div className='flex flex-col font-paragraph text-base max-w-3/4 mb-8'>
          Are you sure you want to request to appear on this live stream?
        </div>
        <div className='flex items-center justify-end'>
          <Button
            key='decline'
            buttonType='secondary'
            buttonSize='medium'
            buttonOnClick={onClose}
            classes='mr-4'
          >
            Decline
          </Button>
          <Button
            key='accept'
            buttonType='primary'
            buttonSize='medium'
            buttonOnClick={handleAccept}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>,
    node
  );
};

export default RequestToJoinDialog;
