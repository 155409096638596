import React, { useContext } from 'react';
import ReactTooltip from 'react-tooltip';

import { AlertsContext } from '../../context/alerts';
import { SponsorRoomsContext } from '../../context/sponsorRooms';
import Icon from '../typography/Icon';
import colors from '../../theme-colors';

const RtmpTab = ({ inactive }) => {
  const alertsContext = useContext(AlertsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const { addAlert } = alertsContext;
  const { currentUserRoom } = sponsorRoomsContext;

  const copyToClipboard = (key) => {
    if (inactive) {
      return;
    }

    let value = '';

    if (key === 'Stream Key') {
      value = currentUserRoom.ivsChannel.streamKey;
    }

    if (key === 'Stream Url') {
      value = currentUserRoom.ivsChannel.ingestEndpoint;
    }

    navigator.clipboard.writeText(value);
    addAlert('success', `${key} copied to clipboard`);
  };

  return (
    <>
      <div
        className={`grid grid-cols-12 gap-4 mb-4 ${inactive ? 'inactive' : ''}`}
      >
        <div className='col-span-full label-base flex items-center justify-between'>
          <div className='flex items-center'>
            <label className='label-base'>Stream key</label>
            <span data-tip='Never share your stream key with anyone or show on stream. Clash Tv will never ask you for this information.'>
              <Icon
                name='warning-exclamation-mark'
                color={colors.warning}
                classes='ml-4'
              />
              <ReactTooltip
                place='top'
                effect='solid'
                className='react-tooltip'
              />
            </span>
          </div>
          <span className='text-sm font-display font-medium'>Copy Key</span>
        </div>
        <div
          onClick={() => (inactive ? null : copyToClipboard('Stream Key'))}
          className={`col-span-full border base-input text-center py-2 rounded-lg overflow-hidden whitespace-nowrap overflow-ellipsis${
            inactive ? '' : ' cursor-pointer'
          }`}
        >
          {currentUserRoom.ivsChannel.streamKey}
        </div>
      </div>
      <div
        className={`grid grid-cols-12 gap-4 mb-4 ${inactive ? 'inactive' : ''}`}
      >
        <div className='col-span-full flex items-center justify-between'>
          <label className='label-base'>Stream Url</label>
          <span className='text-sm font-display font-medium'>Copy Key</span>
        </div>
        <div
          onClick={() => {
            inactive ? null : copyToClipboard('Stream Url');
          }}
          className={`col-span-full border base-input text-center py-2 rounded-lg overflow-hidden whitespace-nowrap overflow-ellipsis${
            inactive ? '' : ' cursor-pointer'
          }`}
        >
          {currentUserRoom.ivsChannel.ingestEndpoint}
        </div>
      </div>
    </>
  );
};

export default RtmpTab;
