import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const TableCell = ({ children, variant, classes }) => {
  if (!children) {
    logError("Component TableCell not rendered, because 'TableBody' missing.");
    return null;
  }

  if (variant == 'head') {
    return <th className={classes}>{children}</th>;
  } else if (variant == 'body') {
    return <td className={classes}>{children}</td>;
  }

  return null;
};

TableCell.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['head', 'body']),
  classes: PropTypes.string,
};

TableCell.defaultProps = {
  classes: '',
  variant: 'body',
};

export default TableCell;
