import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { logError } from '../../helpers';

// const autoplayError = `DOMException: play() failed because the user didn't interact with the document first.`;

const TeaserSlide = ({
  playing,
  muted,
  streamUrl,
  play,
  playerRef,
  setDuration,
  setProgress,
  toggleMute,
  pause,
  goToNextSlide,
  handleEnd,
}) => {
  const handleError = (err) => {
    logError(err);

    // handle DOMException: play() failed because the user didn't interact with the document first.
    // if (err.message.includes('play() failed')) {
    //   setState({ muted: true, playing: true });
    // }
  };

  return (
    <ReactPlayer
      id='event-video'
      ref={playerRef}
      onReady={() => {
        play();
        if (muted) {
          toggleMute();
        }
      }}
      onPlay={() => {
        console.log('on play');
      }}
      onError={(err) => handleError(err)}
      onDuration={(duration) => setDuration(duration)}
      onProgress={({ playedSeconds }) => setProgress(playedSeconds)}
      onEnded={handleEnd}
      config={{
        file: {
          forceHLS: true,
          attributes: { playsInline: true },
        },
      }}
      controls={false}
      playing={playing}
      muted={muted}
      volume={muted ? 0 : 1}
      url={streamUrl}
      loop={false}
      width='100%'
      height='100%'
      className='event-video bg-black rounded-lg'
    />
  );
};

export default TeaserSlide;
