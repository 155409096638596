import React, { useState, useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Skeleton from 'react-loading-skeleton';

import Heading from '../components/typography/Heading';
import Table from '../components/table/Table';
import TableBody from '../components/table/TableBody';
import TableHead from '../components/table/TableHead';
import TableRow from '../components/table/TableRow';
import TableCell from '../components/table/TableCell';

import { UserContext } from '../context/user';

import useEventProfits from '../hooks/useEventProfits';
import { getRoomSidebarWidth } from '../helpers';
import history from '../history';
import routes from '../routes';

const tableHeadingStyles =
  'py-2 font-display font-medium text-sm text-left leading-5 tracking-tight';

const RenderLoader = ({ innerRef }) => {
  return (
    <TableRow innerRef={innerRef} classes={`border-b border-secondaryB`}>
      <TableCell variant='body' classes='font-bold py-2'>
        <Skeleton height={20} width={240} />
        <Skeleton height={22} width={120} />
      </TableCell>
      <TableCell
        variant='body'
        classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight'
      >
        <Skeleton height={20} width={100} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={120} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={40} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={40} />
      </TableCell>
    </TableRow>
  );
};

const ProfitsTable = ({ userHasLoaded }) => {
  const [loadedData, setLoadedData] = useState(false);
  const { data, nextToken, loading, loadMoreByUserId, getEventProfitByUserId } =
    useEventProfits();

  useEffect(async () => {
    if (!loadedData && userHasLoaded) {
      await getEventProfitByUserId();
      setLoadedData(true);
    }
  }, [userHasLoaded]);

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: nextToken || false,
    onLoadMore: loadMoreByUserId,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <Table>
      <TableHead>
        <TableRow classes='border-b border-secondaryB'>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Event
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Coins Earned
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Total Earnings USD
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Revenue Share
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Net Earnings
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((event, i) => {
          if (!event) {
            return null;
          }

          return (
            <TableRow key={event.id} classes={`border-b border-secondaryB`}>
              <TableCell
                variant='body'
                classes='font-display text-sm font-medium max-w-32'
              >
                {event?.event?.title || 'EVENT HAS BEEN DELETED'}
              </TableCell>
              <TableCell
                variant='body'
                classes='font-numbers font-bold py-2 text-sm tracking-tight'
              >
                {event.totalCoins}
              </TableCell>
              <TableCell
                variant='body'
                classes='font-numbers font-bold py-2 text-sm tracking-tight'
              >
                S{event.totalUsd}
              </TableCell>
              <TableCell
                variant='body'
                classes='font-numbers font-bold py-2 text-sm tracking-tight'
              >
                {event.revenuePercent}%
              </TableCell>
              <TableCell
                variant='body'
                classes='font-numbers font-bold py-2 text-sm tracking-tight'
              >
                ${event.totalCreatorProfitUsd}
              </TableCell>
            </TableRow>
          );
        })}
        {nextToken && <RenderLoader key='sentry' innerRef={sentryRef} />}
      </TableBody>
    </Table>
  );
};

const EventEarnings = () => {
  const [loadedUser, setLoadedUser] = useState(false);

  const sidebarWidth = getRoomSidebarWidth();

  const userContext = useContext(UserContext);
  const { userIsLoading, user, isCreator } = userContext;

  useEffect(() => {
    if (!userIsLoading && !user) {
      history.replace({
        pathname: routes.login,
        state: { from: history.location },
      });
      return;
    }

    if (user && !isCreator) {
      history.replace(routes.dashboard);
      return;
    }

    if (!loadedUser) {
      setLoadedUser(true);
    }
  }, [userIsLoading, user, isCreator]);

  return (
    <>
      <Helmet>
        <title>Earnings | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>Earnings</Heading>
      </div>
      <div className='mr-6 mt-28 -ml-2 px-8 py-10 shadow-card rounded-lg min-h-80vh'>
        <Heading headingSize={6} classes='font-bold tracking-tight mb-9'>
          Earnings
        </Heading>
        <ProfitsTable userHasLoaded={loadedUser} />
      </div>
    </>
  );
};

export default EventEarnings;
