/* eslint-disable prettier/prettier */

import { RootState } from 'store/config';

const  getSubscriptionsState               = (state: RootState) => state.subscriptions;
export const selectSubscriptionIds         = (state: RootState) => getSubscriptionsState(state).allIDs;
export const selectSubscriptionById        = (id: string) => (state: RootState) => getSubscriptionsState(state).byID[id];
export const selectSubscriptions           = (state: RootState) => Object.values(getSubscriptionsState(state).byID);
export const selectAreSubscriptionsLoading = (state: RootState) => getSubscriptionsState(state).loading;

export const selectUpToDateSubscriptions = (startTime?: string) => (state: RootState) => {
  const allSubs = selectSubscriptions(state);

  const timeInMs = startTime
    ? new Date(startTime).getTime()
    : null;

  return timeInMs
  ? allSubs.filter((p) => (
    new Date(p.startDate).getTime() < timeInMs &&
    new Date(p.endDate).getTime() > timeInMs
  ))
  : allSubs;
}
