import React, { useEffect } from 'react';
import Heading from './components/typography/Heading';
import Logo from './components/common/Logo';
import { useLogEvent } from './firebase';
import { getMobileOperatingSystem } from './helpers';

import { ReactComponent as AppStoreBadge } from './assets/app-store-badge.svg';
import GooglePlaybadge from './assets/google-play.svg';

const appStoreUrl = 'https://apps.apple.com/tt/app/clashtv/id1468675126';
const googlePlayUrl =
  'https://play.google.com/store/apps/details?id=com.clashtv.clashtv';

const MobileScreen = () => {
  const { logEvent } = useLogEvent();
  useEffect(() => {
    const platform = getMobileOperatingSystem();
    let isMobile = false;
    if ('maxTouchPoints' in navigator) {
      isMobile = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      isMobile = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        isMobile = !!mQ.matches;
      } else if ('orientation' in window) {
        isMobile = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        isMobile =
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
          /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
    if (isMobile) {
      logEvent('view_mobile_page', { category: 'consumer', platform });
    }
  }, []);

  const onAppStoreClick = () => {
    logEvent('open_app_store', { category: 'engagement' });
  };

  const onGooglePlayClick = () => {
    logEvent('open_google_play', { category: 'engagement' });
  };

  return (
    <div
      className='h-screen flex flex-col justify-center items-center mobile'
      style={{
        backgroundColor: 'rgb(82,0,233)',
        background:
          'linear-gradient(305deg, rgba(82,0,233,1) 2%, rgba(47,0,135,1) 98%)',
      }}
    >
      <div className='flex flex-col items-center justify-center mb-8'>
        <Logo classes='mb-8' />
        <Heading headingSize={4} classes='text-primaryD tracking-tight mb-1'>
          ClashTV Brings You the Best
        </Heading>
        <Heading headingSize={4} classes='text-primaryD tracking-tight mb-1'>
          In High-Passion Sports Streaming
        </Heading>
      </div>
      <div className='flex flex-col items-center justify-center mb-8'>
        <Heading
          headingSize={6}
          classes='pb-4 text-primaryD tracking-tight font-bold'
        >
          ClashTV offers:
        </Heading>
        <Heading headingSize={6} classes='text-primaryD tracking-tight'>
          - The #1 global destination for live <br />
          street basketball
        </Heading>
        <br />
        <Heading headingSize={6} classes='text-primaryD tracking-tight'>
          - Exciting combat sports. MMA, <br /> Bare Knuckle Boxing and more
        </Heading>
        <br />
        <Heading headingSize={6} classes='text-primaryD tracking-tight'>
          - Sign-up to receive 1,000 Clash Coins
          <br /> for free to use for in-app purchases.
        </Heading>
      </div>
      <Heading headingSize={5} classes='text-white mb-16 text-center'>
        Download the ClashTV app <br /> and Join the action now!
      </Heading>
      <a className='mb-8' href={appStoreUrl} onClick={onAppStoreClick}>
        <AppStoreBadge width={160} height={54} />
      </a>
      <a href={googlePlayUrl} onClick={onGooglePlayClick}>
        <img src={GooglePlaybadge} width={160} height={54} />
      </a>
    </div>
  );
};

export default MobileScreen;
