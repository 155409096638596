import React from 'react';
import Heading from '../typography/Heading';
import Icon from '../typography/Icon';
import Input from '../form/Input';
import Button from '../common/Button';

const EditAttributeInput = ({
  type,
  sendCode,
  isOpen,
  showCode,
  cancel,
  currentValue,
}) => {
  if (!type) {
    return null;
  }

  let containerClassName = '';
  let title = '';
  let inputLabel = '';

  if (type === 'email') {
    containerClassName = 'profile-details-email-box';
    title = 'Change your email';
    inputLabel = 'Add your email address';
  }

  if (type === 'phone') {
    containerClassName = 'profile-details-phone-box';
    title = 'Change your phone number';
    inputLabel = 'Add your phone number [+code,number]';
  }

  const handleSubmit = () => {
    const value =
      document.getElementById(`edit-${type}-input`)?.value.trim() || '';
    sendCode(value);
  };

  const isSafari =
    navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1;

  return (
    <div
      className={`${containerClassName} ${isOpen ? '' : 'hide'} ${
        isSafari ? 'safari' : ''
      } ${showCode ? 'hide-left' : ''}`}
    >
      <div className='mb-16'>
        <button onClick={cancel}>
          <Icon color='#5200e9' name='back' size={12} classes='align-middle' />
        </button>
        <Heading
          headingSize={6}
          classes='inline-block ml-9 font-bold text-primaryC tracking-tight'
        >
          {title}
        </Heading>
      </div>
      <Input
        id={`edit-${type}-input`}
        placeholder='Type here …'
        label={<span>{inputLabel}</span>}
        wrapClasses='w-1/2 2xl:w-1/3 mx-auto mb-20'
        icon='user'
        autoFocus={true}
        initialValue={currentValue}
        hint={type === 'phone' ? 'For Example: +19171234567' : ''}
      />
      <Button
        buttonId='done'
        buttonType='secondary'
        buttonSize='medium'
        isLink={false}
        classes='relative transform left-1/2 -translate-x-1/2 inline-blok w-52'
        disabled={false}
        buttonOnClick={handleSubmit}
      >
        Done
      </Button>
    </div>
  );
};

export default EditAttributeInput;
