import { API, graphqlOperation } from 'aws-amplify';
import { getEventStatistics } from '../graphql/queries';
import {
  createEventUserWatchTime,
  updateEventUserWatchTime,
} from '../graphql/mutations';
import { logError } from '../helpers';

const useStatistics = () => {
  const addUserToWatchers = async (userID, eventID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(createEventUserWatchTime, {
          input: {
            eventID,
            userID,
          },
        })
      );

      return res.data.createEventUserWatchTime;
    } catch (error) {
      logError('addUserToWatchers', error);
      return null;
    }
  };

  const updateUserInWatchers = async (id) => {
    try {
      const res = await API.graphql(
        graphqlOperation(updateEventUserWatchTime, {
          input: { id },
        })
      );

      return res.data.updateEventUserWatchTime;
    } catch (error) {
      logError('updateUserInWatchers', error);
      return null;
    }
  };

  const getStatisticsById = async (id) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getEventStatistics, {
          id,
        })
      );

      return res.data.getEventStatistics;
    } catch (error) {
      logError('getStatisticsById', error);
      return null;
    }
  };

  return {
    getStatisticsById,
    addUserToWatchers,
    updateUserInWatchers,
  };
};

export default useStatistics;
