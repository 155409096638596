import { useContext, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { UserContext } from '../context/user';
import { creatorPayoutInfoByUserId } from '../graphql/queries';
import {
  createCreatorPayoutInfo,
  updateCreatorPayoutInfo,
  deleteCreatorPayoutInfo,
} from '../graphql/mutations';
import { logError } from '../helpers';

const useBankingData = () => {
  const [data, setData] = useState(null);
  const userContext = useContext(UserContext);
  const { getCurrentUserId } = userContext;

  const getCurrentUserBankingData = async () => {
    const userID = getCurrentUserId();

    if (!userID) {
      logError('getCurrentUserBankingData', 'No user id');
      return { success: false, data: null };
    }

    try {
      const res = await API.graphql(
        graphqlOperation(creatorPayoutInfoByUserId, { userID })
      );

      setData(res.data.creatorPayoutInfoByUserId.items?.[0] || null);

      return {
        success: true,
        data: res.data.creatorPayoutInfoByUserId.items?.[0],
      };
    } catch (error) {
      logError('getCurrentUserBankingData', error);
      return { success: false, data: null };
    }
  };

  const createBankingData = async (data) => {
    try {
      const res = await API.graphql(
        graphqlOperation(createCreatorPayoutInfo, { input: data })
      );

      setData(res.data.createCreatorPayoutInfo);
      return { success: true, data: res.data.createCreatorPayoutInfo };
    } catch (error) {
      logError('createBankingData', error);
      return { success: false, data: null };
    }
  };

  const updateBankingData = async (data) => {
    try {
      const res = await API.graphql(
        graphqlOperation(updateCreatorPayoutInfo, { input: data })
      );

      setData(res.data.updateCreatorPayoutInfo);
      return { success: true, data: res.data.updateCreatorPayoutInfo };
    } catch (error) {
      logError('updateBankingData', error);
      return { success: false, data: null };
    }
  };

  const deleteBankingData = async () => {
    try {
      await API.graphql(
        graphqlOperation(deleteCreatorPayoutInfo, { input: { id: data.id } })
      );

      setData(null);
      return { success: true, data: null };
    } catch (error) {
      logError('deleteBankingData', error);
      return { success: false, data: null };
    }
  };

  return {
    data,
    getCurrentUserBankingData,
    createBankingData,
    updateBankingData,
    deleteBankingData,
  };
};

export default useBankingData;
