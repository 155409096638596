import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Table from '../table/Table';
import TableHead from '../table/TableHead';
import TableBody from '../table/TableBody';
import TableRow from '../table/TableRow';
import TableCell from '../table/TableCell';
import Heading from '../typography/Heading';
import Badge from '../common/Badge';
import stripeImg from '../../../src/assets/stripe.png';
import applePayImg from '../../../src/assets/apple-pay.png';

import { PaymentsContext } from '../../context/payments';
import { UserContext } from '../../context/user';

const Payments = () => {
  const paymentsContext = useContext(PaymentsContext);
  const userContext = useContext(UserContext);

  const { payments, loadPayments } = paymentsContext;
  const { getCurrentUserId } = userContext;

  useEffect(() => {
    loadPayments(getCurrentUserId());
  }, []);

  const tableHeadingStyles =
    'py-2 font-display font-medium text-sm text-left leading-5 tracking-tight';

  return (
    <div className='col-span-full mt-28 px-8 py-10 shadow-card rounded-lg mb-8'>
      <Helmet>
        <title>Payments | ClashTV</title>
      </Helmet>
      <div className='flex justify-between mb-9'>
        <Heading headingSize={6} classes='font-bold tracking-tight'>
          Payment History
        </Heading>
      </div>
      <Table>
        <TableHead>
          <TableRow classes='border-b border-secondaryB'>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Item
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Date
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Payment Method
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Status
            </TableCell>
            <TableCell variant='head' classes={`${tableHeadingStyles}`}>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {payments.map((payment, i) => {
            let status = 'failed';

            if (payment.status.toLowerCase() === 'succeeded') {
              status = 'succeeded';
            }

            return (
              <TableRow
                key={i + payment.item}
                classes={`border-b border-secondaryB`}
              >
                <TableCell
                  variant='body'
                  classes='text-primaryC text-2xl font-display font-bold py-2'
                >
                  {payment.item}{' '}
                  <span className='block text-sm leading-5'>coins</span>
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight'
                >
                  {payment.date}
                </TableCell>
                <TableCell variant='body'>
                  <img
                    src={payment.method == 'stripe' ? stripeImg : applePayImg}
                  />
                </TableCell>
                <TableCell variant='body'>
                  <Badge badgeType={status} classes='uppercase'>
                    {status}
                  </Badge>
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-numbers font-bold text-base'
                >
                  ${payment.amount}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default Payments;
