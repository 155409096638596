import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Heading from '../typography/Heading';
import PollOption from './PollOption';
import Button from '../common/Button';
import Spinner from '../system/Spinner';

import { PollsContext } from '../../context/polls';
import { UserContext } from '../../context/user';
import Icon from '../typography/Icon';
import colors from '../../theme-colors';
import PollResults from './PollResults';
import { useLogEvent } from '../../firebase';

const UserPoll = ({ eventId }) => {
  const pollsContext = useContext(PollsContext);
  const userContext = useContext(UserContext);
  const { logEvent } = useLogEvent();

  const {
    getLatestActivePollByEventId,
    submitAnswer,
    setPollSubscriptionByEventId,
    setPollSubscriptionById,
    didUserVote,
  } = pollsContext;
  const { getCurrentUserId } = userContext;

  const [activePoll, setActivePoll] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('A');
  const [submittingVote, setSubmittingVote] = useState(false);
  const [showResults, setShowResults] = useState(false);

  const handleNewPoll = (newPoll) => {
    setVisible(false);
    setActivePoll(newPoll);
    setSelectedOption('A');
  };

  const handlePollUpdate = async (updatedPoll) => {
    if (updatedPoll.status === 'archived') {
      setVisible(false);
      setActivePoll(null);
      setSelectedOption('A');
      setShowResults(false);
    }
  };

  useEffect(async () => {
    const poll = await getLatestActivePollByEventId(eventId);

    if (poll) {
      const userHasVoted = await didUserVote(poll.id, getCurrentUserId());

      if (!userHasVoted) {
        setActivePoll(poll);
      }
    }

    const pollCreateSub = await setPollSubscriptionByEventId(
      eventId,
      handleNewPoll
    );

    return () => {
      if (pollCreateSub) {
        pollCreateSub.unsubscribe();
      }
    };
  }, []);

  useEffect(async () => {
    let pollUpdateSub = null;

    if (activePoll) {
      pollUpdateSub = await setPollSubscriptionById(
        activePoll.id,
        handlePollUpdate
      );
    } else {
      if (pollUpdateSub) {
        pollUpdateSub.unsubscribe();
        pollUpdateSub = null;
      }
    }

    return () => {
      if (pollUpdateSub) {
        pollUpdateSub.unsubscribe();
      }
    };
  }, [activePoll]);

  const handleVote = async () => {
    setSubmittingVote(true);
    await submitAnswer(
      eventId,
      activePoll.id,
      getCurrentUserId(),
      selectedOption
    );
    setSubmittingVote(false);
    setShowResults(true);
    logEvent('vote_in_poll', {
      eventId,
      pollId: activePoll.id,
      category: 'engagement',
    });
  };

  const hideResults = () => {
    setShowResults(false);
    setVisible(false);
    setSelectedOption('A');
    setActivePoll(null);
  };

  const closePoll = () => {
    setShowResults(false);
    setVisible(false);
    setActivePoll(null);
  };

  const renderPoll = () => {
    if (showResults) {
      return (
        <div className='user-poll-container'>
          <button className='user-poll-close-button' onClick={hideResults}>
            <Icon name='error' size={12} />
          </button>
          <PollResults
            pollData={activePoll}
            showArhiveButton={false}
            maxTextLength={25}
            showStatus={false}
          />
          <div className='flex items-center justify-end'>
            <Button
              buttonType='primary'
              buttonSize='medium'
              buttonOnClick={hideResults}
            >
              Close
            </Button>
          </div>
        </div>
      );
    }

    if (activePoll && visible) {
      return (
        <div className='user-poll-container'>
          {submittingVote && (
            <div className='absolute flex inset-0 items-center justify-center bg-white bg-opacity-50 z-10'>
              <Spinner size='medium' />
            </div>
          )}
          <Heading headingSize={5} classes='mb-4'>
            {activePoll.question}
          </Heading>
          <button className='user-poll-close-button' onClick={closePoll}>
            <Icon name='error' size={12} />
          </button>
          <div
            style={{ maxHeight: 237, overflowY: 'auto' }}
            className='scrollable'
          >
            <PollOption
              message={activePoll.answerA}
              checked={selectedOption === 'A'}
              setChecked={() => setSelectedOption('A')}
            />
            <PollOption
              message={activePoll.answerB}
              checked={selectedOption === 'B'}
              setChecked={() => setSelectedOption('B')}
            />
            {activePoll.answerC && (
              <PollOption
                message={activePoll.answerC}
                checked={selectedOption === 'C'}
                setChecked={() => setSelectedOption('C')}
              />
            )}
          </div>
          <div className='flex items-center justify-end'>
            <Button
              buttonType='primary'
              buttonSize='medium'
              buttonOnClick={handleVote}
              disabled={activePoll.status === 'archived'}
            >
              Vote
            </Button>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className='relative w-full border-white flex flex-col z-10'>
      {activePoll && activePoll.status == 'active' && !visible && (
        <div className='user-poll-indication' onClick={() => setVisible(true)}>
          <Heading headingSize={6} classes='truncate w-11/12 text-primaryD'>
            {activePoll.question}
          </Heading>
          <Icon name='forward' color={colors.primaryD} />
        </div>
      )}
      {renderPoll()}
    </div>
  );
};

UserPoll.propTypes = {
  eventId: PropTypes.string,
};

export default UserPoll;
