import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const Heading = ({ children, headingSize, classes }) => {
  if (!children) {
    logError("Component Heading not rendered, because 'children' missing.");
    return null;
  }

  if (headingSize <= 0 || headingSize > 6 || !headingSize) {
    logError('Component Heading not rendered, because of wrong headingSize.');
    return null;
  }

  function setHeadingSize(size) {
    switch (size) {
      case 1:
        return (
          <h1
            className={`font-display text-5xl font-bold leading-18 ${classes}`}
          >
            {children}
          </h1>
        );
      case 2:
        return (
          <h2
            className={`font-display text-4xl font-bold leading-12 ${classes}`}
          >
            {children}
          </h2>
        );
      case 3:
        return (
          <h3 className={`font-display text-3.5xl leading-9.5 ${classes}`}>
            {children}
          </h3>
        );
      case 4:
        return (
          <h4
            className={`font-display font-semibold text-2xl leading-5 xl:text-2xl xl:leading-7.5 ${classes}`}
          >
            {children}
          </h4>
        );
      case 5:
        return (
          <h5
            className={`font-display font-semibold text-xl leading-6 ${classes}`}
          >
            {children}
          </h5>
        );
      case 6:
        return (
          <h6 className={`font-display text-base leading-4.5 ${classes}`}>
            {children}
          </h6>
        );
    }
  }

  return <>{setHeadingSize(headingSize)}</>;
};

Heading.propTypes = {
  children: PropTypes.any.isRequired,
  headingSize: PropTypes.number.isRequired,
  classes: PropTypes.string,
};

Heading.defaultProps = {
  headingSize: 1,
  classes: '',
};

export default Heading;
