import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const TableHead = ({ children, classes }) => {
  if (!children) {
    logError("Component TableHead not rendered, because 'children' missing.");
    return null;
  }
  return <thead className={classes}>{children}</thead>;
};

TableHead.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
};

TableHead.defaultProps = {
  classes: '',
};

export default TableHead;
