/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import useVOD from '../../hooks/useVOD';
import { UserContext } from '../../context/user';
import { TransactionsContext } from '../../context/transactions';
import { InventoryContext } from '../../context/inventory';

import Heading from '../typography/Heading';
import DashLayout from '../dashboard/DashLayout';
import BuyEventModal from '../dashboard/BuyEventModal';
import { CardSkeletonWithRef } from '../../skeletons/CardSkeleton';
import { AlertsContext } from '../../context/alerts';

const RoomVideos = ({ roomId }) => {
  const [buyModalData, setBuyModalData] = useState(null);

  const userContext = useContext(UserContext);
  const transactionsContext = useContext(TransactionsContext);
  const inventoryContext = useContext(InventoryContext);
  const alertsContext = useContext(AlertsContext);

  const { getCurrentUserId } = userContext;
  const { myPPVList } = transactionsContext;
  const { coins } = inventoryContext;
  const { addAlert } = alertsContext;

  const { loadVideosByListingAndRoomId, videos, loading, nextToken } = useVOD();

  useEffect(() => {
    loadVideosByListingAndRoomId('public', roomId);
  }, []);

  const onPPVBuyAttempt = (ppvData) => {
    if (coins < ppvData.price) {
      addAlert('danger', "You don't have enough coins to purchase this event");
      return;
    }

    setBuyModalData(ppvData);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage: nextToken !== null,
    onLoadMore: () => loadVideosByListingAndRoomId('public', roomId),
  });

  return (
    <div className='relative grid gap-8 auto-rows-card-row pt-8 mb-4'>
      {!loading && !videos.length && (
        <Heading headingSize={6} classes='col-span-full'>
          No videos available at the moment
        </Heading>
      )}
      <DashLayout
        events={videos}
        loading={false}
        showBuyModal={onPPVBuyAttempt}
        ppvItems={myPPVList}
        currentUserId={getCurrentUserId()}
        contentType='videos'
      />
      {nextToken !== null && <CardSkeletonWithRef ref={sentryRef} />}
      <BuyEventModal
        data={buyModalData}
        onClose={() => setBuyModalData(null)}
      />
    </div>
  );
};

export default RoomVideos;
