import { useEffect, useState } from 'react';

const useVideoContainerHeight = (element) => {
  const [containerSizes, setContainerSizes] = useState({ width: 0, height: 0 });

  const calcAndSetContainerSize = () => {
    const heightMargins = 156;
    const widthMargins = 32;
    const containerMaxWidth = window.innerWidth - widthMargins;
    const maxHeight = window.innerHeight - heightMargins;
    const calculatedHeight = (containerMaxWidth * 9) / 16;
    const height = calculatedHeight > maxHeight ? maxHeight : calculatedHeight;
    const width = (height * 16) / 9;
    setContainerSizes({ height, width });
  };

  useEffect(() => {
    if (element) {
      window.addEventListener('resize', () => calcAndSetContainerSize());
      calcAndSetContainerSize();
    }

    return () => {
      if (element) {
        window.removeEventListener('resize', () => calcAndSetContainerSize());
      }
    };
  }, [element]);

  return { containerSizes, calcAndSetContainerSize };
};

export default useVideoContainerHeight;
