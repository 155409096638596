/* eslint-disable prettier/prettier */
import subsciptionsMiddleware from "./subscriptions/middleware";
import subsciptionCreateMiddleware from "./subscriptionCreate/middleware";
import purchasedSubsciptionsMiddleware from "./purchasedSubscriptions/middleware";
import eventMiddleware from "./event/middleware";
import ppvPassEarningsMiddleware from "./ppvPassEarnings/middleware";

export default [
  subsciptionsMiddleware,
  subsciptionCreateMiddleware,
  purchasedSubsciptionsMiddleware,
  eventMiddleware,
  ppvPassEarningsMiddleware
];