import React from 'react';
import Skeleton from 'react-loading-skeleton';

const UserSidebarSkeleton = () => {
  return (
    <div className='h-full pt-14 px-10 border-r border-secondaryB col-span-3 2xl:col-span-2'>
      <div className='flex justify-between items-center mb-10'>
        <Skeleton circle={true} height={96} width={96} className='mr-4' />
        <div>
          <Skeleton duration={2} height={14} width={90} />
          <Skeleton duration={2} height={14} width={90} />
          <Skeleton duration={2} height={14} width={90} />
        </div>
      </div>
      <Skeleton duration={2} height={2} width='100%' className='mb-10' />
      <div className='flex justify-between items-center mb-10'>
        <Skeleton circle={true} height={96} width={96} className='mr-4' />
        <div>
          <Skeleton duration={2} height={14} width={90} />
          <Skeleton duration={2} height={14} width={90} />
          <Skeleton duration={2} height={14} width={90} />
        </div>
      </div>
      <div className='flex justify-between align-middle mb-4'>
        <Skeleton duration={2} height={20} width={69} />
        <Skeleton duration={2} height={20} width={69} />
      </div>
      <Skeleton duration={2} height={14} width='100%' className='mb-4' />
      <Skeleton duration={2} height={14} width='100%' className='mb-4' />
      <Skeleton duration={2} height={14} width='100%' className='mb-4' />
      <Skeleton duration={2} height={14} width='100%' className='mb-4' />
      <Skeleton duration={2} height={14} width='100%' className='mb-10' />
      <Skeleton duration={2} height={14} width='40%' className='mb-4' />
      <Skeleton duration={2} height={60} width='100%' className='mb-4' />
      <Skeleton duration={2} height={60} width='100%' className='mb-4' />
    </div>
  );
};

export default UserSidebarSkeleton;
