import { useEffect } from 'react';

const DataLoader = ({
  children,
  loadCurrentUser,
  userHasSignedIn,
  loadActions,
  loadBoosters,
  loadRankingUsers,
  setInventory,
  loadRoomCategories,
  loadEventCategories,
  subscribeToCreatorPermissionUpdate,
  user,
}) => {
  useEffect(async () => {
    await loadCurrentUser(setInventory);
  }, []);

  useEffect(async () => {
    let sub = null;
    if (user) {
      sub = await subscribeToCreatorPermissionUpdate();
    }

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [user]);

  useEffect(() => {
    if (userHasSignedIn) {
      loadActions();
      loadBoosters();
      loadRankingUsers();
      loadRoomCategories();
      loadEventCategories();
    }
  }, [userHasSignedIn]);

  return children;
};

export default DataLoader;
