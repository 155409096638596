import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Heading from '../typography/Heading';
import Paragraph from '../typography/Paragraph';
import Icon from '../typography/Icon';
import themeColors from '../../theme-colors';

const Wrapper = ({ disabled, path, children }) => {
  if (!disabled) {
    return (
      <Link
        to={path}
        className='col-span-6 flex p-8 border border-secondaryB rounded-lg shadow-card'
      >
        {children}
      </Link>
    );
  }

  return (
    <div
      className={`col-span-6 flex p-8 border border-secondaryB rounded-lg shadow-card${
        disabled ? ' opacity-60' : ''
      }`}
    >
      {children}
    </div>
  );
};

const RoomDashboardLink = ({
  heading,
  firstLine,
  secondLine,
  path,
  disabled,
}) => {
  if (!path) {
    return null;
  }

  return (
    <Wrapper disabled={disabled} path={path}>
      <div className='flex flex-col flex-1'>
        <Heading headingSize={5} classes='mb-4'>
          {heading}
        </Heading>
        <Paragraph paragraphSize='large'>{firstLine}</Paragraph>
        {secondLine && (
          <Paragraph paragraphSize='large'>{secondLine}</Paragraph>
        )}
      </div>
      <div className='flex items-center justify-center'>
        <div className='w-16 h-16 rounded-full bg-primaryA flex items-center justify-center'>
          <Icon name='plus' color={themeColors.primaryD} size={24} />
        </div>
      </div>
    </Wrapper>
  );
};

RoomDashboardLink.propTypes = {
  heading: PropTypes.string.isRequired,
  firstLine: PropTypes.string.isRequired,
  secondLine: PropTypes.string,
  mode: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RoomDashboardLink;
