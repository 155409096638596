import { useContext, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { UserContext } from '../context/user';
import { createWithdrawRequest } from '../graphql/mutations';
import { withdrawRequestsByuserID } from '../graphql/queries';
import { logError } from '../helpers';

const useWithdraw = () => {
  const [data, setData] = useState(null);
  const userContext = useContext(UserContext);
  const { getCurrentUserId } = userContext;

  const getPendingRequestsByUserId = async () => {
    const userID = getCurrentUserId();

    if (!userID) {
      logError('getWithdrawRequestsByUserId', 'No user id');
      return { success: false, data: null };
    }

    try {
      const res = await API.graphql(
        graphqlOperation(withdrawRequestsByuserID, {
          userID,
          status: { eq: 'pending' },
          limit: 1,
        })
      );

      setData(res.data.withdrawRequestsByuserID.items?.[0] || null);

      return {
        success: true,
        data: res.data.withdrawRequestsByuserID.items?.[0],
      };
    } catch (error) {
      logError('getWithdrawRequestsByUserId', error);
      return { success: false, data: null };
    }
  };

  const createWithdrawReq = async (data) => {
    try {
      const res = await API.graphql(
        graphqlOperation(createWithdrawRequest, {
          input: { userID: getCurrentUserId(), ...data },
        })
      );

      setData(res.data.createWithdrawRequest);
      return { success: true, data: res.data.createWithdrawRequest };
    } catch (error) {
      logError('createBankingData', error);
      return { success: false, data: null };
    }
  };

  return {
    data,
    createWithdrawReq,
    getPendingRequestsByUserId,
  };
};

export default useWithdraw;
