/* eslint-disable new-cap */
import React, { useContext, useEffect, useState } from 'react';

import Heading from '../../typography/Heading';

import { logError, formatDate } from '../../../helpers';
import { SponsorRoomsContext } from '../../../context/sponsorRooms';
import CardRoomAvatar from './CardRoomAvatar';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const bgColor = 'primaryD';
const primaryColor = 'primaryC';
const secondaryColor = 'secondaryA';

const getPPVText = (isOwn, isPurchased, price) => {
  if (isOwn) return 'OWN';
  if (isPurchased) return 'PURCHASED';
  return `PPV ${price} Coins`;
};

const CardContent = ({
  title,
  classes,
  date,
  description,
  hostUser,
  hostSponsorRoom,
  hostName,
  state,
  isPPV,
  price,
  eventId,
  showBuyModal,
  isPurchased,
  eventIsCreatedByCurrentUser,
  roomId,
}) => {
  const { getRoomById } = useContext(SponsorRoomsContext);
  const [room, setRoom] = useState(null);

  useEffect(() => {
    if (!roomId) return;

    getRoomById(roomId).then(setRoom);
  }, [roomId]);

  if (!title) {
    logError("Component CardContent not rendered, because 'title' missing.");
    return null;
  }

  const stateColor = state === 'live' ? 'primaryB' : secondaryColor;

  const onPriceClick = (e) => {
    if (eventIsCreatedByCurrentUser || isPurchased) return;

    e.stopPropagation();
    showBuyModal({ price, title, id: eventId });
  };

  const renderPPV = () => {
    if (isPPV && price) {
      return (
        <React.Fragment>
          <span>&middot;</span>
          <span
            className={`font-display font-medium m-0 text-xs capitalize text-${secondaryColor}`}
            onClick={onPriceClick}
          >
            {getPPVText(eventIsCreatedByCurrentUser, isPurchased, price)}
          </span>
        </React.Fragment>
      );
    }

    return null;
  };

  const avatarUri = room?.image?.key
    ? `${imgBaseUrl}${room.image.key}?w=200`
    : null;

  return (
    <div className={`flex gap-x-3 mt-2 rounded-b-lg bg-${bgColor} ${classes}`}>
      <CardRoomAvatar uri={avatarUri} />

      <div className={`flex flex-col`}>
        <Heading
          headingSize={6}
          classes={`mb-1 tracking-tight line-clamp-2 text-${primaryColor}`}
        >
          {title}
        </Heading>

        <div
          className={`flex gap-x-1 items-center mt-2 font-display font-medium text-xs text-${secondaryColor}`}
        >
          {state && (
            <span className={`capitalize text-${stateColor}`}>{state}</span>
          )}

          {state !== 'live' && (
            <React.Fragment>
              <span>&middot;</span>
              <time dateTime={date} className={`inline-block tracking-tight`}>
                {formatDate(date)}
              </time>
            </React.Fragment>
          )}

          {renderPPV()}
        </div>
      </div>
    </div>
  );
};

export default CardContent;
