import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/analytics';
import { useContext } from 'react';

import config from './config';
import { getCookie, getCurrentUserFromLocalStorage, logError } from './helpers';
import { EventsContext } from './context/events';

const firebaseConfig = config.firebaseConfig;

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

let messaging = null;

if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export const getToken = (updateUser) => {
  if (!messaging) {
    return null;
  }

  return messaging
    .getToken({
      vapidKey: config.webPushCertificate,
    })
    .then(async (currentToken) => {
      if (currentToken) {
        // logError(currentToken);
        await updateUser(currentToken);
      } else {
        logError(
          'No registration token available. Request permission to generate one.'
        );
      }
    })
    .catch((err) => {
      logError('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

export const analytics = () => {
  firebase.analytics();
  const user = getCurrentUserFromLocalStorage();

  if (user) {
    firebase.analytics().setUserId(user.id);
  }
};

export const setUserId = (id) => {
  firebase.analytics().setUserId(id);
};

let logs = 0;

export const useLogEvent = () => {
  const eventsContext = useContext(EventsContext);
  const { getCurrentEvent } = eventsContext;
  const logEvent = async (event, params) => {
    const user = getCurrentUserFromLocalStorage();

    if (user) {
      const id = user.id;
      const cookie = getCookie(id);
      logs = 0;
      if (cookie) {
        try {
          const value = JSON.parse(cookie);
          if (value.analytics) {
            if (params.eventId) {
              const event = await getCurrentEvent(params.eventId);
              if (event.state === 'live') {
                params.cl_video_type = 'live stream';
              } else if (
                ['upcoming', 'delayed', 'ended'].includes(event.state)
              ) {
                params.cl_video_type = event.state + ' event';
              } else {
                params.cl_video_type = event.state;
              }
              params.creator_id =
                event.hostSponsorRoom?.id || event.hostSponsorRoomID;
              params.creator_name =
                event.hostSponsorRoom?.title || 'Not Available';
              params.content_category = event.eventCategorisation?.title;
              params.cl_video_title = event.title;
            }
            firebase.analytics().logEvent(event, {
              ...params,
              user_id: user.id,
              cl_user_id: user.cl_user_id,
              environment: process.env.REACT_APP_ENV,
              debug_mode: true,
            });
          }
        } catch (error) {
          logError('Cannot parse cookie');
        }
      }
    } else {
      // Resolves the case where the user has just logged in
      // and the user's data has not yet been saved to localStorage
      setTimeout(() => {
        logs++;
        if (logs < 5) {
          logEvent(event, params);
        } else {
          logs = 0;
        }
      }, 500);
    }
  };
  return { logEvent };
};

export const logEvent = (event, params) => {
  const user = getCurrentUserFromLocalStorage();
  console.log('user', user);

  if (user) {
    const id = user.id;
    const cookie = getCookie(id);
    console.log('cookie', cookie)
    logs = 0;
    if (cookie) {
      try {
        const value = JSON.parse(cookie);
        console.log('cookie value', value);
        if (value.analytics) {
          firebase.analytics().logEvent(event, {
            ...params,
            user_id: user.id,
            cl_user_id: user.cl_user_id,
            environment: process.env.REACT_APP_ENV,
            debug_mode: true,
          });
          console.log('da', params);
        }
      } catch (error) {
        logError('Cannot parse cookie');
      }
    }
  } else {
    // Resolves the case where the user has just logged in
    // and the user's data has not yet been saved to localStorage
    setTimeout(() => {
      logs++;
      if (logs < 5) {
        logEvent(event, params);
      } else {
        logs = 0;
      }
    }, 500);
  }
};


let unregisteredEvents = [];
export const logUnregisteredEvent = (event, params) => {
  const user = getCurrentUserFromLocalStorage();
  console.log('user', user);

  if (user) {
    const id = user.id;
    const cookie = getCookie(id);
    console.log('cookie', cookie)
    if (cookie) {
      try {
        const value = JSON.parse(cookie);
        console.log('cookie value', value);
        if (value.analytics) {
          unregisteredEvents.forEach((e) => {
            firebase.analytics().logEvent(e.event, {
              ...e.params,
              user_id: user.id,
              cl_user_id: user.cl_user_id,
              environment: process.env.REACT_APP_ENV,
              debug_mode: true,
            });
            console.log('fired stored event', e.event, {
              ...e.params,
              user_id: user.id,
              cl_user_id: user.cl_user_id,
              environment: process.env.REACT_APP_ENV,
              debug_mode: true,
            });
          });
          unregisteredEvents = [];

          firebase.analytics().logEvent(event, {
            ...params,
            user_id: user.id,
            cl_user_id: user.cl_user_id,
            environment: process.env.REACT_APP_ENV,
            debug_mode: true,
          });
          console.log('fired event', event, {
            ...params,
            user_id: user.id,
            cl_user_id: user.cl_user_id,
            environment: process.env.REACT_APP_ENV,
            debug_mode: true,
          });
        }
      } catch (error) {
        logError('Cannot parse cookie');
      }
    }
  } else {
    unregisteredEvents.push({ event, params });
  }
};
