import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Spinner from '../system/Spinner';

const InputAutocomplete = ({
  id,
  containerClassName,
  onChange,
  onFocus,
  inputRef,
  placeholder,
  resultsList,
  disabled,
  resultsAreLoading,
  inputClassName,
}) => {
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    const input = inputRef.current;

    if (input === document.activeElement) {
      setShowResults(true);
    }
  });

  const handleFocus = async () => {
    const input = inputRef.current;
    await onFocus();

    if (input?.value) {
      setShowResults(true);
    }
  };

  const handleBlur = () => {
    setShowResults(false);
  };

  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className={`relative ${containerClassName}`}>
      <input
        ref={inputRef}
        autoComplete='off'
        id={id}
        placeholder={placeholder}
        type='text'
        onChange={handleChange}
        autoFocus={false}
        className={`base-input ${inputClassName}`}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
      />
      {resultsAreLoading && (
        <div className='absolute top-0 bottom-0 right-4 flex items-center justify-center'>
          <Spinner size={'extra-small'} />
        </div>
      )}
      {showResults && resultsList}
    </div>
  );
};

InputAutocomplete.propTypes = {
  id: PropTypes.string,
  containerClassName: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  resultsAreLoading: PropTypes.bool,
};

InputAutocomplete.defaultProps = {
  id: 'inputAutocomplete',
  placeholder: 'Search',
  containerClassName: '',
  onChange: () => {},
  onFocus: () => {},
  disabled: false,
  resultsAreLoading: false,
};

export default InputAutocomplete;
