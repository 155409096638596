import React, { useEffect } from 'react';

import Heading from '../typography/Heading';
import useScore from '../../hooks/useScore';
import { getVictoryStatus } from '../../helpers';

const EndedEventContestorStatus = ({ eventId, contestorA, contestorB }) => {
  const { loadScore, score } = useScore();

  useEffect(() => {
    loadScore(eventId);
  }, []);

  return (
    <div className='event-ended-contestors'>
      <div className='event-ended-contestorA-container'>
        <Heading headingSize={4} classes='text-white'>
          {getVictoryStatus(score.contestorAScore, score.contestorBScore)}
        </Heading>
        <div className='flex items-center whitespace-nowrap mb-10'>
          <div className='contestor-color blue'></div>
          <div className='event-contestor-name'>{contestorA}</div>
        </div>
        <div className='event-ended-score'>
          <div className='text-xl text-white font-display font-bold'>
            {score.contestorAScore}
          </div>
          <div className='text-base font-display font-medium text-white'>
            Points Clapped
          </div>
        </div>
      </div>
      <div className='event-ended-contestorB-container'>
        <Heading headingSize={4} classes='text-white'>
          {getVictoryStatus(score.contestorBScore, score.contestorAScore)}
        </Heading>
        <div className='flex items-center justify-end whitespace-nowrap mb-10'>
          <div className='event-contestor-name'>{contestorB}</div>
          <div className='contestor-color red'></div>
        </div>
        <div className='event-ended-score'>
          <div className='text-xl text-white font-display font-bold'>
            {score.contestorBScore}
          </div>
          <div className='text-base font-display font-medium text-white'>
            Points Clapped
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndedEventContestorStatus;
