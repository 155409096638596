import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { RoomCategoriesContext } from './roomCategories';
import {
  listRoomCategorisations,
  roomCategorisationPromoted,
  getRoomCategorisation,
} from '../graphql/queries';
import { logError } from '../helpers';
const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class RoomCategoriesProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roomCategories: [],
      promotedCategory: null,
      error: null,
      loading: false,
      loadRoomCategories: this.loadRoomCategories,
      loadPromotedCategory: this.loadPromotedCategory,
      getCategorieById: this.getCategorieById,
    };
  }

  getImageUrl = (key) => {
    return `${imgBaseUrl}${key}?w=360`;
  };

  getCategorieById = async (categoryId) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getRoomCategorisation, { id: categoryId })
      );

      return { success: true, category: res.data.getRoomCategorisation };
    } catch (error) {
      return { success: false, category: null };
    }
  };

  loadRoomCategories = async () => {
    try {
      this.setState({ loading: true });

      const res = await API.graphql(graphqlOperation(listRoomCategorisations));
      const items = res?.data?.listRoomCategorisations?.items || [];

      items.sort((a, b) => a.order - b.order);

      for (const i of items) {
        if (i?.cover?.key) {
          i.cover._url = this.getImageUrl(i.cover.key);
        }

        if (i?.logo?.key) {
          i.logo._url = this.getImageUrl(i.logo.key);
        }
      }

      this.setState({
        loading: false,
        roomCategories: items,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        roomCategories: [],
        error: 'Error loading room categories',
        loading: false,
      });
    }
  };

  loadPromotedCategory = async () => {
    try {
      const res = await API.graphql(
        graphqlOperation(roomCategorisationPromoted, { promoted: 1, limit: 1 })
      );

      const category = res.data.roomCategorisationPromoted.items[0] || null;

      this.setState({
        promotedCategory: category,
      });
    } catch (error) {
      this.setState({
        promotedCategory: null,
        error: 'Error loading promoted category',
      });
    }
  };

  render() {
    return (
      <RoomCategoriesContext.Provider value={this.state}>
        {this.props.children}
      </RoomCategoriesContext.Provider>
    );
  }
}
