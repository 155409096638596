import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ size, classes = '' }) => {
  return <div className={`loader ${size} ${classes}`}></div>;
};

Spinner.propTypes = {
  size: PropTypes.oneOf(['big', 'medium', 'small', 'extra-small']),
};

Spinner.defaultProps = {
  size: 'big',
};

export default Spinner;
