import {
  createAction,
  createAsyncAction,
} from "typesafe-actions";

import { PurchasedSubscription } from "domain/types";

import {
  EventMountedPayload,
  PuchaseSubscriptionPayload,
} from "./types";

export const eventPageMounted = createAction('Event/MOUNTED')<EventMountedPayload>();

export const puchaseSubscription = createAsyncAction(
  'Event/PURCHASE_PPV_REQUEST',
  'Event/PURCHASE_PPV_SUCCESS',
  'Event/PURCHASE_PPV_ERROR',
)<PuchaseSubscriptionPayload, PurchasedSubscription, string>();
