import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';

import CreatorEventForm from '../components/creator/CreatorEventForm';
import Heading from '../components/typography/Heading';

import { UserContext } from '../context/user';
import { getRoomSidebarWidth } from '../helpers';

import routes from '../routes';
import history from '../history';

const NewEventPage = () => {
  const userContext = useContext(UserContext);
  const { user, loading: userIsLoading, isCreator } = userContext;
  const sidebarWidth = getRoomSidebarWidth();

  if (!userIsLoading && !user) {
    history.replace({
      pathname: routes.login,
      state: { from: history.location },
    });
  }

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  return (
    <>
      <Helmet>
        <title>Create New Event | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>New Event</Heading>
      </div>
      <CreatorEventForm isNewEvent={true} eventId={null} />
    </>
  );
};

export default NewEventPage;
