import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  MeetingProvider,
  lightTheme,
} from 'amazon-chime-sdk-component-library-react';
import { ThemeProvider } from 'styled-components';

import { ContextProvider } from './context';
import Consumers from './context/Consumers';

import './App.css';
import Routing from './components/Routing';
import Header from './components/navbar/Header';
import MobileScreen from './MobileScreen';
import TabletScreen from './TabletScreen';
import CookieConsent from './components/cookie-consent/CookieConsent';
import history from './history';
import { analytics } from './firebase';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);

const App = () => {
  useEffect(() => {
    analytics();
  }, []);

  let isMobileView = false;

  if (history?.location?.search === '?mobile-view') {
    isMobileView = true;
  }

  return (
    <>
      {!isMobileView && <MobileScreen />}
      {!isMobileView && <TabletScreen />}
      <Elements stripe={stripePromise}>
        <Router history={history}>
          <ThemeProvider theme={lightTheme}>
            <MeetingProvider>
              <ContextProvider>
                <div className={!isMobileView ? 'desktop' : ''}>
                  <CookieConsent />
                  <Header />
                  <Consumers>
                    <Routing />
                  </Consumers>
                </div>
              </ContextProvider>
            </MeetingProvider>
          </ThemeProvider>
        </Router>
      </Elements>
    </>
  );
};

export default App;
