import React, { useContext, useEffect, useRef } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Avatar from '../common/Avatar';
import Input from '../form/Input';

import { SponsorRoomsContext } from '../../context/sponsorRooms';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const FollowerItem = ({ follower }) => {
  const getExistingImageUrl = (key) => {
    if (!key) {
      return null;
    }

    return `${imgBaseUrl}${key}`;
  };

  return (
    <div key={follower.id} className='flex items-center pl-4 mb-2'>
      <Avatar imgSrc={getExistingImageUrl(follower?.image?.key)} imgSize={5} />{' '}
      <span className='text-primaryA text-lg font-bold ml-4 hover:text-primaryADark cursor-default'>
        {follower.displayName}
      </span>
    </div>
  );
};

const FollowersList = ({ roomId }) => {
  const sponsorRoomsContext = useContext(SponsorRoomsContext);
  const {
    filterRoomFollowers,
    filteredFollowers,
    filteringFollowers,
    filterMoreRoomFollowers,
    filterNextToken,
  } = sponsorRoomsContext;

  const inputRef = useRef();

  useEffect(() => {
    filterRoomFollowers(roomId);
  }, []);

  const onChange = async (value) => {
    await filterRoomFollowers(roomId, value);
  };

  const handleLoadMore = async () => {
    await filterMoreRoomFollowers(roomId, inputRef.current.value);
  };

  const [sentryRef] = useInfiniteScroll({
    loading: filteringFollowers,
    hasNextPage: filterNextToken || false,
    onLoadMore: handleLoadMore,
  });

  return (
    <div className=''>
      <div className='mt-4'>
        <Input
          setRef={inputRef}
          id='room-name'
          placeholder='Search Followers'
          wrapClasses='mb-4'
          onChange={onChange}
          icon='search'
        />
      </div>
      <div ref={sentryRef} className='max-h-80 overflow-auto mb-4'>
        {filteredFollowers.map((follower) => (
          <FollowerItem key={follower.id} follower={follower} />
        ))}
      </div>
    </div>
  );
};

export default FollowersList;
