import React, { useContext, useEffect, useState } from 'react';
import { InventoryContext } from '../../context/inventory';
import useWithdraw from '../../hooks/useWithdraw';
import Spinner from '../system/Spinner';
import CurrentBalance from './CurrentBalance';
import PendingWarning from './PendingWarning';
import WithdrawForm from './WithdrawForm';

const WithdrawTile = ({ bankingData, loadedBankingData }) => {
  const [activeTile, setActiveTile] = useState('balance');

  const { data, getPendingRequestsByUserId } = useWithdraw();

  const inventoryContext = useContext(InventoryContext);
  const { coins, usd } = inventoryContext;

  useEffect(async () => {
    if (loadedBankingData) {
      await getPendingRequestsByUserId();
    }
  }, [activeTile, loadedBankingData]);

  const onBack = () => {
    setActiveTile('balance');
  };

  const handleTransitionToWithdraw = () => {
    if (data) {
      setActiveTile('pending');
      return;
    }

    setActiveTile('form');
  };

  return (
    <div className='col-span-full md:col-span-5 min-h-20 h-full shadow-card relative'>
      <CurrentBalance
        coins={coins}
        usd={usd}
        isVisible={activeTile === 'balance'}
        onClick={handleTransitionToWithdraw}
        bankingData={bankingData}
      />
      <WithdrawForm
        isVisible={activeTile === 'form'}
        onBack={onBack}
        usd={usd}
        bankingData={bankingData}
      />
      <PendingWarning isVisible={activeTile === 'pending'} onBack={onBack} />
      {!loadedBankingData && (
        <div className='absolute inset-0 flex items-center justify-center bg-white rounded-lg z-10'>
          <Spinner size='small' />
        </div>
      )}
    </div>
  );
};

export default WithdrawTile;
