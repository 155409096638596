import React, { Component } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { EventsContext } from './events';
import {
  getEvent,
  getEventLeaderBoard,
  eventByState,
  eventBySponsorRoom,
  eventByStartTimestampRealm,
  eventByStateAndRoomID,
  eventBySponsorRoomAndState,
  eventBySeoUrl,
} from '../graphql/queries';
import {
  onUpdateEvent,
  onUpdateEventLastUpdate,
} from '../graphql/subscriptions';
import {
  createEvent,
  deleteEvent,
  updateEvent,
  createQuestionAnswer,
  createTeaserVideo,
} from '../graphql/mutations';
import { logError, resizeImage } from '../helpers';

import awsExports from '../aws-exports';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;
const standardImages = ['image', 'image2', 'image3', 'image4'];
const landscapeImages = [
  'landscapeImage',
  'landscapeImage2',
  'landscapeImage3',
  'landscapeImage4',
];
const verticalImages = [
  'verticalImage',
  'verticalImage2',
  'verticalImage3',
  'verticalImage4',
];

export default class EventsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      eventsByHostUser: [],
      loading: false,
      loadingEventsByHostUser: false,
      error: null,
      nextToken: null,
      nextTokenForEventsByHostUser: null,
      loadedEventStates: [],
      stateLoaded: null,
      getCurrentEvent: this.getCurrentEvent,
      filterInitial: this.filterInitial,
      filterMore: this.filterMore,
      filterInitialByRoomAndState: this.filterInitialByRoomAndState,
      createEventSubscription: this.createEventSubscription,
      createDashboardSubscription: this.createDashboardSubscription,
      removeEventSubscription: this.removeEventSubscription,
      getEventLeaderBoard: this.getEventLeaderBoard,
      loadCreatorEvents: this.loadCreatorEvents,
      loadMoreCreatorEvents: this.loadMoreCreatorEvents,
      updateEventInState: this.updateEventInState,
      updateEvent: this.handleUpdateEvent,
      createEvent: this.createEvent,
      deleteEvent: this.deleteEvent,
      getNextEvent: this.getNextEvent,
      submitQuizAnswear: this.submitQuizAnswear,
      clearEventsByHostUserId: this.clearEventsByHostUserId,
      markAsDeleted: this.markAsDeleted,
      getLiveEventsByRoomId: this.getLiveEventsByRoomId,
      getUpcomingEventsByRoomIds: this.getUpcomingEventsByRoomIds,
      getEndedEventsByRoomIds: this.getEndedEventsByRoomIds,
      getLiveEventsByRoomIds: this.getLiveEventsByRoomIds,
      getLiveEventByRoomId: this.getLiveEventByRoomId,
      getEventsByStateAndRoomId: this.getEventsByStateAndRoomId,
      isTimeIntervalAvailable: this.isTimeIntervalAvailable,
      getAllEventsByRoomId: this.getAllEventsByRoomId,
      getEventIdBySeoUrl: this.getEventIdBySeoUrl,
      sortImageUrls: this.sortImageUrls,
    };
  }

  isTimeIntervalAvailable = async (
    eventId,
    roomId,
    startTimestamp,
    duration
  ) => {
    const { events: upcomingEvents } = await this.getUpcomingEventsByRoomIds([
      roomId,
    ]);
    const { event: liveEvent } = await this.getLiveEventByRoomId(roomId);
    const events = [...upcomingEvents];

    if (liveEvent) {
      events.push(liveEvent);
    }

    const filteredEvents = events.filter(
      (e) => e.mode === 'delayedLive' && e.duration !== null
    );

    const endTimestamp = startTimestamp + duration;

    const isSlotBeforeEvent = (eventStartTimestamp) => {
      return (
        startTimestamp < eventStartTimestamp &&
        endTimestamp < eventStartTimestamp
      );
    };

    const isSlotAfterEvent = (eventEndTimestamp) => {
      return (
        startTimestamp > eventEndTimestamp && endTimestamp > eventEndTimestamp
      );
    };

    if (filteredEvents.length === 0) {
      return true;
    }

    let slotIsFree = false;

    for (const event of filteredEvents) {
      if (event.id === eventId) {
        continue;
      }

      if (event.mode !== 'delayedLive') {
        continue;
      }

      if (event.duration === null) {
        continue;
      }

      const slotIsBeforeEvent = isSlotBeforeEvent(event.startTimestamp);
      const slotIsAfterEvent = isSlotAfterEvent(event.endTimestamp);

      if (slotIsBeforeEvent || slotIsAfterEvent) {
        slotIsFree = true;
      }

      if (!slotIsFree) {
        break;
      }
    }

    return slotIsFree;
  };

  // used for upcoming and ended events
  getEventsByStateAndRoomId = async (roomId, state, nextToken) => {
    const startTimestampObj = this.getRoomFilterTimeStamp(state);
    const sortDirection = state === 'upcoming' ? 'ASC' : 'DESC';
    const input = {
      sortDirection,
      nextToken,
      limit: 100,
      startTimestamp: startTimestampObj,
      hostSponsorRoomID: roomId,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(eventBySponsorRoom, input)
      );

      const items =
        res.data.eventBySponsorRoom.items.filter((e) => {
          return e.state === state;
        }) || [];
      const events = items.map((e) => {
        e.imageUrls = this.sortImageUrls(e);
        return e;
      });

      return {
        success: true,
        events,
        nextToken: res.data.eventBySponsorRoom.nextToken,
      };
    } catch (error) {
      logError('getEventsByStateAndRoomId', error);
      return {
        success: false,
        events: [],
        nextToken: null,
      };
    }
  };

  getAllEventsByRoomId = async (roomId, nextToken) => {
    const states = ['live', 'upcoming', 'ended'];
    const input = {
      sortDirection: 'ASC',
      nextToken,
      limit: 100,
      // startTimestamp: startTimestampObj,
      hostSponsorRoomID: roomId,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(eventBySponsorRoom, input)
      );

      const items =
        res.data.eventBySponsorRoom.items.filter((e) => {
          return states.includes(e.state);
        }) || [];
      const events = items.map((e) => {
        e.imageUrls = this.sortImageUrls(e);
        return e;
      });

      return {
        success: true,
        events,
        nextToken: res.data.eventBySponsorRoom.nextToken,
      };
    } catch (error) {
      logError('getEventsByStateAndRoomId', error);
      return {
        success: false,
        events: [],
        nextToken: null,
      };
    }
  };

  getLiveEventByRoomId = async (roomId) => {
    try {
      const res = await API.graphql(
        graphqlOperation(eventBySponsorRoomAndState, {
          state: { eq: 'live' },
          limit: 1,
          hostSponsorRoomID: roomId,
        })
      );

      const e = res.data.eventBySponsorRoomAndState.items[0] || null;

      if (e) {
        e.imageUrls = this.sortImageUrls(e);
      }

      return { success: true, event: e };
    } catch (error) {
      logError('getLiveEventbyRoomId', error);
      return { success: false, event: null };
    }
  };

  getLiveEventsByRoomIds = async (roomIds) => {
    try {
      let nextToken = null;
      const events = [];

      do {
        const res = await API.graphql(
          graphqlOperation(eventByStateAndRoomID, {
            nextToken,
            state: 'live',
            hostSponsorRoomID: { in: roomIds },
            limit: 20,
          })
        );

        for (const e of res.data.eventByStateAndRoomID.items) {
          if (roomIds.includes(e.hostSponsorRoomID)) {
            events.push(e);
          }
        }

        nextToken = res.data.eventByStateAndRoomID.nextToken;
      } while (nextToken !== null);

      for (const e of events) {
        e.imageUrls = this.sortImageUrls(e);
      }

      const sortedEvents = events.sort(
        (a, b) => new Date(a.startTimestamp) - new Date(b.startTimestamp)
      );

      return {
        events: sortedEvents,
        success: true,
      };
    } catch (error) {
      logError(error);
      return { success: false, events: [] };
    }
  };

  getUpcomingEventsByRoomIds = async (roomIds) => {
    try {
      let nextToken = null;
      const events = [];

      do {
        const res = await API.graphql(
          graphqlOperation(eventByStateAndRoomID, {
            nextToken,
            state: 'upcoming',
            hostSponsorRoomID: { in: roomIds },
            limit: 100,
          })
        );

        for (const e of res.data.eventByStateAndRoomID.items) {
          if (roomIds.includes(e.hostSponsorRoomID)) {
            events.push(e);
          }
        }

        nextToken = res.data.eventByStateAndRoomID.nextToken;
      } while (nextToken !== null);

      for (const e of events) {
        e.imageUrls = this.sortImageUrls(e);
      }

      const sortedEvents = events.sort(
        (a, b) => new Date(a.startTimestamp) - new Date(b.startTimestamp)
      );

      return {
        events: sortedEvents,
        success: true,
      };
    } catch (error) {
      logError(error);
      return { success: false, events: [] };
    }
  };

  getEndedEventsByRoomIds = async (roomIds) => {
    try {
      let nextToken = null;
      const events = [];

      do {
        const res = await API.graphql(
          graphqlOperation(eventByStateAndRoomID, {
            nextToken,
            state: 'ended',
            hostSponsorRoomID: { in: roomIds },
            limit: 100,
          })
        );

        for (const e of res.data.eventByStateAndRoomID.items) {
          if (roomIds.includes(e.hostSponsorRoomID)) {
            events.push(e);
          }
        }

        nextToken = res.data.eventByStateAndRoomID.nextToken;
      } while (nextToken !== null);

      for (const e of events) {
        e.imageUrls = this.sortImageUrls(e);
      }

      const sortedEvents = events.sort(
        (a, b) => new Date(a.startTimestamp) - new Date(b.startTimestamp)
      );

      return {
        events: sortedEvents,
        success: true,
      };
    } catch (error) {
      logError(error);
      return { success: false, events: [] };
    }
  };

  getLiveEventsByRoomId = async (roomId, nextToken = null) => {
    const input = {
      nextToken,
      limit: 100,
      hostSponsorRoomID: roomId,
      filter: { state: { eq: 'live' } },
    };

    try {
      const res = await API.graphql(
        graphqlOperation(eventBySponsorRoom, input)
      );

      return {
        events: res.data.eventBySponsorRoom.items,
        stateLoaded: 'upcoming',
        nextToken: res.data.eventBySponsorRoom.nextToken,
        error: null,
      };
    } catch (error) {
      logError('getLiveEventsByRoomId', error);
      return {
        error,
        events: [],
        nextToken: null,
        stateLoaded: 'upcoming',
      };
    }
  };

  submitQuizAnswear = async (eventID, userID, answer) => {
    try {
      await API.graphql(
        graphqlOperation(createQuestionAnswer, {
          input: { eventID, userID, answer },
        })
      );

      return { success: true, error: null };
    } catch (error) {
      logError(error);
      return { success: false, error: 'Error submiting answear' };
    }
  };

  getS3ImageObject = async (s3PutRes) => {
    return {
      bucket: awsExports['aws_user_files_s3_bucket'],
      region: awsExports['aws_user_files_s3_bucket_region'],
      key: s3PutRes.key,
    };
  };

  handleUpdateEvent = async (eventData, imageFiles, teaserFile) => {
    try {
      if (
        imageFiles &&
        Object.keys(imageFiles).length > 0 &&
        imageFiles.constructor === Object
      ) {
        for (const field in imageFiles) {
          if (Object.hasOwnProperty.call(imageFiles, field)) {
            const file = await resizeImage(imageFiles[field]);
            const fileType = file.type;

            if (!fileType.includes('image')) {
              logError(field, 'File is not an image');
              continue;
            }

            const ext = fileType.substring(fileType.indexOf('/') + 1);
            const fileName = `${Date.now()}.${ext}`;
            const s3PutRes = await Storage.put(fileName, file, {
              contentType: fileType,
            });

            if (s3PutRes) {
              const image = await this.getS3ImageObject(s3PutRes);
              eventData[field] = image;
            }
          }
        }
      }

      if (teaserFile) {
        const videoFileType = teaserFile.type;
        const videoExt = videoFileType.substring(
          videoFileType.indexOf('/') + 1
        );
        const videoFileName = `${Date.now()}.${videoExt}`;

        const videoS3PutRes = await Storage.put(videoFileName, teaserFile, {
          useAccelerateEndpoint: true, // use accelerated S3 endpoint
          contentType: videoFileType,
        });

        if (videoS3PutRes) {
          const teaserS3Object = await this.getS3ImageObject(videoS3PutRes);
          await API.graphql(
            graphqlOperation(createTeaserVideo, {
              input: { eventID: eventData.id, file: teaserS3Object },
            })
          );
          const eventRes = await API.graphql(
            graphqlOperation(getEvent, { id: eventData.id })
          );
          const lastEventData = eventRes.data.getEvent || null;

          return {
            success: true,
            error: null,
            updatedEvent: lastEventData,
          };
        }
      }

      const result = await API.graphql(
        graphqlOperation(updateEvent, { input: eventData })
      );

      return {
        success: true,
        error: null,
        updatedEvent: result.data.updateEvent,
      };
    } catch (error) {
      return { success: false, error, updateEvent: null };
    }
  };

  deleteEvent = async (id) => {
    this.setState({
      eventsByHostUser: this.state.eventsByHostUser.filter((e) => e.id !== id),
    });

    try {
      await API.graphql(graphqlOperation(deleteEvent, { input: { id } }));
      return { success: true, error: null };
    } catch (error) {
      logError('Error deleting event', error);
      return { success: false, error: 'Error deleting event' };
    }
  };

  markAsDeleted = async (id) => {
    this.setState({
      eventsByHostUser: this.state.eventsByHostUser.filter((e) => e.id !== id),
    });

    try {
      await API.graphql(
        graphqlOperation(updateEvent, {
          input: {
            id,
            deleted: true,
            state: 'inactive',
            startTimestamp: -1000,
          },
        })
      );
      return { success: true, error: null };
    } catch (error) {
      logError('Error marking event as deleted', error);
      return { success: false, error: 'Error marking event as deleted' };
    }
  };

  createEvent = async (newEvent, imageFiles) => {
    if (
      imageFiles &&
      Object.keys(imageFiles).length > 0 &&
      imageFiles.constructor === Object
    ) {
      for (const field in imageFiles) {
        if (Object.hasOwnProperty.call(imageFiles, field)) {
          const file = imageFiles[field];
          const fileType = file.type;

          if (!fileType.includes('image')) {
            logError(field, 'File is not an image');
            continue;
          }

          const ext = fileType.substring(fileType.indexOf('/') + 1);
          const fileName = `${Date.now()}.${ext}`;
          const s3PutRes = await Storage.put(fileName, file, {
            contentType: fileType,
          });

          if (s3PutRes) {
            const image = await this.getS3ImageObject(s3PutRes);
            newEvent[field] = image;
          }
        }
      }
    }

    try {
      const res = await API.graphql(
        graphqlOperation(createEvent, { input: newEvent })
      );

      return { success: true, error: null, event: res.data.createEvent };
    } catch (error) {
      logError('Error creating new event', error);
      return { success: false, error: error };
    }
  };

  updateEventInState = (newData) => {
    const events = [...this.state.events];
    const eventIndex = events.findIndex((e) => e.id === newData.id);

    if (eventIndex !== -1) {
      Object.keys(newData).forEach(
        (k) => newData[k] === null && delete newData[k]
      );
      events[eventIndex] = { ...events[eventIndex], ...newData };
      this.setState({ events });
    }
  };

  getEventLeaderBoard = async (userId) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getEventLeaderBoard, { id: userId })
      );

      if (res?.data?.getEventLeaderBoard?.board) {
        return JSON.parse(res.data.getEventLeaderBoard.board);
      }
    } catch (error) {
      logError(error);
      return null;
    }
  };

  createEventSubscription = async (eventId, handleUpdate, handleError) => {
    const eventUpdateSubscription = await API.graphql(
      graphqlOperation(onUpdateEvent, {
        id: eventId,
      })
    ).subscribe({
      next: ({ provider, value }) => {
        if (value.data.onUpdateEvent) {
          handleUpdate(value.data.onUpdateEvent);
        }
      },
      error: (error) => handleError(error),
    });

    return eventUpdateSubscription;
  };

  removeEventSubscription = async (subscription) => {
    if (subscription.unsubscribe) {
      await subscription.unsubscribe();
    }
  };

  getScrollDistance = () => {
    return window.pageYOffset !== undefined
      ? window.pageYOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;
  };

  createDashboardSubscription = async (refreshDashboard, showPopup) => {
    const dashboardSubscription = await API.graphql(
      graphqlOperation(onUpdateEventLastUpdate)
    ).subscribe({
      next: ({ provider, value }) => {
        const handleUpdate = () => {
          if (this.getScrollDistance() > 500) {
            showPopup(true);
          } else {
            refreshDashboard();
          }
        };

        if (value.data.onUpdateEventLastUpdate) {
          const isNewEvent =
            value.data.onUpdateEventLastUpdate.type === 'new_event' ||
            value.data.onUpdateEventLastUpdate.type === 'going_upcoming';

          const eventInDashboard = this.state.events.find(
            (event) => event.id === value.data.onUpdateEventLastUpdate.eventID
          );

          if (
            window.location.pathname.includes('/state/upcoming') &&
            isNewEvent
          ) {
            handleUpdate();
            return;
          }

          if (!eventInDashboard) {
            return;
          }

          handleUpdate();
        }
      },
      error: (error) => logError('Dashboard Subscription error', error),
    });

    return dashboardSubscription;
  };

  getCurrentEvent = async (eventId, force = false) => {
    if (!eventId) {
      return;
    }

    let currentEvent = this.state.events.find((e) => e.id === eventId);

    if (!currentEvent || force) {
      try {
        const res = await API.graphql(
          graphqlOperation(getEvent, { id: eventId })
        );
        currentEvent = res.data.getEvent || null;

        if (currentEvent) {
          currentEvent.imageUrls = this.sortImageUrls(currentEvent);
        }
      } catch (error) {
        logError('loadCurrentEvent', error);
        currentEvent = null;
      }
    }

    return currentEvent;
  };

  getNextEvent = async () => {
    const input = {
      sortDirection: 'ASC',
      limit: 1,
      realm: 'A',
      startTimestamp: {
        ge: parseInt(new Date().getTime() / 1000),
      },
      nextToken: null,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(eventByStartTimestampRealm, input)
      );

      const items = res?.data?.eventByStartTimestampRealm?.items || [];

      return items?.[0] || null;
    } catch (error) {
      logError('getEventsByHostUserId', error);
      return null;
    }
  };

  clearEventsByHostUserId = () => {
    this.setState({
      eventsByHostUser: [],
      loadingEventsByHostUser: false,
      nextTokenForEventsByHostUser: null,
    });
  };

  loadCreatorEvents = async (
    hostSponsorRoomID,
    state,
    type,
    category,
    title
  ) => {
    if (this.state.loadingEventsByHostUser) {
      return;
    }

    this.setState({ loadingEventsByHostUser: true });
    const filters = {
      hostSponsorRoomID: { eq: hostSponsorRoomID },
      deleted: { ne: true },
    };

    if (state) {
      filters.state = { eq: state };
    }

    if (type) {
      filters.type = { eq: type };
    }

    if (category) {
      filters.category = { eq: category };
    }

    if (title) {
      filters.title = { contains: title };
    }

    const input = {
      sortDirection: 'DESC',
      limit: 200,
      filter:
        filters &&
        Object.keys(filters).length === 0 &&
        filters.constructor === Object
          ? null
          : filters,
      realm: 'A',
      startTimestamp: {
        ge: -1000,
      },
      nextToken: null,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(eventByStartTimestampRealm, input)
      );

      const items = res?.data?.eventByStartTimestampRealm?.items || [];

      this.setState({
        eventsByHostUser: items,
        loadingEventsByHostUser: false,
        nextTokenForEventsByHostUser:
          res.data.eventByStartTimestampRealm.nextToken,
      });
    } catch (error) {
      logError('loadCreatorEvents', error);
      this.setState({
        eventsByHostUser: [],
        loadingEventsByHostUser: false,
        nextTokenForEventsByHostUser: null,
      });
    }
  };

  loadMoreCreatorEvents = async (
    hostSponsorRoomID,
    state,
    type,
    category,
    title
  ) => {
    if (!this.state.nextTokenForEventsByHostUser) {
      return;
    }

    this.setState({ loadingEventsByHostUser: true });
    const filters = {
      hostSponsorRoomID: { eq: hostSponsorRoomID },
      deleted: { ne: true },
    };

    if (state) {
      filters.state = { eq: state };
    }

    if (type) {
      filters.type = { eq: type };
    }

    if (category) {
      filters.category = { eq: category };
    }

    if (title) {
      filters.title = { contains: title };
    }

    const input = {
      sortDirection: 'DESC',
      limit: 200,
      filter:
        filters &&
        Object.keys(filters).length === 0 &&
        filters.constructor === Object
          ? null
          : filters,
      realm: 'A',
      startTimestamp: {
        ge: -1000,
      },
      nextToken: this.state.nextTokenForEventsByHostUser,
    };

    try {
      const res = await API.graphql(
        graphqlOperation(eventByStartTimestampRealm, input)
      );

      const items = res?.data?.eventByStartTimestampRealm?.items || [];

      this.setState({
        eventsByHostUser: [...this.state.eventsByHostUser, ...items],
        loadingEventsByHostUser: false,
        nextTokenForEventsByHostUser:
          res.data.eventByStartTimestampRealm.nextToken,
      });
    } catch (error) {
      logError('loadMoreCreatorEvents', error);
      this.setState({
        eventsByHostUser: [],
        loadingEventsByHostUser: false,
        nextTokenForEventsByHostUser: null,
      });
    }
  };

  getRoomFilterTimeStamp = (state) => {
    if (state === 'upcoming') {
      return {
        gt: parseInt(new Date().getTime() / 1000) - 3 * 60 * 60,
      };
    }

    return { lt: parseInt(new Date().getTime() / 1000) };
  };

  filterInitialByRoomAndState = async (roomId, state) => {
    if (this.state.loading) {
      return { success: false };
    }

    if (!roomId) {
      return await this.filterInitial(roomId, state);
    }

    this.setState({
      loading: true,
      events: [],
      stateLoaded: null,
      error: null,
      nextToken: null,
    });

    return await this.getEventsByRoomAndState(roomId, state);
  };

  filterMoreByRoomAndState = async (roomId, state) => {
    return await this.getEventsByRoomAndState(roomId, state, true);
  };

  getEventsByRoomAndState = async (
    roomId,
    state,
    useNextTokenFromState = false
  ) => {
    const sortDirection = state === 'upcoming' ? 'ASC' : 'DESC';
    const stateFilters =
      state === 'upcoming' ? ['ended', 'inactive'] : ['live', 'inactive'];
    const startTimestampObj = this.getRoomFilterTimeStamp(state);
    const input = {
      sortDirection,
      limit: 10,
      startTimestamp: startTimestampObj,
      hostSponsorRoomID: roomId,
      nextToken: useNextTokenFromState ? this.state.nextToken : null,
    };

    let newItems = [];
    let nextToken = null;

    try {
      do {
        const res = await API.graphql(
          graphqlOperation(eventBySponsorRoom, input)
        );

        const data = res?.data?.eventBySponsorRoom;

        if (data) {
          const items = data.items;
          nextToken = data.nextToken;

          input.nextToken = nextToken;

          const filteredItems = items.filter(
            (item) => !stateFilters.includes(item.state)
          );

          newItems = [...newItems, ...filteredItems];
        }
      } while (newItems.length < 10 && nextToken);

      for (const item of newItems) {
        item.imageUrls = this.sortImageUrls(item);
      }
      this.setState({
        events: [...this.state.events, ...newItems],
        stateLoaded: state,
        loadedEventStates: [],
        loading: false,
        error: null,
        nextToken,
      });

      return { success: true, events: newItems };
    } catch (error) {
      this.setState({
        loading: false,
        error: 'Error filtering events',
        nextToken: null,
        loadedEventStates: [],
        stateLoaded: state,
      });

      return { success: false, events: [] };
    }
  };

  filterInitial = async (roomId, state) => {
    if (this.state.loading) {
      return { success: true, events: [] };
    }

    this.setState({
      loading: true,
      events: [],
      error: null,
      nextToken: null,
      stateLoaded: null,
    });

    let loadedEventStates;

    if (!roomId) {
      if (state === 'upcoming') {
        loadedEventStates = [
          { state: 'live', value: false },
          { state: 'upcoming', value: false },
        ];
      }

      if (state === 'ended') {
        loadedEventStates = [{ state: 'ended', value: false }];
      }

      return await this.getEventsByState(state, loadedEventStates);
    }

    return await this.filterInitialByRoomAndState(roomId, state);
  };

  getEventsByState = async (state, loadedEventStates = null) => {
    const input = {
      limit: 10,
      startTimestamp: {
        gt: 0,
      },
    };
    const sortDirection = state === 'upcoming' ? 'ASC' : 'DESC';

    let loadedStates;

    if (loadedEventStates) {
      loadedStates = [...loadedEventStates];
    } else {
      loadedStates = [...this.state.loadedEventStates];
    }

    let nextToken = loadedEventStates ? null : this.state.nextToken;

    const stateNotFullyLoaded = loadedStates.find(({ value }) => {
      return value === false;
    });

    if (stateNotFullyLoaded) {
      const inputState = stateNotFullyLoaded.state;

      input.nextToken = nextToken;
      input.state = inputState;
      input.sortDirection = sortDirection;

      try {
        const res = await API.graphql(graphqlOperation(eventByState, input));
        if (res?.data?.eventByState) {
          let newEvents = res.data.eventByState.items;
          nextToken = res.data.eventByState.nextToken;

          if (nextToken === null) {
            for (const st of loadedStates) {
              if (st.state === inputState) {
                st.value = true;
                break;
              }
            }
          }

          if (newEvents.length < 10) {
            const stateNotFullyLoaded = loadedStates.find(({ value }) => {
              return value === false;
            });

            if (stateNotFullyLoaded) {
              const inputState = stateNotFullyLoaded.state;

              input.state = inputState;
              input.nextToken = nextToken;

              const newRes = await API.graphql(
                graphqlOperation(eventByState, input)
              );

              if (newRes?.data?.eventByState) {
                const newResEvents = newRes.data.eventByState.items;
                nextToken = newRes.data.eventByState.nextToken;

                newEvents = [...newEvents, ...newResEvents];

                if (nextToken === null) {
                  for (const st of loadedStates) {
                    if (st.state === inputState) {
                      st.value = true;
                      break;
                    }
                  }
                }
              }
            }
          }

          for (const e of newEvents) {
            e.imageUrls = this.sortImageUrls(e);
          }

          this.setState({
            events: [...this.state.events, ...newEvents],
            loadedEventStates: loadedStates,
            stateLoaded: state,
            loading: false,
            error: null,
            nextToken,
          });

          return { success: true, events: newEvents };
        }
      } catch (error) {
        logError('getEventsByState', error);
        this.setState({
          loading: false,
          error: 'Error loading events',
          nextToken: null,
          stateLoaded: state,
        });

        return { success: false, events: [] };
      }
    }
  };

  filterMore = async (roomId, state) => {
    if (!roomId) {
      return await this.getEventsByState(state);
    }

    return await this.filterMoreByRoomAndState(roomId, state);
  };

  getFiltersAdDirection = (roomId, state) => {
    let filters;
    let sortDirection = 'ASC';

    if (roomId) {
      filters = { and: [] };
      filters.and.push({ hostSponsorRoomID: { eq: roomId } });
    }

    if (state === 'ended') {
      sortDirection = 'DESC';
    }

    return {
      filters,
      sortDirection,
    };
  };

  filterByState = (events, state) => {
    const filteredEvents = [];
    if (state) {
      for (const e of events) {
        if (
          state === 'upcoming' &&
          (e.state === 'upcoming' || e.state === 'live')
        ) {
          filteredEvents.push(e);
        }

        if (state === 'ended' && e.state === 'ended') {
          filteredEvents.push(e);
        }
      }

      return filteredEvents;
    }

    return events;
  };

  getImageUrl = (image, width) => {
    const s3Key = image?.key;

    return `${imgBaseUrl}${s3Key}?w=${width}`;
  };

  sortImageUrls = (item) => {
    const imageUrls = {
      standard: [],
      landscape: [],
      vertical: [],
    };

    for (const key in standardImages) {
      if (item[standardImages[key]]) {
        const url = this.getImageUrl(item[standardImages[key]], 360);

        if (url) {
          imageUrls.standard.push(url);
        }
      }
    }

    for (const key in landscapeImages) {
      if (item[landscapeImages[key]]) {
        const url = this.getImageUrl(item[landscapeImages[key]], 720);

        if (url) {
          imageUrls.landscape.push(url);
        }
      }
    }

    for (const key in verticalImages) {
      if (item[verticalImages[key]]) {
        const url = this.getImageUrl(item[verticalImages[key]], 360);

        if (url) {
          imageUrls.vertical.push(url);
        }
      }
    }

    return imageUrls;
  };

  getEventIdBySeoUrl = async (seoUrl) => {
    let currentEvent;
    const nextToken = null;
    let eventId = null;
    try {
      const res = await API.graphql(
        graphqlOperation(eventBySeoUrl, { seoUrl, nextToken })
      );

      currentEvent = res.data.eventBySeoUrl.items[0] || null;

      if (currentEvent) {
        eventId = currentEvent.id;
      } else {
        eventId = seoUrl;
      }
    } catch (error) {
      logError('EventBySeoUrl', error);
      eventId = null;
    }
    return eventId;
  };

  render() {
    return (
      <EventsContext.Provider value={this.state}>
        {this.props.children}
      </EventsContext.Provider>
    );
  }
}
