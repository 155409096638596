/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const customUpdateUser = /* GraphQL */ `
  mutation CustomUpdateUser(
    $id: ID!
    $email: String
    $phone: String
    $fullName: String
    $displayName: String
    $displayNameLowerCase: String
    $cognitoUsername: String
    $location: String
    $info: String
    $profileCompleted: Boolean
    $notifications: Int
    $subscribedForNews: Boolean
    $coins: Int
    $level: Int
    $rank: Int
    $battles: Int
    $wins: Int
    $experience: Int
    $inventory: String
    $activatedBoosters: String
    $realm: String
    $achievements: String
    $image: S3ObjectInput
    $notificationsArray: [NotificationInput]
    $fcmID: String
    $isWild: Int
  ) {
    customUpdateUser(
      id: $id
      email: $email
      phone: $phone
      fullName: $fullName
      displayName: $displayName
      displayNameLowerCase: $displayNameLowerCase
      cognitoUsername: $cognitoUsername
      location: $location
      info: $info
      profileCompleted: $profileCompleted
      notifications: $notifications
      subscribedForNews: $subscribedForNews
      coins: $coins
      level: $level
      rank: $rank
      battles: $battles
      wins: $wins
      experience: $experience
      inventory: $inventory
      activatedBoosters: $activatedBoosters
      realm: $realm
      achievements: $achievements
      image: $image
      notificationsArray: $notificationsArray
      fcmID: $fcmID
      isWild: $isWild
    ) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const customUpdateUserFcmId = /* GraphQL */ `
  mutation CustomUpdateUserFcmId($id: ID!, $fcmID: String) {
    customUpdateUserFcmId(id: $id, fcmID: $fcmID) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const customUpdateUserMainAttributes = /* GraphQL */ `
  mutation CustomUpdateUserMainAttributes(
    $id: ID!
    $email: String
    $phone: String
    $displayName: String
    $displayNameLowerCase: String
    $isWild: Int
  ) {
    customUpdateUserMainAttributes(
      id: $id
      email: $email
      phone: $phone
      displayName: $displayName
      displayNameLowerCase: $displayNameLowerCase
      isWild: $isWild
    ) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventLastUpdate = /* GraphQL */ `
  mutation DeleteEventLastUpdate(
    $input: DeleteEventLastUpdateInput!
    $condition: ModelEventLastUpdateConditionInput
  ) {
    deleteEventLastUpdate(input: $input, condition: $condition) {
      id
      type
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const updateBoosterActivation = /* GraphQL */ `
  mutation UpdateBoosterActivation(
    $input: UpdateBoosterActivationInput!
    $condition: ModelBoosterActivationConditionInput
  ) {
    updateBoosterActivation(input: $input, condition: $condition) {
      id
      userID
      boosterID
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteBoosterActivation = /* GraphQL */ `
  mutation DeleteBoosterActivation(
    $input: DeleteBoosterActivationInput!
    $condition: ModelBoosterActivationConditionInput
  ) {
    deleteBoosterActivation(input: $input, condition: $condition) {
      id
      userID
      boosterID
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      description
      category
      type
      runWorkflowAutomatically
      videoInputUrl
      streamKey
      workflowID
      workflowType
      videoUrl
      teaserUrl
      videoRecordingDownloadUrl
      teaserThumbnailUrl
      libraryVodGuid
      startTime
      endTime
      startTimestamp
      endTimestamp
      hostSponsorRoomID
      moderatorID
      contestorA
      contestorAColor
      contestorB
      contestorBColor
      contestorAScore
      contestorBScore
      image {
        bucket
        region
        key
        _url
      }
      image2 {
        bucket
        region
        key
        _url
      }
      image3 {
        bucket
        region
        key
        _url
      }
      image4 {
        bucket
        region
        key
        _url
      }
      teaserFile {
        bucket
        region
        key
        _url
      }
      teaserFileBrokerID
      landscapeImage {
        bucket
        region
        key
        _url
      }
      landscapeImage2 {
        bucket
        region
        key
        _url
      }
      landscapeImage3 {
        bucket
        region
        key
        _url
      }
      landscapeImage4 {
        bucket
        region
        key
        _url
      }
      verticalImage {
        bucket
        region
        key
        _url
      }
      verticalImage2 {
        bucket
        region
        key
        _url
      }
      verticalImage3 {
        bucket
        region
        key
        _url
      }
      verticalImage4 {
        bucket
        region
        key
        _url
      }
      currentRound
      state
      paused
      archived
      deleted
      censorChat
      realm
      highlighted
      createdAt
      updatedAt
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      eventCategorisationID
      roundsEnabled
      mixerEventUUID
      mixerEventModeratorUUID
      mixerEventModeratorAccessCode
      mode
      disableChat
      dynamicParticipants
      ppv
      ppvPrice
      duration
      richTextDescription
      googleAdTagUrl
      seoUrl
      moderator {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
      hostSponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      description
      category
      type
      runWorkflowAutomatically
      videoInputUrl
      streamKey
      workflowID
      workflowType
      videoUrl
      teaserUrl
      videoRecordingDownloadUrl
      teaserThumbnailUrl
      libraryVodGuid
      startTime
      endTime
      startTimestamp
      endTimestamp
      hostSponsorRoomID
      moderatorID
      contestorA
      contestorAColor
      contestorB
      contestorBColor
      contestorAScore
      contestorBScore
      image {
        bucket
        region
        key
        _url
      }
      image2 {
        bucket
        region
        key
        _url
      }
      image3 {
        bucket
        region
        key
        _url
      }
      image4 {
        bucket
        region
        key
        _url
      }
      teaserFile {
        bucket
        region
        key
        _url
      }
      teaserFileBrokerID
      landscapeImage {
        bucket
        region
        key
        _url
      }
      landscapeImage2 {
        bucket
        region
        key
        _url
      }
      landscapeImage3 {
        bucket
        region
        key
        _url
      }
      landscapeImage4 {
        bucket
        region
        key
        _url
      }
      verticalImage {
        bucket
        region
        key
        _url
      }
      verticalImage2 {
        bucket
        region
        key
        _url
      }
      verticalImage3 {
        bucket
        region
        key
        _url
      }
      verticalImage4 {
        bucket
        region
        key
        _url
      }
      currentRound
      state
      paused
      archived
      deleted
      censorChat
      realm
      highlighted
      createdAt
      updatedAt
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      eventCategorisationID
      roundsEnabled
      mixerEventUUID
      mixerEventModeratorUUID
      mixerEventModeratorAccessCode
      mode
      disableChat
      dynamicParticipants
      ppv
      ppvPrice
      duration
      richTextDescription
      googleAdTagUrl
      seoUrl
      moderator {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
      hostSponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      description
      category
      type
      runWorkflowAutomatically
      videoInputUrl
      streamKey
      workflowID
      workflowType
      videoUrl
      teaserUrl
      videoRecordingDownloadUrl
      teaserThumbnailUrl
      libraryVodGuid
      startTime
      endTime
      startTimestamp
      endTimestamp
      hostSponsorRoomID
      moderatorID
      contestorA
      contestorAColor
      contestorB
      contestorBColor
      contestorAScore
      contestorBScore
      image {
        bucket
        region
        key
        _url
      }
      image2 {
        bucket
        region
        key
        _url
      }
      image3 {
        bucket
        region
        key
        _url
      }
      image4 {
        bucket
        region
        key
        _url
      }
      teaserFile {
        bucket
        region
        key
        _url
      }
      teaserFileBrokerID
      landscapeImage {
        bucket
        region
        key
        _url
      }
      landscapeImage2 {
        bucket
        region
        key
        _url
      }
      landscapeImage3 {
        bucket
        region
        key
        _url
      }
      landscapeImage4 {
        bucket
        region
        key
        _url
      }
      verticalImage {
        bucket
        region
        key
        _url
      }
      verticalImage2 {
        bucket
        region
        key
        _url
      }
      verticalImage3 {
        bucket
        region
        key
        _url
      }
      verticalImage4 {
        bucket
        region
        key
        _url
      }
      currentRound
      state
      paused
      archived
      deleted
      censorChat
      realm
      highlighted
      createdAt
      updatedAt
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      eventCategorisationID
      roundsEnabled
      mixerEventUUID
      mixerEventModeratorUUID
      mixerEventModeratorAccessCode
      mode
      disableChat
      dynamicParticipants
      ppv
      ppvPrice
      duration
      richTextDescription
      googleAdTagUrl
      seoUrl
      moderator {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
      hostSponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createGoogleDefaultTagUrl = /* GraphQL */ `
  mutation CreateGoogleDefaultTagUrl(
    $input: CreateGoogleDefaultTagUrlInput!
    $condition: ModelGoogleDefaultTagUrlConditionInput
  ) {
    createGoogleDefaultTagUrl(input: $input, condition: $condition) {
      id
      googleAdTagUrl
      createdAt
      updatedAt
    }
  }
`;
export const updateGoogleDefaultTagUrl = /* GraphQL */ `
  mutation UpdateGoogleDefaultTagUrl(
    $input: UpdateGoogleDefaultTagUrlInput!
    $condition: ModelGoogleDefaultTagUrlConditionInput
  ) {
    updateGoogleDefaultTagUrl(input: $input, condition: $condition) {
      id
      googleAdTagUrl
      createdAt
      updatedAt
    }
  }
`;
export const deleteGoogleDefaultTagUrl = /* GraphQL */ `
  mutation DeleteGoogleDefaultTagUrl(
    $input: DeleteGoogleDefaultTagUrlInput!
    $condition: ModelGoogleDefaultTagUrlConditionInput
  ) {
    deleteGoogleDefaultTagUrl(input: $input, condition: $condition) {
      id
      googleAdTagUrl
      createdAt
      updatedAt
    }
  }
`;
export const createOnEventEndedTrigger = /* GraphQL */ `
  mutation CreateOnEventEndedTrigger(
    $input: CreateOnEventEndedTriggerInput!
    $condition: ModelOnEventEndedTriggerConditionInput
  ) {
    createOnEventEndedTrigger(input: $input, condition: $condition) {
      id
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const updateOnEventEndedTrigger = /* GraphQL */ `
  mutation UpdateOnEventEndedTrigger(
    $input: UpdateOnEventEndedTriggerInput!
    $condition: ModelOnEventEndedTriggerConditionInput
  ) {
    updateOnEventEndedTrigger(input: $input, condition: $condition) {
      id
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const deleteOnEventEndedTrigger = /* GraphQL */ `
  mutation DeleteOnEventEndedTrigger(
    $input: DeleteOnEventEndedTriggerInput!
    $condition: ModelOnEventEndedTriggerConditionInput
  ) {
    deleteOnEventEndedTrigger(input: $input, condition: $condition) {
      id
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const createEventUserWatchTime = /* GraphQL */ `
  mutation CreateEventUserWatchTime(
    $input: CreateEventUserWatchTimeInput!
    $condition: ModelEventUserWatchTimeConditionInput
  ) {
    createEventUserWatchTime(input: $input, condition: $condition) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateEventUserWatchTime = /* GraphQL */ `
  mutation UpdateEventUserWatchTime(
    $input: UpdateEventUserWatchTimeInput!
    $condition: ModelEventUserWatchTimeConditionInput
  ) {
    updateEventUserWatchTime(input: $input, condition: $condition) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventUserWatchTime = /* GraphQL */ `
  mutation DeleteEventUserWatchTime(
    $input: DeleteEventUserWatchTimeInput!
    $condition: ModelEventUserWatchTimeConditionInput
  ) {
    deleteEventUserWatchTime(input: $input, condition: $condition) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createEventStatistics = /* GraphQL */ `
  mutation CreateEventStatistics(
    $input: CreateEventStatisticsInput!
    $condition: ModelEventStatisticsConditionInput
  ) {
    createEventStatistics(input: $input, condition: $condition) {
      id
      eventLength
      totalViewers
      totalUniqueViewers
      totalComments
      averageWatchTime
      watchGraph
      claps
      tomatoes
      createdAt
      updatedAt
    }
  }
`;
export const updateEventStatistics = /* GraphQL */ `
  mutation UpdateEventStatistics(
    $input: UpdateEventStatisticsInput!
    $condition: ModelEventStatisticsConditionInput
  ) {
    updateEventStatistics(input: $input, condition: $condition) {
      id
      eventLength
      totalViewers
      totalUniqueViewers
      totalComments
      averageWatchTime
      watchGraph
      claps
      tomatoes
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventStatistics = /* GraphQL */ `
  mutation DeleteEventStatistics(
    $input: DeleteEventStatisticsInput!
    $condition: ModelEventStatisticsConditionInput
  ) {
    deleteEventStatistics(input: $input, condition: $condition) {
      id
      eventLength
      totalViewers
      totalUniqueViewers
      totalComments
      averageWatchTime
      watchGraph
      claps
      tomatoes
      createdAt
      updatedAt
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      hostSponsorRoomID
      userID
      title
      description
      richTextDescription
      eventCategorisationID
      thumbnail {
        bucket
        region
        key
        _url
      }
      generatedThumbnailUrl
      listing
      length
      ppv
      ppvPrice
      videoFile {
        bucket
        region
        key
        _url
      }
      playbackUrl
      createdAt
      updatedAt
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      hostSponsorRoomID
      userID
      title
      description
      richTextDescription
      eventCategorisationID
      thumbnail {
        bucket
        region
        key
        _url
      }
      generatedThumbnailUrl
      listing
      length
      ppv
      ppvPrice
      videoFile {
        bucket
        region
        key
        _url
      }
      playbackUrl
      createdAt
      updatedAt
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      hostSponsorRoomID
      userID
      title
      description
      richTextDescription
      eventCategorisationID
      thumbnail {
        bucket
        region
        key
        _url
      }
      generatedThumbnailUrl
      listing
      length
      ppv
      ppvPrice
      videoFile {
        bucket
        region
        key
        _url
      }
      playbackUrl
      createdAt
      updatedAt
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEventStickyMessage = /* GraphQL */ `
  mutation CreateEventStickyMessage(
    $input: CreateEventStickyMessageInput!
    $condition: ModelEventStickyMessageConditionInput
  ) {
    createEventStickyMessage(input: $input, condition: $condition) {
      id
      eventID
      moderatorID
      text
      originalCommentDisplayName
      originalCommentTime
      createdAt
      updatedAt
    }
  }
`;
export const updateEventStickyMessage = /* GraphQL */ `
  mutation UpdateEventStickyMessage(
    $input: UpdateEventStickyMessageInput!
    $condition: ModelEventStickyMessageConditionInput
  ) {
    updateEventStickyMessage(input: $input, condition: $condition) {
      id
      eventID
      moderatorID
      text
      originalCommentDisplayName
      originalCommentTime
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventStickyMessage = /* GraphQL */ `
  mutation DeleteEventStickyMessage(
    $input: DeleteEventStickyMessageInput!
    $condition: ModelEventStickyMessageConditionInput
  ) {
    deleteEventStickyMessage(input: $input, condition: $condition) {
      id
      eventID
      moderatorID
      text
      originalCommentDisplayName
      originalCommentTime
      createdAt
      updatedAt
    }
  }
`;
export const createEventScore = /* GraphQL */ `
  mutation CreateEventScore(
    $input: CreateEventScoreInput!
    $condition: ModelEventScoreConditionInput
  ) {
    createEventScore(input: $input, condition: $condition) {
      id
      contestorAScore
      contestorBScore
      createdAt
      updatedAt
    }
  }
`;
export const updateEventScore = /* GraphQL */ `
  mutation UpdateEventScore(
    $input: UpdateEventScoreInput!
    $condition: ModelEventScoreConditionInput
  ) {
    updateEventScore(input: $input, condition: $condition) {
      id
      contestorAScore
      contestorBScore
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventScore = /* GraphQL */ `
  mutation DeleteEventScore(
    $input: DeleteEventScoreInput!
    $condition: ModelEventScoreConditionInput
  ) {
    deleteEventScore(input: $input, condition: $condition) {
      id
      contestorAScore
      contestorBScore
      createdAt
      updatedAt
    }
  }
`;
export const createSingleModeEventEnd = /* GraphQL */ `
  mutation CreateSingleModeEventEnd(
    $input: CreateSingleModeEventEndInput!
    $condition: ModelSingleModeEventEndConditionInput
  ) {
    createSingleModeEventEnd(input: $input, condition: $condition) {
      id
      eventID
      status
      fileKey
      jobID
      numberOfJobs
      brokerID
      channelArn
      createdAt
      updatedAt2
      updatedAt
    }
  }
`;
export const updateSingleModeEventEnd = /* GraphQL */ `
  mutation UpdateSingleModeEventEnd(
    $input: UpdateSingleModeEventEndInput!
    $condition: ModelSingleModeEventEndConditionInput
  ) {
    updateSingleModeEventEnd(input: $input, condition: $condition) {
      id
      eventID
      status
      fileKey
      jobID
      numberOfJobs
      brokerID
      channelArn
      createdAt
      updatedAt2
      updatedAt
    }
  }
`;
export const deleteSingleModeEventEnd = /* GraphQL */ `
  mutation DeleteSingleModeEventEnd(
    $input: DeleteSingleModeEventEndInput!
    $condition: ModelSingleModeEventEndConditionInput
  ) {
    deleteSingleModeEventEnd(input: $input, condition: $condition) {
      id
      eventID
      status
      fileKey
      jobID
      numberOfJobs
      brokerID
      channelArn
      createdAt
      updatedAt2
      updatedAt
    }
  }
`;
export const createUserPayPerViewList = /* GraphQL */ `
  mutation CreateUserPayPerViewList(
    $input: CreateUserPayPerViewListInput!
    $condition: ModelUserPayPerViewListConditionInput
  ) {
    createUserPayPerViewList(input: $input, condition: $condition) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateUserPayPerViewList = /* GraphQL */ `
  mutation UpdateUserPayPerViewList(
    $input: UpdateUserPayPerViewListInput!
    $condition: ModelUserPayPerViewListConditionInput
  ) {
    updateUserPayPerViewList(input: $input, condition: $condition) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserPayPerViewList = /* GraphQL */ `
  mutation DeleteUserPayPerViewList(
    $input: DeleteUserPayPerViewListInput!
    $condition: ModelUserPayPerViewListConditionInput
  ) {
    deleteUserPayPerViewList(input: $input, condition: $condition) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createUserSponsorRoomPayPerViewList = /* GraphQL */ `
  mutation CreateUserSponsorRoomPayPerViewList(
    $input: CreateUserSponsorRoomPayPerViewListInput!
    $condition: ModelUserSponsorRoomPayPerViewListConditionInput
  ) {
    createUserSponsorRoomPayPerViewList(input: $input, condition: $condition) {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSponsorRoomPayPerViewList = /* GraphQL */ `
  mutation UpdateUserSponsorRoomPayPerViewList(
    $input: UpdateUserSponsorRoomPayPerViewListInput!
    $condition: ModelUserSponsorRoomPayPerViewListConditionInput
  ) {
    updateUserSponsorRoomPayPerViewList(input: $input, condition: $condition) {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSponsorRoomPayPerViewList = /* GraphQL */ `
  mutation DeleteUserSponsorRoomPayPerViewList(
    $input: DeleteUserSponsorRoomPayPerViewListInput!
    $condition: ModelUserSponsorRoomPayPerViewListConditionInput
  ) {
    deleteUserSponsorRoomPayPerViewList(input: $input, condition: $condition) {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const createTeaserVideo = /* GraphQL */ `
  mutation CreateTeaserVideo(
    $input: CreateTeaserVideoInput!
    $condition: ModelTeaserVideoConditionInput
  ) {
    createTeaserVideo(input: $input, condition: $condition) {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTeaserVideo = /* GraphQL */ `
  mutation UpdateTeaserVideo(
    $input: UpdateTeaserVideoInput!
    $condition: ModelTeaserVideoConditionInput
  ) {
    updateTeaserVideo(input: $input, condition: $condition) {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTeaserVideo = /* GraphQL */ `
  mutation DeleteTeaserVideo(
    $input: DeleteTeaserVideoInput!
    $condition: ModelTeaserVideoConditionInput
  ) {
    deleteTeaserVideo(input: $input, condition: $condition) {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestionAnswer = /* GraphQL */ `
  mutation CreateQuestionAnswer(
    $input: CreateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    createQuestionAnswer(input: $input, condition: $condition) {
      id
      eventID
      pollID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestionAnswer = /* GraphQL */ `
  mutation UpdateQuestionAnswer(
    $input: UpdateQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    updateQuestionAnswer(input: $input, condition: $condition) {
      id
      eventID
      pollID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestionAnswer = /* GraphQL */ `
  mutation DeleteQuestionAnswer(
    $input: DeleteQuestionAnswerInput!
    $condition: ModelQuestionAnswerConditionInput
  ) {
    deleteQuestionAnswer(input: $input, condition: $condition) {
      id
      eventID
      pollID
      userID
      answer
      createdAt
      updatedAt
    }
  }
`;
export const createEventPoll = /* GraphQL */ `
  mutation CreateEventPoll(
    $input: CreateEventPollInput!
    $condition: ModelEventPollConditionInput
  ) {
    createEventPoll(input: $input, condition: $condition) {
      id
      eventID
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateEventPoll = /* GraphQL */ `
  mutation UpdateEventPoll(
    $input: UpdateEventPollInput!
    $condition: ModelEventPollConditionInput
  ) {
    updateEventPoll(input: $input, condition: $condition) {
      id
      eventID
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventPoll = /* GraphQL */ `
  mutation DeleteEventPoll(
    $input: DeleteEventPollInput!
    $condition: ModelEventPollConditionInput
  ) {
    deleteEventPoll(input: $input, condition: $condition) {
      id
      eventID
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      status
      createdAt
      updatedAt
    }
  }
`;
export const createEventLastUpdate = /* GraphQL */ `
  mutation CreateEventLastUpdate(
    $input: CreateEventLastUpdateInput!
    $condition: ModelEventLastUpdateConditionInput
  ) {
    createEventLastUpdate(input: $input, condition: $condition) {
      id
      type
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const updateEventLastUpdate = /* GraphQL */ `
  mutation UpdateEventLastUpdate(
    $input: UpdateEventLastUpdateInput!
    $condition: ModelEventLastUpdateConditionInput
  ) {
    updateEventLastUpdate(input: $input, condition: $condition) {
      id
      type
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBoosterActivation = /* GraphQL */ `
  mutation CreateBoosterActivation(
    $input: CreateBoosterActivationInput!
    $condition: ModelBoosterActivationConditionInput
  ) {
    createBoosterActivation(input: $input, condition: $condition) {
      id
      userID
      boosterID
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createGameAction = /* GraphQL */ `
  mutation CreateGameAction(
    $input: CreateGameActionInput!
    $condition: ModelGameActionConditionInput
  ) {
    createGameAction(input: $input, condition: $condition) {
      id
      name
      description
      defaultPower
      defaltCooldown
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateGameAction = /* GraphQL */ `
  mutation UpdateGameAction(
    $input: UpdateGameActionInput!
    $condition: ModelGameActionConditionInput
  ) {
    updateGameAction(input: $input, condition: $condition) {
      id
      name
      description
      defaultPower
      defaltCooldown
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteGameAction = /* GraphQL */ `
  mutation DeleteGameAction(
    $input: DeleteGameActionInput!
    $condition: ModelGameActionConditionInput
  ) {
    deleteGameAction(input: $input, condition: $condition) {
      id
      name
      description
      defaultPower
      defaltCooldown
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction(
    $input: CreateActionInput!
    $condition: ModelActionConditionInput
  ) {
    createAction(input: $input, condition: $condition) {
      id
      userID
      gameActionID
      eventID
      contestor
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateAction = /* GraphQL */ `
  mutation UpdateAction(
    $input: UpdateActionInput!
    $condition: ModelActionConditionInput
  ) {
    updateAction(input: $input, condition: $condition) {
      id
      userID
      gameActionID
      eventID
      contestor
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteAction = /* GraphQL */ `
  mutation DeleteAction(
    $input: DeleteActionInput!
    $condition: ModelActionConditionInput
  ) {
    deleteAction(input: $input, condition: $condition) {
      id
      userID
      gameActionID
      eventID
      contestor
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUserActionHistory = /* GraphQL */ `
  mutation CreateUserActionHistory(
    $input: CreateUserActionHistoryInput!
    $condition: ModelUserActionHistoryConditionInput
  ) {
    createUserActionHistory(input: $input, condition: $condition) {
      id
      userID
      stats
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateUserActionHistory = /* GraphQL */ `
  mutation UpdateUserActionHistory(
    $input: UpdateUserActionHistoryInput!
    $condition: ModelUserActionHistoryConditionInput
  ) {
    updateUserActionHistory(input: $input, condition: $condition) {
      id
      userID
      stats
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteUserActionHistory = /* GraphQL */ `
  mutation DeleteUserActionHistory(
    $input: DeleteUserActionHistoryInput!
    $condition: ModelUserActionHistoryConditionInput
  ) {
    deleteUserActionHistory(input: $input, condition: $condition) {
      id
      userID
      stats
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createEventCategorisation = /* GraphQL */ `
  mutation CreateEventCategorisation(
    $input: CreateEventCategorisationInput!
    $condition: ModelEventCategorisationConditionInput
  ) {
    createEventCategorisation(input: $input, condition: $condition) {
      id
      title
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateEventCategorisation = /* GraphQL */ `
  mutation UpdateEventCategorisation(
    $input: UpdateEventCategorisationInput!
    $condition: ModelEventCategorisationConditionInput
  ) {
    updateEventCategorisation(input: $input, condition: $condition) {
      id
      title
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventCategorisation = /* GraphQL */ `
  mutation DeleteEventCategorisation(
    $input: DeleteEventCategorisationInput!
    $condition: ModelEventCategorisationConditionInput
  ) {
    deleteEventCategorisation(input: $input, condition: $condition) {
      id
      title
      order
      createdAt
      updatedAt
    }
  }
`;
export const createRoomCategorisation = /* GraphQL */ `
  mutation CreateRoomCategorisation(
    $input: CreateRoomCategorisationInput!
    $condition: ModelRoomCategorisationConditionInput
  ) {
    createRoomCategorisation(input: $input, condition: $condition) {
      id
      title
      order
      logo {
        bucket
        region
        key
        _url
      }
      cover {
        bucket
        region
        key
        _url
      }
      label
      promotionTitle
      promoted
      createdAt
      updatedAt
    }
  }
`;
export const updateRoomCategorisation = /* GraphQL */ `
  mutation UpdateRoomCategorisation(
    $input: UpdateRoomCategorisationInput!
    $condition: ModelRoomCategorisationConditionInput
  ) {
    updateRoomCategorisation(input: $input, condition: $condition) {
      id
      title
      order
      logo {
        bucket
        region
        key
        _url
      }
      cover {
        bucket
        region
        key
        _url
      }
      label
      promotionTitle
      promoted
      createdAt
      updatedAt
    }
  }
`;
export const deleteRoomCategorisation = /* GraphQL */ `
  mutation DeleteRoomCategorisation(
    $input: DeleteRoomCategorisationInput!
    $condition: ModelRoomCategorisationConditionInput
  ) {
    deleteRoomCategorisation(input: $input, condition: $condition) {
      id
      title
      order
      logo {
        bucket
        region
        key
        _url
      }
      cover {
        bucket
        region
        key
        _url
      }
      label
      promotionTitle
      promoted
      createdAt
      updatedAt
    }
  }
`;
export const createSponsorRoomPayPerView = /* GraphQL */ `
  mutation CreateSponsorRoomPayPerView(
    $input: CreateSponsorRoomPayPerViewInput!
    $condition: ModelSponsorRoomPayPerViewConditionInput
  ) {
    createSponsorRoomPayPerView(input: $input, condition: $condition) {
      id
      sponsorRoomId
      title
      description
      image {
        bucket
        region
        key
        _url
      }
      price
      monthPrice
      startDate
      endDate
      createdAt
      updatedAt
      userID
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSponsorRoomPayPerView = /* GraphQL */ `
  mutation UpdateSponsorRoomPayPerView(
    $input: UpdateSponsorRoomPayPerViewInput!
    $condition: ModelSponsorRoomPayPerViewConditionInput
  ) {
    updateSponsorRoomPayPerView(input: $input, condition: $condition) {
      id
      sponsorRoomId
      title
      description
      image {
        bucket
        region
        key
        _url
      }
      price
      monthPrice
      startDate
      endDate
      createdAt
      updatedAt
      userID
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSponsorRoomPayPerView = /* GraphQL */ `
  mutation DeleteSponsorRoomPayPerView(
    $input: DeleteSponsorRoomPayPerViewInput!
    $condition: ModelSponsorRoomPayPerViewConditionInput
  ) {
    deleteSponsorRoomPayPerView(input: $input, condition: $condition) {
      id
      sponsorRoomId
      title
      description
      image {
        bucket
        region
        key
        _url
      }
      price
      monthPrice
      startDate
      endDate
      createdAt
      updatedAt
      userID
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createSponsorRoom = /* GraphQL */ `
  mutation CreateSponsorRoom(
    $input: CreateSponsorRoomInput!
    $condition: ModelSponsorRoomConditionInput
  ) {
    createSponsorRoom(input: $input, condition: $condition) {
      id
      userID
      title
      description
      merchUrl
      image {
        bucket
        region
        key
        _url
      }
      coverPhoto {
        bucket
        region
        key
        _url
      }
      collaborationLogo {
        bucket
        region
        key
        _url
      }
      order
      isLive
      roomCategorisationID
      ivsChannel {
        channelArn
        ingestEndpoint
        playbackUrl
        name
        streamKeyArn
        streamKey
        recordingConfigurationArn
      }
      ioioChannel {
        ioioChannelID
        playbackUrl
      }
      followers
      featured
      seoUrl
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      roomCategorisation {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSponsorRoom = /* GraphQL */ `
  mutation UpdateSponsorRoom(
    $input: UpdateSponsorRoomInput!
    $condition: ModelSponsorRoomConditionInput
  ) {
    updateSponsorRoom(input: $input, condition: $condition) {
      id
      userID
      title
      description
      merchUrl
      image {
        bucket
        region
        key
        _url
      }
      coverPhoto {
        bucket
        region
        key
        _url
      }
      collaborationLogo {
        bucket
        region
        key
        _url
      }
      order
      isLive
      roomCategorisationID
      ivsChannel {
        channelArn
        ingestEndpoint
        playbackUrl
        name
        streamKeyArn
        streamKey
        recordingConfigurationArn
      }
      ioioChannel {
        ioioChannelID
        playbackUrl
      }
      followers
      featured
      seoUrl
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      roomCategorisation {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSponsorRoom = /* GraphQL */ `
  mutation DeleteSponsorRoom(
    $input: DeleteSponsorRoomInput!
    $condition: ModelSponsorRoomConditionInput
  ) {
    deleteSponsorRoom(input: $input, condition: $condition) {
      id
      userID
      title
      description
      merchUrl
      image {
        bucket
        region
        key
        _url
      }
      coverPhoto {
        bucket
        region
        key
        _url
      }
      collaborationLogo {
        bucket
        region
        key
        _url
      }
      order
      isLive
      roomCategorisationID
      ivsChannel {
        channelArn
        ingestEndpoint
        playbackUrl
        name
        streamKeyArn
        streamKey
        recordingConfigurationArn
      }
      ioioChannel {
        ioioChannelID
        playbackUrl
      }
      followers
      featured
      seoUrl
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      roomCategorisation {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSticker = /* GraphQL */ `
  mutation CreateSticker(
    $input: CreateStickerInput!
    $condition: ModelStickerConditionInput
  ) {
    createSticker(input: $input, condition: $condition) {
      id
      title
      image {
        bucket
        region
        key
        _url
      }
      stickerBundleID
      order
      createdAt
      updatedAt
      stickerBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
    }
  }
`;
export const updateSticker = /* GraphQL */ `
  mutation UpdateSticker(
    $input: UpdateStickerInput!
    $condition: ModelStickerConditionInput
  ) {
    updateSticker(input: $input, condition: $condition) {
      id
      title
      image {
        bucket
        region
        key
        _url
      }
      stickerBundleID
      order
      createdAt
      updatedAt
      stickerBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
    }
  }
`;
export const deleteSticker = /* GraphQL */ `
  mutation DeleteSticker(
    $input: DeleteStickerInput!
    $condition: ModelStickerConditionInput
  ) {
    deleteSticker(input: $input, condition: $condition) {
      id
      title
      image {
        bucket
        region
        key
        _url
      }
      stickerBundleID
      order
      createdAt
      updatedAt
      stickerBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
    }
  }
`;
export const createStickersBundle = /* GraphQL */ `
  mutation CreateStickersBundle(
    $input: CreateStickersBundleInput!
    $condition: ModelStickersBundleConditionInput
  ) {
    createStickersBundle(input: $input, condition: $condition) {
      id
      title
      description
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
      stickers {
        items {
          id
          title
          stickerBundleID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateStickersBundle = /* GraphQL */ `
  mutation UpdateStickersBundle(
    $input: UpdateStickersBundleInput!
    $condition: ModelStickersBundleConditionInput
  ) {
    updateStickersBundle(input: $input, condition: $condition) {
      id
      title
      description
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
      stickers {
        items {
          id
          title
          stickerBundleID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteStickersBundle = /* GraphQL */ `
  mutation DeleteStickersBundle(
    $input: DeleteStickersBundleInput!
    $condition: ModelStickersBundleConditionInput
  ) {
    deleteStickersBundle(input: $input, condition: $condition) {
      id
      title
      description
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
      stickers {
        items {
          id
          title
          stickerBundleID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createBooster = /* GraphQL */ `
  mutation CreateBooster(
    $input: CreateBoosterInput!
    $condition: ModelBoosterConditionInput
  ) {
    createBooster(input: $input, condition: $condition) {
      id
      type
      title
      description
      factor
      awardUserLevel
      duration
      icon {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBooster = /* GraphQL */ `
  mutation UpdateBooster(
    $input: UpdateBoosterInput!
    $condition: ModelBoosterConditionInput
  ) {
    updateBooster(input: $input, condition: $condition) {
      id
      type
      title
      description
      factor
      awardUserLevel
      duration
      icon {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBooster = /* GraphQL */ `
  mutation DeleteBooster(
    $input: DeleteBoosterInput!
    $condition: ModelBoosterConditionInput
  ) {
    deleteBooster(input: $input, condition: $condition) {
      id
      type
      title
      description
      factor
      awardUserLevel
      duration
      icon {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      shopItemID
      eventID
      sponsorRoomPayPerViewID
      isMonthPayPerView
      isDonation
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      shopItem {
        id
        image {
          bucket
          region
          key
          _url
        }
        title
        description
        price
        boosterID
        boosterAmount
        gameActionID
        gameActionAmount
        avatarID
        stickersBundleID
        order
        createdAt
        updatedAt
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
        stickersBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
        avatar {
          id
          free
          gender
          order
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      shopItemID
      eventID
      sponsorRoomPayPerViewID
      isMonthPayPerView
      isDonation
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      shopItem {
        id
        image {
          bucket
          region
          key
          _url
        }
        title
        description
        price
        boosterID
        boosterAmount
        gameActionID
        gameActionAmount
        avatarID
        stickersBundleID
        order
        createdAt
        updatedAt
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
        stickersBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
        avatar {
          id
          free
          gender
          order
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      shopItemID
      eventID
      sponsorRoomPayPerViewID
      isMonthPayPerView
      isDonation
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      shopItem {
        id
        image {
          bucket
          region
          key
          _url
        }
        title
        description
        price
        boosterID
        boosterAmount
        gameActionID
        gameActionAmount
        avatarID
        stickersBundleID
        order
        createdAt
        updatedAt
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
        stickersBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
        avatar {
          id
          free
          gender
          order
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      time
      provider
      coinsPackageID
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      coinsPackage {
        id
        title
        description
        numberOfCoins
        priceInUSD
        iOsProductID
        androidProductID
        image {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      time
      provider
      coinsPackageID
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      coinsPackage {
        id
        title
        description
        numberOfCoins
        priceInUSD
        iOsProductID
        androidProductID
        image {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      time
      provider
      coinsPackageID
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      coinsPackage {
        id
        title
        description
        numberOfCoins
        priceInUSD
        iOsProductID
        androidProductID
        image {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createFollowing = /* GraphQL */ `
  mutation CreateFollowing(
    $input: CreateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    createFollowing(input: $input, condition: $condition) {
      id
      userID
      sponsorRoomID
      followerDisplayName
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateFollowing = /* GraphQL */ `
  mutation UpdateFollowing(
    $input: UpdateFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    updateFollowing(input: $input, condition: $condition) {
      id
      userID
      sponsorRoomID
      followerDisplayName
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteFollowing = /* GraphQL */ `
  mutation DeleteFollowing(
    $input: DeleteFollowingInput!
    $condition: ModelFollowingConditionInput
  ) {
    deleteFollowing(input: $input, condition: $condition) {
      id
      userID
      sponsorRoomID
      followerDisplayName
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createShopItem = /* GraphQL */ `
  mutation CreateShopItem(
    $input: CreateShopItemInput!
    $condition: ModelShopItemConditionInput
  ) {
    createShopItem(input: $input, condition: $condition) {
      id
      image {
        bucket
        region
        key
        _url
      }
      title
      description
      price
      boosterID
      boosterAmount
      gameActionID
      gameActionAmount
      avatarID
      stickersBundleID
      order
      createdAt
      updatedAt
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      stickersBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      avatar {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateShopItem = /* GraphQL */ `
  mutation UpdateShopItem(
    $input: UpdateShopItemInput!
    $condition: ModelShopItemConditionInput
  ) {
    updateShopItem(input: $input, condition: $condition) {
      id
      image {
        bucket
        region
        key
        _url
      }
      title
      description
      price
      boosterID
      boosterAmount
      gameActionID
      gameActionAmount
      avatarID
      stickersBundleID
      order
      createdAt
      updatedAt
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      stickersBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      avatar {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteShopItem = /* GraphQL */ `
  mutation DeleteShopItem(
    $input: DeleteShopItemInput!
    $condition: ModelShopItemConditionInput
  ) {
    deleteShopItem(input: $input, condition: $condition) {
      id
      image {
        bucket
        region
        key
        _url
      }
      title
      description
      price
      boosterID
      boosterAmount
      gameActionID
      gameActionAmount
      avatarID
      stickersBundleID
      order
      createdAt
      updatedAt
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      stickersBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      avatar {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAchievement = /* GraphQL */ `
  mutation CreateAchievement(
    $input: CreateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    createAchievement(input: $input, condition: $condition) {
      id
      badge {
        bucket
        region
        key
        _url
      }
      title
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateAchievement = /* GraphQL */ `
  mutation UpdateAchievement(
    $input: UpdateAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    updateAchievement(input: $input, condition: $condition) {
      id
      badge {
        bucket
        region
        key
        _url
      }
      title
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteAchievement = /* GraphQL */ `
  mutation DeleteAchievement(
    $input: DeleteAchievementInput!
    $condition: ModelAchievementConditionInput
  ) {
    deleteAchievement(input: $input, condition: $condition) {
      id
      badge {
        bucket
        region
        key
        _url
      }
      title
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const createChatMessage = /* GraphQL */ `
  mutation CreateChatMessage(
    $input: CreateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    createChatMessage(input: $input, condition: $condition) {
      id
      userID
      eventID
      content
      censoredContent
      stickerID
      displayName
      avatar
      level
      time
      createdAt
      updatedAt
    }
  }
`;
export const updateChatMessage = /* GraphQL */ `
  mutation UpdateChatMessage(
    $input: UpdateChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    updateChatMessage(input: $input, condition: $condition) {
      id
      userID
      eventID
      content
      censoredContent
      stickerID
      displayName
      avatar
      level
      time
      createdAt
      updatedAt
    }
  }
`;
export const deleteChatMessage = /* GraphQL */ `
  mutation DeleteChatMessage(
    $input: DeleteChatMessageInput!
    $condition: ModelChatMessageConditionInput
  ) {
    deleteChatMessage(input: $input, condition: $condition) {
      id
      userID
      eventID
      content
      censoredContent
      stickerID
      displayName
      avatar
      level
      time
      createdAt
      updatedAt
    }
  }
`;
export const createEventLeaderBoard = /* GraphQL */ `
  mutation CreateEventLeaderBoard(
    $input: CreateEventLeaderBoardInput!
    $condition: ModelEventLeaderBoardConditionInput
  ) {
    createEventLeaderBoard(input: $input, condition: $condition) {
      id
      board
      createdAt
      updatedAt
    }
  }
`;
export const updateEventLeaderBoard = /* GraphQL */ `
  mutation UpdateEventLeaderBoard(
    $input: UpdateEventLeaderBoardInput!
    $condition: ModelEventLeaderBoardConditionInput
  ) {
    updateEventLeaderBoard(input: $input, condition: $condition) {
      id
      board
      createdAt
      updatedAt
    }
  }
`;
export const deleteEventLeaderBoard = /* GraphQL */ `
  mutation DeleteEventLeaderBoard(
    $input: DeleteEventLeaderBoardInput!
    $condition: ModelEventLeaderBoardConditionInput
  ) {
    deleteEventLeaderBoard(input: $input, condition: $condition) {
      id
      board
      createdAt
      updatedAt
    }
  }
`;
export const createCoinsPackage = /* GraphQL */ `
  mutation CreateCoinsPackage(
    $input: CreateCoinsPackageInput!
    $condition: ModelCoinsPackageConditionInput
  ) {
    createCoinsPackage(input: $input, condition: $condition) {
      id
      title
      description
      numberOfCoins
      priceInUSD
      iOsProductID
      androidProductID
      image {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCoinsPackage = /* GraphQL */ `
  mutation UpdateCoinsPackage(
    $input: UpdateCoinsPackageInput!
    $condition: ModelCoinsPackageConditionInput
  ) {
    updateCoinsPackage(input: $input, condition: $condition) {
      id
      title
      description
      numberOfCoins
      priceInUSD
      iOsProductID
      androidProductID
      image {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCoinsPackage = /* GraphQL */ `
  mutation DeleteCoinsPackage(
    $input: DeleteCoinsPackageInput!
    $condition: ModelCoinsPackageConditionInput
  ) {
    deleteCoinsPackage(input: $input, condition: $condition) {
      id
      title
      description
      numberOfCoins
      priceInUSD
      iOsProductID
      androidProductID
      image {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAvatar = /* GraphQL */ `
  mutation CreateAvatar(
    $input: CreateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    createAvatar(input: $input, condition: $condition) {
      id
      image {
        bucket
        region
        key
        _url
      }
      free
      gender
      order
      createdAt
      updatedAt
    }
  }
`;
export const updateAvatar = /* GraphQL */ `
  mutation UpdateAvatar(
    $input: UpdateAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    updateAvatar(input: $input, condition: $condition) {
      id
      image {
        bucket
        region
        key
        _url
      }
      free
      gender
      order
      createdAt
      updatedAt
    }
  }
`;
export const deleteAvatar = /* GraphQL */ `
  mutation DeleteAvatar(
    $input: DeleteAvatarInput!
    $condition: ModelAvatarConditionInput
  ) {
    deleteAvatar(input: $input, condition: $condition) {
      id
      image {
        bucket
        region
        key
        _url
      }
      free
      gender
      order
      createdAt
      updatedAt
    }
  }
`;
export const createChatReport = /* GraphQL */ `
  mutation CreateChatReport(
    $input: CreateChatReportInput!
    $condition: ModelChatReportConditionInput
  ) {
    createChatReport(input: $input, condition: $condition) {
      id
      reportingUserID
      reportedUserID
      message
      description
      createdAt
      updatedAt
      reportingUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      reportedUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateChatReport = /* GraphQL */ `
  mutation UpdateChatReport(
    $input: UpdateChatReportInput!
    $condition: ModelChatReportConditionInput
  ) {
    updateChatReport(input: $input, condition: $condition) {
      id
      reportingUserID
      reportedUserID
      message
      description
      createdAt
      updatedAt
      reportingUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      reportedUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteChatReport = /* GraphQL */ `
  mutation DeleteChatReport(
    $input: DeleteChatReportInput!
    $condition: ModelChatReportConditionInput
  ) {
    deleteChatReport(input: $input, condition: $condition) {
      id
      reportingUserID
      reportedUserID
      message
      description
      createdAt
      updatedAt
      reportingUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      reportedUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createLegalInformation = /* GraphQL */ `
  mutation CreateLegalInformation(
    $input: CreateLegalInformationInput!
    $condition: ModelLegalInformationConditionInput
  ) {
    createLegalInformation(input: $input, condition: $condition) {
      id
      termsOfService
      privacyPolicy
      createdAt
      updatedAt
    }
  }
`;
export const updateLegalInformation = /* GraphQL */ `
  mutation UpdateLegalInformation(
    $input: UpdateLegalInformationInput!
    $condition: ModelLegalInformationConditionInput
  ) {
    updateLegalInformation(input: $input, condition: $condition) {
      id
      termsOfService
      privacyPolicy
      createdAt
      updatedAt
    }
  }
`;
export const deleteLegalInformation = /* GraphQL */ `
  mutation DeleteLegalInformation(
    $input: DeleteLegalInformationInput!
    $condition: ModelLegalInformationConditionInput
  ) {
    deleteLegalInformation(input: $input, condition: $condition) {
      id
      termsOfService
      privacyPolicy
      createdAt
      updatedAt
    }
  }
`;
export const createCustomNotification = /* GraphQL */ `
  mutation CreateCustomNotification(
    $input: CreateCustomNotificationInput!
    $condition: ModelCustomNotificationConditionInput
  ) {
    createCustomNotification(input: $input, condition: $condition) {
      id
      message
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomNotification = /* GraphQL */ `
  mutation UpdateCustomNotification(
    $input: UpdateCustomNotificationInput!
    $condition: ModelCustomNotificationConditionInput
  ) {
    updateCustomNotification(input: $input, condition: $condition) {
      id
      message
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomNotification = /* GraphQL */ `
  mutation DeleteCustomNotification(
    $input: DeleteCustomNotificationInput!
    $condition: ModelCustomNotificationConditionInput
  ) {
    deleteCustomNotification(input: $input, condition: $condition) {
      id
      message
      createdAt
      updatedAt
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      creatorID
      userID
      status
      eventID
      mixerEventUUID
      mixerAtendeeID
      mixerAccessCode
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      creatorID
      userID
      status
      eventID
      mixerEventUUID
      mixerAtendeeID
      mixerAccessCode
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      creatorID
      userID
      status
      eventID
      mixerEventUUID
      mixerAtendeeID
      mixerAccessCode
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const createEventOnlineUser = /* GraphQL */ `
  mutation CreateEventOnlineUser(
    $input: CreateEventOnlineUserInput!
    $condition: ModelEventOnlineUserConditionInput
  ) {
    createEventOnlineUser(input: $input, condition: $condition) {
      id
      userID
      displayName
      displayNameLowerCase
      eventID
      createdAt
      updatedAt
      raisedHand
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateEventOnlineUser = /* GraphQL */ `
  mutation UpdateEventOnlineUser(
    $input: UpdateEventOnlineUserInput!
    $condition: ModelEventOnlineUserConditionInput
  ) {
    updateEventOnlineUser(input: $input, condition: $condition) {
      id
      userID
      displayName
      displayNameLowerCase
      eventID
      createdAt
      updatedAt
      raisedHand
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteEventOnlineUser = /* GraphQL */ `
  mutation DeleteEventOnlineUser(
    $input: DeleteEventOnlineUserInput!
    $condition: ModelEventOnlineUserConditionInput
  ) {
    deleteEventOnlineUser(input: $input, condition: $condition) {
      id
      userID
      displayName
      displayNameLowerCase
      eventID
      createdAt
      updatedAt
      raisedHand
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createAppVersion = /* GraphQL */ `
  mutation CreateAppVersion(
    $input: CreateAppVersionInput!
    $condition: ModelAppVersionConditionInput
  ) {
    createAppVersion(input: $input, condition: $condition) {
      id
      minVersion
      currentVersion
      createdAt
      updatedAt
    }
  }
`;
export const updateAppVersion = /* GraphQL */ `
  mutation UpdateAppVersion(
    $input: UpdateAppVersionInput!
    $condition: ModelAppVersionConditionInput
  ) {
    updateAppVersion(input: $input, condition: $condition) {
      id
      minVersion
      currentVersion
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppVersion = /* GraphQL */ `
  mutation DeleteAppVersion(
    $input: DeleteAppVersionInput!
    $condition: ModelAppVersionConditionInput
  ) {
    deleteAppVersion(input: $input, condition: $condition) {
      id
      minVersion
      currentVersion
      createdAt
      updatedAt
    }
  }
`;
export const createCreatorPayoutInfo = /* GraphQL */ `
  mutation CreateCreatorPayoutInfo(
    $input: CreateCreatorPayoutInfoInput!
    $condition: ModelCreatorPayoutInfoConditionInput
  ) {
    createCreatorPayoutInfo(input: $input, condition: $condition) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCreatorPayoutInfo = /* GraphQL */ `
  mutation UpdateCreatorPayoutInfo(
    $input: UpdateCreatorPayoutInfoInput!
    $condition: ModelCreatorPayoutInfoConditionInput
  ) {
    updateCreatorPayoutInfo(input: $input, condition: $condition) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCreatorPayoutInfo = /* GraphQL */ `
  mutation DeleteCreatorPayoutInfo(
    $input: DeleteCreatorPayoutInfoInput!
    $condition: ModelCreatorPayoutInfoConditionInput
  ) {
    deleteCreatorPayoutInfo(input: $input, condition: $condition) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSponsorRoomPayPerViewProfit = /* GraphQL */ `
  mutation CreateSponsorRoomPayPerViewProfit(
    $input: CreateSponsorRoomPayPerViewProfitInput!
    $condition: ModelSponsorRoomPayPerViewProfitConditionInput
  ) {
    createSponsorRoomPayPerViewProfit(input: $input, condition: $condition) {
      id
      userID
      totalWholePurchases
      totalMonthPurchases
      payPerViewID
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateSponsorRoomPayPerViewProfit = /* GraphQL */ `
  mutation UpdateSponsorRoomPayPerViewProfit(
    $input: UpdateSponsorRoomPayPerViewProfitInput!
    $condition: ModelSponsorRoomPayPerViewProfitConditionInput
  ) {
    updateSponsorRoomPayPerViewProfit(input: $input, condition: $condition) {
      id
      userID
      totalWholePurchases
      totalMonthPurchases
      payPerViewID
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteSponsorRoomPayPerViewProfit = /* GraphQL */ `
  mutation DeleteSponsorRoomPayPerViewProfit(
    $input: DeleteSponsorRoomPayPerViewProfitInput!
    $condition: ModelSponsorRoomPayPerViewProfitConditionInput
  ) {
    deleteSponsorRoomPayPerViewProfit(input: $input, condition: $condition) {
      id
      userID
      totalWholePurchases
      totalMonthPurchases
      payPerViewID
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createEventProfit = /* GraphQL */ `
  mutation CreateEventProfit(
    $input: CreateEventProfitInput!
    $condition: ModelEventProfitConditionInput
  ) {
    createEventProfit(input: $input, condition: $condition) {
      id
      userID
      totalPurchases
      eventID
      totalCoinsFromDonations
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateEventProfit = /* GraphQL */ `
  mutation UpdateEventProfit(
    $input: UpdateEventProfitInput!
    $condition: ModelEventProfitConditionInput
  ) {
    updateEventProfit(input: $input, condition: $condition) {
      id
      userID
      totalPurchases
      eventID
      totalCoinsFromDonations
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteEventProfit = /* GraphQL */ `
  mutation DeleteEventProfit(
    $input: DeleteEventProfitInput!
    $condition: ModelEventProfitConditionInput
  ) {
    deleteEventProfit(input: $input, condition: $condition) {
      id
      userID
      totalPurchases
      eventID
      totalCoinsFromDonations
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createWithdrawRequest = /* GraphQL */ `
  mutation CreateWithdrawRequest(
    $input: CreateWithdrawRequestInput!
    $condition: ModelWithdrawRequestConditionInput
  ) {
    createWithdrawRequest(input: $input, condition: $condition) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      amount
      coins
      status
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateWithdrawRequest = /* GraphQL */ `
  mutation UpdateWithdrawRequest(
    $input: UpdateWithdrawRequestInput!
    $condition: ModelWithdrawRequestConditionInput
  ) {
    updateWithdrawRequest(input: $input, condition: $condition) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      amount
      coins
      status
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteWithdrawRequest = /* GraphQL */ `
  mutation DeleteWithdrawRequest(
    $input: DeleteWithdrawRequestInput!
    $condition: ModelWithdrawRequestConditionInput
  ) {
    deleteWithdrawRequest(input: $input, condition: $condition) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      amount
      coins
      status
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCreatorRequest = /* GraphQL */ `
  mutation CreateCreatorRequest(
    $input: CreateCreatorRequestInput!
    $condition: ModelCreatorRequestConditionInput
  ) {
    createCreatorRequest(input: $input, condition: $condition) {
      id
      userID
      email
      name
      displayName
      socialLink1
      socialLink2
      socialLink3
      socialLink4
      socialLink5
      socialLink6
      motivation
      heardFromAdventuresMusicTour
      adventuresMusicTourCity
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCreatorRequest = /* GraphQL */ `
  mutation UpdateCreatorRequest(
    $input: UpdateCreatorRequestInput!
    $condition: ModelCreatorRequestConditionInput
  ) {
    updateCreatorRequest(input: $input, condition: $condition) {
      id
      userID
      email
      name
      displayName
      socialLink1
      socialLink2
      socialLink3
      socialLink4
      socialLink5
      socialLink6
      motivation
      heardFromAdventuresMusicTour
      adventuresMusicTourCity
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCreatorRequest = /* GraphQL */ `
  mutation DeleteCreatorRequest(
    $input: DeleteCreatorRequestInput!
    $condition: ModelCreatorRequestConditionInput
  ) {
    deleteCreatorRequest(input: $input, condition: $condition) {
      id
      userID
      email
      name
      displayName
      socialLink1
      socialLink2
      socialLink3
      socialLink4
      socialLink5
      socialLink6
      motivation
      heardFromAdventuresMusicTour
      adventuresMusicTourCity
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const createMultipartUploadLibraryQueue = /* GraphQL */ `
  mutation CreateMultipartUploadLibraryQueue(
    $input: CreateMultipartUploadLibraryQueueInput!
    $condition: ModelMultipartUploadLibraryQueueConditionInput
  ) {
    createMultipartUploadLibraryQueue(input: $input, condition: $condition) {
      id
      uploadID
      status
      bucket
      copySource
      key
      lastPartNumber
      numRequests
      uploadedParts {
        ETag
        PartNumber
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMultipartUploadLibraryQueue = /* GraphQL */ `
  mutation UpdateMultipartUploadLibraryQueue(
    $input: UpdateMultipartUploadLibraryQueueInput!
    $condition: ModelMultipartUploadLibraryQueueConditionInput
  ) {
    updateMultipartUploadLibraryQueue(input: $input, condition: $condition) {
      id
      uploadID
      status
      bucket
      copySource
      key
      lastPartNumber
      numRequests
      uploadedParts {
        ETag
        PartNumber
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMultipartUploadLibraryQueue = /* GraphQL */ `
  mutation DeleteMultipartUploadLibraryQueue(
    $input: DeleteMultipartUploadLibraryQueueInput!
    $condition: ModelMultipartUploadLibraryQueueConditionInput
  ) {
    deleteMultipartUploadLibraryQueue(input: $input, condition: $condition) {
      id
      uploadID
      status
      bucket
      copySource
      key
      lastPartNumber
      numRequests
      uploadedParts {
        ETag
        PartNumber
      }
      createdAt
      updatedAt
    }
  }
`;
