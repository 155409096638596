import React, { useContext, useState } from 'react';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';

import { StreamContext } from './stream';
import { EventsContext } from './events';
import { InvitationsContext } from './invitations';
import { PollsContext } from './polls';

import { leaveMeeting, logError } from '../helpers';

const StreamProvider = ({ children }) => {
  const [broadcasterIsLive, setBroadcasterIsLive] = useState(false);
  const [streamUrl, setStreamUrl] = useState(null);
  const [startedStreaming, setStartedStreaming] = useState(false);

  const eventsContext = useContext(EventsContext);
  const invitationsContext = useContext(InvitationsContext);
  const pollsContext = useContext(PollsContext);

  const { updateEvent, getCurrentEvent } = eventsContext;
  const { getLastInviteByEventId, updateInvitation } = invitationsContext;
  const { getLatestActivePollByEventId, updatePoll } = pollsContext;

  const meetingManager = useMeetingManager();

  const setBroadcasterState = (state) => {
    setBroadcasterIsLive(state);
  };

  const endEventByLeaving = async (eventId) => {
    try {
      const eventData = await getCurrentEvent(eventId, true);

      if (eventData.state !== 'live') {
        return null;
      }

      if (eventData.mode === 'battle') {
        const invite = await getLastInviteByEventId(eventId);

        if (invite && invite.status !== 'declined') {
          await updateInvitation(invite.id, {
            status: 'declined',
          });

          await leaveMeeting(
            { meetingId: meetingManager.meetingId },
            invite.mixerEventUUID,
            invite.mixerAccessCode
          );
        }

        const eventUUID = eventData?.mixerEventUUID || null;
        const accessCode = eventData?.mixerEventModeratorAccessCode || null;

        if (eventUUID && accessCode) {
          await leaveMeeting(
            { meetingId: meetingManager.meetingId },
            eventUUID,
            accessCode
          );
        }

        setBroadcasterState(false);
      }

      setTimeout(async () => {
        const activePoll = await getLatestActivePollByEventId(eventId);
        await updateEvent({ id: eventId, state: 'ended' });

        if (activePoll) {
          await updatePoll(activePoll.id, { status: 'archived' });
        }
      }, 5 * 1000);
    } catch (error) {
      logError('Error ending event', error);
    }
  };

  return (
    <StreamContext.Provider
      value={{
        broadcasterIsLive,
        streamUrl,
        setBroadcasterState,
        setStreamUrl,
        endEventByLeaving,
        startedStreaming,
        setStartedStreaming,
      }}
    >
      {children}
    </StreamContext.Provider>
  );
};

export default StreamProvider;

// export default class StreamProvider extends Component {
//   constructor(props) {
//     super(props);

//     this.state = {
//       broadcasterIsLive: false,
//       streamUrl: null,
//       setBroadcasterState: this.setBroadcasterState,
//       setStreamUrl: this.setStreamUrl,
//     };
//   }

//   setBroadcasterState = (state) => {
//     this.setState({ broadcasterIsLive: state });
//   };

//   setStreamUrl = (url) => {
//     this.setState({ streamUrl: url });
//   };

//   render() {
//     return (
//       <StreamContext.Provider value={this.state}>
//         {this.props.children}
//       </StreamContext.Provider>
//     );
//   }
// }
