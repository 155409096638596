import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const Paragraph = ({ children, paragraphSize, classes }) => {
  if (!children) {
    logError("Component Paragraph not rendered, because 'children' missing.");
    return null;
  }

  function setParagraphSize(size) {
    const paragraphStyles = 'font-paragraph font-normal';

    switch (size.toLowerCase()) {
      case 'large':
        return (
          <p className={`text-base leading-6 ${paragraphStyles} ${classes}`}>
            {children}
          </p>
        );
      case 'medium':
        return (
          <p className={`text-sm leading-5 ${paragraphStyles} ${classes}`}>
            {children}
          </p>
        );
      case 'small':
        return (
          <p className={`text-xs leading-4.5 ${paragraphStyles} ${classes}`}>
            {children}
          </p>
        );
    }
  }

  return <>{setParagraphSize(paragraphSize)}</>;
};

Paragraph.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  paragraphSize: PropTypes.oneOf(['large', 'medium', 'small']),
  classes: PropTypes.string,
};

Paragraph.defaultProps = {
  paragraphSize: 'large',
  classes: '',
};

export default Paragraph;
