/* eslint-disable */

export const onCustomUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      inventory
    }
  }
`;

export const onCreatorPermissionUpdate = /* GraphQL */ `
subscription OnUpdateUser($id: ID!) {
  onUpdateUser(id: $id) {
    creator
  }
}
`;

export const onNotificationsUpdate = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
    }
  }
`;