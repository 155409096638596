import React, { useContext } from 'react';
import Icon from '../typography/Icon';
import { AlertsContext } from '../../context/alerts';
import { logError } from '../../helpers';
import themeColors from '../../theme-colors';

const VideoUpload = ({
  disabled,
  onFileSelect,
  uploadProgress,
  processingProgress = 0,
  state,
  containerClassName = '',
  children,
}) => {
  const alertsContext = useContext(AlertsContext);
  const { addAlert } = alertsContext;

  const handleFileSelect = (e) => {
    if (disabled) {
      return;
    }

    const file = e?.target?.files[0];

    if (file) {
      const fileType = file.type;

      if (!fileType.includes('video')) {
        logError('File is not a video');
        addAlert('danger', 'Please select a video file');
        return;
      }
    }

    onFileSelect(file);
  };

  return (
    <div className={`video-upload-container ${containerClassName}`}>
      <label
        htmlFor='videoUpload'
        className={`video-upload-content ${disabled ? ' disabled' : ''}`}
      >
        <div className='absolute inset-0 flex items-center justify-center flex-col'>
          <Icon
            name='video'
            color={disabled ? '#dadada' : themeColors.primaryADark}
            size={22}
            classes='mb-4'
          />
          <div className='text-base font-display text-primaryADark'>
            {state === 'idle' && 'Choose a video file'}
            {state === 'uploading' && `Uploading... ${uploadProgress}%`}
            {state === 'processing' &&
              (processingProgress
                ? `Processing... ${processingProgress}%`
                : 'Processing...')}
          </div>
          {state === 'idle' && children}
        </div>
      </label>
      <div className='flex items-center justify-center mb-6'>
        <input
          id='videoUpload'
          style={{ visibility: 'hidden', width: 0, height: 0 }}
          type='file'
          onChange={handleFileSelect}
          accept='video/*'
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default VideoUpload;
