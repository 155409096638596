/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "us-east-1",
  "aws_cognito_identity_pool_id": "us-east-1:513999cb-be6c-4d03-8a45-2f07ebc7f1db",
  "aws_cognito_region": "us-east-1",
  "aws_user_pools_id": "us-east-1_RnIsWxP0R",
  "aws_user_pools_web_client_id": "3eqt09igana9v0kchchipnkhm4",
  "oauth": {},
  "aws_cognito_login_mechanisms": [
      "EMAIL",
      "PHONE_NUMBER",
      "PREFERRED_USERNAME"
  ],
  "aws_cognito_signup_attributes": [],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL",
      "PHONE"
  ],
  "aws_cloud_logic_custom": [
      {
          "name": "AdminQueries",
          "endpoint": "https://8pfnop9lka.execute-api.us-east-1.amazonaws.com/staging",
          "region": "us-east-1"
      },
      {
          "name": "clashTVStripeApi",
          "endpoint": "https://h7mizkicfe.execute-api.us-east-1.amazonaws.com/staging",
          "region": "us-east-1"
      },
      {
          "name": "clashTVRestApi",
          "endpoint": "https://p2i510vn27.execute-api.us-east-1.amazonaws.com/staging",
          "region": "us-east-1"
      }
  ],
  "aws_appsync_graphqlEndpoint": "https://6uki2kdbh5b7hldgauigi6qpkq.appsync-api.us-east-1.amazonaws.com/graphql",
  "aws_appsync_region": "us-east-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
  "aws_appsync_apiKey": "da2-mz55vkx7hzhrzec33s3tl7vvfu",
  "aws_content_delivery_bucket": "clashbackend-20210902105243-hostingbucket-staging",
  "aws_content_delivery_bucket_region": "us-east-1",
  "aws_content_delivery_url": "https://d20lbykz8jyh6v.cloudfront.net",
  "aws_user_files_s3_bucket": "clashtvbucket93106-staging",
  "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
