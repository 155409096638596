import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as LogoComponent } from '../logo.svg';
import history from '../../history';
import routes from '../../routes';

const Logo = ({ logoWidth, logoHeight, classes, clickable }) => {
  const handleClick = () => {
    if (clickable) {
      history.push(`${routes.dashboard}/state/upcoming`);
    }
  };

  return (
    <LogoComponent
      width={logoWidth}
      height={logoHeight}
      className={classes}
      onClick={handleClick}
    />
  );
};

Logo.propTypes = {
  logoWidth: PropTypes.number,
  logoHeight: PropTypes.number,
  classes: PropTypes.string,
  clickable: PropTypes.bool,
};

Logo.defaultProps = {
  logoWidth: 152,
  logoHeight: 88,
  classes: '',
  clickable: false,
};

export default Logo;
