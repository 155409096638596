import React, { useEffect, createRef } from 'react';
import PropTypes from 'prop-types';

const ClickawayListener = ({ children, onClick, classes }) => {
  const wrapperRef = createRef();

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => document.removeEventListener('click', handleClickOutside);
  }, [wrapperRef]);

  const handleClickOutside = (event) => {
    if (wrapperRef?.current) {
      if (!wrapperRef.current.contains(event.target)) {
        onClick();
        return;
      }
    }
  };

  return (
    <div ref={wrapperRef} className={`${classes}`}>
      {children}
    </div>
  );
};

ClickawayListener.propTypes = {
  children: PropTypes.element.isRequired,
  onClick: PropTypes.func,
  classes: PropTypes.string,
};

ClickawayListener.defaultProps = {
  onClick: () => {},
  classes: '',
};

export default ClickawayListener;
