import React from 'react';
import Skeleton from 'react-loading-skeleton';

const ShopItemSkeleton = () => {
  return (
    <div
      className={`flex flex-col justify-between p-4 rounded-lg w-1/4 mr-16 bg-secondaryB`}
    >
      <Skeleton duration={2} height={48} width={134} />
      <span className='block mb-8 font-display font-bold text-3xl tracking-tight'>
        <Skeleton duration={2} height={37} width={69} />
      </span>
      <span className='block font-display font-bold text-xl tracking-tight'>
        <Skeleton duration={2} height={24} width={100} />
      </span>
    </div>
  );
};

export default ShopItemSkeleton;
