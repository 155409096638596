import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { StickersContext } from './stickers';
import { listStickers, listStickersBundles } from '../graphql/queries';
import { logError } from '../helpers';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class StickersProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stickers: [],
      bundles: [],
      loading: false,
      loadStickers: this.loadStickers,
    };
  }

  loadStickers = async () => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listStickers));
      const bundlesRes = await API.graphql(
        graphqlOperation(listStickersBundles)
      );

      const items = res?.data?.listStickers?.items || [];
      const bundles = bundlesRes?.data?.listStickersBundles?.items || [];

      for (const i of items) {
        if (i?.image?.key) {
          i.image._url = this.getStickerUrl(i.image.key);
        }
      }

      for (const b of bundles) {
        if (b?.icon?.key) {
          b.icon._url = this.getStickerUrl(b.icon.key);
        }
      }

      this.setState({
        bundles,
        loading: false,
        stickers: items,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        stickers: [],
        bundles: [],
        error: 'Error loading stickers',
        loading: false,
      });
    }
  };

  getStickerUrl = (key) => {
    return `${imgBaseUrl}${key}?w=160`;
  };

  render() {
    return (
      <StickersContext.Provider value={this.state}>
        {this.props.children}
      </StickersContext.Provider>
    );
  }
}
