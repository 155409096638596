import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Heading from '../components/typography/Heading';
import RoomDashboardLink from '../components/room/RoomDashboardLink';
import DashLayout from '../components/dashboard/DashLayout';
import CardSkeleton from '../skeletons/CardSkeleton';

import { UserContext } from '../context/user';
import { EventsContext } from '../context/events';
import { SponsorRoomsContext } from '../context/sponsorRooms';

import { getRoomSidebarWidth } from '../helpers';
import routes from '../routes';
import history from '../history';
import featureFlags from '../features';

const Loader = () => {
  return Array(3)
    .fill()
    .map((item, index) => <CardSkeleton key={index} />);
};

const RoomDashboard = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  const sidebarWidth = getRoomSidebarWidth();

  const userContext = useContext(UserContext);
  const eventsContext = useContext(EventsContext);
  const roomsContext = useContext(SponsorRoomsContext);

  const { getCurrentUserId, user, isCreator } = userContext;
  const { getEventsByStateAndRoomId } = eventsContext;
  const { currentUserRoom } = roomsContext;

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  const loadEvents = async () => {
    setLoading(true);
    const { success, events: newEvents } = await getEventsByStateAndRoomId(
      currentUserRoom.id,
      'upcoming'
    );

    setLoading(false);

    if (success) {
      setEvents(newEvents);
    }
  };

  useEffect(() => {
    if (currentUserRoom?.id) {
      loadEvents();
    }
  }, [currentUserRoom?.id]);

  return (
    <>
      <Helmet>
        <title>My Dashboard | ClashTV</title>
      </Helmet>
      <div style={{ left: sidebarWidth }} className='account-tab-menu'>
        <Heading headingSize={4}>What do you want to do today?</Heading>
      </div>
      <div className='grid grid-cols-12 gap-6 mr-6 mt-28 pb-10 border-b border-secondaryB'>
        {/* <RoomDashboardLink
          heading='Stream using your webcam'
          firstLine='Stream using your webcam and'
          secondLine='invite participants to join you in a battle mode'
          path={{ pathname: routes.newEvent, state: { mode: 'battle' } }}
          disabled={!currentUserRoom || currentUserRoom?.isLive !== true}
        /> */}
        <RoomDashboardLink
          heading='Stream using prerecorded video'
          firstLine='Set the time and date on when do you want'
          secondLine='this video to be streamed to your community'
          path={{ pathname: routes.newEvent, state: { mode: 'delayedLive' } }}
          disabled={!currentUserRoom || currentUserRoom?.isLive !== true}
        />
        <RoomDashboardLink
          heading='Stream using software'
          firstLine='Stream your content live using a software'
          secondLine='like OBS, Streamlabs or LightStream'
          path={{ pathname: routes.newEvent, state: { mode: 'user' } }}
          disabled={!currentUserRoom || currentUserRoom?.isLive !== true}
        />
        {featureFlags.videos.active && (
          <RoomDashboardLink
            type='video'
            heading='Upload a video'
            firstLine='Upload a video that your community'
            secondLine='can see on demand'
            disabled={!currentUserRoom || currentUserRoom?.isLive !== true}
            path={routes.newVideo}
          />
        )}
      </div>
      <div className='flex justify-center mr-6 my-7'>
        <Heading headingSize={4}>Your Upcoming Events</Heading>
      </div>
      {events.length === 0 && !loading && (
        <div className='flex flex-1 items-center justify-center mr-6 my-14'>
          <Heading headingSize={5}>
            You don&apos;t have any scheduled events
          </Heading>
        </div>
      )}
      <div className='grid grid-cols-9 gap-4 mr-6'>
        {events.length > 0 && (
          <DashLayout
            events={events.slice(0, 3)}
            loading={false}
            showBuyModal={() => {}}
            ppvItems={[]}
            currentUserId={getCurrentUserId()}
          />
        )}
        {loading && <Loader />}
      </div>
    </>
  );
};

export default RoomDashboard;
