import React from 'react';
import PropTypes from 'prop-types';

const TableBody = ({ children, classes }) => {
  if (!children) {
    return null;
  }
  return <tbody className={classes}>{children}</tbody>;
};

TableBody.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};

TableBody.defaultProps = {
  classes: '',
};

export default TableBody;
