import React, { useRef } from 'react';
import logo from 'components/logo.svg';

const CardRoomAvatar = ({ uri, className = '' }) => {
  const imageRef = useRef();
  const objectCover = uri ? 'object-cover' : 'object-contain';
  const imageSrc = uri ? uri : logo;

  const setFallbackSrc = () => {
    imageRef.current.src = logo;
    imageRef.current.classList.remove('object-cover');
    imageRef.current.classList.add('object-contain');
  };

  return (
    <img
      ref={imageRef}
      src={imageSrc}
      onError={setFallbackSrc}
      className={`w-8 h-8 rounded-full ${objectCover} ${className}`}
    />
  );
};

export default CardRoomAvatar;
