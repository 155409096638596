import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Label from './Label';

const DatePicker = ({
  label,
  value,
  setValue,
  className,
  disabled,
  minDate,
  timeIntervals,
}) => {
  return (
    <div className='flex flex-col'>
      <Label
        text={label}
        inputId={Math.random().toString()}
        classes={`mb-5 ${disabled ? 'text-secondaryB' : ''}`}
        disabled={disabled}
      />
      <ReactDatePicker
        selected={value ? new Date(value) : null}
        onChange={setValue}
        showTimeSelect
        timeIntervals={timeIntervals}
        className={`base-input ${className}`}
        dateFormat='MMMM d, yyyy h:mm aa'
        placeholderText='Click to select Start time'
        disabled={disabled}
        minDate={minDate || null}
      />
    </div>
  );
};

export default DatePicker;
