import { useContext } from 'react';
import { StreamContext } from '../context/stream';

import { listChimeAttendees, setStreamLiveStatus } from '../helpers';

const useBroadcaster = () => {
  const streamContext = useContext(StreamContext);
  const { broadcasterIsLive, setBroadcasterState, streamUrl, setStreamUrl } =
    streamContext;

  const checkAndSetBroadcasterState = async (eventUUID, accessCode) => {
    const chimeAttendees = await listChimeAttendees(eventUUID, accessCode);
    let state = false;

    if (!chimeAttendees || !chimeAttendees.live) {
      setBroadcasterState(false);
      return;
    }

    if (chimeAttendees.live.length === 0) {
      setBroadcasterState(false);
      return;
    }

    for (const a of chimeAttendees.live) {
      const meta = a.metadata;

      if (meta && meta.role === 'broadcast') {
        state = true;
        break;
      }
    }

    if (state) {
      await setStreamLiveStatus(eventUUID, accessCode);
    }

    setBroadcasterState(state);
  };

  return {
    broadcasterIsLive,
    setBroadcasterState,
    streamUrl,
    setStreamUrl,
    checkAndSetBroadcasterState,
  };
};

export default useBroadcaster;
