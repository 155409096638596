import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { GameActionsContext } from './gameActions';
import { listGameActions } from '../graphql/queries';
import { createAction } from '../graphql/mutations';
import { logError } from '../helpers';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

export default class GameActionsProvider extends Component {
  constructor(props) {
    super(props);

    this.intervals = {};

    this.state = {
      actions: [],
      loading: true,
      error: null,
      cooldowns: {},
      tomatoAnimationIsStopped: true,
      tomatoColor: null,
      stopTomatoAnimation: this.stopTomatoAnimation,
      startTomatoAnimation: this.startTomatoAnimation,
      loadActions: this.loadActions,
      doAction: this.doAction,
    };
  }

  stopTomatoAnimation = () => {
    this.setState({
      tomatoColor: null,
      tomatoAnimationIsStopped: true,
    });
  };

  startTomatoAnimation = (color) => {
    this.setState({
      tomatoColor: color,
      tomatoAnimationIsStopped: false,
    });
  };

  loadActions = async () => {
    try {
      this.setState({ loading: true });
      const res = await API.graphql(graphqlOperation(listGameActions));

      const items = res?.data?.listGameActions?.items || [];

      for (const action of items) {
        if (action?.icon?.key) {
          action.icon._url = `${imgBaseUrl}${action.icon.key}?w=80`;
        }
      }

      this.setState({
        loading: false,
        actions: items,
        error: null,
      });
    } catch (error) {
      logError(error);
      this.setState({
        actions: [],
        error: 'Error loading ranking users',
        loading: false,
      });
    }
  };

  updateCooldowns = (gameActionID, actionCooldown) => {
    const cooldown = this.state.cooldowns[gameActionID] || 0;
    const seconds = cooldown + 1;

    if (seconds > actionCooldown) {
      clearInterval(this.intervals[gameActionID]);

      const cooldowns = { ...this.state.cooldowns };
      delete cooldowns[gameActionID];

      this.setState({ cooldowns });
      return;
    }

    this.setState({
      cooldowns: {
        ...this.state.cooldowns,
        [gameActionID]: seconds,
      },
    });
  };

  doAction = async (
    userID,
    eventID,
    gameActionID,
    contestor = 'A',
    actionCooldown
  ) => {
    try {
      if (actionCooldown) {
        this.intervals = {
          [gameActionID]: setInterval(
            () => this.updateCooldowns(gameActionID, actionCooldown),
            1000
          ),
        };
      }

      await API.graphql(
        graphqlOperation(createAction, {
          input: {
            contestor,
            eventID,
            userID,
            gameActionID,
          },
        })
      );
    } catch (error) {
      logError('Clap failed');
    }
  };

  componentWillUnmount() {
    for (const id in this.intervals) {
      if (Object.hasOwnProperty.call(this.intervals, id)) {
        const interval = this.intervals[id];
        clearInterval(interval);
      }
    }
  }

  render() {
    return (
      <GameActionsContext.Provider value={this.state}>
        {this.props.children}
      </GameActionsContext.Provider>
    );
  }
}
