import React from 'react';

import Story from './Story';

const Stories = ({
  stories,
  activeIndex,
  setTeaserDuration,
  setVideoDuration,
}) => {
  if (!stories) {
    return null;
  }

  return stories.map((story, i) => {
    return (
      <Story
        key={i}
        active={activeIndex === i}
        story={story}
        setTeaserDuration={setTeaserDuration}
        setVideoDuration={setVideoDuration}
      />
    );
  });
};

export default Stories;
