import React, { createRef, useState, useRef } from 'react';
import screenfull from 'screenfull';

import Icon from '../typography/Icon';
import Player from '../ivs-player/Player';
import useVideoContainerSize from '../../hooks/useVideoContainerSize';

const StreamPlayer = ({ streamUrl }) => {
  const [showPlayButton, setShowPlayButton] = useState(false);
  const videoRef = useRef();
  const videoContainerRef = useRef(null);
  const { containerSizes } = useVideoContainerSize(videoContainerRef?.current);

  const isSafari =
    navigator.userAgent.indexOf('Safari') != -1 &&
    navigator.userAgent.indexOf('Chrome') == -1;

  return (
    <div
      id='event-video-container'
      className={`event-video-container`}
      style={
        isSafari
          ? { height: containerSizes.height, width: containerSizes.width }
          : {}
      }
    >
      {showPlayButton && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 31,
          }}
        >
          <button
            className='bg-transparent w-20 h-20 left-1/2 top-1/2 rounded-full flex items-center justify-center focus:outline-none'
            style={{
              background: 'rgba(0,0,0,0.57)',
            }}
            onClick={() => {
              if (videoRef) {
                videoRef.current.play();
              }
            }}
          >
            <Icon name='play' color='#fff' size={32} />
          </button>
        </div>
      )}
      <Player
        player={videoRef}
        streamUrl={streamUrl}
        onReady={() => {
          if (videoRef?.current) {
            videoRef.current.setMuted(true);
            setShowPlayButton(videoRef.current.isPaused());
          }
        }}
      />
    </div>
  );
};

export default StreamPlayer;

export class StreamPlay extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      event: null,
      loading: true,
      volume: 1,
      muted: false,
      pip: false,
      fullScreen: false,
      showPlayButton: false,
    };

    this.videoRef = null;
  }

  componentDidMount() {
    this.videoRef = createRef();
  }

  handleClickFullscreen = () => {
    if (screenfull.isEnabled) {
      const videoContainer = document.getElementById('event-video-container');

      screenfull.toggle(videoContainer);
    }
  };

  handleVideoError = (event) => {
    const player = this.videoRef.current.getInternalPlayer();
    player[0].removeEventListener('error', this.handleVideoError);
  };

  render() {
    const { showPlayButton } = this.state;
    const { streamUrl } = this.props;

    return (
      <div id='event-video-container' className={`event-video-container`}>
        {showPlayButton && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 31,
            }}
          >
            <button
              className='bg-transparent w-20 h-20 left-1/2 top-1/2 rounded-full flex items-center justify-center focus:outline-none'
              style={{
                background: 'rgba(0,0,0,0.57)',
              }}
              onClick={() => {
                if (this.videoRef) {
                  this.videoRef.current.play();
                }
              }}
            >
              <Icon name='play' color='#fff' size={32} />
            </button>
          </div>
        )}
        <Player
          player={this.videoRef}
          streamUrl={streamUrl}
          onReady={() => {
            if (this.videoRef?.current) {
              this.videoRef.current.setMuted(true);

              this.setState({
                showPlayButton: this.videoRef.current.isPaused(),
              });
            }
          }}
        />
      </div>
    );
  }
}
