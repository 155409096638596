import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const Tooltip = ({ arrowSide, txt, classes }) => {
  if (!txt) {
    logError("Component Tooltip not rendered, because 'txt' missing.");
    return null;
  }
  return <span className={`tooltip ${classes} ${arrowSide}`}>{txt}</span>;
};

Tooltip.propTypes = {
  arrowSide: PropTypes.oneOf(['left-arrow', 'right-arrow', 'none']),
  txt: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

Tooltip.defaultProps = {
  arrowSide: 'left-arrow',
  txt: '',
  classes: '',
};

export default Tooltip;
