import { API, graphqlOperation } from 'aws-amplify';
import { useState } from 'react';
import { creatorRequestByUserID } from '../graphql/queries';
import { createCreatorRequest } from '../graphql/mutations';
import { logError } from '../helpers';

const useCreatorRequests = () => {
  const [request, setRequest] = useState(null);

  const getRequestByUserId = async (userID) => {
    if (!userID) {
      logError('getRequestByUserId', 'No user id');
      return { success: false, data: null };
    }

    try {
      const res = await API.graphql(
        graphqlOperation(creatorRequestByUserID, {
          userID,
          limit: 1,
        })
      );

      const existingReq = res.data.creatorRequestByUserID.items?.[0] || null;

      if (existingReq) {
        setRequest(res.data.creatorRequestByUserID.items?.[0] || null);
      }

      return {
        success: true,
        data: existingReq,
      };
    } catch (error) {
      logError('getRequestByUserId', error);
      return { success: false, data: null };
    }
  };

  const createRequest = async (data) => {
    try {
      const res = await API.graphql(
        graphqlOperation(createCreatorRequest, { input: data })
      );

      setRequest(res.data.createCreatorRequest);
      return { success: true, data: res.data.createCreatorRequest };
    } catch (error) {
      logError('createRequest', error);
      return { success: false, data: null };
    }
  };

  return { request, getRequestByUserId, createRequest };
};

export default useCreatorRequests;
