/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateChatMessage = /* GraphQL */ `
  subscription OnCreateChatMessage($eventID: String!) {
    onCreateChatMessage(eventID: $eventID) {
      id
      userID
      eventID
      content
      censoredContent
      stickerID
      displayName
      avatar
      level
      time
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent($id: ID!) {
    onUpdateEvent(id: $id) {
      id
      title
      description
      category
      type
      runWorkflowAutomatically
      videoInputUrl
      streamKey
      workflowID
      workflowType
      videoUrl
      teaserUrl
      videoRecordingDownloadUrl
      teaserThumbnailUrl
      libraryVodGuid
      startTime
      endTime
      startTimestamp
      endTimestamp
      hostSponsorRoomID
      moderatorID
      contestorA
      contestorAColor
      contestorB
      contestorBColor
      contestorAScore
      contestorBScore
      image {
        bucket
        region
        key
        _url
      }
      image2 {
        bucket
        region
        key
        _url
      }
      image3 {
        bucket
        region
        key
        _url
      }
      image4 {
        bucket
        region
        key
        _url
      }
      teaserFile {
        bucket
        region
        key
        _url
      }
      teaserFileBrokerID
      landscapeImage {
        bucket
        region
        key
        _url
      }
      landscapeImage2 {
        bucket
        region
        key
        _url
      }
      landscapeImage3 {
        bucket
        region
        key
        _url
      }
      landscapeImage4 {
        bucket
        region
        key
        _url
      }
      verticalImage {
        bucket
        region
        key
        _url
      }
      verticalImage2 {
        bucket
        region
        key
        _url
      }
      verticalImage3 {
        bucket
        region
        key
        _url
      }
      verticalImage4 {
        bucket
        region
        key
        _url
      }
      currentRound
      state
      paused
      archived
      deleted
      censorChat
      realm
      highlighted
      createdAt
      updatedAt
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      eventCategorisationID
      roundsEnabled
      mixerEventUUID
      mixerEventModeratorUUID
      mixerEventModeratorAccessCode
      mode
      disableChat
      dynamicParticipants
      ppv
      ppvPrice
      duration
      richTextDescription
      googleAdTagUrl
      seoUrl
      moderator {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      eventCategorisation {
        id
        title
        order
        createdAt
        updatedAt
      }
      hostSponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID!) {
    onUpdateUser(id: $id) {
      id
      email
      phone
      fullName
      displayName
      displayNameLowerCase
      cognitoUsername
      isWild
      creator
      location
      info
      image {
        bucket
        region
        key
        _url
      }
      profileCompleted
      notifications
      subscribedForNews
      coins
      level
      rank
      battles
      wins
      experience
      currentLevelBaseExperience
      nextLevelBaseExperience
      inventory
      activatedBoosters
      realm
      achievements
      fcmID
      notificationsArray {
        message
        read
        openUrl
        createdAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTransaction = /* GraphQL */ `
  subscription OnUpdateTransaction($userID: ID!) {
    onUpdateTransaction(userID: $userID) {
      id
      userID
      amount
      description
      status
      createdAt
      updatedAt
      shopItemID
      eventID
      sponsorRoomPayPerViewID
      isMonthPayPerView
      isDonation
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      shopItem {
        id
        image {
          bucket
          region
          key
          _url
        }
        title
        description
        price
        boosterID
        boosterAmount
        gameActionID
        gameActionAmount
        avatarID
        stickersBundleID
        order
        createdAt
        updatedAt
        gameAction {
          id
          name
          description
          defaultPower
          defaltCooldown
          order
          createdAt
          updatedAt
        }
        stickersBundle {
          id
          title
          description
          order
          createdAt
          updatedAt
        }
        booster {
          id
          type
          title
          description
          factor
          awardUserLevel
          duration
          createdAt
          updatedAt
        }
        avatar {
          id
          free
          gender
          order
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateBoosterActivation = /* GraphQL */ `
  subscription OnCreateBoosterActivation($userID: ID!) {
    onCreateBoosterActivation(userID: $userID) {
      id
      userID
      boosterID
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUserJoinEvent = /* GraphQL */ `
  subscription OnUserJoinEvent($eventID: ID!) {
    onUserJoinEvent(eventID: $eventID) {
      id
      userID
      displayName
      displayNameLowerCase
      eventID
      createdAt
      updatedAt
      raisedHand
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUserLeaveEvent = /* GraphQL */ `
  subscription OnUserLeaveEvent($eventID: ID!) {
    onUserLeaveEvent(eventID: $eventID) {
      id
      userID
      displayName
      displayNameLowerCase
      eventID
      createdAt
      updatedAt
      raisedHand
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUserRaiseHand = /* GraphQL */ `
  subscription OnUserRaiseHand($eventID: ID!) {
    onUserRaiseHand(eventID: $eventID) {
      id
      userID
      displayName
      displayNameLowerCase
      eventID
      createdAt
      updatedAt
      raisedHand
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUserInviteCreate = /* GraphQL */ `
  subscription OnUserInviteCreate {
    onUserInviteCreate {
      id
      creatorID
      userID
      status
      eventID
      mixerEventUUID
      mixerAtendeeID
      mixerAccessCode
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUserInviteUpdateByUserID = /* GraphQL */ `
  subscription OnUserInviteUpdateByUserID($userID: ID!) {
    onUserInviteUpdateByUserID(userID: $userID) {
      id
      creatorID
      userID
      status
      eventID
      mixerEventUUID
      mixerAtendeeID
      mixerAccessCode
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onUserInviteUpdateByID = /* GraphQL */ `
  subscription OnUserInviteUpdateByID($id: ID!) {
    onUserInviteUpdateByID(id: $id) {
      id
      creatorID
      userID
      status
      eventID
      mixerEventUUID
      mixerAtendeeID
      mixerAccessCode
      timestamp
      createdAt
      updatedAt
    }
  }
`;
export const onEventPollCreate = /* GraphQL */ `
  subscription OnEventPollCreate($eventID: ID!) {
    onEventPollCreate(eventID: $eventID) {
      id
      eventID
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      status
      createdAt
      updatedAt
    }
  }
`;
export const onEventPollUpdate = /* GraphQL */ `
  subscription OnEventPollUpdate($eventID: ID!) {
    onEventPollUpdate(eventID: $eventID) {
      id
      eventID
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      status
      createdAt
      updatedAt
    }
  }
`;
export const onEventPollUpdateID = /* GraphQL */ `
  subscription OnEventPollUpdateID($id: ID!) {
    onEventPollUpdateID(id: $id) {
      id
      eventID
      question
      answerA
      answerB
      answerC
      scoreAnswerA
      scoreAnswerB
      scoreAnswerC
      status
      createdAt
      updatedAt
    }
  }
`;
export const onPayPerViewListItemCreateByUserID = /* GraphQL */ `
  subscription OnPayPerViewListItemCreateByUserID($userID: ID!) {
    onPayPerViewListItemCreateByUserID(userID: $userID) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onSponsorRoomPayPerViewListItemCreateByUserID = /* GraphQL */ `
  subscription OnSponsorRoomPayPerViewListItemCreateByUserID($userID: ID!) {
    onSponsorRoomPayPerViewListItemCreateByUserID(userID: $userID) {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeaserVideoByID = /* GraphQL */ `
  subscription OnUpdateTeaserVideoByID($id: ID!) {
    onUpdateTeaserVideoByID(id: $id) {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventScoreByID = /* GraphQL */ `
  subscription OnUpdateEventScoreByID($id: ID!) {
    onUpdateEventScoreByID(id: $id) {
      id
      contestorAScore
      contestorBScore
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventStickyMessageByEventID = /* GraphQL */ `
  subscription OnCreateEventStickyMessageByEventID($eventID: ID!) {
    onCreateEventStickyMessageByEventID(eventID: $eventID) {
      id
      eventID
      moderatorID
      text
      originalCommentDisplayName
      originalCommentTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventStickyMessageByEventID = /* GraphQL */ `
  subscription OnUpdateEventStickyMessageByEventID($eventID: ID!) {
    onUpdateEventStickyMessageByEventID(eventID: $eventID) {
      id
      eventID
      moderatorID
      text
      originalCommentDisplayName
      originalCommentTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGoogleDefaultTagUrl = /* GraphQL */ `
  subscription OnCreateGoogleDefaultTagUrl {
    onCreateGoogleDefaultTagUrl {
      id
      googleAdTagUrl
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGoogleDefaultTagUrl = /* GraphQL */ `
  subscription OnUpdateGoogleDefaultTagUrl {
    onUpdateGoogleDefaultTagUrl {
      id
      googleAdTagUrl
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGoogleDefaultTagUrl = /* GraphQL */ `
  subscription OnDeleteGoogleDefaultTagUrl {
    onDeleteGoogleDefaultTagUrl {
      id
      googleAdTagUrl
      createdAt
      updatedAt
    }
  }
`;
export const onCreateOnEventEndedTrigger = /* GraphQL */ `
  subscription OnCreateOnEventEndedTrigger {
    onCreateOnEventEndedTrigger {
      id
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateOnEventEndedTrigger = /* GraphQL */ `
  subscription OnUpdateOnEventEndedTrigger {
    onUpdateOnEventEndedTrigger {
      id
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteOnEventEndedTrigger = /* GraphQL */ `
  subscription OnDeleteOnEventEndedTrigger {
    onDeleteOnEventEndedTrigger {
      id
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventUserWatchTime = /* GraphQL */ `
  subscription OnCreateEventUserWatchTime($userID: String) {
    onCreateEventUserWatchTime(userID: $userID) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventUserWatchTime = /* GraphQL */ `
  subscription OnUpdateEventUserWatchTime($userID: String) {
    onUpdateEventUserWatchTime(userID: $userID) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventUserWatchTime = /* GraphQL */ `
  subscription OnDeleteEventUserWatchTime($userID: String) {
    onDeleteEventUserWatchTime(userID: $userID) {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventStatistics = /* GraphQL */ `
  subscription OnCreateEventStatistics {
    onCreateEventStatistics {
      id
      eventLength
      totalViewers
      totalUniqueViewers
      totalComments
      averageWatchTime
      watchGraph
      claps
      tomatoes
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventStatistics = /* GraphQL */ `
  subscription OnUpdateEventStatistics {
    onUpdateEventStatistics {
      id
      eventLength
      totalViewers
      totalUniqueViewers
      totalComments
      averageWatchTime
      watchGraph
      claps
      tomatoes
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventStatistics = /* GraphQL */ `
  subscription OnDeleteEventStatistics {
    onDeleteEventStatistics {
      id
      eventLength
      totalViewers
      totalUniqueViewers
      totalComments
      averageWatchTime
      watchGraph
      claps
      tomatoes
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserPayPerViewList = /* GraphQL */ `
  subscription OnCreateUserPayPerViewList {
    onCreateUserPayPerViewList {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserPayPerViewList = /* GraphQL */ `
  subscription OnUpdateUserPayPerViewList {
    onUpdateUserPayPerViewList {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserPayPerViewList = /* GraphQL */ `
  subscription OnDeleteUserPayPerViewList {
    onDeleteUserPayPerViewList {
      id
      eventID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserSponsorRoomPayPerViewList = /* GraphQL */ `
  subscription OnCreateUserSponsorRoomPayPerViewList {
    onCreateUserSponsorRoomPayPerViewList {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserSponsorRoomPayPerViewList = /* GraphQL */ `
  subscription OnUpdateUserSponsorRoomPayPerViewList {
    onUpdateUserSponsorRoomPayPerViewList {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserSponsorRoomPayPerViewList = /* GraphQL */ `
  subscription OnDeleteUserSponsorRoomPayPerViewList {
    onDeleteUserSponsorRoomPayPerViewList {
      id
      sponsorRoomPayPerViewID
      userID
      month
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTeaserVideo = /* GraphQL */ `
  subscription OnCreateTeaserVideo {
    onCreateTeaserVideo {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTeaserVideo = /* GraphQL */ `
  subscription OnUpdateTeaserVideo {
    onUpdateTeaserVideo {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTeaserVideo = /* GraphQL */ `
  subscription OnDeleteTeaserVideo {
    onDeleteTeaserVideo {
      id
      eventID
      isDelayedLive
      vodPlaybackUrl
      livePlaybackUrl
      thumbnailUrl
      vodGuID
      length
      length3
      brokerID
      file {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventLastUpdate = /* GraphQL */ `
  subscription OnCreateEventLastUpdate {
    onCreateEventLastUpdate {
      id
      type
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventLastUpdate = /* GraphQL */ `
  subscription OnUpdateEventLastUpdate {
    onUpdateEventLastUpdate {
      id
      type
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventLastUpdate = /* GraphQL */ `
  subscription OnDeleteEventLastUpdate {
    onDeleteEventLastUpdate {
      id
      type
      eventID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGameAction = /* GraphQL */ `
  subscription OnCreateGameAction {
    onCreateGameAction {
      id
      name
      description
      defaultPower
      defaltCooldown
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGameAction = /* GraphQL */ `
  subscription OnUpdateGameAction {
    onUpdateGameAction {
      id
      name
      description
      defaultPower
      defaltCooldown
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGameAction = /* GraphQL */ `
  subscription OnDeleteGameAction {
    onDeleteGameAction {
      id
      name
      description
      defaultPower
      defaltCooldown
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAction = /* GraphQL */ `
  subscription OnCreateAction {
    onCreateAction {
      id
      userID
      gameActionID
      eventID
      contestor
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateAction = /* GraphQL */ `
  subscription OnUpdateAction {
    onUpdateAction {
      id
      userID
      gameActionID
      eventID
      contestor
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteAction = /* GraphQL */ `
  subscription OnDeleteAction {
    onDeleteAction {
      id
      userID
      gameActionID
      eventID
      contestor
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateUserActionHistory = /* GraphQL */ `
  subscription OnCreateUserActionHistory {
    onCreateUserActionHistory {
      id
      userID
      stats
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateUserActionHistory = /* GraphQL */ `
  subscription OnUpdateUserActionHistory {
    onUpdateUserActionHistory {
      id
      userID
      stats
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteUserActionHistory = /* GraphQL */ `
  subscription OnDeleteUserActionHistory {
    onDeleteUserActionHistory {
      id
      userID
      stats
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateEventCategorisation = /* GraphQL */ `
  subscription OnCreateEventCategorisation {
    onCreateEventCategorisation {
      id
      title
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventCategorisation = /* GraphQL */ `
  subscription OnUpdateEventCategorisation {
    onUpdateEventCategorisation {
      id
      title
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventCategorisation = /* GraphQL */ `
  subscription OnDeleteEventCategorisation {
    onDeleteEventCategorisation {
      id
      title
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoomCategorisation = /* GraphQL */ `
  subscription OnCreateRoomCategorisation {
    onCreateRoomCategorisation {
      id
      title
      order
      logo {
        bucket
        region
        key
        _url
      }
      cover {
        bucket
        region
        key
        _url
      }
      label
      promotionTitle
      promoted
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoomCategorisation = /* GraphQL */ `
  subscription OnUpdateRoomCategorisation {
    onUpdateRoomCategorisation {
      id
      title
      order
      logo {
        bucket
        region
        key
        _url
      }
      cover {
        bucket
        region
        key
        _url
      }
      label
      promotionTitle
      promoted
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoomCategorisation = /* GraphQL */ `
  subscription OnDeleteRoomCategorisation {
    onDeleteRoomCategorisation {
      id
      title
      order
      logo {
        bucket
        region
        key
        _url
      }
      cover {
        bucket
        region
        key
        _url
      }
      label
      promotionTitle
      promoted
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSponsorRoomPayPerView = /* GraphQL */ `
  subscription OnCreateSponsorRoomPayPerView($userID: String) {
    onCreateSponsorRoomPayPerView(userID: $userID) {
      id
      sponsorRoomId
      title
      description
      image {
        bucket
        region
        key
        _url
      }
      price
      monthPrice
      startDate
      endDate
      createdAt
      updatedAt
      userID
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateSponsorRoomPayPerView = /* GraphQL */ `
  subscription OnUpdateSponsorRoomPayPerView($userID: String) {
    onUpdateSponsorRoomPayPerView(userID: $userID) {
      id
      sponsorRoomId
      title
      description
      image {
        bucket
        region
        key
        _url
      }
      price
      monthPrice
      startDate
      endDate
      createdAt
      updatedAt
      userID
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteSponsorRoomPayPerView = /* GraphQL */ `
  subscription OnDeleteSponsorRoomPayPerView($userID: String) {
    onDeleteSponsorRoomPayPerView(userID: $userID) {
      id
      sponsorRoomId
      title
      description
      image {
        bucket
        region
        key
        _url
      }
      price
      monthPrice
      startDate
      endDate
      createdAt
      updatedAt
      userID
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateSponsorRoom = /* GraphQL */ `
  subscription OnCreateSponsorRoom($userID: String) {
    onCreateSponsorRoom(userID: $userID) {
      id
      userID
      title
      description
      merchUrl
      image {
        bucket
        region
        key
        _url
      }
      coverPhoto {
        bucket
        region
        key
        _url
      }
      collaborationLogo {
        bucket
        region
        key
        _url
      }
      order
      isLive
      roomCategorisationID
      ivsChannel {
        channelArn
        ingestEndpoint
        playbackUrl
        name
        streamKeyArn
        streamKey
        recordingConfigurationArn
      }
      ioioChannel {
        ioioChannelID
        playbackUrl
      }
      followers
      featured
      seoUrl
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      roomCategorisation {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSponsorRoom = /* GraphQL */ `
  subscription OnUpdateSponsorRoom($userID: String) {
    onUpdateSponsorRoom(userID: $userID) {
      id
      userID
      title
      description
      merchUrl
      image {
        bucket
        region
        key
        _url
      }
      coverPhoto {
        bucket
        region
        key
        _url
      }
      collaborationLogo {
        bucket
        region
        key
        _url
      }
      order
      isLive
      roomCategorisationID
      ivsChannel {
        channelArn
        ingestEndpoint
        playbackUrl
        name
        streamKeyArn
        streamKey
        recordingConfigurationArn
      }
      ioioChannel {
        ioioChannelID
        playbackUrl
      }
      followers
      featured
      seoUrl
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      roomCategorisation {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSponsorRoom = /* GraphQL */ `
  subscription OnDeleteSponsorRoom($userID: String) {
    onDeleteSponsorRoom(userID: $userID) {
      id
      userID
      title
      description
      merchUrl
      image {
        bucket
        region
        key
        _url
      }
      coverPhoto {
        bucket
        region
        key
        _url
      }
      collaborationLogo {
        bucket
        region
        key
        _url
      }
      order
      isLive
      roomCategorisationID
      ivsChannel {
        channelArn
        ingestEndpoint
        playbackUrl
        name
        streamKeyArn
        streamKey
        recordingConfigurationArn
      }
      ioioChannel {
        ioioChannelID
        playbackUrl
      }
      followers
      featured
      seoUrl
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      roomCategorisation {
        id
        title
        order
        logo {
          bucket
          region
          key
          _url
        }
        cover {
          bucket
          region
          key
          _url
        }
        label
        promotionTitle
        promoted
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSticker = /* GraphQL */ `
  subscription OnCreateSticker {
    onCreateSticker {
      id
      title
      image {
        bucket
        region
        key
        _url
      }
      stickerBundleID
      order
      createdAt
      updatedAt
      stickerBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
    }
  }
`;
export const onUpdateSticker = /* GraphQL */ `
  subscription OnUpdateSticker {
    onUpdateSticker {
      id
      title
      image {
        bucket
        region
        key
        _url
      }
      stickerBundleID
      order
      createdAt
      updatedAt
      stickerBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
    }
  }
`;
export const onDeleteSticker = /* GraphQL */ `
  subscription OnDeleteSticker {
    onDeleteSticker {
      id
      title
      image {
        bucket
        region
        key
        _url
      }
      stickerBundleID
      order
      createdAt
      updatedAt
      stickerBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
    }
  }
`;
export const onCreateStickersBundle = /* GraphQL */ `
  subscription OnCreateStickersBundle {
    onCreateStickersBundle {
      id
      title
      description
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
      stickers {
        items {
          id
          title
          stickerBundleID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateStickersBundle = /* GraphQL */ `
  subscription OnUpdateStickersBundle {
    onUpdateStickersBundle {
      id
      title
      description
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
      stickers {
        items {
          id
          title
          stickerBundleID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteStickersBundle = /* GraphQL */ `
  subscription OnDeleteStickersBundle {
    onDeleteStickersBundle {
      id
      title
      description
      icon {
        bucket
        region
        key
        _url
      }
      order
      createdAt
      updatedAt
      stickers {
        items {
          id
          title
          stickerBundleID
          order
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateBooster = /* GraphQL */ `
  subscription OnCreateBooster {
    onCreateBooster {
      id
      type
      title
      description
      factor
      awardUserLevel
      duration
      icon {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBooster = /* GraphQL */ `
  subscription OnUpdateBooster {
    onUpdateBooster {
      id
      type
      title
      description
      factor
      awardUserLevel
      duration
      icon {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBooster = /* GraphQL */ `
  subscription OnDeleteBooster {
    onDeleteBooster {
      id
      type
      title
      description
      factor
      awardUserLevel
      duration
      icon {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFollowing = /* GraphQL */ `
  subscription OnCreateFollowing {
    onCreateFollowing {
      id
      userID
      sponsorRoomID
      followerDisplayName
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateFollowing = /* GraphQL */ `
  subscription OnUpdateFollowing {
    onUpdateFollowing {
      id
      userID
      sponsorRoomID
      followerDisplayName
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteFollowing = /* GraphQL */ `
  subscription OnDeleteFollowing {
    onDeleteFollowing {
      id
      userID
      sponsorRoomID
      followerDisplayName
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoom {
        id
        userID
        title
        description
        merchUrl
        image {
          bucket
          region
          key
          _url
        }
        coverPhoto {
          bucket
          region
          key
          _url
        }
        collaborationLogo {
          bucket
          region
          key
          _url
        }
        order
        isLive
        roomCategorisationID
        ivsChannel {
          channelArn
          ingestEndpoint
          playbackUrl
          name
          streamKeyArn
          streamKey
          recordingConfigurationArn
        }
        ioioChannel {
          ioioChannelID
          playbackUrl
        }
        followers
        featured
        seoUrl
        createdAt
        updatedAt
        user {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        roomCategorisation {
          id
          title
          order
          label
          promotionTitle
          promoted
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateShopItem = /* GraphQL */ `
  subscription OnCreateShopItem {
    onCreateShopItem {
      id
      image {
        bucket
        region
        key
        _url
      }
      title
      description
      price
      boosterID
      boosterAmount
      gameActionID
      gameActionAmount
      avatarID
      stickersBundleID
      order
      createdAt
      updatedAt
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      stickersBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      avatar {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateShopItem = /* GraphQL */ `
  subscription OnUpdateShopItem {
    onUpdateShopItem {
      id
      image {
        bucket
        region
        key
        _url
      }
      title
      description
      price
      boosterID
      boosterAmount
      gameActionID
      gameActionAmount
      avatarID
      stickersBundleID
      order
      createdAt
      updatedAt
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      stickersBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      avatar {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteShopItem = /* GraphQL */ `
  subscription OnDeleteShopItem {
    onDeleteShopItem {
      id
      image {
        bucket
        region
        key
        _url
      }
      title
      description
      price
      boosterID
      boosterAmount
      gameActionID
      gameActionAmount
      avatarID
      stickersBundleID
      order
      createdAt
      updatedAt
      gameAction {
        id
        name
        description
        defaultPower
        defaltCooldown
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
      }
      stickersBundle {
        id
        title
        description
        icon {
          bucket
          region
          key
          _url
        }
        order
        createdAt
        updatedAt
        stickers {
          nextToken
        }
      }
      booster {
        id
        type
        title
        description
        factor
        awardUserLevel
        duration
        icon {
          bucket
          region
          key
          _url
        }
        createdAt
        updatedAt
      }
      avatar {
        id
        image {
          bucket
          region
          key
          _url
        }
        free
        gender
        order
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateAchievement = /* GraphQL */ `
  subscription OnCreateAchievement {
    onCreateAchievement {
      id
      badge {
        bucket
        region
        key
        _url
      }
      title
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAchievement = /* GraphQL */ `
  subscription OnUpdateAchievement {
    onUpdateAchievement {
      id
      badge {
        bucket
        region
        key
        _url
      }
      title
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAchievement = /* GraphQL */ `
  subscription OnDeleteAchievement {
    onDeleteAchievement {
      id
      badge {
        bucket
        region
        key
        _url
      }
      title
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEventLeaderBoard = /* GraphQL */ `
  subscription OnCreateEventLeaderBoard {
    onCreateEventLeaderBoard {
      id
      board
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateEventLeaderBoard = /* GraphQL */ `
  subscription OnUpdateEventLeaderBoard {
    onUpdateEventLeaderBoard {
      id
      board
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteEventLeaderBoard = /* GraphQL */ `
  subscription OnDeleteEventLeaderBoard {
    onDeleteEventLeaderBoard {
      id
      board
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCoinsPackage = /* GraphQL */ `
  subscription OnCreateCoinsPackage {
    onCreateCoinsPackage {
      id
      title
      description
      numberOfCoins
      priceInUSD
      iOsProductID
      androidProductID
      image {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCoinsPackage = /* GraphQL */ `
  subscription OnUpdateCoinsPackage {
    onUpdateCoinsPackage {
      id
      title
      description
      numberOfCoins
      priceInUSD
      iOsProductID
      androidProductID
      image {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCoinsPackage = /* GraphQL */ `
  subscription OnDeleteCoinsPackage {
    onDeleteCoinsPackage {
      id
      title
      description
      numberOfCoins
      priceInUSD
      iOsProductID
      androidProductID
      image {
        bucket
        region
        key
        _url
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAvatar = /* GraphQL */ `
  subscription OnCreateAvatar {
    onCreateAvatar {
      id
      image {
        bucket
        region
        key
        _url
      }
      free
      gender
      order
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAvatar = /* GraphQL */ `
  subscription OnUpdateAvatar {
    onUpdateAvatar {
      id
      image {
        bucket
        region
        key
        _url
      }
      free
      gender
      order
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAvatar = /* GraphQL */ `
  subscription OnDeleteAvatar {
    onDeleteAvatar {
      id
      image {
        bucket
        region
        key
        _url
      }
      free
      gender
      order
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChatReport = /* GraphQL */ `
  subscription OnCreateChatReport {
    onCreateChatReport {
      id
      reportingUserID
      reportedUserID
      message
      description
      createdAt
      updatedAt
      reportingUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      reportedUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateChatReport = /* GraphQL */ `
  subscription OnUpdateChatReport {
    onUpdateChatReport {
      id
      reportingUserID
      reportedUserID
      message
      description
      createdAt
      updatedAt
      reportingUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      reportedUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteChatReport = /* GraphQL */ `
  subscription OnDeleteChatReport {
    onDeleteChatReport {
      id
      reportingUserID
      reportedUserID
      message
      description
      createdAt
      updatedAt
      reportingUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      reportedUser {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateLegalInformation = /* GraphQL */ `
  subscription OnCreateLegalInformation {
    onCreateLegalInformation {
      id
      termsOfService
      privacyPolicy
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLegalInformation = /* GraphQL */ `
  subscription OnUpdateLegalInformation {
    onUpdateLegalInformation {
      id
      termsOfService
      privacyPolicy
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLegalInformation = /* GraphQL */ `
  subscription OnDeleteLegalInformation {
    onDeleteLegalInformation {
      id
      termsOfService
      privacyPolicy
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCustomNotification = /* GraphQL */ `
  subscription OnCreateCustomNotification {
    onCreateCustomNotification {
      id
      message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomNotification = /* GraphQL */ `
  subscription OnUpdateCustomNotification {
    onUpdateCustomNotification {
      id
      message
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomNotification = /* GraphQL */ `
  subscription OnDeleteCustomNotification {
    onDeleteCustomNotification {
      id
      message
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAppVersion = /* GraphQL */ `
  subscription OnCreateAppVersion {
    onCreateAppVersion {
      id
      minVersion
      currentVersion
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAppVersion = /* GraphQL */ `
  subscription OnUpdateAppVersion {
    onUpdateAppVersion {
      id
      minVersion
      currentVersion
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAppVersion = /* GraphQL */ `
  subscription OnDeleteAppVersion {
    onDeleteAppVersion {
      id
      minVersion
      currentVersion
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCreatorPayoutInfo = /* GraphQL */ `
  subscription OnCreateCreatorPayoutInfo($userID: String) {
    onCreateCreatorPayoutInfo(userID: $userID) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateCreatorPayoutInfo = /* GraphQL */ `
  subscription OnUpdateCreatorPayoutInfo($userID: String) {
    onUpdateCreatorPayoutInfo(userID: $userID) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteCreatorPayoutInfo = /* GraphQL */ `
  subscription OnDeleteCreatorPayoutInfo($userID: String) {
    onDeleteCreatorPayoutInfo(userID: $userID) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateSponsorRoomPayPerViewProfit = /* GraphQL */ `
  subscription OnCreateSponsorRoomPayPerViewProfit($userID: String) {
    onCreateSponsorRoomPayPerViewProfit(userID: $userID) {
      id
      userID
      totalWholePurchases
      totalMonthPurchases
      payPerViewID
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onUpdateSponsorRoomPayPerViewProfit = /* GraphQL */ `
  subscription OnUpdateSponsorRoomPayPerViewProfit($userID: String) {
    onUpdateSponsorRoomPayPerViewProfit(userID: $userID) {
      id
      userID
      totalWholePurchases
      totalMonthPurchases
      payPerViewID
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onDeleteSponsorRoomPayPerViewProfit = /* GraphQL */ `
  subscription OnDeleteSponsorRoomPayPerViewProfit($userID: String) {
    onDeleteSponsorRoomPayPerViewProfit(userID: $userID) {
      id
      userID
      totalWholePurchases
      totalMonthPurchases
      payPerViewID
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
      sponsorRoomPayPerView {
        id
        sponsorRoomId
        title
        description
        image {
          bucket
          region
          key
          _url
        }
        price
        monthPrice
        startDate
        endDate
        createdAt
        updatedAt
        userID
        sponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const onCreateEventProfit = /* GraphQL */ `
  subscription OnCreateEventProfit($userID: String) {
    onCreateEventProfit(userID: $userID) {
      id
      userID
      totalPurchases
      eventID
      totalCoinsFromDonations
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateEventProfit = /* GraphQL */ `
  subscription OnUpdateEventProfit($userID: String) {
    onUpdateEventProfit(userID: $userID) {
      id
      userID
      totalPurchases
      eventID
      totalCoinsFromDonations
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteEventProfit = /* GraphQL */ `
  subscription OnDeleteEventProfit($userID: String) {
    onDeleteEventProfit(userID: $userID) {
      id
      userID
      totalPurchases
      eventID
      totalCoinsFromDonations
      totalCoinsFromPPV
      totalCoins
      totalUsd
      revenuePercent
      totalCreatorProfitUsd
      createdAt
      updatedAt
      event {
        id
        title
        description
        category
        type
        runWorkflowAutomatically
        videoInputUrl
        streamKey
        workflowID
        workflowType
        videoUrl
        teaserUrl
        videoRecordingDownloadUrl
        teaserThumbnailUrl
        libraryVodGuid
        startTime
        endTime
        startTimestamp
        endTimestamp
        hostSponsorRoomID
        moderatorID
        contestorA
        contestorAColor
        contestorB
        contestorBColor
        contestorAScore
        contestorBScore
        image {
          bucket
          region
          key
          _url
        }
        image2 {
          bucket
          region
          key
          _url
        }
        image3 {
          bucket
          region
          key
          _url
        }
        image4 {
          bucket
          region
          key
          _url
        }
        teaserFile {
          bucket
          region
          key
          _url
        }
        teaserFileBrokerID
        landscapeImage {
          bucket
          region
          key
          _url
        }
        landscapeImage2 {
          bucket
          region
          key
          _url
        }
        landscapeImage3 {
          bucket
          region
          key
          _url
        }
        landscapeImage4 {
          bucket
          region
          key
          _url
        }
        verticalImage {
          bucket
          region
          key
          _url
        }
        verticalImage2 {
          bucket
          region
          key
          _url
        }
        verticalImage3 {
          bucket
          region
          key
          _url
        }
        verticalImage4 {
          bucket
          region
          key
          _url
        }
        currentRound
        state
        paused
        archived
        deleted
        censorChat
        realm
        highlighted
        createdAt
        updatedAt
        question
        answerA
        answerB
        answerC
        scoreAnswerA
        scoreAnswerB
        scoreAnswerC
        eventCategorisationID
        roundsEnabled
        mixerEventUUID
        mixerEventModeratorUUID
        mixerEventModeratorAccessCode
        mode
        disableChat
        dynamicParticipants
        ppv
        ppvPrice
        duration
        richTextDescription
        googleAdTagUrl
        seoUrl
        moderator {
          id
          email
          phone
          fullName
          displayName
          displayNameLowerCase
          cognitoUsername
          isWild
          creator
          location
          info
          profileCompleted
          notifications
          subscribedForNews
          coins
          level
          rank
          battles
          wins
          experience
          currentLevelBaseExperience
          nextLevelBaseExperience
          inventory
          activatedBoosters
          realm
          achievements
          fcmID
          createdAt
          updatedAt
        }
        eventCategorisation {
          id
          title
          order
          createdAt
          updatedAt
        }
        hostSponsorRoom {
          id
          userID
          title
          description
          merchUrl
          order
          isLive
          roomCategorisationID
          followers
          featured
          seoUrl
          createdAt
          updatedAt
        }
      }
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateWithdrawRequest = /* GraphQL */ `
  subscription OnCreateWithdrawRequest($userID: String) {
    onCreateWithdrawRequest(userID: $userID) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      amount
      coins
      status
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateWithdrawRequest = /* GraphQL */ `
  subscription OnUpdateWithdrawRequest($userID: String) {
    onUpdateWithdrawRequest(userID: $userID) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      amount
      coins
      status
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteWithdrawRequest = /* GraphQL */ `
  subscription OnDeleteWithdrawRequest($userID: String) {
    onDeleteWithdrawRequest(userID: $userID) {
      id
      userID
      abaNumber
      accountNumber
      accountType
      accountName
      bankInfo
      amount
      coins
      status
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateCreatorRequest = /* GraphQL */ `
  subscription OnCreateCreatorRequest($userID: String) {
    onCreateCreatorRequest(userID: $userID) {
      id
      userID
      email
      name
      displayName
      socialLink1
      socialLink2
      socialLink3
      socialLink4
      socialLink5
      socialLink6
      motivation
      heardFromAdventuresMusicTour
      adventuresMusicTourCity
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateCreatorRequest = /* GraphQL */ `
  subscription OnUpdateCreatorRequest($userID: String) {
    onUpdateCreatorRequest(userID: $userID) {
      id
      userID
      email
      name
      displayName
      socialLink1
      socialLink2
      socialLink3
      socialLink4
      socialLink5
      socialLink6
      motivation
      heardFromAdventuresMusicTour
      adventuresMusicTourCity
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteCreatorRequest = /* GraphQL */ `
  subscription OnDeleteCreatorRequest($userID: String) {
    onDeleteCreatorRequest(userID: $userID) {
      id
      userID
      email
      name
      displayName
      socialLink1
      socialLink2
      socialLink3
      socialLink4
      socialLink5
      socialLink6
      motivation
      heardFromAdventuresMusicTour
      adventuresMusicTourCity
      createdAt
      updatedAt
      user {
        id
        email
        phone
        fullName
        displayName
        displayNameLowerCase
        cognitoUsername
        isWild
        creator
        location
        info
        image {
          bucket
          region
          key
          _url
        }
        profileCompleted
        notifications
        subscribedForNews
        coins
        level
        rank
        battles
        wins
        experience
        currentLevelBaseExperience
        nextLevelBaseExperience
        inventory
        activatedBoosters
        realm
        achievements
        fcmID
        notificationsArray {
          message
          read
          openUrl
          createdAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateMultipartUploadLibraryQueue = /* GraphQL */ `
  subscription OnCreateMultipartUploadLibraryQueue {
    onCreateMultipartUploadLibraryQueue {
      id
      uploadID
      status
      bucket
      copySource
      key
      lastPartNumber
      numRequests
      uploadedParts {
        ETag
        PartNumber
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMultipartUploadLibraryQueue = /* GraphQL */ `
  subscription OnUpdateMultipartUploadLibraryQueue {
    onUpdateMultipartUploadLibraryQueue {
      id
      uploadID
      status
      bucket
      copySource
      key
      lastPartNumber
      numRequests
      uploadedParts {
        ETag
        PartNumber
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMultipartUploadLibraryQueue = /* GraphQL */ `
  subscription OnDeleteMultipartUploadLibraryQueue {
    onDeleteMultipartUploadLibraryQueue {
      id
      uploadID
      status
      bucket
      copySource
      key
      lastPartNumber
      numRequests
      uploadedParts {
        ETag
        PartNumber
      }
      createdAt
      updatedAt
    }
  }
`;
