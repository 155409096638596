import React, { useContext, useRef, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import InputAutocomplete from './InputAutocomplete';
import Avatar from '../common/Avatar';
import { UserContext } from '../../context/user';
import Button from '../common/Button';

const imgBaseUrl = process.env.REACT_APP_IMG_BASE;

const getExistingImageUrl = (key) => {
  if (!key) {
    return null;
  }

  return `${imgBaseUrl}${key}`;
};

const ResultsList = ({ items }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className='scrollable shadow-card absolute bg-white w-full top-14 z-10 max-h-64 overflow-y-auto'>
      {items}
    </div>
  );
};

const UserAutocomplete = ({
  label,
  tooltip,
  onSelect,
  initialvalue,
  onClear,
  disabled = true,
}) => {
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [value, setValue] = useState('');
  const [users, setUsers] = useState([]);

  const userContext = useContext(UserContext);
  const { getUsersByDisplayNameTerm } = userContext;

  const autocompleteRef = useRef(null);

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      if (autocompleteRef.current.value) {
        await getUsers(null, autocompleteRef.current.value);
      } else {
        setUsers([]);
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [value]);

  useEffect(() => {
    if (initialvalue?.displayName) {
      autocompleteRef.current.value = initialvalue.displayName;
    }
  }, [initialvalue]);

  const onInputChange = async (text) => {
    setValue(text);
  };

  const getUsers = async () => {
    if (value.length > 2) {
      if (!loadingUsers) {
        setLoadingUsers(true);
        const { users } = await getUsersByDisplayNameTerm(null, value);
        setUsers(users);
        setLoadingUsers(false);
      }
    }
  };

  const onUserSelect = (user) => {
    autocompleteRef.current.value = user.displayName;
    onSelect(user);
  };

  const getResultItems = () => {
    return users.map((user) => (
      <div
        key={user.id}
        className='flex p-4 font-display text-base cursor-pointer hover:bg-secondaryC border-red-600'
        onMouseDown={() => onUserSelect(user)}
      >
        <Avatar
          imgSrc={getExistingImageUrl(user?.image?.key)}
          imgSize={7}
          classes='mr-2'
        />
        {user.displayName}
      </div>
    ));
  };

  const handleClear = () => {
    setUsers([]);
    setValue('');
    autocompleteRef.current.value = '';
    onClear();
  };

  return (
    <div className='flex flex-row items-end'>
      <div className='flex flex-col flex-1'>
        <span className='label-base mb-5' data-tip={tooltip}>
          {label}
        </span>
        <ReactTooltip effect='solid' className='react-tooltip' />
        <InputAutocomplete
          id='participants-autocomplete'
          placeholder='Assign moderator'
          containerClassName='flex flex-1 mr-4'
          resultsList={<ResultsList items={getResultItems()} />}
          onChange={onInputChange}
          inputRef={autocompleteRef}
          disabled={disabled}
          resultsAreLoading={loadingUsers}
          inputClassName={'py-5'}
        />
      </div>
      <Button
        buttonType='secondary'
        buttonSize='large'
        buttonOnClick={handleClear}
        disabled={disabled}
      >
        Clear
      </Button>
    </div>
  );
};

export default UserAutocomplete;
