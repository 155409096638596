import React, { useContext, useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import Skeleton from 'react-loading-skeleton';
import { Helmet } from 'react-helmet';

import Table from '../components/table/Table';
import TableHead from '../components/table/TableHead';
import TableBody from '../components/table/TableBody';
import TableRow from '../components/table/TableRow';
import TableCell from '../components/table/TableCell';
import CreatorEventRow from '../components/creator/CreatorEventRow';
import Button from '../components/common/Button';
import Modal from '../components/system/Modal';

import { UserContext } from '../context/user';
import { EventsContext } from '../context/events';
import { AlertsContext } from '../context/alerts';
import { SponsorRoomsContext } from '../context/sponsorRooms';

import routes from '../routes';
import history from '../history';

const EditEventPage = () => {
  const userContext = useContext(UserContext);
  const eventsContext = useContext(EventsContext);
  const alertsContext = useContext(AlertsContext);
  const sponsorRoomsContext = useContext(SponsorRoomsContext);

  const {
    loadCreatorEvents,
    loadMoreCreatorEvents,
    eventsByHostUser,
    loadingEventsByHostUser,
    nextTokenForEventsByHostUser,
    markAsDeleted,
  } = eventsContext;
  const { user, loading: userIsLoading, isCreator } = userContext;
  const { addAlert } = alertsContext;
  const { currentUserRoom } = sponsorRoomsContext;

  const [eventToDelete, setEventToDelete] = useState(null);

  useEffect(() => {
    if (currentUserRoom) {
      loadCreatorEvents(currentUserRoom.id);
    }
  }, [currentUserRoom]);

  if (!userIsLoading && !user) {
    history.replace({
      pathname: routes.login,
      state: { from: history.location },
    });
  }

  if (user && !isCreator) {
    history.replace(routes.dashboard);
  }

  const loadMore = () => {
    if (currentUserRoom) {
      loadMoreCreatorEvents(currentUserRoom.id);
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loadingEventsByHostUser,
    hasNextPage: nextTokenForEventsByHostUser || false,
    onLoadMore: loadMore,
    rootMargin: '0px 0px 400px 0px',
  });

  const goToNewEvent = () => {
    history.push(routes.newEvent);
  };

  const openDeleteModal = (event) => {
    setEventToDelete(event);
  };

  const markEventAsDeleted = async () => {
    const event = eventToDelete;
    setEventToDelete(null);
    const result = await markAsDeleted(event.id);

    if (!result.success) {
      addAlert('danger', `Something went wrong while deleting ${event.title}`);
      return;
    }

    addAlert('success', `${event.title} has been deleted`);
  };

  const tableHeadingStyles =
    'py-2 font-display font-medium text-sm text-left leading-5 tracking-tight';

  return (
    <>
      <Helmet>
        <title>My Events | ClashTV</title>
      </Helmet>
      <div className='mt-8 flex items-center justify-end'>
        <Button
          buttonType='primary'
          buttonSize='small'
          disabled={currentUserRoom?.isLive !== true}
          buttonOnClick={goToNewEvent}
        >
          Create Event
        </Button>
      </div>
      <div className='mt-8 mb-8 px-8 py-4 shadow-card rounded-lg'>
        <Table classes='table-fixed'>
          <TableHead>
            <TableRow classes='border-b border-secondaryB'>
              <TableCell variant='head' classes={`${tableHeadingStyles} w-3/5`}>
                Event Name
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Current Status
              </TableCell>
              <TableCell variant='head' classes={`${tableHeadingStyles}`}>
                Date and Time
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventsByHostUser.map((event) => {
              return (
                <CreatorEventRow
                  key={event.id}
                  event={event}
                  deleteEvent={openDeleteModal}
                />
              );
            })}
            {nextTokenForEventsByHostUser && (
              <RenderLoader key='sentry' innerRef={sentryRef} />
            )}
          </TableBody>
        </Table>
        <Modal
          title={'Warning'}
          message={`Are you sure you want to delete ${eventToDelete?.title}?`}
          isVisible={!!eventToDelete}
          handleClose={() => setEventToDelete(null)}
          actions={[
            <Button
              key='cancel-button'
              disabled={false}
              buttonType='secondary'
              buttonSize='medium'
              buttonOnClick={() => setEventToDelete(null)}
              classes='mr-4'
            >
              No
            </Button>,
            <Button
              key='delete-button'
              disabled={false}
              buttonType='primary'
              buttonSize='medium'
              buttonOnClick={markEventAsDeleted}
            >
              Yes, delete it
            </Button>,
          ]}
        ></Modal>
      </div>
    </>
  );
};

const RenderLoader = ({ innerRef }) => {
  return (
    <TableRow innerRef={innerRef} classes={`border-b border-secondaryB`}>
      <TableCell variant='body' classes='font-bold py-2'>
        <Skeleton height={20} width={240} />
        <Skeleton height={22} width={120} />
      </TableCell>
      <TableCell
        variant='body'
        classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight'
      >
        <Skeleton height={20} width={100} />
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Skeleton height={22} width={120} />
      </TableCell>
    </TableRow>
  );
};

export default EditEventPage;
