import React, { Component } from 'react';
import { DevicesContext } from './devices';

export default class DevicesProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      audioInput: null,
      audioOutput: null,
      videoInput: null,
      setAudioInput: this.setAudioInput,
      setVideoInput: this.setVideoInput,
      setAudioOutput: this.setAudioOutput,
    };
  }

  setAudioInput = (device) => {
    this.setState({ audioInput: device });
  };

  setVideoInput = (device) => {
    this.setState({ videoInput: device });
  };

  setAudioOutput = (device) => {
    this.setState({ audioOutput: device });
  };

  render() {
    return (
      <DevicesContext.Provider value={this.state}>
        {this.props.children}
      </DevicesContext.Provider>
    );
  }
}
