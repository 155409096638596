/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { produce } from 'immer';
import {
  ActionType,
  createReducer,
} from 'typesafe-actions';

import { PurchasedSubscription } from 'domain/types';

import {
  eventPageMounted,
  puchaseSubscription,
} from 'store/event/actions';
import { arrayToByID } from 'store/mapping';

import { loadPurchasedSubscriptions } from './actions';

export type Actions = ActionType<
  | typeof eventPageMounted
  | typeof loadPurchasedSubscriptions
  | typeof puchaseSubscription
>;

export type State = {
  byID: Record<string, PurchasedSubscription>;
  allIDs: string[];
  loading: boolean;
  hasError: boolean;
};

const initialState: State = {
  byID: {},
  allIDs: [],
  loading: false,
  hasError: false,
};

const purchasedSubscriptionsReducer = createReducer<State, Actions>(initialState)
  .handleAction([
    loadPurchasedSubscriptions.request,
    eventPageMounted,
  ], (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.hasError = false;
    }))
  .handleAction(loadPurchasedSubscriptions.success, (state, action) =>
    produce(state, (draft) => {
      draft.byID = arrayToByID(action.payload);
      draft.allIDs = action.payload.map((s) => s.id);
      draft.loading = false;
    }))
  .handleAction(loadPurchasedSubscriptions.failure, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.hasError = true;
    }))
  .handleAction(puchaseSubscription.success, (state, action) =>
    produce(state, (draft) => {
      draft.byID[action.payload.id] = action.payload;
      draft.allIDs.push(action.payload.id)
    }));

export default purchasedSubscriptionsReducer;
