import React, { Component } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { InvitationsContext } from './invitations';
import { createInvitation, updateInvitation } from '../graphql/mutations';
import {
  invitationByEventID,
  invitationByUserID,
  getInvitation,
} from '../graphql/queries';
import {
  onUserInviteUpdateByUserID,
  onUserInviteCreate,
  onUserInviteUpdateByID,
} from '../graphql/subscriptions';
import { logError } from '../helpers';

export default class InvitationsProvider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptedInviteAsParticipant: null,
      setAcceptedInviteAsParticipant: this.setAcceptedInviteAsParticipant,
      sendInvitation: this.sendInvitation,
      getLastInviteByEventId: this.getLastInviteByEventId,
      getLastInviteByUserId: this.getLastInviteByUserId,
      subscribeToInvitationUpdateByUserId:
        this.subscribeToInvitationUpdateByUserId,
      subscribeToInvitationCreateByUserId:
        this.subscribeToInvitationCreateByUserId,
      subscribeToInvitationUpdateById: this.subscribeToInvitationUpdateById,
      updateInvitation: this.handleUpdateInvitation,
      getInvitationById: this.getInvitationById,
    };
  }

  setAcceptedInviteAsParticipant = (invite) => {
    this.setState({ acceptedInviteAsParticipant: invite });
  };

  getInvitationById = async (id) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getInvitation, {
          id,
        })
      );

      return res.data.getInvitation || null;
    } catch (error) {
      logError('getInvitationById', error);
      return null;
    }
  };

  handleUpdateInvitation = async (id, newData) => {
    try {
      const res = await API.graphql(
        graphqlOperation(updateInvitation, { input: { id, ...newData } })
      );

      return res.data.updateInvitation;
    } catch (error) {
      logError('handleUpdateInvitation', error);
      return null;
    }
  };

  subscribeToInvitationUpdateByUserId = async (userID, update) => {
    const subscription = await API.graphql(
      graphqlOperation(onUserInviteUpdateByUserID, {
        userID,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        update(value.data.onUserInviteUpdateByUserID);
      },
      error: (error) => logError('subscribeToInvitationUpdateByUserId', error),
    });

    return subscription;
  };

  subscribeToInvitationCreateByUserId = async (userID, create) => {
    const subscription = await API.graphql(
      graphqlOperation(onUserInviteCreate, {
        userID,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        if (value.data.onUserInviteCreate.userID === userID) {
          create(value.data.onUserInviteCreate);
        }
      },
      error: (error) => logError('subscribeToInvitationCreateByUserId', error),
    });

    return subscription;
  };

  subscribeToInvitationUpdateById = async (id, update) => {
    const subscription = await API.graphql(
      graphqlOperation(onUserInviteUpdateByID, {
        id,
      })
    ).subscribe({
      next: async ({ provider, value }) => {
        if (value.data) {
          update(value.data.onUserInviteUpdateByID);
        }
      },
      error: (error) => logError('subscribeToInvitationUpdateById', error),
    });

    return subscription;
  };

  getLastInviteByEventId = async (eventID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(invitationByEventID, {
          eventID,
          sortDirection: 'DESC',
          limit: 1,
        })
      );
      return res.data.invitationByEventID.items[0] || null;
    } catch (error) {
      logError('getLastInviteByEventId', error);
      return null;
    }
  };

  getLastInviteByUserId = async (userID) => {
    try {
      const res = await API.graphql(
        graphqlOperation(invitationByUserID, {
          userID,
          sortDirection: 'DESC',
          limit: 1,
        })
      );
      return res.data.invitationByUserID.items[0] || null;
    } catch (error) {
      logError('getLastInviteByEventId', error);
      return null;
    }
  };

  sendInvitation = async (creatorID, userID, eventID, mixerEventUUID) => {
    const input = {
      creatorID,
      userID,
      eventID,
      mixerEventUUID,
      status: 'sent',
      timestamp: Math.round(new Date().getTime() / 1000),
    };
    try {
      const res = await API.graphql(
        graphqlOperation(createInvitation, { input })
      );

      return {
        success: true,
        invitation: res.data.createInvitation,
        error: null,
      };
    } catch (error) {
      logError('sendInvitation', error);
      return { success: false, invitation: null, error };
    }
  };

  render() {
    return (
      <InvitationsContext.Provider value={this.state}>
        {this.props.children}
      </InvitationsContext.Provider>
    );
  }
}
