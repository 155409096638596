import React, { useContext, useEffect, useState } from 'react';
import { BoostersContext } from '../../context/boosters';
import { UserContext } from '../../context/user';
import GameItem from '../gamification/GameItem';

const ActiveBoosters = () => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const boostersContext = useContext(BoostersContext);
  const userContext = useContext(UserContext);

  const {
    subscribeToBoosterActivation,
    unsubscribeBoosterActivation,
    activeBoosters,
    durations,
  } = boostersContext;
  const { getCurrentUserId } = userContext;

  useEffect(() => {
    let boostersSubscription = null;

    if (getCurrentUserId()) {
      boostersSubscription = subscribeToBoosterActivation(getCurrentUserId());
    }

    return () => {
      if (boostersSubscription) {
        unsubscribeBoosterActivation(boostersSubscription);
      }
    };
  }, [getCurrentUserId()]);

  const renderActiveBoosters = () => {
    const active = [];
    for (const id in activeBoosters) {
      if (Object.hasOwnProperty.call(activeBoosters, id)) {
        const b = activeBoosters[id];
        active.push(
          <div
            key={b.id}
            className='relative flex items-center'
            onMouseEnter={() => {
              setTooltipVisible(true);
            }}
            onMouseLeave={() => {
              setTooltipVisible(false);
            }}
          >
            <GameItem
              img={b.icon._url}
              classes={`cursor-default hoverable-booster`}
              showAmount={false}
              showProgressBar={true}
              onClick={() => false}
              btnColor='#ff004f'
              fullCooldown={b.duration || 100}
              cooldownPassed={durations?.[b.id] || null}
              btnSize={25}
              buyable={false}
              amount={1}
              passive={true}
            />
            <div
              className={`active-booster-tooltip ${
                tooltipVisible ? 'visible' : ''
              }`}
            >
              {b.title}
            </div>
          </div>
        );
      }
    }

    return active;
  };

  return <div className='clap-booster-container'>{renderActiveBoosters()}</div>;
};

export default ActiveBoosters;
