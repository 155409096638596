import React, { useContext, useRef, useState } from 'react';
import BackButton from './BackButton';
import Paragraph from '../typography/Paragraph';
import Input from '../form/Input';
import Button from '../common/Button';
import useWithdraw from '../../hooks/useWithdraw';
import { AlertsContext } from '../../context/alerts';
import { InventoryContext } from '../../context/inventory';

const WithdrawForm = ({ isVisible, onBack, usd, bankingData }) => {
  const [error, setError] = useState('');
  const inputRef = useRef();

  const alertsContext = useContext(AlertsContext);
  const inventoryContext = useContext(InventoryContext);

  const { addAlert } = alertsContext;
  const { extractUsd } = inventoryContext;

  const { createWithdrawReq } = useWithdraw();

  const onRequest = async () => {
    const value = parseFloat(inputRef.current.value);

    if (!value) {
      setError('Requested amount cannot be $0');
      return;
    }

    if (value < 0) {
      setError('Requested amount cannot be $0');
      return;
    }

    if (value > usd) {
      setError('Requested amount exceeds your balance');
      return;
    }

    setError('');
    const data = {
      abaNumber: bankingData.abaNumber,
      accountName: bankingData.accountName,
      accountNumber: bankingData.accountNumber,
      accountType: bankingData.accountType,
      amount: value,
      status: 'pending',
    };

    const { success } = await createWithdrawReq(data);

    if (!success) {
      return addAlert('danger', 'Please try again later');
    }

    addAlert('success', 'Your request was submitted successfully');
    extractUsd(value);
    onBack();
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className='p-6 bg-white rounded-lg'>
      <div className='flex items-center justify-start mb-6'>
        <BackButton onClick={onBack} />
      </div>
      <Paragraph classes='mb-12'>Withdrawal to your bank account</Paragraph>
      <div className='flex items-center flex-col'>
        <Input
          setRef={inputRef}
          initialValue={usd}
          rightText='USD'
          id='amount'
          label='Withdrawal amount'
          type='number'
          wrapClasses='mb-9'
        />
        <div className='h-6 mb-7'>
          <Paragraph
            classes={`text-primaryB ${error ? 'visible' : 'invisible'}`}
          >
            {error}
          </Paragraph>
        </div>
        <Button
          buttonType='secondary'
          buttonSize='medium'
          buttonOnClick={onRequest}
        >
          Send request
        </Button>
      </div>
    </div>
  );
};

export default WithdrawForm;
