import React, { useContext, useEffect } from 'react';
import Table from 'components/table/Table';
import TableBody from 'components/table/TableBody';
import TableHead from 'components/table/TableHead';
import TableRow from 'components/table/TableRow';
import TableCell from 'components/table/TableCell';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import PPVPassEarningsLoader from '../PPVPassEarningsLoader';
import { useDispatch, useSelector } from "react-redux";
import { selectPpvEarnings, selectPpvEarningsAreLoading, selectPpvEarningsNextToken } from 'store/ppvPassEarnings/selectors';
import { loadPpvPassEarnings, ppvPassEarningsTableMounted } from 'store/ppvPassEarnings/actions';
import { UserContext } from 'context/user';

const tableHeadingStyles =
  'py-2 font-display font-medium text-sm text-left leading-5 tracking-tight w-32';

const PPVPassEarningsTable = () => {
  const dispatch = useDispatch();
  const { getCurrentUserId } = useContext<any>(UserContext);

  const isLoading = useSelector(selectPpvEarningsAreLoading);
  const nextToken = useSelector(selectPpvEarningsNextToken);
  const earnings = useSelector(selectPpvEarnings);

  const payload = { userID: getCurrentUserId(),  nextToken };

  
  const fetchEarnings = () => dispatch(loadPpvPassEarnings.request(payload))

  const [sentryRef] = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: !!nextToken,
    onLoadMore: fetchEarnings,
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    dispatch(ppvPassEarningsTableMounted(payload))
  }, []);

  return (
    <Table>
      <TableHead>
        <TableRow innerRef={undefined} classes='border-b border-secondaryB'>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Event
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Coins Earned
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Total Earnings USD
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Revenue Share
          </TableCell>
          <TableCell variant='head' classes={`${tableHeadingStyles}`}>
            Net Earnings
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {earnings
          .filter((item) => !!item)
          .map((item) => {
            return (
              <TableRow innerRef={undefined} key={item.id} classes={`border-b border-secondaryB`}>
                <TableCell
                  variant='body'
                  classes='font-display text-sm font-medium max-w-32'
                >
                  {item?.sponsorRoomPayPerView?.title ||
                    'item HAS BEEN DELETED'}
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-numbers font-bold py-2 text-sm tracking-tight'
                >
                  {item.totalCoins}
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-numbers font-bold py-2 text-sm tracking-tight'
                >
                  S{item.totalUsd}
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-numbers font-bold py-2 text-sm tracking-tight'
                >
                  {item.revenuePercent}%
                </TableCell>
                <TableCell
                  variant='body'
                  classes='font-numbers font-bold py-2 text-sm tracking-tight'
                >
                  ${item.totalCreatorProfitUsd}
                </TableCell>
              </TableRow>
            );
          })}
        {isLoading || nextToken ? (
          <PPVPassEarningsLoader key='sentry' innerRef={sentryRef} />
        ) : null}
      </TableBody>
    </Table>
  );
};

export default PPVPassEarningsTable;
