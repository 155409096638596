import React from 'react';
import Skeleton from 'react-loading-skeleton';
import TableRow from '../components/table/TableRow';
import TableCell from '../components/table/TableCell';

const TableRowSkeleton = () => {
  return (
    <TableRow classes={`border-b border-secondaryB`}>
      <TableCell
        variant='body'
        classes='text-primaryC text-sm font-display font-medium leading-5 py-2 tracking-tight'
      >
        <Skeleton wrapper='div' duration={2} height={24} width='50%' />
      </TableCell>
      <TableCell
        variant='body'
        classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight'
      >
        <Skeleton wrapper='div' duration={2} height={24} width='70%' />
      </TableCell>
      <TableCell variant='body' classes='font-numbers font-bold text-base'>
        <Skeleton wrapper='div' duration={2} height={24} width='30%' />
      </TableCell>
    </TableRow>
  );
};

export default TableRowSkeleton;
