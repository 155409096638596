/* eslint-disable no-unused-vars */
/* eslint-disable new-cap */
import React, { useState } from 'react';

import TableRow from '../table/TableRow';
import TableCell from '../table/TableCell';
import Badge from '../common/Badge';
import { Link } from 'react-router-dom';
import routes from '../../routes';
import Icon from '../typography/Icon';
import colors from '../../theme-colors';

const formatDate = (date) => {
  const options = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  return `${new Intl.DateTimeFormat('en-US', options).format(
    new Date(date)
  )} LT`;
};

const getStateText = (state) => {
  if (state === 'upcoming') {
    return 'scheduled';
  }

  if (state === 'inactive') {
    return 'not scheduled';
  }

  return state;
};

const CreatorEventRow = ({ event, deleteEvent }) => {
  const [visible, setVisible] = useState(false);

  const setDeleteAsVisible = () => {
    setVisible(true);
  };

  const setDeleteAsInvisible = () => {
    setVisible(false);
  };

  const handleDeleteEvent = () => {
    deleteEvent(event);
  };

  const badgeText = getStateText(event.state);

  return (
    <TableRow
      key={event.id}
      classes={`border-b border-secondaryB`}
      onMouseEnter={setDeleteAsVisible}
      onMouseLeave={setDeleteAsInvisible}
    >
      <TableCell variant='body' classes='font-bold py-2 pr-4'>
        <div className='flex justify-between items-center'>
          <Link to={`${routes.editMyEvent}/${event.id}/event`}>
            {event.title}
          </Link>
          <div className='flex'>
            {event.state === 'ended' &&
              event.videoRecordingDownloadUrl !== null && (
                <div className={`w-10 ${visible ? 'visible' : 'invisible'}`}>
                  <a
                    href={event.videoRecordingDownloadUrl}
                    target='_blank'
                    className='rounded-full w-9 h-9 items-center justify-center flex'
                    rel='noreferrer'
                  >
                    <Icon name='download' color={colors.primaryC} size={24} />
                  </a>
                </div>
              )}
            <div className={`w-10 ${visible ? 'visible' : 'invisible'}`}>
              <button
                onClick={handleDeleteEvent}
                className='rounded-full border-2 border-primaryB w-9 h-9 items-center justify-center flex'
              >
                <Icon name='trash' color={colors.primaryB} size={24} />
              </button>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell variant='body' classes='py-2'>
        <Badge badgeType={event.state}>{badgeText}</Badge>
      </TableCell>
      <TableCell
        variant='body'
        classes='font-numbers font-bold py-2 text-sm text-secondaryA tracking-tight'
      >
        {formatDate(event.startTime)}
      </TableCell>
    </TableRow>
  );
};

export default CreatorEventRow;
