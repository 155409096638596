import { useEffect, useRef, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listGoogleDefaultTagUrls, getEvent } from '../graphql/queries';

const useIMA = () => {
  const [adsLoaded, setAdsLoaded] = useState(false);

  const adsManagerRef = useRef(null);
  const videoElementRef = useRef(null);
  const adDisplayContainerRef = useRef(null);
  const adFinished = useRef(false);
  const initStarted = useRef(false);

  const getDefaultTagUrl = async () => {
    try {
      const res = await API.graphql(
        graphqlOperation(listGoogleDefaultTagUrls, {
          limit: 1,
        })
      );

      return res.data.listGoogleDefaultTagUrls.items[0].googleAdTagUrl;
    } catch (error) {
      return '';
    }
  };

  const getEventTagUrl = async (eventId) => {
    try {
      const res = await API.graphql(
        graphqlOperation(getEvent, { id: eventId })
      );
      const currentEvent = res.data.getEvent;

      if (!currentEvent) {
        return '';
      }

      return currentEvent.googleAdTagUrl;
    } catch (error) {
      return '';
    }
  };

  useEffect(() => {
    if (videoElementRef.current) {
      window.addEventListener('resize', function (event) {
        if (adsManagerRef.current) {
          const width = videoElementRef.current.clientWidth;
          const height = videoElementRef.current.clientHeight;
          adsManagerRef.current.resize(
            width,
            height,
            window.google.ima.ViewMode.NORMAL
          );
        }
      });
    }
  }, [videoElementRef.current]);

  const initializeIMA = async (eventId, videoElement, adContainerElement) => {
    if (adFinished.current || initStarted.current) {
      return;
    }

    initStarted.current = true;
    const eventTagUrl = await getEventTagUrl(eventId);
    const defaultTagUrl = await getDefaultTagUrl();

    if (!eventTagUrl && !defaultTagUrl) {
      return;
    }

    videoElementRef.current = videoElement;
    adDisplayContainerRef.current = new window.google.ima.AdDisplayContainer(
      adContainerElement,
      videoElementRef.current
    );
    const adsLoader = new window.google.ima.AdsLoader(
      adDisplayContainerRef.current
    );

    adsLoader.addEventListener(
      window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      onAdsManagerLoaded,
      false
    );
    adsLoader.addEventListener(
      window.google.ima.AdErrorEvent.Type.AD_ERROR,
      onAdError,
      false
    );

    // Let the AdsLoader know when the video has ended
    videoElement.addEventListener('ended', function () {
      adsLoader.contentComplete();
    });

    const adsRequest = new window.google.ima.AdsRequest();
    adsRequest.adTagUrl = eventTagUrl || defaultTagUrl;

    // Specify the linear and nonlinear slot sizes. This helps the SDK to
    // select the correct creative if multiple are returned.
    adsRequest.linearAdSlotWidth = videoElement.clientWidth;
    adsRequest.linearAdSlotHeight = videoElement.clientHeight;
    adsRequest.nonLinearAdSlotWidth = videoElement.clientWidth;
    adsRequest.nonLinearAdSlotHeight = videoElement.clientHeight / 3;

    // Pass the request to the adsLoader to request ads
    adsLoader.requestAds(adsRequest);
  };

  const loadAds = () => {
    // Prevent this function from running on if there are already ads loaded
    if (adsLoaded) {
      return;
    }

    setAdsLoaded(true);

    // Initialize the container. Must be done via a user action on mobile devices.
    adDisplayContainerRef.current.initialize();

    const width = videoElementRef.current.clientWidth;
    const height = videoElementRef.current.clientHeight;

    try {
      adsManagerRef.current.init(
        width,
        height,
        window.google.ima.ViewMode.NORMAL
      );
      adsManagerRef.current.start();
    } catch (adError) {
      // Play the video without ads, if an error occurs
      videoElementRef.current.play();
    }
  };

  const onAdsManagerLoaded = (adsManagerLoadedEvent) => {
    // Instantiate the AdsManager from the adsLoader response and pass it the video element
    adsManagerRef.current = adsManagerLoadedEvent.getAdsManager(
      videoElementRef.current
    );

    adsManagerRef.current.addEventListener(
      window.google.ima.AdEvent.Type.COMPLETE,
      () => {
        const width = videoElementRef.current.clientWidth;
        adsManagerRef.current.resize(
          width,
          0,
          window.google.ima.ViewMode.NORMAL
        );
        adFinished.current = true;
        videoElementRef.current.play();
        adsManagerRef.current.destroy();
        adDisplayContainerRef.current.destroy();
      }
    );

    adsManagerRef.current.addEventListener(
      window.google.ima.AdEvent.Type.SKIPPED,
      () => {
        const width = videoElementRef.current.clientWidth;
        adsManagerRef.current.resize(
          width,
          0,
          window.google.ima.ViewMode.NORMAL
        );
        adFinished.current = true;
        videoElementRef.current.play();
        adsManagerRef.current.destroy();
        adDisplayContainerRef.current.destroy();
      }
    );

    loadAds();
    videoElementRef.current.pause();
  };

  const onAdError = (adErrorEvent) => {
    // Handle the error logging.
    if (adsManagerRef.current) {
      adsManagerRef.current.destroy();
    }
  };

  return { initializeIMA, loadAds, adFinished, initStarted };
};

export default useIMA;
