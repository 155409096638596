import React from 'react';
import PropTypes from 'prop-types';
import { logError } from '../../helpers';

const Number = ({ children, numberSize, classes }) => {
  if (!children) {
    logError("Component Heading not rendered, because 'children' missing.");
    return null;
  }

  if (numberSize <= 0 || numberSize > 5 || !numberSize) {
    logError('Component Number not rendered, because of wrong numberSize.');
    return null;
  }

  function setNumberSize(size) {
    switch (size) {
      case 1:
        return (
          <span className={`font-numbers text-2xl leading-9.5 ${classes}`}>
            {children}
          </span>
        );
      case 2:
        return (
          <span className={`font-numbers text-xl leading-5 ${classes}`}>
            {children}
          </span>
        );
      case 3:
        return (
          <span className={`font-numbers text-base leading-4.5 ${classes}`}>
            {children}
          </span>
        );
      case 4:
        return (
          <span className={`font-numbers text-sm leading-5 ${classes}`}>
            {children}
          </span>
        );
      case 5:
        return (
          <span className={`font-numbers text-xs leading-3 ${classes}`}>
            {children}
          </span>
        );
    }
  }

  return <>{setNumberSize(numberSize)}</>;
};

Number.propTypes = {
  children: PropTypes.number.isRequired,
  numberSize: PropTypes.number.isRequired,
  classes: PropTypes.string,
};

Number.defaultProps = {
  numberSize: 1,
  classes: '',
};

export default Number;
