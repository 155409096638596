import React, { useContext, useEffect, useState } from 'react';
import {
  useMeetingManager,
  useMeetingStatus,
} from 'amazon-chime-sdk-component-library-react';

import { UserContext } from '../../context/user';
import { InvitationsContext } from '../../context/invitations';
import { EventsContext } from '../../context/events';
import { AlertsContext } from '../../context/alerts';

import InvitationDialog from './InvitationDialog';
import DevicesDialog from '../creator/DevicesDialog';

import history from '../../history';
import routes from '../../routes';
import { logError, messagingConnect } from '../../helpers';
import Spinner from '../system/Spinner';

import useDeviceSelection from '../../hooks/useDeviceSelection';

const chimeRegionEndpoint = 'https://nearest-media-region.l.chime.aws';
const deploymentDomain = process.env.REACT_APP_DEPLOYMENT_DOMAIN;

const MeetingStatuses = [
  'Loading',
  'Succeeded',
  'Failed',
  'Ended',
  'JoinedFromAnotherDevice',
];

const InvitationsContainer = () => {
  const userContext = useContext(UserContext);
  const invitationsContext = useContext(InvitationsContext);
  const eventsContext = useContext(EventsContext);
  const alertsContext = useContext(AlertsContext);

  const { loading: userIsLoading, getCurrentUserId } = userContext;
  const {
    getLastInviteByUserId,
    subscribeToInvitationCreateByUserId,
    subscribeToInvitationUpdateById,
    getInvitationById,
    setAcceptedInviteAsParticipant,
    acceptedInviteAsParticipant,
  } = invitationsContext;
  const { getCurrentEvent } = eventsContext;
  const { addAlert } = alertsContext;

  const [invitations, setInvitations] = useState([]);
  const [activeModal, setActiveModal] = useState(null);
  const [updateSub, setUpdateSub] = useState(null);
  const [showDeviceSelectionDialog, setShowDeviceSelectionDialog] =
    useState(false);
  const [loadingDevices, setLoadingDevices] = useState(false);

  const meetingManager = useMeetingManager();
  const meetingStatusId = useMeetingStatus();

  const { startVideo } = useDeviceSelection();

  const handleNewInvitations = (newInvite) => {
    let invites = [];
    setInvitations((oldInvitations) => {
      invites = [...oldInvitations, newInvite];
      return invites;
    });

    setActiveModal((currentActiveModal) => {
      return currentActiveModal || invites?.[0]?.id;
    });
  };

  const handleUpdatedInvitations = (updatedInvite) => {
    setInvitations((oldInvitations) => {
      if (updatedInvite.status === 'declined') {
        setActiveModal(null);
        setAcceptedInviteAsParticipant(null);
        return oldInvitations.filter((inv) => inv.id !== updatedInvite.id);
      }

      return oldInvitations;
    });
  };

  useEffect(async () => {
    let sub = updateSub;
    if (activeModal) {
      sub = await subscribeToInvitationUpdateById(
        activeModal,
        handleUpdatedInvitations
      );

      if (updateSub) {
        updateSub.unsubscribe();
      }

      setUpdateSub(sub);
    }

    return () => {
      if (updateSub) {
        updateSub.unsubscribe();
      }
    };
  }, [activeModal]);

  useEffect(async () => {
    let subCreate = null;

    if (!userIsLoading && getCurrentUserId()) {
      // if user is ready get his last invite
      const lastInvite = await getLastInviteByUserId(getCurrentUserId());

      // if invite is still active and event is live show invitation dialog
      if (lastInvite?.status === 'sent') {
        const event = await getCurrentEvent(lastInvite.eventID, true);

        if (event.state === 'live') {
          setInvitations([lastInvite]);
          setActiveModal(lastInvite.id);
        }
      }

      // set subscription for incoming invites
      subCreate = await subscribeToInvitationCreateByUserId(
        getCurrentUserId(),
        handleNewInvitations
      );
    }

    () => {
      if (subCreate) {
        subCreate.unsubscribe();
      }
    };
  }, [userIsLoading]);

  useEffect(() => {
    if (MeetingStatuses[meetingStatusId] === 'Succeeded') {
      startVideo();
    }
  }, [meetingStatusId]);

  useEffect(() => {
    if (activeModal && document.fullscreenElement) {
      document.exitFullscreen();
    }
  }, [activeModal]);

  const joinMeeting = async (eventUUID, accessCode) => {
    try {
      const regionResp = await fetch(chimeRegionEndpoint);
      const { region } = await regionResp.json();

      if (!region) {
        throw new Error('Missing chime region');
      }

      const joinMeetingEndpoint = `${deploymentDomain}events/mixer/chime-join-meeting?mediaRegion=${region}`;
      const joinResp = await fetch(joinMeetingEndpoint, {
        method: 'GET',
        headers: {
          Authorization: `${eventUUID} ${accessCode}`,
          broadcasterOrigin: deploymentDomain,
        },
      });
      const { data } = await joinResp.json();

      const joinData = {
        meetingInfo: data.meetingInfo.Meeting,
        attendeeInfo: data.attendeeInfo.Attendee,
      };

      await meetingManager.join(joinData);
      const endpoint = data.meetingInfo.MessagingEndpoint;

      messagingConnect(endpoint, eventUUID, accessCode, () => {}, data);
    } catch (error) {
      logError(error);
      addAlert('danger', 'Error joining the event!');
    }
  };

  const handleAcceptProcess = async (inviteId) => {
    setLoadingDevices(true);
    const invite = await getInvitationById(inviteId);

    if (invite?.mixerEventUUID && invite?.mixerAccessCode) {
      await joinMeeting(invite.mixerEventUUID, invite.mixerAccessCode);
      setAcceptedInviteAsParticipant(invite);
    }

    setLoadingDevices(false);
    setShowDeviceSelectionDialog(true);
  };

  const handleInviteAction = (inviteId) => {
    const newList = invitations.filter((i) => i.id !== inviteId);
    const nextInviteId = newList?.[0]?.id || null;
    setInvitations(newList);
    setActiveModal(nextInviteId);
  };

  const handleDeclineInvitaiton = (inviteId) => {
    handleInviteAction(inviteId);
    setAcceptedInviteAsParticipant(null);
    updateSub.unsubscribe();
  };

  const handleAcceptInvitatian = (inviteId) => {
    handleInviteAction(inviteId);
    handleAcceptProcess(inviteId);
  };

  const handleCloseDevicesSelection = async () => {
    setShowDeviceSelectionDialog(false);
  };

  const handleAccept = async () => {
    setShowDeviceSelectionDialog(false);
    await meetingManager.start();
    const event = await getCurrentEvent(
      acceptedInviteAsParticipant.eventID,
      true
    );
    const eventRoute = `${routes.event}/${
      event?.seoUrl ? event.seoUrl : acceptedInviteAsParticipant.eventID
    }`;

    if (history.location.pathname !== eventRoute) {
      history.push(eventRoute);
    }
  };

  return (
    <>
      {invitations[0] && (
        <InvitationDialog
          key={invitations[0].id}
          invitation={invitations[0]}
          isVisible={invitations[0].id === activeModal}
          onDecline={handleDeclineInvitaiton}
          onAccept={handleAcceptInvitatian}
        />
      )}
      {loadingDevices && (
        <div
          key='loader'
          className='fixed inset-0 flex items-center justify-center z-50'
        >
          <Spinner size='big' />
        </div>
      )}
      <DevicesDialog
        key='device-dialog'
        isVisible={showDeviceSelectionDialog}
        onAccept={handleAccept}
        onClose={handleCloseDevicesSelection}
      />
    </>
  );
};

export default InvitationsContainer;
